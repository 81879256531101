import { Box, Typography, Stack, styled } from '@mui/material';
import { transientOptions } from '~/utils/styled';

type ParameterWrapperProps = {
  $iconLeft?: boolean;
};

export const ParameterWrapperStyled = styled(
  Stack,
  transientOptions
)<ParameterWrapperProps>(({ $iconLeft }) => ({
  gap: '8px',
  flexDirection: 'row',
  ...($iconLeft && {
    flexDirection: 'row-reverse'
  })
}));

type TypographyProps = {
  $iconLeft?: boolean;
};

const TypographyStyled = styled(
  Typography,
  transientOptions
)<TypographyProps>(({ theme, $iconLeft }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  fontSize: '14px',
  marginRight: theme.spacing(1),
  ...($iconLeft && {
    marginRight: theme.spacing(0)
  })
}));

type IconWrapperProps = {
  width: number | string;
  height: number | string;
  $iconLeft?: boolean;
};

export const IconWrapperStyled = styled(
  Box,
  transientOptions
)<IconWrapperProps>(({ theme, width, height, $iconLeft }) => ({
  width,
  height,
  fontSize: '14px',
  lineHeight: '1',
  ...($iconLeft && {
    marginRight: theme.spacing(1)
  })
}));

export default TypographyStyled;

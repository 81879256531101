import { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import intersection from 'lodash/intersection';
import includes from 'lodash/includes';
import ActionsDropdown from '~/components/actions_dropdown';
import { MenuItemStyled, MenuGroupTitleStyled } from '~/components/actions_dropdown/styled';
import { PERMISSIONS } from '~/mst/models/permissions/types';
import useMst from '~/hooks/use_mst';
import useAuth from '~/hooks/use_auth';
import useSelection from '~/hooks/react_grid/use_selection';
import useActions from '~/components/actions_dropdown/context';
import useNavigate from '~/hooks/use_navigate';
import useLocales from '~/hooks/use_locales';

import ActionsModal from './manage_modal/actions_modal';
import MultiAlertModal from './multi_alert_modal';

function ActionsAcontainer() {
  const { t } = useLocales();
  const { selection } = useSelection();
  const { auth } = useAuth();
  const { alerts } = useMst();
  const { actions } = alerts;
  const { action, setAction } = useActions();
  const { navigate } = useNavigate();

  const canCreate = auth.user?.hasPermission(PERMISSIONS.ManageDevices);
  const canManage = Boolean(intersection(['activate', 'deactivate'], actions).length);
  const availableActions = intersection(...selection.map((id) => alerts.getById(id)?.actions));
  const handleActivate = useCallback(() => setAction('activate'), [setAction]);
  const handleDeactivate = useCallback(() => setAction('deactivate'), [setAction]);
  const handleCreateNewAlert = useCallback(() => navigate('alerts/create'), [navigate]);
  const handleCreateNewGroupAlert = useCallback(() => setAction('create_new_group_alert'), [setAction]);

  if (canCreate === false && canManage === false) {
    return null;
  }

  return (
    <>
      <ActionsDropdown>
        {canManage && [
          <MenuGroupTitleStyled key="manage-0">{t('base.buttons.manage')}</MenuGroupTitleStyled>,
          <MenuItemStyled key="manage-1" disabled={!includes(availableActions, 'activate')} onClick={handleActivate}>
            {t('base.buttons.activate')}
          </MenuItemStyled>,
          <MenuItemStyled key="manage-2" disabled={!includes(availableActions, 'deactivate')} onClick={handleDeactivate}>
            {t('base.buttons.deactivate')}
          </MenuItemStyled>
        ]}

        {canCreate && [
          <MenuGroupTitleStyled key="create-0">{t('base.buttons.create_new')}</MenuGroupTitleStyled>,
          <MenuItemStyled key="create-1" onClick={handleCreateNewAlert}>
            {t('base.buttons.alert')}
          </MenuItemStyled>,
          <MenuItemStyled key="create-2" onClick={handleCreateNewGroupAlert}>
            {t('base.buttons.group_alert')}
          </MenuItemStyled>
        ]}
      </ActionsDropdown>
      <ActionsModal
        open={action === 'activate' || action === 'deactivate'}
        type={action}
        toggleModal={() => setAction(null)}
        selected={selection.map((id) => alerts.getById(id))}
      />
      <MultiAlertModal open={action === 'create_new_group_alert'} toggleModal={() => setAction(null)} />
    </>
  );
}

export default observer(ActionsAcontainer);

/* eslint-disable */
import React from 'react';
import { IconProps } from './types';

export default function DeviceHubIcon({ sx }: IconProps) {
  return (
    <svg
      width={sx ? sx.width : '100%'}
      height={sx ? sx.height : '100%'}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 70.4 65.4"
      fill="currentColor"
    >
      <g xmlns="http://www.w3.org/2000/svg">
        <path d="M48,52.1c1.1,2.2,3.3,3.7,5.9,3.7c3.7,0,6.7-3,6.7-6.7c0-3.7-3-6.7-6.7-6.7c-2.6,0-4.8,1.5-5.9,3.7l-15.6,0   c0,0,0,0,0,0l-3.4,0l-0.1,0v0l-2.2,0c0.3,1,0.4,2,0.4,3c0,5.9-4.8,10.7-10.7,10.7c-5.9,0-10.7-4.8-10.7-10.7   c0-5.9,4.8-10.7,10.7-10.7l0-5.6C7.3,32.8,0,40.1,0,49.1c0,9,7.3,16.3,16.3,16.3c8,0,14.6-5.7,16-13.3L48,52.1z" />
        <path d="M10.5,45.8c-1.8,3.2-0.7,7.3,2.6,9.1c3.2,1.8,7.3,0.7,9.1-2.6c1.3-2.3,1.1-5-0.3-7l7.6-13.6c0,0,0,0,0,0   l1.7-3.1l0,0l0,0l1.1-1.9c-1-0.2-1.9-0.6-2.8-1.1c-5.1-2.9-7-9.4-4.1-14.6C28.3,6,34.8,4.1,40,7c5.1,2.9,7,9.4,4.1,14.6l4.9,2.8   c4.4-7.9,1.6-17.8-6.2-22.2c-7.9-4.4-17.8-1.6-22.2,6.2c-3.9,7-2.2,15.6,3.8,20.5l-7.6,13.6C14.2,42.3,11.8,43.6,10.5,45.8z" />
        <path d="M68.1,40.5c-4.1-6.9-12.4-9.6-19.7-6.9l-7.9-13.2c1.4-2.1,1.5-4.9,0.1-7.2c-1.9-3.2-6.1-4.3-9.4-2.4   c-3.2,1.9-4.3,6.1-2.4,9.4c1.4,2.3,3.9,3.5,6.4,3.3l7.9,13.2c0,0,0,0,0,0l1.8,3l0,0l0,0l1.1,1.9c0.7-0.7,1.5-1.4,2.4-1.9   c5.1-3,11.6-1.4,14.7,3.7c3,5.1,1.4,11.6-3.7,14.7c-5.1,3-11.6,1.4-14.7-3.7l-4.8,2.9c4.6,7.7,14.7,10.3,22.4,5.6   S72.7,48.2,68.1,40.5z" />
      </g>
    </svg>
  );
}

import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Typography, Stack } from '@mui/material';
import useSvgLoader from '~/hooks/use_svg_loader';
import { DeviceCard, Icon } from '~/pages/devices/grid_container/detail_row/components';

interface DeviceProps {
  name: string;
  total: number;
}

function Device(props: DeviceProps) {
  const theme = useTheme();
  const { name, total } = props;

  const SvgIcon = useSvgLoader(name, 'DefaultDevice');

  return (
    <DeviceCard $device $centered sx={{ marginRight: 2 }}>
      <Icon $fixheight>
        <SvgIcon />
      </Icon>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          marginTop: theme.spacing(-0.25),
          paddingLeft: theme.spacing(1),
          paddingRight: theme.spacing(1)
        }}
      >
        <Stack direction="column" alignItems="flex-start">
          <Typography variant="body2">{name}</Typography>
          <Typography>{total}</Typography>
        </Stack>
      </Box>
    </DeviceCard>
  );
}

export default Device;

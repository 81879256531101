import Axios from 'axios';
import axiosRetry from 'axios-retry';
import get from 'lodash/get';
import * as Sentry from '@sentry/react';
import { jwtDecode } from 'jwt-decode';
import { makeAutoObservable } from 'mobx';
import getAuth0Client from '~/services/auth0';
import toasts from '~/utils/toasts';

const axios = Axios.create();

export class TokenStore {
  token = null as string | null;

  constructor() {
    makeAutoObservable(this);
  }

  setToken(token: string) {
    this.token = token;
  }

  getAppToken() {
    return this.token ? `Bearer ${this.token}` : null;
  }

  isAuthorized() {
    return Boolean(this.token);
  }

  isExpired() {
    if (this.token) {
      const { exp } = jwtDecode(this.token!);
      const expiration = new Date(exp * 1000);
      const now = new Date();
      now.setSeconds(now.getSeconds() - 10);
      return now > expiration;
    }
    return true;
  }

  async refreshToken() {
    const client = getAuth0Client();
    await client.getTokenSilently({ cacheMode: 'off' });
    const claims = await client.getIdTokenClaims();
    this.token = claims?.__raw as string;
  }
}

export const token = new TokenStore();

axiosRetry(axios, { retries: 3 });

axios.interceptors.request.use(
  (config) => {
    Sentry.setContext('axios', config);
    return config;
  },
  (error) => {
    Sentry.setContext('axios', error.config);
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    Sentry.setContext('axios', response);
    return response;
  },
  async (error) => {
    const orginalRequest = error.config;
    if (Axios.isCancel(error)) {
      return Promise.reject(error);
    }
    if (error.response?.status === 500) {
      toasts.error('There was a problem processing your request, please try again later or contact ssupport');
    } else if (error.response?.status === 403 || error.response?.data === 'Unauthorized') {
      if (token.isExpired()) {
        await token.refreshToken();
        orginalRequest.headers.Authorization = token.getAppToken();
        return axios.request(orginalRequest);
      }
    } else {
      let errorResponse;
      if (get(error, 'response.data.errors.detail')) {
        errorResponse = get(error, 'response.data.errors.detail');
      } else if (get(error, 'error.response.data.error')) {
        errorResponse = get(error, 'error.response.data.error');
      } else if (error.request) {
        errorResponse = error.request.message || error.request.statusText;
      } else {
        errorResponse = error.message;
      }
      if (errorResponse) {
        toasts.error(errorResponse);
      }
    }
    return Promise.reject(error);
  }
);

export default axios;

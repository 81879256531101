import React, { useCallback, useMemo } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { observer } from 'mobx-react-lite';
import { computed } from 'mobx';
import { Box, CardContent } from '@mui/material';

import Yup, { searchableSelectSchema } from '~/utils/yup';
import { useValidationSchema } from '~/hooks/use_validate';
import toasts from '~/utils/toasts';
import useNavigate from '~/hooks/use_navigate';
import useMst from '~/hooks/use_mst';
import OrganizationSelect from '~/components/organizations_select';
import useLocales from '~/hooks/use_locales';
import useAuth from '~/hooks/use_auth';

import FormGrid from '~/components/@material-extend/form_grid';
import Modal, { ModalActions } from '~/components/modal';
import TextInput from '~/components/final_form/text_input';
import { captureException } from '~/services/sentry';

type AddDashboardModalProps = {
  open: boolean;
  onClose: () => void;
};

function AddDashboardModal({ open, onClose }: AddDashboardModalProps) {
  const { t } = useLocales();
  const { auth } = useAuth();
  const { dashboards, organizations } = useMst();
  const { navigate } = useNavigate();
  const { isSyncing } = dashboards;

  const CreateDashboardSchema = Yup.object().shape({
    name: Yup.string().required('Name is required').min(3, 'Name is too short').max(50, 'Name is too long'),
    organization_id: searchableSelectSchema('Organization is required')
  });

  const validate = useValidationSchema(CreateDashboardSchema);

  const initialValues = useMemo(() => computed(() => ({ organization_id: auth.organizationId })), [auth.organizationId]).get();

  const handleSave = useCallback(
    async (values) => {
      try {
        const dashboard = await dashboards.create({
          ...values,
          configuration: {
            widgets: []
          }
        });
        onClose();
        toasts.success(t('notifications.success.forms.create_dashboard'));
        navigate(`/${dashboard.organization_id}/dashboards/${dashboard.id}`);
      } catch (e) {
        captureException(e);
        toasts.error(t('notifications.errors.server_error'));
      }
    },
    [dashboards, onClose, navigate, t]
  );

  return (
    <Modal open={open} handleClose={onClose} title={t('models.dashboard_create')}>
      <FinalForm onSubmit={handleSave} validate={validate} initialValues={initialValues}>
        {({ handleSubmit, invalid }) => (
          <Box>
            <CardContent>
              <FormGrid>
                <TextInput label={t('attributes.dashboard.name')} name="name" />
                <TextInput multiline label={t('attributes.dashboard.description')} name="description" />
                <OrganizationSelect
                  name="organization_id"
                  label={t('models.organization')}
                  options={organizations.valueLabelPairsManagerAccess}
                  loading={isSyncing}
                />
              </FormGrid>
            </CardContent>
            <ModalActions onSave={handleSubmit} onClose={onClose} disabled={invalid} loading={isSyncing} saveLabel={t('base.buttons.create')} />
          </Box>
        )}
      </FinalForm>
    </Modal>
  );
}

export default observer(AddDashboardModal);

/* eslint-disable import/prefer-default-export */
import request from '../request';

export function fetch(params, options) {
  return [request, 'get', 'tokens', params, options];
}

export function read(id: string) {
  return request('get', `tokens/${id}`);
}

export function create(payload: any) {
  return request('post', 'tokens', payload);
}

export function destroy(id: string) {
  return request('delete', `tokens/${id}`);
}

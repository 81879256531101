/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef, ReactNode } from 'react';
import { m } from 'framer-motion';
import { Box, IconButton, IconButtonProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const varSmall = {
  hover: { scale: 1.1 },
  tap: { scale: 0.95 }
};

const varMedium = {
  hover: { scale: 1.09 },
  tap: { scale: 0.97 }
};

const varLarge = {
  hover: { scale: 1.08 },
  tap: { scale: 0.99 }
};

function AnimateWrap({ size, children }: AnimateWrapProp) {
  const isSmall = size === 'small';
  const isLarge = size === 'large';

  return (
    <Box
      component={m.div}
      whileTap="tap"
      whileHover="hover"
      variants={(isSmall && varSmall) || (isLarge && varLarge) || varMedium}
      sx={{
        display: 'inline-flex'
      }}
    >
      {children}
    </Box>
  );
}

// eslint-disable-next-line react/prop-types
const IconButtonAnimate = forwardRef<HTMLButtonElement, IconButtonProps>(({ children, size = 'medium', sx, ...other }, ref) => {
  const theme = useTheme();

  return (
    <AnimateWrap size={size}>
      <IconButton
        size={size}
        ref={ref}
        {...other}
        sx={{
          ...sx,
          transition: `transform ${theme.animations.duration}s`,
          '&:hover': {
            transform: `scale(${varSmall.hover.scale})`
          }
        }}
      >
        {children}
      </IconButton>
    </AnimateWrap>
  );
});

export default IconButtonAnimate;

type AnimateWrapProp = {
  children: ReactNode;
  size: 'small' | 'medium' | 'large';
};

import useResponsive from '~/hooks/use_responsive';

function useWidth() {
  const isMobile = useResponsive('down', 'sm');
  if (isMobile) {
    return [
      { columnName: 'status', width: 100 },
      { columnName: 'actions', width: 65 },
      { columnName: 'organizationName', width: 100 },
      { columnName: 'description', width: 100 },
      { columnName: 'name', width: 100 }
    ];
  }
  return [
    { columnName: 'status', width: 100 },
    { columnName: 'actions', width: 65 },
    { columnName: 'name', width: 240 },
    { columnName: 'organizationName', width: 240 },
    { columnName: 'description', width: 240 }
  ];
}

export default useWidth;

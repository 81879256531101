import React, { useState, useCallback } from 'react';
import { styled, Stack, Portal, Button, Box, Typography } from '@mui/material';
import { WidgetTypes } from '~/mst/models/widget/types';
import { IconProps } from '~/assets/icons/types';
import { Iconify } from '~/components/iconify';
import PanelStyled from '~/components/filters/filters_picker/styled';
import useLocales from '~/hooks/use_locales';
import useModal from '~/hooks/use_modal';
import WIDGET_TYPES from './widget_types';
import AddWidgetModal from './add_widget_modal_wrapper';

type AddWidgetsContainerProps = {
  isVisible: boolean;
  onHide: () => void;
};

const OptionContainer = styled(Button)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  background: theme.palette.action.hover,
  padding: theme.spacing(2),
  margin: theme.spacing(1, 0)
}));

const StyledOptionLabel = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.primary.main,
  fontWeight: '600',
  marginLeft: theme.spacing(2)
}));

const IconWrapper = styled(Box)(() => ({
  width: 50,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

type OptionProps = {
  item: {
    label: string;
    type: WidgetTypes;
    Icon: ({ sx }: IconProps) => JSX.Element;
  };
  onClick: (item: string) => void;
};

function Option({ onClick, item }: OptionProps) {
  const { t } = useLocales();
  const { type, label, Icon } = item;
  const handleClick = useCallback(() => {
    onClick(type);
  }, [onClick, type]);

  return (
    <OptionContainer onClick={handleClick} fullWidth>
      <IconWrapper>
        <Icon sx={{ height: 30 }} />
      </IconWrapper>
      <StyledOptionLabel>{t(`models.${label}`)}</StyledOptionLabel>
    </OptionContainer>
  );
}

function AddWidgetsContainer({ isVisible, onHide }: AddWidgetsContainerProps) {
  const [widgetType, setWidgetType] = useState(null);
  const { open: modalOpen, handleOpen: handleOpenModal, handleClose: closeModal } = useModal();

  const handleAddWidget = useCallback(
    (widget_type) => {
      setWidgetType(widget_type);
      handleOpenModal();
      onHide();
    },
    [setWidgetType, handleOpenModal, onHide]
  );

  const handleCloseModal = useCallback(() => {
    setWidgetType(null);
    closeModal();
  }, [setWidgetType, closeModal]);

  return (
    <Portal>
      <PanelStyled $isVisible={isVisible} boxShadow={3}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" mb={3}>
          <Box>
            <Typography variant="subtitle1">Add Widgets</Typography>
          </Box>
          <Button onClick={onHide} variant="text">
            <Iconify icon="eva:close-fill" width={20} height={20} />
          </Button>
        </Stack>
        {WIDGET_TYPES.map((item) => (
          <Option key={item.label} item={item} onClick={handleAddWidget} />
        ))}
      </PanelStyled>
      <AddWidgetModal widgetType={widgetType} open={modalOpen} onClose={handleCloseModal} />
    </Portal>
  );
}

export default AddWidgetsContainer;

import { types as t, Instance } from 'mobx-state-tree';
import Partnership from '~/mst/models/partnership';
import Syncable from '~/mst/models/abstract/syncable';
import Fetchable from '~/mst/models/abstract/fetchable';
import Filterable from '../abstract/filterable';
import CollectionMap from '../abstract/collection_map';

const PartnershipsModel = t.compose(CollectionMap(Partnership), Syncable, Fetchable({ cache: true }), Filterable);

export interface IPartnershipsModel extends Instance<typeof PartnershipsModel> {}

export default PartnershipsModel;

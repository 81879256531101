import { Box, BoxProps } from '@mui/material';
import Image from '~/components/image';
import LogoImage from '~/assets/images/logo.png';
import LogoMiniImage from '~/assets/images/logo_small.png';

type LogoProps = {
  isMini?: boolean; // eslint-disable-line react/require-default-props
  sx?: BoxProps; // eslint-disable-line react/require-default-props
};

export default function Logo({ isMini, sx }: LogoProps) {
  return (
    <Box sx={{ width: isMini ? 'auto' : 160, ...sx }}>
      {isMini ? <Image sx={{ width: '50px', height: '50px' }} disabledEffect src={LogoMiniImage} /> : <Image disabledEffect src={LogoImage} />}
    </Box>
  );
}

import React, { useState, useEffect } from 'react';
import { computed } from 'mobx';
import isNumber from 'lodash/isNumber';
import isArray from 'lodash/isArray';
import map from 'lodash/map';
import { token } from '~/api/axios';

const { VITE_API_URL_ROOT } = process.env;

const createInput = (value: any, name: string, index?: number): any => {
  if (isArray(value)) {
    return value.map((_value, _index) => createInput(_value, `${name}[]`, _index));
  }
  return <input type="hidden" value={value} name={name} key={isNumber(index) ? `${name}_${index}` : name} />;
};

interface DownloadFormProps {
  // eslint-disable-next-line react/require-default-props
  method?: string;
  actionUrl: string;
}

const useDownloadForm = (callback?: VoidFunction) => {
  const formRef = React.useRef(null);
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    if (data) {
      if (formRef.current) {
        formRef.current.submit();
      }
      if (callback) {
        callback();
      }
    }
  }, [data, callback]);

  const DownloadForm: React.FC<DownloadFormProps> = React.useMemo(
    () =>
      computed(() =>
        React.memo(({ method = 'POST', actionUrl }: DownloadFormProps) => {
          return (
            <form action={`${VITE_API_URL_ROOT}${actionUrl}`} method={method} ref={formRef}>
              <fieldset style={{ border: 0 }}>
                {createInput(token.getAppToken(), 'token')}
                {data && map(data, createInput)}
              </fieldset>
            </form>
          );
        })
      ),
    [data]
  ).get();

  return {
    setData,
    DownloadForm
  };
};

export default useDownloadForm;

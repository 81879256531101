import { ButtonProps } from '@mui/material';
import ButtonDesktopOnlyStyled from './styled';

export type Sizes = 'xs' | 'sm' | 'md' | 'lg';

interface ButtonPropsBreakable extends ButtonProps {
  breakpoint: Sizes;
  $mobileFullWidth?: boolean;
}

export default function ButtonAdaptive(props: ButtonPropsBreakable) {
  const { breakpoint, children } = props;
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <ButtonDesktopOnlyStyled {...props} breakpoint={breakpoint}>
      {children}
    </ButtonDesktopOnlyStyled>
  );
}

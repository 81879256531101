import React, { useState } from 'react';
import { Card, CardContent } from '@mui/material';
import { IFrameRenderer } from '~/components/iframe_renderer';

const { VITE_API_ANALYTICS_URL_ROOT } = process.env;

function ReportContent({ name, token }: { name: string; token: string }) {
  const [isIFrameLoaded, setIsIFrameLoaded] = useState<boolean>(false);

  return (
    <Card>
      <CardContent>
        {isIFrameLoaded === false && <div>Loading...</div>}
        <IFrameRenderer
          sourceUrl={`${VITE_API_ANALYTICS_URL_ROOT}analytics/report?token=${token}`}
          title={name}
          handleOnLoad={() => {
            setIsIFrameLoaded(true);
          }}
        />
      </CardContent>
    </Card>
  );
}

export default React.memo(ReportContent);

/* eslint-disable import/prefer-default-export */
import { styled, Box } from '@mui/material';

export const TableContainer = styled(Box)(({ theme }) => ({
  margin: theme.spacing(0, -1.5),
  '.MuiTableHead-root .MuiTableCell-root': {
    backgroundColor: 'transparent'
  },
  '.MuiTableHead-root': {
    position: 'sticky',
    top: '0',
    zIndex: '10'
  },
  '.MuiTableBody-root .MuiTableRow-root:last-child': {
    '.MuiTableCell-root': {
      border: 0
    }
  },
  '.TableCell-cell:first-of-type': {
    paddingLeft: '6px'
  },
  '.MuiTableCell-root.MuiTableCell-body': {
    padding: '12px',
    fontSize: '14px'
  },
  '.MuiTableCell-root .Container-wrapper': {
    width: '100%',
    backgroundColor: 'transparent',
    '.MuiButtonBase-root': {
      display: 'none'
    },
    zIndex: 1
  }
}));

import { FieldArrayRenderProps } from 'react-final-form-arrays';
import type { IAlert } from '~/mst/models/alert';
import Condition from './condition';
import type { ConditionType } from './data_point';

type ConditionsFieldProps = FieldArrayRenderProps<ConditionType, HTMLInputElement> & {
  model: IAlert;
};

function ConditionsField(props: ConditionsFieldProps) {
  const { fields, model } = props;
  return fields.map((name, index) => <Condition name={name} key={name} model={model} fields={fields} index={index} />);
}

export default ConditionsField;

/* eslint-disable react/require-default-props */
import { SxProps } from '@mui/material';
import GsmSignalLevel from '~/components/device_signal/gsm_signal_level';
import WifiSignalLevel from '~/components/device_signal/wifi_signal_level';
import useLocales from '~/hooks/use_locales';

import { SIGNAL_LEVELS } from '~/utils/constants';

type SignalContainerType = {
  showLabel?: boolean;
  hasWifi?: boolean;
  iconLeft?: boolean;
  networkSignal?: number;
  networkName?: string;
  networkSignalUnit?: string;
  sx?: SxProps;
};

export const getSignalLevel = (value: string | number) => {
  if (!value) {
    return 0;
  }
  let signalLevel = SIGNAL_LEVELS.find((level) => value >= level);
  signalLevel = SIGNAL_LEVELS.indexOf(signalLevel);
  // eslint-disable-next-line no-bitwise
  if (~signalLevel) {
    return signalLevel + 1;
  }
  return 0;
};

function DeviceSignalContainer({
  networkSignal,
  networkName,
  networkSignalUnit,
  showLabel = true,
  iconLeft = false,
  sx,
  hasWifi
}: SignalContainerType) {
  const { t } = useLocales();

  const level = getSignalLevel(networkSignal);

  if (hasWifi) {
    return (
      <WifiSignalLevel
        value={networkSignal}
        level={level}
        title={networkName}
        iconLeft={iconLeft}
        tooltipTitle={
          networkSignal
            ? t('thiamis.more_info.wifi_signal', {
                network: networkName || t('base.labels.n_a'),
                value: networkSignal ? `${networkSignal} ${networkSignalUnit || ''}` : t('base.labels.n_a')
              })
            : t('thiamis.more_info.wifi_signal_na')
        }
        showLabel={showLabel}
        sx={sx}
      />
    );
  }

  return (
    <GsmSignalLevel
      value={networkSignal}
      level={level}
      title={networkName}
      iconLeft={iconLeft}
      tooltipTitle={t('thiamis.more_info.gsm_signal_with_network', {
        network: networkName,
        value: networkSignal ? `${networkSignal}${networkSignalUnit || ''}` : t('base.labels.n_a')
      })}
      showLabel={showLabel}
      sx={sx}
    />
  );
}

export default DeviceSignalContainer;

/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-props-no-spreading */
import { DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid';
import TimeAgoFormatter from '~/components/react_grid/time_ago_formatter';

import PictureFormatter from './picture_formatter';
import RolesFormatter from './roles_formatter';
import ActionsFormatter from './actions_formatter';

export function ActionsProvider(props) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <DataTypeProvider {...props} formatterComponent={ActionsFormatter} />;
}

export function CreatedAtProvider(props: DataTypeProviderProps) {
  return <DataTypeProvider {...props} formatterComponent={TimeAgoFormatter} />;
}

export function PictureProvider(props: DataTypeProviderProps) {
  return <DataTypeProvider {...props} formatterComponent={PictureFormatter} />;
}

export function RolesProvider(props: DataTypeProviderProps) {
  return <DataTypeProvider {...props} formatterComponent={RolesFormatter} />;
}

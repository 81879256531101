import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Grid, Stack } from '@mui/material';
import Modal from '~/components/modal';
import useModal from '~/hooks/use_modal';
import useLocales from '~/hooks/use_locales';
import Filters from '~/components/filters';
import ButtonAdaptive from '~/components/adaptive_button';
import useMst from '~/hooks/use_mst';

import CreateApiForm from './create_api_form';

function FilterBar() {
  const { t } = useLocales();
  const { open, handleClose, handleOpen } = useModal();
  const { tokens } = useMst();

  return (
    <Grid container spacing={2} sx={{ py: 2, px: 3 }}>
      <Grid item xs={12} sm={4} lg={6} md={6}>
        <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }}>
          <Filters collection={tokens} />
        </Stack>
      </Grid>
      <Grid item xs={12} sm={8} lg={6} md={6} sx={{ textAlign: 'right' }}>
        <ButtonAdaptive variant="contained" size="large" startIcon={<AddIcon />} $mobileFullWidth breakpoint="md" onClick={handleOpen}>
          {t('api.create_token')}
        </ButtonAdaptive>
        <Modal title={t('api.create_token')} open={open} handleClose={handleClose}>
          <CreateApiForm />
        </Modal>
      </Grid>
    </Grid>
  );
}

export default FilterBar;

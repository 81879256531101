/* eslint-disable react/prop-types */
import { useState, useEffect, useRef } from 'react';
import { autorun } from 'mobx';
import { observer } from 'mobx-react-lite';
import Highcharts from 'highcharts';
import XrangeModule from 'highcharts/modules/xrange';
import { useTheme } from '@mui/material/styles';
import NoData from 'highcharts/modules/no-data-to-display';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExporting from 'highcharts/modules/exporting';
import useChart from '~/hooks/use_chart';
import type { IAlert } from '~/mst/models/alert';
import getChartOptions from './options';
import type { IChartTableWidget } from '../store/chart_table_widget';

HighchartsMore(Highcharts);
XrangeModule(Highcharts);
NoData(Highcharts);
HighchartsExporting(Highcharts);

Highcharts.setOptions({
  time: {
    useUTC: false
  }
});

function AlertChart({ widget, alert }: { widget: IChartTableWidget; alert: IAlert }) {
  const chartRef = useRef<HighchartsReact.RefObject>(null);
  const { setChartRef } = useChart();
  const [chartOptions, setChartOptions] = useState(null);
  const theme = useTheme();

  useEffect(
    () =>
      autorun(() => {
        if (widget.data_points?.isFetched) {
          setChartOptions(getChartOptions({ theme: theme.palette.mode, data: widget.chartData, categories: widget.chartCategories, widget, alert }));
        }
      }),
    [widget, alert, theme.palette.mode]
  );

  useEffect(() => {
    if (chartRef.current) {
      setChartRef(chartRef);
    }
  }, [chartRef, setChartRef]);

  useEffect(
    () =>
      autorun(() => {
        if (chartRef.current) {
          if (widget?.data_points?.isFetching) {
            chartRef.current.chart?.showLoading();
          } else {
            chartRef.current.chart?.hideLoading();
          }
        }
      }),
    [widget?.data_points?.isFetched, widget?.data_points?.isFetching, widget.chartData]
  );

  return <HighchartsReact ref={chartRef} highcharts={Highcharts} options={chartOptions} containerProps={{ style: { width: '100%' } }} />;
}

export default observer(AlertChart);

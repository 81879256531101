import React, { useMemo } from 'react';
import { Responsive } from 'react-grid-layout';
import { useTheme } from '@mui/system';
import { observer } from 'mobx-react-lite';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import WidgetComponent from './widget_wrapper';
import WidthProvider, { WidgetsContainerProps } from './width_provider';

const ResponsiveGridLayout = WidthProvider(Responsive);

const LAYOUT_MARGIN = [24, 24];

function GridLayout({ editable, widgets, dashboard }: WidgetsContainerProps) {
  const { breakpoints } = useTheme();

  const children = useMemo(
    () =>
      widgets?.toArray?.map((widget) => (
        <div key={`${widget.id}`}>
          <WidgetComponent widget={widget} moveable={Boolean(editable)} editable={editable} dashboard={dashboard} />
        </div>
      )),
    [widgets?.toArray, editable, dashboard]
  );

  // inline negative margin to remove the margins introduced by grid layout for whole container
  return (
    <div id="widgets-region" style={{ margin: '-20px' }}>
      <ResponsiveGridLayout
        isBounded
        rowHeight={100}
        layouts={widgets?.widgetLayouts}
        margin={LAYOUT_MARGIN}
        isDraggable={false}
        isResizable={false}
        isDroppable={false}
        breakpoints={breakpoints.values}
        cols={{
          xl: 16,
          lg: 12,
          md: 8,
          sm: 4,
          xs: 4
        }}
      >
        {children}
      </ResponsiveGridLayout>
    </div>
  );
}

export default WidthProvider(observer(GridLayout));

import { STATUS_WEIGHTS } from '~/utils/constants';
import uniq from 'lodash/uniq';
import compact from 'lodash/compact';
import filter from 'lodash/fp/filter';
import flow from 'lodash/fp/flow';
import map from 'lodash/fp/map';
import sortBy from 'lodash/sortBy';

import type { IHeatmapWidget } from '.';

export default (self: IHeatmapWidget) => ({
  get selectedPath() {
    if (self.selected_path?.includes(':')) {
      // Compatibility with old data
      const nodeId = self.node_id?.find((id) => !!self.nodes?.getDataPointByNodeIdAndPath(id, self.selected_path));
      return self.nodes?.getDataPointByNodeIdAndPath(nodeId, self.selected_path)?.presentName;
    }
    return self.selected_path;
  },
  get data() {
    const result = compact(self.node_id.map((id) => self.nodes?.getById(id))).filter(
      (node) => node?.data_points?.getByPresentName(self.selectedPath)?.hasMeeasurements
    );
    if (self.filter) {
      return result.filter((node) => {
        const dataPoint = node.data_points?.getByPresentName(self.selectedPath);
        return dataPoint?.statusType(dataPoint?.avg) === self.filter;
      });
    }
    return result;
  },
  get legend() {
    return flow(
      filter((id) => self.nodes?.getDataPointByNodeIdAndPresentName(id, self.selectedPath)?.hasMeeasurements),
      map((id) => {
        const dataPoint = self.nodes?.getDataPointByNodeIdAndPresentName(id, self.selectedPath);
        return dataPoint?.statusType(dataPoint?.avg);
      }),
      compact,
      uniq,
      sortBy((a, b) => STATUS_WEIGHTS[b] - STATUS_WEIGHTS[a])
    )(self.node_id);
  }
});

/* eslint-disable @typescript-eslint/no-use-before-define */
import { types as t, Instance } from 'mobx-state-tree';
import Thiamis from '~/mst/models/device/thiamis';
import BitConfigType from '~/mst/common/bit_config_type';

const HealthwayModel = t
  .compose(
    Thiamis,
    t.model({
      automation: t.maybeNull(BitConfigType),
      dfs: t.maybeNull(BitConfigType),
      speed: t.maybeNull(t.number),
      cron: t.maybeNull(t.frozen())
    })
  )
  .named('Healthway');

export interface IHealthwayModel extends Instance<typeof HealthwayModel> {}

export default HealthwayModel;

import isEmpty from 'lodash/isEmpty';

export const preProcessor = (values: any) => {
  if (!isEmpty(values.user_calibration)) {
    return {
      ...values,
      user_calibration: Object.entries(values.user_calibration)
        .map(([id, params]) => ({ id, params }))
        .filter(({ id }) => id.split(':').length === 2) // make sure it's path
    };
  }
  return values;
};

export const postProcessor = (sn: any) => {
  if (!isEmpty(sn.user_calibration)) {
    return {
      ...sn,
      user_calibration: Object.fromEntries(sn.user_calibration.map(({ id, params }) => [id, params]))
    };
  }
  return sn;
};

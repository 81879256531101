import isString from 'lodash/isString';
import isNil from 'lodash/isNil';
import { types as t } from 'mobx-state-tree';

export default t.custom({
  name: 'MaybeString',
  fromSnapshot: (value: string) => value || '',
  toSnapshot: (value: string) => value,
  isTargetType: (maybeString) => isString(maybeString) || isNil(maybeString),
  getValidationMessage: (snapshot) => {
    if (!snapshot) return '';
    if (!isString(snapshot) || !isNil(snapshot)) {
      const message = `"${snapshot}" is not a valid string value`;
      return message;
    }
    return '';
  }
});

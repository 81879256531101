import type { IUser } from '~/mst/models/user';
import UserAvatar from '~/components/user_avatar';

function PictureFormatter({ row = {} as IUser }: { row?: IUser }) {
  return <UserAvatar user={row} />;
}

PictureFormatter.defaultProps = {
  row: {} as IUser
};

export default PictureFormatter;

/* eslint-disable import/prefer-default-export */
import { styled } from '@mui/material';
import { GridStyled } from '~/components/react_grid/styled';

export const GridStyledNoWrap = styled(GridStyled)(() => ({
  '.MuiTableCell-root.MuiTableCell-body': {
    wordWrap: 'break-word',
    whiteSpace: 'normal !important'
  }
}));

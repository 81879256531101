/* eslint-disable react/jsx-props-no-spreading */
import kebabCase from 'lodash/kebabCase';
import DeviceIcons from '~/assets/icons/devices';
import DefaultIcon from '~/assets/icons/devices/default-sensor.svg';

function useSvgLoader(name: string, fallback?: string) {
  const iconName = kebabCase(name);
  const fallbackIconName = kebabCase(fallback);
  if (DeviceIcons[iconName]) {
    // eslint-disable-next-line func-names
    return function (props) {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <img src={DeviceIcons[iconName]} {...props} />;
    };
  }
  if (DeviceIcons[fallbackIconName]) {
    // eslint-disable-next-line func-names
    return function (props) {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <img src={DeviceIcons[fallbackIconName]} {...props} />;
    };
  }
  return function (props) {
    // eslint-disable-next-line jsx-a11y/alt-text
    return <img src={DefaultIcon} {...props} />;
  };
}

export default useSvgLoader;

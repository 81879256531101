import React, { ReactElement } from 'react';
import { Wrapper as MapWrapper } from '@googlemaps/react-wrapper';
import { Box } from '@mui/material';
import { observer } from 'mobx-react-lite';
import pick from 'lodash/pick';
import get from 'lodash/get';
import { WidgetPropType } from '~/components/widgets/common/types';
import LoadingBox from '~/components/loading_box';
import Map from './map';
import AddressBar from './address_bar';
import StaticMap from './static_map';

const { VITE_GOOGLE_MAPS_KEY } = process.env;

export const render = (): ReactElement => {
  return <LoadingBox />;
};

export const MapObservable = observer(({ nodes, mapOptions, isStatic, showAddressBar, isPinnable, mapId, isLoading }) => {
  const location = nodes?.first?.thiamis?.locationLatLng;

  return (
    <Box sx={{ position: 'relative', width: '100%', height: '100%', opacity: isLoading ? 0.6 : 1 }}>
      {showAddressBar && nodes.size === 1 && location && (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <AddressBar {...pick(get(nodes, 'first.thiamis', {}), 'locationLatLng', 'locationDescription', 'hasWiFi', 'hasManualLocation')} />
      )}
      {isStatic ? (
        <StaticMap location={location} status={nodes?.first?.statusType()} mapId={mapId} />
      ) : (
        <Map nodes={nodes?.filteredListMap} options={mapOptions} isPinnable={isPinnable} mapId={mapId} />
      )}
    </Box>
  );
});

function MapWidget({ widget, mapOptions, isStatic, showAddressBar }: WidgetPropType) {
  return (
    <MapWrapper apiKey={VITE_GOOGLE_MAPS_KEY} render={render} libraries={['places', 'marker']}>
      <MapObservable nodes={widget?.nodes} mapId="e2b1b4ca24b54ab8" mapOptions={mapOptions} showAddressBar={showAddressBar} isStatic={isStatic} />
    </MapWrapper>
  );
}

export default MapWidget;

import { types as t, Instance } from 'mobx-state-tree';
import CollectionMap from '~/mst/models/abstract/collection_map';
import TotalDevicesOnlinePieChartWidget from '../../widgets/total_devices_online_pie_chart_widget/store';
import TotalDevicesOnlineColumnChartWidget from '../../widgets/total_devices_online_column_chart_widget/store';
import RecentAlertsWidget from '../../widgets/recent_alerts_widget/store';
import DevicesOnlineChartWidget from '../../widgets/devices_online_chart_widget/store';

const WidgetsModel = CollectionMap(
  t.union(
    {
      dispatcher(snapshot) {
        const { type } = snapshot;
        switch (type) {
          case 'RecentAlertsWidget':
            return RecentAlertsWidget;
          case 'DevicesOnlineChartWidget':
            return DevicesOnlineChartWidget;
          case 'TotalDevicesOnlineColumnChartWidget':
            return TotalDevicesOnlineColumnChartWidget;
          case 'TotalDevicesOnlinePieChartWidget':
            return TotalDevicesOnlinePieChartWidget;
          default:
            throw new Error(`Unkown widget type ${type}`);
        }
      }
    },

    TotalDevicesOnlinePieChartWidget,
    TotalDevicesOnlineColumnChartWidget,
    RecentAlertsWidget,
    DevicesOnlineChartWidget
  )
);

export interface IWidgetsModel extends Instance<typeof WidgetsModel> {}

export default WidgetsModel;

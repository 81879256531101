/* eslint-disable react/no-unstable-nested-components */
import React, { useMemo } from 'react';
import WidgetContainer from '~/components/widgets/common';
import { WidgetPropType } from '~/components/widgets/common/types';
import { IMapWidget } from '~/mst/models/widget/map_widget';
import useModal from '~/hooks/use_modal';
import { observer } from 'mobx-react-lite';
import MapWidgetContent from './content';
import DeviceWidgetTitle from '../common/device_widget_title';
import MapWidgetConfiguration from './configuration';

function MapWidget({ widget, moveable, editable, dashboard, mapOptions, isStatic, showAddressBar }: WidgetPropType<IMapWidget>) {
  const { open, handleOpen, handleClose } = useModal();

  const sx = useMemo(
    () => (moveable || editable ? {} : { '.MuiCardHeader-root': { display: 'none' }, '.MuiCardContent-root:last-child': { pb: 0 }, p: 0 }),
    [moveable, editable]
  );

  return (
    <WidgetContainer
      sx={sx}
      widget={widget}
      moveable={moveable}
      editable={editable}
      onEdit={handleOpen}
      Header={<DeviceWidgetTitle node={widget?.node} />}
      dashboard={dashboard}
      sxWidgetBody={{ padding: 0, overflowY: 'hidden' }}
    >
      <MapWidgetContent widget={widget} dashboard={dashboard} mapOptions={mapOptions} isStatic={isStatic} showAddressBar={showAddressBar} />
      <MapWidgetConfiguration open={open} onClose={handleClose} widget={widget} />
    </WidgetContainer>
  );
}

export default observer(MapWidget);

import { Grid, VirtualTable, TableHeaderRow, PagingPanel } from '@devexpress/dx-react-grid-material-ui';
import { SortingState, PagingState, IntegratedSorting, IntegratedPaging } from '@devexpress/dx-react-grid';

import { observer } from 'mobx-react-lite';
import { GridStyled } from '~/components/react_grid/styled';
import useSorting from '~/hooks/react_grid/use_sorting';
import useLoading from '~/hooks/react_grid/use_loading';
import usePaging from '~/hooks/react_grid/use_paging';
import useFetch from '~/hooks/use_fetch';
import useMst from '~/hooks/use_mst';
import i18n from '~/locales/i18n';
import useLocales from '~/hooks/use_locales';
import { DeviceScopeProvider, TimeAgoProvider, TokenProvider, PermissionsProvider, ActionsProvider } from './info_providers';

const columns = [
  { name: 'external_id', title: i18n.t('api.token'), sortingEnabled: false },
  { name: 'ownerName', title: i18n.t('api.organization') },
  { name: 'permissions', title: i18n.t('api.permissions') },
  { name: 'requests_number', title: i18n.t('api.requests_number') },
  { name: 'last_used_at', title: i18n.t('api.last_used_at') },
  { name: 'created_at', title: i18n.t('api.created') },
  { name: 'actions', title: ' ', sortingEnabled: false }
];

function TokensGrid() {
  const { tokens, users } = useMst();
  const { t } = useLocales();

  useFetch(users);
  useFetch(tokens);

  const { messages, RootComponent } = useLoading(tokens);

  const { sorting, handleSortingChange } = useSorting([{ columnName: t('api.created'), direction: 'desc' }]);
  const { pageSizes, pageSize, handlePageSizeChange } = usePaging();

  const data = tokens.filteredList;

  return (
    <GridStyled>
      <Grid rows={data} columns={columns} rootComponent={RootComponent}>
        <SortingState sorting={sorting} onSortingChange={handleSortingChange} />
        <IntegratedSorting />
        <PagingState defaultCurrentPage={0} pageSize={pageSize} onPageSizeChange={handlePageSizeChange} />
        <IntegratedPaging />
        <TimeAgoProvider />
        <DeviceScopeProvider />
        <TokenProvider />
        <PermissionsProvider />
        <ActionsProvider />
        <VirtualTable
          messages={messages}
          height="auto"
          columnExtensions={[
            {
              columnName: 'actions',
              width: 60
            },
            {
              columnName: 'device_scope',
              width: 150
            },
            {
              columnName: 'created_at',
              width: 150
            },
            {
              columnName: 'requests_number',
              width: 150
            },
            {
              columnName: 'last_used_at',
              width: 150
            }
          ]}
        />
        <TableHeaderRow showSortingControls />
        {data.length > pageSize && <PagingPanel pageSizes={pageSizes} />}
      </Grid>
    </GridStyled>
  );
}

export default observer(TokensGrid);

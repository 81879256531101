import startsLodash from 'lodash/startsWith';
import endsLodash from 'lodash/endsWith';

type OperationNumberFunction = (v1: number, v2: number) => boolean;
type OperationFunction = (v1: string, v2: string) => boolean;

export const equal: OperationNumberFunction = (v1, v2) => {
  return v1 === v2;
};
export const notEqual: OperationNumberFunction = (v1, v2) => {
  return v1 !== v2;
};
export const greaterThan: OperationNumberFunction = (v1, v2) => {
  return v1 > v2;
};
export const greaterThanOrEqual: OperationNumberFunction = (v1, v2) => {
  return v1 >= v2;
};
export const lessThan: OperationNumberFunction = (v1, v2) => {
  return v1 < v2;
};
export const lessThanOrEqual: OperationNumberFunction = (v1, v2) => {
  return v1 <= v2;
};
export const contains: OperationFunction = (v1, v2) => {
  return v1.includes(v2);
};
export const startsWith: OperationFunction = (v1, v2) => {
  return startsLodash(v1, v2);
};
export const endsWith: OperationFunction = (v1, v2) => {
  return endsLodash(v1, v2);
};

/* eslint-disable */
import React from 'react';
import { IconProps } from '../types';

export default function DeviceWidgetIcon({ sx }: IconProps) {
  return (
    <svg width={sx ? sx.width : '100%'} height={sx ? sx.height : '100%'} viewBox="0 0 36 31" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_42_5920)">
        <g clipPath="url(#clip1_42_5920)">
          <path
            d="M34.8896 0H8.77957C8.24385 0 7.80957 0.434284 7.80957 0.97V5.06C7.80957 5.59572 8.24385 6.03 8.77957 6.03H34.8896C35.4253 6.03 35.8596 5.59572 35.8596 5.06V0.97C35.8596 0.434284 35.4253 0 34.8896 0Z"
            fill="#C0D5E8"
          />
          <path
            d="M3.01 6.02C4.67238 6.02 6.02 4.67238 6.02 3.01C6.02 1.34762 4.67238 0 3.01 0C1.34762 0 0 1.34762 0 3.01C0 4.67238 1.34762 6.02 3.01 6.02Z"
            fill="#7998B5"
          />
          <path
            d="M34.8896 24.78H8.77957C8.24385 24.78 7.80957 25.2143 7.80957 25.75V29.84C7.80957 30.3757 8.24385 30.81 8.77957 30.81H34.8896C35.4253 30.81 35.8596 30.3757 35.8596 29.84V25.75C35.8596 25.2143 35.4253 24.78 34.8896 24.78Z"
            fill="#C0D5E8"
          />
          <path
            d="M3.01 24.78C1.35 24.78 0 26.13 0 27.79C0 29.45 1.35 30.8 3.01 30.8C4.67 30.8 6.02 29.45 6.02 27.79C6.02 26.13 4.67 24.78 3.01 24.78Z"
            fill="#7998B5"
          />
          <path
            d="M34.8896 16.52H8.77957C8.24385 16.52 7.80957 16.9543 7.80957 17.49V21.58C7.80957 22.1157 8.24385 22.55 8.77957 22.55H34.8896C35.4253 22.55 35.8596 22.1157 35.8596 21.58V17.49C35.8596 16.9543 35.4253 16.52 34.8896 16.52Z"
            fill="#C0D5E8"
          />
          <path
            d="M3.01 16.52C1.35 16.52 0 17.87 0 19.53C0 21.19 1.35 22.54 3.01 22.54C4.67 22.54 6.02 21.19 6.02 19.53C6.02 17.87 4.67 16.52 3.01 16.52Z"
            fill="#7998B5"
          />
          <path
            d="M34.8896 8.26001H8.77957C8.24385 8.26001 7.80957 8.69429 7.80957 9.23001V13.32C7.80957 13.8557 8.24385 14.29 8.77957 14.29H34.8896C35.4253 14.29 35.8596 13.8557 35.8596 13.32V9.23001C35.8596 8.69429 35.4253 8.26001 34.8896 8.26001Z"
            fill="#C0D5E8"
          />
          <path
            d="M3.01 14.29C4.67238 14.29 6.02 12.9424 6.02 11.28C6.02 9.61764 4.67238 8.27002 3.01 8.27002C1.34762 8.27002 0 9.61764 0 11.28C0 12.9424 1.34762 14.29 3.01 14.29Z"
            fill="#7998B5"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_42_5920">
          <rect width="35.86" height="30.81" fill="white" />
        </clipPath>
        <clipPath id="clip1_42_5920">
          <rect width="35.86" height="30.81" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en';
import zh from './zh';
import es from './es';
import pt from './pt';

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: en },
    zh: { translation: zh },
    es: { translation: es },
    pt: { translation: pt }
  },
  lng: 'en',
  interpolation: { prefix: '%{', suffix: '}', escapeValue: false },
  fallbackLng: 'en',
  nsSeparator: '::'
});

export default i18n;

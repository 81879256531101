/* eslint-disable react/jsx-props-no-spreading */
import { SortingState, IntegratedSorting, RowDetailState, SelectionState, IntegratedSelection } from '@devexpress/dx-react-grid';
import { Grid, VirtualTable, TableHeaderRow, TableSelection, TableRowDetail } from '@devexpress/dx-react-grid-material-ui';
import { observer } from 'mobx-react-lite';

import useSelection from '~/hooks/react_grid/use_selection';
import useLoading from '~/hooks/react_grid/use_loading';
import useSorting from '~/hooks/react_grid/use_sorting';
import useMst from '~/hooks/use_mst';
import useExpandRow from '~/hooks/react_grid/use_expand_row';
import I18n from '~/utils/i18n';
import { CALIBRATION_STATUS_WEIGHTS } from '~/utils/constants';

import { GridStyled } from '~/components/react_grid/styled';
import { Container } from '~/components/react_grid';

import { LastRunProvider, StatusTypeProvider, ParametersTypeProvider, ReadyTypeProvider } from './info_providers';
import GridDetailContainer from './detail_row';

const compareStatuses = (a, b) => {
  const statusA = CALIBRATION_STATUS_WEIGHTS[a];
  const statusB = CALIBRATION_STATUS_WEIGHTS[b];
  if (statusA === statusB) {
    return 0;
  }
  return statusA < statusB ? -1 : 1;
};

function compareDates(a: number, b: number) {
  // @ts-ignore
  return (b != null) - (a != null) || b - a;
}

const columns = [
  { name: 'serial', title: I18n.t('attributes.calibration.serial') },
  { name: 'parameters', title: I18n.t('attributes.calibration.parameters') },
  { name: 'status', title: I18n.t('attributes.calibration.status') },
  { name: 'ready', title: I18n.t('attributes.calibration.ready') },
  { name: 'timestamp', title: I18n.t('attributes.calibration.timestamp') }
];

const getRowId = ({ node_id }) => node_id;

function GridContainer() {
  const { selection, setSelection } = useSelection();
  const { expandedRowIds, handleSetExpandedRowIds, Row } = useExpandRow();
  const { sorting, handleSortingChange } = useSorting([
    { columnName: 'status', direction: 'desc' },
    { columnName: 'timestamp', direction: 'desc' }
  ]);

  const { calibrations } = useMst();
  const { messages, RootComponent } = useLoading(calibrations);

  return (
    <GridStyled>
      <Grid rows={calibrations.filteredList} columns={columns} getRowId={getRowId} rootComponent={RootComponent}>
        <SortingState sorting={sorting} onSortingChange={handleSortingChange} />
        <IntegratedSorting
          columnExtensions={[
            { columnName: 'status', compare: compareStatuses },
            { columnName: 'timestamp', compare: compareDates }
          ]}
        />
        <SelectionState selection={selection} onSelectionChange={setSelection} />
        <IntegratedSelection />
        <LastRunProvider for={['timestamp']} />
        <ParametersTypeProvider for={['parameters']} />
        <StatusTypeProvider for={['status']} />
        <ReadyTypeProvider for={['ready']} />
        <RowDetailState expandedRowIds={expandedRowIds} onExpandedRowIdsChange={handleSetExpandedRowIds} />
        <VirtualTable
          height="auto"
          messages={messages}
          containerComponent={Container}
          columnExtensions={[{ columnName: 'parameters', width: 400 }]}
        />
        <TableSelection showSelectAll highlightRow rowComponent={Row} />
        <TableRowDetail rowComponent={GridDetailContainer} />
        <TableHeaderRow showSortingControls />
      </Grid>
    </GridStyled>
  );
}

export default observer(GridContainer);

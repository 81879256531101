import { DateTime } from 'luxon';
import isString from 'lodash/isString';
import isNumber from 'lodash/isNumber';
import useLocales from '~/hooks/use_locales';

function TimeAgoFormatter({ value }: { value: number | string }) {
  const { t } = useLocales();
  if (isNumber(value) && DateTime.fromMillis(value)?.isValid) {
    return DateTime.fromMillis(value).toRelative();
  }
  if (isString(value) && DateTime.fromISO(value)?.isValid) {
    return DateTime.fromISO(value).toRelative();
  }
  return <span>{t('alerts.triggered_never')}</span>;
}

export default TimeAgoFormatter;

import { Instance } from 'mobx-state-tree';
import CollectionArrayProcessor from '~/mst/models/abstract/collection_array_processor';
import model from './model';
import views from './views';
import actions from './actions';

const DevicesStore = model.views(views).actions(actions);

export interface IDevicesStore extends Instance<typeof DevicesStore> {}

export default CollectionArrayProcessor(DevicesStore);

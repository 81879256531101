import React, { useContext, useState, useCallback, useMemo, ReactNode } from 'react';

const stub = (): never => {
  throw new Error('You forgot to wrap your component in <ActionProvider>.');
};

export type ActionState = {
  action: string | null;
  setAction: (value: string | null) => void;
  openMenu: () => void;
  closeMenu: () => void;
  toggleMenu: () => void;
  isOpen: boolean;
};

const initialState = {
  action: null,
  isOpen: false,
  setAction: stub,
  closeMenu: stub,
  toggleMenu: stub,
  openMenu: stub
} as ActionState;

const ActionContext = React.createContext(initialState);

function ActionProvider({ children }: { children: ReactNode }) {
  const [action, setAction] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleSetAction = useCallback((newAction: string) => setAction(newAction), [setAction]);

  const handleClose = useCallback(() => setIsOpen(false), [setIsOpen]);
  const handleOpen = useCallback(() => setIsOpen(true), [setIsOpen]);
  const handleToggleMenu = useCallback(() => setIsOpen((prevOpen) => !prevOpen), [setIsOpen]);

  const value = useMemo(
    () => ({
      action,
      setAction: handleSetAction,
      isOpen,
      closeMenu: handleClose,
      toggleMenu: handleToggleMenu,
      openMenu: handleOpen
    }),
    [action, isOpen, handleSetAction, handleOpen, handleClose, handleToggleMenu]
  );

  return <ActionContext.Provider value={value}>{children}</ActionContext.Provider>;
}

export { ActionProvider, ActionContext };

const useActions = () => useContext(ActionContext);

export default useActions;

import * as Yup from 'yup';
import { DateTime } from 'luxon';
import isNumber from 'lodash/isNumber';
import { isValidPhoneNumber } from 'react-phone-number-input';

Yup.addMethod(Yup.string, 'phoneNumber', function phoneNumberValidator(errorMessage) {
  return this.test(`test-phone-number`, errorMessage, function validate(value) {
    const { path, createError } = this;

    return (value && isValidPhoneNumber(value)) || createError({ path, message: errorMessage });
  });
});

export const searchableSelectSchema = (message: string, matches?: { regexp: RegExp; message: string }) =>
  Yup.lazy((value): any => {
    switch (typeof value) {
      case 'object':
        return Yup.object().required(message).typeError(message);
      case 'string': {
        const valid = Yup.string().required(message);
        if (matches) {
          return valid.matches(matches.regexp, matches.message);
        }
        return valid;
      }
      default:
        return Yup.mixed();
    }
  });

export function parseDate(value: Date, originalValue: number) {
  if (isNumber(originalValue) && DateTime.fromMillis(originalValue)?.isValid) {
    return DateTime.fromMillis(originalValue).toJSDate();
  }
  return new Date();
}

export default Yup;

import { Grid } from '@mui/material';
import { observer } from 'mobx-react-lite';
import useMst from '~/hooks/use_mst';
import Filters from '~/components/filters';
import { ActionsWrapperStyled } from '~/components/actions_dropdown/styled';
import { ActionProvider } from '~/components/actions_dropdown/context';

import ActionsContainer from './actions_container';

const alertFilterGroups = ['alert-status'];
const statusCustomSorting = {
  'alert-status': {
    active: 0,
    resolved: 1,
    idle: 2,
    deactivated: 3
  }
};

function FormTop() {
  const { alerts } = useMst();

  return (
    <Grid container spacing={2} sx={{ py: 2, px: 3 }}>
      <Grid item xs={12} sm={4} lg={6} md={6}>
        <Filters collection={alerts} groups={alertFilterGroups} sorting={statusCustomSorting} />
      </Grid>

      <Grid item xs={12} sm={8} lg={6} md={6} sx={{ textAlign: 'right' }}>
        <ActionsWrapperStyled spacing={2} direction="row">
          <ActionProvider>
            <ActionsContainer />
          </ActionProvider>
        </ActionsWrapperStyled>
      </Grid>
    </Grid>
  );
}

export default observer(FormTop);

/* eslint-disable import/prefer-default-export */
import request from '../request';

export function fetch(params, options) {
  return [request, 'get', 'webhooks', params, options];
}

export function create(payload: any) {
  return request('post', `webhooks`, payload);
}

export function toggle(id: string, action: 'enable' | 'disable') {
  return request('put', `webhooks/${id}/${action}`);
}

export function destroy(id: string) {
  return request('delete', `webhooks/${id}`);
}

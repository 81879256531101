/* eslint-disable @typescript-eslint/naming-convention */
import type { IPartnership } from '../partnership';
import type { IPartnershipsModel } from './model';

export default (self: IPartnershipsModel) => ({
  get filteredList() {
    return self.filteredModels.map((partnership: IPartnership) => ({
      id: partnership.id,
      created_at: partnership.created_at,
      partnerId: partnership.partnerId,
      partnerName: partnership.partnerName,
      partnerExternalId: partnership.partnerExternalId,
      organizationId: partnership.organizationId,
      organizationName: partnership.organizationName,
      organizationExternalId: partnership.organizationExternalId
    }));
  }
});

import React, { useCallback, useEffect } from 'react';
import { Stack, Box, IconButton, MenuItem } from '@mui/material';
import { observer } from 'mobx-react-lite';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { observable, action, autorun } from 'mobx';

import Trendline from '~/components/trendline';
import useActionsPopover from '~/hooks/react_grid/use_actions_popover';
import type { IDataPointNode } from '~/mst/models/data_point/node/default';
import withAutoupdate from '~/hocs/with_autoupdate';
import useFetch from '~/hooks/use_fetch';
import { StatusIconWithAutoUpdate } from '~/components/widgets/node_widget/content/name_formatter';
import type { IDeviceMonitor } from '../../store';
import { ParamItemContainerStyled, BoxOverflowedStyled, ParamLabelStyled } from './styled';

type ParamItemProps = {
  dataPoint: IDataPointNode;
  report: IDeviceMonitor;
  isExpanded: boolean;
};

const params = observable(
  {
    from: null,
    to: null,
    setParams({ from, to }) {
      this.from = from;
      this.to = to;
    }
  },
  {
    setParams: action
  }
);

function ParamItem({ isExpanded, dataPoint, report }: ParamItemProps) {
  const { data, shortPresentName, lastValue, unit, _id: dataPointId } = dataPoint;
  const { ActionsMenu, handleClose, handleOpen } = useActionsPopover();
  const isSelected = report.isParamSelected(dataPointId);

  const chartDataPoint = report?.chartWidget?.node?.data_points?.getById(dataPointId);

  useEffect(
    () =>
      autorun(() => {
        params.setParams(report.range.fetchParams());
      }),
    [report.range]
  );

  useFetch(isSelected ? chartDataPoint : null, params);

  const handleClick = useCallback(() => {
    report.toggleParameter(dataPointId);
  }, [report, dataPointId]);

  const handleDeleteMetricClick = useCallback(
    async (event) => {
      event.preventDefault();
      event.stopPropagation();
      await dataPoint.destroy();
      handleClose();
    },
    [dataPoint, handleClose]
  );

  return (
    <ParamItemContainerStyled
      data-dd-action-name={`Click Parameter ${dataPoint?.presentName} on ${report?.selected_tab}`}
      direction="row"
      title={dataPoint.presentName}
      alignItems="center"
      justifyContent="space-between"
      onClick={handleClick}
      $isActive={isSelected}
      $isExpanded={isExpanded}
      $isOffline={dataPoint?.isOnline() === false}
      $isSyncing={chartDataPoint?.isFetching || dataPoint?.isSyncing}
    >
      <Stack direction="row" alignItems="center" sx={{ flex: '1 0 140px', maxWidth: '150px', overflow: 'hidden', paddinng: '8px' }}>
        <StatusIconWithAutoUpdate model={dataPoint} />
        <BoxOverflowedStyled>
          <ParamLabelStyled variant="body2">
            <span className="paramName">{shortPresentName}</span>
            <div className="paramValue">
              {lastValue} <small>{unit}</small>
            </div>
          </ParamLabelStyled>
        </BoxOverflowedStyled>
      </Stack>
      <Stack direction="row" alignItems="center" sx={{ width: '40%', height: '25px', alignSelf: 'end' }}>
        {Boolean(data?.length) && <Trendline dataPoint={dataPoint} data={data} />}
      </Stack>
      {dataPoint.isMetric && (
        <Box>
          <IconButton onClick={handleOpen} sx={{ position: 'absolute', top: 0, right: 0, opacity: 0 }} data-dd-action-name="Add Metric Parameter">
            <MoreVertIcon />
          </IconButton>
          <ActionsMenu
            menuProps={{
              arrow: false
            }}
          >
            <MenuItem onClick={handleDeleteMetricClick} data-dd-action-name="Delete Metric Parameter">
              Delete
            </MenuItem>
          </ActionsMenu>
        </Box>
      )}
    </ParamItemContainerStyled>
  );
}

export default withAutoupdate(observer(ParamItem));

import React, { useMemo } from 'react';
import { Stack, Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import useNavigate from '~/hooks/use_navigate';
import useLocales from '~/hooks/use_locales';
import useMst from '~/hooks/use_mst';
import useFetch from '~/hooks/use_fetch';
import useAuth from '~/hooks/use_auth';
import useReportSubscribe from '~/hooks/use_dashboard_subscribe';
import { SelectionProvider } from '~/hooks/react_grid/use_selection';
import { ExportProvider } from '~/hooks/react_grid/use_export';
import { ChartProvider } from '~/hooks/use_chart';

import Container from '~/components/container';
import Breadcrumbs from '~/components/breadcrumbs';
import NavigateDropdown from '~/components/navigate_dropdown/devices_dropdown';
import ActionsButton from '~/pages/devices/form_top/actions_button';

import MonitorLayout from './layout';
import DeviceAdminModel from './store';

function DeviceAdmin() {
  const { id } = useParams();
  const { t } = useLocales();
  const { navigate } = useNavigate();
  const { auth } = useAuth();
  const { nodes } = useMst();

  const { isFetching } = useFetch(nodes);

  const node = nodes.getById(id);

  const report = useMemo(
    () =>
      DeviceAdminModel.create(
        {
          range: {
            from: '30_minutes'
          },
          widgets: [
            {
              id: 'debugWidget',
              type: 'DebugWidget',
              node_id: id
            },
            {
              id: 'nodeWidget',
              type: 'NodeWidget',
              node_id: id
            },
            {
              id: 'filesWidget',
              type: 'FilesWidget',
              serial: node?.serial
            }
          ]
        },
        { auth }
      ),
    [id, auth, node?.serial]
  );

  useReportSubscribe(report);

  useFetch(report?.nodeWidget, { includes: ['configuration', 'profiles'], last: 1 });

  const breadcrumbs = useMemo(
    () => [
      { title: t('devices_page.title'), to: 'devices' },
      [
        { title: t('thiamis.monitor_page_breadcrumb'), to: `devices/${id}/monitor` },
        { title: t('thiamis.config_page_breadcrumb'), to: `devices/${id}/edit` },
        { title: t('thiamis.admin_page_breadcrumb'), to: `devices/${id}/admin` }
      ]
    ],
    [id, t]
  );

  if (node && auth.hasAdminDevice(node.organization_id) === false) {
    return navigate('devices');
  }

  return (
    <Container>
      <Breadcrumbs links={breadcrumbs} />
      <ChartProvider>
        <ExportProvider>
          <Stack direction="column" spacing={{ xs: 0, sm: 2, md: 2 }}>
            <Stack direction="row" justifyContent="space-between">
              <NavigateDropdown
                onChange={(value) => navigate(`devices/${value}/admin`)}
                options={nodes.valueLabelPairs}
                loading={isFetching}
                value={id}
              />
              {node?.isModifiable && (
                <Box display="flex" justifyContent="flex-end" alignItems="center" alignContent="center">
                  <SelectionProvider selected={[id]}>
                    <ActionsButton canManage />
                  </SelectionProvider>
                </Box>
              )}
            </Stack>
            <MonitorLayout report={report} />
          </Stack>
        </ExportProvider>
      </ChartProvider>
    </Container>
  );
}

export default observer(DeviceAdmin);

import { Instance, SnapshotOut } from 'mobx-state-tree';
import model from './model';
import actions from './actions';
import views from './views';

const AlertDurationsTableRow = model.actions(actions).views(views);

export interface IAlertDurationsTableRow extends Instance<typeof AlertDurationsTableRow> {}
export interface IAlertDurationsTableRowSnapshotOut extends SnapshotOut<typeof AlertDurationsTableRow> {}

export default AlertDurationsTableRow;

import { Instance } from 'mobx-state-tree';
import model from './model';
import views from './views';
import actions from './actions';

const DeviceMonitor = model.views(views).actions(actions);

export interface IDeviceMonitor extends Instance<typeof DeviceMonitor> {}

export default DeviceMonitor;

import { styled, Grid, Box, Typography, Stack, Button, Theme, Chip as MChip, FormLabel } from '@mui/material';

type BodyWrapperProps = {
  theme: Theme;
  expandable?: boolean;
  expanded?: boolean;
};

export const HeaderWrapperStyled = styled(Grid)(({ theme }) => ({
  height: '100%',
  textAlign: 'right',
  borderRight: `1px solid ${theme.palette.grey[100]}`,
  padding: theme.spacing(1, 2, 3, 1),
  [theme.breakpoints.down('xl')]: {
    textAlign: 'left',
    borderRight: 'none',
    padding: theme.spacing(1, 2, 1, 2)
  }
}));

export const BodyWrapperStyled = styled(Box)<BodyWrapperProps>(({ expandable = false, expanded = false, theme }: BodyWrapperProps) => ({
  width: '100%',
  padding: theme.spacing(1, 0, 1, 3),
  transition: 'all .2s',
  transformOrigin: 'top',
  [theme.breakpoints.down('xl')]: {
    padding: theme.spacing(2, 4, 1, 4),
    ...(expandable && {
      padding: theme.spacing(2, 4, 1, 4),
      height: '0',
      transform: 'scaleY(0)',
      ...(expanded
        ? {
            padding: theme.spacing(2, 4, 1, 4),
            height: '100%',
            transform: 'scaleY(1)'
          }
        : {
            padding: theme.spacing(0, 0, 1, 0)
          })
    })
  }
}));

export const TitleStyled = styled(Typography)(() => ({
  fontWeight: '600',
  lineHeight: '1.5714285714285714',
  fontSize: '14px'
}));

export const ItemStyled = styled(Box)(() => ({
  overflow: 'hidden'
}));

export const ItemValueStyled = styled(Typography)(({ $small }: { $small?: boolean }) => ({
  fontSize: '14px',
  textOverflow: 'ellipsis',
  display: 'block',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  ...($small && {
    fontSize: '12px'
  })
}));

export const HeaderModuleWrapperStyled = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(1, 3, 2, 3),
  borderBottom: `1px solid ${theme.palette.grey[100]}`,
  [theme.breakpoints.down('xl')]: {
    padding: theme.spacing(1, 3, 3, 3)
  }
}));

export const HeaderModuleStyled = styled(Box)(({ theme }) => ({
  marginTop: 1,
  [theme.breakpoints.down('xl')]: {
    width: '100%'
  }
}));

export const HeaderGridIconWrapperStyled = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyCOntent: 'center',
  alignItems: 'center',
  alignSelf: 'center'
}));

export const HeaderInputWrapperStyled = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 0, 0, 4),
  [theme.breakpoints.down('xl')]: {
    padding: theme.spacing(0, 1)
  }
}));

export const BodyModuleWrapperStyled = styled(Grid)(({ theme }) => ({
  alignItems: 'stretch',
  padding: theme.spacing(0, 3),
  [theme.breakpoints.down('xl')]: {
    padding: theme.spacing(0),
    borderBottom: `1px solid ${theme.palette.grey[100]}`
  }
}));

export const BodyColumnStyled = styled(Box)(({ columns, theme }: { columns?: number; theme: Theme }) => ({
  display: 'grid',
  columnGap: theme.spacing(1),
  rowGap: theme.spacing(3),
  gridTemplateColumns: `repeat(${columns}, 1fr)`,
  [theme.breakpoints.down('xl')]: {
    gridTemplateColumns: `repeat(1, 1fr)`
  }
}));

export const ExpandButtonStyled = styled(Button)(({ theme }) => ({
  margin: theme.spacing(0, 0, 0, 4),
  [theme.breakpoints.up('xl')]: {
    display: 'none'
  }
}));

export const ModuleTitleStyled = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  paddingTop: theme.spacing(1)
}));

export const StackWrapperStyled = styled(Stack)(({ theme }) => ({
  justifyContent: 'end',
  [theme.breakpoints.down('xl')]: {
    justifyContent: 'space-between'
  }
}));

export const SensorContainerStyled = styled(Box)(({ theme }) => ({
  '> div:nth-child(2)': { borderTop: `1px solid ${theme.palette.grey[100]}` }
}));

export const ButtonStyled = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper
}));

export const ChipStyled = styled(MChip)(() => ({
  marginRight: '0.3rem',
  marginBottom: '0.4rem'
}));

export const ModulesContainerStyled = styled(Box)(({ theme }) => ({
  minHeight: '80px',
  marginTop: 10,
  borderBottom: `1px solid ${theme.palette.grey[100]}`,
  ' > div:nth-child(2)': {
    '.BodySection, .BodyTitle': {
      paddingTop: theme.spacing(2)
    }
  },
  ' > div:last-child .BodySection': {
    paddingBottom: theme.spacing(2)
  }
}));

export const HeaderSensorWrapperStyled = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1, 0),
  [theme.breakpoints.down('xl')]: {
    padding: theme.spacing(1, 2)
  }
}));

export const HeaderDeviceWrapperStyled = styled(Box)(({ theme }) => ({
  borderTop: 'none',
  borderBottom: `1px solid ${theme.palette.grey[100]}`,
  padding: theme.spacing(1, 2)
}));

export const FormLabelStyled = styled(FormLabel)(({ theme }) => ({
  color: theme.palette.secondary.muted,
  fontSize: 14,
  fontWeight: 600
}));

/* eslint-disable react/jsx-props-no-spreading */
import { DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid';

import StatusFormatter from './status_formatter';
import SharedLeasedFormatter from './shared_leased_formatter';
import ActionsFormatter from './actions_formatter';
import NameFormatter from './name_formatter';
import OrganizationNameFormatter from './organization_name_formatter';

export function StatusTypeProvider(props: DataTypeProviderProps) {
  return <DataTypeProvider {...props} formatterComponent={StatusFormatter} />;
}

export function NameProvider(props: DataTypeProviderProps) {
  return <DataTypeProvider {...props} formatterComponent={NameFormatter} />;
}

export function SharedLeasedProvider(props: DataTypeProviderProps) {
  return <DataTypeProvider {...props} formatterComponent={SharedLeasedFormatter} />;
}

export function ActionsProvider(props: DataTypeProviderProps) {
  return <DataTypeProvider {...props} formatterComponent={ActionsFormatter} />;
}

export function OrganizationNameProvider(props: DataTypeProviderProps) {
  return <DataTypeProvider {...props} formatterComponent={OrganizationNameFormatter} />;
}

/* eslint-disable @typescript-eslint/naming-convention */
import { flow, getParent, getIdentifier } from 'mobx-state-tree';
import omit from 'lodash/omit';
import type { IUserCalibrationModel } from './model';
import type { IDevice } from '../../..';

export default (self: IUserCalibrationModel) => ({
  destroy: flow(function* destroy() {
    self.startSyncing();
    try {
      const device = getParent<IDevice>(self, 3);
      const payload = device.toJSON();
      yield device.update({
        ...payload,
        user_calibration: omit(payload.user_calibration, getIdentifier(self) as string)
      });
      self.finishSyncing();
    } catch (error) {
      self.failSyncing(error);
    }
  })
});

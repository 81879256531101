import { Grid, Stack } from '@mui/material';
import { observer } from 'mobx-react-lite';
import AddIcon from '@mui/icons-material/Add';
import useNavigate from '~/hooks/use_navigate';
import useLocales from '~/hooks/use_locales';
import useMst from '~/hooks/use_mst';

import Filters from '~/components/filters';
import ButtonAdaptive from '~/components/adaptive_button';

function FormTop() {
  const { t } = useLocales();
  const { navigate } = useNavigate();
  const { templates, certificates } = useMst();

  return (
    <Grid container spacing={2} sx={{ py: 2, px: 3 }}>
      <Grid item xs={12} sm={4} lg={6} md={6}>
        <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }}>
          <Filters collection={certificates} />
        </Stack>
      </Grid>
      <Grid item xs={12} sm={8} lg={6} md={6} sx={{ textAlign: 'right' }}>
        {templates.isFetched && templates.blue && (
          <ButtonAdaptive
            variant="contained"
            size="large"
            $mobileFullWidth
            breakpoint="md"
            startIcon={<AddIcon />}
            onClick={() => navigate('certificates/create')}
          >
            {t('certificates.create_new')}
          </ButtonAdaptive>
        )}
      </Grid>
    </Grid>
  );
}

export default observer(FormTop);

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { List, Box } from '@mui/material';

import { NavSectionProps } from './type';

import NavListRoot from './nav_list';
import { ListSubheaderStyled } from './styled';

function hasAccess(access: boolean | undefined) {
  if (typeof access !== 'boolean') {
    return true;
  }
  return access;
}

function NavSection({ navConfig, isCollapse = false, ...other }: NavSectionProps) {
  return (
    <Box {...other}>
      {navConfig.map((group, index) => {
        const hasSomeAccess = group.items.some(({ access }) => hasAccess(access));
        return (
          hasSomeAccess && (
            // eslint-disable-next-line react/no-array-index-key
            <List key={`${group.subheader}${index}`} disablePadding>
              <ListSubheaderStyled $isCollapse={isCollapse}>{group.subheader}</ListSubheaderStyled>
              {group.items.map((list) => hasAccess(list.access) && <NavListRoot key={list.title} list={list} isCollapse={isCollapse} />)}
            </List>
          )
        );
      })}
    </Box>
  );
}

export default React.memo(NavSection);

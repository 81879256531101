import { TableCell, Link, Box, List, ListItem, ListItemText } from '@mui/material';
import { isValidPhoneNumber, formatPhoneNumberIntl } from 'react-phone-number-input';
import { observer } from 'mobx-react-lite';

import useMst from '~/hooks/use_mst';

import I18n from '~/utils/i18n';
import { DetailRowStyled } from '~/components/react_grid/styled';
import { ICertificateModelSnapshotOut } from '~/mst/models/certificate/model';
import { INode } from '~/mst/models/node';

function GridDetailContainer({ row }: { row: ICertificateModelSnapshotOut }) {
  const { certificates } = useMst();
  const certificate = certificates.getById(row._id);
  const { phone, address } = certificate?.user_metadata || {};

  const nodes = certificate?.templates[0]?.configuration?.nodes;

  return (
    <DetailRowStyled>
      <TableCell />
      <TableCell>
        {!!nodes.length && (
          <Box>
            <Box>{I18n.t('certificates.devices')}</Box>
            <List dense disablePadding>
              {nodes.map((node: INode) => (
                <ListItem key={node} disablePadding>
                  <ListItemText>{node.presentName}</ListItemText>
                </ListItem>
              ))}
            </List>
          </Box>
        )}
      </TableCell>
      <TableCell>
        <Box>
          <List>
            {phone && (
              <ListItem disablePadding>
                <Link to={isValidPhoneNumber(phone) ? formatPhoneNumberIntl(phone) : phone} />
              </ListItem>
            )}
            {address && (
              <ListItem disablePadding>
                <ListItemText>{address}</ListItemText>
              </ListItem>
            )}
          </List>
        </Box>
      </TableCell>
      <TableCell />
      <TableCell />
    </DetailRowStyled>
  );
}

export default observer(GridDetailContainer);

import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import Yup from '~/utils/yup';
import { Form as FinalForm } from 'react-final-form';
import { computed } from 'mobx';
import { INode } from '~/mst/models/node';
import useMst from '~/hooks/use_mst';
import toasts from '~/utils/toasts';
import { useValidationSchema } from '~/hooks/use_validate';
import useLocales from '~/hooks/use_locales';
import useNavigate from '~/hooks/use_navigate';

import { captureException } from '~/services/sentry';
import DeviceForm from './form';

function DeviceContainer({ node }: { node: INode }) {
  const { t } = useLocales();
  const { navigate } = useNavigate();
  const { nodes } = useMst();
  const handleOnSubmit = useCallback(
    async (values) => {
      try {
        const { _id: nodeId } = await node.create(values);
        nodes.fetch();
        navigate(`devices/${nodeId}/edit`);
        toasts.success(t(`notifications.success.forms.create_node`));
      } catch (e) {
        captureException(e);
        toasts.error(t('notifications.errors.server_error'));
      }
    },
    [node, t, navigate, nodes]
  );

  const AddDeviceSchema = Yup.object().shape({
    name: Yup.string().required('Name is required')
  });

  const validate = useValidationSchema(AddDeviceSchema);

  const initialValues = useMemo(
    () =>
      computed(() => ({
        name: node?.name,
        description: node?.description,
        interval: node?.interval || 60000
      })),
    [node]
  ).get();

  return (
    <FinalForm
      onSubmit={handleOnSubmit}
      validate={validate}
      initialValues={initialValues}
      subscription={{ submitting: true, pristine: true, dirty: true }}
    >
      {({ handleSubmit }) => <DeviceForm node={node} handleSubmit={handleSubmit} />}
    </FinalForm>
  );
}

export default observer(DeviceContainer);

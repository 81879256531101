/* eslint-disable @typescript-eslint/naming-convention */
import { flow, getParent, getIdentifier, applySnapshot } from 'mobx-state-tree';
import compact from 'lodash/compact';
import omit from 'lodash/omit';
import api from '~/api';
import type { INode } from '~/mst/models/node';

export default (self) => ({
  create: flow(function* create(payload) {
    try {
      self.startSyncing();
      const { address, com_port, com_type, name } = payload;
      const node: INode = getParent(self);
      const devices = node.devices.toJSON().map((device) => omit(device, ['locationType', 'hasWiFi', 'configuration']));
      const { data } = yield api.nodes.update(getIdentifier(node), {
        devices: [{ name, connection: compact([node.serial, com_port, com_type, address]).join('.') }, ...devices]
      });
      applySnapshot(self.models, data.devices);
      self.finishSyncing();
    } catch (e) {
      self.failSyncing(e);
    }
  }),
  destroy: flow(function* destroy(model) {
    try {
      model.startSyncing();
      yield api.nodes.update(getIdentifier(model.node), {
        devices: self.toArray.filter((device) => getIdentifier(model) !== getIdentifier(device)).map((device) => device.toJSON())
      });
      self.remove(model);
    } catch (e) {
      model.failSyncing(e);
    }
  })
});

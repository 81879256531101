/* eslint-disable no-param-reassign */
import { types as t, Instance } from 'mobx-state-tree';
import Widgets from './widgets';

const HomeModel = t.model({
  widgets: t.optional(Widgets, [])
});

export interface IHomeModel extends Instance<typeof HomeModel> {}

export default HomeModel;

import { types as t, Instance } from 'mobx-state-tree';
import compact from 'lodash/compact';
import { getStore } from '~/mst/utils';
import { ModelV1 as Model } from '~/mst/models/abstract/model';

const CertificateTemplateConfigurationModel = t
  .compose(
    t.model({
      node_id: t.array(t.string)
    }),
    Model
  )
  .views((self) => ({
    get nodes() {
      return compact(self.node_id.map((id) => getStore(self).organizations.getById(id)));
    }
  }))
  .named('CertificateTemplateConfiguration');

export interface ICertificateTemplateConfigurationModel extends Instance<typeof CertificateTemplateConfigurationModel> {}

export default CertificateTemplateConfigurationModel;

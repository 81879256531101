/* eslint-disable react/prop-types */
import { useState, useCallback, useMemo } from 'react';
import { Field as FinalField, useForm } from 'react-final-form';
import { CardContent, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { OnChange } from 'react-final-form-listeners';
import { observer } from 'mobx-react-lite';
import throttle from 'lodash/throttle';
import { computed } from 'mobx';

import TextInput from '~/components/final_form/text_input';
import OrganizationsSelect from '~/components/organizations_select';
import { ModalActions } from '~/components/modal';
import Organizations from '~/mst/models/organizations';

import useLocales from '~/hooks/use_locales';
import useAuth from '~/hooks/use_auth';

type ModalFormProps = {
  onClose: VoidFunction;
  handleSubmit: VoidFunction;
};

function ModalForm(props: ModalFormProps) {
  const { handleSubmit, onClose } = props;
  const { t } = useLocales();
  const { change } = useForm();
  const { auth } = useAuth();
  const [organizations] = useState(Organizations.create());

  const { isSyncing } = auth;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleInputChange = useCallback(
    throttle(async (e) => {
      if (e.target.value && e.target.value.length > 2) {
        change('external_id', e.target.value);
        await organizations.fetch({ query: e.target.value });
      }
    }, 300),
    [organizations]
  );

  const options = useMemo(
    () => computed(() => organizations.valueLabelPairs.map(({ model, ...rest }) => ({ ...rest, model, value: model.external_id }))),
    [organizations.valueLabelPairs]
  ).get();

  return (
    <>
      <CardContent>
        <FinalField name="option">
          {({ input }) => (
            <>
              <RadioGroup
                name={input.name}
                value={input.value}
                onChange={input.onChange}
                sx={{ display: 'flex', flexDirection: 'row', gap: '1rem', marginBottom: 2 }}
              >
                <FormControlLabel sx={{ gridColumn: '1' }} value="email" control={<Radio />} label={t('attributes.user.email')} />
                <FormControlLabel sx={{ gridColumn: '1' }} value="external" control={<Radio />} label={t('models.organization')} />
              </RadioGroup>

              {input.value === 'email' ? (
                <TextInput name="email" label={t('attributes.user.email')} sx={{ gridColumn: '2 / 4' }} />
              ) : (
                <OrganizationsSelect
                  freeSolo
                  onInputChange={handleInputChange}
                  name="external_id"
                  label={t('models.organization')}
                  loading={organizations.isFetching}
                  sx={{ gridColumn: '2 / 4' }}
                  options={options}
                />
              )}
            </>
          )}
        </FinalField>
        <OnChange name="option">
          {(value) => {
            if (value === 'email') {
              change(`external_id`, undefined);
            } else {
              change(`email`, undefined);
            }
          }}
        </OnChange>
      </CardContent>
      <ModalActions
        disabled={isSyncing}
        loading={isSyncing}
        onClose={onClose}
        onSave={handleSubmit}
        saveLabel={t('base.buttons.design_access_context')}
      />
    </>
  );
}

export default observer(ModalForm);

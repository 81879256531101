import React, { useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import flatten from 'lodash/flatten';
import { getIdentifier } from 'mobx-state-tree';
import compact from 'lodash/compact';
import uniqBy from 'lodash/uniqBy';
import isEmpty from 'lodash/isEmpty';
import { computed } from 'mobx';
import { OnChange } from 'react-final-form-listeners';
import get from 'lodash/get';
import { CardContent } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useForm, useFormState } from 'react-final-form';
import I18n from '~/utils/i18n';
import FormGrid from '~/components/@material-extend/form_grid';
import Autocomplete from '~/components/final_form/select';
import DevicesSelect from '~/components/devices_select';
import { ModalActions } from '~/components/modal';
import TextInput from '~/components/final_form/text_input';
import useMst from '~/hooks/use_mst';
import useAuth from '~/hooks/use_auth';
import OrganizationSelect from '~/components/organizations_select';
import { StatusOptions } from '~/pages/alert_edit/conditions_block/condition/value_field';

const conditionsOptions = ['lt', 'lte', 'gt', 'gte', 'eq', 'neq'].map((exp) => ({
  value: exp,
  label: I18n.t(`conditions.expressions.${exp}_title`)
}));

type ModalFormProps = {
  handleCancel: VoidFunction;
  handleSubmit: VoidFunction;
};

function ModalForm({ handleCancel, handleSubmit }: ModalFormProps) {
  const { t } = useTranslation();
  const { change } = useForm();
  const { auth } = useAuth();
  const { nodes: nodesStore, organizations } = useMst();

  useEffect(() => {
    if (auth.organization_id !== 'all') {
      nodesStore.fetch({ organization_id: auth.organization_id, includes: 'data_points' });
    }
  }, [auth.organization_id, nodesStore]);

  const {
    values: { devices, parameter },
    hasValidationErrors
  } = useFormState({ subscription: { values: true, hasValidationErrors: true } });

  const nodes = useMemo(
    () =>
      computed(() =>
        get(devices, '[0].value') === 'all'
          ? nodesStore.monitorableValueLabelPairs.map(({ model }) => model)
          : compact(devices?.map(({ value }) => nodesStore.getById(value)))
      ),
    [devices, nodesStore]
  ).get();

  const parameterOptions = useMemo(
    () =>
      computed(() =>
        uniqBy(
          flatten(nodes?.map((node) => node.data_points?.defaultValueLabelPairsWithStatus?.map(({ label }) => ({ label, value: label })))),
          'value'
        )
      ),
    [nodes]
  ).get();

  const isStatus = parameter === 'Status';
  const optionsExpr = useMemo(
    () => (isStatus ? conditionsOptions.filter(({ value }) => ['eq', 'neq'].includes(value)) : conditionsOptions),
    [isStatus]
  );

  return (
    <>
      <CardContent>
        <FormGrid>
          <OrganizationSelect name="organizationId" label={t('models.organization')} options={organizations.valueLabelPairsManagerAccess} />
          <DevicesSelect
            multiple
            allowSelectAll
            name="devices"
            label={t('thiamis.download_csv.devices')}
            loading={nodesStore.isFetching}
            options={nodesStore.monitorableValueLabelPairs}
          />
          <Autocomplete
            searchable
            name="parameter"
            loading={nodes.some((node) => node.isFetching)}
            label={t('models.parameter')}
            options={parameterOptions}
          />
          <FormGrid columns="2">
            <Autocomplete name="expr" label={t('attributes.alert_rule.func')} options={optionsExpr} />
            {isStatus ? (
              <Autocomplete noHelperText name="value" label={t('attributes.alert_rule.value')} options={StatusOptions} />
            ) : (
              <TextInput noHelperText name="value" label={t('attributes.alert_rule.value')} />
            )}
          </FormGrid>
        </FormGrid>
        <OnChange name="organizationId">
          {(value) => {
            change('devices', []);
            nodesStore.fetch({ organization_id: value, includes: 'data_points' });
          }}
        </OnChange>
        <OnChange name="devices">
          {(value) => {
            if (!isEmpty(value)) {
              nodesStore.fetchDataPoints({ node_id: nodes.map((node) => getIdentifier(node)) });
            }
            change('parameter', null);
          }}
        </OnChange>
      </CardContent>
      <ModalActions onSave={handleSubmit} onClose={handleCancel} saveLabel={t('base.buttons.create')} disabled={hasValidationErrors} />
    </>
  );
}

export default observer(ModalForm);

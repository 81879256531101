import { Instance } from 'mobx-state-tree';
import WidgetProcessor from '~/mst/models/widget/processor';
import actions from './actions';
import model from './model';
import views from './views';

const DevicesOnlineChartWidgetStore = model.views(views).actions(actions);

export interface IDevicesOnlineChartWidgetStore extends Instance<typeof DevicesOnlineChartWidgetStore> {}

export default WidgetProcessor(DevicesOnlineChartWidgetStore);

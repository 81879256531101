/* eslint-disable no-param-reassign */
import { flow } from 'mobx-state-tree';
import api from '~/api';
import { ITotalDevicesWidgetModel } from './model';

export default (self: ITotalDevicesWidgetModel) => ({
  fetch: flow(function* fetch(params = {}) {
    try {
      self.startFetching();
      const { data } = yield self.sync(api.search.status, params);
      self.data = data;
      self.finishFetching();
    } catch (error) {
      self.failFetching(error);
    }
  }),
  setChartType(chartType: string) {
    self.chart_type = chartType;
  }
});

import { DataTypeProvider } from '@devexpress/dx-react-grid';
import TimeAgoFormatter from '~/components/react_grid/time_ago_formatter';
import ActionsFormatter from './actions_formatter';
import ResultFormatter from './result_formatter';
import EnabledFormatter from './enabled_formatter';

export function TimeAgoProvider() {
  return <DataTypeProvider for={['created_at', 'last_used_at']} formatterComponent={TimeAgoFormatter} />;
}

export function ResultProvider() {
  return <DataTypeProvider for={['status']} formatterComponent={ResultFormatter} />;
}

export function ActionsProvider() {
  return <DataTypeProvider for={['actions']} formatterComponent={ActionsFormatter} />;
}

export function EnabledProvider() {
  return <DataTypeProvider for={['enabled']} formatterComponent={EnabledFormatter} />;
}

/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { DateTime } from 'luxon';
import { SxProps } from '@mui/material';
import { observer } from 'mobx-react-lite';

import useLocales from '~/hooks/use_locales';
import humanizer from '~/utils/humanize_duration';

import Refresh from '~/assets/icons/params/refresh.svg?react';
import DeviceParameter from '~/components/device_parameter';
import withAutoupdate from '~/hocs/with_autoupdate';

type DeviceLastUpdateType = {
  lastTimeReportedTs: number;
  iconLeft?: boolean;
  sx?: SxProps;
};

function DeviceLastUpdate({ lastTimeReportedTs, iconLeft = false, ...rest }: DeviceLastUpdateType) {
  const { t } = useLocales();
  const now = DateTime.now().toUTC().toMillis();

  let lastTs;
  if (lastTimeReportedTs) {
    const delta = now - lastTimeReportedTs;
    lastTs = humanizer.humanizeDuration(delta, { smallestUnit: 'min' });
  }
  return (
    <DeviceParameter
      title={lastTs}
      tooltipTitle={t('thiamis.more_info.last_updated')}
      value={lastTs}
      IconComponent={Refresh}
      iconWidth="12px"
      iconHeight="100%"
      iconLeft={iconLeft}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    />
  );
}

export default withAutoupdate(observer(DeviceLastUpdate));

import React, { useMemo } from 'react';
import { computed } from 'mobx';
import { observer } from 'mobx-react-lite';
import { LoadingButton } from '@mui/lab';
import { Box, CardContent, CardActions } from '@mui/material';
import { useFormState } from 'react-final-form';

import { getValueLabelPairs } from '~/mst/utils';
import FormGrid from '~/components/@material-extend/form_grid';
import useLocales from '~/hooks/use_locales';
import Select from '~/components/final_form/select';
import type { IDeviceAdmin } from '../../../store';

type SidebarType = {
  handleSubmit: VoidFunction;
  report: IDeviceAdmin;
  submitting: boolean;
};

function FormSidebar({ report, handleSubmit, submitting }: SidebarType) {
  const { t } = useLocales();
  const thiamis = report?.debugWidget?.node?.thiamis;
  const debugLevelOptions = useMemo(() => computed(() => getValueLabelPairs(thiamis?.configuration?.debug_level)), [thiamis]).get();
  const debugTagsOptions = useMemo(() => computed(() => getValueLabelPairs(thiamis?.configuration?.debug_tags)), [thiamis]).get();
  const { pristine } = useFormState({ subscription: { pristine: true } });
  return (
    <Box>
      <CardContent>
        <FormGrid>
          <Select name="debug_level" label={t('attributes.thiamis.debug_level')} options={debugLevelOptions || []} />
          <Select searchable name="debug_tags" label={t('attributes.thiamis.debug_tags')} multiple options={debugTagsOptions || []} />
        </FormGrid>
      </CardContent>
      <CardActions>
        <LoadingButton type="submit" disabled={submitting || pristine} variant="contained" loading={submitting} onClick={handleSubmit}>
          {t('base.buttons.save')}
        </LoadingButton>
      </CardActions>
    </Box>
  );
}

export default observer(FormSidebar);

import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import isEmpty from 'lodash/isEmpty';
import { TableCell, Box, List, ListItem, ListItemText } from '@mui/material';
import useLocales from '~/hooks/use_locales';
import { DetailRowStyled } from '~/components/react_grid/styled';
import { GridRowProps } from '~/components/react_grid/types';
import { IWebhook } from '~/mst/models/webhook';

function GridDetailRow({ row }: GridRowProps<IWebhook>) {
  const { conditions } = row;

  const { t } = useLocales();

  useEffect(() => {
    if (conditions?.node_id.length && !conditions?.nodes.length) {
      row.fetch();
    }
  }, [row, conditions?.node_id, conditions?.nodes]);

  return (
    <DetailRowStyled>
      <TableCell />
      <TableCell />
      <TableCell />
      <TableCell>
        <Box>
          <p>
            <b>X-Netronix-Auth</b>
          </p>
          <code>{row.auth}</code>
        </Box>
      </TableCell>
      <TableCell>
        <Box>
          <p>
            <b>{t('webhooks.conditions')}</b>
          </p>
          {isEmpty(conditions?.nodes) ? (
            t('base.labels.all')
          ) : (
            <List dense disablePadding>
              {conditions.nodes.map((node) => (
                <ListItem key={node._id} disablePadding>
                  <ListItemText>{node.presentName}</ListItemText>
                </ListItem>
              ))}
            </List>
          )}
        </Box>
      </TableCell>
      <TableCell>
        <p>
          <b>{t('attributes.webhook.type')}</b>
        </p>
        {conditions?.type || t('base.labels.all')}
      </TableCell>
      <TableCell />
      <TableCell />
    </DetailRowStyled>
  );
}

export default observer(GridDetailRow);

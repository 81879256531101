import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Form as FinalForm } from 'react-final-form';
import { Stack, CircularProgress } from '@mui/material';
import get from 'lodash/get';
import { computed } from 'mobx';

import toasts from '~/utils/toasts';
import { TabContainerStyled } from '~/pages/device_monitor/monitor_chart/styled';
import useFetch from '~/hooks/use_fetch';
import useLocales from '~/hooks/use_locales';
import { captureException } from '~/services/sentry';
import { IDeviceAdmin } from '../../store';
import FilesEditor from './editor';
import FilesSidebar from './sidebar';

type FilesTabPropsType = {
  report: IDeviceAdmin;
  isExpanded: boolean;
  sidebarWidth: number;
  handleCollapse: VoidFunction;
};

function FilesTab({ report, isExpanded, sidebarWidth, handleCollapse }: FilesTabPropsType) {
  const { isFetching } = useFetch(report?.filesWidget);
  const { t } = useLocales();
  const handleOnSubmit = useCallback(
    async (values) => {
      try {
        const { filename, content } = values;
        await report?.filesWidget?.update({
          files: {
            ...report?.filesWidget?.files,
            [filename]: content
          }
        });
        toasts.success(t(`notifications.success.forms.files`));
      } catch (e) {
        captureException(e);
        toasts.error(t('notifications.errors.server_error'));
      }
    },
    [report?.filesWidget, t]
  );

  const initialValues = useMemo(
    () =>
      computed(() => {
        const filename = get(report?.filesWidget?.filenames, '[0]');
        return {
          filename,
          content: get(report?.filesWidget?.files, filename)
        };
      }),
    [report?.filesWidget]
  ).get();

  return (
    <FinalForm
      onSubmit={handleOnSubmit}
      initialValues={initialValues}
      subscription={{
        dirty: true,
        pristine: true
      }}
    >
      {({ handleSubmit }) => (
        <Stack direction="row">
          <TabContainerStyled $isExpanded={isExpanded} width={sidebarWidth} sx={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
            {isFetching ? <CircularProgress size={30} color="info" /> : <FilesEditor />}
          </TabContainerStyled>
          <FilesSidebar handleSubmit={handleSubmit} onToggleCollapse={handleCollapse!} isExpanded={isExpanded} width={sidebarWidth} report={report} />
        </Stack>
      )}
    </FinalForm>
  );
}

export default observer(FilesTab);

import { Instance, SnapshotOut } from 'mobx-state-tree';
import model from './model';
import views from './views';

const CalibrationMeasurement = model.views(views);

export interface ICalibrationMeasurement extends Instance<typeof CalibrationMeasurement> {}
export interface ICalibrationMeasurementSnapshotOut extends SnapshotOut<typeof CalibrationMeasurement> {}

export default CalibrationMeasurement;

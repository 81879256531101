import React, { useMemo } from 'react';
import { Tooltip, Badge } from '@mui/material';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import useLocales from '~/hooks/use_locales';
import SharedLeasedStyled from './styled';

export default function SharedLeasedFormatter(props: DataTypeProvider.ValueFormatterProps) {
  const {
    row: { sharedTo, leasedTo }
  } = props;
  const isShared = sharedTo && sharedTo.length;
  const isLeased = leasedTo && leasedTo.length;
  const { t } = useLocales();

  const { type, count, ids } = useMemo(() => {
    if (isShared) {
      return {
        count: sharedTo.length,
        ids: [...sharedTo],
        type: 'shared'
      };
    }
    if (isLeased) {
      return {
        ids: [leasedTo],
        type: 'leased'
      };
    }
    return {};
  }, [sharedTo, leasedTo, isLeased, isShared]);

  if (!isLeased && !isShared) return null;

  return (
    <Tooltip
      title={<span style={{ whiteSpace: 'pre-line' }}>{ids?.map(({ name, externalId }) => `${name}\n${externalId}`).join('\n')}</span>}
      arrow
      placement="top"
    >
      <Badge badgeContent={count} color="info" invisible={ids?.length < 2}>
        <SharedLeasedStyled>{t(`filters.states.${type}`).toLowerCase()}</SharedLeasedStyled>
      </Badge>
    </Tooltip>
  );
}

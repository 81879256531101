import { Instance } from 'mobx-state-tree';
import model from './model';
import actions from './actions';
import views from './views';

const Token = model.views(views).actions(actions);

export interface IToken extends Instance<typeof Token> {}

export default Token;

import { types as t, Instance } from 'mobx-state-tree';
import Node from '~/mst/models/node';
import Syncable from '~/mst/models/abstract/syncable';
import Fetchable from '~/mst/models/abstract/fetchable';
import Filterable from '~/mst/models/abstract/filterable';
import CollectionMap from '~/mst/models/abstract/collection_map';

const NodesModel = t.compose(CollectionMap(Node), Syncable, Fetchable({ cache: true }), Filterable).named('Nodes');

export interface INodesModel extends Instance<typeof NodesModel> {}

export default NodesModel;

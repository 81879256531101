import { styled } from '@mui/material/styles';
import { APP_BAR_MOBILE, APP_BAR_DESKTOP } from '~/theme/values';

export const DefaultLayoutRootStyled = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

export const DefaultLayoutMainStyled = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE,
  paddingBottom: 0,
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP
  },
  '@media print': {
    paddingTop: 0
  }
}));

/* eslint-disable react/jsx-props-no-spreading */
import { DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid';
import SerialFormatter from './serial_formatter';
import ActionsFormatter from './actions_formatter';

export function SerialProvider(props: DataTypeProviderProps) {
  return <DataTypeProvider {...props} formatterComponent={SerialFormatter} />;
}

export function ActionsProvider(props: DataTypeProviderProps) {
  return <DataTypeProvider {...props} formatterComponent={ActionsFormatter} />;
}

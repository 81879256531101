import { observer } from 'mobx-react-lite';
import get from 'lodash/get';
import isString from 'lodash/isString';
import { Box, Stack } from '@mui/material';
import { useFormState, useForm } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';

import type { IAlert } from '~/mst/models/alert';
import { FUNCTIONS } from '~/mst/models/condition/types';
import DevicesFunctionsSelect from './devices_functions_select';
import TimePeriodSelect from './time_period_select';
import ParametersSelect from './parameters_select';

type ConditionFieldType = {
  name: string;
  model: IAlert;
};

function ParameterField(props: ConditionFieldType) {
  const { name, model } = props;
  const { values } = useFormState({ subscription: { values: true } });
  const { change } = useForm();

  const { dataPointId } = get(values, name, {});

  return (
    <Stack direction="column" spacing={2}>
      <OnChange name={`${name}.deviceId`}>
        {(value) => {
          if (FUNCTIONS.includes(value)) {
            if (value === 'diff') {
              change(`${name}.dataPointId`, { func: value, dataPointId: '', dataPointId2: { func: '', dataPointId: '' } });
            } else {
              change(`${name}.dataPointId`, { func: value, value: null, dataPointId: '' });
            }
          } else {
            model?.nodes?.fetchDataPoints({ last: 1, node_id: value });
          }
        }}
      </OnChange>
      {isString(dataPointId) ? (
        <>
          <DevicesFunctionsSelect condition={name} name="deviceId" model={model} />
          <ParametersSelect condition={name} name="dataPointId" model={model} />
        </>
      ) : (
        <>
          <DevicesFunctionsSelect condition={name} name="dataPointId.func" model={model} />
          <Box sx={{ pl: '20px' }}>
            {dataPointId?.func === 'diff' ? (
              <Stack direction="column" spacing={2}>
                <ParameterField name={`${name}.dataPointId`} model={model} />
                <ParameterField name={`${name}.dataPointId.dataPointId2`} model={model} />
              </Stack>
            ) : (
              <ParameterField name={`${name}.dataPointId`} model={model} />
            )}
          </Box>
          <Box sx={{ pl: '20px' }}>
            {['avg', 'min', 'max'].includes(dataPointId?.func) && <TimePeriodSelect name={`${name}.dataPointId.value`} />}
          </Box>
          <OnChange name={`${name}.dataPointId.func`}>
            {(value) => {
              if (!FUNCTIONS.includes(value)) {
                change(`${name}.dataPointId`, '');
                change(`${name}.deviceId`, value);
              }
            }}
          </OnChange>
        </>
      )}
    </Stack>
  );
}

export default observer(ParameterField);

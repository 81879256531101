/* eslint-disable @typescript-eslint/no-use-before-define */
import { types as t, Instance } from 'mobx-state-tree';
import Sensor from '~/mst/models/device/sensor';

const ParticlesPlusSensor = t
  .compose(
    t.model({
      units: t.maybeNull(t.number)
    }),
    Sensor
  )
  .named('ParticlesPlus');

export interface IParticlesPlusSensor extends Instance<typeof ParticlesPlusSensor> {}

export default ParticlesPlusSensor;

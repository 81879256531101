/* eslint-disable no-param-reassign */
import axios from 'axios';
import { types as t, Instance } from 'mobx-state-tree';

const Syncable = t
  .model({})
  .volatile(() => ({
    error: null,
    isSyncing: false,
    isSynced: false,
    retries: 0
  }))
  .views((self) => ({
    get hasError() {
      return Boolean(self.error);
    }
  }))
  .actions((self) => {
    function finishSyncing() {
      self.isSyncing = false;
      self.isSynced = true;
    }
    return {
      startSyncing() {
        self.isSyncing = true;
        self.isSynced = false;
      },
      finishSyncing,
      failSyncing(thrown: any) {
        self.isSyncing = false;
        self.isSynced = false;
        if (!axios.isCancel(thrown)) {
          self.retries += 1;
          if (self.retries >= 3) {
            finishSyncing();
          }
          throw new Error(thrown);
        }
      }
    };
  });

export interface ISyncable extends Instance<typeof Syncable> {}

export default Syncable;

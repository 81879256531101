import React from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import StatusIcon from '~/components/status_icon';
import useLocales from '~/hooks/use_locales';

export default function StatusFormatter(props: DataTypeProvider.ValueFormatterProps) {
  const {
    row: { status }
  } = props;
  const { t } = useLocales();
  return <StatusIcon statusType={status} statusText={t(`devices.statuses.${status}`)} size="large" />;
}

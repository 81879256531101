import React, { useState, useCallback } from 'react';
import { IconButton, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import { IWebhook } from '~/mst/models/webhook';
import ConfirmDialog from '~/components/confirm_dialog';
import useActionsPopover from '~/hooks/react_grid/use_actions_popover';
import useLocales from '~/hooks/use_locales';

export function DeleteFormatter({ row }: { row: IWebhook }) {
  const { t } = useLocales();

  const [isDeleting, setIsDeleting] = useState(false);
  const { ActionsMenu, handleClose, handleOpen } = useActionsPopover();

  const handleStartDelete = useCallback(() => {
    handleClose();
    setIsDeleting(true);
  }, [handleClose]);

  const handleCancelDelete = useCallback((event) => {
    event.stopPropagation();
    setIsDeleting(false);
  }, []);

  const handleDeleteWebhook = useCallback(
    async (event) => {
      event.stopPropagation();
      row.destroy();
      setIsDeleting(false);
    },
    [row, setIsDeleting]
  );

  return (
    <>
      <IconButton onClick={handleOpen}>
        <MoreVertIcon />
      </IconButton>
      <ActionsMenu>
        <MenuItem sx={{ color: 'error.main' }} onClick={handleStartDelete} disabled={row.isSyncing}>
          <DeleteIcon />
          Delete
        </MenuItem>
      </ActionsMenu>
      <ConfirmDialog
        open={isDeleting}
        onClose={handleCancelDelete}
        onConfirm={handleDeleteWebhook}
        title={t('webhooks.dialogs.delete.title')}
        content={t('webhooks.dialogs.delete.content')}
        confirmLabel={t('webhooks.dialogs.delete.confirm')}
        confirmButtonType="error"
      />
    </>
  );
}

export default DeleteFormatter;

import Yup from '~/utils/yup';
import { PRECIPITATION_UNITS, PRESSURE_UNITS, TEMPERATURE_UNITS, WINDSPEED_UNITS } from '~/mst/models/device/sensor/airmar/config';
import { SensorSchema } from '../validation';

export default SensorSchema.shape({
  precipitation_unit: Yup.string().oneOf(PRECIPITATION_UNITS).nullable().notRequired(),
  pressure_unit: Yup.string().oneOf(PRESSURE_UNITS).nullable().notRequired(),
  temperature_unit: Yup.string().oneOf(TEMPERATURE_UNITS).nullable().notRequired(),
  windspeed_unit: Yup.string().oneOf(WINDSPEED_UNITS).nullable().notRequired()
});

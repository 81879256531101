import React, { useMemo } from 'react';
import { Card, CardContent } from '@mui/material';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import Breadcrumbs from '~/components/breadcrumbs';
import Container from '~/components/container';

import useLocales from '~/hooks/use_locales';
import LoadingBox from '~/components/loading_box';
import useMst from '~/hooks/use_mst';
import useFetch from '~/hooks/use_fetch';
import OrganizationModel from '~/mst/models/organization';
import FormContainer from './form_container';

function OrganizationsEdit() {
  const { id } = useParams();
  const { t } = useLocales();
  const { organizations } = useMst();

  const { isFetched } = useFetch(organizations, null, { excludeParams: ['organization_id'] });

  const organization = id ? organizations.getById(id) : OrganizationModel.create({});

  const links = useMemo(
    () => [
      { title: t('organizations.title'), to: 'organizations' },
      { title: id ? t('organizations.edit_details') : t('organizations.create_new'), to: id ? `organizations/${id}` : 'organizations/new' }
    ],
    [id, t]
  );

  return (
    <Container>
      <Breadcrumbs links={links} />
      <Card>
        <CardContent>{isFetched ? <FormContainer model={organization} /> : <LoadingBox />}</CardContent>
      </Card>
    </Container>
  );
}

export default observer(OrganizationsEdit);

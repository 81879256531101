/* eslint-disable @typescript-eslint/naming-convention */
import { WidgetTypes } from '~/mst/models/widget/types';
import type { IDeviceAdminModel } from './model';
import { TABS } from './model';

export default (self: IDeviceAdminModel) => ({
  get debugWidget() {
    return self.widgets.findWhere({ type: 'DebugWidget' });
  },
  get nodeWidget() {
    return self.widgets.findWhere({ type: WidgetTypes.NodeWidget });
  },
  get filesWidget() {
    return self.widgets.findWhere({ type: 'FilesWidget' });
  },
  get isDebugView() {
    return self.selected_tab === TABS.DEBUG.name;
  },
  get isTableView() {
    return self.selected_tab === TABS.DEBUG.name;
  },
  get isFilesView() {
    return self.selected_tab === TABS.FILES.name;
  },
  get nodeIds() {
    return [self.debugWidget?.node_id];
  }
});

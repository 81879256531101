import React from 'react';
import { DateTime } from 'luxon';
import I18n from '~/utils/i18n';

function LastTriggeredFormatter({ value }: { value: string }) {
  if (value && DateTime.fromMillis(value).isValid) {
    return DateTime.fromMillis(value).toRelative();
  }
  return <span>{I18n.t('alerts.triggered_never')}</span>;
}

export default LastTriggeredFormatter;

/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useMemo } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import Yup, { searchableSelectSchema } from '~/utils/yup';
import { Form as FinalForm } from 'react-final-form';

import { useValidationSchema } from '~/hooks/use_validate';
import useLocales from '~/hooks/use_locales';
import toasts from '~/utils/toasts';

import Modal from '~/components/modal';
import { INode } from '~/mst/models/node';
import { captureException } from '~/services/sentry';
import Form from './form';

type AddUserCalibrationFormProps = {
  open: boolean;
  onClose: () => void;
  node: INode;
};

const AddUserCalibrationSchema = Yup.object().shape({
  path: Yup.string().required('Parameter is required'),
  params: Yup.string()
    .required('Params are required')
    .matches(/^(-?\d+\.)?-?\d+(,(-?\d+\.)?-?\d+)*$/, 'Invalid params')
});

function AddUserCalibrationForm({ open, onClose, node }: AddUserCalibrationFormProps) {
  const { t } = useLocales();

  const validate = useValidationSchema(AddUserCalibrationSchema);

  const handleSave = useCallback(
    async (values) => {
      try {
        await node.thiamis.createUserCalibrationParameter(values);
        datadogRum.addAction('create_custom_calibration', values);
        onClose();
        toasts.success(t('notifications.success.add_calibration_message'));
      } catch (e) {
        captureException(e);
        toasts.error(t('notifications.errors.server_error'));
      }
    },
    [node, onClose, t]
  );

  const initialValues = useMemo(() => ({ params: '0,1' }), []);

  return (
    <Modal open={open} handleClose={onClose} title={t('sensor.data_point.add_custom_calibration_title')}>
      <FinalForm onSubmit={handleSave} initialValues={initialValues} validate={validate}>
        {({ handleSubmit }) => <Form handleCancel={onClose} handleSubmit={handleSubmit} node={node} />}
      </FinalForm>
    </Modal>
  );
}

export default AddUserCalibrationForm;

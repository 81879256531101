import React, { useEffect } from 'react';
import { Card, CardContent } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useFormState } from 'react-final-form';
import { observable, action } from 'mobx';

import OrganizationSelect from '~/components/organizations_select';
import useLocales from '~/hooks/use_locales';
import TextInput from '~/components/final_form/text_input';
import FormGrid from '~/components/@material-extend/form_grid';
import useFetch from '~/hooks/use_fetch';
import MCardHeader from '~/components/@material-extend/MCardHeader';
import Select from '~/components/final_form/select';
import DevicesSelect from '~/components/devices_select';
import DateTimePicker from '~/components/final_form/date_time_picker';
import { HeaderDeviceWrapperStyled } from '~/pages/device_edit/components/styled';
import useMst from '~/hooks/use_mst';
import { IReport } from '~/mst/models/report';

const standardOptions = [{ value: 'aqi', label: 'AQI' }];
const params = observable(
  {
    organization_id: null,
    setOrganizationId(value) {
      this.organization_id = value;
    }
  },
  {
    setOrganizationId: action
  }
);
function EditReportForm({ model }: { model: IReport }) {
  const { t } = useLocales();
  const { nodes, organizations } = useMst();
  const {
    values: {
      organization_id: organizationId,
      configuration: { from, to }
    }
  } = useFormState({ subscription: { values: true } });

  useEffect(() => params.setOrganizationId(organizationId), [organizationId]);
  const { isFetching } = useFetch(nodes, params);

  const now = Date.now();

  return (
    <Card>
      <HeaderDeviceWrapperStyled>
        <MCardHeader title="Settings" />
      </HeaderDeviceWrapperStyled>
      <CardContent>
        <FormGrid>
          <TextInput name="name" label={t('attributes.report.name')} />
          <FormGrid columns="2">
            <Select options={standardOptions} label={t('attributes.report.standard')} name="configuration.standard" />
            <OrganizationSelect
              data-testid="reportOwnerSelect"
              disabled={!model.isNew}
              options={organizations.valueLabelPairsManagerAccess}
              name="organization_id"
              label={t('attributes.alert.organization_id')}
            />
          </FormGrid>
          <DevicesSelect
            allowSelectAll
            name="configuration.node_ids"
            loading={isFetching}
            label={t('attributes.report.node_ids')}
            options={nodes.monitorableAirthinxValueLabelPairs || []}
            multiple
          />
          <FormGrid columns="2">
            <DateTimePicker name="configuration.from" label={t('thiamis.download_csv.from')} maxDateTime={to || now} timezone="UTC" />
            <DateTimePicker name="configuration.to" label={t('thiamis.download_csv.to')} maxDateTime={now} minDate={from} timezone="UTC" />
          </FormGrid>
        </FormGrid>
      </CardContent>
    </Card>
  );
}

export default observer(EditReportForm);

import { Instance, SnapshotOut } from 'mobx-state-tree';
import model from './model';
import actions from './actions';
import views from './views';
import viewsFilters from './views/filters';
import viewsActions from './views/actions';

const Node = model.views(views).views(viewsFilters).views(viewsActions).actions(actions);

export interface INode extends Instance<typeof Node> {}
export interface INodeSnapshotOut extends SnapshotOut<typeof Node> {}

export default Node;

import { Link as RouterLink, Outlet } from 'react-router-dom';

import Logo from '~/components/sidebar/logo';
import HeaderStyled from './styled';

export default function LogoOnly() {
  return (
    <>
      <HeaderStyled>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
      </HeaderStyled>
      <Outlet />
    </>
  );
}

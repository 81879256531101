import React, { useMemo, useEffect, useCallback } from 'react';
import { CardContent } from '@mui/material';
import { useFormState, useForm } from 'react-final-form';

import useLocales from '~/hooks/use_locales';
import useModal from '~/contexts/modal_context';
import { ValueLabelModelPair } from '~/mst/models/abstract/collection_types';
import { IOrganization } from '~/mst/models/organization';
import { RolesEnum } from '~/utils/constants';

import FormGrid from '~/components/@material-extend/form_grid';
import TextInput from '~/components/final_form/text_input';
import OrganizationSelect from '~/components/organizations_select';
import Select from '~/components/final_form/select';
import { ModalActions } from '~/components/modal';

type Props = {
  handleSubmit: VoidFunction;
  submitting: boolean;
  organizations: ValueLabelModelPair<IOrganization>[];
};

export default function Form(props: Props) {
  const { handleSubmit, organizations, submitting } = props;
  const { reset, change } = useForm();
  const { handleClose } = useModal();
  const { t } = useLocales();
  const {
    hasValidationErrors,
    values: { organization_id: selectedOrganization }
  } = useFormState({ subscription: { values: true, hasValidationErrors: true } });

  const handleCloseModal = useCallback(() => {
    handleClose();
    reset();
  }, [handleClose, reset]);

  const handleSubmitForm = useCallback(() => {
    handleSubmit();
    handleCloseModal();
  }, [handleSubmit, handleCloseModal]);

  useEffect(() => {
    change('role', '');
  }, [change, selectedOrganization]);

  const possibleRoles = useMemo(() => {
    if (!selectedOrganization) {
      return [];
    }
    const organization = organizations.find(({ value }) => selectedOrganization === value);
    if (organization?.model.hasOwnerAccess) {
      return [
        {
          label: t('users.roles.user'),
          value: RolesEnum.User
        },
        {
          label: t('users.roles.manager'),
          value: RolesEnum.Manager
        }
      ];
    }
    if (organization?.model.hasManagerAccess) {
      return [
        {
          label: t('users.roles.user'),
          value: RolesEnum.User
        }
      ];
    }
    return [];
  }, [t, selectedOrganization, organizations]);

  return (
    <>
      <CardContent>
        <FormGrid>
          <TextInput name="email" formatOnBlur label={t('attributes.user.email')} />
          <OrganizationSelect options={organizations} name="organization_id" label={t('attributes.user_invitation.organization')} />
          <Select name="role" label={t('attributes.user.role')} placeholder={t('attributes.user.role')} options={possibleRoles} />
        </FormGrid>
      </CardContent>
      <ModalActions
        onClose={handleClose}
        onSave={handleSubmitForm}
        loading={submitting}
        disabled={hasValidationErrors}
        saveLabel={t('base.buttons.invite')}
      />
    </>
  );
}

import { types as t, Instance } from 'mobx-state-tree';
import Syncable from '~/mst/models/abstract/syncable';
import { ModelV2 as Model } from '~/mst/models/abstract/model';

const UserCalibrationModel = t.compose(
  t.model({
    params: t.string
  }),
  Model,
  Syncable
);

export interface IUserCalibrationModel extends Instance<typeof UserCalibrationModel> {}

export default UserCalibrationModel;

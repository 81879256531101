import { Box, styled } from '@mui/material';

export const AddWidgetPlaceholderStyled = styled(Box)(({ theme }) => ({
  border: `1px dashed ${theme.palette.primary.light}`,
  cursor: 'pointer',
  textTransform: 'capitalize',
  fontSize: 20,
  color: theme.palette.primary.light,
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

export const WidgetRegionStyled = styled(Box)(({ theme }) => ({
  margin: '-22px',
  '.react-grid-item': {
    '&:hover': {
      '.handle-se': {
        visibility: 'visible'
      }
    }
  },
  '.handle-se': {
    [theme.breakpoints.up('sm')]: {
      visibility: 'hidden'
    }
  }
}));

import { useEffect } from 'react';
import { Card, createFilterOptions, CardContent, Stack } from '@mui/material';
import { observer } from 'mobx-react-lite';

import { observable, action } from 'mobx';
import { useFormState } from 'react-final-form';
import useLocales from '~/hooks/use_locales';
import useMst from '~/hooks/use_mst';
import Autocomplete from '~/components/final_form/select';
import useFetch from '~/hooks/use_fetch';
import { ValueLabelModelPair } from '~/mst/models/abstract/collection_types';
import { IUser } from '~/mst/models/user';
import MCardHeader from '~/components/@material-extend/MCardHeader';
import { HeaderDeviceWrapperStyled } from '~/pages/device_edit/components/styled';
import { ListItemStyled, ExternalIdStyled } from '~/components/organizations_select/styled';

type Option = ValueLabelModelPair<IUser>;

const params = observable(
  {
    organization_id: null,
    setOrganizationId(value) {
      this.organization_id = value;
    }
  },
  {
    setOrganizationId: action
  }
);

const filterOptions = createFilterOptions({
  stringify: (option: Option) => `${option.label} ${option.model?.email}`
});

function SubscribersBlock() {
  const { t } = useLocales();
  const {
    values: { organization_id: organizationId }
  } = useFormState({ subscription: { values: true } });

  const { users } = useMst();

  useEffect(() => params.setOrganizationId(organizationId), [organizationId]);

  const { isFetching } = useFetch(users, params);

  return (
    <Card>
      <HeaderDeviceWrapperStyled>
        <MCardHeader title={t('alerts.subscribers')} />
      </HeaderDeviceWrapperStyled>
      <CardContent>
        <Stack spacing={3}>
          <Autocomplete
            searchable
            multiple
            filterOptions={filterOptions}
            renderOption={(option) => (
              <ListItemStyled>
                {option.label}
                <ExternalIdStyled className="ExternalId">{option.model?.email!}</ExternalIdStyled>
              </ListItemStyled>
            )}
            options={users.valueLabelPairs}
            name="configuration.subscribers"
            label={t('attributes.alert.subscriptions')}
            loading={isFetching}
            data-testid="reportSubscribersSelect"
          />
        </Stack>
      </CardContent>
    </Card>
  );
}

export default observer(SubscribersBlock);

/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import { TableCell, Box, CircularProgress, Stack } from '@mui/material';
import { getIdentifier } from 'mobx-state-tree';
import { observer } from 'mobx-react-lite';
import useMst from '~/hooks/use_mst';
import useFetch from '~/hooks/use_fetch';
import { DetailRowStyled } from '~/components/react_grid/styled';
import { INodeModelSnapshotOut } from '~/mst/models/node/model';
import DefaultDevice from './device';
import Sensor from './sensor';
import AirthinxParameter from './airthinx_parameter';

function GridDetailContainer({ row }: { row: INodeModelSnapshotOut }) {
  const { _id: nodeId } = row;
  const { nodes } = useMst();
  const node = nodes.getById(nodeId);

  const { isFetching } = useFetch(node, { last: 1, includes: ['profiles', 'configuration'] });

  return (
    <DetailRowStyled>
      <TableCell />
      <TableCell colSpan={7} sx={{ opacity: isFetching ? 0.25 : 1 }}>
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
          <Stack direction="row" gap={2} flexWrap="wrap">
            <DefaultDevice node={node} device={node?.thiamis} isAirthinx={node?.isAirthinx} />
            {node?.devices?.sensors?.map((sensor) => <Sensor device={sensor} key={getIdentifier(sensor)} />)}
            {node?.data_points?.defaultValueLabelPairsSortedByStatus?.map(({ model: dataPoint }) => (
              <AirthinxParameter key={getIdentifier(dataPoint)} dataPoint={dataPoint} />
            ))}
          </Stack>
        </Box>
      </TableCell>
      <TableCell />
      {isFetching && (
        <TableCell
          sx={{
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            zIndex: 9,
            position: 'absolute'
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '102px' }}>
            <CircularProgress size={30} color="info" />
          </Box>
        </TableCell>
      )}
    </DetailRowStyled>
  );
}

export default observer(GridDetailContainer);

/* eslint-disable @typescript-eslint/naming-convention */
import type { ICertificateModel } from '~/mst/models/certificate';
import type { ICertificatesModel } from './model';

export default (self: ICertificatesModel) => ({
  get filteredList() {
    return self.filteredModels.map((certficate: ICertificateModel) => ({
      _id: certficate._id,
      name: certficate.name,
      serial: certficate.serial,
      description: certficate.description
    }));
  }
});

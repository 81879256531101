import { types as t, Instance, SnapshotOut } from 'mobx-state-tree';
import Breakpoint from './breakpoint';

const LayoutModel = t
  .model({
    xl: t.maybeNull(Breakpoint),
    lg: t.maybeNull(Breakpoint),
    md: t.maybeNull(Breakpoint),
    sm: t.maybeNull(Breakpoint),
    xs: t.maybeNull(Breakpoint)
  })
  .named('Layout');

export interface ILayoutModel extends Instance<typeof LayoutModel> {}
export interface ILayoutModelSnapshotOut extends SnapshotOut<typeof LayoutModel> {}

export default LayoutModel;

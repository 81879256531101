/* eslint-disable import/prefer-default-export */
import request from '../request';

export function fetch(params, options) {
  return [request, 'get', 'organizations', params, options];
}

export function create(payload: any) {
  return request('post', 'organizations', payload);
}

export function update(id, payload: any) {
  return request('put', `organizations/${id}`, payload);
}

export function destroy(id) {
  return request('delete', `organizations/${id}`);
}

import { Typography } from '@mui/material';
import { ContainerGridStyled } from '~/components/react_grid/styled';
import Container from '~/components/container';
import useLocales from '~/hooks/use_locales';

import FormTop from './form_top';
import InvitationsGrid from './grid_container';

export default function Invitations() {
  const { t } = useLocales();

  return (
    <Container>
      <Typography variant="h4" component="h1" paragraph>
        {t('invitations.title')}
      </Typography>
      <ContainerGridStyled>
        <FormTop />
        <InvitationsGrid />
      </ContainerGridStyled>
    </Container>
  );
}

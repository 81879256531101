import { observer } from 'mobx-react-lite';
import { Box, Grid, Stack } from '@mui/material';

import type { IAlert } from '~/mst/models/alert';
import type { IAlertMonitor } from '~/pages/alert_monitor/store';

import BasicInfoBlock from './basic_info_block';
import SubscribersBlock from './subscribers_block';
import ChartTableBlock from './chart_table_block';
import ConditionsBlock from './conditions_block';

type AlertMonitorPagePropsType = {
  alert: IAlert;
  monitor: IAlertMonitor;
};

function AlertMonitorPage({ alert, monitor }: AlertMonitorPagePropsType) {
  return (
    <Stack>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <ChartTableBlock widget={monitor.chartTableWidget} monitor={monitor} alert={alert} />
        </Grid>
        <Grid item xs={12} md={4}>
          <Box mb={3}>
            <BasicInfoBlock alert={alert} />
          </Box>
          <Box mb={3}>
            <ConditionsBlock alert={alert} />
          </Box>
          <SubscribersBlock alert={alert} />
        </Grid>
      </Grid>
    </Stack>
  );
}

export default observer(AlertMonitorPage);

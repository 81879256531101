import { Typography } from '@mui/material';
import { ContainerGridStyled } from '~/components/react_grid/styled';
import Container from '~/components/container';
import useLocales from '~/hooks/use_locales';

import FormTop from './form_top';
import ArchivesGrid from './grid_container';

export default function Archives() {
  const { t } = useLocales();

  return (
    <Container>
      <Typography variant="h4" component="h1" paragraph>
        {t('archives.title')}
      </Typography>
      <ContainerGridStyled>
        <FormTop />
        <ArchivesGrid />
      </ContainerGridStyled>
    </Container>
  );
}

import React, { useCallback, useMemo } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { getIdentifier } from 'mobx-state-tree';
import snakeCase from 'lodash/snakeCase';
import { datadogRum } from '@datadog/browser-rum';
import compact from 'lodash/fp/compact';
import flatten from 'lodash/flatten';
import isEmpty from 'lodash/isEmpty';

import Yup, { parseDate } from '~/utils/yup';
import { formatDateFromTs } from '~/utils/format_date';
import useSelection from '~/hooks/react_grid/use_selection';

import { useValidationSchema } from '~/hooks/use_validate';

import useDownloadForm from '~/hooks/use_download_form';
import useModal from '~/contexts/modal_context';

import { INode } from '~/mst/models/node';
import { IDataPointNode } from '~/mst/models/data_point/node/default';
import { ValueLabelPair } from '~/mst/models/abstract/collection_types';
import { captureException } from '~/services/sentry';
import toasts from '~/utils/toasts';
import useLocales from '~/hooks/use_locales';
import useMst from '~/hooks/use_mst';
import ModalForm from './modal_form';

interface DownloadFormData {
  data_point_id: string[];
  timezone: string;
  filename?: string;
  from: number;
  to?: number;
}

interface DownloadFormValues {
  devices: Array<ValueLabelPair & { model: INode }>;
  parameters: Array<ValueLabelPair & { model: IDataPointNode }>;
  from: Date;
  to?: Date;
  timezone: string;
}

const DownloadFormSchema = Yup.object().shape({
  devices: Yup.array().required('Required').min(1),
  parameters: Yup.array().required('Required').min(1),
  from: Yup.date().transform(parseDate).required('Required'),
  to: Yup.date().transform(parseDate).nullable()
});

function DownloadModal() {
  const { handleClose: handleCloseModal } = useModal();
  const { t } = useLocales();
  const { nodes } = useMst();
  const { selection: selectedDevices } = useSelection();

  const validate = useValidationSchema(DownloadFormSchema);

  const handleClose = useCallback(() => {
    handleCloseModal();
  }, [handleCloseModal]);

  const { DownloadForm, setData } = useDownloadForm(handleClose);

  const handleDownload = useCallback(
    (values: DownloadFormValues) => {
      try {
        const { devices, from, to, parameters, timezone } = values;
        const devicesAll = compact(devices.map((option) => nodes.getById(option?.value || option)));
        const dataPointIds = flatten(parameters.map(({ models }) => models.map((model) => getIdentifier(model))));

        let filename = devicesAll
          .slice(0, 5)
          .map((node: INode) => node.serial)
          .join('_');

        filename = `${filename}_${snakeCase(formatDateFromTs(from))}`;
        const formData = {
          data_point_id: dataPointIds,
          from,
          ...(!isEmpty(timezone) && { timezone })
        } as DownloadFormData;

        if (to) {
          formData.to = to;
          filename = `${filename}_${snakeCase(formatDateFromTs(to))}`;
        }

        formData.filename = `${filename}.csv`;

        setData(formData);
        datadogRum.addAction('download_parameters_csv', formData);
      } catch (e) {
        captureException(e);
        toasts.error(t('notifications.errors.server_error'));
      }
    },
    [setData, t, nodes]
  );

  const initialValues = useMemo(() => ({ devices: selectedDevices }), [selectedDevices]);

  return (
    <>
      <FinalForm onSubmit={handleDownload} validate={validate} initialValues={initialValues}>
        {({ handleSubmit }) => <ModalForm handleCancel={handleClose} handleSubmit={handleSubmit} />}
      </FinalForm>
      <DownloadForm method="POST" actionUrl="search/export" />
    </>
  );
}

export default DownloadModal;

export default (theme) => {
  const {
    water,
    waterLabelsText,
    road,
    administrativeCountryText,
    landscapeManMade,
    landscapeNatural,
    roadArterial,
    roadHighway,
    roadHighwayStroke,
    administrativeCountryStroke
  } = theme.palette.map;

  return [
    {
      featureType: 'administrative',
      elementType: 'labels.text.stroke',
      stylers: [
        {
          color: administrativeCountryStroke
        }
      ]
    },
    {
      featureType: 'administrative.country',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: administrativeCountryText
        }
      ]
    },
    {
      featureType: 'administrative.land_parcel',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'administrative.locality',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: administrativeCountryText
        }
      ]
    },
    {
      featureType: 'administrative.neighborhood',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: administrativeCountryText
        }
      ]
    },
    {
      featureType: 'administrative.province',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: administrativeCountryText
        }
      ]
    },
    {
      featureType: 'landscape.man_made',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: landscapeManMade
        }
      ]
    },
    {
      featureType: 'landscape.natural',
      stylers: [
        {
          color: landscapeNatural
        }
      ]
    },
    {
      featureType: 'landscape.natural',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: landscapeNatural
        },
        {
          saturation: -35
        }
      ]
    },
    {
      featureType: 'poi',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'road.arterial',
      elementType: 'labels.text',
      stylers: [
        {
          color: roadArterial
        }
      ]
    },
    {
      featureType: 'road.arterial',
      elementType: 'labels.text.stroke',
      stylers: [
        {
          color: landscapeManMade
        }
      ]
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: roadHighway
        }
      ]
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.stroke',
      stylers: [
        {
          color: roadHighwayStroke
        }
      ]
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.text',
      stylers: [
        {
          color: roadArterial
        }
      ]
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.text.stroke',
      stylers: [
        {
          color: landscapeManMade
        }
      ]
    },
    {
      featureType: 'road.highway.controlled_access',
      elementType: 'labels.text',
      stylers: [
        {
          color: roadArterial
        }
      ]
    },
    {
      featureType: 'road.highway.controlled_access',
      elementType: 'labels.text.stroke',
      stylers: [
        {
          color: landscapeManMade
        }
      ]
    },
    {
      featureType: 'road.local',
      elementType: 'labels.text',
      stylers: [
        {
          color: roadArterial
        }
      ]
    },
    {
      featureType: 'road.local',
      elementType: 'labels.text.stroke',
      stylers: [
        {
          color: road
        }
      ]
    },
    {
      featureType: 'water',
      stylers: [
        {
          color: water
        }
      ]
    },
    {
      featureType: 'water',
      elementType: 'labels.text',
      stylers: [
        {
          color: waterLabelsText
        }
      ]
    }
  ];
};

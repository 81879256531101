/* eslint-disable @typescript-eslint/naming-convention */
import includes from 'lodash/includes';
import { getStore } from '~/mst/utils';
import { NodeActions } from './types';
import { INodeBase } from './model';

export default (self: INodeBase) => ({
  get isCreatable() {
    return includes(self.actions, NodeActions.Create);
  },
  get isMonitorable() {
    return includes(self.actions, NodeActions.Read);
  },
  get isDeletable() {
    return includes(self.actions, NodeActions.Delete);
  },
  get isModifiable() {
    return includes(self.actions, NodeActions.Configure);
  },
  get owner() {
    return getStore(self).organizations.getById(self.organization_id);
  }
});

import { types as t, Instance } from 'mobx-state-tree';
import NodeWidget from '~/mst/models/widget/node_widget';
import CollectionMap from '~/mst/models/abstract/collection_map';
import DebugWidget from '../debug_widget';
import FilesWidget from '../files_widget';

const WidgetsModel = CollectionMap(
  t.union(
    {
      dispatcher(snapshot) {
        const { type } = snapshot;
        switch (type) {
          case 'DebugWidget':
            return DebugWidget;
          case 'NodeWidget':
            return NodeWidget;
          case 'FilesWidget':
            return FilesWidget;
          default:
            throw new Error(`Unkown widget type ${type}`);
        }
      }
    },
    NodeWidget,
    FilesWidget,
    DebugWidget
  )
);

export interface IWidgetsModel extends Instance<typeof WidgetsModel> {}

export default WidgetsModel;

/* eslint-disable */
import React from 'react';
import { IconProps } from '../types';

export default function HeatmapWidgetIcon({ sx }: IconProps) {
  return (
    <svg width={sx ? sx.width : '100%'} height={sx ? sx.height : '100%'} viewBox="0 0 32 30" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_42_5930)">
        <path
          d="M8.27 0H3.34C1.5 0 0 1.5 0 3.34V8.27C0 10.11 1.5 11.61 3.34 11.61H8.27C10.11 11.61 11.61 10.11 11.61 8.27V3.34C11.61 1.5 10.11 0 8.27 0ZM9.11 8.27C9.11 8.73 8.73 9.11 8.27 9.11H3.34C2.88 9.11 2.5 8.73 2.5 8.27V3.34C2.5 2.88 2.88 2.5 3.34 2.5H8.27C8.73 2.5 9.11 2.88 9.11 3.34V8.27Z"
          fill="#1890FF"
        />
        <path
          d="M19.7604 2.63H17.0904C15.3504 2.63 13.9404 4.05 13.9404 5.79V8.46C13.9404 10.2 15.3604 11.61 17.0904 11.61H19.7604C21.5004 11.61 22.9204 10.19 22.9204 8.46V5.79C22.9204 4.05 21.5004 2.63 19.7604 2.63ZM20.4204 8.46C20.4204 8.82 20.1304 9.11 19.7604 9.11H17.0904C16.7304 9.11 16.4404 8.82 16.4404 8.46V5.79C16.4404 5.43 16.7304 5.13 17.0904 5.13H19.7604C20.1204 5.13 20.4204 5.42 20.4204 5.79V8.46Z"
          fill="#1890FF"
        />
        <path
          d="M8.45988 12.91H5.78988C4.04988 12.91 2.62988 14.33 2.62988 16.07V18.74C2.62988 20.48 4.04988 21.9 5.78988 21.9H8.45988C10.1999 21.9 11.6099 20.48 11.6099 18.74V16.07C11.6099 14.33 10.1899 12.91 8.45988 12.91ZM9.10988 18.74C9.10988 19.1 8.81988 19.4 8.45988 19.4H5.78988C5.42988 19.4 5.12988 19.11 5.12988 18.74V16.07C5.12988 15.71 5.41988 15.41 5.78988 15.41H8.45988C8.81988 15.41 9.10988 15.7 9.10988 16.07V18.74Z"
          fill="#1890FF"
        />
        <path
          d="M18.7598 12.91H17.2498C15.1898 12.91 13.5098 14.59 13.5098 16.65V18.16C13.5098 20.22 15.1898 21.9 17.2498 21.9H18.7598C20.8198 21.9 22.4998 20.22 22.4998 18.16V16.65C22.4998 14.59 20.8198 12.91 18.7598 12.91ZM19.9998 18.16C19.9998 18.84 19.4498 19.4 18.7598 19.4H17.2498C16.5698 19.4 16.0098 18.85 16.0098 18.16V16.65C16.0098 15.97 16.5598 15.41 17.2498 15.41H18.7598C19.4398 15.41 19.9998 15.96 19.9998 16.65V18.16Z"
          fill="#FFC107"
        />
        <path
          d="M28.3802 4.95996H27.6802C26.0402 4.95996 24.7002 6.29996 24.7002 7.93996V8.63996C24.7002 10.28 26.0402 11.62 27.6802 11.62H28.3802C30.0202 11.62 31.3602 10.28 31.3602 8.63996V7.93996C31.3602 6.29996 30.0202 4.95996 28.3802 4.95996ZM28.8602 8.63996C28.8602 8.90996 28.6402 9.11996 28.3802 9.11996H27.6802C27.4102 9.11996 27.2002 8.89996 27.2002 8.63996V7.93996C27.2002 7.66996 27.4202 7.45996 27.6802 7.45996H28.3802C28.6502 7.45996 28.8602 7.67996 28.8602 7.93996V8.63996Z"
          fill="#FFC107"
        />
        <path
          d="M28.3802 14.0699H27.6802C26.0402 14.0699 24.7002 15.4099 24.7002 17.0499V17.7499C24.7002 19.3899 26.0402 20.7299 27.6802 20.7299H28.3802C30.0202 20.7299 31.3602 19.3899 31.3602 17.7499V17.0499C31.3602 15.4099 30.0202 14.0699 28.3802 14.0699ZM28.8602 17.7499C28.8602 18.0199 28.6402 18.2299 28.3802 18.2299H27.6802C27.4102 18.2299 27.2002 18.0099 27.2002 17.7499V17.0499C27.2002 16.7799 27.4202 16.5699 27.6802 16.5699H28.3802C28.6502 16.5699 28.8602 16.7899 28.8602 17.0499V17.7499Z"
          fill="#F04C48"
        />
        <path
          d="M8.63996 23.1899H7.93996C6.29996 23.1899 4.95996 24.5299 4.95996 26.1699V26.8699C4.95996 28.5099 6.29996 29.8499 7.93996 29.8499H8.63996C10.28 29.8499 11.62 28.5099 11.62 26.8699V26.1699C11.62 24.5299 10.28 23.1899 8.63996 23.1899ZM9.11996 26.8699C9.11996 27.1399 8.89996 27.3499 8.63996 27.3499H7.93996C7.66996 27.3499 7.45996 27.1299 7.45996 26.8699V26.1699C7.45996 25.9099 7.67996 25.6899 7.93996 25.6899H8.63996C8.90996 25.6899 9.11996 25.9099 9.11996 26.1699V26.8699Z"
          fill="#FFC107"
        />
        <path
          d="M18.3597 23.1899H17.6597C16.0197 23.1899 14.6797 24.5299 14.6797 26.1699V26.8699C14.6797 28.5099 16.0197 29.8499 17.6597 29.8499H18.3597C19.9997 29.8499 21.3397 28.5099 21.3397 26.8699V26.1699C21.3397 24.5299 19.9997 23.1899 18.3597 23.1899ZM18.8397 26.8699C18.8397 27.1399 18.6197 27.3499 18.3597 27.3499H17.6597C17.3897 27.3499 17.1797 27.1299 17.1797 26.8699V26.1699C17.1797 25.9099 17.3997 25.6899 17.6597 25.6899H18.3597C18.6297 25.6899 18.8397 25.9099 18.8397 26.1699V26.8699Z"
          fill="#F04C48"
        />
        <path
          d="M28.0804 23.1899H27.3804C25.7404 23.1899 24.4004 24.5299 24.4004 26.1699V26.8699C24.4004 28.5099 25.7404 29.8499 27.3804 29.8499H28.0804C29.7204 29.8499 31.0604 28.5099 31.0604 26.8699V26.1699C31.0604 24.5299 29.7204 23.1899 28.0804 23.1899ZM28.5604 26.8699C28.5604 27.1399 28.3404 27.3499 28.0804 27.3499H27.3804C27.1104 27.3499 26.9004 27.1299 26.9004 26.8699V26.1699C26.9004 25.9099 27.1204 25.6899 27.3804 25.6899H28.0804C28.3504 25.6899 28.5604 25.9099 28.5604 26.1699V26.8699Z"
          fill="#F04C48"
        />
        <g clipPath="url(#clip1_42_5930)">
          <path
            d="M8.27 0H3.34C1.5 0 0 1.5 0 3.34V8.27C0 10.11 1.5 11.61 3.34 11.61H8.27C10.11 11.61 11.61 10.11 11.61 8.27V3.34C11.61 1.5 10.11 0 8.27 0ZM9.11 8.27C9.11 8.73 8.73 9.11 8.27 9.11H3.34C2.88 9.11 2.5 8.73 2.5 8.27V3.34C2.5 2.88 2.88 2.5 3.34 2.5H8.27C8.73 2.5 9.11 2.88 9.11 3.34V8.27Z"
            fill="#C0D5E8"
          />
          <path
            d="M19.7604 2.63H17.0904C15.3504 2.63 13.9404 4.05 13.9404 5.79V8.46C13.9404 10.2 15.3604 11.61 17.0904 11.61H19.7604C21.5004 11.61 22.9204 10.19 22.9204 8.46V5.79C22.9204 4.05 21.5004 2.63 19.7604 2.63ZM20.4204 8.46C20.4204 8.82 20.1304 9.11 19.7604 9.11H17.0904C16.7304 9.11 16.4404 8.82 16.4404 8.46V5.79C16.4404 5.43 16.7304 5.13 17.0904 5.13H19.7604C20.1204 5.13 20.4204 5.42 20.4204 5.79V8.46Z"
            fill="#C0D5E8"
          />
          <path
            d="M8.45988 12.91H5.78988C4.04988 12.91 2.62988 14.33 2.62988 16.07V18.74C2.62988 20.48 4.04988 21.9 5.78988 21.9H8.45988C10.1999 21.9 11.6099 20.48 11.6099 18.74V16.07C11.6099 14.33 10.1899 12.91 8.45988 12.91ZM9.10988 18.74C9.10988 19.1 8.81988 19.4 8.45988 19.4H5.78988C5.42988 19.4 5.12988 19.11 5.12988 18.74V16.07C5.12988 15.71 5.41988 15.41 5.78988 15.41H8.45988C8.81988 15.41 9.10988 15.7 9.10988 16.07V18.74Z"
            fill="#C0D5E8"
          />
          <path
            d="M18.7598 12.91H17.2498C15.1898 12.91 13.5098 14.59 13.5098 16.65V18.16C13.5098 20.22 15.1898 21.9 17.2498 21.9H18.7598C20.8198 21.9 22.4998 20.22 22.4998 18.16V16.65C22.4998 14.59 20.8198 12.91 18.7598 12.91ZM19.9998 18.16C19.9998 18.84 19.4498 19.4 18.7598 19.4H17.2498C16.5698 19.4 16.0098 18.85 16.0098 18.16V16.65C16.0098 15.97 16.5598 15.41 17.2498 15.41H18.7598C19.4398 15.41 19.9998 15.96 19.9998 16.65V18.16Z"
            fill="#AAC1D7"
          />
          <path
            d="M28.3802 4.95996H27.6802C26.0402 4.95996 24.7002 6.29996 24.7002 7.93996V8.63996C24.7002 10.28 26.0402 11.62 27.6802 11.62H28.3802C30.0202 11.62 31.3602 10.28 31.3602 8.63996V7.93996C31.3602 6.29996 30.0202 4.95996 28.3802 4.95996ZM28.8602 8.63996C28.8602 8.90996 28.6402 9.11996 28.3802 9.11996H27.6802C27.4102 9.11996 27.2002 8.89996 27.2002 8.63996V7.93996C27.2002 7.66996 27.4202 7.45996 27.6802 7.45996H28.3802C28.6502 7.45996 28.8602 7.67996 28.8602 7.93996V8.63996Z"
            fill="#AAC1D7"
          />
          <path
            d="M28.3802 14.0699H27.6802C26.0402 14.0699 24.7002 15.4099 24.7002 17.0499V17.7499C24.7002 19.3899 26.0402 20.7299 27.6802 20.7299H28.3802C30.0202 20.7299 31.3602 19.3899 31.3602 17.7499V17.0499C31.3602 15.4099 30.0202 14.0699 28.3802 14.0699ZM28.8602 17.7499C28.8602 18.0199 28.6402 18.2299 28.3802 18.2299H27.6802C27.4102 18.2299 27.2002 18.0099 27.2002 17.7499V17.0499C27.2002 16.7799 27.4202 16.5699 27.6802 16.5699H28.3802C28.6502 16.5699 28.8602 16.7899 28.8602 17.0499V17.7499Z"
            fill="#7998B5"
          />
          <path
            d="M8.63996 23.1899H7.93996C6.29996 23.1899 4.95996 24.5299 4.95996 26.1699V26.8699C4.95996 28.5099 6.29996 29.8499 7.93996 29.8499H8.63996C10.28 29.8499 11.62 28.5099 11.62 26.8699V26.1699C11.62 24.5299 10.28 23.1899 8.63996 23.1899ZM9.11996 26.8699C9.11996 27.1399 8.89996 27.3499 8.63996 27.3499H7.93996C7.66996 27.3499 7.45996 27.1299 7.45996 26.8699V26.1699C7.45996 25.9099 7.67996 25.6899 7.93996 25.6899H8.63996C8.90996 25.6899 9.11996 25.9099 9.11996 26.1699V26.8699Z"
            fill="#AAC1D7"
          />
          <path
            d="M18.3597 23.1899H17.6597C16.0197 23.1899 14.6797 24.5299 14.6797 26.1699V26.8699C14.6797 28.5099 16.0197 29.8499 17.6597 29.8499H18.3597C19.9997 29.8499 21.3397 28.5099 21.3397 26.8699V26.1699C21.3397 24.5299 19.9997 23.1899 18.3597 23.1899ZM18.8397 26.8699C18.8397 27.1399 18.6197 27.3499 18.3597 27.3499H17.6597C17.3897 27.3499 17.1797 27.1299 17.1797 26.8699V26.1699C17.1797 25.9099 17.3997 25.6899 17.6597 25.6899H18.3597C18.6297 25.6899 18.8397 25.9099 18.8397 26.1699V26.8699Z"
            fill="#7998B5"
          />
          <path
            d="M28.0804 23.1899H27.3804C25.7404 23.1899 24.4004 24.5299 24.4004 26.1699V26.8699C24.4004 28.5099 25.7404 29.8499 27.3804 29.8499H28.0804C29.7204 29.8499 31.0604 28.5099 31.0604 26.8699V26.1699C31.0604 24.5299 29.7204 23.1899 28.0804 23.1899ZM28.5604 26.8699C28.5604 27.1399 28.3404 27.3499 28.0804 27.3499H27.3804C27.1104 27.3499 26.9004 27.1299 26.9004 26.8699V26.1699C26.9004 25.9099 27.1204 25.6899 27.3804 25.6899H28.0804C28.3504 25.6899 28.5604 25.9099 28.5604 26.1699V26.8699Z"
            fill="#7998B5"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_42_5930">
          <rect width="31.36" height="29.85" fill="white" />
        </clipPath>
        <clipPath id="clip1_42_5930">
          <rect width="31.36" height="29.85" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

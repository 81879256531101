// @ts-nocheck
import { Duration } from 'luxon';
import I18n from '~/utils/i18n';

const { VITE_API_URL_ROOT } = process.env;

export const MIN_REPORTING_INTERVAL = Duration.fromDurationLike({ minutes: 3 }).toMillis();

export enum THEMES {
  DARK = 'dark',
  LIGHT = 'light'
}

export enum AirthixParameterPaths {
  AQ = '132:19',
  PM = '132:18',
  PM1 = '132:4',
  PM10 = '132:6',
  PM25 = '132:5',
  CO2 = '131:10',
  VOC_ETOH = '140:11',
  VOC_ISOBUTYLENE = '140:12',
  CH2O = '132:13',
  HUMIDITY = '132:15',
  TEMPERATURE_F = '132:24',
  TEMPERATURE_C = '132:14'
}

export const PATHS = {
  BATTERY_VOLTAGE: '0:1',
  BATTERY_CURRENT: '0:2',
  GSM_NETWORK: '0:3',
  GSM_SIGNAL: '0:4',
  IMEI: '0:5',
  IMSI: '0:7',
  ICCID: '0:6',
  LOCATION: '0:8',
  WIFI_SIGNAL: '0:10',
  WIFI_SECURITY: '0:11',
  WIFI_NETWORK: '0:12',
  WIFI_BSSID: '0:13',
  FIRMWARE: '0:15',
  DEBUG: '0:16',
  TIMEZONE: '0:18',
  LOCATION_DESCRIPTION: '0:17',
  TEMPERATURE: '0:19',
  MAC_ADDRESS: '0:21',
  ONLINE: '0:22',
  GPS_ERROR: '0:26',
  WIFI_NETWORKS: '0:30',
  GPS_SOURCE: '0:31',
  VOC_CALIBRATION_1_PPM: '140:21',
  VOC_CALIBRATION_1_VALUE: '140:22',
  VOC_CALIBRATION_2_PPM: '140:23',
  VOC_CALIBRATION_2_VALUE: '140:24',
  VOC_CALIBRATION_3_PPM: '140:25',
  VOC_CALIBRATION_3_VALUE: '140:26',
  AQ: '132:19',
  PM: '132:18',
  PM1: '132:4',
  PM10: '132:6',
  PM25: '132:5',
  CO2: '131:10',
  VOC_ETOH: '140:11',
  VOC_ISOBUTYLENE: '140:12',
  CH2O: '132:13',
  PRESSURE: '0:20',
  HUMIDITY: '132:15',
  TEMPERATURE_F: '132:24',
  TEMPERATURE_C: '132:14'
};

export const TEMPERATURE_UNITS = {
  C: '°C',
  F: '°F'
};

export const SENSOR_STATUSES = {
  REPORTING: 'OK',
  NOT_REPORTING: ''
};

export const CALIBRATION_STATUSES = {
  COMPLETED: 'ok',
  FAILED: 'fail',
  NOT_RUN: 'not_run'
};

export const CALIBRATION_STATUS_WEIGHTS = {
  [CALIBRATION_STATUSES.COMPLETED]: 0,
  [CALIBRATION_STATUSES.NOT_RUN]: 1,
  [CALIBRATION_STATUSES.FAILED]: 2
};

export const AQ_DEVICE_SERIALS = ['0C', '1C'];

export const DEVICE_STATUSES = {
  ACTIVATED: 'activated',
  INVENTORY: 'inventory',
  DEACTIVATED: 'deactivated',
  HIBERNATED: 'hibernated',
  ONLINE: 'online',
  OFFLINE: 'offline',
  ERROR: 'error',
  GOOD: 'good',
  POOR: 'poor',
  VERYPOOR: 'veryPoor',
  SATISFACTORY: 'satisfactory',
  MODERATE: 'moderate',
  EXCELLENT: 'excellent'
};

export const AQ_STATUSES = [DEVICE_STATUSES.GOOD, DEVICE_STATUSES.MODERATE, DEVICE_STATUSES.POOR, DEVICE_STATUSES.VERYPOOR];

export const ALERT_STATUSES = {
  ACTIVE: 'active',
  RESOLVED: 'resolved',
  IDLE: 'idle'
};

export const STATUS_WEIGHTS = {
  [DEVICE_STATUSES.DEACTIVATED]: 0,
  [DEVICE_STATUSES.HIBERNATED]: 0,
  [DEVICE_STATUSES.OFFLINE]: 1,
  [DEVICE_STATUSES.ONLINE]: 2,
  [DEVICE_STATUSES.ACTIVATED]: 3,
  [DEVICE_STATUSES.EXCELLENT]: 4,
  [DEVICE_STATUSES.GOOD]: 5,
  [DEVICE_STATUSES.SATISFACTORY]: 6,
  [DEVICE_STATUSES.MODERATE]: 7,
  [DEVICE_STATUSES.POOR]: 8,
  [DEVICE_STATUSES.VERYPOOR]: 9
};

export enum RolesEnum {
  Owner = 'owner',
  Manager = 'manager',
  User = 'user',
  Support = 'support',
  Partner = 'partner',
  Admin = 'admin'
}

export const Roles = [
  { label: I18n.t('users.roles.user'), value: RolesEnum.User },
  { label: I18n.t('users.roles.manager'), value: RolesEnum.Manager },
  { label: I18n.t('users.roles.owner'), value: RolesEnum.Owner }
];

export enum NoticeMethodsEnum {
  SMS = 'sms',
  EMAIL = 'email',
  EMAIL_SMS = 'email_sms'
}

export enum AcceptanceState {
  filed = 'filed',
  declined = 'declined',
  accepted = 'accepted'
}

export const SOCKET_URL = `wss://${VITE_API_URL_ROOT?.split('://')[1]}socket`;

export const SIGNAL_LEVELS = [-65, -75, -85, -95, -105];

// TODO add localization + pluralization
export const REPORTING_INTERVALS = [
  { label: '1 minute', value: Duration.fromDurationLike({ minutes: 1 }).toMillis() },
  { label: '5 minutes', value: Duration.fromDurationLike({ minutes: 5 }).toMillis() },
  { label: '15 minutes', value: Duration.fromDurationLike({ minutes: 15 }).toMillis() }
];

export const MAX_PARAMS_SELECTED = 3;
export const CONDITION_MAX_PERIOD_MINUTES = 360;
export const THIAMIS_BATTERY_MIN_REPORTING_INTERVAL = 300000;

import { useEffect } from 'react';
import useMst from '~/hooks/use_mst';
import useSubscribable from '~/hooks/use_subscribable_mst';

const useDevicesSubscribe = (ids: string[]) => {
  const { nodes } = useMst();
  const { subscribe, unsubscribe } = useSubscribable((dataPoints) => {
    dataPoints?.forEach((dataPoint) => {
      const { _id: dataPointId, measurements } = dataPoint;
      nodes?.getNodeByDataPointId(dataPointId)?.data_points?.getById(dataPointId)?.updateMeasurements(measurements);
    });
  });

  useEffect(() => {
    if (ids) {
      subscribe(ids);
    }
    return () => unsubscribe();
  }, [ids, subscribe, unsubscribe]);
};

export default useDevicesSubscribe;

/* eslint-disable react/require-default-props */
import { useState } from 'react';
import { Outlet } from 'react-router-dom';

import { useTheme } from '@mui/material/styles';

import Header from '~/components/header/header';
import Sidebar from '~/components/sidebar';

import useCollapseDrawer from '../../hooks/use_collapse_drawer';
import { DefaultLayoutMainStyled, DefaultLayoutRootStyled } from './styled';

export default function DefaultLayout() {
  const theme = useTheme();
  const { collapseClick } = useCollapseDrawer();
  const [open, setOpen] = useState(false);

  return (
    <DefaultLayoutRootStyled>
      <Header onOpenSidebar={() => setOpen(true)} />
      <Sidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      <DefaultLayoutMainStyled
        sx={{
          transition: theme.transitions.create('margin', {
            duration: `${theme.animations.duration}s`
          }),
          ...(collapseClick && {
            ml: '102px'
          })
        }}
      >
        <Outlet />
      </DefaultLayoutMainStyled>
    </DefaultLayoutRootStyled>
  );
}

/* eslint-disable @typescript-eslint/no-use-before-define */
import { types as t } from 'mobx-state-tree';
import isString from 'lodash/isString';
import isNumber from 'lodash/isNumber';
import isBoolean from 'lodash/isBoolean';

const BitConfigType = t.custom({
  name: 'BitConfig',
  fromSnapshot: (value) => {
    return value;
  },
  toSnapshot: (value) => {
    if (value === null) {
      return null;
    }
    if (value === undefined) {
      return value;
    }
    return +value;
  },
  isTargetType: () => false,
  getValidationMessage: (snapshot) => {
    const isNumeric = isString(snapshot) || isNumber(snapshot) || isBoolean(snapshot);
    if (isNumeric && [0, 1].includes(+snapshot)) {
      return '';
    }
    return 'must be 0 or 1';
  }
});

export default BitConfigType;

import { useState, useCallback } from 'react';
import { SortingState, Sorting, IntegratedSorting } from '@devexpress/dx-react-grid';
import { Grid, VirtualTable, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { observer } from 'mobx-react-lite';

import { GridStyled } from '~/components/react_grid/styled';
import { Container } from '~/components/react_grid';

import { IUser } from '~/mst/models/user';
import I18n from '~/utils/i18n';

import useMst from '~/hooks/use_mst';
import useLoading from '~/hooks/react_grid/use_loading';
import { PictureProvider, CreatedAtProvider, RolesProvider, ActionsProvider } from './info_providers';

const getRowId = (row: IUser) => row.id;

function compareDates(a: any, b: any) {
  // @ts-ignore
  return (b != null) - (a != null) || b - a;
}

const columns = [
  { name: 'picture', title: ' ', sortingEnabled: false },
  { name: 'fullName', title: I18n.t('attributes.user.name') },
  { name: 'roles', title: I18n.t('attributes.user.role'), sortingEnabled: false },
  { name: 'email', title: I18n.t('attributes.user.email') },
  { name: 'createdAt', title: I18n.t('attributes.common.created_at') },
  { name: 'lastLogin', title: I18n.t('attributes.user.last_login') },
  { name: 'actions', title: ' ', sortingEnabled: false }
];

const columnExtensions = [
  { columnName: 'picture', wordWrapEnabled: true, width: 75 },
  { columnName: 'fullName', wordWrapEnabled: true },
  { columnName: 'createdAt', wordWrapEnabled: true, width: 150 },
  { columnName: 'lastLogin', wordWrapEnabled: true, width: 150 },
  { columnName: 'actions', width: 65 }
];

function UsersGrid() {
  const { users } = useMst();
  const { messages, RootComponent } = useLoading(users);
  const [sort, setSort] = useState<Sorting[]>([
    { columnName: 'lastLogin', direction: 'asc' },
    { columnName: 'fullName', direction: 'desc' }
  ]);

  const rows = users.filteredList;

  const handleSortChange = useCallback((value) => setSort(value), [setSort]);

  return (
    <GridStyled>
      <Grid rows={rows} columns={columns} getRowId={getRowId} rootComponent={RootComponent}>
        <SortingState sorting={sort} onSortingChange={handleSortChange} />
        <IntegratedSorting
          columnExtensions={[
            { columnName: 'createdAt', compare: compareDates },
            { columnName: 'lastLogin', compare: compareDates }
          ]}
        />

        <ActionsProvider for={['actions']} />
        <PictureProvider for={['picture']} />
        <RolesProvider for={['roles']} />
        <CreatedAtProvider for={['createdAt', 'lastLogin']} />
        <VirtualTable height="auto" messages={messages} columnExtensions={columnExtensions} containerComponent={Container} />
        <TableHeaderRow showSortingControls />
      </Grid>
    </GridStyled>
  );
}

export default observer(UsersGrid);

/* eslint-disable @typescript-eslint/no-use-before-define */
import { IDataPointMetricModel } from './model';

export default (self: IDataPointMetricModel) => ({
  get presentName() {
    return self.conditions.map((condition) => condition.presentName).join(' AND ');
  },
  get shortPresentName() {
    return self.conditions.map((condition) => condition.shortPresentName).join(' AND ');
  },
  get data() {
    return self.measurements.map(([ts, value]) => (Array.isArray(value) ? [ts, value[0]] : [ts, value]));
  }
});

import { types as t, Instance, SnapshotOut } from 'mobx-state-tree';
import { NodeTypes } from './types';

export const NodeBase = t.model({
  _type: t.frozen(t.maybeNull(t.enumeration('NodeType', Object.values(NodeTypes)))),
  serial: t.maybeNull(t.frozen(t.refinement(t.string, (value) => value.length === 8))),
  name: t.optional(t.string, ''),
  description: t.optional(t.string, ''),
  actions: t.maybeNull(t.frozen(t.array(t.string))),
  organization_id: t.maybeNull(t.string)
});

export interface INodeBase extends Instance<typeof NodeBase> {}
export interface INodeBaseSnapshotOut extends SnapshotOut<typeof NodeBase> {}

export default NodeBase;

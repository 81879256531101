import { styled } from '@mui/material/styles';
import { Theme } from '@mui/system';

import { transientOptions } from '~/utils/styled';

export const ContentLayoutRootStyled = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

export const ContentLayoutMainStyled = styled(
  'div',
  transientOptions
)(({ $noPadding, theme }: { $noPadding: boolean; theme: Theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingBottom: $noPadding ? theme.spacing(0) : theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    marginTop: $noPadding ? theme.spacing(0) : theme.spacing(3),
    paddingLeft: $noPadding ? theme.spacing(0) : theme.spacing(4),
    paddingRight: $noPadding ? theme.spacing(0) : theme.spacing(4)
  }
}));

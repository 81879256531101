import React, { useState, useCallback } from 'react';
import { IconButton, MenuItem, Divider } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { observer } from 'mobx-react-lite';

import useMst from '~/hooks/use_mst';
import useLocales from '~/hooks/use_locales';

import { ICertificateModelSnapshotOut } from '~/mst/models/certificate/model';
import toasts from '~/utils/toasts';

import useActionsPopover from '~/hooks/react_grid/use_actions_popover';
import ConfirmDialog from '~/components/confirm_dialog';
import useNavigate from '~/hooks/use_navigate';

const ICON = {
  mr: 2,
  width: 20,
  height: 20
};

function ActionsFormatter({ row }: { row?: ICertificateModelSnapshotOut }) {
  const certificateId = row?._id;
  const { certificates } = useMst();
  const { t } = useLocales();
  const certificate = certificates.getById(certificateId);
  const { isDeletable, isModifiable, name } = certificate || {};
  const { ActionsMenu, handleClose, handleOpen } = useActionsPopover();
  const [isDeleting, setIsDeleting] = useState(false);
  const { navigate } = useNavigate();

  const handleDelete = useCallback(
    async (event) => {
      event.stopPropagation();
      try {
        await certificate.destroy();
        toasts.success(t('notifications.success.forms.delete_certificate'));
      } catch (e) {}
    },
    [certificate, t]
  );

  const handleModify = useCallback(
    (event) => {
      event.stopPropagation();
      navigate(`certificates/${certificateId}`);
    },
    [certificateId, navigate]
  );

  const handleStartDelete = useCallback(
    (event) => {
      event.stopPropagation();
      setIsDeleting(true);
      handleClose();
    },
    [handleClose]
  );

  const handleCancelDelete = useCallback((event) => {
    event.stopPropagation();
    setIsDeleting(false);
  }, []);

  return (
    <>
      {(isModifiable || isDeletable) && (
        <IconButton onClick={handleOpen}>
          <MoreVertIcon />
        </IconButton>
      )}
      <ActionsMenu>
        {isModifiable && (
          <MenuItem onClick={handleModify}>
            <EditIcon sx={ICON} />
            Edit
          </MenuItem>
        )}
        <Divider />
        {isDeletable && (
          <MenuItem onClick={handleStartDelete} sx={{ color: 'error.main' }}>
            <DeleteIcon sx={ICON} />
            Delete
          </MenuItem>
        )}
      </ActionsMenu>
      {isDeletable && (
        <ConfirmDialog
          open={isDeleting}
          onClose={handleCancelDelete}
          onConfirm={handleDelete}
          title={t('certificates.dialogs.delete.title')}
          content={t('certificates.dialogs.delete.content', { name })}
          confirmLabel={t('base.buttons.delete')}
          confirmButtonType="error"
        />
      )}
    </>
  );
}

ActionsFormatter.defaultProps = {
  row: {} as ICertificateModelSnapshotOut
};

export default observer(ActionsFormatter);

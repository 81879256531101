/* eslint-disable no-prototype-builtins */
import { observer } from 'mobx-react-lite';
import { BodyModule } from '~/pages/device_edit/components';
import useLocales from '~/hooks/use_locales';
import SwitchFields from '../sensor/common/switch_fields';
import StelAlerts from '../sensor/common/stel_alerts';

function DustTrak() {
  const { t } = useLocales();
  return (
    <BodyModule title={t('device_monitor.forms.settings')} columns={1} expandable expanded>
      <SwitchFields />
      <StelAlerts />
    </BodyModule>
  );
}

export default observer(DustTrak);

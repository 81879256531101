/* eslint-disable @typescript-eslint/naming-convention */
import { WidgetTypes } from '~/mst/models/widget/types';
import type { IDeviceMonitorModel } from './model';
import { TABS } from './model';

export default (self: IDeviceMonitorModel) => {
  function handleIsParamSelected(params, dataPointId) {
    return params.some((id) => id === dataPointId);
  }
  return {
    get chartWidget() {
      return self.widgets.toArray.find(({ type }) => type === WidgetTypes.ChartTableWidget);
    },
    get nodeWidget() {
      return self.widgets.toArray.find(({ type }) => type === WidgetTypes.NodeWidget);
    },
    get mapWidget() {
      return self.widgets.toArray.find(({ type }) => type === WidgetTypes.MapWidget);
    },
    get isChartView() {
      return self.selected_tab === TABS.GRAPH.name;
    },
    get isWindRoseChartView() {
      return self.selected_tab === TABS.WIND_ROSE_GRAPH.name;
    },
    get isTableView() {
      return self.selected_tab === TABS.TABLE.name;
    },
    get nodeIds() {
      return [self.chartWidget?.node_id];
    },
    get sidebarParams() {
      if (self.isWindRoseChartView) {
        return self?.nodeWidget?.data?.filter((dp) => dp.isWind);
      }
      return self?.nodeWidget?.data;
    },
    get tabIndex() {
      return Object.values(TABS).find(({ name }) => name === self.selected_tab).index;
    },
    isParamSelected(dataPointId) {
      if (self.isWindRoseChartView) {
        return handleIsParamSelected(self.params.selected_params_wind_chart, dataPointId);
      }
      if (self.isTableView) {
        return handleIsParamSelected(self.params.selected_params_table, dataPointId);
      }
      return handleIsParamSelected(self.params.selected_params_chart, dataPointId);
    }
  };
};

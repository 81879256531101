import UsbIcon from '~/assets/icons/params/usb.svg?react';
import DeviceParameter from '~/components/device_parameter';

function UsbSource({ showLabel, iconLeft }: { showLabel: boolean; iconLeft?: boolean }) {
  return (
    <DeviceParameter
      showLabel={showLabel}
      title="USB"
      tooltipTitle="USB Battery Source"
      value="USB"
      IconComponent={UsbIcon}
      iconWidth="16px"
      iconHeight="100%"
      iconLeft={iconLeft}
    />
  );
}

export default UsbSource;

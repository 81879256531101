import I18n from '~/utils/i18n';
import Autocomplete from '~/components/final_form/select';
import useLocales from '~/hooks/use_locales';

type ConditionFieldType = {
  name: string;
};

export const timePeriodOptions = [
  { label: I18n.t('time.number_mins', { n: 5 }), value: 5 },
  { label: I18n.t('time.number_mins', { n: 10 }), value: 10 },
  { label: I18n.t('time.number_mins', { n: 15 }), value: 15 },
  { label: I18n.t('time.number_mins', { n: 30 }), value: 30 },
  { label: I18n.t('time.number_mins', { n: 45 }), value: 45 },
  { label: I18n.t('time.one_hour'), value: 60 },
  { label: I18n.t('time.number_hours', { n: 2 }), value: 2 * 60 },
  { label: I18n.t('time.number_hours', { n: 6 }), value: 6 * 60 },
  { label: I18n.t('time.number_hours', { n: 8 }), value: 8 * 60 },
  { label: I18n.t('time.number_hours', { n: 12 }), value: 12 * 60 },
  { label: I18n.t('time.one_day'), value: 24 * 60 },
  { label: I18n.t('time.number_days', { n: 7 }), value: 7 * 24 * 60 },
  { label: I18n.t('time.number_days', { n: 30 }), value: 30 * 24 * 60 }
];

function ParameterField(props: ConditionFieldType) {
  const { name } = props;
  const { t } = useLocales();
  return (
    <Autocomplete name={name} options={timePeriodOptions} label={t('widgets.time_period')} noHelperText data-testid={`${name}TimePeriodSelect`} />
  );
}

export default ParameterField;

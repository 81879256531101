import { Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function IconButton(theme: Theme) {
  const isMobile = useMediaQuery<Theme>(() => theme.breakpoints.down('md'));
  return {
    MuiIconButton: {
      styleOverrides: {
        root: {}
      },
      defaultProps: {
        size: isMobile ? 'small' : 'medium'
      }
    }
  };
}

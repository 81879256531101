/* eslint-disable no-prototype-builtins */
import { observer } from 'mobx-react-lite';
import Select from '~/components/final_form/select';
import { FILTERS, MEASUREMENT_MODE } from '~/mst/models/device/sensor/sound_pro/config';
import { BodyModule } from '~/pages/device_edit/components';
import useLocales from '~/hooks/use_locales';
import humanizer from '~/utils/humanize_duration';
import I18n from '~/utils/i18n';
import { getValueLabelPairs } from '~/mst/utils';
import SwitchFields from '~/pages/device_edit/containers/sensor_form_container/sensors/sensor/common/switch_fields';

import { SensorProperty } from '../components';

const sessionTimeOptions = [
  {
    label: I18n.t('base.labels.off'),
    value: 0
  },
  ...[15, 30, 60, 120].map((time) => {
    const value = time * 60 * 1000;
    return { value, label: humanizer.humanizeDuration(value, { smallestUnit: 'min' }) };
  })
];

const measurementModeOptions = getValueLabelPairs(MEASUREMENT_MODE);
const filtersOptions = getValueLabelPairs(FILTERS);

function SoundPro() {
  const { t } = useLocales();

  return (
    <BodyModule title={t('device_monitor.forms.settings')} columns={1} expandable expanded>
      <SwitchFields />
      <SensorProperty name="measurement_mode">
        <Select name="measurement_mode" label={t('sensor.configuration.measurement_mode')} options={measurementModeOptions} />
      </SensorProperty>
      <SensorProperty name="filters">
        <Select name="filters" multiple label={t('sensor.configuration.filters')} options={filtersOptions} />
      </SensorProperty>
      <SensorProperty name="session_time">
        <Select name="session_time" label={t('sensor.configuration.session_time')} options={sessionTimeOptions} />
      </SensorProperty>
    </BodyModule>
  );
}

export default observer(SoundPro);

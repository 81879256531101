import { DateTime } from 'luxon';
import { DateTimePicker as MDateTimePicker } from '@mui/x-date-pickers';
import { CalendarContainerStyled, PopperStyled } from './styled';

type DateTimePickerProps = {
  containerTestId?: string;
  value: DateTime | null;
  onChange: (time: DateTime | null) => void;
  timezone?: string;
};

function DateTimePicker({ containerTestId, value, onChange, timezone }: DateTimePickerProps) {
  return (
    <CalendarContainerStyled data-testid={containerTestId}>
      <MDateTimePicker
        disableFuture
        onChange={onChange}
        value={value}
        timezone={timezone}
        slots={{
          popper: PopperStyled
        }}
      />
    </CalendarContainerStyled>
  );
}

export default DateTimePicker;

/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import compact from 'lodash/compact';
import Yup, { searchableSelectSchema } from '~/utils/yup';
import { Form as FinalForm } from 'react-final-form';

import { captureException } from '~/services/sentry';
import { useValidationSchema } from '~/hooks/use_validate';
import useLocales from '~/hooks/use_locales';
import toasts from '~/utils/toasts';

import Modal from '~/components/modal';
import { INode } from '~/mst/models/node';
import Form from './form';

type AddStatisticalParameterModalProps = {
  open: boolean;
  onClose: () => void;
  node: INode;
  deviceId?: string;
};

const AddStatisticalParameterSchema = Yup.object().shape({
  data_point_id: searchableSelectSchema('Parameter is required'),
  data_point_id2: Yup.mixed().when('func', {
    is: 'diff',
    then: () => searchableSelectSchema('Parameter 2 is required')
  }),
  name: Yup.string().required('Name is required'),
  func: Yup.string().required('Function is required'),
  period: Yup.number().when('func', {
    is: (func) => func !== 'diff',
    then: (schema) => schema.required('Period is required')
  })
});

function AddStatisticalParameterModal({ open, onClose, node, deviceId }: AddStatisticalParameterModalProps) {
  const { t } = useLocales();

  const validate = useValidationSchema(AddStatisticalParameterSchema);

  const handleSave = useCallback(
    async (values) => {
      try {
        const { name, period, data_point_id: dataPointId, func, data_point_id2: dataPointId2, step } = values;
        const payload = {
          conditions: {
            [func]: compact([dataPointId, func === 'diff' ? dataPointId2 : period, step])
          },
          name
        };
        await node.data_points.createMetric(payload);
        datadogRum.addAction('create_metric', payload);
        onClose();
        toasts.success(t('notifications.success.add_stat_message'));
      } catch (e) {
        captureException(e);
        toasts.error(t('notifications.errors.server_error'));
      }
    },
    [node, onClose, t]
  );

  return (
    <Modal open={open} handleClose={onClose} title={t('sensor.data_point.add_title')}>
      <FinalForm onSubmit={handleSave} initialValues={{}} validate={validate}>
        {({ handleSubmit }) => <Form handleCancel={onClose} handleSubmit={handleSubmit} node={node} deviceId={deviceId} />}
      </FinalForm>
    </Modal>
  );
}

export default AddStatisticalParameterModal;

/* eslint-disable react/require-default-props */
import { Popover, PopoverProps } from '@mui/material';
import ArrowStyled from './styled';

type Dir = 'left' | 'right';
type Vertical = 'top' | 'bottom';

export type Arrow =
  | 'top-left'
  | 'top-center'
  | 'top-right'
  | 'bottom-left'
  | 'bottom-center'
  | 'bottom-right'
  | 'left-top'
  | 'left-center'
  | 'left-bottom'
  | 'right-top'
  | 'right-center'
  | 'right-bottom';

export interface Props extends PopoverProps {
  vertical?: Vertical;
  dir?: Dir;
  arrow?: Arrow;
  disabledArrow?: boolean;
  onMouseLeave?: () => void;
}

export default function MenuPopover({
  children,
  arrow = 'top-right',
  dir = 'right',
  disabledArrow,
  sx,
  vertical = 'bottom',
  onMouseLeave,
  ...other
}: Props) {
  return (
    <Popover
      elevation={6}
      anchorOrigin={{ vertical, horizontal: dir }}
      transformOrigin={{ vertical: 'top', horizontal: dir }}
      PaperProps={{
        sx: {
          p: 1,
          width: 200,
          overflow: 'inherit',
          ...sx
        },
        onMouseLeave
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    >
      {!disabledArrow && <ArrowStyled arrow={arrow} />}

      {children}
    </Popover>
  );
}

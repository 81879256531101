/* eslint-disable import/prefer-default-export */
import request from '../request';

export function fetch(params) {
  return [request, 'get', 'firmware', params];
}

export function create(payload: any) {
  return request('post', 'firmware', payload);
}

export function destroy(id: string) {
  return request('delete', `firmware/${id}`);
}

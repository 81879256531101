/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback, useMemo } from 'react';
import Yup from '~/utils/yup';
import { Form as FinalForm } from 'react-final-form';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import useNavigate from '~/hooks/use_navigate';
import useMst from '~/hooks/use_mst';
import useLocales from '~/hooks/use_locales';
import { useValidationSchema } from '~/hooks/use_validate';
import toasts from '~/utils/toasts';
import { IOrganization } from '~/mst/models/organization';

import { captureException } from '~/services/sentry';
import useAuth from '~/hooks/use_auth';
import Form from './form';

function OrganizationForm({ model }: { model: IOrganization }) {
  const { navigate } = useNavigate();
  const { t } = useLocales();
  const { search } = useLocation();
  const serials = useMemo(() => new URLSearchParams(search).get('serials'), [search]);
  const { auth } = useAuth();

  const { organizations } = useMst();

  const { name, logo } = model;

  const OrganizationSchema = Yup.object().shape({
    name: Yup.string()
      .required(t('validation.required', { name: 'Organization name' }))
      .typeError(t('validation.required', { name: 'Organization name' }))
  });
  const validate = useValidationSchema(OrganizationSchema);

  const handleFormSubmit = useCallback(
    async (values) => {
      try {
        let data;
        if (model.isNew) {
          data = await organizations.create(values, model);
          await organizations.fetch(null, { noCache: true });
          await auth.user?.fetch();
          if (organizations.size === 1) {
            auth.setOrganizationId(organizations.first.id);
          }
        } else {
          data = await model.update(values);
        }
        if (serials) {
          navigate(`devices/claim?serials=${serials}&organization=${data.id}`);
        } else {
          navigate(`organizations`);
        }
        toasts.success(t(`notifications.success.forms.${model.isNew ? 'create_organization' : 'update_organization'}`));
      } catch (e) {
        captureException(e);
        toasts.error(t('notifications.errors.server_error'));
      }
    },
    [model, serials, t, navigate, organizations, auth]
  );

  const initialValues = useMemo(
    () => ({
      logo,
      name
    }),
    [logo, name]
  );

  return (
    <FinalForm
      validate={validate}
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      render={({ handleSubmit }) => <Form model={model} handleSubmit={handleSubmit} />}
    />
  );
}

export default observer(OrganizationForm);

import { Instance, SnapshotOut } from 'mobx-state-tree';
import model from './model';
import views from './views';
import actions from './actions';

const UserCalibrationStore = model.views(views).actions(actions);

export interface IUserCalibrationStore extends Instance<typeof UserCalibrationStore> {}
export interface IUserCalibrationStoreSnapshot extends SnapshotOut<typeof UserCalibrationStore> {}

export default UserCalibrationStore;

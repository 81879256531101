import React, { useState, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { Box } from '@mui/material';
import useSelection from '~/hooks/react_grid/use_selection';
import useMst from '~/hooks/use_mst';
import useLocales from '~/hooks/use_locales';
import Modal, { ModalActions } from '~/components/modal';
import toasts from '~/utils/toasts';
import { IAlertSnapshotOut } from '~/mst/models/alert';
import ModalTable from './modal_table';

type ActionsModalProps = {
  selected: IAlertSnapshotOut[];
  type: 'activate' | 'deactivate' | null;
  open: boolean;
  toggleModal: () => void;
};

function ActionsModal({ selected, type, toggleModal, open }: ActionsModalProps) {
  const { t } = useLocales();
  const [loading, setLoading] = useState(false);
  const { setSelection } = useSelection();
  const { alerts } = useMst();

  const handleCancel = useCallback(() => toggleModal(), [toggleModal]);
  const handleAction = useCallback(async () => {
    setLoading(true);
    try {
      switch (type) {
        case 'activate':
          await alerts.activate(selected);
          break;
        case 'deactivate':
          await alerts.deactivate(selected);
          break;
        default:
          throw new Error(t('alerts.errors', { type }));
      }
      setSelection([]);
      toggleModal();
      toasts.success(t('alerts.manage_dialog.success', { action: t(`alerts.manage_actions.${type}_past`) }));
    } catch (e) {
    } finally {
      setLoading(false);
    }
  }, [type, setSelection, toggleModal, alerts, selected, t]);

  return (
    <Modal open={open} handleClose={handleCancel} title={t('alerts.manage_alerts')}>
      <Box>
        <Box sx={{ p: 3 }}>
          <ModalTable selected={selected} />
        </Box>
        <ModalActions onClose={handleCancel} onSave={handleAction} loading={loading} saveLabel={t(`alerts.manage_actions.${type}`)} />
      </Box>
    </Modal>
  );
}

export default observer(ActionsModal);

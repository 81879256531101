import { observer } from 'mobx-react-lite';
import { TableCell, Grid, styled } from '@mui/material';

import useFetch from '~/hooks/use_fetch';
import type { IAlert } from '~/mst/models/alert';
import { DetailRowStyled } from '~/components/react_grid/styled';
import ConditionsBlock from '~/pages/alert_monitor/conditions_block';
import SubscribersBlock from '~/pages/alert_monitor/subscribers_block';
import useMst from '~/hooks/use_mst';

const DetailRow = styled(DetailRowStyled)(() => ({
  '.AlertBlockTitleContainer': {
    display: 'none'
  }
}));

function GridDetailContainer({ row: model }: { row: IAlert }) {
  const { _id: alertId } = model;
  const { alerts } = useMst();
  const alert = alerts.getById(alertId);

  useFetch(alert);

  return (
    <DetailRow>
      <TableCell />
      <TableCell colSpan={7}>
        <Grid container spacing={2} alignItems="flex-start">
          <Grid item md={5} sm={9} lg={5}>
            <ConditionsBlock alert={alert} />
          </Grid>
          <Grid item md={5} sm={9} lg={5}>
            <SubscribersBlock alert={alert} />
          </Grid>
        </Grid>
      </TableCell>
      <TableCell />
    </DetailRow>
  );
}

export default observer(GridDetailContainer);

import { Grid, VirtualTable, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { SortingState, IntegratedSorting } from '@devexpress/dx-react-grid';
import { observer } from 'mobx-react-lite';
import useMst from '~/hooks/use_mst';

import { GridStyled } from '~/components/react_grid/styled';
import useSorting from '~/hooks/react_grid/use_sorting';
import useLoading from '~/hooks/react_grid/use_loading';
import useFetch from '~/hooks/use_fetch';
import { RoleProvider, CreatedAtProvider, ActionsProvider } from './info_providers';

const columns = [
  { name: 'email', title: 'Email' },
  { name: 'role', title: 'User Role' },
  { name: 'organizationName', title: 'Organization' },
  { name: 'created_at', title: 'Created' },
  { name: 'actions', title: ' ', sortingEnabled: false }
];

function UsersGrid() {
  const { invitations, users } = useMst();

  const { messages, RootComponent } = useLoading(invitations);

  useFetch(users);
  useFetch(invitations);

  const { sorting, handleSortingChange } = useSorting([{ columnName: 'created_at', direction: 'desc' }]);

  return (
    <GridStyled>
      <Grid rows={invitations.filteredList} columns={columns} rootComponent={RootComponent}>
        <SortingState sorting={sorting} onSortingChange={handleSortingChange} />
        <IntegratedSorting />
        <RoleProvider for={['role']} />
        <ActionsProvider />
        <CreatedAtProvider />
        <VirtualTable
          height="auto"
          messages={messages}
          columnExtensions={[
            {
              columnName: 'actions',
              width: 60
            },
            {
              columnName: 'role',
              width: 150
            },
            {
              columnName: 'created_at',
              width: 150
            }
          ]}
        />
        <TableHeaderRow showSortingControls />
      </Grid>
    </GridStyled>
  );
}

export default observer(UsersGrid);

import { Box, Card, CardProps, styled, Theme, Chip } from '@mui/material';
import { transientOptions } from '~/utils/styled';

interface ICardProps extends CardProps {
  hoverable?: string;
  column?: boolean;
  centered?: boolean;
  theme?: Theme;
  device?: boolean;
}

interface IconType {
  $fixheight?: boolean;
  $sensor?: boolean;
}

export const Icon = styled(
  Box,
  transientOptions
)<IconType>(({ $fixheight, $sensor, theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  maxWidth: '56px',
  minWidth: '40px',
  maxHeight: '100%',
  overflow: 'hidden',
  marginRight: theme.spacing(1),
  ...($sensor && {
    maxWidth: '65px'
  }),
  ...($fixheight
    ? {
        height: '60px'
      }
    : {
        height: 'unset'
      }),
  [theme.breakpoints.down('md')]: {
    maxWidth: '40px',
    minWidth: '30px'
  },
  '> img': {
    maxHeight: '100%'
  }
}));

export const DeviceCard = styled(
  Card,
  transientOptions
)<ICardProps>(({ $column, $centered, $device, theme, sx }) => ({
  display: 'flex',
  minWidth: '160px',
  alignItems: 'flex-start',
  columnGap: theme.spacing(1),
  borderRadius: theme.shape.borderRadiusSm,
  position: 'relative',
  whiteSpace: 'nowrap',
  boxShadow: '0 4px 6px 0 rgba(0, 0, 0, 0.05)',
  transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  border: '1px solid #EBEBEB',
  padding: theme.spacing(2),
  ...($device && {
    marginRight: theme.spacing(5),
    minWidth: '360px'
  }),
  ...($centered && {
    alignItems: 'center'
  }),
  ...($column
    ? {
        flexDirection: 'column',
        justifyContent: 'flex-end'
      }
    : {
        flexDirection: 'row'
      }),
  [theme.breakpoints.down('md')]: {
    padding: 5
  },
  ...sx
}));

export const ErrorChip = styled(Chip)(() => ({
  maxWidth: '120px'
}));

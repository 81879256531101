import React, { useState, useCallback } from 'react';
import includes from 'lodash/includes';
import { TableSelection, VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import { Row as ReactGridRow } from '~/components/react_grid';

const useExpandRow = () => {
  const [expandedRowIds, setExpandedRowIds] = useState<string[]>([]);
  const handleSetExpandedRowIds = useCallback((expanded) => setExpandedRowIds(expanded), [setExpandedRowIds]);

  const Row = React.memo((props: TableSelection.RowProps | VirtualTable.RowProps) => {
    const { tableRow, row, highlighted } = props;
    const rowData = row || tableRow.row;
    const rowId = rowData._id || rowData.id;
    const { isExpandable } = rowData;

    const handleExpandRowClick = useCallback(() => {
      if (includes(expandedRowIds, rowId)) {
        setExpandedRowIds(expandedRowIds.filter((expandedRowId) => expandedRowId !== rowId));
      } else {
        setExpandedRowIds([...expandedRowIds, ...[rowId]]);
      }
    }, [rowId]);

    if (isExpandable) {
      return (
        <ReactGridRow
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          onClick={handleExpandRowClick}
          style={{ cursor: 'pointer' }}
          className={highlighted && 'TableSelectRow-selected'}
          hover
        />
      );
    }
    return (
      <ReactGridRow
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        style={{ cursor: 'default' }}
        className={highlighted && 'TableSelectRow-selected'}
        hover
      />
    );
  });

  return {
    expandedRowIds,
    setExpandedRowIds,
    handleSetExpandedRowIds,
    Row
  };
};

export default useExpandRow;

import { styled, Box } from '@mui/material';
import { pxToRem } from '~/theme/typography';

export const ListItemStyled = styled('span')(() => ({
  fontSize: pxToRem(14),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start !important',
  lineHeight: 1
}));

export const ExternalIdStyled = styled('span')(({ theme }) => ({
  color: theme.palette.text.disabled,
  fontSize: pxToRem(12)
}));

export const AutocompleteStyled = styled(Box)(({ theme }) => ({
  transition: 'width 0.5s',
  '.MuiOutlinedInput-root': {
    borderColor: 'transparent',
    padding: 0,
    fieldset: { borderColor: 'transparent' },
    '&.Mui-disabled': {
      backgroundColor: 'transparent',
      fieldset: { borderColor: 'transparent' }
    }
  },
  '.MuiOutlinedInput-input': {
    fontSize: pxToRem(24)
  },
  '.ExternalIdContainer': {
    span: {
      fontSize: pxToRem(16)
    },
    flexBasis: '100%',
    height: 0,
    position: 'relative',
    paddingBottom: theme.spacing(2.25),
    top: '-12px',
    left: '30px'
  }
}));

export const AutocompleteOrganizationsStyled = styled(Box)(({ theme }) => ({
  '.MuiAutocomplete-root': {
    '.MuiOutlinedInput-root': {
      padding: theme.spacing(1)
    },
    '.MuiOutlinedInput-input': {
      fontSize: pxToRem(16)
    },
    '.ExternalIdContainer': {
      span: {
        fontSize: pxToRem(13)
      },
      flexBasis: '100%',
      height: 0,
      position: 'relative',
      paddingBottom: '4px',
      top: '-12px',
      left: '5px'
    }
  }
}));

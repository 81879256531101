import { SortingState, IntegratedSorting, RowDetailState } from '@devexpress/dx-react-grid';
import { Grid, VirtualTable, TableHeaderRow, TableRowDetail } from '@devexpress/dx-react-grid-material-ui';

import { observer } from 'mobx-react-lite';

import { GridStyled, HeaderStyled } from '~/components/react_grid/styled';
import { Container } from '~/components/react_grid';
import useLoading from '~/hooks/react_grid/use_loading';
import useSorting from '~/hooks/react_grid/use_sorting';
import useMst from '~/hooks/use_mst';
import useExpandRow from '~/hooks/react_grid/use_expand_row';

import { CreatedAtProvider, ActionsProvider, NameProvider } from './info_providers';
import GridDetailContainer from './detail_row';

function compareDates(a: any, b: any) {
  // @ts-ignore
  return (b != null) - (a != null) || b - a;
}

const columns = [
  { name: 'name', title: 'Name' },
  {
    name: 'location',
    title: 'Address'
  },
  { name: 'created_at', title: 'Date Created' },
  { name: 'actions', title: ' ', sortingEnabled: false }
];

const getRowId = (row) => row.id;

function GridContainer() {
  const { organizations } = useMst();
  const { expandedRowIds, handleSetExpandedRowIds, Row } = useExpandRow();

  const { messages, RootComponent } = useLoading(organizations);
  const { sorting, handleSortingChange } = useSorting([{ columnName: 'name', direction: 'asc' }]);

  return (
    <GridStyled>
      <Grid rows={organizations.filteredList} columns={columns} getRowId={getRowId} rootComponent={RootComponent}>
        <SortingState sorting={sorting} onSortingChange={handleSortingChange} />
        <IntegratedSorting columnExtensions={[{ columnName: 'created_at', compare: compareDates }]} />
        <NameProvider for={['name']} />
        <RowDetailState expandedRowIds={expandedRowIds} onExpandedRowIdsChange={handleSetExpandedRowIds} />
        <CreatedAtProvider for={['created_at']} />
        <ActionsProvider for={['actions']} />
        <VirtualTable
          height="auto"
          messages={messages}
          columnExtensions={[{ columnName: 'actions', width: 65 }]}
          containerComponent={Container}
          rowComponent={Row}
        />
        <TableHeaderRow showSortingControls rowComponent={HeaderStyled} />
        <TableRowDetail rowComponent={GridDetailContainer} />
      </Grid>
    </GridStyled>
  );
}

export default observer(GridContainer);

/* eslint-disable @typescript-eslint/naming-convention */
import type { IInvitationsModel } from './model';

export default (self: IInvitationsModel) => {
  return {
    get filteredList() {
      return self.filteredModels.map((invitation) => ({
        ...invitation,
        organizationName: invitation.organizationName
      }));
    }
  };
};

import { observer } from 'mobx-react-lite';
import { INodeWidget } from '~/mst/models/widget/node_widget';

import DeviceIndicator from '../../map_widget/content/DeviceIndicator';
import TableContent from './index';

function NodeWidgetContent({ widget, height }: { widget: INodeWidget; height?: number }) {
  return (
    <>
      <DeviceIndicator infoWindowId={null} node={widget?.node} />
      <TableContent widget={widget} height={height} />
    </>
  );
}

export default observer(NodeWidgetContent);

import { Box, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

import { transientOptions } from '~/utils/styled';

export const LegendCircleStyled = styled(Box)`
  height: 10px;
  width: 10px;
  border: 1px solid transparent;
  border-radius: 50%;
`;

export const LegendCircleBoxStyled = styled('div')`
  padding: 3px;
`;

export const LegendItemStyled = styled(
  Stack,
  transientOptions
)(({ $isSelected }) => ({
  alignItems: 'center',
  cursor: 'pointer',
  ...($isSelected && {
    fontWeight: 'bold'
  }),
  '&:hover': {
    '.heatmap-legend-item-circle': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
      borderRadius: '50%'
    }
  }
}));

export const HeatmapItemNormalStyled = styled(Box)`
  height: 42px;
  width: 42px;
  position: relative;
  line-height: 1;
  overflow: hidden;
  border-radius: 50%;
  font-size: 110%;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const HeatmapItemButtonStyled = styled('div')`
  position: relative;
  border: 1px solid transparent;
  padding: 5px;
  &:hover {
    background-color: rgba(0, 0, 0, 0.08);
    border-radius: 50%;
  }
`;

export const HeatmapSVGTextStyled = styled('svg')`
  opacity: 0.7;
  fill: #ffffff;
`;

export const HeatmapSVGTextSmallStyled = styled(HeatmapSVGTextStyled)`
  font-weight: 400;
  opacity: 1;
`;

export const HeatmapContainerStyled = styled(Stack)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  flexWrap: 'wrap'
}));

export const TitleContainer = styled(Stack)`
  height: 100%;
  align-items: center;
`;

export const Icon = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'baseline',
  height: '100%',
  marginRight: '5px',
  '& small': {
    fontWeight: '400',
    fontSize: '11px',
    color: theme.palette.common.black,
    paddingLeft: '3px'
  },
  '& svg': {
    width: '25px',
    fill: theme.palette.common.black,
    margin: '0 auto',
    display: 'block'
  }
}));

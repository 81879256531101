/* eslint-disable no-param-reassign */
import { flow, applySnapshot } from 'mobx-state-tree';
import api from '~/api';
import type { SearchDataPointsParams } from '~/api/rest/search';
import type { IDataPointsAlertModel } from './model';

export default (self: IDataPointsAlertModel) => ({
  fetch: flow(function* fetch(params: SearchDataPointsParams) {
    try {
      self.startFetching();
      const { data } = yield self.sync(api.search.dataPoints, params);
      applySnapshot(self.models, data);
      self.finishFetching();
    } catch (error) {
      self.failFetching(error);
    }
  })
});

/* eslint-disable no-param-reassign */
import { flow } from 'mobx-state-tree';
import type { IAlertMonitor } from '.';

export default (self: IAlertMonitor) => ({
  fetch: flow(function* fetch() {
    try {
      self.startFetching();
      yield self.chartTableWidget.data_points.fetch({ ...self.range.fetchParams(), node_id: self.alert_id });
      self.finishFetching();
    } catch (error) {
      self.failFetching(error);
    }
  }),
  setRange(range) {
    self.range = range;
  },
  setAlertId(alertId) {
    self.alert_id = alertId;
  }
});

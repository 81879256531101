import { Box, styled } from '@mui/material';
import { transientOptions } from '~/utils/styled';

type StyledProps = {
  $hasError?: boolean;
};

export const ImageUploadWrapperStyled = styled(
  Box,
  transientOptions
)<StyledProps>(({ theme, $hasError }) => ({
  borderRadius: '50%',
  border: `1px dashed ${$hasError ? 'red' : theme.palette.grey[300]}`,
  width: theme.spacing(18),
  height: theme.spacing(18),
  position: 'relative',
  cursor: 'pointer'
}));

export const ImageUploadInnerStyled = styled(Box)(({ theme }) => ({
  background: theme.palette.grey[100],
  borderRadius: '50%',
  position: 'absolute',
  top: theme.spacing(1),
  left: theme.spacing(1),
  bottom: theme.spacing(1),
  right: theme.spacing(1),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden'
}));

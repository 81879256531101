import { DateTime } from 'luxon';

export const DATE_TIME_FORMAT = 'LL/dd/yy HH:mm:ss.SSS';

function DateTimeWithMillisFormatter({ value }: { value: number }) {
  if (value && DateTime.fromMillis(value)?.isValid) {
    return DateTime.fromMillis(value).toFormat(DATE_TIME_FORMAT);
  }
}

export default DateTimeWithMillisFormatter;

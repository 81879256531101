import { types as t, Instance } from 'mobx-state-tree';
import { ModelV1 as Model } from '~/mst/models/abstract/model';
import CertificateTemplateConfiguration from './configuration';

const CertificateTemplateModel = t
  .compose(
    t.model({
      configuration: CertificateTemplateConfiguration
    }),
    Model
  )
  .named('CertificateTemplate');

export interface ICertificateTemplateModel extends Instance<typeof CertificateTemplateModel> {}

export default CertificateTemplateModel;

import { types as t, Instance, SnapshotOut } from 'mobx-state-tree';
import WidgetModel from '~/mst/models/widget';
import NodesStore from '~/mst/models/nodes';

const ChartWidgetModel = t
  .compose(
    WidgetModel,
    t.model({
      type: 'ChartWidget',
      name: t.maybeNull(t.string),
      node_id: t.maybeNull(t.array(t.string)),
      selected_data_point_ids: t.array(t.string),
      nodes: t.maybeNull(NodesStore)
    })
  )
  .named('ChartWidget');

export interface IChartWidgetModel extends Instance<typeof ChartWidgetModel> {}
export interface IChartWidgetModelSnapshotOut extends SnapshotOut<typeof ChartWidgetModel> {}

export default ChartWidgetModel;

/* eslint-disable @typescript-eslint/naming-convention */
import { getIdentifier } from 'mobx-state-tree';
import uniq from 'lodash/uniq';
import flatten from 'lodash/flatten';
import orderBy from 'lodash/orderBy';
import { IAlert } from '~/mst/models/alert';
import { IAlertsModel } from './model';

export default (self: IAlertsModel) => ({
  get actions() {
    return uniq(flatten(self.toArray.map(({ actions }) => actions)));
  },
  get filteredList() {
    return self.filteredModels.map((alert: IAlert) => ({
      _id: alert._id,
      name: alert.name,
      description: alert.description,
      statusType: alert.statusType(),
      statusText: alert.statusText,
      isCreatable: alert.isCreatable,
      isMonitorable: alert.isMonitorable,
      isDeletable: alert.isDeletable,
      isModifiable: alert.isModifiable,
      lastTriggeredTs: alert.lastTriggeredTs
    }));
  },
  get valueLabelPairsSortedByLastTriggeredTs() {
    return orderBy(self.valueLabelPairs, ({ model }) => model.lastTriggeredTs ?? 0, ['desc']);
  },
  getAlertsByIds(ids: string[]) {
    return self.toArray.filter((item) => ids.includes(getIdentifier(item) || ''));
  }
});

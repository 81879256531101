/* eslint-disable @typescript-eslint/naming-convention */
import { ICalibrationModel } from '../model';

export default (self: ICalibrationModel) => {
  function calibrationStatusFilterTags() {
    const tags: string[] = [self.status];
    return tags.map((tag) => tag.toLowerCase());
  }
  function calibrationReadyFilterTags() {
    return [self.ready ? 'yes' : 'no'];
  }
  return {
    get calibrationStatusFilterTags() {
      return calibrationStatusFilterTags();
    },
    get calibrationReadyFilterTags() {
      return calibrationReadyFilterTags();
    },
    get filterTags() {
      return [...calibrationReadyFilterTags(), ...calibrationStatusFilterTags()];
    },
    get searchString() {
      const { serial, statusText } = self;
      return [serial, statusText].join(' ').toLowerCase();
    }
  };
};

import type { FC } from 'react';

// eslint-disable-next-line import/prefer-default-export
export const IFrameRenderer: FC<{
  sourceUrl: string;
  title: string;
  handleOnLoad?: () => void;
  // eslint-disable-next-line react/function-component-definition
}> = ({ sourceUrl, title, handleOnLoad }): JSX.Element => {
  return <iframe onLoad={handleOnLoad} src={sourceUrl} title={`${title} - Environet Console`} width="100%" height={900} style={{ border: 0 }} />;
};

import React, { useCallback } from 'react';
import { Button, Tooltip } from '@mui/material';
import { observer } from 'mobx-react-lite';
import DownloadIcon from '@mui/icons-material/DownloadOutlined';

import { IReport } from '~/mst/models/report';
import useLocales from '~/hooks/use_locales';
import useExport from '~/hooks/react_grid/use_export';

function DownloadButton({ report }: { report: IReport }) {
  const { t } = useLocales();
  const { startExport } = useExport();

  const handleDownload = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      if (report.isTableView) {
        startExport();
      } else {
        window.print();
      }
    },
    [startExport, report.isTableView]
  );

  return (
    <Tooltip title={`${report.isChartView ? t('monitor.download_chart_pdf') : t('monitor.download_table_data')}`} placement="top">
      <Button variant="outlined" onClick={handleDownload} sx={{ minWidth: 'auto', px: '4px' }} title={t('monitor.download_chart')}>
        <DownloadIcon />
      </Button>
    </Tooltip>
  );
}

export default observer(DownloadButton);

/* eslint-disable no-param-reassign */
import { applySnapshot } from 'mobx-state-tree';
import type { IMeasurementsModel } from './model';

export default (self: IMeasurementsModel) => ({
  reset(data) {
    applySnapshot(
      self.models,
      Object.fromEntries(Object.entries(data).map(([dataPointId, values]) => [dataPointId, { data_point_id: dataPointId, data: values }]))
    );
  }
});

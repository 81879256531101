import { Instance, SnapshotOut } from 'mobx-state-tree';
import model from './model';
import CommonViews from '../views';
import views from './views';

const HealthwayDevice = model.views(CommonViews).views(views);

export interface IHealthwayDevice extends Instance<typeof HealthwayDevice> {}
export interface IHealthwayDeviceSnapshot extends SnapshotOut<typeof HealthwayDevice> {}

export default HealthwayDevice;

/* eslint-disable @typescript-eslint/naming-convention */
import pick from 'lodash/pick';
import { IOrganizationModel } from '../model';

export default (self: IOrganizationModel) => {
  return {
    get searchString() {
      const searchFields = ['name', 'shippingLocation', 'external_id'];
      return Object.values(pick(self, searchFields)).join(' ').toLowerCase();
    }
  };
};

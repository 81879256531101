import { Chip, Stack, Tooltip } from '@mui/material';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { CALIBRATION_STATUSES } from '~/utils/constants';
import DoneIconStyled from './styled';

export default function ParametersFormatter(props: DataTypeProvider.ValueFormatterProps) {
  const {
    value,
    row: { serial }
  } = props;
  return (
    <Stack spacing={1} alignItems="center" direction="row">
      {value.map((parameter) => (
        <Tooltip title={parameter.statusText} placement="top" key={`${serial}_${parameter.name}`}>
          <Chip
            size="small"
            variant="outlined"
            icon={
              parameter.status === CALIBRATION_STATUSES.COMPLETED ? (
                <DoneIconStyled fontSize="small" />
              ) : (
                <ErrorOutlineIcon fontSize="small" color="error" />
              )
            }
            label={parameter.name}
          />
        </Tooltip>
      ))}
    </Stack>
  );
}

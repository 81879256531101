import { observer } from 'mobx-react-lite';

import useFetch from '~/hooks/use_fetch';
import { IDeviceAdmin } from '../../store';
import DebugTable from './table';

function DebugTab({ report }: { report: IDeviceAdmin }) {
  useFetch(report?.debugWidget, report.range.fetchParams());

  return <DebugTable report={report} />;
}

export default observer(DebugTab);

import { useFormState } from 'react-final-form';
import get from 'lodash/get';
import isObject from 'lodash/isObject';
import { computed } from 'mobx';

const useParameterUnitHook = (name, model) => {
  const { values } = useFormState({ subscription: { values: true } });

  const { deviceId, dataPointId } = get(values, name);
  function getUnit(dataPoint: any, device: any) {
    if (isObject(dataPoint)) {
      const { deviceId: nestedDeviceId, dataPointId: nestedDataPointId } = dataPoint;
      return getUnit(nestedDataPointId, nestedDeviceId);
    }
    return model.nodes?.getById(device)?.getDataPointById(dataPoint)?.unit;
  }

  return computed(() => getUnit(dataPointId, deviceId)).get();
};

export default useParameterUnitHook;

/* eslint-disable @typescript-eslint/no-use-before-define */
import { types as t, Instance } from 'mobx-state-tree';
import Sensor from '~/mst/models/device/sensor';
import BitConfigType from '~/mst/common/bit_config_type';

const DataRamSensor = t.compose(
  t.model({
    auto_zero: t.maybeNull(t.union(t.null, t.number)),
    enabled: t.maybeNull(t.union(t.null, BitConfigType)),
    auto_zero_enabled: t.maybeNull(t.union(t.null, BitConfigType)),
    auto_zero_time: t.maybeNull(t.union(t.null, t.number)),
    flow_calibration: t.maybeNull(t.union(t.null, t.number))
  }),
  Sensor
);

export interface IDataRamSensor extends Instance<typeof DataRamSensor> {}

export default DataRamSensor;

import { Theme } from '@mui/material/styles';

export default function FormHelperText(theme: Theme) {
  return {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginTop: theme.spacing(0.5)
        }
      }
    }
  };
}

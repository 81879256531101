import { getParent } from 'mobx-state-tree';
import compact from 'lodash/compact';
import round from 'lodash/round';
import range from 'lodash/range';
import filter from 'lodash/fp/filter';
import flatten from 'lodash/flatten';
import groupBy from 'lodash/fp/groupBy';
import sortBy from 'lodash/fp/sortBy';
import flow from 'lodash/fp/flow';
import map from 'lodash/fp/map';
import type { IDeviceMonitor } from '../..';
import type { IChartTableWidgetStore } from '..';

export const categories = ['N', 'NNE', 'NE', 'ENE', 'E', 'ESE', 'SE', 'SSE', 'S', 'SSW', 'SW', 'WSW', 'W', 'WNW', 'NW', 'NNW'];

export function azimut2cardinal(degree) {
  const val = Math.floor(degree / 22.5 + 0.5);
  return categories[val % 16];
}

function getMinMax(data) {
  return data.reduce(
    (acc, { speed }) => {
      // eslint-disable-next-line no-param-reassign
      acc.min = speed < acc.min ? speed : acc.min ?? speed;
      // eslint-disable-next-line no-param-reassign
      acc.max = speed > acc.max ? speed : acc.max ?? speed;
      return acc;
    },
    { min: undefined, max: undefined }
  );
}

const NUMBER_OF_SERIES = 4;
export function getSeries(data, unit = 'm/s') {
  const { min, max } = getMinMax(data);
  const step = round((max - min) / NUMBER_OF_SERIES, 1);
  const series = range(min, max, step);
  return series.map((value, index) => {
    // eslint-disable-next-line no-param-reassign
    value = round(value, 1);
    const valueWithStep = round(value + step, 1);
    if (index === 0) {
      return { min: valueWithStep, name: `< ${valueWithStep}${unit}` };
    }
    if (index === series.length - 1) {
      return { max: value, name: `> ${value}${unit}` };
    }
    return { min: value, max: valueWithStep, name: `${value}${unit} - ${valueWithStep}${unit}` };
  });
}

function getSeriesName(series, value) {
  const serie = series.find(({ min, max }) => {
    if (min != null && max != null) {
      return value >= min && value <= max;
    }
    if (min != null) {
      return value < min;
    }
    return value > max;
  });
  return serie?.name;
}

export default (self: IChartTableWidgetStore) => {
  function getData() {
    const report = getParent<IDeviceMonitor>(self, 3);
    return report?.params.selected_params_wind_chart?.map((id) => self.node?.data_points?.getById(id));
  }
  return {
    get windChartData() {
      return flow(
        compact,
        flatten,
        groupBy('ts'),
        Object.values,
        map((item) => {
          // eslint-disable-next-line @typescript-eslint/no-shadow
          const { direction } = item.find(({ direction }) => direction != null) || {};
          // eslint-disable-next-line @typescript-eslint/no-shadow
          const { speed } = item.find(({ speed }) => speed != null) || {};
          return { direction, speed };
        }),
        filter(({ speed }) => speed != null)
      )(
        getData().map((dp) =>
          dp.measurements.map(([ts, value]) => ({ ts, ...(dp.isWindDirection ? { direction: azimut2cardinal(value) } : { speed: value }) }))
        )
      );
    },
    get windChartSeries() {
      if (getData().length === 2) {
        const windDirection = getData().find((dp) => !dp.isWindDirection);
        const data = self.windChartData;
        const total = data.length;
        const series = getSeries(data, windDirection.unit);
        return flow(
          groupBy(({ speed }) => getSeriesName(series, speed)),
          Object.entries,
          map(([name, items]) => {
            return {
              name,
              ...getMinMax(items),
              data: categories.map((category) => {
                const count = items.filter(({ direction }) => direction === category).length;
                const frequency = round((count / total) * 100, 2);
                return [category, frequency || 0];
              })
            };
          }),
          sortBy(['min'])
        )(data);
      }
      return [];
    }
  };
};

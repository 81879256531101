/* eslint-disable no-prototype-builtins */
import { useCallback, useState } from 'react';
import { Chip } from '@mui/material';
import { observer } from 'mobx-react-lite';
import isEmpty from 'lodash/isEmpty';
import { getIdentifier } from 'mobx-state-tree';
import SettingsIcon from '@mui/icons-material/Settings';
import { ISensor } from '~/mst/models/device/sensor';
import { BodyModule } from '~/pages/device_edit/components';
import useLocales from '~/hooks/use_locales';
import useModal from '~/hooks/use_modal';
import SwitchFields from '~/pages/device_edit/containers/sensor_form_container/sensors/sensor/common/switch_fields';
import { SensorProperty } from '../components';
import { MeasurementsContainer } from '../styled';
import ConfigureMeasurementModal from './configure_measurement_modal';

type SensorType = {
  sensor: ISensor;
};

function GX6000({ sensor }: SensorType) {
  const { open, handleClose, handleOpen } = useModal();
  const [measurementId, setMeasurementId] = useState(null);
  const { t } = useLocales();
  const handleClick = useCallback(
    (id) => {
      setMeasurementId(id);
      handleOpen();
    },
    [setMeasurementId, handleOpen]
  );

  if (isEmpty(sensor.rawDataPoints) && !sensor.formValues.hasOwnProperty('enabled')) {
    return null;
  }

  return (
    <BodyModule title={t('device_monitor.forms.settings')} columns={1} expandable expanded>
      <SwitchFields />
      <SensorProperty name="optional_measurements">
        <MeasurementsContainer component="ul">
          {sensor.rawDataPoints?.map((dp) => (
            <Chip
              key={getIdentifier(dp)}
              variant="outlined"
              icon={<SettingsIcon fontSize="12px" />}
              data-measurementid={getIdentifier(dp)}
              label={dp.name}
              onClick={handleClick}
            />
          ))}
        </MeasurementsContainer>
      </SensorProperty>
      <ConfigureMeasurementModal
        open={open}
        onClose={handleClose}
        sensor={sensor}
        dataPoint={sensor?.rawDataPoints?.find(({ _id: id }) => id === measurementId)}
      />
    </BodyModule>
  );
}

export default observer(GX6000);

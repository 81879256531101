import React, { useCallback } from 'react';
import { Button, Tooltip } from '@mui/material';
import { observer } from 'mobx-react-lite';
import SyncIcon from '@mui/icons-material/Sync';

import { IReport } from '~/mst/models/report';
import useLocales from '~/hooks/use_locales';

function RefreshButton({ report }: { report: IReport }) {
  const { t } = useLocales();
  const handleReloadData = useCallback(() => {
    report.fetch();
  }, [report]);

  return (
    <Tooltip title={t('device_monitor.reload_data')} placement="top">
      <Button variant="outlined" disabled={report.isFetching} onClick={handleReloadData} sx={{ minWidth: 'auto', px: '4px' }}>
        <SyncIcon />
      </Button>
    </Tooltip>
  );
}

export default observer(RefreshButton);

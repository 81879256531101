import { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { Tooltip } from '@mui/material';

import { DEVICE_STATUSES } from '~/utils/constants';
import useNavigate from '~/hooks/use_navigate';
import type { IDataPointNode } from '~/mst/models/data_point/node/default';
import { STATUS_COLORS } from '~/theme/types';
import isNull from 'lodash/isNull';
import { HeatmapItemButtonStyled, HeatmapItemNormalStyled, HeatmapSVGTextSmallStyled } from './styled';

type TooltipTitleProps = {
  nodeName: string;
  name: string;
  value: string;
  unit: string;
};

type HeatmapItemProps = {
  status: string;
  value: number;
};

function HeatmapItem({ status = DEVICE_STATUSES.OFFLINE, value }: HeatmapItemProps) {
  return (
    <HeatmapItemNormalStyled sx={{ backgroundColor: STATUS_COLORS[status] }}>
      <HeatmapSVGTextSmallStyled viewBox="0 0 60 18" height="18">
        <text x="50%" y="15" textAnchor="middle">
          <tspan>{isNull(value) ? '' : value}</tspan>
        </text>
      </HeatmapSVGTextSmallStyled>
    </HeatmapItemNormalStyled>
  );
}

function TooltipTitle({ nodeName, name, value, unit }: TooltipTitleProps) {
  const hasValue = typeof value === 'number';
  return (
    <span>
      <b>{nodeName}</b>
      <br />
      {name}: {hasValue ? value : 'N/A'} {hasValue && unit}
      <br />
    </span>
  );
}

type HeatmapItemContainerProps = {
  dataPoint: IDataPointNode;
};

function HeatmapItemContainer({ dataPoint }: HeatmapItemContainerProps) {
  const { navigate } = useNavigate();

  const handleOpenMonitorPage = useCallback(() => {
    navigate(`devices/${dataPoint?.node?._id}/monitor?parameter=${dataPoint?._id}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  return (
    <Tooltip
      title={
        <TooltipTitle nodeName={dataPoint?.node?.presentName} name={dataPoint?.shortPresentName} value={dataPoint?.avg!} unit={dataPoint?.unit!} />
      }
      placement="top"
      arrow
      enterDelay={300}
    >
      <HeatmapItemButtonStyled onClick={handleOpenMonitorPage}>
        <HeatmapItem value={dataPoint?.avg} unit={dataPoint?.unit!} status={dataPoint?.statusType(dataPoint?.avg)} />
      </HeatmapItemButtonStyled>
    </Tooltip>
  );
}

export default observer(HeatmapItemContainer);

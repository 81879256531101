/* eslint-disable no-param-reassign */
import { flow, getIdentifier, applySnapshot, getParent } from 'mobx-state-tree';
import api from '~/api';
import flatten from 'lodash/flatten';
import { toMapById } from '~/mst/utils';
import type { IAlertsModel } from '~/mst/models/alerts/model';
import type { IAlertModel } from './model';

export default (self: IAlertModel) => ({
  fetch: flow(function* update(params = {}) {
    self.startFetching();
    try {
      const dataPointIds = flatten(self.data_points?.toArray?.map(({ linked }) => linked));
      const { data: nodesWithDataPoints } = yield self.sync(api.search.nodes, {
        type: 'thiamis',
        data_point_id: dataPointIds,
        ...(dataPointIds.length && { includes: ['data_points'] }),
        ...params
      });
      const { data: nodes } = yield self.sync(api.search.nodes, {
        type: 'thiamis',
        ...params
      });
      applySnapshot(self.nodes, { models: { ...toMapById(nodes), ...toMapById(nodesWithDataPoints) } });
      self.finishFetching();
    } catch (error) {
      self.failFetching(error);
    }
  }),
  update: flow(function* update(values) {
    self.startSyncing();
    try {
      yield api.alerts.update(getIdentifier(self), values);
      self.finishSyncing();
    } catch (error) {
      self.failSyncing(error);
    }
  }),
  destroy: flow(function* destroy() {
    self.startSyncing();
    try {
      yield api.nodes.destroy(getIdentifier(self)!);
      const alerts = getParent<IAlertsModel>(self, 2);
      alerts.remove(self);
    } catch (error) {
      self.failSyncing(error);
      throw new Error(error);
    }
  }),
  activate: flow(function* activate() {
    self.startSyncing();
    try {
      const { data } = yield api.alerts.activate(getIdentifier(self));
      applySnapshot(self, data);
      self.finishSyncing();
    } catch (error) {
      self.failSyncing(error);
    }
  }),
  deactivate: flow(function* deactivate() {
    self.startSyncing();
    try {
      const { data } = yield api.alerts.deactivate(getIdentifier(self));
      applySnapshot(self, data);
      self.finishSyncing();
    } catch (error) {
      self.failSyncing(error);
    }
  })
});

/* eslint-disable import/prefer-default-export */
import request from '../request';

export function fetch(params, otpions) {
  return [request, 'get', 'partnerships', params, otpions];
}

export function create(payload) {
  return request('post', 'partnerships', payload);
}

export function destroy(id, payload) {
  return request('delete', `partnerships/${id}`, payload);
}

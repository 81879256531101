import React from 'react';
import Popover from '@mui/material/Popover';
import { List } from '@mui/material';
import { NavItemRoot } from './nav_item';
import type { NavListProps } from './type';
import NavListSub from './nav_list_sub';

interface INavPopover {
  item: NavListProps;
}

function NavPopover({ item }: INavPopover) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <NavItemRoot item={item} onOpen={() => setOpen(!open)} onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose} isCollapse />

      <Popover
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left'
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <List component="div" disablePadding onMouseLeave={handlePopoverClose}>
          {item?.children?.map((_item) => <NavListSub key={_item.title} list={_item} root={item.path} />)}
        </List>
      </Popover>
    </>
  );
}

export default NavPopover;

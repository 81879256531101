import { useState } from 'react';

const PAGE_SIZES = [15, 20, 30];

const usePaging = (pageSizes = PAGE_SIZES) => {
  const [size, setPageSize] = useState(pageSizes[0]);
  const [sizes] = useState(pageSizes);
  const [page, setPage] = useState(0);

  return {
    pageSizes: sizes,
    pageSize: size,
    currentPage: page,
    handlePageSizeChange: setPageSize,
    handlePageChange: setPage
  };
};

export default usePaging;

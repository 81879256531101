import React, { useMemo } from 'react';
import { Typography, Box, Card, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CircleIcon from '@mui/icons-material/Circle';
import { useField } from 'react-final-form';
import { ValueLabelPair } from '~/mst/models/abstract/collection_types';

type ResultsProps = {
  results: any[];
  organizations: ValueLabelPair[];
};

function Results({ results, organizations }: ResultsProps) {
  const {
    input: { value: organizationId }
  } = useField('organization');
  const organization: ValueLabelPair | undefined = useMemo(
    () => (organizations || []).find((item) => item.value === organizationId),
    [organizations, organizationId]
  );
  const { t } = useTranslation();
  return (
    <Box>
      <Typography variant="h6" sx={{ my: 1 }}>
        {t('claim.result')}
      </Typography>
      <Card
        sx={{
          py: 1,
          minHeight: 300,
          alignItems: 'flex-start'
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('claim.serial')}</TableCell>
              <TableCell>{t('claim.organization')}</TableCell>
              <TableCell>{t('claim.status')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {results.map((item) => {
              const sx = !item.status ? { color: 'red' } : {};
              return (
                <TableRow key={item.serial}>
                  <TableCell sx={sx}>{item.serial}</TableCell>
                  <TableCell sx={sx}>{organization?.label}</TableCell>
                  <TableCell sx={sx}>
                    <Box display="flex" alignItems="center">
                      <CircleIcon color={item.status ? 'success' : 'error'} sx={{ height: '100%', transform: `scale(0.6)` }} />
                      {item.status ? t('claim.claimed') : t('claim.failed')}
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Card>
    </Box>
  );
}

export default Results;

/* eslint-disable no-param-reassign */
import { applySnapshot } from 'mobx-state-tree';
import sortBy from 'lodash/sortBy';
import get from 'lodash/get';
import flatten from 'lodash/flatten';
import camelCase from 'lodash/camelCase';

import { IFilterableModel, IFilter } from './model';

type FilterOption = {
  count: number;
  title: string;
  key: string;
  group: string;
};

export const getCounts = (tags: string[]) => {
  return tags.reduce((result: any, tag) => {
    if (!result[tag]) {
      result[tag] = 0; // eslint-disable-line no-param-reassign
    }
    result[tag] += 1; // eslint-disable-line no-param-reassign
    return result;
  }, {});
};

export const getFilterOptions = (filters: any, group: string, getTitle = (key: string) => key): FilterOption[] => {
  return sortBy(
    Object.keys(filters).map((key) => ({ title: getTitle(key), count: filters[key], group, key: key.toLowerCase() })),
    'title'
  );
};

export const getFiltersItemsList = (models: Array<any>, group: string) => {
  const tagsList = getCounts(flatten(models.map(({ [`${camelCase(group)}FilterTags`]: tag }) => tag).filter((item) => item)));
  return [...getFilterOptions(tagsList, group)];
};

export default (self: IFilterableModel) => {
  const updateFilters = () => {
    self.filterGroups.forEach((filterGroup) => {
      const availableFilters = get(
        self,
        camelCase(`get-${filterGroup.title}FiltersItemsList`),
        getFiltersItemsList(self.filteredModels, filterGroup.title)
      )
        .map((filter) => (filter.children?.length ? [filter, ...filter.children] : filter))
        .flat();
      filterGroup.filters
        .map((filter) => (filter.children?.length ? [filter, ...filter.children] : filter))
        .flat()
        .forEach((filter) => {
          const filterItem = availableFilters.find(({ title }) => title === filter.title);
          if (filterItem) {
            filter.disabled = false;
            filter.count = filterItem.count;
          } else {
            filter.disabled = true;
            filter.count = 0;
          }
        });
    });
  };

  return {
    buildFilters(groups) {
      applySnapshot(
        self.filterGroups,
        groups.map((groupName) => ({
          title: groupName,
          filters: get(self, camelCase(`get-${groupName}FiltersItemsList`), getFiltersItemsList(self.toArray, groupName))
        }))
      );
    },
    clearFilters() {
      self.query = '';
      self.filterGroups.forEach((filterGroup) => {
        filterGroup.filters
          .map((filter) => (filter.children?.length ? [filter, ...filter.children] : filter))
          .flat()
          .forEach((filter) => {
            filter.chosen = false;
            filter.disabled = false;
          });
      });
      updateFilters();
    },
    toggleFilter(filter: IFilter) {
      filter.chosen = !filter.chosen;
      updateFilters();
    },
    setQuery(query: string) {
      self.query = query;
      updateFilters();
    }
  };
};

import { Box } from '@mui/material';
import { observer } from 'mobx-react-lite';

import type { ITotalDevicesWidget } from '../store';
import PieChart from './pie_chart';

function Chart({ widget }: { widget: ITotalDevicesWidget }) {
  return (
    <Box sx={{ width: '100%', height: '100%', pb: 2 }}>
      <PieChart widget={widget} />
    </Box>
  );
}

export default observer(Chart);

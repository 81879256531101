import { DataTypeProvider } from '@devexpress/dx-react-grid';
import TimeAgoFormatter from '~/components/react_grid/time_ago_formatter';
import ActionsFormatter from './actions_formatter';
import RoleFormatter from './role_formatter';

export function CreatedAtProvider() {
  return <DataTypeProvider for={['created_at']} formatterComponent={TimeAgoFormatter} />;
}

export function RoleProvider(props) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <DataTypeProvider {...props} formatterComponent={RoleFormatter} />;
}

export function ActionsProvider() {
  return <DataTypeProvider for={['actions']} formatterComponent={ActionsFormatter} />;
}

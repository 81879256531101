import { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import intersection from 'lodash/intersection';
import includes from 'lodash/includes';
import ActionsDropdown from '~/components/actions_dropdown';
import { MenuItemStyled, MenuGroupTitleStyled } from '~/components/actions_dropdown/styled';
import useMst from '~/hooks/use_mst';
import useSelection from '~/hooks/react_grid/use_selection';
import useActions from '~/components/actions_dropdown/context';
import ActionsModal from '~/pages/alerts/form_top/manage_modal/actions_modal';

function ActionsAcontainer() {
  const { selection } = useSelection();
  const { alerts } = useMst();
  const { actions } = alerts;
  const { action, setAction } = useActions();

  const canManage = Boolean(intersection(['activate', 'deactivate'], actions).length);
  const availableActions = intersection(...selection.map((id) => alerts.getById(id)?.actions));

  const handleActivate = useCallback(() => setAction('activate'), [setAction]);
  const handleDeactivate = useCallback(() => setAction('deactivate'), [setAction]);

  return (
    <>
      <ActionsDropdown btnSize="medium">
        {canManage && [
          <MenuGroupTitleStyled key="manage-0">Manage</MenuGroupTitleStyled>,
          <MenuItemStyled key="manage-1" disabled={!includes(availableActions, 'activate')} onClick={handleActivate}>
            Activate
          </MenuItemStyled>,
          <MenuItemStyled key="manage-2" disabled={!includes(availableActions, 'deactivate')} onClick={handleDeactivate}>
            Deactivate
          </MenuItemStyled>
        ]}
      </ActionsDropdown>
      <ActionsModal
        open={action === 'activate' || action === 'deactivate'}
        type={action}
        toggleModal={() => setAction(null)}
        selected={selection.map((id) => alerts.getById(id))}
      />
    </>
  );
}

export default observer(ActionsAcontainer);

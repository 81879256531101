import { Instance, SnapshotOut } from 'mobx-state-tree';
import CollectionMapProcessor from '~/mst/models/abstract/collection_map_processor';
import model from './model';
import views from './views';
import actions from './actions';

const AlertsStore = model.views(views).actions(actions);

export interface IAlertsStore extends Instance<typeof AlertsStore> {}
export interface IAlertsStoreSnapshot extends SnapshotOut<typeof AlertsStore> {}

export default CollectionMapProcessor(AlertsStore);

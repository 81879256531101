import { Checkbox, FormControlLabel } from '@mui/material';
import { useCallback } from 'react';
import { observer } from 'mobx-react-lite';

import type { IDeviceMonitor } from '~/pages/device_monitor/store';

import useLocales from '~/hooks/use_locales';
import SettingsWrapperStyled from './styled';

function ChartSettingsButton({ report }: { report: IDeviceMonitor }) {
  const { t } = useLocales();

  const hasProfile = report?.chartWidget?.data?.some((dp) => dp.hasProfileRange && dp.measurements.length);

  const handleChangeLimitsShow = useCallback(
    (event) => {
      report?.chartWidget?.toggleShowZones(event.target.checked);
    },
    [report?.chartWidget]
  );

  const handleChangeDataGapsShow = useCallback(
    (event) => {
      report?.chartWidget?.toggleShowDataGaps(event.target.checked);
    },
    [report?.chartWidget]
  );

  return (
    <SettingsWrapperStyled direction="row" sx={{ displayPrint: 'none' }}>
      <FormControlLabel
        checked={!!report?.chartWidget?.show_data_gaps}
        control={<Checkbox size="small" onChange={handleChangeDataGapsShow} value={report?.chartWidget?.show_data_gaps} />}
        label={t('Data gaps')}
        labelPlacement="end"
      />
      {hasProfile && (
        <FormControlLabel
          checked={!!report?.chartWidget?.show_zones}
          control={<Checkbox size="small" onChange={handleChangeLimitsShow} value={report?.chartWidget?.show_zones} />}
          label={t('Limits')}
          labelPlacement="end"
        />
      )}
    </SettingsWrapperStyled>
  );
}

export default observer(ChartSettingsButton);

import { types as t, Instance, SnapshotOut } from 'mobx-state-tree';
import Syncable from '~/mst/models/abstract/syncable';
import PermissionsOrganization from '~/mst/models/permissions/organization';
import { ModelV2 } from '~/mst/models/abstract/model';
import StatsModel from '~/mst/models/stats';
import NodesStore from '~/mst/models/nodes';
import CreatedAtModel from '~/mst/models/abstract/created_at';
import OrganizationAddressModel from './address';

const OrganizationModel = t.compose(
  t
    .model({
      name: t.maybeNull(t.string),
      external_id: t.maybeNull(t.string),
      logo: t.maybeNull(t.string),
      partner_of: t.maybeNull(t.array(t.string)),
      addresses: t.maybeNull(t.array(OrganizationAddressModel)),
      permissions: t.maybeNull(PermissionsOrganization),
      is_member: t.maybeNull(t.boolean)
    })
    .props({
      stats: t.optional(StatsModel, {}),
      nodes: t.optional(NodesStore, [])
    }),
  Syncable,
  ModelV2,
  CreatedAtModel
);

export interface IOrganizationModel extends Instance<typeof OrganizationModel> {}
export interface IOrganizationModelSnapshotOut extends SnapshotOut<typeof OrganizationModel> {}

export default OrganizationModel;

import React, { useCallback } from 'react';
import { IconButton, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DownloadIcon from '@mui/icons-material/Download';
import useDownloadForm from '~/hooks/use_download_form';
import useLocales from '~/hooks/use_locales';
import useActionsPopover from '~/hooks/react_grid/use_actions_popover';
import { IArchive } from '~/mst/models/archive';

export function DownloadFormatter({ row: { id, filename } }: { row: IArchive }) {
  const { ActionsMenu, handleClose, handleOpen } = useActionsPopover();
  const { t } = useLocales();

  const { DownloadForm, setData } = useDownloadForm(handleClose);

  const handleDownload = useCallback(() => setData({ filename }), [setData, filename]);

  return (
    <>
      <IconButton onClick={handleOpen}>
        <MoreVertIcon />
      </IconButton>
      <ActionsMenu>
        <MenuItem onClick={handleDownload}>
          <DownloadIcon />
          {t('base.buttons.download')}
        </MenuItem>
      </ActionsMenu>
      <DownloadForm method="POST" actionUrl={`snapshots/${id}.csv`} />
    </>
  );
}

export default DownloadFormatter;

import { types as t, Instance, SnapshotOut } from 'mobx-state-tree';
import { ModelV1 as Model } from '~/mst/models/abstract/model';
import Syncable from '~/mst/models/abstract/syncable';
import NodeBase from '~/mst/models/abstract/node_base';
import Template from './template';
import UserMetadata from './user_metadata';

const CertificateModel = t
  .compose(
    t.model({
      _type: 'report',
      user_metadata: t.maybeNull(UserMetadata),
      templates: t.maybeNull(t.array(Template))
    }),
    Model,
    NodeBase,
    Syncable
  )
  .named('Certificate');

export interface ICertificateModel extends Instance<typeof CertificateModel> {}
export interface ICertificateModelSnapshotOut extends SnapshotOut<typeof CertificateModel> {}

export default CertificateModel;

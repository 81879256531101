import { Instance } from 'mobx-state-tree';
import WidgetProcessor from '~/mst/models/widget/processor';
import actions from './actions';
import model from './model';
import views from './views';

const DebugWidgetStore = model.views(views).actions(actions);

export interface IDebugWidgetStore extends Instance<typeof DebugWidgetStore> {}

export default WidgetProcessor(DebugWidgetStore);

import * as React from "react";
const SvgBattery2 = (props) => /* @__PURE__ */ React.createElement("svg", { width: "6.4062384mm", height: "5.8404012mm", viewBox: "0 0 10.427818 5.8404012", id: "svg5", "inkscape:version": "1.1.1 (3bf5ae0d25, 2021-09-20)", "sodipodi:docname": "battery_2.svg", "xmlns:inkscape": "http://www.inkscape.org/namespaces/inkscape", "xmlns:sodipodi": "http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd", xmlns: "http://www.w3.org/2000/svg", "xmlns:svg": "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("sodipodi:namedview", { id: "namedview7", pagecolor: "#ffffff", bordercolor: "#666666", borderopacity: 1, "inkscape:pageshadow": 2, "inkscape:pageopacity": 0, "inkscape:pagecheckerboard": 0, "inkscape:document-units": "mm", showgrid: "false", "inkscape:zoom": 8.4863924, "inkscape:cx": 5.3615244, "inkscape:cy": 19.619644, "inkscape:window-width": 1920, "inkscape:window-height": 1027, "inkscape:window-x": -8, "inkscape:window-y": -8, "inkscape:window-maximized": 1, "inkscape:current-layer": "g709", showguides: "false", "fit-margin-top": 0, "fit-margin-left": 0, "fit-margin-right": 0, "fit-margin-bottom": 0 }), /* @__PURE__ */ React.createElement("defs", { id: "defs2" }, /* @__PURE__ */ React.createElement("clipPath", { id: "clip0_109_6211" }, /* @__PURE__ */ React.createElement("rect", { width: 8.3999996, height: 8.7799997, fill: "#ffffff", id: "rect15", x: 0, y: 0 }))), /* @__PURE__ */ React.createElement("g", { "inkscape:label": "Layer 1", "inkscape:groupmode": "layer", id: "layer1", transform: "translate(-43.720503,-126.22863)" }, /* @__PURE__ */ React.createElement("g", { id: "g3198" }, /* @__PURE__ */ React.createElement("g", { style: {
  fill: "none"
}, id: "g709", transform: "matrix(0.58546399,0,0,0.58546399,35.617492,126.21403)" }, /* @__PURE__ */ React.createElement("rect", { x: 16.488419, y: 2.3120599, width: 1.2328517, height: 5.3119426, rx: 0.61642385, fill: "#637381", stroke: "#637381", strokeWidth: 0.379662, id: "rect696-3", style: {
  fill: "#637381",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("rect", { x: 18.987904, y: 2.3120599, width: 1.2328517, height: 5.3119426, rx: 0.61642385, fill: "#637381", stroke: "#637381", strokeWidth: 0.379662, id: "rect696-3-5", style: {
  fill: "#637381",
  fillOpacity: 1
} })), /* @__PURE__ */ React.createElement("g", { id: "g862", transform: "matrix(0.26458333,0,0,0.26458333,43.89222,116.21882)" }, /* @__PURE__ */ React.createElement("g", { id: "g807" }, /* @__PURE__ */ React.createElement("path", { d: "m 35.15693,56.631905 v -4.047247 h 2.476896 V 45.153969 H 35.15693 v -4.047246 c 0,-1.18272 -0.962275,-2.144993 -2.144992,-2.144993 H 2.6253791 c -1.1827178,0 -2.14499194,0.962273 -2.14499194,2.144993 v 15.524564 c 0,1.183337 0.96227414,2.14561 2.14499194,2.14561 H 33.011938 c 1.182717,0 2.144992,-0.962273 2.144992,-2.144992 z M 36.395377,46.392418 V 51.34621 H 35.15693 V 46.392418 Z M 2.6253791,57.538449 c -0.500333,0 -0.906544,-0.406829 -0.906544,-0.906544 V 41.106723 c 0,-0.499715 0.406211,-0.906544 0.906544,-0.906544 H 33.011938 c 0.500333,0 0.906544,0.406829 0.906544,0.906544 v 4.047246 7.430689 4.047247 c 0,0.499715 -0.406211,0.906544 -0.906544,0.906544 z", id: "path803", style: {
  fill: "#637381",
  fillOpacity: 1,
  stroke: "#637381",
  strokeWidth: 2.25879,
  strokeMiterlimit: 4,
  strokeDasharray: "none",
  strokeOpacity: 1
} })), /* @__PURE__ */ React.createElement("g", { id: "g809" }), /* @__PURE__ */ React.createElement("g", { id: "g811" }), /* @__PURE__ */ React.createElement("g", { id: "g813" }), /* @__PURE__ */ React.createElement("g", { id: "g815" }), /* @__PURE__ */ React.createElement("g", { id: "g817" }), /* @__PURE__ */ React.createElement("g", { id: "g819" }), /* @__PURE__ */ React.createElement("g", { id: "g821" }), /* @__PURE__ */ React.createElement("g", { id: "g823" }), /* @__PURE__ */ React.createElement("g", { id: "g825" }), /* @__PURE__ */ React.createElement("g", { id: "g827" }), /* @__PURE__ */ React.createElement("g", { id: "g829" }), /* @__PURE__ */ React.createElement("g", { id: "g831" }), /* @__PURE__ */ React.createElement("g", { id: "g833" }), /* @__PURE__ */ React.createElement("g", { id: "g835" }), /* @__PURE__ */ React.createElement("g", { id: "g837" })))));
export default SvgBattery2;

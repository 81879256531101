/* eslint-disable no-param-reassign */
import { flow, getIdentifier, getParent, getSnapshot } from 'mobx-state-tree';
import reject from 'lodash/reject';

import api from '~/api';
import type { IDashboard, IDashboardOut } from '~/mst/models/dashboard';
import { IWidgetsModel } from './model';

export default (self: IWidgetsModel) => ({
  create: flow(function* create(widget) {
    self.startSyncing();
    try {
      const dashboard = getParent<IDashboard>(self);
      const payload = getSnapshot<IDashboardOut>(dashboard);
      yield api.dashboards.update(getIdentifier(dashboard)!, {
        ...payload,
        configuration: {
          ...payload.configuration,
          widgets: [...payload.configuration.widgets, getSnapshot(widget)]
        }
      });
      self.add(widget);
      self.finishSyncing();
    } catch (error) {
      self.failSyncing(error);
    }
  }),
  destroy: flow(function* destroy(widget) {
    widget.startSyncing();
    try {
      const dashboard = getParent<IDashboard>(self);
      const payload = getSnapshot<IDashboardOut>(dashboard);
      yield api.dashboards.update(getIdentifier(dashboard)!, {
        ...payload,
        configuration: {
          ...payload.configuration,
          widgets: reject(payload.configuration.widgets, ({ id }) => id === widget.id)
        }
      });
      widget.finishSyncing();
      self!.remove(widget);
    } catch (error) {
      widget.failSyncing(error);
    }
  })
});

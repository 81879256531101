import { CardHeader } from '@mui/material';
import { styled } from '@mui/material/styles';

const MCardHeader = styled(CardHeader)(({ theme }) => ({
  padding: 0,
  [theme.breakpoints.down('md')]: {
    padding: '0'
  }
}));

export default MCardHeader;

import { observer } from 'mobx-react-lite';
import { CardContent } from '@mui/material';
import { useFormState } from 'react-final-form';

import useMst from '~/hooks/use_mst';
import useLocales from '~/hooks/use_locales';
import useFetch from '~/hooks/use_fetch';

import FormGrid from '~/components/@material-extend/form_grid';
import DevicesSelect from '~/components/devices_select';
import Autocomplete from '~/components/final_form/select';
import { ModalActions } from '~/components/modal';
import DateTimePicker from '~/components/final_form/date_time_picker';

type NewCalibrationModalProps = {
  toggleModal: VoidFunction;
  handleSubmit: VoidFunction;
};

function NewCalibrationModal({ handleSubmit, toggleModal }: NewCalibrationModalProps) {
  const { t } = useLocales();
  const {
    values: { from, to },
    submitting,
    pristine
  } = useFormState({ subscription: { values: true, submitting: true, pristine: true } });
  const { referenceDevices, calibrations } = useMst();

  useFetch(referenceDevices);

  const now = Date.now();

  return (
    <>
      <CardContent>
        <FormGrid>
          <DevicesSelect name="serials" allowSelectAll multiple label={t('calibrations.serials')} options={calibrations.valueLabelPairs} />
          <Autocomplete
            searchable
            name="reference"
            label={t('attributes.calibration.reference_value')}
            options={referenceDevices.valueLabelPairs}
            groupBy={({ model }) => model?.sensor}
          />
          <FormGrid columns="2">
            <DateTimePicker name="from" label={t('thiamis.download_csv.from')} maxDateTime={to || now} timezone="UTC" />
            <DateTimePicker name="to" label={t('thiamis.download_csv.to')} maxDateTime={now} minDate={from} timezone="UTC" />
          </FormGrid>
        </FormGrid>
      </CardContent>
      <ModalActions
        onSave={handleSubmit}
        saveLabel={t('base.buttons.run')}
        onClose={toggleModal}
        disabled={submitting || pristine}
        loading={submitting}
      />
    </>
  );
}

export default observer(NewCalibrationModal);

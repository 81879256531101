import React, { useContext, useState, useCallback, useMemo, ReactNode, useEffect } from 'react';
import useSlug from '~/hooks/use_slug';

const stub = (): never => {
  throw new Error('You forgot to wrap your component in <SelectionProvider>.');
};

export type SelectionState = {
  selection: (string | number)[];
  isSelected: boolean;
  setSelection: (value: (string | number)[]) => void;
};

const initialState = {
  isSelected: false,
  selection: [],
  setSelection: stub
} as SelectionState;

const SelectionContext = React.createContext(initialState);

function SelectionProvider({ children, selected }: { children: ReactNode; selected?: string[] }) {
  const [selection, setSelection] = useState<(string | number)[]>(selected || []);

  const handleSetSelection = useCallback((newTags: (string | number)[]) => setSelection(newTags), [setSelection]);

  const { slug } = useSlug();
  useEffect(() => {
    setSelection(selected || []);
  }, [slug, setSelection, selected]);

  const value = useMemo(
    () => ({
      selection,
      setSelection: handleSetSelection,
      isSelected: !!selection.length
    }),
    [selection, handleSetSelection]
  );

  return <SelectionContext.Provider value={value}>{children}</SelectionContext.Provider>;
}

export { SelectionProvider, SelectionContext };

const useSelection = () => useContext(SelectionContext);

export default useSelection;

import React, { useMemo } from 'react';
import FormGrid from '~/components/@material-extend/form_grid';
import { computed } from 'mobx';
import { CardContent } from '@mui/material';
import { useFormState } from 'react-final-form';
import useLocales from '~/hooks/use_locales';
import useModal from '~/contexts/modal_context';
import useMst from '~/hooks/use_mst';
import OrganizationSelect from '~/components/organizations_select';

import { ModalActions } from '~/components/modal';

type ModalFormProps = {
  handleSubmit: VoidFunction;
};

function AddPartnerFormContent({ handleSubmit }: ModalFormProps) {
  const { t } = useLocales();
  const { organizations } = useMst();
  const { handleClose } = useModal();

  const {
    values: { organization_id: organizationId },
    invalid,
    submitting
  } = useFormState({ subscription: { values: true, invalid: true, submitting: true } });

  const partnershipOptions = useMemo(
    () =>
      computed(() =>
        organizations.valueLabelPairs.filter(
          ({ value }) => value !== organizationId && !organizations.getById(organizationId)?.partner_of.includes(value)
        )
      ),
    [organizationId, organizations]
  ).get();

  return (
    <CardContent>
      <FormGrid>
        <OrganizationSelect name="organization_id" label={t('models.organization')} options={organizations.valueLabelPairs} />
        <OrganizationSelect
          name="partner_id"
          label={t('models.partnership')}
          options={partnershipOptions}
          autoSelect
          freeSolo
          helperText={t('partnerships.add_partnership_help')}
        />
        <ModalActions onClose={handleClose} onSave={handleSubmit} disabled={invalid} loading={submitting} saveLabel={t('base.buttons.create')} />
      </FormGrid>
    </CardContent>
  );
}

export default AddPartnerFormContent;

import React from 'react';
import { Icon as IIcon, IconifyIcon } from '@iconify/react';
import { Box, BoxProps, SxProps } from '@mui/material';

interface Props extends BoxProps {
  sx?: SxProps; // eslint-disable-line react/require-default-props
  icon: IconifyIcon | string;
}

export function Iconify({ icon, sx, ...other }: Props) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Box component={IIcon} icon={icon} sx={{ ...sx }} {...other} />;
}

export function Icon(name: React.ElementType<any>) {
  return <Box component={name!} sx={{ width: '18px', height: '18px' }} />;
}

/* eslint-disable import/prefer-default-export */
import request from '../request';

const { VITE_AUTH0_DOMAIN, VITE_AUTH0_CLIENT_ID } = process.env;

export function resetPassword(email: string) {
  return request('post', `https://${VITE_AUTH0_DOMAIN}/dbconnections/change_password`, {
    email,
    clientId: VITE_AUTH0_CLIENT_ID,
    connection: 'Username-Password-Authentication'
  });
}

export function requestAccessContext({ email, external_id }: { email: string; external_id: string }) {
  return request('post', 'users/access_context/support', { email, external_id });
}

import { types as t, Instance } from 'mobx-state-tree';
import User from '~/mst/models/user';
import Fetchable from '~/mst/models/abstract/fetchable';
import Filterable from '~/mst/models/abstract/filterable';
import CollectionMap from '~/mst/models/abstract/collection_map';

const UsersModel = t.compose(CollectionMap(User), Fetchable({ cache: true }), Filterable);

export interface IUsersModel extends Instance<typeof UsersModel> {}

export default UsersModel;

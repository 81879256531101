/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import React, { useCallback, useMemo } from 'react';
import Select, { SelectProps } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import ListSubheader from '@mui/material/ListSubheader';
import capitalize from 'lodash/capitalize';
import { SxProps } from '@mui/system';
import { Typography, styled, IconButton, FormControl } from '@mui/material';
import { isNull } from 'lodash';
import { Close } from '@mui/icons-material';

export const NULL_VALUE = 'NULL';

type OptionType = { label: string; value: string; group?: boolean };

interface Props extends SelectProps<string> {
  label: string;
  name?: string;
  sx?: SxProps;
  placeholder?: string;
  disabled?: boolean;
  options: Array<OptionType>;
  standalone?: boolean;
}

const NoOptionLabel = styled(Typography)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.text.disabled
}));

export default function MuiSelect(props: Props) {
  const { label, options, name, placeholder, disabled, sx, value, onChange, multiple, standalone = true, ...rest } = props;
  const mappedOptions = useMemo(
    () =>
      options.map((item) => ({
        ...item,
        value: item?.value || NULL_VALUE
      })),
    [options]
  );

  const mappedValue = useMemo(() => {
    if (multiple) {
      return value || [];
    }
    if (isNull(value)) {
      return NULL_VALUE;
    }
    return value;
  }, [value, multiple]);

  const handleChange = useCallback(
    (event) => {
      const newValue = event.target.value;
      if (newValue === NULL_VALUE) {
        if (onChange) {
          onChange(null);
        }
      } else if (onChange) {
        onChange(newValue);
      }
    },
    [onChange]
  );

  const handleClearClick = useCallback(() => {
    if (multiple && onChange) {
      onChange([]);
    }
  }, [multiple, onChange]);

  const Wrapper = standalone ? FormControl : React.Fragment;

  return (
    <Wrapper fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select
        placeholder={placeholder}
        disabled={disabled}
        multiple={multiple}
        fullWidth
        {...rest}
        {...props}
        value={mappedValue}
        onChange={handleChange}
        label={label}
        endAdornment={
          multiple && (
            <IconButton data-testid="clear-button" size="small" sx={{ display: mappedValue!.length ? '' : 'none', mr: 1 }} onClick={handleClearClick}>
              <Close fontSize="small" />
            </IconButton>
          )
        }
      >
        {!mappedOptions.length && <NoOptionLabel>No options</NoOptionLabel>}
        {mappedOptions.map((option) => {
          return option.group ? (
            <ListSubheader key={option.label} data-testid={`${name}GroupOption${capitalize(option.label?.toString() || 'Null')}`}>
              {option.label}
            </ListSubheader>
          ) : (
            <MenuItem key={option.value} value={option.value} data-testid={`${name}Option${capitalize(option.value?.toString() || 'Null')}`}>
              {option.label}
            </MenuItem>
          );
        })}
      </Select>
    </Wrapper>
  );
}

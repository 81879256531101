import { styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';

const EditIconStyled = styled(EditIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
  [theme.breakpoints.down('md')]: {
    display: 'none'
  }
}));

export default EditIconStyled;

import { observer } from 'mobx-react-lite';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import { IDashboard } from '~/mst/models/dashboard';

import GridLayout from './grid_layout';

export type WidgetsProps = {
  dashboard: IDashboard;
};

function Widgets({ dashboard }: WidgetsProps) {
  return <GridLayout widgets={dashboard?.widgets} dashboard={dashboard} editable={false} />;
}

export default observer(Widgets);

import React, { useCallback, useMemo } from 'react';
import { Responsive } from 'react-grid-layout';
import { useTheme } from '@mui/system';

import { Add } from '@mui/icons-material';
import { AddPlaceholder } from '~/pages/dashboards/styled';
import { observer } from 'mobx-react-lite';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import { ResizeHandle } from '~/components/widgets/common';

import WidgetComponent from './widget_wrapper';
import WidthProvider, { WidgetsContainerProps } from './width_provider';
import { WidgetRegionStyled } from './styled';

const ResponsiveGridLayout = WidthProvider(Responsive);

const LAYOUT_MARGIN = [24, 24];

function GridLayout({ editable, breakpoint, updateLayout, widgets, dashboard, addWidget }: WidgetsContainerProps) {
  const { breakpoints } = useTheme();

  const handleUpdateLayout = useCallback(
    (updatedLayout) => {
      if (breakpoint === 'sm' || breakpoint === 'xs') {
        // update disabled for phones
      }
      updateLayout(updatedLayout, breakpoint);
    },
    [breakpoint, updateLayout]
  );

  const children = useMemo(
    () =>
      widgets?.toArray?.map((widget) => (
        <div key={`${widget.id}`}>
          <WidgetComponent widget={widget} moveable={Boolean(editable)} editable={Boolean(editable)} dashboard={dashboard} />
        </div>
      )),
    [widgets?.toArray, editable, dashboard]
  );

  // inline negative margin to remove the margins introduced by grid layout for whole container
  return (
    <WidgetRegionStyled id="widgets-region">
      <ResponsiveGridLayout
        rowHeight={100}
        layouts={widgets?.widgetLayouts}
        margin={LAYOUT_MARGIN}
        isDraggable={editable}
        isResizable={editable}
        isDroppable={false}
        draggableHandle=".action-move"
        breakpoints={breakpoints.values}
        resizeHandle={<ResizeHandle handleAxis="se" />}
        cols={{
          xl: 16,
          lg: 12,
          md: 8,
          sm: 4,
          xs: 4
        }}
        onDragStop={handleUpdateLayout}
        onResizeStop={handleUpdateLayout}
      >
        {children}
        {widgets?.isEmpty && (
          <AddPlaceholder onClick={addWidget} key="new-widget">
            <Add />
            Add widget
          </AddPlaceholder>
        )}
      </ResponsiveGridLayout>
    </WidgetRegionStyled>
  );
}

export default WidthProvider(observer(GridLayout));

import { Field as FinalField } from 'react-final-form';
import { Tooltip, Box } from '@mui/material';

import useLocales from '~/hooks/use_locales';
import I18n from '~/utils/i18n';
import { EXPRESSIONS } from '~/mst/models/condition/types';

type ConditionFieldType = {
  name: string;
};

const FuncOptions = EXPRESSIONS.map((exp) => ({ value: exp, label: I18n.t(`conditions.expressions.${exp}`) }));

function ComparatorSelect(props: ConditionFieldType) {
  const { name } = props;
  const { t } = useLocales();

  return (
    <FinalField name={`${name}.expr`}>
      {({ input }) => {
        return (
          <Tooltip title={t(input.value ? `conditions.expressions.${input.value}_title` : 'attributes.alert_rule.func')} placement="top" arrow>
            <Box sx={{ textAlign: 'center', fontSize: '24px', fontWeight: 400, fontFamily: 'Roboto, sans-serif' }}>
              {FuncOptions.find(({ value }) => input.value === value)?.label}
            </Box>
          </Tooltip>
        );
      }}
    </FinalField>
  );
}

export default ComparatorSelect;

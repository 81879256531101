import React, { useCallback, useMemo } from 'react';
import Yup from '~/utils/yup';
import { Form as FinalForm } from 'react-final-form';
import { observer } from 'mobx-react-lite';

import toasts from '~/utils/toasts';
import { findTimezoneOption } from '~/utils/timezones';
import { useValidationSchema } from '~/hooks/use_validate';
import useAuth from '~/hooks/use_auth';
import useLocales from '~/hooks/use_locales';
import { captureException } from '~/services/sentry';
import UserAccountForm from './form';

function AccountForm() {
  const {
    auth: { user }
  } = useAuth();
  const { t } = useLocales();

  const UserProfileSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    mobile: Yup.string().required('Mobile is required')
  });
  const validate = useValidationSchema(UserProfileSchema);

  const initialValues = useMemo(
    () => ({
      mobile: user?.phone_number,
      picture: user?.picture,
      firstName: user?.first_name,
      lastName: user?.last_name,
      tempUnits: user?.tempUnits,
      timezone: findTimezoneOption(user?.timezone)?.value || null,
      locale: user?.locale
    }),
    [user]
  );
  const handleFormSubmit = useCallback(
    async (values) => {
      const mappedValues = {
        first_name: values.firstName,
        last_name: values.lastName,
        phone_number: values.mobile,
        ...values
      };
      try {
        await user.update(mappedValues);
        toasts.success(t('notifications.success.forms.profile'));
      } catch (e) {
        captureException(e);
        toasts.error(t('notifications.errors.server_error'));
      }
    },
    [t, user]
  );

  return (
    <FinalForm
      validate={validate}
      onSubmit={handleFormSubmit}
      initialValues={initialValues}
      render={({ handleSubmit }) => <UserAccountForm handleSubmit={handleSubmit} submitting={user?.isSyncing} />}
    />
  );
}

export default observer(AccountForm);

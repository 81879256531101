/* eslint-disable react/jsx-no-useless-fragment */
import React, { useMemo } from 'react';
import { Container, Grid, Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import useMst from '~/hooks/use_mst';
import useFetch from '~/hooks/use_fetch';
import useLocales from '~/hooks/use_locales';
import useNavigate from '~/hooks/use_navigate';
import Breadcrumbs from '~/components/breadcrumbs';
import FormContainer from './form_container';

function DeviceCreate() {
  const { t } = useLocales();
  const { id } = useParams();
  const { nodes } = useMst();
  const { navigate } = useNavigate();

  const { isFetched } = useFetch(nodes);

  const node = nodes.getById(id);

  useFetch(node, { node_id: id, includes: ['configuration', 'profiles'] });

  if (isFetched && !node) {
    navigate('devices');
  }

  const links = useMemo(
    () => [
      { title: t('devices_page.title'), to: 'devices' },
      { title: t('thiamis.create_configuration'), to: `devices/${id}/create` }
    ],
    [id, t]
  );

  return (
    <Container maxWidth="lg">
      <Box mb={3}>
        <Breadcrumbs links={links} />
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Box mb={3}>
            <FormContainer node={node} />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export default observer(DeviceCreate);

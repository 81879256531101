/* eslint-disable react/require-default-props */
import { CardActions } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useLocales from '~/hooks/use_locales';
import TextInput from '~/components/final_form/text_input';
import FormGrid from '~/components/@material-extend/form_grid';
import ImageUpload from '~/components/final_form/image_upload';
import { IOrganization } from '~/mst/models/organization';
import { observer } from 'mobx-react-lite';

type FormType = {
  model: IOrganization;
  handleSubmit: VoidFunction;
};

function Form({ model, handleSubmit }: FormType) {
  const { t } = useLocales();

  return (
    <>
      <FormGrid columns="2">
        <ImageUpload name="logo" />
        <TextInput name="name" label={t('attributes.organization.fullname')} />
      </FormGrid>
      <CardActions>
        <LoadingButton type="submit" disabled={model.isSyncing} variant="contained" loading={model.isSyncing} onClick={handleSubmit}>
          {model.isNew ? t('base.buttons.create_new') : t('base.buttons.save_changes')}
        </LoadingButton>
      </CardActions>
    </>
  );
}

export default observer(Form);

/* eslint-disable no-param-reassign */
import { flow, applySnapshot } from 'mobx-state-tree';
import groupBy from 'lodash/groupBy';
import api from '~/api';
import { toMapById } from '~/mst/utils';
import DataPoints from '~/mst/models/data_points/node';
import type { INodesModel } from './model';

export default (self: INodesModel) => ({
  fetchDataPoints: flow(function* fetchDataPoints(params) {
    try {
      const { data } = yield self.sync(api.search.dataPoints, params);
      Object.entries(groupBy(data, 'node_id')).forEach(([nodeId, dataPoints]) => {
        const node = self.getById(nodeId);
        if (node) {
          if (node.data_points?.size) {
            node.data_points.reset();
            applySnapshot(node.data_points, { models: dataPoints });
          } else {
            node.data_points = DataPoints.create(dataPoints);
          }
        }
      });
    } catch (error) {
      self.failFetching(error);
    }
  }),
  fetch: flow(function* fetch(params: { path?: string[]; includes?: string[]; node_id?: string[]; organization_id?: string }, options) {
    try {
      self.startFetching();
      const { data, fromCache } = yield self.sync(api.search.nodes, { type: 'thiamis', ...params }, options);
      if (fromCache === false) {
        self.detach();
        applySnapshot(self, { models: toMapById(data) });
      }
      self.finishFetching();
    } catch (error) {
      self.failFetching(error);
    }
  }),
  claim: flow(function* claim(payload) {
    self.startSyncing();
    try {
      const { serials, organization } = payload;
      const requestBody = { serials, organization_id: organization };
      const { data } = yield api.nodes.claim(requestBody);
      self.finishSyncing();
      return data;
    } catch (error) {
      self.failSyncing(error);
    }
    return null;
  })
});

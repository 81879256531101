import { Grid, Button, Box, CardActions } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { observer } from 'mobx-react-lite';

import useLocales from '~/hooks/use_locales';
import type { IAlert } from '~/mst/models/alert';
import useNavigate from '~/hooks/use_navigate';
import SubscribersBlock from './subscribers_block';
import DetailsBlock from './details_block';
import ConditionsBlock from './conditions_block';

type EditAlertFormType = {
  model: IAlert;
  handleSubmit: VoidFunction;
};

function AlertEditLayout({ handleSubmit, model }: EditAlertFormType) {
  const { t } = useLocales();
  const { navigate } = useNavigate();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={8}>
        <Box mb={3}>
          <DetailsBlock model={model} />
        </Box>
        <ConditionsBlock model={model} />
      </Grid>
      <Grid item xs={12} md={4}>
        <Box mb={3}>
          <SubscribersBlock />
        </Box>
        <CardActions>
          <Button variant="outlined" onClick={() => navigate('alerts')}>
            {t('base.buttons.cancel')}
          </Button>
          <LoadingButton type="submit" variant="contained" loading={model.isSyncing} onClick={handleSubmit} data-testid="alertSaveBtn">
            {model.isNew ? t('base.buttons.create_new') : t('base.buttons.save_changes')}
          </LoadingButton>
        </CardActions>
      </Grid>
    </Grid>
  );
}

export default observer(AlertEditLayout);

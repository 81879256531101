import { Card, CardContent, Stack } from '@mui/material';

import useLocales from '~/hooks/use_locales';
import type { IAlert } from '~/mst/models/alert';
import MCardHeader from '~/components/@material-extend/MCardHeader';
import { HeaderDeviceWrapperStyled } from '~/pages/device_edit/components/styled';

import Item from './components';

function BasicInfoBlock({ alert }: { alert: IAlert }) {
  const { t } = useLocales();
  return (
    <Card>
      <HeaderDeviceWrapperStyled>
        <MCardHeader title="Alert Info" />
      </HeaderDeviceWrapperStyled>
      <CardContent>
        <Stack spacing={2}>
          <Item name="Name" value={alert?.name} />
          <Item name="Owner" value={alert?.ownerName} />
          <Item name="Message" value={alert?.description} />
          <Item name="Notification Type" value={t(`alerts.notification_methods.${alert?.subscribers.notification_method}`)} />
        </Stack>
      </CardContent>
    </Card>
  );
}

export default BasicInfoBlock;

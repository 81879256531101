import React, { useCallback, useMemo } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { Form as FinalForm } from 'react-final-form';
import { observer } from 'mobx-react-lite';
import pick from 'lodash/pick';
import Yup, { searchableSelectSchema } from '~/utils/yup';
import useModal from '~/contexts/modal_context';
import { useValidationSchema } from '~/hooks/use_validate';
import useMst from '~/hooks/use_mst';
import useLocales from '~/hooks/use_locales';
import toasts from '~/utils/toasts';
import WebhookModel from '~/mst/models/webhook';

import { captureException } from '~/services/sentry';
import Form from './form';

function CreateWebhookModal() {
  const { webhooks } = useMst();
  const { t } = useLocales();
  const webhook = WebhookModel.create({});
  const { handleClose: handleCloseModal } = useModal();

  const CreateWebhookSchema = Yup.object().shape({
    url: Yup.string().url().required(),
    auth: Yup.string().required(),
    organization: searchableSelectSchema('Organization is required')
  });

  const validate = useValidationSchema(CreateWebhookSchema);

  const handleClose = useCallback(() => {
    handleCloseModal();
  }, [handleCloseModal]);

  const handleCreateWebhook = useCallback(
    async (values) => {
      const { type, nodes } = values;
      const nodeIds = nodes?.map((node) => node.value);
      try {
        const payload = {
          ...pick(values, ['organization_id', 'url', 'auth']),
          conditions: {
            _type: type,
            node_id: nodeIds?.length ? nodeIds : undefined
          }
        };
        await webhooks.create(payload, webhook);
        datadogRum.addAction('create_webhook', payload);
        handleCloseModal();
        toasts.success(t('notifications.success.forms.create_webhook'));
      } catch (e) {
        captureException(e);
        toasts.error(t('notifications.errors.server_error'));
      }
    },
    [webhooks, handleCloseModal, t, webhook]
  );

  const initialValues = useMemo(() => ({ organization_id: null, nodes: [] }), []);

  return (
    <FinalForm onSubmit={handleCreateWebhook} validate={validate} initialValues={initialValues} subscription={{ submitting: true, pristine: true }}>
      {({ handleSubmit }) => <Form model={webhook} handleCancel={handleClose} handleSubmit={handleSubmit} />}
    </FinalForm>
  );
}

export default observer(CreateWebhookModal);

import { NavLink as RouterLink, useParams } from 'react-router-dom';
import { ListItemText, Link } from '@mui/material';
import useLocales from '~/hooks/use_locales';

import { Iconify } from '~/components/iconify';
import type { NavItemProps } from './type';

import { ListItemStyled, ListItemTextStyled, ListItemIconStyled, ListItemDotStyled, ChildrenIconStyled } from './styled';

type ArrowIconProps = {
  open: boolean;
};

export function ArrowIcon({ open }: ArrowIconProps) {
  return <Iconify icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'} sx={{ width: 16, height: 16, ml: 1 }} />;
}

type DotIconProps = {
  active: boolean;
};

export function DotIcon({ active }: DotIconProps) {
  return (
    <ListItemIconStyled>
      <ListItemDotStyled $active={active} />
    </ListItemIconStyled>
  );
}

export function NavItemRoot({ item, isCollapse, open = false, active, onOpen, onMouseEnter }: NavItemProps) {
  const { title, path, icon, children, component, onClick, target, info } = item;
  const { slug } = useParams();
  let itemPath = `/${slug}${path}`;
  if (target === '_blank') {
    itemPath = path;
  }

  const { t } = useLocales();

  if (component) {
    return component;
  }

  const renderContent = (
    <>
      {icon && <ListItemIconStyled $isCollapse={isCollapse}>{icon}</ListItemIconStyled>}
      <ListItemTextStyled
        disableTypography
        secondary={info}
        primary={t(`${title}.navigation_title`, { defaultValue: title })}
        $isCollapse={isCollapse}
      />
      {children && (
        <ChildrenIconStyled $isCollapse={isCollapse}>
          {info && info}
          <ArrowIcon open={open} />
        </ChildrenIconStyled>
      )}
    </>
  );

  if (children) {
    return (
      <ListItemStyled onClick={onOpen} $activeRoot={active} $isCollapse={isCollapse} onMouseEnter={onMouseEnter}>
        {renderContent}
      </ListItemStyled>
    );
  }

  return (
    <ListItemStyled
      component={onClick ? Link : RouterLink}
      to={itemPath}
      $activeRoot={active}
      onClick={onClick}
      $isCollapse={isCollapse}
      target={target}
      info={info}
    >
      {renderContent}
    </ListItemStyled>
  );
}

interface NavItemSubProps extends NavItemProps {
  root: string;
}

export function NavItemSub({ item, root, open = false, active = false, onOpen }: NavItemSubProps) {
  const { title, path, info, children, component, target, subTitle } = item;
  const { slug } = useParams();
  const itemPath = `/${slug}${root}/${path}`;

  const { t } = useLocales();

  if (component) {
    return component;
  }

  const renderContent = (
    <>
      <DotIcon active={active} />
      <ListItemText sx={{ textTransform: 'none' }} disableTypography primary={t(`${title}.navigation_title`, { defaultValue: title })} />
      {info && info}
      {children && <ArrowIcon open={open} />}
    </>
  );

  if (children) {
    return (
      <ListItemStyled onClick={onOpen} $activeSub={active} $subItem>
        {renderContent}
      </ListItemStyled>
    );
  }

  return (
    <ListItemStyled component={RouterLink} to={itemPath} $activeSub={active} $subItem target={target} subTitle={subTitle}>
      {renderContent}
    </ListItemStyled>
  );
}

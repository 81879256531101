import { Typography } from '@mui/material';
import { ContainerGridStyled } from '~/components/react_grid/styled';
import Container from '~/components/container';
import ApisGrid from './grid_container';
import FormTop from './form_top';

export default function Api() {
  return (
    <Container>
      <Typography variant="h4" component="h1" paragraph>
        API
      </Typography>
      <ContainerGridStyled>
        <FormTop />
        <ApisGrid />
      </ContainerGridStyled>
    </Container>
  );
}

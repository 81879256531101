/* eslint-disable */
import React from 'react';
import { IconProps } from './types';

export default function DevicesIcon({ sx }: IconProps) {
  return (
    <svg
      width={sx ? sx.width : '100%'}
      height={sx ? sx.height : '100%'}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 70.4 66.9"
      fill="currentColor"
    >
      <g>
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M39.7,54.6h-9.9c-0.8,0-1.5,0.7-1.5,1.5c0,0.8,0.7,1.5,1.5,1.5h9.9c0.8,0,1.5-0.7,1.5-1.5   C41.1,55.2,40.5,54.6,39.7,54.6z"
        />
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M69.2,0H40.3C39.6,0,39,0.6,39,1.2v30.4H6.8C6.1,31.6,0,31.8,0,39v22.3v3.1c0,1.4,1.1,2.5,2.5,2.5l3.2,0h35.5   c3.8,0,6.8-1.7,8.3-4.6h12.6c4.6,0,8.3-3.7,8.3-8.3V1.2C70.4,0.6,69.9,0,69.2,0z M6.8,37.2c2.4,0,38.3,0,38.3,0s0,0.8,0,2H5.6V39   C5.6,37.8,5.7,37.2,6.8,37.2z M41.1,61.3H6.4c-0.4,0-0.7-0.3-0.7-0.7V43h39.5c0,5.1,0,12.1,0,14.1C45.1,60.4,43.2,61.3,41.1,61.3z    M60.8,52.8c0,0.8-0.6,1.4-1.4,1.4h-8.6v-2.9h8.6C60.2,51.3,60.8,52,60.8,52.8L60.8,52.8z"
        />
      </g>
    </svg>
  );
}

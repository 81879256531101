import { Grid, Box } from '@mui/material';

import { IDashboard } from '~/mst/models/dashboard';
import MapWidget from '~/components/widgets/map_widget';

import MonitorChart from './monitor_chart';
import DeviceSensors from './device_sensors';
import DeviceDetails from './device_details';

function DeviceMonitor({ monitor }: { monitor: IDashboard }) {
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <MonitorChart monitor={monitor} />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{ displayPrint: 'none' }}>
          <DeviceDetails node={monitor?.nodeWidget?.node} />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{ displayPrint: 'none' }}>
          <MapWidget widget={monitor?.mapWidget} monitor={monitor} isStatic showAddressBar />
        </Grid>
      </Grid>
      <DeviceSensors widget={monitor?.nodeWidget} />
    </Box>
  );
}

export default DeviceMonitor;

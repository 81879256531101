import { types as t, Instance } from 'mobx-state-tree';
import Dashboard from '~/mst/models/dashboard';
import Syncable from '~/mst/models/abstract/syncable';
import Fetchable from '~/mst/models/abstract/fetchable';
import Filterable from '~/mst/models/abstract/filterable';
import CollectionMap from '~/mst/models/abstract/collection_map';

const DashboardsModel = t.compose(CollectionMap(Dashboard), Fetchable({ cache: true }), Syncable, Filterable);

export interface IDashboardsModel extends Instance<typeof DashboardsModel> {}

export default DashboardsModel;

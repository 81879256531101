/* eslint-disable react/require-default-props */
/* eslint-disable react/no-array-index-key */
import { Stack } from '@mui/material';
import useNavigate from '~/hooks/use_navigate';
import Breadcrumb from '~/components/breadcrumbs/components';
import { BreadcrumbsItemStyled, SeparatorLineStyled, SeparatorCircleStyled } from '~/components/breadcrumbs/styled';

export type LinkType = {
  title: string;
  to: string;
  hasAccess?: boolean;
};

function filterLinks(links: LinkType[]) {
  return links.filter(({ hasAccess = true }) => hasAccess);
}

function Breadcrumbs({ links }: { links: LinkType[] }) {
  const { path } = useNavigate();
  return (
    <Stack direction="row" spacing={0.5} sx={{ mb: 3, displayPrint: 'none' }}>
      {filterLinks(links).map((link, index) => {
        if (Array.isArray(link)) {
          return (
            <BreadcrumbsItemStyled key={index}>
              {filterLinks(link).map((subLink, subIndex) => {
                const { to, title } = subLink;
                return (
                  <BreadcrumbsItemStyled key={to}>
                    <Breadcrumb to={to} title={title} active={`/${to}` === path} />
                    {subIndex !== filterLinks(link).length - 1 && <SeparatorCircleStyled />}
                  </BreadcrumbsItemStyled>
                );
              })}
            </BreadcrumbsItemStyled>
          );
        }

        const { to, title } = link;
        return (
          <BreadcrumbsItemStyled key={to}>
            <Breadcrumb to={to} title={title} active={`/${to}` === path} />
            {index !== links.length - 1 && <SeparatorLineStyled />}
          </BreadcrumbsItemStyled>
        );
      })}
    </Stack>
  );
}

export default Breadcrumbs;

/* eslint-disable no-param-reassign */
import { flow, applySnapshot, getIdentifier } from 'mobx-state-tree';
import api from '~/api';
import { toMapById } from '~/mst/utils';
import { IUsersModel } from './model';

export default (self: IUsersModel) => ({
  fetch: flow(function* fetch(params?, options?) {
    try {
      self.startFetching();
      const { data } = yield self.sync(api.users.fetch, params, options);
      applySnapshot(self.models, toMapById(data, 'id'));
      self.finishFetching();
    } catch (error) {
      self.failFetching(error);
    }
  }),
  remove(user) {
    self.models.delete(getIdentifier(user)!);
  }
});

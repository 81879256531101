import { Field as FinalField } from 'react-final-form';

import { timePeriodOptions } from '~/pages/alert_edit/conditions_block/condition/parameter_field/time_period_select';

type ConditionFieldType = {
  name: string;
};

function TimePeriodField(props: ConditionFieldType) {
  const { name } = props;
  return (
    <FinalField name={name}>{({ input }) => <span> for {timePeriodOptions.find(({ value }) => input.value === value)?.label}</span>}</FinalField>
  );
}

export default TimePeriodField;

/* eslint-disable no-param-reassign */
import { flow } from 'mobx-state-tree';
import api from '~/api';
import type { IFilesWidgetModel } from './model';

export default (self: IFilesWidgetModel) => ({
  fetch: flow(function* fetch() {
    try {
      self.startFetching();
      const { data } = yield self.sync(api.thiamis.read, self.serial, 'lua');
      self.files = data.files;
      self.finishFetching();
    } catch (error) {
      self.failFetching(error);
    }
  }),
  update: flow(function* update(payload) {
    self.startSyncing();
    try {
      yield api.thiamis.update(self.serial, 'lua', payload);
      self.finishSyncing();
    } catch (error) {
      self.failSyncing(error);
    }
  })
});

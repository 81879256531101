/* eslint-disable no-param-reassign */
import { types as t, Instance } from 'mobx-state-tree';
import Syncable from '~/mst/models/abstract/syncable';
import Fetchable from '~/mst/models/abstract/fetchable';
import { THEMES } from '~/utils/constants';
import User from '~/mst/models/user';
import { PERMISSIONS } from '~/mst/models/permissions/types';

const AuthModel = t
  .compose(
    t.model({
      user: t.maybeNull(User),
      organization_id: t.maybeNull(t.string),
      theme: t.optional(t.string, THEMES.LIGHT),
      locale: t.optional(t.string, 'en-US')
    }),
    Syncable,
    Fetchable()
  )
  .views((self) => ({
    get organizationId() {
      if (self.organization_id && self.organization_id !== 'all') {
        return self.organization_id;
      }
      return null;
    },
    hasAdminDevice(organizationId: string) {
      return self.user?.hasPermission(PERMISSIONS.AdminDevices, organizationId);
    }
  }));

export interface IAuthModel extends Instance<typeof AuthModel> {}

export default AuthModel;

import { Tooltip, TooltipProps, styled } from '@mui/material';

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Tooltip {...props} componentsProps={{ tooltip: { className } }} />
))(`
    padding: 15px 20px;
    min-width: 160px;
    background-color: white;
    -webkit-box-shadow: 0px 2px 11px -2px rgba(0,0,0,0.2); 
    box-shadow: 0px 2px 11px -2px rgba(0,0,0,0.2);
    & .MuiTooltip-arrow {
      color: white;
    }
`);

export default StyledTooltip;

import { styled, Box, Card, IconButton } from '@mui/material';
import { transientOptions } from '~/utils/styled';

export const IconButtonStyled = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: -10,
  right: -10,
  opacity: 0,
  backgroundColor: theme.palette.grey[200],
  padding: theme.spacing(0.5)
}));
export const ParamContainerStyled = styled(
  Card,
  transientOptions
)(({ theme, $hidden }) => ({
  position: 'relative',
  cursor: 'pointer',
  padding: theme.spacing(1, 3),
  justifyContent: 'center',
  overflow: 'visible',
  marginBottom: '1rem !important',
  [theme.breakpoints.down('sm')]: {
    width: '100%'
  },
  '&:hover': {
    boxShadow: theme.shadows[8]
  },
  ...($hidden && {
    opacity: 0.7,
    '.MuiButtonBase-root': {
      opacity: 1
    }
  })
}));

export const ParamUnitItemStyled = styled(Box)(() => ({
  // display: 'flex',
  lineHeight: 1
  // textAlign: 'center',
  // gap: '0.5rem'
}));

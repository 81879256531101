import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import Switch from '@mui/material/Switch';
import { IWebhook } from '~/mst/models/webhook';

function EnabledFormatter({ row }: { row: IWebhook }) {
  const handleChangeEnabled = useCallback(() => {
    row.toggle();
  }, [row]);

  return <Switch checked={row.enabled} onChange={handleChangeEnabled} />;
}

export default observer(EnabledFormatter);

import Autocomplete from '~/components/final_form/select';
import type { IOrganization } from '~/mst/models/organization';
import type { ValueLabelModelPair } from '~/mst/models/abstract/collection_types';
import { ExternalIdStyled, ExternalIdContainerStyled, ListItemStyled } from './styled';

export type Option = ValueLabelModelPair<IOrganization>;
type OrganizationsSelectProps = {
  options: Option[];
  onInputChange?: () => void;
};

export const filterText = (option: Option) => `${option.label} ${option.model?.external_id}`;
export const renderOption = (option: Option) => (
  <ListItemStyled>
    {option.label}
    <ExternalIdStyled className="ExternalId">{option.model?.external_id}</ExternalIdStyled>
  </ListItemStyled>
);
export const renderInput = (option: Option) =>
  option?.model ? (
    <ExternalIdContainerStyled className="ExternalIdContainer">
      <ExternalIdStyled data-testid="external-id" className="ExternalId">
        {option?.model?.external_id}
      </ExternalIdStyled>
    </ExternalIdContainerStyled>
  ) : null;

function OrganizationsSelect(props: OrganizationsSelectProps) {
  return (
    <Autocomplete
      searchable
      filterText={filterText}
      renderOption={renderOption}
      renderInput={renderInput}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
}

export default OrganizationsSelect;

import { types as t, Instance, SnapshotOut } from 'mobx-state-tree';
import { ModelV1 } from '~/mst/models/abstract/model';
import Syncable from '~/mst/models/abstract/syncable';

const ArchiveModel = t.compose(
  t.model({
    serial: t.string,
    from: t.number,
    to: t.number,
    oid: t.array(t.string)
  }),
  ModelV1,
  Syncable
);

export interface IArchiveModel extends Instance<typeof ArchiveModel> {}
export interface IArchiveModelSnapshotOut extends SnapshotOut<typeof ArchiveModel> {}

export default ArchiveModel;

import { getIdentifier, getType, getParent, hasParent } from 'mobx-state-tree';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import kebabCase from 'lodash/kebabCase';

import { SENSOR_STATUSES } from '~/utils/constants';
import { isThiamisDevice } from '~/mst/models/device/utils';
import type { INode } from '~/mst/models/node';
import type { IDeviceModel } from './model';
import type { IAirthinxDevice } from './airthinx';

type Model = IDeviceModel | IAirthinxDevice;

export default (self: Model) => {
  function getDataPoints() {
    if (hasParent(self, 3)) {
      const dataPoints = getParent<INode>(self, 3)?.data_points;
      return dataPoints?.toArray.filter((dp) => {
        if (dp.isMetric) {
          return dataPoints?.getById(dp.linked[0])?.deviceId === getIdentifier(self);
        }
        return dp.deviceId === getIdentifier(self);
      });
    }
    return [];
  }
  function getDataPointByName(name) {
    return self.dataPoints?.find((dp) => kebabCase(dp.name) === kebabCase(name));
  }
  return {
    get node() {
      return getParent<INode>(self, 3);
    },
    get isThiamis() {
      return isThiamisDevice(getIdentifier(self));
    },
    get isAirthinx() {
      return getType(self)?.name === 'AirThinx';
    },
    get isHealthway() {
      return getType(self)?.name === 'Healthway';
    },
    get hasProfile() {
      return isEmpty(self.profiles) === false;
    },
    get dataPoints() {
      return getDataPoints();
    },
    get defaultAndMetricDataPoints() {
      return orderBy(
        getDataPoints()?.filter((dp) => dp.isDefault || dp.isMetric),
        'name'
      );
    },
    get metricDataPoints() {
      return orderBy(
        getDataPoints()?.filter((dp) => dp.isMetric),
        'name'
      );
    },
    get rawDataPoints() {
      return orderBy(
        getDataPoints()?.filter((dp) => dp.isRaw),
        'name'
      );
    },
    get status() {
      return getDataPointByName('status')?.lastValue;
    },
    get errors() {
      return self.dataPoints?.filter((dp) => dp.isError && dp.lastValue);
    },
    get displayErrors() {
      return isEmpty(self.errors) ? [{ name: self.status }] : self.errors;
    },
    get hasError() {
      return (!isEmpty(self.status) && self.status !== SENSOR_STATUSES.REPORTING) || !isEmpty(self.errors);
    },
    get lastTs() {
      const timestamps = self.defaultAndMetricDataPoints?.map((dp) => dp.lastTs);
      if (!isEmpty(timestamps)) {
        return Math.max(...timestamps);
      }
      return null;
    },
    getDataPointByName(name) {
      return getDataPointByName(name);
    }
  };
};

import { useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { IDataPointNode } from '~/mst/models/data_point/node/default';
import Trendline from '~/components/trendline';
import { Stack } from '@mui/material';

function MeasurementsFormatter({ row }: { row: IDataPointNode }) {
  const ref = useRef();

  return (
    <Stack ref={ref} direction="row" alignItems="center" sx={{ width: '100%', height: '25px', alignSelf: 'end' }}>
      <Trendline dataPoint={row} data={row?.data} showZones />
    </Stack>
  );
}

export default observer(MeasurementsFormatter);

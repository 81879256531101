/* eslint-disable no-param-reassign */
import { types as t, Instance } from 'mobx-state-tree';
import ReferenceDevice from '~/mst/models/reference_device';
import Fetchable from '~/mst/models/abstract/fetchable';
import CollectionArray from '~/mst/models/abstract/collection_array';

const ReferenceDevicesModel = t.compose(CollectionArray(ReferenceDevice), Fetchable({ cache: true }));

export interface IReferenceDevicesModel extends Instance<typeof ReferenceDevicesModel> {}

export default ReferenceDevicesModel;

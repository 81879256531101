import { styled, Typography, Box } from '@mui/material';

export const EmptyDevicesContainerStyled = styled(Box)`
  display: inline-block;
  position: absolute;
  left: 50%;
  padding-top: 50px;
  text-align: center;
`;

export const EmptyDevicesTextStyled = styled(Typography)`
  white-space: nowrap;
  transform: translateX(-50%);
`;

export const EmptyScreenContainerStyled = styled(Box)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

export const EmptyAirthinxContainerStyled = styled(Box)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 50vw;
  max-width: 400px;
  min-width: 300px;
  height: 300px;
  margin: 40px auto;
  text-align: center;
  padding: 10px 40px 20px 40px;
  border: 1px dashed rgba(119, 119, 119, 0.3);
  border-radius: 8px;
`;

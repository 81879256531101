/* eslint-disable @typescript-eslint/no-use-before-define */
import { types as t, Instance } from 'mobx-state-tree';
import Device from '~/mst/models/device';
import BitConfigType from '~/mst/common/bit_config_type';

const ThiamisModel = t
  .compose(
    Device,
    t.model({
      wifi_ssid: t.maybeNull(t.string),
      wifi_password: t.maybeNull(t.string),
      location: t.maybeNull(t.frozen()),
      location_description: t.maybeNull(t.string),
      debug: t.maybeNull(BitConfigType),
      enable_battery: t.maybeNull(BitConfigType),
      enable_temperature: t.maybeNull(BitConfigType),
      enable_pressure: t.maybeNull(BitConfigType),
      debug_level: t.maybeNull(t.frozen()),
      debug_tags: t.maybeNull(t.array(t.string))
    })
  )
  .named('Thiamis');

export interface IThiamisModel extends Instance<typeof ThiamisModel> {}

export default ThiamisModel;

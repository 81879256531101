import { Stack, Box, Button, styled, TextField } from '@mui/material';
import { transientOptions } from '~/utils/styled';

export const SearchWrapperStyled = styled(Box)(() => ({
  position: 'relative',
  width: '100%'
}));

export const FilterButtonStyled = styled(Button)(({ theme }) => ({
  background: theme.palette.grey[500_12],
  color: 'rgba(0, 0, 0, 0.6)',
  minWidth: '50px',
  borderRadius: '5px'
}));

export const FilterButtonWrapperStyled = styled(Stack)(() => ({
  display: 'flex',
  alignItems: 'strech',
  background: 'transparent',
  borderRadius: '0px',
  position: 'absolute',
  right: '0.4rem',
  top: '0.4rem',
  bottom: '0.4rem'
}));

export const BadgeContainerStyled = styled(Stack)(({ theme }) => ({
  cursor: 'pointer',
  padding: '0 6px',
  transition: 'all .2s',
  borderRadius: '10px',
  minWidth: '20px',
  lineHeight: 1,
  height: '20px',
  fontSize: '11px',
  background: theme.palette.primary.main,
  '&:hover': {
    transform: 'scale(1.05) !important',
    boxShadow: '0 8px 16px 0 rgb(145, 158, 171, 0.64)',
    outline: `6px solid ${theme.palette.grey[100]}`
  }
}));

export const TextFieldStyled = styled(
  // eslint-disable-next-line react/jsx-props-no-spreading
  (props) => <TextField InputProps={{ disableUnderline: true }} {...props} />,
  transientOptions
)(({ theme, $transition = true }) => ({
  flex: 1,
  width: '100%',
  maxWidth: '100%',
  background: theme.palette.background.navbar,
  borderRadius: theme.shape.borderRadiusSm,
  ...($transition && {
    '.MuiInputBase-root': {
      transition: 'all 0.2s',
      [theme.breakpoints.down('md')]: {
        maxWidth: '100%'
      }
    },
    '.Mui-focused': {
      maxWidth: '100%'
    }
  })
}));

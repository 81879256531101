/* eslint-disable no-param-reassign */
import { flow, applySnapshot } from 'mobx-state-tree';
import api from '~/api';
import type { ICertificateModel } from '~/mst/models/certificate';
import { toMapById } from '~/mst/utils';
import type { ICertificatesModel } from './model';

export default (self: ICertificatesModel) => ({
  fetch: flow(function* fetch(params = {}, options = {}) {
    try {
      self.startFetching();
      const { data } = yield self.sync(api.search.nodes, { ...params, includes: ['templates', 'user_metadata'], type: 'report' }, options);
      // normilization can be handled in snapshotProcessor once https://github.com/mobxjs/mobx-state-tree/pull/1823 is merged
      applySnapshot(self.models, toMapById(data));
      self.finishFetching();
    } catch (error) {
      self.failFetching(error);
    }
  }),
  create: flow(function* create(payload, model: ICertificateModel) {
    model.startSyncing();
    try {
      const { data } = yield api.nodes.create(payload);
      self.models.put(data);
      model.finishSyncing();
    } catch (error) {
      model.failSyncing(error);
    }
  })
});

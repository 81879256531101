import { types as t, Instance } from 'mobx-state-tree';
import { ModelV1 as Model } from '~/mst/models/abstract/model';
import Syncable from '~/mst/models/abstract/syncable';

const DeviceModel = t.compose(
  t.model({
    connection: t.string,
    name: t.string,
    configuration: t.frozen()
  }),
  Model,
  Syncable
);

export interface IDeviceModel extends Instance<typeof DeviceModel> {}

export default DeviceModel;

import { Stack, Box } from '@mui/material';
import { observer } from 'mobx-react-lite';
import compact from 'lodash/compact';

import withAutoupdate from '~/hocs/with_autoupdate';
import type { INodeWidget } from '~/mst/models/widget/node_widget';
import useLocales from '~/hooks/use_locales';

import DeviceLastUpdate from '~/components/device_last_update';
import DevicePower from '~/components/device_power';
import DeviceSignal from '~/components/device_signal';
import { STATUS_COLORS } from '~/theme/types';
import { LegendItemStyled, LegendCircleStyled, LegendCircleBoxStyled } from '~/components/widgets/heatmap_widget/content/styled';

const NodeWidgetFooter = withAutoupdate(
  observer(({ widget }: { widget: INodeWidget }) => {
    const lastTs = compact(widget?.nodes?.toArray.map((node) => node.data_points?.lastTimeReportedTs));
    const { t } = useLocales();
    if (widget.nodeId.length === 1) {
      return (
        <Stack direction="row" spacing={1.5} sx={{ width: '100%' }} alignItems="center">
          <DevicePower batteryLevel={widget?.node?.batteryLevel} showLabel={false} isAirthinx={widget?.node?.isAirthinx} />
          <DeviceSignal
            showLabel
            iconLeft
            networkSignal={widget?.node?.networkSignal}
            hasWifi={widget?.node?.hasWifi}
            networkName={widget?.node?.networkName}
            networkSignalUnit={widget?.node?.networkSignalUnit}
          />
          <Box sx={{ ml: 'auto !important' }}>
            <DeviceLastUpdate lastTimeReportedTs={Math.max(...lastTs)} />
          </Box>
        </Stack>
      );
    }
    return (
      <Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }}>
        <Stack direction="row" spacing={1.5} alignItems="flex-end">
          {widget.legend?.map((status) => (
            <LegendItemStyled
              key={`${widget.id}_${status}`}
              onClick={() => widget.setFilter(widget.filter === status ? null : status)}
              direction="row"
              $isSelected={widget.filter === status}
            >
              <LegendCircleBoxStyled className="heatmap-legend-item-circle">
                <LegendCircleStyled sx={{ backgroundColor: STATUS_COLORS[status] }} />
              </LegendCircleBoxStyled>
              {t(`heatmap_legend.${status}`)}
            </LegendItemStyled>
          ))}
        </Stack>
        <Box sx={{ ml: 'auto !important' }}>
          <DeviceLastUpdate lastTimeReportedTs={Math.max(...lastTs)} />
        </Box>
      </Stack>
    );
  })
);

export default NodeWidgetFooter;

import { types as t, Instance, SnapshotOut } from 'mobx-state-tree';
import DateType from '~/mst/models/abstract/date_time';
import AlertDataPoints from '~/mst/models/data_points/alert';

const DurationsTableRowModel = t.model({
  triggered: DateType,
  duration: t.number,
  data_point_id: t.string
});

export interface IDurationsTableRowModel extends Instance<typeof DurationsTableRowModel> {}
export interface IDurationsTableRowModelSnapshotOut extends SnapshotOut<typeof DurationsTableRowModel> {}

const ChartTableWidgetModel = t
  .model({
    zoomLevel: t.maybeNull(
      t.model({
        min: t.number,
        max: t.number
      })
    ),
    data_points: t.optional(AlertDataPoints, [])
  })
  .named('ChartTableWidgetModel');

export interface IChartTableWidgetModel extends Instance<typeof ChartTableWidgetModel> {}
export interface IChartTableWidgetModelSnapshotOut extends SnapshotOut<typeof ChartTableWidgetModel> {}

export default ChartTableWidgetModel;

/* eslint-disable @typescript-eslint/no-use-before-define */
import { types as t, Instance } from 'mobx-state-tree';
import Sensor from '~/mst/models/device/sensor';

const AdamSensor = t
  .compose(
    t.model({
      channels: t.maybeNull(t.frozen())
    }),
    Sensor
  )
  .named('Adam');

export interface IAdamSensor extends Instance<typeof AdamSensor> {}

export default AdamSensor;

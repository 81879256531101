import { alpha, styled } from '@mui/material/styles';
import { AppBar, Toolbar, IconButton, Stack } from '@mui/material';
import cssStyles from '~/utils/css_styles';
import { DRAWER_WIDTH, APP_BAR_MOBILE, APP_BAR_DESKTOP, COLLAPSE_WIDTH } from '~/theme/values';
import { transientOptions } from '~/utils/styled';
import { IconButtonAnimate } from '~/components/animate';
import MenuPopover from '~/components/menu_popover';
import Image from '~/components/image';

export const NavbarStyled = styled(
  AppBar,
  transientOptions
)(({ theme, $isCollapse }) => ({
  ...cssStyles(theme).bgBlur(),
  transition: theme.transitions.create(['width', 'height'], {
    duration: `${theme.animations.duration}s`
  }),
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  borderBottom: theme.palette.borders.header.borderBottom,
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  },
  ...($isCollapse && {
    width: `calc(100% - ${COLLAPSE_WIDTH}px) !important`
  })
}));

export const ToolbarStyled = styled(Toolbar)(({ theme }) => ({
  minHeight: APP_BAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APP_BAR_DESKTOP,
    padding: theme.spacing(0, 4)
  }
}));

export const IconButtonAnimateStyled = styled(
  IconButtonAnimate,
  transientOptions
)(({ $open }) => ({
  width: 40,
  height: 40,
  ...($open && {
    bgcolor: 'action.selected'
  })
}));

export const MenuPopoverStyled = styled(MenuPopover)(() => ({
  mt: 1.5,
  ml: 0.75,
  width: 180,
  '& .MuiMenuItem-root': {
    paddingLeft: 1,
    paddingRight: 1,
    borderRadius: 0.75
  }
}));

export const ImageStyled = styled(Image)(() => ({
  width: 28,
  mr: 2,
  flex: 1
}));

export const IconButtonStyled = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary
}));

export const PopoversWrapperStyled = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: 1.5,
  [theme.breakpoints.down('lg')]: {
    gap: 0.5
  }
}));

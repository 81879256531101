import round from 'lodash/round';

export default {
  chart: {
    zooming: {
      mouseWheel: {
        enabled: false
      }
    },
    defaultSeriesType: 'spline',
    zoomType: 'xy',
    spacingLeft: 0,
    spacingRight: 0,
    marginLeft: 10,
    marginRight: 0,
    spacingTop: 0,
    spacingBottom: 0,
    marginTop: 0,
    marginBottom: 0,
    backgroundColor: 'transparent'
  },
  title: {
    text: ''
  },
  xAxis: {
    type: 'datetime',
    visible: false,
    title: {
      enabled: false
    },
    labels: {
      enabled: false
    },
    startOnTick: false,
    endOnTick: false,
    showLastLabel: false
  },
  yAxis: {
    visible: false,
    title: {
      enabled: false
    },
    labels: {
      enabled: false
    },
    startOnTick: false,
    endOnTick: false,
    showLastLabel: false
  },
  legend: {
    enabled: false
  },
  rangeSelector: {
    enabled: false
  },
  navigator: {
    enabled: false,
    outlineWidth: 0
  },
  exporting: {
    enabled: false
  },
  credits: {
    enabled: false
  },
  lang: {
    noData: ''
  },
  scrollbar: {
    enabled: false,
    barBackgroundColor: '#DCDCDD',
    barBorderRadius: 5,
    barBorderWidth: 0,
    buttonBackgroundColor: 'white',
    buttonBorderWidth: 0,
    buttonArrowColor: '#DCDCDD',
    buttonBorderRadius: 7,
    rifleColor: 'white',
    trackBackgroundColor: 'white',
    trackBorderWidth: 0,
    trackBorderColor: 'white',
    trackBorderRadius: 7
  },
  tooltip: {
    enabled: true,
    hideDelay: 0,
    outside: true,
    split: false,
    crosshairs: true,
    shared: true,
    useHTML: true,
    pointFormatter() {
      const precision = this.series.options.round || 2;
      let value = this.y;
      const decimalPlaces = (value.toString().split('.')[1] || []).length;
      if (decimalPlaces > 4) {
        value = round(value, precision);
      }
      return `<b>${this.series.name}</b>: ${value}`;
    },
    xDateFormat: '%a, %e of %B, %H:%M:%S'
  },
  series: [
    {
      type: 'spline',
      lineWidth: 1,
      crisp: false,
      states: {
        enabled: false,
        hover: {
          enabled: false
        }
      },
      marker: {
        enabled: false,
        states: {
          hover: {
            enabled: true
          }
        }
      }
    }
  ],
  plotOptions: {
    series: {
      marker: {
        enabled: true
      }
    }
  }
};

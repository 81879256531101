/* eslint-disable no-param-reassign */
import { types as t, Instance } from 'mobx-state-tree';
import uniq from 'lodash/uniq';
import Range from '~/mst/models/range';
import Fetchable from '~/mst/models/abstract/fetchable';
import Widgets from './widgets';

export const TABS = {
  GRAPH: {
    name: 'chart',
    index: 0
  },
  TABLE: {
    index: 1,
    name: 'table'
  },
  WIND_ROSE_GRAPH: {
    index: 2,
    name: 'wind_chart'
  }
};
const Params = t
  .model({
    selected_params_chart: t.array(t.string),
    selected_params_table: t.array(t.string),
    selected_params_wind_chart: t.array(t.string)
  })
  .views((self) => ({
    get selectedParamsChart() {
      return uniq(self.selected_params_chart);
    },
    get selectedParamsTable() {
      return uniq(self.selected_params_table);
    },
    get selectedParamsWindChart() {
      return uniq(self.selected_params_wind_chart);
    }
  }))
  .actions((self) => ({
    setSelectedParamsChart(params: string[]) {
      self.selected_params_chart = params;
    },
    setSelectedParamsTable(params: string[]) {
      self.selected_params_table = params;
    },
    setSelectedParamsWindChart(params: string[]) {
      self.selected_params_wind_chart = params;
    }
  }));

const DeviceMonitorModel = t.compose(
  t.model({
    selected_tab: t.optional(
      t.enumeration(
        'Tab',
        Object.entries(TABS).map(([, { name }]) => name)
      ),
      TABS.GRAPH.name
    ),
    is_live_updates_on: t.optional(t.boolean, true),
    params: t.optional(Params, {}),
    widgets: t.optional(Widgets, []),
    range: t.optional(Range, () =>
      // @ts-ignore
      Range.create({
        from: '3_hours'
      })
    )
  }),
  Fetchable()
);

export interface IDeviceMonitorModel extends Instance<typeof DeviceMonitorModel> {}

export default DeviceMonitorModel;

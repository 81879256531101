import React, { useCallback } from 'react';
import { LoadingButton } from '@mui/lab';
import { Card, Box, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { resetPassword } from '~/api/rest/auth';
import useLocales from '~/hooks/use_locales';
import useAuth from '~/hooks/use_auth';
import toasts from '~/utils/toasts';

function PasswordResetForm() {
  const { auth } = useAuth();
  const { t } = useLocales();

  const handleResetPassword = useCallback(async () => {
    try {
      auth.startSyncing();
      const {
        user: { email }
      } = auth;
      await resetPassword(email);
      auth.finishSyncing();
      toasts.success(t('user_profile.pw_change_info', { email }));
    } catch (e) {
      auth.failSyncing(e);
    }
  }, [auth, t]);

  return (
    <Card sx={{ p: 3, mt: 4, display: 'block', overflow: 'hidden' }}>
      <Box
        sx={{
          py: 6,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Typography
          sx={{
            mb: 4,
            maxWidth: '565px',
            textAlign: 'center'
          }}
        >
          {t('user_profile.password_help_text')}
        </Typography>
        <LoadingButton variant="contained" onClick={handleResetPassword} loading={auth.isSyncing}>
          {t('user_profile.set_new_password')}
        </LoadingButton>
      </Box>
    </Card>
  );
}

export default observer(PasswordResetForm);

import { styled } from '@mui/material/styles';
import { pxToRem } from '~/theme/typography';
import { Box } from '@mui/material';

export const ExternalIdContainerStyled = styled(Box)(() => ({
  flexBasis: '100%',
  height: 0,
  position: 'relative',
  paddingBottom: '4px',
  top: '-12px',
  left: '5px'
}));

export const ListItemStyled = styled('span')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start !important',
  lineHeight: 1
}));

export const ExternalIdStyled = styled('span')(({ theme }) => ({
  fontSize: pxToRem(11),
  color: theme.palette.text.disabled,
  paddingTop: theme.spacing(0.5),
  paddingLeft: theme.spacing(0.25)
}));

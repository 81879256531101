import { useCallback } from 'react';

import { useNavigate as useNavigateRouter, useLocation } from 'react-router-dom';
import useSlug from '~/hooks/use_slug';

export default function useNavigate() {
  const { slug } = useSlug();
  const { pathname } = useLocation();
  const navigate = useNavigateRouter();

  const getCurrentPath = useCallback(() => {
    return pathname.replace(`/${slug}`, '');
  }, [slug, pathname]);

  return {
    navigate: useCallback(
      (path, options?) => {
        if (slug) {
          if (path.startsWith('/')) {
            navigate(path, options);
          } else {
            navigate(`/${slug}/${path}`, options);
          }
        } else {
          navigate(`/${path}`, options);
        }
      },
      [navigate, slug]
    ),
    path: getCurrentPath()
  };
}

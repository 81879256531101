/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useCallback } from 'react';
import { Link } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import useLocales from '~/hooks/use_locales';
import { IToken } from '~/mst/models/token';
import useMst from '~/hooks/use_mst';

export default function TokenFormatter({ row, value }: { row: IToken; value: string }) {
  const { t } = useLocales();
  const [isCopied, setIsCopied] = useState(false);
  const [isOpened, setIsOpened] = useState(false);
  const { tokens } = useMst();

  const handleCopyToClipboard = useCallback(async () => {
    if (!row.external_id_revealed) {
      await tokens.getById(row.id).fetch();
    }
    navigator.clipboard.writeText(tokens.getById(row.id).external_id_revealed);
    setIsCopied(true);
    setTimeout(() => setIsOpened(false), 300);
  }, [row, setIsCopied, setIsOpened, tokens]);

  return (
    <Tooltip
      PopperProps={{
        disablePortal: true
      }}
      onMouseLeave={() => {
        setIsOpened(false);
      }}
      onMouseEnter={() => {
        setIsCopied(false);
        setIsOpened(true);
      }}
      open={isOpened}
      title={isCopied ? t('base.labels.copied') : t('base.labels.copy_to_clipboard')}
    >
      <Link onClick={handleCopyToClipboard}>{value}</Link>
    </Tooltip>
  );
}

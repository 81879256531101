import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Portal } from '@mui/material';
import { Form as FinalForm } from 'react-final-form';
import { computed } from 'mobx';

import StatusBar from '~/pages/device_monitor/monitor_chart/sidebar/status_bar';
import useResponsive from '~/hooks/use_responsive';
import { SidebarContainerStyled, SidebarInnerStyled, SidebarOuterStyled, CatcherStyled } from '~/pages/device_monitor/monitor_chart/sidebar/styled';
import toasts from '~/utils/toasts';
import { captureException } from '~/services/sentry';
import useLocales from '~/hooks/use_locales';
import type { IDeviceAdmin } from '../../../store';
import SidebarForm from './form';

type SidebarType = {
  isExpanded?: boolean;
  onToggleCollapse?: VoidFunction;
  report?: IDeviceAdmin;
  width?: string | number;
};

function Sidebar({ report, isExpanded, width, onToggleCollapse }: SidebarType) {
  const { t } = useLocales();
  const isMobile = useResponsive('down', 'md');

  const handleOnSubmit = useCallback(
    async (values) => {
      try {
        await report?.debugWidget?.node?.thiamis?.update(values);
      } catch (e) {
        captureException(e);
        toasts.error(t('notifications.errors.server_error'));
      }
    },
    [report?.debugWidget, t]
  );

  const initialValues = useMemo(() => computed(() => report?.debugWidget?.node?.thiamis?.toJSON()), [report?.debugWidget?.node?.thiamis]).get();

  return (
    <SidebarContainerStyled $isExpanded={isExpanded} $width={width}>
      {isMobile && (
        <Portal>
          <CatcherStyled $isExpanded={isExpanded} onClick={onToggleCollapse} />
        </Portal>
      )}

      <Portal disablePortal={!isMobile}>
        <SidebarOuterStyled $isExpanded={isExpanded} $width={width}>
          <SidebarInnerStyled $isExpanded={isExpanded} $width={width}>
            <FinalForm onSubmit={handleOnSubmit} initialValues={initialValues}>
              {({ handleSubmit }) => (
                <SidebarForm handleSubmit={handleSubmit} report={report} submitting={report?.debugWidget?.node?.thiamis?.isSyncing} />
              )}
            </FinalForm>
          </SidebarInnerStyled>
        </SidebarOuterStyled>
      </Portal>
      <StatusBar node={report?.nodeWidget?.node} isExpanded={isExpanded} />
    </SidebarContainerStyled>
  );
}

export default observer(Sidebar);

import { DataTypeProvider } from '@devexpress/dx-react-grid';
import TimeAgoFormatter from '~/components/react_grid/time_ago_formatter';
import DeviceScopeFormatter from './device_scope_formatter';
import TokenFormatter from './token_formatter';
import PermissionsFormatter from './permissions_formatter';
import ActionsFormatter from './actions_formatter';

export function TimeAgoProvider() {
  return <DataTypeProvider for={['created_at', 'last_used_at']} formatterComponent={TimeAgoFormatter} />;
}

export function DeviceScopeProvider() {
  return <DataTypeProvider for={['deviceScope']} formatterComponent={DeviceScopeFormatter} />;
}

export function TokenProvider() {
  return <DataTypeProvider for={['external_id']} formatterComponent={TokenFormatter} />;
}

export function PermissionsProvider() {
  return <DataTypeProvider for={['permissions']} formatterComponent={PermissionsFormatter} />;
}

export function ActionsProvider() {
  return <DataTypeProvider for={['actions']} formatterComponent={ActionsFormatter} />;
}

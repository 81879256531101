import { Box, ListItemIcon, ListItemButton } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import SimpleBarReact from 'simplebar-react';

export const SidebarStyled = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: 0
  }
}));

export const SupportStyled = styled(Box)(({ theme }) => ({
  left: '0',
  right: '0',
  bottom: '0',
  padding: theme.spacing(1, 2.5),
  cursor: 'pointer',
  background: theme.palette.primary.contrastText,
  zIndex: '998',
  boxShadow: theme.customShadows.z24
}));

export const ListItemStyled = styled(ListItemButton)(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  paddingLeft: theme.spacing(2.5),
  paddingRight: theme.spacing(2.5),
  marginBottom: theme.spacing(0.5),
  color: theme.palette.primary.muted,
  borderRadius: theme.shape.borderRadiusSm,
  '&:hover': {
    backgroundColor: theme.palette.grey[500_12]
  },
  '&.active': {
    backgroundColor: theme.palette.primary.lighter,
    color: theme.palette.primary.main,
    fontWeight: 'bold'
  }
}));

export const ListItemIconStyled = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

export const ScrollbarStyled = styled('div')(() => ({
  flexGrow: 1,
  height: '100%',
  overflow: 'hidden'
}));

export const SimpleBarStyled = styled(SimpleBarReact)(({ theme }) => ({
  position: 'relative',
  flexDirection: 'column',
  flexWrap: 'wrap',
  justifyContent: 'flex-start',
  alignContent: 'flex-start',
  alignItems: 'flex-start',
  maxHeight: '100%',
  '& .simplebar-wrapper': {
    overflow: 'hidden',
    width: 'inherit',
    height: 'inherit',
    maxWidth: 'inherit',
    maxHeight: 'inherit'
  },
  '& .simplebar-offset': {
    direction: 'inherit !important',
    boxSizing: 'inherit !important',
    resize: 'none !important',
    position: 'absolute',
    top: '0',
    left: '0',
    bottom: '0',
    right: '0 !important',
    padding: '0',
    margin: '0'
  },
  '.simplebar-content-wrapper::-webkit-scrollbar, .simplebar-hide-scrollbar::-webkit-scrollbar': {
    width: 0,
    height: 0
  },
  '& .simplebar-content-wrapper': {
    direction: 'inherit',
    boxSizing: 'border-box !important',
    position: 'relative',
    display: 'block',
    height: '100%',
    width: 'auto',
    maxWidth: '100%',
    maxHeight: '100%',
    scrollbarWidth: 'none'
  },
  '& .simplebar-height-auto-observer-wrapper': {
    boxSizing: 'inherit !important',
    height: '100%',
    width: '100%',
    maxWidth: '1px',
    position: 'relative',
    float: 'left',
    maxHeight: '1px',
    overflow: 'hidden',
    zIndex: '-1',
    padding: '0',
    margin: '0',
    pointerEvents: 'none',
    flexGrow: 'inherit',
    flexShrink: '0',
    flexBasis: '0'
  },
  '& .simplebar-height-auto-observer': {
    boxSizing: 'inherit',
    display: 'block',
    opacity: '0',
    position: 'absolute',
    top: '0',
    left: '0',
    height: '1000%',
    width: '1000%',
    minHeight: '1px',
    minWidth: '1px',
    overflow: 'hidden',
    pointerEvents: 'none',
    zIndex: '-1'
  },
  '& .simplebar-content': {
    '&:before': {
      content: '" "',
      display: 'table'
    },
    '&:after': {
      content: '" "',
      display: 'table'
    }
  },
  '& .simplebar-scrollbar': {
    position: 'absolute',
    left: '0',
    right: '0',
    minHeight: '10px',
    '&:before': {
      position: 'absolute',
      content: '" "',
      borderRadius: '7px',
      left: '2px',
      right: '2px',
      opacity: '0',
      transition: `opacity ${theme.animations.duration}s linear`,
      top: '2px',
      bottom: '2px',
      backgroundColor: alpha(theme.palette.grey[600], 0.48)
    },
    '&.simplebar-visible:before': {
      opacity: 1
    }
  },
  '& .simplebar-track': {
    zIndex: '1',
    position: 'absolute',
    right: '0',
    bottom: '0',
    pointerEvents: 'none',
    overflow: 'hidden'
  },
  '& .simplebar-track.simplebar-vertical': {
    top: 0,
    width: 10,
    '& .simplebar-scrollbar:before': {
      top: '2px',
      bottom: '2px'
    }
  },
  '& .simplebar-track.simplebar-horizontal .simplebar-scrollbar': {
    height: 6
  },
  '& .simplebar-placeholder': {
    maxHeight: '100%',
    maxWidth: '100%',
    width: '100%',
    pointerEvents: 'none'
  },
  '& .simplebar-mask': {
    direction: 'inherit',
    position: 'absolute',
    overflow: 'hidden',
    padding: '0',
    margin: '0',
    left: '0',
    top: '0',
    bottom: '0',
    right: '0',
    width: 'auto !important',
    height: 'auto !important',
    zIndex: '0'
  },
  '& .simplebar-hide-scrollbar': {
    position: 'fixed',
    left: '0',
    visibility: 'hidden',
    overflowY: 'scroll',
    scrollbarWidth: 'none'
  }
}));

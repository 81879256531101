/* eslint-disable no-param-reassign */
import { flow, applySnapshot } from 'mobx-state-tree';
import api from '~/api';
import { toMapById } from '~/mst/utils';
import type { SearchDataPointsParams } from '~/api/rest/search';
import type { IChartTableWidgetModel } from './model';

export default (self: IChartTableWidgetModel) => ({
  fetch: flow(function* fetch(params = {}) {
    try {
      self.startFetching();
      const payload = {
        node_id: self.node_id,
        includes: ['profiles', 'configuration', 'data_points'],
        ...params
      } as SearchDataPointsParams;
      const { data } = yield self.sync(api.search.nodes, payload);
      applySnapshot(self.nodes, {
        models: toMapById(data)
      });
      self.finishFetching();
    } catch (error) {
      self.failFetching(error);
    }
  }),
  toggleShowZones() {
    self.show_zones = !self.show_zones;
  },
  toggleShowDataGaps() {
    self.show_data_gaps = !self.show_data_gaps;
  }
});

import React from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import NavLink from '~/components/nav_link';

export default function NameFormatter(props: DataTypeProvider.ValueFormatterProps) {
  const {
    row: { _id: alertId, name }
  } = props;
  return <NavLink to={`alerts/${alertId}/monitor`}>{name}</NavLink>;
}

/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import { observer } from 'mobx-react-lite';
import WidgetContainer from '~/components/widgets/common';
import { WidgetPropType } from '~/components/widgets/common/types';
import useModal from '~/hooks/use_modal';
import { INodeWidget } from '~/mst/models/widget/node_widget';
import { WidgetTitleStyled } from '~/components/widgets/common/styled';
import DeviceWidgetTitle from '../common/device_widget_title';

import NodeWidgetContent from './content';
import NodeWidgetConfiguration from './configuration';
import NodeWidgetFooter from './content/footer';

function NodeWidget({ widget, moveable, dashboard, editable, onClose }: WidgetPropType<INodeWidget>) {
  const { open, handleOpen, handleClose } = useModal();
  return (
    <WidgetContainer
      widget={widget}
      moveable={moveable}
      editable={editable}
      onEdit={handleOpen}
      dashboard={dashboard}
      Header={
        widget?.nodeId.length === 1 ? (
          <DeviceWidgetTitle node={widget?.node} onClose={onClose} />
        ) : (
          <WidgetTitleStyled>{widget.name || 'Device widget'}</WidgetTitleStyled>
        )
      }
      Footer={NodeWidgetFooter}
    >
      <NodeWidgetContent widget={widget} dashboard={dashboard} />
      <NodeWidgetConfiguration open={open} onClose={handleClose} widget={widget} />
    </WidgetContainer>
  );
}

export default observer(NodeWidget);

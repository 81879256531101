/* eslint-disable no-param-reassign */
import { types as t, Instance } from 'mobx-state-tree';
import CollectionMap from '~/mst/models/abstract/collection_map';

const MeasurementDataItemModel = t.model({
  ts: t.frozen(t.number),
  value: t.frozen()
});

const MeasurementDataItem = t.snapshotProcessor(MeasurementDataItemModel, {
  preProcessor(values) {
    if (Array.isArray(values)) {
      const [ts, value] = values;
      return { ts, value };
    }
    return values;
  },
  postProcessor(sn) {
    const { ts, value } = sn;
    return [ts, value];
  }
});

export const MeasurementsModel = t.model({
  data_point_id: t.string,
  data: t.array(MeasurementDataItem)
});

export interface IMeasurementsModel extends Instance<typeof MeasurementsModel> {}

const MeasurementsStore = CollectionMap(MeasurementsModel);
export interface IMeasurementsStore extends Instance<typeof MeasurementsStore> {}

export default MeasurementsStore;

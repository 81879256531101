/* eslint-disable @typescript-eslint/naming-convention */
import pick from 'lodash/pick';
import { IPartnershipModel } from '../model';

export default (self: IPartnershipModel) => {
  return {
    get searchString() {
      const searchFields = ['partnerName', 'partnerExternalId', 'organizationName', 'organizationExternalId'];
      return Object.values(pick(self, searchFields)).join(' ').toLowerCase();
    }
  };
};

import FormGrid from '~/components/@material-extend/form_grid';
import TextInput from '~/components/final_form/text_input';
import { REPORTING_INTERVALS } from '~/utils/constants';
import Select from '~/components/final_form/select';
import useLocales from '~/hooks/use_locales';
import { INode } from '~/mst/models/node';

function DescriptionForm({ node }: { node: INode }) {
  const { t } = useLocales();
  const options = node?.isAirthinx ? REPORTING_INTERVALS.slice(0, 1) : REPORTING_INTERVALS;
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormGrid>
      <TextInput label="Name" name="name" data-testid="deviceNameInput" />
      <TextInput label="Description" name="description" data-testid="deviceDescriptionInput" />
      <Select name="interval" label={t('device_monitor.forms.reporting_interval')} options={options} data-testid="deviceIntervalSelect" />
    </FormGrid>
  );
}

export default DescriptionForm;

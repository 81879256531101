import { Grid, VirtualTable, TableHeaderRow, PagingPanel } from '@devexpress/dx-react-grid-material-ui';
import { SortingState, PagingState, IntegratedSorting, IntegratedPaging } from '@devexpress/dx-react-grid';
import { observer } from 'mobx-react-lite';
import useMst from '~/hooks/use_mst';
import I18n from '~/utils/i18n';
import { GridStyled } from '~/components/react_grid/styled';
import useSorting from '~/hooks/react_grid/use_sorting';
import useLoading from '~/hooks/react_grid/use_loading';
import usePaging from '~/hooks/react_grid/use_paging';
import useFetch from '~/hooks/use_fetch';
import { FromToProvider, ActionsProvider } from './info_providers';

const columns = [
  { name: 'serial', title: I18n.t('attributes.archive.serial') },
  { name: 'from', title: I18n.t('attributes.archive.date_from') },
  { name: 'to', title: I18n.t('attributes.archive.date_to') },
  { name: 'organizationName', title: I18n.t('attributes.archive.uploaded_by') },
  { name: 'actions', title: ' ', sortingEnabled: false }
];

function ArchivesGrid() {
  const { archives } = useMst();

  useFetch(archives);

  const { messages, RootComponent } = useLoading(archives);
  const { pageSizes, pageSize, handlePageSizeChange } = usePaging();

  const { sorting, handleSortingChange } = useSorting([{ columnName: 'to', direction: 'desc' }]);
  return (
    <GridStyled>
      <Grid rows={archives.filteredList} columns={columns} rootComponent={RootComponent}>
        <SortingState sorting={sorting} onSortingChange={handleSortingChange} />
        <IntegratedSorting />
        <PagingState defaultCurrentPage={0} pageSize={pageSize} onPageSizeChange={handlePageSizeChange} />
        <IntegratedPaging />
        <FromToProvider for={['from', 'to']} />
        <ActionsProvider for={['actions']} />
        <VirtualTable
          height="auto"
          messages={messages}
          columnExtensions={[
            {
              columnName: 'actions',
              width: 60
            }
          ]}
        />
        <TableHeaderRow showSortingControls />
        {archives.filteredList.length > pageSize && <PagingPanel pageSizes={pageSizes} />}
      </Grid>
    </GridStyled>
  );
}

export default observer(ArchivesGrid);

import { styled, alpha } from '@mui/material/styles';
import MMenu, { MenuProps } from '@mui/material/Menu';
import MMenuItem, { MenuItemProps } from '@mui/material/MenuItem';
import { Stack } from '@mui/material';

export const ActionsWrapperStyled = styled(Stack)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  right: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    position: 'relative',
    top: 'unset',
    right: 'unset',
    margin: theme.spacing(0)
  }
}));

export const MenuStyled = styled((props: MenuProps) => (
  <MMenu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0'
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5)
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
      }
    }
  }
}));

// eslint-disable-next-line react/jsx-props-no-spreading
export const MenuGroupTitleStyled = styled((props: MenuItemProps) => <MMenuItem disabled {...props} />)(({ theme }) => ({
  fontWeight: 'bold',
  '&.Mui-disabled': {
    opacity: 1,
    color: theme.palette.text.secondary
  }
}));

export const MenuItemStyled = styled(MMenuItem)(({ theme }) => ({
  padding: theme.spacing(1, 2.5),
  paddingLeft: theme.spacing(4)
}));

export default {
  ActionsWrapper: ActionsWrapperStyled,
  Menu: MenuStyled,
  MenuGroupTitle: MenuGroupTitleStyled,
  MenuItem: MenuItemStyled
};

/* eslint-disable no-param-reassign */
import { flow } from 'mobx-state-tree';
import api from '~/api';
import type { IAlertDurationsTableRowModel } from './model';

export default (self: IAlertDurationsTableRowModel) => ({
  fetch: flow(function* fetch(params) {
    try {
      self.startFetching();
      const { from, to } = self;
      const { data } = yield self.sync(api.search.measurements, { from, to, ...params });
      self.measurements.reset(data);
      self.finishFetching();
    } catch (error) {
      self.failFetching(error);
    }
  })
});

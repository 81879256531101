import { Stack } from '@mui/material';
import result from 'lodash/result';
import Select from '~/components/final_form/select';
import type { AutocompleteProps } from '~/components/final_form/select/types';
import type { INode } from '~/mst/models/node';
import type { ValueLabelModelPair } from '~/mst/models/abstract/collection_types';
import StatusIcon from '~/components/status_icon';
import { ListItemStyled, ExternalIdStyled } from '~/components/organizations_select/styled';
import { ExternalIdContainerStyled } from './styled';

type Value = ValueLabelModelPair<INode>;

export const filterText = (option: Value) => `${option.label} ${option.model?.serial!}`;
export const renderOption = (option: Value) => (
  <Stack direction="row" spacing={0.5} alignItems="center">
    {option?.model && <StatusIcon statusType={result(option?.model, 'statusType')} statusText={result(option?.model, 'statusText')} size="small" />}
    <ListItemStyled>
      {option.label}
      <ExternalIdStyled className="ExternalId">{option.model?.serial!}</ExternalIdStyled>
    </ListItemStyled>
  </Stack>
);
export const renderInput = (option: Value) =>
  option?.model ? (
    <ExternalIdContainerStyled className="ExternalIdContainer">
      <ExternalIdStyled data-testid="external-id" className="ExternalId">
        {option?.model?.serial}
      </ExternalIdStyled>
    </ExternalIdContainerStyled>
  ) : null;

function DevicesSelect(props: AutocompleteProps<Value>) {
  return (
    <Select
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      searchable
      filterText={filterText}
      renderOption={renderOption}
      renderInput={renderInput}
    />
  );
}

export default DevicesSelect;

import { types as t, Instance } from 'mobx-state-tree';
import { ModelV1 as Model } from '~/mst/models/abstract/model';

const WebhookLastResponseModel = t
  .compose(
    t.model({
      body: t.optional(t.string, ''),
      status: t.maybeNull(t.number),
      timestamp: t.maybeNull(t.frozen(t.number))
    }),
    Model
  )
  .named('WebhookLastResponse');

export interface IWebhookLastResponseModel extends Instance<typeof WebhookLastResponseModel> {}

export default WebhookLastResponseModel;

/* eslint-disable no-param-reassign */
import { types as t, Instance } from 'mobx-state-tree';
import Syncable from '~/mst/models/abstract/syncable';

const CalibrationMeasurementModel = t
  .compose(
    t.model({
      serial: t.identifier,
      measurements: t.maybeNull(t.array(t.frozen())),
      calibrated: t.boolean,
      timestamp: t.number,
      name: t.string,
      metrics: t.frozen(),
      params: t.frozen(),
      reference: t.frozen()
    }),
    Syncable
  )
  .named('CalibrationMeasurement');

export interface ICalibrationMeasurementModel extends Instance<typeof CalibrationMeasurementModel> {}

export default CalibrationMeasurementModel;

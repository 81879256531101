/* eslint-disable no-param-reassign */
import { flow } from 'mobx-state-tree';
import { getStore } from '~/mst/utils';
import { IRecentAlertsWidgetModel } from './model';

export default (self: IRecentAlertsWidgetModel) => ({
  fetch: flow(function* fetch(params = {}) {
    try {
      self.startFetching();
      yield getStore(self).alerts.fetch(params);
      self.finishFetching();
    } catch (error) {
      self.failFetching(error);
    }
  })
});

import { Theme } from '@mui/material/styles';

export default function Autocomplete(theme: Theme) {
  return {
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '.MuiOutlinedInput-root': {
            flexWrap: 'wrap',
            '.MuiAutocomplete-input': {
              textOverflow: 'initial',
              padding: theme.spacing(0.9)
            },
            '.MuiAutocomplete-endAdornment': {
              '@media print': {
                display: 'none'
              }
            }
          }
        },
        paper: {
          boxShadow: theme.customShadows.z20
        }
      }
    }
  };
}

import { Instance } from 'mobx-state-tree';
import model from './model';
import views from './views';
import actions from './actions';

const Filterable = model.views(views).actions(actions);

export interface IFilterable extends Instance<typeof Filterable> {}

export default Filterable;

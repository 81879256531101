import { observer } from 'mobx-react-lite';

import RangeSelector from '~/components/range_selector';
import I18n from '~/utils/i18n';

import { IAlertMonitor } from '../store';

const options = [
  {
    value: {
      from: 'today'
    },
    label: I18n.t('datepicker.ranges.today')
  },
  {
    value: {
      from: 'yesterday',
      to: 'today'
    },
    label: I18n.t('datepicker.ranges.yesterday')
  },
  {
    value: {
      from: 'this_week'
    },
    label: I18n.t('datepicker.ranges.this_week')
  },
  {
    value: {
      from: 'last_week',
      to: 'this_week'
    },
    label: I18n.t('datepicker.ranges.last_week')
  },
  {
    value: {
      from: 'this_month'
    },
    label: I18n.t('datepicker.ranges.this_month')
  },
  {
    value: {
      from: 'last_month',
      to: 'this_month'
    },
    label: I18n.t('datepicker.ranges.last_month')
  }
];

function RangeSelect({ monitor }: { monitor: IAlertMonitor }) {
  return <RangeSelector options={options} value={monitor.range} setValue={monitor.setRange} allowCustom adaptive />;
}

export default observer(RangeSelect);

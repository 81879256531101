import { types as t, Instance } from 'mobx-state-tree';

const Filter = t.model({
  count: t.optional(t.number, 0),
  title: t.string,
  chosen: t.optional(t.boolean, false),
  disabled: t.optional(t.boolean, false),
  children: t.optional(t.array(t.late(() => Filter)), [])
});

const FilterGroup = t.model({
  title: t.string,
  filters: t.array(Filter)
});

export interface IFilter extends Instance<typeof Filter> {}
export interface IFilterGroup extends Instance<typeof FilterGroup> {}

const FilterableModel = t.model({
  filterGroups: t.array(FilterGroup),
  query: t.optional(t.string, '')
});

export interface IFilterableModel extends Instance<typeof FilterableModel> {}

export default FilterableModel;

/* eslint-disable no-nested-ternary */
import { Typography, Tooltip, styled, Stack, Box } from '@mui/material';
import PinDropIcon from '@mui/icons-material/PinDrop';
import get from 'lodash/get';
import SatelliteAltIcon from '@mui/icons-material/SatelliteAlt';
import WifiIcon from '@mui/icons-material/Wifi';
import useLocales from '~/hooks/use_locales';
import { observer } from 'mobx-react-lite';

const AddressBarContainer = styled('div')(({ theme }) => ({
  fontSize: '12px',
  fontWeight: 'bold',
  lineHeight: '30px',
  position: 'absolute',
  rowGap: theme.spacing(0.5),
  zIndex: 9,
  top: 0,
  right: 0,
  opacity: 0.75,
  width: 'auto',
  padding: theme.spacing(1, 2),
  paddingLeft: theme.spacing(1.5),
  borderBottomLeftRadius: '5px',
  backgroundColor: 'rgba(255, 255, 255, .9)',
  boxShadow: '0 2px 4px rgba(0, 0, 0, .08)'
}));

type AddressBarProps = { locationLatLng: number[]; locationDescription: string; hasWiFi: boolean; hasManualLocation: boolean };

function AddressBar({ locationLatLng, locationDescription, hasWiFi, hasManualLocation }: AddressBarProps) {
  const { t } = useLocales();
  return (
    <AddressBarContainer>
      <Stack direction="row" spacing={1}>
        <Box>
          <Tooltip
            placement="top"
            title={
              hasManualLocation
                ? t('thiamis.tooltips.location_determined_manual')
                : hasWiFi
                  ? t('thiamis.tooltips.location_determined_auto_wifi')
                  : t('thiamis.tooltips.location_determined_auto')
            }
          >
            {hasManualLocation ? <PinDropIcon size="small" /> : hasWiFi ? <WifiIcon size="small" /> : <SatelliteAltIcon size="small" />}
          </Tooltip>
        </Box>

        <Stack spacing={0.5}>
          <Typography variant="body3">{locationDescription}</Typography>
          {locationLatLng && <Typography variant="caption">{`${get(locationLatLng, '[0]')}, ${get(locationLatLng, '[1]')}`}</Typography>}
        </Stack>
      </Stack>
    </AddressBarContainer>
  );
}

export default observer(AddressBar);

import React, { useCallback } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { observer } from 'mobx-react-lite';
import Yup, { searchableSelectSchema } from '~/utils/yup';
import { CardContent, FormHelperText } from '@mui/material';
import compact from 'lodash/compact';

import useMst from '~/hooks/use_mst';
import useLocales from '~/hooks/use_locales';
import useSelection from '~/hooks/react_grid/use_selection';
import { useValidationSchema } from '~/hooks/use_validate';
import toasts from '~/utils/toasts';

import FormGrid from '~/components/@material-extend/form_grid';
import OrganizationsSelect from '~/components/organizations_select';
import { ModalActions } from '~/components/modal';
import { captureException } from '~/services/sentry';
import ModalTable from './modal_table';

type TransferModalProps = {
  toggleModal: VoidFunction;
};

const TransferNodesSchema = Yup.object().shape({
  selectedOrganization: searchableSelectSchema('Organization is required'),
  selectedBilling: searchableSelectSchema('Organization is required')
});

function TransferModal({ toggleModal }: TransferModalProps) {
  const { selection: selectedCalibrations, setSelection } = useSelection();
  const { t } = useLocales();

  const { organizations, nodes } = useMst();
  const isUpdating = selectedCalibrations.some((id) => nodes.getById(id)?.isSyncing);

  const handleCancel = useCallback(() => toggleModal(), [toggleModal]);
  const validate = useValidationSchema(TransferNodesSchema);

  const handleTransfer = useCallback(
    async (values) => {
      const { selectedOrganization, selectedBilling } = values;
      const devices = compact(selectedCalibrations.map((nodeId) => nodes.getById(nodeId)));
      try {
        await Promise.all(devices?.map((node) => node.transfer(selectedOrganization, selectedBilling)));
        setSelection([]);
        toggleModal();
        toasts.success(t('notifications.success.calibration_device_transfer', { serials: devices.map((device) => device.serial).join(', ') }));
      } catch (e) {
        captureException(e);
        toasts.error(t('notifications.errors.server_error'));
      }
    },
    [selectedCalibrations, nodes, toggleModal, t, setSelection]
  );

  return (
    <FinalForm
      initialValues={{ selectedBilling: null, selectedOrganization: null, disabledNodes: [] }}
      onSubmit={handleTransfer}
      validate={validate}
      render={({ handleSubmit, valid }) => (
        <>
          <CardContent>
            <FormGrid>
              <OrganizationsSelect name="selectedOrganization" label={t('thiamis.owner')} options={organizations.valueLabelPairs} />
              <OrganizationsSelect name="selectedBilling" label={t('thiamis.billing')} options={organizations.valueLabelPairs} />
              <ModalTable showBilling />
              <FormHelperText>{t('thiamis.manage_dialog.transfer_help_text')}</FormHelperText>
            </FormGrid>
          </CardContent>
          <ModalActions onSave={handleSubmit} saveLabel={t('base.buttons.transfer')} onClose={handleCancel} disabled={!valid} loading={isUpdating} />
        </>
      )}
    />
  );
}

export default observer(TransferModal);

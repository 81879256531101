import { EnumAsUnion } from '~/utils/enum_as_union';

export enum NodeActions {
  PaymentUpdate = 'payment_update',
  Transfer = 'transfer',
  FirmwareUpdate = 'firmware_update',
  Lease = 'lease',
  Share = 'share',
  StatusUpdate = 'status_update',
  Configure = 'configure',
  List = 'list',
  Create = 'create',
  Delete = 'delete',
  Read = 'read'
}

export type NodeActionsUnionType = EnumAsUnion<typeof NodeActions>;

export enum NodeTypes {
  Thiamis = 'thiamis',
  Alert = 'alert',
  Report = 'report',
  Template = 'template'
}

export type NodeTypesUnionType = EnumAsUnion<typeof NodeTypes>;

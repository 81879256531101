/* eslint-disable import/prefer-default-export */
import request from '../request';

export function read(id) {
  return request('get', `invitations/${id}`);
}

export function fetch(params, otpions) {
  return [request, 'get', 'invitations', params, otpions];
}

export function update(id: string, payload: any) {
  return request('put', `invitations/${id}`, payload);
}

export function create(payload: any) {
  return request('post', 'invitations', payload);
}

export function destroy(id: string) {
  return request('delete', `invitations/${id}`);
}

import { Instance, SnapshotOut } from 'mobx-state-tree';
import CollectionArrayProcessor from '~/mst/models/abstract/collection_array_processor';
import model from './model';
import actions from './actions';

const ReferenceDevicesStore = model.actions(actions);

export interface IReferenceDevicesStore extends Instance<typeof ReferenceDevicesStore> {}
export interface IReferenceDevicesStoreSnapshot extends SnapshotOut<typeof ReferenceDevicesStore> {}

export default CollectionArrayProcessor(ReferenceDevicesStore);

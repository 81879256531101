/* eslint-disable @typescript-eslint/no-use-before-define */
import { types as t, Instance } from 'mobx-state-tree';
import Syncable from '~/mst/models/abstract/syncable';
import Fetchable from '~/mst/models/abstract/fetchable';
import MetricCondition from '~/mst/models/condition/metric';
import { DataPointTypes } from '~/mst/models/data_point/base/types';
import DataPointNodeReference from '~/mst/models/data_point/node/reference';
import DataPointNodeBase from '../base';

const DataPointMetric = t
  .compose(
    DataPointNodeBase,
    Syncable,
    Fetchable(),
    t.model({
      description: t.maybeNull(t.string),
      name: t.maybeNull(t.string),
      unit: t.maybeNull(t.string),
      node_id: t.string,
      _type: t.frozen(DataPointTypes.Metric),
      conditions: t.array(MetricCondition),
      linked: t.array(DataPointNodeReference)
    })
  )
  .named('DataPointMetric');

export interface IDataPointMetricModel extends Instance<typeof DataPointMetric> {}

export default DataPointMetric;

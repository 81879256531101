/* eslint-disable no-param-reassign */
import { flow } from 'mobx-state-tree';
import api from '~/api';
import { IStatsModel } from './model';

export default (self: IStatsModel) => ({
  fetch: flow(function* fetch(params?) {
    try {
      self.startFetching();
      const { data } = yield self.sync(api.search.stats, params);
      self.stats = data;
      self.finishFetching();
    } catch (error) {
      self.failFetching(error);
    }
    return null;
  })
});

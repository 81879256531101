/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
import { LinkStyled } from './styled';

type BreadcrumsItemType = {
  title: string;
  to: string;
  active?: boolean;
};

function Breadcrumb({ title, to, active }: BreadcrumsItemType) {
  return (
    <LinkStyled to={`/${to}`} $active={!!active}>
      {title}
    </LinkStyled>
  );
}

export default Breadcrumb;

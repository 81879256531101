/* eslint-disable no-param-reassign */
import { flow, applySnapshot } from 'mobx-state-tree';
import { toMapById } from '~/mst/utils';
import { PATHS } from '~/utils/constants';
import api from '~/api';
import type { IMapWidgetModel } from './model';

export default (self: IMapWidgetModel) => ({
  fetch: flow(function* fetch(params = {}) {
    try {
      self.startFetching();
      const { data } = yield self.sync(api.search.nodes, {
        type: 'thiamis',
        includes: ['configuration', 'profiles'],
        path: [PATHS.LOCATION, PATHS.LOCATION_DESCRIPTION],
        last: 1,
        node_id: self.node_id,
        ...params
      });
      applySnapshot(self.nodes, {
        models: toMapById(data)
      });
      self.finishFetching();
    } catch (error) {
      self.failFetching(error);
    }
  })
});

import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Grid, Stack } from '@mui/material';

import Modal from '~/components/modal';
import useLocales from '~/hooks/use_locales';
import useModal from '~/hooks/use_modal';
import Filters from '~/components/filters';

import useMst from '~/hooks/use_mst';
import ButtonAdaptive from '~/components/adaptive_button';
import CreateWebhookForm from './create_webhook_form';

function FilterBar() {
  const { t } = useLocales();
  const { open, handleClose, handleOpen } = useModal();
  const { webhooks } = useMst();

  return (
    <Grid container spacing={2} sx={{ py: 2, px: 3 }}>
      <Grid item xs={12} sm={4} lg={6} md={6}>
        <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }}>
          <Filters collection={webhooks} />
        </Stack>
      </Grid>
      <Grid item xs={12} sm={8} lg={6} md={6} sx={{ textAlign: 'right' }}>
        <ButtonAdaptive variant="contained" size="large" startIcon={<AddIcon />} $mobileFullWidth breakpoint="md" onClick={handleOpen}>
          {t('webhooks.create')}
        </ButtonAdaptive>
        <Modal title={t('webhooks.create')} open={open} handleClose={handleClose}>
          <CreateWebhookForm />
        </Modal>
      </Grid>
    </Grid>
  );
}

export default FilterBar;

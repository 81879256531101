import { types as t, Instance, SnapshotOut } from 'mobx-state-tree';
import { v4 as uuid } from 'uuid';
import Syncable from '~/mst/models/abstract/syncable';
import Fetchable from '~/mst/models/abstract/fetchable';
import Layout from './layout';

export const Identifier = t.optional(t.identifier, () => uuid());

const WidgetModel = t.compose(
  t.model({
    id: Identifier,
    type: t.frozen(),
    layout: t.maybeNull(Layout)
  }),
  Syncable,
  Fetchable()
);

export interface IWidgetModel extends Instance<typeof WidgetModel> {}
export interface IWidgetModelSnapshotOut extends SnapshotOut<typeof WidgetModel> {}

export default WidgetModel;

import get from 'lodash/get';
import isObject from 'lodash/isObject';
import isBoolean from 'lodash/isBoolean';
import i18n from '~/locales/i18n';
import { NoticeMethodsEnum, CONDITION_MAX_PERIOD_MINUTES } from '~/utils/constants';

function processCondition(cnd: any) {
  const { dataPointId, func, expr, value } = cnd;
  const result = {};
  const dataPoint = isObject(dataPointId) ? processCondition(dataPointId) : dataPointId;
  if (expr) {
    result[expr] = [dataPoint, isBoolean(value) ? value : +value];
  } else if (func === 'diff') {
    const {
      dataPointId2: { dataPointId: dataPointId2 }
    } = cnd;
    result[func] = [dataPoint, isObject(dataPointId2) ? processCondition(dataPointId2) : dataPointId2];
  } else if (func === 'abs') {
    result[func] = [dataPoint];
  } else {
    result[func] = [dataPoint, isBoolean(value) ? value : +value];
    if (['min', 'max', 'avg'].includes(func) && +value >= CONDITION_MAX_PERIOD_MINUTES) {
      result[func] = result[func].concat([60]);
    }
  }
  return result;
}

function processConditions(conditions: any) {
  if (conditions.length > 1) {
    return { and: conditions.map((cnd) => processCondition(cnd)) };
  }
  return processCondition(get(conditions, '[0]', {}));
}

export function processDataPoints(dataPoints = [], dataPointsInitial = []) {
  return dataPoints.map(({ _id, conditions }: any, index) => {
    const processedConditions = processConditions(conditions);
    const initialConditions = processConditions(get(dataPointsInitial, `[${index}].conditions`, []));
    const conditionsNotChanged = JSON.stringify(processedConditions) === JSON.stringify(initialConditions);
    return {
      ...(conditionsNotChanged && { _id }),
      conditions: processedConditions
    };
  });
}

export function processSubscribers(formData: any) {
  const {
    subscribers: { users, notification_method: notificationMethod }
  } = formData;
  const subscribers = users.map((option) => get(option, 'value', option));
  if (notificationMethod === NoticeMethodsEnum.EMAIL_SMS) {
    return { [NoticeMethodsEnum.EMAIL]: subscribers, [NoticeMethodsEnum.SMS]: subscribers };
  }
  if (!Object.values(NoticeMethodsEnum).includes(notificationMethod)) {
    throw new Error(i18n.t('alerts.errors.unknown_notification', { notificationMethod }));
  }
  return { [notificationMethod]: subscribers };
}

/* eslint-disable react/jsx-props-no-spreading */
import type { IUser } from '~/mst/models/user';
import Avatar, { Props as AvatarProps } from './avatar';

type UserAvatarProps = AvatarProps & {
  user: IUser;
};
export default function UserAvatar({ user, ...other }: UserAvatarProps) {
  const { picture, fullName, initials } = user;
  return (
    <Avatar src={picture} alt={fullName} color="default" {...other}>
      {initials}
    </Avatar>
  );
}

/* eslint-disable @typescript-eslint/no-use-before-define */
import { Instance } from 'mobx-state-tree';
import model from './model';
import views from './views';

const GX6000Sensor = model.views(views);

export interface IGX6000Sensor extends Instance<typeof GX6000Sensor> {}

export default GX6000Sensor;

import { Portal } from '@mui/material';
import { Iconify } from '~/components/iconify';
import { IconButtonAnimate } from '~/components/animate';
import type { IFilterGroup } from '~/mst/models/abstract/filterable/model';

import Items from './items';
import PanelStyled, { PanelInnerStyled } from './styled';

type FiltersPickerContainerType = {
  isVisible: boolean;
  groups: IFilterGroup[];
  onHide: () => void;
  onChange: (event: any, filter: any) => void;
};

function FiltersContainer({ isVisible, groups, onHide, onChange }: FiltersPickerContainerType) {
  return (
    <Portal>
      <PanelStyled $isVisible={isVisible}>
        <PanelInnerStyled>
          <IconButtonAnimate onClick={onHide} sx={{ position: 'absolute', top: 5, right: 10, zIndex: 90 }}>
            <Iconify icon="eva:close-fill" width={20} height={20} />
          </IconButtonAnimate>
        </PanelInnerStyled>
        <Items groups={groups} onChange={onChange} />
      </PanelStyled>
    </Portal>
  );
}

export default FiltersContainer;

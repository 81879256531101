import { types as t, Instance } from 'mobx-state-tree';
import Archive from '~/mst/models/archive';
import Fetchable from '~/mst/models/abstract/fetchable';
import Filterable from '~/mst/models/abstract/filterable';
import CollectionMap from '~/mst/models/abstract/collection_map';

const ArchivesModel = t.compose(CollectionMap(Archive), Fetchable({ cache: true }), Filterable);

export interface IArchivesModel extends Instance<typeof ArchivesModel> {}

export default ArchivesModel;

/* eslint-disable @typescript-eslint/no-use-before-define */
import { Instance } from 'mobx-state-tree';
import model from './model';
import views from './views';

const GM460Sensor = model.views(views);

export interface IGM460Sensor extends Instance<typeof GM460Sensor> {}

export default GM460Sensor;

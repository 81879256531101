import React from 'react';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { I18nextProvider } from 'react-i18next';

import I18n from '~/utils/i18n';
import Router from '~/routes';
import ThemeConfig from '~/theme';
import GlobalStyles from '~/theme/global_styles';

import useCheckRelease from '~/hooks/use_check_release';
import useAnalytics from '~/hooks/use_analytics';

import { ProgressBarStyle } from '~/components/loading_screen';
import ThemeLocalization from '~/components/theme_localization';
import SnackbarProvider from '~/components/snackbar_provider';

import { AuthProvider } from '~/contexts/auth_context';
import { SubscribableProvider } from '~/contexts/subscribable_context';

type AppContainerProps = {
  children: React.ReactElement[];
};

const AppContainer = ({ children }: AppContainerProps) => {
  useCheckRelease();
  useAnalytics();

  return children;
};

export default function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={Intl.DateTimeFormat().resolvedOptions().locale}>
      <ThemeConfig variant="default">
        <SnackbarProvider>
          <I18nextProvider i18n={I18n}>
            <AuthProvider>
              <ThemeLocalization>
                <AppContainer>
                  <GlobalStyles />
                  <ProgressBarStyle />
                  <SubscribableProvider>
                    <Router />
                  </SubscribableProvider>
                </AppContainer>
              </ThemeLocalization>
            </AuthProvider>
          </I18nextProvider>
        </SnackbarProvider>
      </ThemeConfig>
    </LocalizationProvider>
  );
}

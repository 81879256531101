import { DataTypeProvider } from '@devexpress/dx-react-grid';
import NavLink from '~/components/nav_link';

export default function NameFormatter(props: DataTypeProvider.ValueFormatterProps) {
  const {
    row: { name, id }
  } = props;

  return <NavLink to={`reports/${id}/view`}>{name}</NavLink>;
}

/* eslint-disable react/require-default-props */
import { Outlet } from 'react-router-dom';
import { ContentLayoutMainStyled, ContentLayoutRootStyled } from './styled';

export default function DefaultLayout({ $noPadding = false }: { $noPadding?: boolean }) {
  return (
    <ContentLayoutRootStyled>
      <ContentLayoutMainStyled $noPadding={$noPadding}>
        <Outlet />
      </ContentLayoutMainStyled>
    </ContentLayoutRootStyled>
  );
}

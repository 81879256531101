import React, { useCallback, useState } from 'react';
import { Box } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import ButtonDesktopOnly from '~/components/adaptive_button';
import Modal from '~/components/modal';

import useExport from '~/hooks/react_grid/use_export';
import useModal from '~/hooks/use_modal';
import useLocales from '~/hooks/use_locales';

import { MenuStyled, MenuGroupTitleStyled, MenuItemStyled } from '~/components/actions_dropdown/styled';
import DownloadModals from './download_modals';
import ActionsModal from './manage_modals/actions_modal';
import TransferModal from './manage_modals/transfer_modal';
import ActionsDropdown from './actions_dropdown';
import EditIconStyled from './styled';

type ActionsMenuProps = {
  canManage: boolean;
  btnSize?: string;
  showDownloadActions?: boolean;
};

export default function ActionsMenu({ canManage, showDownloadActions, btnSize = 'medium' }: ActionsMenuProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { t } = useLocales();
  const open = Boolean(anchorEl);
  const [actionType, setActionType] = useState(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const { startExport } = useExport();
  const { open: manageModalOpen, handleClose: handleManageModalClose, handleOpen: handleManageModalOpen } = useModal();
  const { open: downloadModalOpen, handleOpen: handleDownloadModalOpen, handleClose: handleDownloadModalClose } = useModal();

  const handleDownloadTableData = useCallback(
    (event) => {
      event.stopPropagation();
      startExport();
      handleClose();
    },
    [startExport, handleClose]
  );

  const handleDownloadParametersData = useCallback(
    (event) => {
      event.stopPropagation();
      handleClose();
      handleDownloadModalOpen();
    },
    [handleDownloadModalOpen, handleClose]
  );

  const handleSetActionType = useCallback(
    (action) => {
      handleClose();
      setActionType(action);
      handleManageModalOpen();
    },
    [setActionType, handleManageModalOpen, handleClose]
  );

  const deselectActionType = useCallback(() => {
    handleClose();
    setActionType(null);
  }, [setActionType, handleClose]);

  return (
    <Box sx={{ width: '100%' }}>
      <ButtonDesktopOnly
        aria-haspopup="true"
        variant="contained"
        size={btnSize}
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        fullWidth
        breakpoint="sm"
      >
        <EditIconStyled fontSize="small" />
        {t('devices.actions.title')}
      </ButtonDesktopOnly>
      <MenuStyled
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {canManage && <ActionsDropdown handleToggleDropdown={handleClose} setActionType={handleSetActionType} />}
        {showDownloadActions && [
          <MenuGroupTitleStyled key="download-menu-1" disabled>
            {t('devices.actions.download')}
          </MenuGroupTitleStyled>,
          <MenuItemStyled key="download-menu-2" onClick={handleDownloadTableData}>
            {t('devices.actions.table_data')}
          </MenuItemStyled>,
          <MenuItemStyled key="download-menu-3" onClick={handleDownloadParametersData}>
            {t('devices.actions.parameters_data')}
          </MenuItemStyled>
        ]}
      </MenuStyled>
      {showDownloadActions && (
        <Modal open={downloadModalOpen} handleClose={handleDownloadModalClose} title={t('thiamis.download_csv.download')}>
          <DownloadModals />
        </Modal>
      )}
      {actionType && (
        <Modal open={manageModalOpen} handleClose={handleManageModalClose} title={t(`thiamis.manage.${actionType}`)}>
          {actionType === 'transfer' ? (
            <TransferModal toggleModal={deselectActionType} />
          ) : (
            <ActionsModal type={actionType} toggleModal={deselectActionType} />
          )}
        </Modal>
      )}
    </Box>
  );
}

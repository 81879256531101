/* eslint-disable no-param-reassign */
import { flow, getIdentifier, getParent, applySnapshot } from 'mobx-state-tree';
import api from '~/api';
import type { ICertificatesStore } from '~/mst/models/certificates';
import type { ICertificateModel } from './model';

export default (self: ICertificateModel) => ({
  destroy: flow(function* destroy() {
    self.startSyncing();
    try {
      yield api.nodes.destroy(getIdentifier(self)!);
      const certificates = getParent<ICertificatesStore>(self, 2);
      certificates.remove(self);
    } catch (error) {
      self.failSyncing(error);
    }
  }),
  update: flow(function* update(payload) {
    self.startSyncing();
    try {
      const { data } = yield api.nodes.update(getIdentifier(self), payload);
      applySnapshot(self, data);
      self.finishSyncing();
    } catch (error) {
      self.failSyncing(error);
    }
  })
});

import { observer } from 'mobx-react-lite';

import WidgetContainer from '~/components/widgets/common';
import { WidgetPropType } from '~/components/widgets/common/types';
import { ChartProvider } from '~/hooks/use_chart';
import type { IDevicesOnlineChartWidgetStore } from './store';
import WidgetContent from './content';

function DevicesOnlineChartWidget({ widget, moveable, dashboard, editable }: WidgetPropType<IDevicesOnlineChartWidgetStore>) {
  return (
    <ChartProvider>
      <WidgetContainer widget={widget} moveable={moveable} editable={editable} dashboard={dashboard}>
        <WidgetContent widget={widget} />
      </WidgetContainer>
    </ChartProvider>
  );
}

export default observer(DevicesOnlineChartWidget);

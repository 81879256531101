import { types as t, Instance, SnapshotOut } from 'mobx-state-tree';
import Fetchable from '~/mst/models/abstract/fetchable';

const ReferenceDeviceModel = t.compose(
  t.model({
    path: t.identifier,
    name: t.string,
    node_id: t.string,
    sensor: t.string,
    serial: t.string
  }),
  Fetchable()
);

export interface IReferenceDeviceModel extends Instance<typeof ReferenceDeviceModel> {}
export interface IReferenceDeviceModelSnapshotOut extends SnapshotOut<typeof ReferenceDeviceModel> {}

export default ReferenceDeviceModel;

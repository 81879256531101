/* eslint-disable no-nested-ternary */
import orderBy from 'lodash/orderBy';
import I18n from '~/utils/i18n';

import { getConfiguration } from '~/mst/models/device/sensor/views';
import { IAdamSensor } from './model';

export default (self: IAdamSensor) => ({
  get channelsValueLabelPairs() {
    const options = self.configuration.channel_options?.map((channel) => {
      const value = Object.keys(channel)[0];
      return { value, label: value };
    });
    return [{ label: I18n.t('base.labels.disabled'), value: null }, ...options];
  },
  get formValues() {
    const values = getConfiguration(self);
    return {
      ...values,
      channels: orderBy(Object.entries(values.channels || {}), ([key]) => key).map(([, value]) => value)
    };
  },
  getDataPointByChannelId(id) {
    return self.rawDataPoints?.find((dp) => {
      return dp.shortPath.endsWith(`${+id + 10}`);
    });
  },
  parseFormValues(values) {
    return {
      ...values,
      channels: values.channels.reduce((acc, value, index) => {
        // eslint-disable-next-line no-param-reassign
        acc[index] = value;
        return acc;
      }, {})
    };
  },
  getRangeByChannelId(id) {
    const name = self.channels[id];
    const channel = self.configuration.channel_options.find((option) => option[name]);
    if (channel) {
      return Object.values(channel)[0].split(';');
    }
    return [];
  }
});

import { styled } from '@mui/material/styles';
import { pxToRem } from '~/theme/typography';

export const DeviceNameStyled = styled('span')(() => ({
  fontSize: pxToRem(14),
  display: 'block',
  lineHeight: pxToRem(18)
}));

export const DeviceSerialStyled = styled('span')(({ theme }) => ({
  fontSize: pxToRem(12),
  display: 'block',
  fontWeight: theme.typography.fontWeightBold,
  color: theme.palette.text.disabled
}));

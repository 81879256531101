/* eslint-disable @typescript-eslint/naming-convention */
import uniq from 'lodash/uniq';
import flatten from 'lodash/flatten';
import { ICalibration } from '~/mst/models/calibration';
import { ICalibrationsModel } from './model';

export default (self: ICalibrationsModel) => ({
  get actions() {
    return uniq(flatten(self.toArray.map(({ actions }) => actions)));
  },
  get filteredList() {
    return self.filteredModels.map((calibration: ICalibration) => ({
      node_id: calibration.node_id,
      serial: calibration.serial,
      timestamp: calibration.timestamp,
      statusText: calibration.statusText,
      status: calibration.status,
      ready: calibration.ready ? 'yes' : 'no',
      parameters: calibration.parameters
    }));
  }
});

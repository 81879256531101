/* eslint-disable import/prefer-default-export */
import request from '../request';

export function fetch(params, options) {
  return [request, 'get', 'calibrations', params, options];
}

export function create(payload: any) {
  return request('post', `calibrations`, payload);
}
export function reset(payload: { serials: string[] }) {
  return request('post', `calibrations/reset`, payload);
}

export function destroy(id: string) {
  return request('delete', `calibrations/${id}`);
}

export function references() {
  return [request, 'get', 'calibrations/references'];
}

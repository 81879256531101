import { SortingState, IntegratedSorting, RowDetailState, SelectionState, IntegratedSelection } from '@devexpress/dx-react-grid';
import { Grid, VirtualTable, TableHeaderRow, TableSelection, TableRowDetail } from '@devexpress/dx-react-grid-material-ui';
import { observer } from 'mobx-react-lite';

import useSelection from '~/hooks/react_grid/use_selection';
import useLoading from '~/hooks/react_grid/use_loading';
import useSorting from '~/hooks/react_grid/use_sorting';
import useMst from '~/hooks/use_mst';
import useExpandRow from '~/hooks/react_grid/use_expand_row';

import { GridStyled } from '~/components/react_grid/styled';
import { Container } from '~/components/react_grid';

import { AlertRow } from './types';
import { LastTriggeredProvider, ActionsProvider, NameProvider, StatusTypeProvider } from './info_providers';
import GridDetailContainer from './detail_row';

function compareDates(a: number, b: number) {
  // @ts-ignore
  return (b != null) - (a != null) || b - a;
}

const columns = [
  { name: 'name', title: 'Name' },
  { name: 'description', title: 'Message' },
  { name: 'lastTriggeredTs', title: 'Last Triggered' },
  { name: 'statusType', title: 'Status' },
  { name: 'actions', title: ' ', sortingEnabled: false }
];

const getRowId = ({ _id }: AlertRow) => _id;

function GridContainer() {
  const { selection, setSelection } = useSelection();
  const { expandedRowIds, handleSetExpandedRowIds, Row } = useExpandRow();
  const { sorting, handleSortingChange } = useSorting([{ columnName: 'lastTriggeredTs', direction: 'asc' }]);

  const { alerts } = useMst();
  const { messages, RootComponent } = useLoading(alerts);

  return (
    <GridStyled>
      <Grid rows={alerts.filteredList} columns={columns} getRowId={getRowId} rootComponent={RootComponent}>
        <SortingState sorting={sorting} onSortingChange={handleSortingChange} />
        <IntegratedSorting columnExtensions={[{ columnName: 'lastTriggeredTs', compare: compareDates }]} />
        <SelectionState selection={selection} onSelectionChange={setSelection} />
        <IntegratedSelection />
        <LastTriggeredProvider />
        <ActionsProvider />
        <NameProvider />
        <StatusTypeProvider />
        <RowDetailState expandedRowIds={expandedRowIds} onExpandedRowIdsChange={handleSetExpandedRowIds} />
        <VirtualTable
          messages={messages}
          containerComponent={Container}
          height="auto"
          columnExtensions={[
            { columnName: 'actions', width: 65 },
            { columnName: 'statusType', width: 100 }
          ]}
        />
        <TableSelection showSelectAll highlightRow rowComponent={Row} />
        <TableRowDetail rowComponent={GridDetailContainer} />
        <TableHeaderRow showSortingControls />
      </Grid>
    </GridStyled>
  );
}

export default observer(GridContainer);

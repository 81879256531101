import React, { useState } from 'react';
import { styled, Stack, Typography, Tooltip, SxProps, TooltipProps, ClickAwayListener, Box } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import TripOriginIcon from '@mui/icons-material/TripOrigin';
import { transientOptions } from '~/utils/styled';
import { getScale, SizeType, StatusType } from './options';

type DeviceStatusContainerType = {
  statusType: StatusType;
  statusText: string;
  size?: SizeType; // eslint-disable-line react/require-default-props
  containerSx?: SxProps; // eslint-disable-line react/require-default-props
  sx?: SxProps; // eslint-disable-line react/require-default-props
  label?: string;
} & Pick<TooltipProps, 'placement'>;

const circleProps = ({ $scale, $status = 'disabled', theme }) => ({
  fontSize: '1rem',
  width: 'auto',
  minWidth: '1.45rem',
  height: '14px',
  lineHeight: 1,
  color: theme.palette.statuses[$status],
  transform: `scale(${$scale})`
});

const SolidCircle = styled(CircleIcon, transientOptions)(circleProps);
const CircleHalfEmpty = styled(TripOriginIcon, transientOptions)(circleProps);

function DeviceStatusContainer({ statusType, statusText, size = 'medium', containerSx, sx, label, placement = 'top' }: DeviceStatusContainerType) {
  const [open, setOpen] = useState<boolean>(false);

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const scale = getScale(size);

  return (
    <Stack direction="row" sx={containerSx}>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <Tooltip title={<span style={{ whiteSpace: 'pre-line' }}>{statusText}</span>} arrow placement={placement} open={open}>
          <Box onClick={handleTooltipOpen} onMouseEnter={handleTooltipOpen} onMouseLeave={handleTooltipClose}>
            {statusType === 'offline' ? (
              <CircleHalfEmpty $status={statusType} $scale={scale} sx={sx} />
            ) : (
              <SolidCircle $status={statusType} $scale={scale} sx={sx} />
            )}
          </Box>
        </Tooltip>
      </ClickAwayListener>
      {label && <Typography variant="body3">{label}</Typography>}
    </Stack>
  );
}

export default DeviceStatusContainer;

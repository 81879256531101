import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form as FinalForm } from 'react-final-form';
import { useLocation } from 'react-router-dom';
import { Container, CardContent, Card, Grid } from '@mui/material';
import Yup, { searchableSelectSchema } from '~/utils/yup';
import { observer } from 'mobx-react-lite';
import { useValidationSchema } from '~/hooks/use_validate';
import Breadcrumbs from '~/components/breadcrumbs';
import useMst from '~/hooks/use_mst';
import toasts from '~/utils/toasts';

import { captureException } from '~/services/sentry';
import Results from './results';
import ClaimDeviceForm from './claim_form';

function ClaimDeviceScreen() {
  const { t } = useTranslation();
  const { nodes, organizations } = useMst();
  const { isSyncing } = nodes;
  const [result, setResult] = useState<any[]>([]);
  const { search } = useLocation();
  const serialsInitial = useMemo(() => new URLSearchParams(search).get('serials'), [search]);
  const organizationInitial = useMemo(() => new URLSearchParams(search).get('organization'), [search]);

  const initialData = useMemo(
    () => ({
      serials: serialsInitial,
      organization: organizationInitial
    }),
    [serialsInitial, organizationInitial]
  );

  const DeviceClaimSchema = Yup.object().shape({
    serials: Yup.string().required('Serials are required'),
    organization: searchableSelectSchema('Organization is required')
  });

  const validate = useValidationSchema(DeviceClaimSchema);

  const submit = useCallback(
    async (values) => {
      try {
        const { organization, serials } = values;
        const data = await nodes.claim({ serials: serials.trim(), organization });
        const saved = Object.keys(data).map((serial) => ({
          serial,
          status: data[serial]
        }));
        const responseLength = Object.values(data).length;
        const statusesFailed = Object.values(data).filter((item) => !item);
        if (!statusesFailed.length) {
          toasts.success(t('claim.success_claim'));
        } else if (statusesFailed.length === responseLength) {
          toasts.error(t('claim.error_claim'));
        } else {
          toasts.warning(t('claim.warning_claim'));
        }
        setResult(saved);
      } catch (e) {
        captureException(e);
        toasts.error(t('notifications.errors.server_error'));
      }
    },
    [nodes, t]
  );

  const links = useMemo(
    () => [
      { title: t('devices_page.title'), to: 'devices' },
      { title: t('devices_page.claim'), to: 'devices/claim' }
    ],
    [t]
  );

  return (
    <Container maxWidth="lg">
      <Breadcrumbs links={links} />
      <Card>
        <CardContent>
          <FinalForm onSubmit={submit} initialValues={initialData} validate={validate}>
            {({ handleSubmit }) => (
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <ClaimDeviceForm handleSubmit={handleSubmit} submitting={isSyncing} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Results results={result} organizations={organizations.valueLabelPairsManagerAccess} />
                </Grid>
              </Grid>
            )}
          </FinalForm>
        </CardContent>
      </Card>
    </Container>
  );
}

export default observer(ClaimDeviceScreen);

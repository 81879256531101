/* eslint-disable react/no-unstable-nested-components */
import { observer } from 'mobx-react-lite';
import WidgetContainer from '~/components/widgets/common';
import { WidgetPropType } from '~/components/widgets/common/types';
import { IRecentAlertsWidget } from './store';
import RecentAlertsWidgetContent from './content';

function RecentAlertsWidget({ widget, moveable, dashboard, editable }: WidgetPropType<IRecentAlertsWidget>) {
  return (
    <WidgetContainer
      widget={widget}
      moveable={moveable}
      editable={editable}
      dashboard={dashboard}
      sx={{
        '.PageSizeSelector-pageSizeSelector': { display: 'none' },
        '.MuiCardContent-root:last-child': { p: 0, pt: 0.5, pb: 2 }
      }}
    >
      <RecentAlertsWidgetContent widget={widget} dashboard={dashboard} />
    </WidgetContainer>
  );
}

export default observer(RecentAlertsWidget);

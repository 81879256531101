/* eslint-disable react/no-unstable-nested-components */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Table, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { useFormState } from 'react-final-form';
import { observer } from 'mobx-react-lite';
import includes from 'lodash/includes';

import { GridRowProps } from '~/components/react_grid/types';
import useMst from '~/hooks/use_mst';
import useSelection from '~/hooks/react_grid/use_selection';
import { INode } from '~/mst/models/node';

type ModalTableProps = {
  showBilling: boolean;
};

function ModalTable({ showBilling = false }: ModalTableProps) {
  const {
    values: { disabledNodes }
  } = useFormState({ subscription: { values: true } });
  const { selection } = useSelection();
  const { nodes, organizations } = useMst();
  const { t } = useTranslation();

  const devices = selection.map((id) => {
    const node = nodes.getById(id);
    return {
      displayName: node?.presentName,
      organization: node?.owner?.toJSON(),
      owner: node?.owner?.name,
      billing: organizations.getById(node?.payment)?.toJSON(),
      biller: organizations.getById(node?.payment)?.name
    };
  });

  const Row = React.useMemo(
    () =>
      React.memo((props: GridRowProps<INode>) => {
        const { row } = props;
        // eslint-disable-next-line react/jsx-props-no-spreading
        return <Table.Row {...props} disabled={includes(disabledNodes, row._id)} />;
      }),
    [disabledNodes]
  );

  // eslint-disable-next-line react/jsx-props-no-spreading
  const Root = React.memo((props) => <Grid.Root {...props} style={{ maxHeight: 270 }} />);

  const columns = useMemo(() => {
    const columnList = [
      { name: 'displayName', title: t('attributes.node.name') },
      { name: 'owner', title: t('thiamis.owner') }
    ];
    if (showBilling) {
      columnList.push({
        name: 'biller',
        title: t('thiamis.billing')
      });
    }
    return columnList;
  }, [t, showBilling]);

  return (
    <Grid rows={devices} columns={columns} rootComponent={Root}>
      <Table rowComponent={Row} />
      <TableHeaderRow showSortingControls={false} />
    </Grid>
  );
}

export default observer(ModalTable);

import { Table } from '@devexpress/dx-react-grid-material-ui';
import { styled } from '@mui/material/styles';
import { transientOptions } from '~/utils/styled';

const TableRowStyled = styled(
  Table.Row,
  transientOptions
)(({ $disabled }) => ({
  ...($disabled && {
    textDecoration: 'line-through',
    opacity: 0.6
  })
}));

export default TableRowStyled;

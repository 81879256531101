/* eslint-disable no-prototype-builtins */
import { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Stack, Box } from '@mui/material';
import { getSnapshot } from 'mobx-state-tree';
import { LoadingButton } from '@mui/lab';
import { DateTime } from 'luxon';

import humanizer from '~/utils/humanize_duration';
import Slider from '~/components/final_form/slider';
import { IDustTrakSensor } from '~/mst/models/device/sensor/dust_trak';
import { ItemTitle } from '~/pages/device_edit/components';
import { ItemValueStyled } from '~/pages/device_edit/components/styled';
import useLocales from '~/hooks/use_locales';
import toasts from '~/utils/toasts';

type SensorType = {
  sensor: IDustTrakSensor;
};

function AutoZero({ sensor }: SensorType) {
  const { t } = useLocales();
  const [isAutoZeroSyncing, setIsAutoZeroSyncing] = useState(false);

  const handleChangeAutoZero = useCallback(async () => {
    try {
      setIsAutoZeroSyncing(true);
      await sensor.update({ ...getSnapshot(sensor), auto_zero_time: DateTime.now().toUTC().plus({ minutes: 5 }).toMillis() });
      toasts.success(t(`notifications.success.forms.update_sensor`));
      setIsAutoZeroSyncing(false);
    } catch (e) {}
  }, [sensor, t]);

  const isDisabled = sensor.auto_zero_time >= DateTime.now().toUTC().minus({ minutes: 5 }).toMillis();
  return (
    <Stack direction="row" justifyContent="flex-start" useFlexGap flexWrap="wrap">
      <Stack direction="row" justifyContent="space-between" alignItems="flex-end" sx={{ width: '100%' }}>
        <ItemTitle>{t('sensor.configuration.auto_zero')}</ItemTitle>
        <ItemValueStyled>
          <LoadingButton size="small" variant="outlined" onClick={handleChangeAutoZero} loading={isAutoZeroSyncing} disabled={isDisabled}>
            Run
          </LoadingButton>
        </ItemValueStyled>
      </Stack>
      <Box sx={{ width: '100%', mb: 1 }}>
        <Slider
          name="auto_zero"
          data-testid="autoZeroSlider"
          slider={{
            step: 15 * 60 * 1000,
            min: 0,
            max: 24 * 60 * 60 * 1000,
            valueLabelDisplay: 'on',
            valueLabelFormat(value) {
              return value > 0 ? humanizer.humanizeDuration(value, { units: ['h', 'm'] }) : 'Off';
            }
          }}
        />
      </Box>
    </Stack>
  );
}

export default observer(AutoZero);

import { useCallback, useState, useMemo } from 'react';
import { Stack, Chip, Tooltip, Badge } from '@mui/material';
import { observer } from 'mobx-react-lite';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { getIdentifier } from 'mobx-state-tree';
import isEmpty from 'lodash/isEmpty';
import { BodyModule } from '~/pages/device_edit/components';
import useModal from '~/hooks/use_modal';
import AddStatisticalParamModal from '~/components/add_statistical_param_modal';
import type { ISensor } from '~/mst/models/device/sensor';
import type { IDataPointMetric } from '~/mst/models/data_point/node/metric';

const PARAMETERS_COUNT = 5;

const SensorMetricParameter = observer(({ dp }: { dp: IDataPointMetric }) => {
  return (
    <Tooltip title={dp.conditions[0]?.presentName} placement="top" arrow>
      <Chip color="info" label={dp.presentName} variant="outlined" onDelete={() => dp.destroy()} disabled={dp.isSyncing} />
    </Tooltip>
  );
});

function SensorParameters({ sensor }: { sensor: ISensor }) {
  const { open, handleClose: handleModalClose, handleOpen: handleModalOpen } = useModal();
  const [showMore, setShowMore] = useState(false);
  const handleAddStatsParameter = useCallback(
    (event) => {
      event.stopPropagation();
      handleModalOpen();
    },
    [handleModalOpen]
  );

  const parameters = useMemo(() => {
    if (showMore) {
      return sensor?.defaultAndMetricDataPoints;
    }
    return sensor?.defaultAndMetricDataPoints.slice(0, PARAMETERS_COUNT);
  }, [sensor?.defaultAndMetricDataPoints, showMore]);

  if (isEmpty(sensor?.defaultAndMetricDataPoints)) {
    return null;
  }

  return (
    <BodyModule title="Parameters" columns={1} expandable>
      <Stack direction="row" spacing={0} flexWrap="wrap" gap={1}>
        <Chip color="info" icon={<AddCircleOutlineIcon />} label="Create" onClick={handleAddStatsParameter} />
        {parameters.map((dp) =>
          dp.isMetric ? (
            <SensorMetricParameter key={getIdentifier(dp)} dp={dp} />
          ) : (
            <Chip key={getIdentifier(dp)} color="info" label={dp.presentName} variant="outlined" />
          )
        )}
        {sensor.defaultAndMetricDataPoints.length > PARAMETERS_COUNT && (
          <Badge
            badgeContent={sensor.defaultAndMetricDataPoints.length - PARAMETERS_COUNT}
            invisible={showMore || sensor.defaultAndMetricDataPoints <= PARAMETERS_COUNT}
            color="info"
          >
            <Chip color="info" label="..." onClick={() => setShowMore(!showMore)} variant="outlined" />
          </Badge>
        )}
      </Stack>
      <AddStatisticalParamModal open={open} onClose={handleModalClose} node={sensor?.node} deviceId={getIdentifier(sensor)} />
    </BodyModule>
  );
}

export default observer(SensorParameters);

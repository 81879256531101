/* eslint-disable no-param-reassign */
import { flow, applySnapshot } from 'mobx-state-tree';
import api from '~/api';
import { toMapById } from '~/mst/utils';
import type { INodeWidgetModel } from './model';

export default (self: INodeWidgetModel) => ({
  fetch: flow(function* fetch(params = {}) {
    try {
      self.startFetching();
      const payload = { node_id: self.node_id, last: 20, includes: ['profiles', 'configuration'], ...params };
      const { data } = yield self.sync(api.search.nodes, payload);
      applySnapshot(self.nodes, {
        models: toMapById(data)
      });
      self.finishFetching();
    } catch (error) {
      self.failFetching(error);
    }
  }),
  setFilter(value) {
    self.filter = value;
  }
});

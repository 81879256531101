import { Instance } from 'mobx-state-tree';
import CollectionMapProcessor from '~/mst/models/abstract/collection_map_processor';
import model from './model';
import actions from './actions';
import views from './views';

const InvitationsStore = model.views(views).actions(actions);

export interface IInvitationsStore extends Instance<typeof InvitationsStore> {}

export default CollectionMapProcessor(InvitationsStore);

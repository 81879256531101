import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, CardContent } from '@mui/material';

import FormGrid from '~/components/@material-extend/form_grid';
import { ModalActions } from '~/components/modal';
import OrganizationSelect from '~/components/organizations_select';
import useMst from '~/hooks/use_mst';
import useLocales from '~/hooks/use_locales';
import { ValueLabelModelPair } from '~/mst/models/abstract/collection_types';
import { IOrganizationModel } from '~/mst/models/organization/model';
import { IDashboard } from '~/mst/models/dashboard';

export type ShareModalProps = {
  dashboard: IDashboard;
  invalid: boolean;
  handleModalClose: () => void;
  handleSubmit: () => void;
};

function ShareModal({ invalid, handleSubmit, handleModalClose, dashboard }: ShareModalProps) {
  const { organizations } = useMst();
  const { t } = useLocales();

  const shareOptions: ValueLabelModelPair<IOrganizationModel>[] = useMemo(
    () => organizations.valueLabelPairsCanManageReports.filter((item) => item.value !== dashboard?.organization_id),
    [organizations, dashboard?.organization_id]
  );

  return (
    <Box>
      <CardContent>
        <FormGrid>
          <OrganizationSelect label={t('attributes.dashboard.organization')} name="organization" options={shareOptions} />
        </FormGrid>
        <ModalActions
          disabled={invalid}
          loading={dashboard?.isSyncing}
          onClose={handleModalClose}
          onSave={handleSubmit}
          saveLabel={t('base.buttons.share')}
        />
      </CardContent>
    </Box>
  );
}

export default observer(ShareModal);

import Highcharts from 'highcharts';
import merge from 'lodash/merge';
import { palettes } from '~/components/chart/chart_palettes';

export default ({ theme, data, widget, categories, alert }) =>
  merge(
    {
      chart: {
        zooming: {
          mouseWheel: {
            enabled: false
          }
        },
        type: 'xrange',
        reflow: true,
        zoomType: 'x',
        height: `${150 + categories.length * 75}px`,
        events: {
          selection(event) {
            if (event.xAxis) {
              const { min, max } = event.xAxis[0];
              widget.setZoomLevel({ min, max });
            } else {
              widget.setZoomLevel(null);
            }
          }
        }
      },
      credits: {
        enabled: false
      },
      legend: {
        enabled: true
      },
      title: {
        text: ''
      },
      xAxis: {
        type: 'datetime'
      },
      colors: ['#E43D30'],
      yAxis: {
        title: {
          text: ''
        },
        labels: {
          enabled: categories.length > 1,
          formatter({ value: dataPointId }) {
            return alert?.data_points?.getById(dataPointId)?.shortPresentName;
          }
        },
        categories,
        reversed: true
      },
      tooltip: {
        formatter() {
          const {
            series,
            point: {
              options: { dataPointId },
              x,
              x2,
              color
            }
          } = this;
          const yCategory = alert?.data_points?.getById(dataPointId).presentName;
          const firstDate = Highcharts.dateFormat('%A, %b %e, %H:%M', x);
          const secondDate = Highcharts.dateFormat('%A, %b %e, %H:%M', x2);
          const header = `<span style="font-size: 10px">${firstDate} - ${secondDate}</span><br/>`;
          const content = `<span style="color:${color}">\u25CF</span> ${series.name}: <b>${yCategory}</b><br/>`;

          return header + content;
        }
      },
      series: [
        {
          name: alert?.name,
          colorByPoint: false,
          pointWidth: 30,
          minPointLength: 3,
          dataLabels: {
            enabled: false
          },
          style: {
            fontSize: '14px',
            fontFamily: 'Roboto, sans-serif',
            'font-weight': 'bold',
            textOutline: 'none'
          },
          data
        }
      ],
      exporting: {
        enabled: false,
        chartOptions: {
          title: {
            text: alert?.name
          }
        }
      }
    },
    { ...palettes[theme] }
  );

import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Grid, Typography, styled } from '@mui/material';
import type { IDataPointNode } from '~/mst/models/data_point/node/default';

const TypographyName = styled(Typography)(({ theme }) => ({
  display: 'block',
  fontWeight: 'bold',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  minWidth: theme.spacing(8)
}));

const TypographyValue = styled(Typography)(({ theme }) => ({
  display: 'block',
  fontWeight: 'bold',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  minWidth: theme.spacing(8)
}));

function SensorDataPoint({ datapoint }: { datapoint: IDataPointNode }) {
  const theme = useTheme();

  const { name, unit } = datapoint;
  const value = datapoint.lastValue;

  return (
    <Grid item xs={4}>
      <TypographyName variant="body3" color={theme.palette.common.black}>
        {name}
      </TypographyName>
      <TypographyValue variant="body3" color={theme.palette.grey[900]}>
        {value ? `${value} ${unit}` : 'N/A'}
      </TypographyValue>
    </Grid>
  );
}

export default SensorDataPoint;

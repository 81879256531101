import { setIn } from 'final-form';
import { useMemo } from 'react';
import { AnyObjectSchema, ValidationError } from 'yup';

/**
 * Sets the `innerError.message` in an `errors` object at the key
 * defined by `innerError.path`.
 */
const setInError = (errors: Record<string, string>, innerError: ValidationError) => {
  return setIn(errors, innerError.path ?? '', innerError.message);
};

/**
 * Empty object map with no prototype. Used as default
 * value for reducing the `err.inner` array of errors
 * from a `yup~ValidationError`.
 */
const emptyObj: {} = Object.create(null);

/**
 * Takes a `yup` validation schema and returns a function that expects
 * a map of values to validate. If the validation passes, the function resolves to `undefined`
 * (signalling that the values are valid). If the validation doesn't pass, it resolves
 * to a map of invalid field names to errors.
 */

export function makeValidate<TValue = Record<string, unknown>>(
  schema: AnyObjectSchema
): (val: TValue) => Promise<undefined | Record<string, string>> {
  // eslint-disable-next-line consistent-return
  return async function validate(values: TValue) {
    try {
      await schema.validate(values, { abortEarly: false });
    } catch (err) {
      if (err instanceof ValidationError) {
        return err.inner.reduce(setInError, emptyObj);
      }
    }
  };
}

export function useValidationSchema(schema: AnyObjectSchema) {
  const validate = useMemo(() => makeValidate(schema), [schema]);
  return validate;
}

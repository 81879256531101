import { observer } from 'mobx-react-lite';
import { IHeatmapWidget } from '~/mst/models/widget/heatmap_widget';
import HeatmapItem from './heatmap_item';
import { HeatmapContainerStyled } from './styled';

function HeatmapWidgetContent({ widget }: { widget: IHeatmapWidget }) {
  return (
    <HeatmapContainerStyled spacing={0} direction="row">
      {widget.data.map((node) => (
        <HeatmapItem
          key={`${widget?.id}_${node?._id}`}
          dataPoint={node?.data_points?.toArray.find((dp) => dp.presentName === widget?.selectedPath)}
        />
      ))}
    </HeatmapContainerStyled>
  );
}

export default observer(HeatmapWidgetContent);

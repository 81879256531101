import PropTypes, { InferProps } from 'prop-types';
import { ThemeProvider, createTheme, useTheme } from '@mui/material/styles';
import useLocales from '~/hooks/use_locales';

const ThemeLocalizationProps = {
  children: PropTypes.node.isRequired
};

type ThemeLocalizationPropsType = InferProps<typeof ThemeLocalizationProps>;

export default function ThemeLocalization({ children }: ThemeLocalizationPropsType) {
  const defaultTheme = useTheme();
  const { i18n } = useLocales();

  const theme = createTheme(defaultTheme, i18n.language);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

ThemeLocalization.propTypes = ThemeLocalizationProps;

import { types as t, Instance } from 'mobx-state-tree';
import Fetchable from '~/mst/models/abstract/fetchable';
import Measurements from './measurements';

const AlertDurationsTableRowModel = t
  .compose(
    t.model({
      duration: t.number,
      from: t.number,
      to: t.number,
      data_point_id: t.string,
      name: t.maybeNull(t.string),
      alert_id: t.string,
      measurements: t.optional(Measurements, [])
    }),
    Fetchable()
  )
  .named('AlertDurationsTableRowModel');

export interface IAlertDurationsTableRowModel extends Instance<typeof AlertDurationsTableRowModel> {}

export default AlertDurationsTableRowModel;

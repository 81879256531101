/* eslint-disable no-prototype-builtins */
import { observer } from 'mobx-react-lite';

import Switch from '~/components/final_form/switch';
import { BodyModule } from '~/pages/device_edit/components';

import useLocales from '~/hooks/use_locales';
import { SensorProperty } from '../components';
import SwitchFields from '../sensor/common/switch_fields';

function Davis() {
  const { t } = useLocales();

  return (
    <BodyModule title={t('device_monitor.forms.settings')} columns={2} expandable expanded>
      <SwitchFields>
        <SensorProperty name="us_units">
          <Switch label={t('sensor.configuration.us_units')} name="us_units" type="number" />
        </SensorProperty>
      </SwitchFields>
    </BodyModule>
  );
}

export default observer(Davis);

import { memo } from 'react';

const StatusIcon = memo(({ statusType, label }: { statusType: string; label: string }) => {
  return (
    <span className="widget-map_status">
      <div className="widget-map_status__wrapper">
        {statusType === 'offline' ? (
          <div className={`widget-map_status__half_icon--${statusType}`} />
        ) : (
          <div className={`widget-map_status__solid_icon--${statusType}`} />
        )}
        {label && <div>{label}</div>}
      </div>
    </span>
  );
});

export default StatusIcon;

import flatten from 'lodash/flatten';
import compact from 'lodash/compact';
import includes from 'lodash/includes';
import { observer } from 'mobx-react-lite';
import { Box } from '@mui/material';

import useSelection from '~/hooks/react_grid/use_selection';
import useLocales from '~/hooks/use_locales';
import useMst from '~/hooks/use_mst';

import OrganizationSelect from '~/components/organizations_select';

type ModalOrganizationDropdownProps = {
  type: string;
};

function ModalOrganizationDropdown({ type }: ModalOrganizationDropdownProps) {
  const { selection } = useSelection();
  const { t } = useLocales();
  const { nodes, organizations } = useMst();

  const shared = compact(flatten(selection.map((id) => nodes.getById(id)?.shared_to)));
  const leased = compact(flatten(selection.map((id) => nodes.getById(id)?.leased_to)));
  const organizationsAll = organizations.valueLabelPairs;

  let organizationOptions = [];
  switch (type) {
    case 'share':
      organizationOptions = organizationsAll;
      break;
    case 'remove_share':
      organizationOptions = organizationsAll.filter(({ value }) => includes(shared, value));
      break;
    case 'lease':
      organizationOptions = organizationsAll;
      break;
    case 'remove_lease':
      organizationOptions = organizationsAll.filter(({ value }) => includes(leased, value));
      break;
    default:
      break;
  }

  return (
    <Box mb={2}>
      <OrganizationSelect name="selectedOrganization" label={t('models.organization')} options={organizationOptions} />
    </Box>
  );
}

export default observer(ModalOrganizationDropdown);

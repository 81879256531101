import { useEffect, useState, useMemo, forwardRef } from 'react';
import { styled, Box } from '@mui/material';
import Popover, { PopoverProps } from '@mui/material/Popover';
import CloseIcon from '@mui/icons-material/Close';

import NodeWidget from '~/mst/models/widget/node_widget';

import useFetch from '~/hooks/use_fetch';
import NodeWidgetComponent from '../../node_widget/with_device_status';

export const CloseIconStyled = styled(CloseIcon)(() => ({
  fontSize: 16,
  color: 'grey',
  cursor: 'pointer'
}));

export const PopoverWrapperStyled = styled(Box)(() => ({
  overflowX: 'unset',
  overflowY: 'unset',
  width: '360px'
}));

type TWidget = {
  nodeId: string;
  onHideWidget: VoidFunction;
};

function Widget(props: TWidget, ref) {
  const { nodeId, onHideWidget } = props;
  const widget = useMemo(() => NodeWidget.create({ node_id: [nodeId] }), [nodeId]);

  useFetch(widget);

  const [anchorEl, setAnchorEl] = useState<PopoverProps['anchorEl']>(null);

  useEffect(() => {
    const getBoundingClientRect = () => {
      // eslint-disable-next-line react/destructuring-assignment
      return ref.getBoundingClientRect();
    };

    setAnchorEl({ getBoundingClientRect, nodeType: 1 });
  }, [ref, nodeId]);

  if (!anchorEl) {
    return null;
  }

  return (
    <Popover
      open={!!nodeId}
      onClose={onHideWidget}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      sx={{
        mt: '85px',
        ml: '15px'
      }}
      slotProps={{
        paper: {
          style: {
            background: 'transparent'
          }
        }
      }}
    >
      <PopoverWrapperStyled>
        <NodeWidgetComponent widget={widget} onClose={onHideWidget} height={250} />
      </PopoverWrapperStyled>
    </Popover>
  );
}

export default forwardRef(Widget);

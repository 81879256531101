import { datadogRum } from '@datadog/browser-rum';
import { getReleaseName, getEnv } from '~/utils/helpers';
import type { IUser } from '~/mst/models/user';
import flow from 'lodash/fp/flow';
import map from 'lodash/fp/map';
import uniq from 'lodash/fp/uniq';
import flatten from 'lodash/flatten';

const { CI, VITE_DATADOG_CLIENT_TOKEN, VITE_DATADOG_APPLICATION_ID } = process.env;

(() => {
  if (CI) {
    datadogRum.init({
      applicationId: VITE_DATADOG_APPLICATION_ID,
      clientToken: VITE_DATADOG_CLIENT_TOKEN,
      version: getReleaseName(),
      service: `next.${getEnv()}.io`,
      sampleRate: 100,
      trackInteractions: true,
      trackUserInteractions: true,
      defaultPrivacyLevel: 'allow',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      allowedTracingOrigins: [/https:\/\/api\.(?:envdev|environet)\.io\/v2/]
    });
    datadogRum.startSessionReplayRecording();
  }
})();

// eslint-disable-next-line import/prefer-default-export
export function datadogIdentity(user: IUser) {
  datadogRum.setUser({
    id: user.external_id,
    email: user.email,
    organizations: flow(
      map(([, value]) => value),
      flatten,
      uniq
    )(Object.entries(user.permissions))
  });
}

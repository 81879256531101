/* eslint-disable no-prototype-builtins */
import { observer } from 'mobx-react-lite';
import Select from '~/components/final_form/select';
import { UNITS } from '~/mst/models/device/sensor/particles_plus/config';
import { BodyModule } from '~/pages/device_edit/components';
import useLocales from '~/hooks/use_locales';
import SwitchFields from '~/pages/device_edit/containers/sensor_form_container/sensors/sensor/common/switch_fields';

import { SensorProperty } from '../components';

const unitsOptions = Object.entries(UNITS).map(([value, label]) => ({ value, label }));

function ParticlesPlus() {
  const { t } = useLocales();

  return (
    <BodyModule title={t('device_monitor.forms.settings')} columns={1} expandable expanded>
      <SwitchFields />
      <SensorProperty name="units">
        <Select name="units" label={t('sensor.configuration.units')} options={unitsOptions} />
      </SensorProperty>
    </BodyModule>
  );
}

export default observer(ParticlesPlus);

import isObject from 'lodash/isObject';

export function getClusternetWSUrl() {
  return `wss://${process.env.VITE_API_URL_ROOT?.split('://')[1]}socket`;
}

export type ErrorData = {
  name: string;
  message: string;
  status?: number;
};

export type AtLeastOne<T, U = { [K in keyof T]: Pick<T, K> }> = Partial<T> & U[keyof U];

export const toError = (error: any): ErrorData => {
  if (error instanceof Error) {
    return {
      name: error.name,
      message: error.message
    };
  }
  return {
    name: 'Error',
    message: error?.toString() || 'An unknown error occurred'
  };
};

export function getReleaseName() {
  if (process.env.GITHUB_REF_TYPE === 'tag') {
    return process.env.GITHUB_REF_NAME;
  }
  return process.env.GITHUB_SHA;
}

export function getEnv() {
  return process.env.MODE === 'production' ? 'environet' : 'envdev';
}

export const rgbToHex = (r, g, b) =>
  `#${[r, g, b]
    .map((x) => {
      const hex = x.toString(16);
      return hex.length === 1 ? `0${hex}` : hex;
    })
    .join('')}`;

export const hexToRgb = (hex) =>
  hex
    .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => `#${r}${r}${g}${g}${b}${b}`)
    .substring(1)
    .match(/.{2}/g)
    .map((x) => parseInt(x, 16));

export function parsePath(path) {
  const [serial, deviceId, pathId] = path?.split(':') || [];
  return { serial, deviceId, pathId };
}

export function getShortPath(path) {
  const { deviceId, pathId } = parsePath(path);
  return `${deviceId}:${pathId}`;
}

export function getDifference(a, b) {
  return Object.assign(
    ...Array.from(new Set([...Object.keys(a), ...Object.keys(b)]), (k) => ({
      [k]: isObject(a[k]) && isObject(b[k]) ? getDifference(a[k], b[k]) : `${a[k]} -> ${b[k]}`
    }))
  );
}

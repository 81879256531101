/* eslint-disable import/prefer-default-export */
import { EnumAsUnion } from '~/utils/enum_as_union';

export enum PERMISSIONS {
  ManageDevices = 'manage_devices',
  AdminDevices = 'admin_devices',
  ManageOrganizations = 'manage_organizations',
  ManageReports = 'manage_reports',
  ManageTokens = 'manage_tokens',
  ManageUsers = 'manage_users',
  ReadDevices = 'read_devices',
  ReadOrganizations = 'read_organizations',
  CreateOrganization = 'create_organization',
  ReadReports = 'read_reports',
  ReadUsers = 'read_users',
  AccessContext = 'access_context'
}

export type PermissionsUnionType = EnumAsUnion<typeof PERMISSIONS>;
export type PermissionsRecordType = Record<PermissionsUnionType, string[]>;

/* eslint-disable @typescript-eslint/no-use-before-define */
import { types as t, Instance } from 'mobx-state-tree';
import isArray from 'lodash/isArray';
import pick from 'lodash/pick';
import ConditionAlert from '~/mst/models/condition/alert';
import DataPointBase from '../base';

export const DataPointAlertModel = t
  .compose(
    DataPointBase,
    t
      .model({
        _type: 'alert',
        conditions: t.array(ConditionAlert),
        linked: t.frozen(t.maybeNull(t.array(t.string))),
        description: t.frozen(t.optional(t.string, ''))
      })
      .views((self) => ({
        get presentName() {
          return self.conditions.map((condition) => condition.presentName).join(' AND ');
        },
        get shortPresentName() {
          return self.conditions.map((condition) => condition.presentName).join(' AND ');
        }
      }))
  )
  .named('DataPointAlert');

export interface IDataPointAlert extends Instance<typeof DataPointAlertModel> {}

export default t.snapshotProcessor(DataPointAlertModel, {
  preProcessor(sn: any) {
    let { conditions } = sn;
    if (isArray(conditions.and)) conditions = conditions.and.map((cnd: any) => cnd);
    if (!isArray(conditions)) conditions = [conditions];
    const result = {
      ...sn,
      conditions
    };
    return result;
  },
  postProcessor(sn) {
    if (sn._id?.startsWith('new') === false) {
      return pick(sn, ['_id', 'conditions']);
    }
    return pick(sn, ['conditions']);
  }
});

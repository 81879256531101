import { NavLink as RouterNavLink, RouteProps } from 'react-router-dom';
import { Link } from '@mui/material';
import useSlug from '~/hooks/use_slug';

type NavLinkProps = {
  to: string;
  children: RouteProps['children'];
};

export default function NavLink(props: NavLinkProps) {
  const { slug } = useSlug();
  const { to } = props;
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Link component={RouterNavLink} {...props} to={`/${slug}/${to}`} />;
}

/* eslint-disable no-prototype-builtins */
import { FormLabelStyled } from '~/pages/device_edit/components/styled';

import Slider from '~/components/final_form/slider';
import useLocales from '~/hooks/use_locales';

function FlowCalibration() {
  const { t } = useLocales();

  return (
    <Slider
      label={<FormLabelStyled>{t('sensor.configuration.size_correction')}</FormLabelStyled>}
      name="size_correction"
      showInput
      data-testid="sizeCorrectionSlider"
      slider={{
        min: 0.1,
        max: 10,
        step: 0.001
      }}
    />
  );
}

export default FlowCalibration;

/* eslint-disable @typescript-eslint/naming-convention */
import { Instance, types as t, getParent } from 'mobx-state-tree';
import includes from 'lodash/includes';
import { DateTime } from 'luxon';
import last from 'lodash/last';
import get from 'lodash/get';
import camelCase from 'lodash/camelCase';
import every from 'lodash/every';
import isNil from 'lodash/isNil';
import sortBy from 'lodash/sortBy';

import { getOnlineStatusFromAQ } from '~/mst/models/device/utils';
import { TEMPERATURE_UNITS, PATHS, THIAMIS_BATTERY_MIN_REPORTING_INTERVAL, DEVICE_STATUSES } from '~/utils/constants';
import type { INode } from '~/mst/models/node';
import { parsePath, getShortPath } from '~/utils/helpers';
import I18n from '~/utils/i18n';

import { DataPointTypes } from '~/mst/models/data_point/base/types';
import DataPointBase from '../base';

const isGte = (value, gte) => value >= gte;
const isLte = (value, lte) => value <= lte;
const isGt = (value, gt) => value > gt;
const isLt = (value, lt) => value < lt;

const isValueInRange = (value, range) => {
  const { gte, lte, gt, lt } = range;
  const exprs: boolean[] = [];
  if (!isNil(gte)) exprs.push(isGte(value, gte));
  if (!isNil(lte)) exprs.push(isLte(value, lte));
  if (!isNil(gt)) exprs.push(isGt(value, gt));
  if (!isNil(lt)) exprs.push(isLt(value, lt));

  return every(exprs, (expr) => expr);
};

const DataPointNodeBase = t.compose(
  DataPointBase,
  t
    .model({
      _type: t.frozen(t.maybeNull(t.enumeration('DataPointType', Object.values(DataPointTypes)))),
      path: t.frozen(t.maybeNull(t.string)),
      device_id: t.frozen(t.maybeNull(t.string))
    })
    .views((self) => ({
      get node() {
        return getParent<INode>(self, 3);
      },
      get shortPath() {
        return getShortPath(self.path);
      },
      get isMetric() {
        return self._type === 'metric';
      },
      get isDebug() {
        return self._type === 'debug';
      },
      get isRaw() {
        return self._type === 'raw';
      },
      get isError() {
        return self._type === 'error';
      },
      get isDefault() {
        const isCorrectType = isNil(self._type) || ['metric'].includes(self._type!);
        return isCorrectType && !includes(['0:23', '0:24', '0:25', '0:8'], getShortPath(self.path));
      },
      get parsePath() {
        return parsePath(self.path);
      },
      get deviceId() {
        const { nodeId, device } = parsePath(self.path);
        return self.device_id || `${nodeId}:${device}`;
      },
      get profile() {
        return get(self.node?.thiamis?.profiles, '[0]');
      },
      get profileRange() {
        if (self.profile?.ranges[getShortPath(self.path)]) {
          return sortBy(self.profile?.ranges[getShortPath(self.path)], 'status');
        }
        return null;
      },
      get isWind() {
        return /wind/.test(self.name?.toLowerCase());
      },
      get isTemperature() {
        return Object.values(TEMPERATURE_UNITS).includes(self.unit);
      },
      get isWindDirection() {
        return /wind direction/.test(self.name?.toLowerCase());
      },
      isOnline() {
        const now = DateTime.now().toUTC().toMillis();
        const isBattery = includes([PATHS.BATTERY_CURRENT, PATHS.BATTERY_VOLTAGE], getShortPath(self.path));
        let interval = self.node?.extraReportingInterval;
        if (isBattery) {
          interval += THIAMIS_BATTERY_MIN_REPORTING_INTERVAL;
        }
        return now - self.lastTs <= interval;
      },
      statusType(value?: number) {
        if (self.isOnline()) {
          if (self.profileRange) {
            const selectedRange = self.profileRange?.find((range) => isValueInRange(value ?? self.lastValue, range)) || last(self.profileRange);
            return camelCase(self.profile?.status_name[selectedRange?.status]);
          }
          return DEVICE_STATUSES.ONLINE;
        }
        return DEVICE_STATUSES.OFFLINE;
      },
      statusText(options: { showAQStatus?: boolean } = {}) {
        const { showAQStatus = true } = options;
        const statusType = self.statusType();
        return I18n.t(`thiamis.states.${showAQStatus ? statusType : getOnlineStatusFromAQ(statusType)}`);
      },
      get hasProfileRange() {
        return !!self.profileRange;
      },
      get hasMeeasurements() {
        return !!self.measurements.length;
      }
    }))
);

export interface IDataPointNodeBase extends Instance<typeof DataPointNodeBase> {}

export default DataPointNodeBase;

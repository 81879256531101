import { types as t, Instance } from 'mobx-state-tree';
import NodeWidget from '~/mst/models/widget/node_widget';
import MapWidget from '~/mst/models/widget/map_widget';
import CollectionMap from '~/mst/models/abstract/collection_map';
import ChartTableWidget from '../chart_table_widget';

const WidgetsModel = CollectionMap(
  t.union(
    {
      dispatcher(snapshot) {
        const { type } = snapshot;
        switch (type) {
          case 'ChartTableWidget':
            return ChartTableWidget;
          case 'MapWidget':
            return MapWidget;
          case 'NodeWidget':
            return NodeWidget;
          default:
            throw new Error(`Unkown widget type ${type}`);
        }
      }
    },
    ChartTableWidget,
    NodeWidget,
    MapWidget
  )
);

export interface IWidgetsModel extends Instance<typeof WidgetsModel> {}

export default WidgetsModel;

import { Instance, SnapshotOut } from 'mobx-state-tree';
import model from './model';
import views from './views';
import CommonViews from '../views';

const ThiamisDevice = model.views(CommonViews).views(views);

export interface IThiamisDevice extends Instance<typeof ThiamisDevice> {}
export interface IThiamisDeviceSnapshot extends SnapshotOut<typeof ThiamisDevice> {}

export default ThiamisDevice;

/* eslint-disable import/prefer-default-export */
export const defaultChartOptions = ({ total }) => ({
  title: {
    text: ''
  },
  chart: {
    type: 'pie',
    marginTop: 10,
    marginRight: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    events: {
      render() {
        const chart = this;
        if (chart.series[0]) {
          const textX = chart.plotLeft + chart.series[0].center[0];
          const textY = chart.plotTop + chart.series[0].center[1];
          chart.centerText?.destroy();
          chart.centerText = chart.renderer
            .html(`Total<br/ ><b>${total}</b>`, textX, textY)
            .css({
              color: '#333',
              fontSize: '1em',
              fontWeight: 400,
              textAlign: 'center'
            })
            .add();

          chart.centerText.attr({
            x: textX - chart.centerText.getBBox().width / 2
          });
        }
      }
    }
  },
  plotOptions: {
    pie: {
      innerSize: '65%',
      dataLabels: {
        enabled: true,
        format: '{point.name} <b>{point.y}</b> ({point.percentage:.1f}%)'
      }
    },
    series: {
      allowPointSelect: true,
      cursor: 'pointer',
      dataLabels: [
        {
          enabled: true,
          distance: 20,
          style: {
            color: '#333',
            fontSize: '0.85em',
            fontWeight: 400
          }
        },
        {
          enabled: false
        }
      ]
    }
  },
  tooltip: {
    formatter() {
      return `<b>${this.point.name}</b>: ${this.y} (${this.point.percentage.toFixed(1)}%)`;
    },
    shadow: false
  },
  credits: {
    enabled: false
  },
  exporting: {
    enabled: false
  }
});

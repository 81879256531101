import React, { useCallback, useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import { observer } from 'mobx-react-lite';
import { computed } from 'mobx';
import { Form as FinalForm } from 'react-final-form';

import { INode } from '~/mst/models/node';
import toasts from '~/utils/toasts';
import useLocales from '~/hooks/use_locales';

import { captureException } from '~/services/sentry';
import AdminSettingsForm from './form';

function AdminSettingsContainer({ node }: { node: INode }) {
  const { t } = useLocales();
  const initialValues = useMemo(() => computed(() => ({ endpoint: node?.endpoint, action: null })), [node?.endpoint]).get();
  const handleOnSubmit = useCallback(
    async (values) => {
      try {
        const endpoint = values.endpoint?.value || values.endpoint;
        const action = values.action?.value || values.action;
        if (!isEmpty(endpoint) && endpoint !== initialValues.endpoint) {
          await node.changeEndpoint(endpoint.toLowerCase());
        }
        if (action !== initialValues.action) {
          await node.runAction(action);
        }
        toasts.success(t(`notifications.success.forms.update_node`));
      } catch (e) {
        captureException(e);
        toasts.error(t('notifications.errors.server_error'));
      }
    },
    [initialValues, t, node]
  );

  return (
    <FinalForm onSubmit={handleOnSubmit} initialValues={initialValues} subscription={{ submitting: true, pristine: true, dirty: true }}>
      {({ handleSubmit }) => {
        return <AdminSettingsForm handleSubmit={handleSubmit} model={node} />;
      }}
    </FinalForm>
  );
}

export default observer(AdminSettingsContainer);

import { useCallback } from 'react';
import { observer } from 'mobx-react-lite';

import intersection from 'lodash/intersection';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';

import useSelection from '~/hooks/react_grid/use_selection';
import useLocales from '~/hooks/use_locales';
import useMst from '~/hooks/use_mst';
import { MenuGroupTitleStyled, MenuItemStyled } from '~/components/actions_dropdown/styled';

const manageActions = [['activate', 'deactivate', 'hibernate'], ['share', 'remove_share'], ['lease', 'remove_lease'], ['transfer'], ['delete']];

type ActionsDropdownProps = {
  handleToggleDropdown: VoidFunction;
  setActionType: (action: string | null) => void;
};

function ActionsDropdown({ handleToggleDropdown, setActionType }: ActionsDropdownProps) {
  const { selection } = useSelection();
  const { t } = useLocales();

  const { nodes } = useMst();
  const { actions } = nodes;

  const actionsAll = manageActions.map((group) => intersection(group, actions)).filter((group) => group.length);
  const availableActions = intersection(...selection.map((nodeId) => nodes.getById(nodeId)?.actions));
  const isActionAvailable = useCallback((action) => includes(availableActions, action), [availableActions]);

  const handleActionClick = useCallback(
    (action) => () => {
      if (!isActionAvailable(action)) {
        return;
      }
      handleToggleDropdown();
      setActionType(action);
    },
    [isActionAvailable, handleToggleDropdown, setActionType]
  );

  if (isEmpty(actionsAll)) {
    return null;
  }

  return (
    <>
      <MenuGroupTitleStyled disabled>Manage</MenuGroupTitleStyled>
      {actionsAll.map((group, groupIndex) =>
        group.map((action, index) => (
          <MenuItemStyled
            divider={groupIndex !== actionsAll.length - 1 && index === group.length - 1}
            key={action}
            disabled={!isActionAvailable(action)}
            onClick={handleActionClick(action)}
          >
            {t(`thiamis.manage_actions.${action}`)}
          </MenuItemStyled>
        ))
      )}
    </>
  );
}

export default observer(ActionsDropdown);

import React from 'react';
import { Grid, Box } from '@mui/material';
import { observer } from 'mobx-react-lite';

import useMst from '~/hooks/use_mst';
import { PERMISSIONS } from '~/mst/models/permissions/types';
import useAuth from '~/hooks/use_auth';

import AddDashboard from './add_dashboard';
import GridItem from './grid_item';

function Dashboards() {
  const { dashboards } = useMst();
  const { auth } = useAuth();
  return (
    <Grid container spacing={4} sx={{ mt: 1 }}>
      {dashboards.list.map(({ value, model }) => (
        <Grid item xs={12} md={3} key={value}>
          <GridItem dashboard={model} id={value} />
        </Grid>
      ))}
      {dashboards.isEmpty && auth.user?.hasPermission(PERMISSIONS.ReadReports) && (
        <Grid item xs={12} md={3}>
          <Box sx={{ minHeight: 240, display: 'flex' }}>
            <AddDashboard />
          </Box>
        </Grid>
      )}
    </Grid>
  );
}

export default observer(Dashboards);

import { types as t, Instance } from 'mobx-state-tree';
import Invitation from '~/mst/models/invitation';
import Fetchable from '~/mst/models/abstract/fetchable';
import Filterable from '~/mst/models/abstract/filterable';
import CollectionMap from '~/mst/models/abstract/collection_map';

const InvitationsModel = t.compose(CollectionMap(Invitation), Fetchable({ cache: true }), Filterable);

export interface IInvitationsModel extends Instance<typeof InvitationsModel> {}

export default InvitationsModel;

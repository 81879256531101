/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useMemo } from 'react';
import Yup, { searchableSelectSchema } from '~/utils/yup';
import { Form as FinalForm, useForm, useFormState } from 'react-final-form';
import { observer } from 'mobx-react-lite';
import Autocomplete from '~/components/final_form/select';
import { useValidationSchema } from '~/hooks/use_validate';
import { WidgetTypes } from '~/mst/models/widget/types';
import useMst from '~/hooks/use_mst';
import MapWidget from '~/mst/models/widget/map_widget';

import Modal from '~/components/modal';
import { useParams } from 'react-router-dom';
import useFetch from '~/hooks/use_fetch';
import type { IWidget } from '~/mst/models/widget';
import type { IDashboard } from '~/mst/models/dashboard';
import { captureException } from '~/services/sentry';
import toasts from '~/utils/toasts';
import useLocales from '~/hooks/use_locales';
import AddEditConfigurationModal from '../../common/configuration_form';

type FormProps = {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  isNew?: boolean;
  dashboard?: IDashboard;
};

type AddEditMapConfigurationProps = {
  open: boolean;
  onClose: () => void;
  widget?: IWidget;
};

export function MapConfigurationForm({ open, onClose, onSave, isNew, dashboard }: FormProps) {
  const { nodes } = useMst();
  const { reset } = useForm();
  const { invalid, initialValues } = useFormState({ subscription: { invalid: true, initialValues: true } });
  const { t } = useLocales();

  useFetch(nodes, { organization_id: dashboard?.organization_id });

  const handleClose = useCallback(() => {
    onClose();
    reset(initialValues);
  }, [onClose, reset, initialValues]);

  const handleSave = useCallback(() => {
    onSave();
    handleClose();
  }, [onSave, handleClose]);

  return (
    <AddEditConfigurationModal
      isNew={isNew}
      title={isNew ? t('models.map_widget_create') : t('models.map_widget_update')}
      open={open}
      onClose={handleClose}
      onSave={handleSave}
      disabled={invalid}
    >
      <Autocomplete searchable name="node_id" label={t('models.node')} options={nodes.monitorableValueLabelPairs || []} />
    </AddEditConfigurationModal>
  );
}

const ObservedForm = observer(MapConfigurationForm);

function AddEditMapConfiguration({ open, onClose, widget }: AddEditMapConfigurationProps) {
  const { dashboards } = useMst();
  const { id } = useParams();
  const { t } = useLocales();
  const dashboard = dashboards.getById(id);
  const isNew = !widget;

  const initialValues = useMemo(
    () => ({
      node_id: widget?.node_id
    }),
    [widget]
  );

  const AddEditMapSchema = Yup.object().shape({
    node_id: searchableSelectSchema('Node is required')
  });

  const validate = useValidationSchema(AddEditMapSchema);

  const handleSave = useCallback(
    (values) => {
      try {
        if (widget) {
          widget.update({
            node_id: values.node_id?.value || values.node_id
          });
        } else {
          const newWidget = MapWidget.create({
            _type: WidgetTypes.MapWidget,
            node_id: values.node_id?.value || values.node_id
          });
          const breakpoints = dashboard.widgets.lastPositions(8, 4);
          newWidget.setLayout(breakpoints);
          dashboard.widgets.create(newWidget);
        }
      } catch (e) {
        captureException(e);
        toasts.error(t('notifications.errors.server_error'));
      }
    },
    [widget, dashboard, t]
  );

  return (
    <Modal open={open} handleClose={onClose} title={isNew ? t('models.map_widget_create') : t('models.map_widget_update')}>
      <FinalForm onSubmit={handleSave} initialValues={initialValues} validate={validate}>
        {({ handleSubmit }) => (
          <ObservedForm isNew={!widget} open={open} onClose={onClose} onSave={handleSubmit} initialValues={initialValues} dashboard={dashboard} />
        )}
      </FinalForm>
    </Modal>
  );
}

AddEditMapConfiguration.defaultProps = {
  widget: undefined
};

export default AddEditMapConfiguration;

import { Typography, Stack } from '@mui/material';
import Container from '~/components/container';
import AddIcon from '@mui/icons-material/Add';
import { observer } from 'mobx-react-lite';
import useLocales from '~/hooks/use_locales';
import useMst from '~/hooks/use_mst';
import { PERMISSIONS } from '~/mst/models/permissions/types';
import useFetch from '~/hooks/use_fetch';
import useAuth from '~/hooks/use_auth';
import ButtonAdaptive from '~/components/adaptive_button';
import LoadingBox from '~/components/loading_box';
import useModal from '~/hooks/use_modal';

import Grid from './grid';
import Form from './create/form';

function Dashboards() {
  const { t } = useLocales();
  const { dashboards } = useMst();
  const { open, handleOpen, handleClose } = useModal();
  const { auth } = useAuth();

  const { isFetching } = useFetch(dashboards);

  return (
    <Container maxWidth={false} disableGutters>
      <Stack justifyContent="space-between" direction={{ sm: 'column', md: 'row' }}>
        <Typography variant="h4" component="h1" paragraph>
          {t('dashboards.title')}
        </Typography>
        {auth.user?.hasPermission(PERMISSIONS.ReadReports) && (
          <ButtonAdaptive variant="contained" size="medium" startIcon={<AddIcon />} $mobileFullWidth breakpoint="md" onClick={() => handleOpen()}>
            {t('dashboards.add_dashboard')}
          </ButtonAdaptive>
        )}
      </Stack>
      {isFetching ? <LoadingBox /> : <Grid />}
      <Form open={open} onClose={handleClose} />
    </Container>
  );
}

export default observer(Dashboards);

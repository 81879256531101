import { observer } from 'mobx-react-lite';
import { FieldArrayRenderProps } from 'react-final-form-arrays';
import { Grid, Box } from '@mui/material';
import type { IAlert } from '~/mst/models/alert';
import { DividerStyled } from '~/pages/alert_edit/conditions_block/styled';
import type { ConditionType } from '../data_point';
import ExpressionField from './expression_field';
import ValueField from './value_field';
import ParameterField from './parameter_field';

type ConditionFieldType = FieldArrayRenderProps<ConditionType, HTMLInputElement> & {
  name: string;
  model: IAlert;
  index: number;
};

function ConditionField(props: ConditionFieldType) {
  const { name, model, fields, index } = props;

  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item md={7}>
        <ParameterField name={name} model={model} />
      </Grid>
      <Grid item md={2}>
        <ExpressionField name={name} />
      </Grid>
      <Grid item md={3}>
        <ValueField name={name} model={model} />
      </Grid>
      {fields.value.length - 1 !== index && (
        <Box alignItems="center" sx={{ width: '100%' }}>
          <DividerStyled orientation="vertical" flexItem>
            AND
          </DividerStyled>
        </Box>
      )}
    </Grid>
  );
}

export default observer(ConditionField);

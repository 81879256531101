import React, { useEffect } from 'react';
import { Typography } from '@mui/material';
import { autorun } from 'mobx';
import { ContainerGridStyled } from '~/components/react_grid/styled';
import useMst from '~/hooks/use_mst';
import useFetch from '~/hooks/use_fetch';
import Container from '~/components/container';
import useLocales from '~/hooks/use_locales';

import GridContainer from './grid_container';
import FiltersFormTop from './form_top';

function CertificatesScreen() {
  const { t } = useLocales();
  const { nodes, certificates, templates } = useMst();

  useFetch(certificates);
  useFetch(templates);

  useEffect(() => {
    const disposer = autorun(() => {
      if (!nodes.isFetched && !nodes.isFetching && templates.isFetched && templates.blue?.organization_id) {
        nodes.fetch({
          organization_id: templates.blue.organization_id
        });
      }
    });
    return () => {
      disposer();
      nodes.abortFetching();
    };
  }, [nodes, templates]);

  return (
    <Container>
      <Typography variant="h4" component="h1" paragraph>
        {t('certificates.title')}
      </Typography>
      <ContainerGridStyled>
        <FiltersFormTop />
        <GridContainer />
      </ContainerGridStyled>
    </Container>
  );
}

export default CertificatesScreen;

import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useFormState } from 'react-final-form';
import { observable } from 'mobx';
import { CardContent } from '@mui/material';

import useLocales from '~/hooks/use_locales';
import useMst from '~/hooks/use_mst';
import useFetch from '~/hooks/use_fetch';
import { IWebhook } from '~/mst/models/webhook';

import FormGrid from '~/components/@material-extend/form_grid';
import DevicesSelect from '~/components/devices_select';
import Select from '~/components/final_form/select';
import TextInput from '~/components/final_form/text_input';
import { ModalActions } from '~/components/modal';
import OrganizationSelect from '~/components/organizations_select';

type ModalFormProps = {
  handleCancel: VoidFunction;
  handleSubmit: VoidFunction;
  model: IWebhook;
};

const params = observable({
  organization_id: null,
  last: 1
});

function ModalForm({ handleCancel, handleSubmit, model }: ModalFormProps) {
  const { t } = useLocales();
  const { organizations, nodes } = useMst();

  const {
    values: { organization_id: organizationId },
    hasValidationErrors
  } = useFormState({ subscription: { values: true, hasValidationErrors: true } });

  const typesOptions = useMemo(
    () => [
      { label: t('webhooks.types.alert'), value: 'alert' },
      { label: t('webhooks.types.device'), value: 'device' }
    ],
    [t]
  );

  params.organization_id = organizationId;

  const { isFetching } = useFetch(nodes, params);

  return (
    <>
      <CardContent>
        <FormGrid>
          <TextInput name="url" label={t('attributes.webhook.url')} />
          <TextInput name="auth" label={t('attributes.webhook.auth')} />
          <OrganizationSelect
            label={t('models.organization')}
            name="organization_id"
            options={organizations.valueLabelPairsManagerAccess}
            loading={organizations.isFetching}
          />
          <DevicesSelect
            loading={isFetching}
            label={t('attributes.webhook.nodes')}
            name="nodes"
            multiple
            options={nodes.valueLabelPairs || []}
            placeholder={t('base.labels.all')}
          />
          <Select label={t('attributes.webhook.type')} name="type" placeholder={t('base.labels.all')} options={typesOptions} />
        </FormGrid>
      </CardContent>
      <ModalActions
        disabled={hasValidationErrors}
        loading={model.isSyncing}
        saveLabel={t('base.buttons.create')}
        onSave={handleSubmit}
        onClose={handleCancel}
      />
    </>
  );
}

export default observer(ModalForm);

import { types as t, Instance } from 'mobx-state-tree';
import { ModelV1 as Model } from '~/mst/models/abstract/model';
import Syncable from '~/mst/models/abstract/syncable';
import Fetchable from '~/mst/models/abstract/fetchable';
import CreatedAtModel from '~/mst/models/abstract/created_at';
import WebhookConditionsModel from './conditions';
import WebhookLastResponseModel from './last_response';

const WebhookModel = t
  .compose(
    t.model({
      url: t.optional(t.string, ''),
      auth: t.optional(t.string, ''),
      conditions: t.maybeNull(WebhookConditionsModel),
      last_response: t.maybeNull(WebhookLastResponseModel),
      enabled: t.optional(t.boolean, true),
      organization_id: t.optional(t.string, '')
    }),
    Model,
    Syncable,
    CreatedAtModel,
    Fetchable()
  )
  .named('Webhook');

export interface IWebhookModel extends Instance<typeof WebhookModel> {}

export default WebhookModel;

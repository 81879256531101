import { Typography } from '@mui/material';
import useMst from '~/hooks/use_mst';
import useFetch from '~/hooks/use_fetch';
import { ContainerGridStyled } from '~/components/react_grid/styled';
import Container from '~/components/container';
import useLocales from '~/hooks/use_locales';

import GridContainer from './grid_container';
import FormTop from './form_top';

function PartnershipsScreen() {
  const { t } = useLocales();
  const { partnerships } = useMst();
  useFetch(partnerships);

  return (
    <Container>
      <Typography variant="h4" component="h1" paragraph>
        {t('partnerships.title')}
      </Typography>
      <ContainerGridStyled>
        <FormTop />
        <GridContainer />
      </ContainerGridStyled>
    </Container>
  );
}

export default PartnershipsScreen;

import { types as t, Instance } from 'mobx-state-tree';
import { getStore } from '~/mst/utils';
import DateType from '~/mst/models/abstract/date_time';
import { ModelV2 } from '~/mst/models/abstract/model';
import CreatedAtModel from '~/mst/models/abstract/created_at';
import Syncable from '~/mst/models/abstract/syncable';
import Fetchable from '~/mst/models/abstract/fetchable';
import MaybeString from '~/mst/models/abstract/maybe_string';
import Query from './query';

const TokenModel = t
  .compose(
    t.model({
      organization_id: t.string,
      external_id: t.string,
      external_id_revealed: t.maybeNull(t.string),
      requests_number: t.maybeNull(t.optional(t.number, 0)),
      last_used_at: t.maybeNull(DateType),
      user_id: t.string,
      permissions: t.array(MaybeString),
      query: t.maybeNull(Query),
      token: t.maybeNull(MaybeString)
    }),
    ModelV2,
    Syncable,
    CreatedAtModel,
    Fetchable()
  )
  .views((self) => ({
    get owner() {
      return getStore(self).organizations.getById(self.organization_id);
    },
    get creator() {
      return getStore(self).users.getById(self.user_id);
    }
  }));

export interface ITokenModel extends Instance<typeof TokenModel> {}

export default TokenModel;

/* eslint-disable @typescript-eslint/no-use-before-define */
import { Instance } from 'mobx-state-tree';
import model from './model';
import views from './views';

const DustTrakSensor = model.views(views);

export interface IDustTrakSensor extends Instance<typeof DustTrakSensor> {}

export default DustTrakSensor;

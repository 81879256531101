/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useCallback } from 'react';
import Tooltip from '@mui/material/Tooltip';
import useLocales from '~/hooks/use_locales';

export default function CopyToClipboard({ value, children }: { value: string; children: React.ReactElement }) {
  const { t } = useLocales();
  const [isCopied, setIsCopied] = useState(false);
  const [isOpened, setIsOpened] = useState(false);

  const handleCopyToClipboard = useCallback(async () => {
    navigator.clipboard.writeText(value);
    setIsCopied(true);
    setTimeout(() => setIsOpened(false), 300);
  }, [setIsCopied, setIsOpened, value]);

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { onClick: handleCopyToClipboard });
    }
    return child;
  });
  return (
    <Tooltip
      PopperProps={{
        disablePortal: true
      }}
      onMouseLeave={() => {
        setIsOpened(false);
      }}
      onMouseEnter={() => {
        setIsCopied(false);
        setIsOpened(true);
      }}
      open={isOpened}
      title={isCopied ? t('base.labels.copied') : t('base.labels.copy_to_clipboard')}
    >
      {childrenWithProps}
    </Tooltip>
  );
}

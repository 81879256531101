import { types as t, Instance, SnapshotOut } from 'mobx-state-tree';
import isObject from 'lodash/isObject';
import omit from 'lodash/omit';
import model from './model';
import views from './views';
import viewsFilters from './views/filters';
import actions from './actions';

const Alert = model.views(views).views(viewsFilters).actions(actions);

export function includeDeviceId(cnd, nodes) {
  if (cnd.func === 'diff') {
    return {
      ...cnd,
      ...includeDeviceId(
        {
          dataPointId: cnd.dataPointId
        },
        nodes
      ),
      dataPointId2: includeDeviceId(
        {
          dataPointId: cnd.dataPointId2
        },
        nodes
      )
    };
  }
  if (isObject(cnd.dataPointId)) {
    return { ...cnd, dataPointId: includeDeviceId(cnd.dataPointId, nodes) };
  }
  return { ...cnd, deviceId: nodes?.find((node) => node.data_points?.some((dataPoint) => dataPoint._id === cnd.dataPointId))?._id };
}

export function processDataPoints(dataPoints, nodes) {
  return dataPoints?.map((dp) => ({
    ...dp,
    conditions: dp.conditions.map((cnd) => includeDeviceId(cnd, nodes))
  }));
}

export interface IAlert extends Instance<typeof Alert> {}
export interface IAlertSnapshotOut extends SnapshotOut<typeof Alert> {}

export default t.snapshotProcessor(Alert, {
  postProcessor(sn) {
    return omit(
      {
        ...sn,
        data_points: processDataPoints(sn.data_points, sn.nodes)
      },
      ['nodes']
    );
  }
});

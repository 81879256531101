/* eslint-disable react/jsx-props-no-spreading */
import React, { Suspense } from 'react';
import LoadingScreen from '~/components/loading_screen';

const withLoadable = (WrappedComponent: React.ComponentType) =>
  function WrappedWithLoadable(props: any) {
    return (
      <Suspense
        fallback={
          <LoadingScreen
            sx={{
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            }}
          />
        }
      >
        <WrappedComponent {...props} />
      </Suspense>
    );
  };

export default withLoadable;

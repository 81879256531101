import { useMemo } from 'react';
import { Card, Stack, CircularProgress, Box, CardContent } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { computed } from 'mobx';
import { getSnapshot } from 'mobx-state-tree';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import MCardHeader from '~/components/@material-extend/MCardHeader';
import { HeaderDeviceWrapperStyled } from '~/pages/device_edit/components/styled';

import type { IAlert } from '~/mst/models/alert';
import useLocales from '~/hooks/use_locales';

import Form from './form';

type ConditionsBlockType = {
  alert: IAlert;
};

function ConditionsBlock({ alert }: ConditionsBlockType) {
  const { t } = useLocales();
  const initialValues = useMemo(() => computed(() => (alert ? getSnapshot(alert) : {})), [alert]).get();

  return (
    <Card>
      <HeaderDeviceWrapperStyled className="AlertBlockTitleContainer">
        <MCardHeader title={t('attributes.alert.rules')} />
      </HeaderDeviceWrapperStyled>
      <CardContent>
        {alert?.isFetching ? (
          <Stack alignItems="center">
            <CircularProgress size={25} color="info" />
          </Stack>
        ) : (
          <Box>
            {alert?.data_points.isEmpty ? (
              <Stack alignItems="center">{t('alerts.conditions.no_conditions')}</Stack>
            ) : (
              <FinalForm onSubmit={() => {}} initialValues={initialValues} mutators={{ ...arrayMutators }} subscription={{ values: true }}>
                {() => <Form model={alert} />}
              </FinalForm>
            )}
          </Box>
        )}
      </CardContent>
    </Card>
  );
}

export default observer(ConditionsBlock);

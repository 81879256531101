/* eslint-disable @typescript-eslint/naming-convention */
import pick from 'lodash/pick';
import flatten from 'lodash/flatten';
import flow from 'lodash/fp/flow';
import compact from 'lodash/compact';
import uniq from 'lodash/uniq';
import { PERMISSIONS } from '~/mst/models/permissions/types';
import { RolesEnum } from '~/utils/constants';
import type { IUserModel } from './model';

export default (self: IUserModel) => {
  function fullName() {
    return self.first_name && self.last_name ? `${self.first_name} ${self.last_name}` : '';
  }
  return {
    get fullName() {
      return fullName();
    },
    get initials() {
      return self.first_name && self.last_name ? `${self.first_name?.charAt(0)}${self.last_name?.charAt(0)}` : null;
    },
    get presentName() {
      return fullName();
    },
    get searchString() {
      const searchFields = ['presentName'];
      return Object.values(pick(self, searchFields)).join(' ').toLowerCase();
    },
    hasPermission(key: PERMISSIONS, id?: string) {
      if (id) {
        return Boolean(self.permissions[key]) && self.permissions[key].includes(id);
      }
      return Boolean(self.permissions[key]);
    },
    get isSupport() {
      return self.hasPermission(PERMISSIONS.AccessContext);
    },
    getRoleByOrganizationId(id: string) {
      if (self.hasPermission(PERMISSIONS.AdminDevices, id)) {
        return RolesEnum.Admin;
      }
      if (self.hasPermission(PERMISSIONS.ManageOrganizations, id)) {
        return RolesEnum.Owner;
      }
      if (self.hasPermission(PERMISSIONS.ManageUsers, id)) {
        return RolesEnum.Manager;
      }
      if (self.hasPermission(PERMISSIONS.ReadUsers, id)) {
        return RolesEnum.User;
      }
      if (self.hasPermission(PERMISSIONS.ReadOrganizations, id)) {
        return RolesEnum.Partner;
      }
      return null;
    },
    get organizationsIds() {
      return flow(flatten, uniq, compact)(Object.values(self.permissions.toJSON()));
    },
    get organizationsRoles() {
      return self.organizationsIds.map((id) => {
        const role = self.getRoleByOrganizationId(id);
        return {
          id: `${id}_${role}`,
          organizationId: id,
          role
        };
      });
    }
  };
};

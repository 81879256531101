import { Typography } from '@mui/material';
import useMst from '~/hooks/use_mst';
import useFetch from '~/hooks/use_fetch';
import { ContainerGridStyled } from '~/components/react_grid/styled';
import Container from '~/components/container';
import useLocales from '~/hooks/use_locales';

import FilterBar from './filter_bar';
import UsersGrid from './grid_container';

function Users() {
  const { t } = useLocales();

  const { users } = useMst();
  useFetch(users);

  return (
    <Container>
      <Typography variant="h4" component="h1" paragraph>
        {t('users.title')}
      </Typography>
      <ContainerGridStyled>
        <FilterBar />
        <UsersGrid />
      </ContainerGridStyled>
    </Container>
  );
}

export default Users;

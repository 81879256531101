/* eslint-disable import/prefer-default-export */
import React from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import LastTriggeredFormatter from './last_triggered_formatter';
import NameFormatter from './name_formatter';
import ActionsFormatter from './actions_formatter';
import StatusFormatter from './status_formatter';

export function LastTriggeredProvider() {
  return <DataTypeProvider for={['lastTriggeredTs']} formatterComponent={LastTriggeredFormatter} />;
}
export function NameProvider() {
  return <DataTypeProvider for={['name']} formatterComponent={NameFormatter} />;
}

export function ActionsProvider() {
  return <DataTypeProvider for={['actions']} formatterComponent={ActionsFormatter} />;
}

export function StatusTypeProvider() {
  return <DataTypeProvider for={['statusType']} formatterComponent={StatusFormatter} />;
}

import { Instance } from 'mobx-state-tree';
import CollectionArrayProcessor from '~/mst/models/abstract/collection_array_processor';
import model from './model';
import views from './views';
import actions from './actions';

const DataPointsNode = model.views(views).actions(actions);

export interface IDataPointsNode extends Instance<typeof DataPointsNode> {}

export default CollectionArrayProcessor(DataPointsNode);

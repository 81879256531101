import type { IMapWidgetStore } from '.';

export default (self: IMapWidgetStore) => ({
  get data() {
    return self.nodes.toJSON();
  },
  get node() {
    return self.nodes?.getById(self.node_id);
  }
});

import React, { useCallback } from 'react';
import { Form as FinalForm } from 'react-final-form';
import difference from 'lodash/difference';
import { observer } from 'mobx-react-lite';
import { Box } from '@mui/material';

import useMst from '~/hooks/use_mst';
import toasts from '~/utils/toasts';

import useSelection from '~/hooks/react_grid/use_selection';
import useLocales from '~/hooks/use_locales';

import { ModalActions } from '~/components/modal';
import { captureException } from '~/services/sentry';
import useAuth from '~/hooks/use_auth';
import ActionsModalForm from './actions_modal_form';

type ModalActionsType = 'activate' | 'deactivate' | 'share' | 'remove_share' | 'lease' | 'remove_lease' | 'delete';

type ActionsModalProps = {
  type: ModalActionsType;
  toggleModal: VoidFunction;
};

function ActionsModal({ type, toggleModal }: ActionsModalProps) {
  const { selection: selectedDevices, setSelection } = useSelection();
  const { t } = useLocales();
  const { nodes } = useMst();
  const { auth } = useAuth();

  const isUpdating = selectedDevices.some((id) => nodes.getById(id)?.isSyncing);

  const handleCancel = useCallback(() => toggleModal(), [toggleModal]);
  const handleAction = useCallback(
    (values) => {
      const { selectedOrganization, disabledNodes } = values;
      const allowedNodes = difference(selectedDevices, disabledNodes);
      allowedNodes.forEach(async (nodeId) => {
        const node = nodes.getById(nodeId);
        try {
          switch (type) {
            case 'activate':
              await node.activate();
              break;
            case 'deactivate':
              await node.deactivate();
              break;
            case 'share':
              await node.share(selectedOrganization);
              break;
            case 'remove_share':
              await node.removeShare(selectedOrganization);
              break;
            case 'lease':
              await node.lease(selectedOrganization);
              break;
            case 'remove_lease':
              await node.removeLease(selectedOrganization);
              break;
            case 'delete':
              await node.reset();
              break;
            default:
              throw new Error('Action not supported');
          }
          toggleModal();
          setSelection([]);
          nodes.fetch({ includes: ['profiles'], organization_id: auth.organizationId }, { noCache: true });
          toasts.success(t('thiamis.manage_dialog.success', { action: t(`thiamis.manage_actions.${type}_past`) }));
        } catch (e) {
          captureException(e);
          toasts.error(t('notifications.errors.server_error'));
        }
      });
    },
    [toggleModal, setSelection, nodes, selectedDevices, type, t, auth.organizationId]
  );

  return (
    <Box>
      <FinalForm
        onSubmit={handleAction}
        initialValues={{ disabledNodes: [], selectedOrganization: null }}
        render={({ handleSubmit }) => (
          <Box>
            <ActionsModalForm type={type} />
            <ModalActions onSave={handleSubmit} saveLabel={t(`thiamis.manage_actions.${type}`)} onClose={handleCancel} loading={isUpdating} />
          </Box>
        )}
      />
    </Box>
  );
}

export default observer(ActionsModal);

import React, { ReactNode, useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import { CssBaseline } from '@mui/material';
import { createTheme, StyledEngineProvider, ThemeOptions, ThemeProvider } from '@mui/material/styles';

import useAuth from '~/hooks/use_auth';
import animations from './animations';
import shape from './shape';
import typography from './typography';
import breakpoints from './breakpoints';
import componentsOverride from './overrides';
import shadows, { customShadows } from './shadows';
import variants from './config';

type ThemeConfigProps = {
  children: ReactNode;
  variant: string;
};

function ThemeConfig(props: ThemeConfigProps) {
  const { children, variant = 'default' } = props;
  const {
    auth: { theme: userTheme }
  } = useAuth();

  let themeConfig = useMemo(() => variants.find((item) => item.name === variant), [variant]);

  if (!themeConfig) {
    const [variantOne] = variants;
    themeConfig = variantOne;
  }

  const { palette } = themeConfig;

  const themeOptions: ThemeOptions = useMemo(
    () => ({
      palette: { ...palette[userTheme], mode: userTheme },
      animations,
      shape,
      typography,
      breakpoints,
      shadows: shadows[userTheme],
      customShadows: customShadows[userTheme]
    }),
    [userTheme, palette]
  );

  const theme = useMemo(
    () =>
      createTheme(themeOptions, {
        name: themeConfig.name || variant
      }),
    [themeConfig.name, themeOptions, variant]
  );
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default observer(ThemeConfig);

/* eslint-disable react/jsx-props-no-spreading */
import { useCallback, useEffect, useState, useDeferredValue } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment } from '@mui/material';
import { observer } from 'mobx-react-lite';
import useLocales from '~/hooks/use_locales';

import { TextFieldStyled } from './styled';

function SearchTextInput({ collection }: { collection: any }) {
  const { t } = useLocales();
  const [query, setQuery] = useState('');
  const deferredQuery = useDeferredValue(query);

  const handleChangeQuery = useCallback((event) => {
    setQuery(event.target.value);
  }, []);

  useEffect(() => {
    if (collection) {
      collection.setQuery(query);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deferredQuery]);

  return (
    <TextFieldStyled
      placeholder={t('inputs.search_by_keyword')}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        )
      }}
      onChange={handleChangeQuery}
    />
  );
}

export default observer(SearchTextInput);

import React, { useMemo, useCallback, useState } from 'react';
import { Box, FormControl, Tooltip, InputAdornment, IconButton, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-final-form';
import SyncIcon from '@mui/icons-material/Sync';
import { Iconify } from '~/components/iconify';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { OnChange } from 'react-final-form-listeners';
import { observer } from 'mobx-react-lite';
import { computed } from 'mobx';
import { getIdentifier } from 'mobx-state-tree';

import useLocales from '~/hooks/use_locales';
import TextInput from '~/components/final_form/text_input';
import Select from '~/components/final_form/select';
import { ItemTitle } from '~/pages/device_edit/components';
import { INode } from '~/mst/models/node';
import api from '~/api';
import toasts from '~/utils/toasts';
import useModal from '~/hooks/use_modal';
import FormGrid from '~/components/@material-extend/form_grid';
import DeviceDefaultWifiModal from './default_wifi_modal';

function PasswordField({ name, label, ...rest }: { name: string; label: string }) {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <TextInput
      name={name}
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            <IconButton size="small" onClick={() => setIsVisible(!isVisible)}>
              {isVisible ? <VisibilityIcon fontSize="small" /> : <VisibilityOffIcon fontSize="small" />}
            </IconButton>
          </InputAdornment>
        )
      }}
      label={label}
      type={isVisible ? 'text' : 'password'}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    />
  );
}

function Connectivity({ node }: { node: INode }) {
  const { t } = useLocales();
  const [isRefreshing, setIsRefreshing] = useState(false);
  const { change } = useForm();
  const { open, handleOpen, handleClose } = useModal();

  const wifiNetworksOptions = useMemo(
    () =>
      computed(() => {
        const networtks = node?.wifiNetworks
          ?.map(({ ssid }) => ({ label: ssid, value: ssid }))
          .sort(({ label: label1 }, { label: label2 }) => label1.localeCompare(label2));
        return [{ label: t('thiamis.select_network'), value: null }].concat(networtks);
      }),
    [node?.wifiNetworks, t]
  ).get();

  const handleRefreshNetworksClick = useCallback(async () => {
    setIsRefreshing(true);
    try {
      await api.nodes.runAction(getIdentifier(node), 'wifi_scan');
      toasts.success(t('notifications.success.wifi_scan'));
    } catch (e) {
    } finally {
      setIsRefreshing(false);
    }
  }, [t, node]);

  if (node?.isCreatable) {
    return null;
  }

  return (
    <Box sx={{ mb: -1 }}>
      <Stack direction="row">
        <ItemTitle>{t('device_monitor.forms.connectivity')}</ItemTitle>
        <Tooltip title="See WiFi settings of device">
          <IconButton color="info" onClick={() => handleOpen()} sx={{ position: 'relative', top: '-12px' }}>
            <Iconify icon="eva:question-mark-circle-fill" width={16} height={16} />
          </IconButton>
        </Tooltip>
      </Stack>
      <FormControl fullWidth>
        <FormGrid sx={{ mb: 2, mt: 1, gridColumn: '2 / span 3', display: 'grid' }}>
          <Stack direction="row" gap={1} fullWidth>
            <Select
              sx={(theme) => ({ '& .MuiInputBase-root': { px: theme.spacing(1) }, '& .MuiSvgIcon-root': { right: theme.spacing(7) } })}
              name="thiamis.wifi_ssid"
              data-testid="deviceWifiSsidSelect"
              label={t('attributes.thiamis.wifi_ssid')}
              allowNull
              options={wifiNetworksOptions || []}
            />
            <Tooltip title={t('thiamis.tooltips.refresh_wifi_networks')} placement="top">
              <LoadingButton variant="outlined" onClick={handleRefreshNetworksClick} disabled={isRefreshing}>
                <SyncIcon fontSize="small" />
              </LoadingButton>
            </Tooltip>
          </Stack>
          <PasswordField name="thiamis.wifi_password" label={t('attributes.thiamis.wifi_password')} data-testid="deviceWifiPasswordInput" />
        </FormGrid>
      </FormControl>
      <OnChange name="thiamis.wifi_ssid">
        {(value) => {
          if (value == null) {
            change('thiamis.wifi_password', null);
          }
        }}
      </OnChange>
      <DeviceDefaultWifiModal node={node} open={open} onClose={handleClose} />
    </Box>
  );
}

export default observer(Connectivity);

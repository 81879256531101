import { observer } from 'mobx-react-lite';

import WidgetContainer from '~/components/widgets/common';
import { WidgetPropType } from '~/components/widgets/common/types';
import useModal from '~/hooks/use_modal';
import ChartStock from '~/components/chart';
import { ChartProvider } from '~/hooks/use_chart';
import type { IChartWidgetStore } from '~/mst/models/widget/chart_widget';
import { WidgetTitleStyled } from '~/components/widgets/common/styled';
import ChartWidgetConfiguration from './configuration';
import DeviceWidgetTitle from '../common/device_widget_title';

function ChartWidget({ widget, moveable, dashboard, editable, onClose }: WidgetPropType<IChartWidgetStore>) {
  const { open, handleOpen, handleClose } = useModal();

  return (
    <ChartProvider>
      <WidgetContainer
        Header={
          widget?.nodeId.length === 1 ? (
            <DeviceWidgetTitle node={widget?.node} onClose={onClose} />
          ) : (
            <WidgetTitleStyled>{widget.name || 'Device widget'}</WidgetTitleStyled>
          )
        }
        widget={widget}
        moveable={moveable}
        editable={editable}
        onEdit={handleOpen}
        dashboard={dashboard}
      >
        <ChartStock data={widget.data} legend={widget.legend} isLoading={widget.isSyncing} />
        <ChartWidgetConfiguration open={open} onClose={handleClose} widget={widget} />
      </WidgetContainer>
    </ChartProvider>
  );
}

export default observer(ChartWidget);

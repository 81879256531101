import { ICertificateModel } from './model';

export default (self: ICertificateModel) => ({
  get searchString() {
    const { name, description, serial } = self;
    return [name, description, serial].join(' ').toLowerCase();
  },
  get nodeIds() {
    return self.templates && self.templates[0]?.configuration?.node_id;
  }
});

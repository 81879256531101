import { styled } from '@mui/material/styles';
import { pxToRem } from '~/theme/typography';
import Link from '~/components/link';
import { Box } from '@mui/material';
import { transientOptions } from '~/utils/styled';

export const SeparatorLineStyled = styled(Box)(({ theme }) => ({
  width: 2,
  height: '1.5rem',
  borderRight: '2px solid',
  borderColor: theme.palette.text.disabled,
  margin: theme.spacing(0, 2)
}));

export const SeparatorCircleStyled = styled(Box)(({ theme }) => ({
  width: 4,
  height: 4,
  borderRadius: '50%',
  background: theme.palette.text.disabled,
  margin: theme.spacing(0, 2, 0.25, 2)
}));

export const LinkStyled = styled(
  Link,
  transientOptions
)(({ theme, $active }: { $active?: boolean }) => ({
  fontSize: pxToRem(16),
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.grey[900],
  fontWeight: theme.typography.fontWeightMedium,
  textDecoration: 'none',
  ...($active && {
    color: theme.palette.common.black,
    pointerEvents: 'none'
  }),
  '& > div': { display: 'inherit' }
}));

export const BreadcrumbsItemStyled = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center'
}));

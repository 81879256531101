import { FieldArrayRenderProps } from 'react-final-form-arrays';
import { Box, Grid, Tooltip, Card, IconButton } from '@mui/material';
import type { IAlert } from '~/mst/models/alert';
import useLocales from '~/hooks/use_locales';
import { CirclePlusSmallStyled, ButtonTextSmallStyled, ButtonContainerStyled } from './styled';
import Condition from './condition';
import type { ConditionType } from './data_point';

type ConditionsFieldProps = FieldArrayRenderProps<ConditionType, HTMLInputElement> & {
  model: IAlert;
};

function ConditionsField(props: ConditionsFieldProps) {
  const { fields, model } = props;
  const { t } = useLocales();

  return (
    <Card sx={{ width: '100%', py: 3, px: 2 }}>
      <Box sx={{ width: '100%' }}>
        {fields.map((name, index) => (
          <Condition name={name} key={name} model={model} fields={fields} index={index} />
        ))}
        <Grid container spacing={2} alignItems="center" sx={{ pt: 3 }}>
          <Grid item md={4} sm={12}>
            <ButtonContainerStyled
              data-testid="addAndConditionBtn"
              onClick={() => {
                fields.push({ deviceId: '', dataPointId: '', func: '' });
              }}
            >
              <Tooltip title={<span>{t('conditions.add_condition_or')}</span>}>
                <IconButton>
                  <CirclePlusSmallStyled />
                </IconButton>
              </Tooltip>
              <ButtonTextSmallStyled>{t('conditions.add_condition_or')}</ButtonTextSmallStyled>
            </ButtonContainerStyled>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
}

export default ConditionsField;

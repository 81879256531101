import { types as t, Instance } from 'mobx-state-tree';
import DateType from '~/mst/models/abstract/date_time';

export const CreatedAt = t.model({
  created_at: t.maybeNull(DateType),
  updated_at: t.maybeNull(DateType)
});

export interface ICreatedAt extends Instance<typeof CreatedAt> {}
export default CreatedAt;

import { Instance } from 'mobx-state-tree';

import model from './model';
import views from './views';
import CommonViews from '../views';
import actions from '../actions';

const Sensor = model.views(CommonViews).views(views).actions(actions);

export interface ISensor extends Instance<typeof Sensor> {}

export default Sensor;

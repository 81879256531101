import { useCallback } from 'react';
import { Stack, Chip } from '@mui/material';
import { observer } from 'mobx-react-lite';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { BodyModule } from '~/pages/device_edit/components';
import useModal from '~/hooks/use_modal';
import { INode } from '~/mst/models/node';
import AddUserCalibrationModal from './add_user_calibration_modal';

function UserCalibrationContainer({ node }: { node: INode }) {
  const { open, handleClose: handleModalClose, handleOpen: handleModalOpen } = useModal();
  const handleAddParameter = useCallback(
    (event) => {
      event.stopPropagation();
      handleModalOpen();
    },
    [handleModalOpen]
  );

  return (
    <BodyModule title="Calibrations" columns={1} expandable>
      <Stack direction="row" spacing={0} flexWrap="wrap" gap={1}>
        <Chip color="info" icon={<AddCircleOutlineIcon />} label="Create" onClick={handleAddParameter} />
        {node.thiamis?.user_calibration?.valueLabelPairs?.map(({ value, label, model }) => (
          <Chip key={value} color="info" label={label} variant="outlined" onDelete={() => model.destroy()} disabled={model.isSyncing} />
        ))}
      </Stack>
      <AddUserCalibrationModal open={open} onClose={handleModalClose} node={node} />
    </BodyModule>
  );
}

export default observer(UserCalibrationContainer);

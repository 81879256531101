/* eslint-disable react/jsx-props-no-spreading */
import { Box, SxProps } from '@mui/material';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  sx?: SxProps;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, sx = {}, ...other } = props;
  return (
    <Box hidden={value !== index} {...other}>
      {value === index && <Box sx={{ p: 3, ...sx }}>{children}</Box>}
    </Box>
  );
}

export default TabPanel;

import { observer } from 'mobx-react-lite';
import get from 'lodash/get';
import isString from 'lodash/isString';
import { Box, Stack } from '@mui/material';
import { useFormState } from 'react-final-form';

import type { IAlert } from '~/mst/models/alert';
import DevicesFunctionsSelect from './devices_functions_select';
import ParametersSelect from './parameters_select';

type ConditionFieldType = {
  name: string;
  model: IAlert;
};

function ParameterField(props: ConditionFieldType) {
  const { name, model } = props;
  const { values } = useFormState({ subscription: { values: true } });

  const { dataPointId } = get(values, name, {});

  return isString(dataPointId) ? (
    <Stack direction="column">
      <ParametersSelect condition={name} name="dataPointId" model={model} />
      <Box sx={{ pl: '5px' }}>
        <DevicesFunctionsSelect condition={name} name="deviceId" model={model} />
      </Box>
    </Stack>
  ) : (
    <Stack direction="column" spacing={0.5}>
      <DevicesFunctionsSelect condition={name} name="dataPointId.func" model={model} />
      <Box sx={{ pl: '15px' }}>
        {dataPointId?.func === 'diff' ? (
          <Stack direction="column" spacing={1}>
            <ParameterField name={`${name}.dataPointId`} model={model} />
            <ParameterField name={`${name}.dataPointId.dataPointId2`} model={model} />
          </Stack>
        ) : (
          <ParameterField name={`${name}.dataPointId`} model={model} />
        )}
      </Box>
    </Stack>
  );
}

export default observer(ParameterField);

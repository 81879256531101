import React, { useState } from 'react';
import { Typography, Stack, CircularProgress, Badge } from '@mui/material';
import MenuPopover from '~/components/menu_popover';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { observer } from 'mobx-react-lite';
import useMst from '~/hooks/use_mst';
import SharedLeasedStyled from '~/pages/devices/grid_container/shared_leased_formatter/styled';

export function DevicesPopover({ count, devices, title }: { count: number; devices?: any[]; title?: string }) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  return (
    <>
      <Badge
        onClick={handlePopoverOpen}
        sx={{ ...(devices && { cursor: 'pointer' }), px: 1 }}
        badgeContent={count}
        invisible={count < 1}
        color="primary"
      >
        {Boolean(count) && title && <SharedLeasedStyled>Shared</SharedLeasedStyled>}
      </Badge>

      {devices && (
        <MenuPopover
          open={open}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          disableRestoreFocus
          arrow="left-center"
          transformOrigin={{ vertical: 'center', horizontal: 'left' }}
          anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
        >
          <Stack spacing={1.5} sx={{ p: 1 }}>
            {devices.map(({ total, name }) => (
              <Stack direction="row" justifyContent="space-between" sx={{ borderBottom: '1px dashed #ccc' }}>
                <Typography>{name}</Typography>
                <Typography>{total}</Typography>
              </Stack>
            ))}
          </Stack>
        </MenuPopover>
      )}
    </>
  );
}

function DevicesFormatter({ row, value }: DataTypeProvider.ValueFormatterProps) {
  const { id } = row;
  const { organizations } = useMst();
  const organization = organizations.getById(id);

  if (organization?.stats.isSyncing) {
    return <CircularProgress size={15} color="info" />;
  }

  return <DevicesPopover count={value} devices={organization?.stats?.devicesTotalByType} />;
}

export default observer(DevicesFormatter);

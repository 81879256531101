import { Theme } from '@mui/material/styles';

export default function Container(theme: Theme) {
  return {
    MuiContainer: {
      styleOverrides: {
        root: {
          padding: theme.spacing(0, 5),
          marginTop: theme.spacing(3),
          marginBottom: theme.spacing(3)
        }
      }
    }
  };
}

import { INode } from '~/mst/models/node';
import StatusIcon from './StatusIcon';
import DeviceIcon from './DeviceIcon';

interface IDeviceIndicatorProps {
  infoWindowId: string;
  node: INode;
  isShowTitle: boolean;
}

function DeviceIndicator(props: IDeviceIndicatorProps) {
  const { infoWindowId, node, isShowTitle } = props;
  const { name, thiamis, serial, statusType, statusText } = node || {};
  return (
    <div id={infoWindowId} className="widget-map_popover_content">
      {!!isShowTitle && <div className="widget-map_popover_title">{name}</div>}
      <div
        className="widget-map_popover_details__container"
        style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', columnGap: '20px' }}
      >
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', columnGap: '10px' }}>
          <div>
            <DeviceIcon node={node} />
          </div>
          <div style={{ marginTop: '8px' }}>
            <StatusIcon statusType={statusType} label={statusText} />
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
          <div className="widget-map_popover_model">{thiamis?.modelName}</div>
          <div>{serial}</div>
        </div>
      </div>
    </div>
  );
}

export default DeviceIndicator;

/* eslint-disable @typescript-eslint/no-use-before-define */
import { types as t, Instance } from 'mobx-state-tree';
import Device from '~/mst/models/device/model';
import BitConfigType from '~/mst/common/bit_config_type';

const SensorModel = t.compose(
  Device,
  t.model({
    debug: t.maybeNull(BitConfigType),
    enabled: t.maybeNull(BitConfigType),
    tag: t.frozen(t.string),
    extra_configuration: t.frozen()
  })
);

export interface ISensorModel extends Instance<typeof SensorModel> {}

export default SensorModel;

/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import { observer } from 'mobx-react-lite';
import WidgetContainer from '~/components/widgets/common';
import { WidgetPropType } from '~/components/widgets/common/types';
import useModal from '~/hooks/use_modal';
import { IHeatmapWidget } from '~/mst/models/widget/heatmap_widget';
import HeatmapWidgetContent from './content';
import HeatmapWidgetConfiguration from './configuration';
import HeatmapWidgetFooter from './content/footer';
import HeatmapWidgetTitle from './content/title';

function HeatmapWidget({ widget, moveable, dashboard, editable }: WidgetPropType<IHeatmapWidget>) {
  const { open, handleOpen, handleClose } = useModal();
  return (
    <WidgetContainer
      widget={widget}
      moveable={moveable}
      editable={editable}
      onEdit={handleOpen}
      dashboard={dashboard}
      Header={<HeatmapWidgetTitle widget={widget} />}
      Footer={HeatmapWidgetFooter}
    >
      <HeatmapWidgetContent widget={widget} dashboard={dashboard} />
      {open && <HeatmapWidgetConfiguration open={open} onClose={handleClose} widget={widget} />}
    </WidgetContainer>
  );
}

export default observer(HeatmapWidget);

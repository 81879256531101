/* eslint-disable no-nested-ternary */
import { getSnapshot } from 'mobx-state-tree';
import defaults from 'lodash/defaults';
import get from 'lodash/get';
import omitBy from 'lodash/omitBy';
import isNull from 'lodash/isNull';
import { DateTime } from 'luxon';

import I18n from '~/utils/i18n';

import { ISensorModel } from './model';

export function getConfiguration(self: ISensorModel) {
  return defaults({}, omitBy(getSnapshot(self)!, isNull), self.configuration, get(self.extra_configuration, self.model, {}));
}

export default (self: ISensorModel) => {
  function parseConnection() {
    return self.connection.split('.');
  }
  return {
    get model() {
      return self.getDataPointByName('model')?.lastValue || self.name;
    },
    get formValues() {
      return getConfiguration(self);
    },
    get firmware() {
      return self.getDataPointByName('firmware')?.lastValue;
    },
    get serial() {
      return self.getDataPointByName('serial')?.lastValue;
    },
    get comPort() {
      return get(parseConnection(), '[1]');
    },
    get comType() {
      return get(parseConnection(), '[2]');
    },
    get address() {
      return get(parseConnection(), '[3]');
    },
    get status() {
      return self.getDataPointByName('status')?.lastValue;
    },
    statusType() {
      const now = DateTime.now().toUTC().toMillis();
      if (self.hasError) {
        return 'error';
      }
      if (now - self.lastTs <= self.node?.extraReportingInterval) {
        return 'online';
      }
      return 'offline';
    },
    statusText() {
      const status = self.statusType();
      if (self.hasError) {
        return self.displayErrors.map((dp) => dp.name).join('\n');
      }
      return I18n.t(`devices.statuses.${status}`, { defaultValue: I18n.t('devices.statuses.empty') });
    },
    parseFormValues(values) {
      return values;
    },
    get modelName() {
      return self.model || self.name;
    }
  };
};

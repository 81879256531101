import { Instance, SnapshotOut } from 'mobx-state-tree';
import model from './model';
import views from './views';
import actions from './actions';

const Device = model.views(views).actions(actions);

export interface IDevice extends Instance<typeof Device> {}
export interface IDeviceSnapshot extends SnapshotOut<typeof Device> {}

export default Device;

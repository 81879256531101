import { types as t, Instance, SnapshotOut, flow, applySnapshot, getIdentifier, getParent } from 'mobx-state-tree';
import Fetchable from '~/mst/models/abstract/fetchable';
import api from '~/api';

const DeviceDefaultWifiModel = t
  .compose(
    t.model({
      wifi_ssid: t.maybeNull(t.string),
      wifi_password: t.maybeNull(t.string)
    }),
    Fetchable({ cache: true })
  )
  .actions((self) => ({
    fetch: flow(function* fetch() {
      try {
        self.startFetching();
        const { data } = yield self.sync(api.nodes.getDefaultWifi, getIdentifier(getParent(self)));
        applySnapshot(self, data);
        self.finishFetching();
      } catch (error) {
        self.failFetching(error);
      }
    })
  }));

export interface IDeviceDefaultWifiModel extends Instance<typeof DeviceDefaultWifiModel> {}
export interface IDeviceDefaultWifiModelSnapshotOut extends SnapshotOut<typeof DeviceDefaultWifiModel> {}

export default DeviceDefaultWifiModel;

/* eslint-disable no-param-reassign */
import round from 'lodash/round';
import get from 'lodash/get';
import I18n from '~/utils/i18n';
import type { ICalibrationMeasurementModel } from './model';
import { getStatus } from '../views';

export default (self: ICalibrationMeasurementModel) => {
  return {
    get status() {
      return getStatus(self);
    },
    get statusText() {
      return I18n.t(`attributes.calibration.statuses.${self.status}`);
    },
    get referenceDevice() {
      return `[${self.reference.serial}] ${self.reference.sensor}`;
    },
    get metricsText() {
      return Object.entries(get(self, 'metrics', {}))
        .map(([key, value]) => `${key}: ${round(value, 2)}`)
        .join(', ');
    }
  };
};

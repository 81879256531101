import { observer } from 'mobx-react-lite';
import { Box, CardContent } from '@mui/material';
import { Field as FinalField, useFormState } from 'react-final-form';

import FormGrid from '~/components/@material-extend/form_grid';
import Switch from '~/components/final_form/switch';
import Slider from '~/components/final_form/slider';
import useLocales from '~/hooks/use_locales';
import DevicesSelect from '~/components/devices_select';
import FormContainer from '~/pages/device_edit/containers/form_container';
import useMst from '~/hooks/use_mst';
import { ISyncable } from '~/mst/models/abstract/syncable';

import FormScheduler from './scheduler';

type FormType = {
  handleSubmit: VoidFunction;
  model: ISyncable;
  shedulerRef: HTMLInputElement;
};
const marks = [
  {
    value: 0,
    label: 'Off'
  },
  {
    value: 25,
    label: '25%'
  },
  {
    value: 50,
    label: '50%'
  },
  {
    value: 75,
    label: '75%'
  },
  {
    value: 100,
    label: '100%'
  }
];
function AqmsForm({ handleSubmit, model, shedulerRef }: FormType) {
  const { t } = useLocales();
  const { nodes } = useMst();

  const {
    values: { useSheduler }
  } = useFormState({ subscription: { values: true } });

  return (
    <FormContainer title={t('thiamis.aqms.title')} handleSubmit={handleSubmit} model={model}>
      <CardContent>
        <Box>
          <FormGrid columns="1">
            <Box>
              <DevicesSelect
                label={t('thiamis.aqms.devices')}
                multiple
                name="nodes"
                options={nodes?.airthinxValueLabelPairs}
                loading={nodes?.isSyncing}
              />
            </Box>
            <FormGrid columns="3">
              <Box>
                <Switch label={t('thiamis.aqms.automation')} name="automation" type="number" />
              </Box>
              <Box>
                <Switch label={t('thiamis.aqms.cron')} name="useSheduler" />
              </Box>
              <Box>
                <Switch label={t('thiamis.aqms.dfs')} name="dfs" type="number" />
              </Box>
            </FormGrid>
            <Box>
              <Slider label={t('thiamis.aqms.speed')} name="speed" slider={{ step: null, marks, valueLabelDisplay: 'on' }} />
            </Box>
            {useSheduler && <FinalField name="cron" ref={shedulerRef} component={FormScheduler} />}
          </FormGrid>
        </Box>
      </CardContent>
    </FormContainer>
  );
}

export default observer(AqmsForm);

/* eslint-disable @typescript-eslint/naming-convention */
import pick from 'lodash/pick';
import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';
import kebabCase from 'lodash/kebabCase';
import flatten from 'lodash/flatten';
import { getStore } from '~/mst/utils';
import { formatDateFromTs } from '~/utils/format_date';
import { IArchiveModel } from './model';

export default (self: IArchiveModel) => ({
  get searchString() {
    const searchFields = ['serial', 'organizationName'];
    return flatten(Object.values(pick(self, searchFields)))
      .join(' ')
      .toLowerCase();
  },
  get organizationName() {
    if (isEmpty(self.oid)) {
      return '';
    }
    return compact(self.oid.map((id) => getStore(self).organizations.getById(id)?.name)).join(', ');
  },
  get filename() {
    const { from, to, serial } = self;
    const period = `${formatDateFromTs(from)}_${formatDateFromTs(to)}`;
    return `${serial}_${kebabCase(period)}.csv`;
  }
});

/* eslint-disable import/prefer-default-export */
import { Box, styled, Paper } from '@mui/material';
import { transientOptions } from '~/utils/styled';

export const AddPlaceholder = styled(Box)(({ theme }) => ({
  border: `1px dashed ${theme.palette.primary.light}`,
  cursor: 'pointer',
  textTransform: 'capitalize',
  fontSize: 20,
  color: theme.palette.primary.light,
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center'
}));

export const DashboardItem = styled(
  Paper,
  transientOptions
)(({ $isSyncing }) => ({
  minHeight: 240,
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  ...($isSyncing && { opacity: 0.6 })
}));

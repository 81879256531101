import { Chip } from '@mui/material';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { CALIBRATION_STATUSES } from '~/utils/constants';
import DoneIconStyled from './styled';

export default function StatusFormatter(props: DataTypeProvider.ValueFormatterProps) {
  const {
    value,
    row: { statusText }
  } = props;

  return <Chip icon={value === CALIBRATION_STATUSES.COMPLETED ? <DoneIconStyled /> : <ErrorOutlineIcon color="error" />} label={statusText} />;
}

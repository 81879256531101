/* eslint-disable react/jsx-props-no-spreading */
import { forwardRef } from 'react';
import { Link as RouterLink, RouteProps } from 'react-router-dom';
import useSlug from '~/hooks/use_slug';

type LinkProps = {
  to: string;
  children: RouteProps['children'];
};

// eslint-disable-next-line  @typescript-eslint/no-unused-vars
const Link = forwardRef<HTMLDivElement, LinkProps>((props, ref) => {
  const { slug } = useSlug();
  const { to } = props;
  return <RouterLink {...props} to={`/${slug}${to}`} />;
});

export default Link;

import { Typography } from '@mui/material';

import { SelectionProvider } from '~/hooks/react_grid/use_selection';
import { ExportProvider } from '~/hooks/react_grid/use_export';
import useMst from '~/hooks/use_mst';
import useFetch from '~/hooks/use_fetch';
import { ContainerGridStyled } from '~/components/react_grid/styled';
import Container from '~/components/container';
import useLocales from '~/hooks/use_locales';

import GridContainer from './grid_container';
import FormTop from './form_top';

function CalibrationsScreen() {
  const { calibrations, nodes } = useMst();
  const { t } = useLocales();

  useFetch(calibrations);
  useFetch(nodes, null, { excludeParams: ['organization_id'] });

  return (
    <SelectionProvider>
      <ExportProvider>
        <Container>
          <Typography variant="h4" component="h1" paragraph>
            {t('calibrations.title')}
          </Typography>
          <ContainerGridStyled>
            <FormTop />
            <GridContainer />
          </ContainerGridStyled>
        </Container>
      </ExportProvider>
    </SelectionProvider>
  );
}

export default CalibrationsScreen;

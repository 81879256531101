/* eslint-disable import/prefer-default-export */
import request from '../request';

export function read(id: string) {
  return request('get', `nodes/${id}`);
}

export function update(id, payload: any) {
  return request('put', `nodes/${id}`, payload);
}

export function create(payload: any) {
  return request('post', 'nodes', payload);
}

export function activate(id) {
  return request('put', `nodes/${id}/activate`);
}

export function deactivate(id) {
  return request('put', `nodes/${id}/deactivate`);
}

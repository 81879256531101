/* eslint-disable react/jsx-props-no-spreading */
import { Field as FinalField } from 'react-final-form';
import { FormHelperText, FormControl, FormLabel, Stack } from '@mui/material';
import type { SliderProps } from '@mui/material/Slider';
import isString from 'lodash/isString';
import { SliderInputStyled, SliderWrapperStyled, StyledliderStyled } from './styled';

interface Props {
  name: string;
  label?: string | Element;
  slider?: SliderProps;
  showInput?: boolean;
}

export default function RFFSlider(props: Props) {
  const { name, label, slider = {}, showInput = false, ...rest } = props;

  return (
    <FinalField name={name}>
      {({ input, meta }) => {
        const hasError = meta.touched && meta.error;
        return (
          <SliderWrapperStyled>
            <FormControl error={Boolean(hasError)} fullWidth {...rest}>
              {label && isString(label) ? <FormLabel>{label}</FormLabel> : label}
              <Stack spacing={0} alignItems="center">
                <StyledliderStyled {...input} {...slider} aria-labelledby="input-slider" />
                {showInput && (
                  <SliderInputStyled
                    {...input}
                    type="number"
                    size="small"
                    variant="standard"
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                )}
              </Stack>
              <FormHelperText>{hasError}</FormHelperText>
            </FormControl>
          </SliderWrapperStyled>
        );
      }}
    </FinalField>
  );
}

// export default forwardRef(phoneInput);

/* eslint-disable no-param-reassign */
import { types as t, Instance } from 'mobx-state-tree';
import snakeCase from 'lodash/snakeCase';
import Syncable from '~/mst/models/abstract/syncable';
import CollectionArray from '~/mst/models/abstract/collection_array';
import Thiamis from '~/mst/models/device/thiamis';
import Airthinx from '~/mst/models/device/airthinx';
import Healthway from '~/mst/models/device/healthway';
import Sensors from '~/mst/models/device/sensors';
import { isAirthinxDevice, isThiamisDevice, isHealthwayDevice } from '~/mst/models/device/utils';

const DevicesModel = t.compose(
  CollectionArray(
    t.union({
      dispatcher(snapshot) {
        const { _id: id, name } = snapshot;
        if (isThiamisDevice(id)) {
          if (isAirthinxDevice(id)) {
            return Airthinx;
          }
          if (isHealthwayDevice(id)) {
            return Healthway;
          }
          return Thiamis;
        }
        switch (snakeCase(name)) {
          case 'adam':
            return Sensors.Adam;
          case 'airmar':
            return Sensors.Airmar;
          case 'davis':
            return Sensors.Davis;
          case 'dust_trak':
            return Sensors.DustTrak;
          case 'gm_460':
            return Sensors.GM460;
          case 'gx_6000':
            return Sensors.GX6000;
          case 'particles_plus':
            return Sensors.ParticlesPlus;
          case 'sound_pro':
            return Sensors.SoundPro;
          case 'ysi':
            return Sensors.YSI;
          case 'data_ram':
            return Sensors.DataRam;
          case 'alpha_sense':
            return Sensors.AlphaSense;
          default:
            return Sensors.Sensor;
        }
      }
    })
  ),
  Syncable
);

export interface IDevicesModel extends Instance<typeof DevicesModel> {}

export default DevicesModel;

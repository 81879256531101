import { styled } from '@mui/material/styles';
import { Card, CardActions } from '@mui/material';

type Sizes = 'sm' | 'md' | 'lg';

type StyledProps = {
  size?: Sizes;
};

export const ModalStyled = styled(Card)<StyledProps>(({ theme, size = 'md' }) => ({
  top: '50%',
  left: 0,
  right: 0,
  transform: 'translateY(-50%)',
  zIndex: 1999,
  outline: 'none',
  display: 'flex',
  position: 'fixed',
  flexDirection: 'column',
  margin: 'auto',
  maxWidth: '100%',
  ...(size === 'sm' && {
    width: 400,
    [theme.breakpoints.down('md')]: {
      width: `calc(100% - ${theme.spacing(6)})`
    }
  }),
  ...(size === 'md' && {
    width: 600,
    [theme.breakpoints.down('md')]: {
      width: `calc(100% - ${theme.spacing(6)})`
    }
  }),
  ...(size === 'lg' && {
    width: `calc(100% - ${theme.spacing(6)})`
  })
}));

export const ModalActionsStyled = styled(CardActions)(() => ({
  borderTop: '1px solid #ececec'
}));

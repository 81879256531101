import { useContext, useEffect } from 'react';
import { SubscribableContext } from '~/contexts/subscribable_context';
import socket from '~/services/socket';

const useMstSubscribable = (callabck: (dataPoints: any[]) => void) => {
  const context = useContext(SubscribableContext);

  useEffect(() => {
    const subscription = socket.on('measurements')!.subscribe(callabck);
    return () => {
      subscription?.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  return context;
};

export default useMstSubscribable;

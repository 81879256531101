import { Instance, SnapshotOut } from 'mobx-state-tree';
import CollectionMapProcessor from '~/mst/models/abstract/collection_map_processor';
import model from './model';
import views from './views';
import actions from './actions';

const TemplatesStore = model.views(views).actions(actions);

export interface ITemplatesStore extends Instance<typeof TemplatesStore> {}
export interface ITemplatesStoreSnapshot extends SnapshotOut<typeof TemplatesStore> {}

export default CollectionMapProcessor(TemplatesStore);

import React, { useCallback, useMemo } from 'react';
import { getSnapshot } from 'mobx-state-tree';
import Yup, { searchableSelectSchema } from '~/utils/yup';
import { computed } from 'mobx';
import pick from 'lodash/pick';
import { Form as FinalForm } from 'react-final-form';
import { datadogRum } from '@datadog/browser-rum';

import { useValidationSchema } from '~/hooks/use_validate';
import InvitationModel from '~/mst/models/invitation';
import useMst from '~/hooks/use_mst';
import toasts from '~/utils/toasts';
import useLocales from '~/hooks/use_locales';

import { captureException } from '~/services/sentry';
import Form from './form';

const InvitationFormSchema = Yup.object().shape({
  organization_id: searchableSelectSchema('Organization is required'),
  role: searchableSelectSchema('Role is required'),
  email: Yup.string().email().required('Email is required').typeError('Email is required')
});

function InviteUserForm() {
  const { t } = useLocales();
  const { invitations, organizations } = useMst();
  const model = InvitationModel.create({});
  const validate = useValidationSchema(InvitationFormSchema);

  const handleFormSubmit = useCallback(
    async (values) => {
      try {
        const payload = {
          ...values,
          external_id: organizations.getById(values.organization_id)?.external_id
        };
        await invitations.create(payload, model);
        toasts.success(t('notifications.success.forms.user_invitations'));
        datadogRum.addAction('create_user_invitation', payload);
        invitations.fetch();
      } catch (e) {
        captureException(e);
        toasts.error(t('notifications.errors.server_error'));
      }
    },
    [invitations, organizations, t, model]
  );

  const initialValues = useMemo(() => computed(() => pick(getSnapshot(model), ['role', 'email', 'organization_id'])), [model]).get();

  return (
    <FinalForm
      validate={validate}
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      subscription={{ submitting: true, pristine: true, dirty: true }}
      render={({ handleSubmit }) => (
        <Form submitting={model.isSyncing} handleSubmit={handleSubmit} organizations={organizations.valueLabelPairsManagerAccess} />
      )}
    />
  );
}

export default InviteUserForm;

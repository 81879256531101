import { types as t, Instance, SnapshotOut } from 'mobx-state-tree';
import WidgetModel from '~/mst/models/widget';
import NodesStore from '~/mst/models/nodes';

const NodeWidgetModel = t
  .compose(
    WidgetModel,
    t.model({
      type: 'NodeWidget',
      name: t.maybeNull(t.string),
      filter: t.maybeNull(t.string),
      node_id: t.union(t.string, t.array(t.string)),
      selected_data_point_ids: t.array(t.string),
      nodes: t.maybeNull(NodesStore)
    })
  )
  .named('NodeWidget');

export interface INodeWidgetModel extends Instance<typeof NodeWidgetModel> {}
export interface INodeWidgetModelSnapshotOut extends SnapshotOut<typeof NodeWidgetModel> {}

export default NodeWidgetModel;

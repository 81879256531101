/* eslint-disable import/prefer-default-export */
export const defaultChartOptions = {
  title: {
    text: null
  },
  chart: {
    type: 'column',
    marginTop: 20,
    marginRight: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.1)'
  },
  colors: ['#71B6E1', '#476FA6', '#81BCB9', '#419DA6', '#909ED0', '#69598E'],
  plotOptions: {
    series: {
      dataLabels: {
        enabled: false
      },
      stacking: 'normal',
      pointWidth: 15,
      pointPadding: 0.3
    }
  },
  credits: {
    enabled: false
  },
  exporting: {
    enabled: false
  },
  tooltip: {
    xDateFormat: '%Y-%m-%d %H:%M'
  },
  xAxis: {
    type: 'datetime',
    tickInterval: 3 * 3600 * 1000,
    tickWidth: 2
  },
  yAxis: {
    allowDecimals: false,
    title: {
      enabled: false
    }
  }
};

import light from './light';
import dark from './dark';

const airthinxConfig = {
  name: 'default',
  palette: {
    light: {
      ...light
    },
    dark: {
      ...dark
    }
  }
};

export default airthinxConfig;

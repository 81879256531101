import React from 'react';
import capitalize from 'lodash/capitalize';
import useLocales from '~/hooks/use_locales';

function RoleFormatter({ value }: { value: string }) {
  const { t } = useLocales();

  return <span>{capitalize(t(`users.roles.${value}`))}</span>;
}

export default RoleFormatter;

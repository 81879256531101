import { Instance } from 'mobx-state-tree';
import CollectionMapProcessor from '~/mst/models/abstract/collection_map_processor';
import model from './model';
import views from './views';
import actions from './actions';

const WidgetsStore = model.views(views).actions(actions);

export interface IWidgetsStore extends Instance<typeof WidgetsStore> {}

export default CollectionMapProcessor(WidgetsStore);

import { Instance, SnapshotOut } from 'mobx-state-tree';
import model from './model';
import actions from './actions';
import views from './views';

export const ReportModel = model.views(views).actions(actions);

export interface IReport extends Instance<typeof ReportModel> {}
export interface IReportOut extends SnapshotOut<typeof ReportModel> {}

export default ReportModel;

import { types as t, Instance, SnapshotOut } from 'mobx-state-tree';
import WidgetModel from '~/mst/models/widget';
import Nodes from '~/mst/models/nodes';

const HeatmapWidgetModel = t
  .compose(
    WidgetModel,
    t.model({
      type: 'HeatmapWidget',
      node_id: t.array(t.string),
      name: t.maybeNull(t.string),
      selected_path: t.maybeNull(t.string),
      data_point_id: t.array(t.string),
      filter: t.maybeNull(t.string),
      nodes: t.maybeNull(Nodes)
    })
  )
  .named('HeatmapWidget');

export interface IHeatmapWidgetModel extends Instance<typeof HeatmapWidgetModel> {}
export interface IHeatmapWidgetModelSnapshotOut extends SnapshotOut<typeof HeatmapWidgetModel> {}

export default HeatmapWidgetModel;

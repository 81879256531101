import { useEffect } from 'react';
import { Card, CardContent } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { observable, action } from 'mobx';
import { useFormState } from 'react-final-form';

import useLocales from '~/hooks/use_locales';
import useFetch from '~/hooks/use_fetch';
import TextInput from '~/components/final_form/text_input';
import FormGrid from '~/components/@material-extend/form_grid';
import useMst from '~/hooks/use_mst';
import type { IAlert } from '~/mst/models/alert';
import OrganizationSelect from '~/components/organizations_select';
import MCardHeader from '~/components/@material-extend/MCardHeader';
import { HeaderDeviceWrapperStyled } from '~/pages/device_edit/components/styled';

type DetailsBlockType = {
  model: IAlert;
};

const params = observable(
  {
    organization_id: null,
    setOrganizationId(value) {
      this.organization_id = value;
    }
  },
  {
    setOrganizationId: action
  }
);

function DetailsBlock({ model }: DetailsBlockType) {
  const { t } = useLocales();
  const {
    values: { organization_id: organizationId }
  } = useFormState({ subscription: { values: true } });
  const { organizations } = useMst();

  useEffect(() => params.setOrganizationId(organizationId), [organizationId]);

  useFetch(model, params);

  return (
    <Card>
      <HeaderDeviceWrapperStyled>
        <MCardHeader title="Basic Info" />
      </HeaderDeviceWrapperStyled>
      <CardContent>
        <FormGrid columns="2" sx={{ p: 0 }}>
          <TextInput name="name" label={t('attributes.alert.name')} data-testid="alertNameInput" />
          <OrganizationSelect
            data-testid="alertOwnerSelect"
            disabled={!model.isNew}
            options={organizations.valueLabelPairsManagerAccess}
            name="organization_id"
            label={t('attributes.alert.organization_id')}
          />
        </FormGrid>
      </CardContent>
    </Card>
  );
}

export default observer(DetailsBlock);

/* eslint-disable no-prototype-builtins */
import { observer } from 'mobx-react-lite';

import { BodyModule } from '~/pages/device_edit/components';
import { FormLabelStyled } from '~/pages/device_edit/components/styled';
import Radio from '~/components/final_form/switch';
import useLocales from '~/hooks/use_locales';
import SwitchFields from '../sensor/common/switch_fields';

import { SensorProperty } from '../components';
import { FlowCalibration } from '../dust_trak/sections';

function DataRam() {
  const { t } = useLocales();
  return (
    <BodyModule title={t('device_monitor.forms.settings')} columns={1} expandable expanded>
      <SwitchFields>
        <SensorProperty name="auto_zero_enabled">
          <Radio label={<FormLabelStyled>Auto Zero</FormLabelStyled>} name="auto_zero_enabled" row />
        </SensorProperty>
      </SwitchFields>
      <SensorProperty name="flow_calibration">
        <FlowCalibration
          slider={{
            marks: [{ value: 0.5 }, { value: 3.5 }],
            min: 0.5,
            max: 3.5,
            step: 0.01
          }}
        />
      </SensorProperty>
    </BodyModule>
  );
}

export default observer(DataRam);

/* eslint-disable react/jsx-props-no-spreading */
import { SortingState, RowDetailState, IntegratedSorting } from '@devexpress/dx-react-grid';
import { Grid, VirtualTable, TableHeaderRow, TableRowDetail } from '@devexpress/dx-react-grid-material-ui';
import { observer } from 'mobx-react-lite';
import { GridStyled } from '~/components/react_grid/styled';
import { Container } from '~/components/react_grid';

import I18n from '~/utils/i18n';
import useExpandRow from '~/hooks/react_grid/use_expand_row';
import useLoading from '~/hooks/react_grid/use_loading';
import useSorting from '~/hooks/react_grid/use_sorting';
import useMst from '~/hooks/use_mst';
import { ICertificateModelSnapshotOut } from '~/mst/models/certificate/model';

import { SerialProvider, ActionsProvider } from './info_providers';
import GridDetailContainer from './detail_row';

const columns = [
  { name: 'serial', title: I18n.t('attributes.node.serial') },
  { name: 'name', title: I18n.t('attributes.node.name') },
  { name: 'description', title: I18n.t('attributes.node.description') },
  { name: 'actions', title: ' ', sortingEnabled: false }
];

const getRowId = (row: ICertificateModelSnapshotOut): string => row._id;

function GridContainer() {
  const { certificates } = useMst();
  const { messages, RootComponent } = useLoading(certificates);
  const { sorting, handleSortingChange } = useSorting([{ columnName: 'serial', direction: 'desc' }]);
  const { expandedRowIds, handleSetExpandedRowIds, Row } = useExpandRow();

  return (
    <GridStyled>
      <Grid rows={certificates.filteredList} columns={columns} getRowId={getRowId} rootComponent={RootComponent}>
        <SortingState sorting={sorting} onSortingChange={handleSortingChange} />
        <IntegratedSorting />
        <VirtualTable
          height="auto"
          messages={messages}
          containerComponent={Container}
          columnExtensions={[{ columnName: 'actions', width: 65 }]}
          rowComponent={Row}
        />
        <SerialProvider for={['serial']} />
        <ActionsProvider for={['actions']} />
        <RowDetailState expandedRowIds={expandedRowIds} onExpandedRowIdsChange={handleSetExpandedRowIds} />
        <TableRowDetail rowComponent={GridDetailContainer} />
        <TableHeaderRow showSortingControls />
      </Grid>
    </GridStyled>
  );
}

export default observer(GridContainer);

import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import isEmpty from 'lodash/isEmpty';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Grid, Box, Typography, CardActions, SxProps, Stack, CircularProgress } from '@mui/material';
import StatusIcon from '~/components/status_icon';
import FormGrid from '~/components/@material-extend/form_grid';
import { StatusType } from '~/components/status_icon/options';
import I18n from '~/utils/i18n';
import { ISensor } from '~/mst/models/device/sensor';
import useSvgLoader from '~/hooks/use_svg_loader';
import useLocales from '~/hooks/use_locales';
import IconStyled from '~/components/sensor_icon/styled';
import { INode } from '~/mst/models/node';
import DeviceSignalContainer from '~/components/device_signal';
import useMst from '~/hooks/use_mst';
import DevicePower from '~/components/device_power';
import { observer } from 'mobx-react-lite';

import { StatusIconWithAutoUpdate } from '~/components/widgets/node_widget/content/name_formatter';
import {
  BodyColumnStyled,
  BodyModuleWrapperStyled,
  BodyWrapperStyled,
  ButtonStyled,
  ExpandButtonStyled,
  HeaderGridIconWrapperStyled,
  HeaderInputWrapperStyled,
  HeaderModuleStyled,
  HeaderModuleWrapperStyled,
  HeaderSensorWrapperStyled,
  HeaderWrapperStyled,
  ItemValueStyled,
  ModuleTitleStyled,
  StackWrapperStyled,
  TitleStyled
} from './styled';

export function BodySection({ expandable, isExpanded, children }: { expandable?: boolean; isExpanded?: boolean; children: React.ReactElement }) {
  return (
    <BodyWrapperStyled expandable={expandable ? 1 : 0} expanded={isExpanded ? 1 : 0} className="BodySection">
      {children}
    </BodyWrapperStyled>
  );
}

export function Header({ title, tag, statusType, statusText }: { title: string; tag?: string; statusType?: StatusType; statusText?: string }) {
  const theme = useTheme();
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {statusType && (
        <StatusIcon
          size="medium"
          statusType={statusType}
          statusText={statusText}
          sx={{ paddingRight: theme.spacing(0.5), marginTop: theme.spacing(0) }}
        />
      )}
      <Box>
        {title}{' '}
        {tag && (
          <Typography variant="body3" color="grey.500">
            {tag}
          </Typography>
        )}
      </Box>
    </Box>
  );
}

export function ItemTitle({ children, sx }: { children: React.ReactElement | string; sx?: SxProps }) {
  return (
    <Box sx={{ ...sx, whiteSpace: 'break-spaces' }}>
      <TitleStyled color="secondary.muted" component="div">
        {children}
      </TitleStyled>
    </Box>
  );
}

export const HeaderModule = observer(
  ({
    node,
    icon,
    children,
    iconMaxWidth,
    sx
  }: {
    node: INode;
    icon: string;
    sx?: SxProps;
    iconMaxWidth?: string;
    children?: React.ReactElement | any;
  }) => {
    const SvgIcon = useSvgLoader(icon, 'DefaultDevice');

    return (
      <HeaderModuleWrapperStyled sx={sx}>
        {node?.isFetching ? (
          <Stack alignItems="center" my={3}>
            <CircularProgress size={25} color="info" />
          </Stack>
        ) : (
          <Grid container rowGap={2}>
            <HeaderGridIconWrapperStyled item xs={12} md={3} lg={3} xl={3}>
              <IconStyled $iconMaxWidth={iconMaxWidth} $width={100} $height={90}>
                <SvgIcon />
              </IconStyled>
              <StatusIconWithAutoUpdate model={node} showAQStatus={false} label={node?.statusText({ showAQStatus: false })} size="xsmall" />
            </HeaderGridIconWrapperStyled>
            <Grid item xs={12} md={9} lg={9} xl={9} mb={1}>
              <HeaderInputWrapperStyled>{children}</HeaderInputWrapperStyled>
            </Grid>
          </Grid>
        )}
      </HeaderModuleWrapperStyled>
    );
  }
);

export function BodyModule({
  title,
  children,
  columns,
  expandable,
  expanded = false
}: {
  title?: string;
  children: React.ReactElement | React.ReactElement[];
  columns: number;
  expandable?: boolean;
  expanded?: boolean;
}) {
  const [isExpanded, setIsExpanded] = useState(expanded);

  if (isEmpty(children)) {
    return null;
  }

  return (
    <BodyModuleWrapperStyled container spacing={0}>
      <Grid item xs={12} md={12} lg={12} xl={3}>
        <HeaderWrapperStyled className="BodyTitle">
          <StackWrapperStyled direction="row" alignItems="center">
            {title && (
              <ModuleTitleStyled variant="overcaption" color="grey.500">
                {title}
              </ModuleTitleStyled>
            )}
            {expandable && (
              <ExpandButtonStyled variant="text" onClick={() => setIsExpanded(!isExpanded)}>
                <KeyboardArrowDownIcon sx={{ transform: `${isExpanded ? 'rotateX(180deg)' : ''}` }} />
              </ExpandButtonStyled>
            )}
          </StackWrapperStyled>
        </HeaderWrapperStyled>
      </Grid>
      <Grid item xs={12} md={12} lg={12} xl={9}>
        <BodySection expandable={expandable} isExpanded={isExpanded}>
          <BodyColumnStyled columns={columns}>{children}</BodyColumnStyled>
        </BodySection>
      </Grid>
    </BodyModuleWrapperStyled>
  );
}

export const HeaderCommon = observer(({ node, icon, iconMaxWidth }: { node: INode; icon: string; iconMaxWidth?: string }) => {
  const { t } = useLocales();
  const { organizations } = useMst();

  return (
    <HeaderModule node={node} icon={icon} iconMaxWidth={iconMaxWidth}>
      <HeaderModuleStyled>
        <FormGrid columns={2} columnGap={1} rowGap={1}>
          {Boolean(node?.thiamis?.modelName) && (
            <Box>
              <ItemTitle>{t('device_monitor.forms.model')}</ItemTitle>
              <ItemValueStyled>{node?.thiamis?.modelName}</ItemValueStyled>
            </Box>
          )}
          <Box>
            <ItemTitle>{t('device_monitor.forms.serial_number')}</ItemTitle>
            <ItemValueStyled>{node?.serial}</ItemValueStyled>
          </Box>
          <Box>
            <ItemTitle>{t('attributes.thiamis.battery')}</ItemTitle>
            <DevicePower iconLeft batteryLevel={node?.batteryLevel} isAirthinx={node?.isAirthinx} />
          </Box>
          {Boolean(organizations.getById(node?.organization_id)?.name) && (
            <Box>
              <ItemTitle>{t('device_monitor.forms.owner')}</ItemTitle>
              <ItemValueStyled>{organizations.getById(node?.organization_id)?.name}</ItemValueStyled>
            </Box>
          )}
          <Box>
            <ItemTitle>{t('attributes.thiamis.gsm_network')}</ItemTitle>
            <DeviceSignalContainer
              iconLeft
              networkSignal={node?.networkSignal}
              hasWifi={node?.hasWifi}
              networkName={node?.networkName}
              networkSignalUnit={node?.networkSignalUnit}
            />
          </Box>
          {Boolean(node?.shared_to?.filter((id) => Boolean(organizations.getById(id)?.name)).length) && (
            <Box>
              <ItemTitle>{t('device_monitor.forms.shared_to')}</ItemTitle>
              <Stack>
                {node?.shared_to.map((id) => (
                  <ItemValueStyled key={id} title={organizations.getById(id)?.name}>
                    {organizations.getById(id)?.name}
                  </ItemValueStyled>
                ))}
              </Stack>
            </Box>
          )}
        </FormGrid>
      </HeaderModuleStyled>
    </HeaderModule>
  );
});

type CardFooterProps = {
  handleReset: VoidFunction;
  handleSubmit: VoidFunction;
  submitting: boolean;
};

export function CardFooter({ submitting, handleReset, handleSubmit }: CardFooterProps) {
  return (
    <CardActions>
      <ButtonStyled variant="outlined" onClick={handleReset} data-testid="cancelBtn">
        {I18n.t('base.buttons.cancel')}
      </ButtonStyled>
      <LoadingButton loading={submitting} variant="contained" onClick={handleSubmit} data-testid="saveBtn" type="submit">
        {I18n.t('base.buttons.save')}
      </LoadingButton>
    </CardActions>
  );
}

export function HeaderSensor({ sensor }: { sensor: ISensor }) {
  const { t } = useLocales();
  const { model, comPort, comType, address } = sensor;

  const SvgIcon = useSvgLoader(sensor.model, sensor.name);

  return (
    <HeaderSensorWrapperStyled>
      <Grid container>
        <Grid item xs={12} md={12} lg={12} xl={3}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }} fullWidth>
            <IconStyled $height={60}>
              <SvgIcon />
            </IconStyled>
          </Box>
        </Grid>
        <Grid item xs={12} md={12} lg={12} xl={9}>
          <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" sx={{ width: '100%', px: 2 }}>
            <Box>
              <ItemTitle>{t('device_monitor.forms.model')}</ItemTitle>
              <ItemValueStyled>{model}</ItemValueStyled>
            </Box>
            <Box>
              <ItemTitle>{t('device_monitor.forms.com_port')}</ItemTitle>
              <ItemValueStyled>{comPort}</ItemValueStyled>
            </Box>
            <Box>
              <ItemTitle>{t('device_monitor.forms.com_type')}</ItemTitle>
              <ItemValueStyled>{comType}</ItemValueStyled>
            </Box>
            {address && (
              <Box>
                <ItemTitle>{t('device_monitor.forms.address')}</ItemTitle>
                <ItemValueStyled>{address}</ItemValueStyled>
              </Box>
            )}
          </Stack>
        </Grid>
      </Grid>
    </HeaderSensorWrapperStyled>
  );
}

import React, { useContext, useState, useMemo, ReactNode } from 'react';
import HighchartsReact from 'highcharts-react-official';

const stub = (): never => {
  throw new Error('You forgot to wrap your component in <ChartProvider>.');
};

export type ExportState = {
  chartRef: null | HighchartsReact.RefObject;
  setChartRef: () => void;
};

const initialState = {
  chartRef: null,
  setChartRef: stub
} as ExportState;

const ChartContext = React.createContext(initialState);

function ChartProvider({ children }: { children: ReactNode }) {
  const [chartRef, setChartRef] = useState<HighchartsReact.RefObject>(null);

  const value = useMemo(
    () => ({
      chartRef,
      setChartRef
    }),
    [chartRef, setChartRef]
  );

  return <ChartContext.Provider value={value}>{children}</ChartContext.Provider>;
}

export { ChartProvider, ChartContext };

const useChart = () => useContext(ChartContext);

export default useChart;

import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

const Page404S = styled(Box)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10)
}));

export default Page404S;

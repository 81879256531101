import { useCallback, useEffect } from 'react';
import { autorun } from 'mobx';
import { Button, Tooltip } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { PlayCircle, PauseCircle } from '@mui/icons-material';

import { IDeviceMonitor } from '~/pages/device_monitor/store';
import useLocales from '~/hooks/use_locales';

function ReportPauseUpdatesButton({ report }: { report: IDeviceMonitor }) {
  const { t } = useLocales();

  const handleToggleLiveUpdates = useCallback(() => {
    report.toggleLiveUpdates();
  }, [report]);

  useEffect(
    () =>
      autorun(() => {
        if (report.range.to) {
          report.turnOffLiveUpdates();
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [report.range.to]
  );
  return (
    <Tooltip title={`${report.is_live_updates_on ? t('base.buttons.pause_live_updates') : t('base.buttons.resume_live_updates')}`} placement="top">
      <Button variant="outlined" onClick={handleToggleLiveUpdates} sx={{ minWidth: 'auto', px: '4px' }} disabled={Boolean(report.range.to)}>
        {report.is_live_updates_on ? <PauseCircle /> : <PlayCircle />}
      </Button>
    </Tooltip>
  );
}

export default observer(ReportPauseUpdatesButton);

import React, { ReactNode, createContext } from 'react';
import { IRootStore } from '~/mst/store';

const MstContext = createContext<IRootStore>(null);

type MstProviderProps = {
  children: ReactNode;
  store: IRootStore;
};

function MstProvider({ store, children }: MstProviderProps) {
  return <MstContext.Provider value={store}>{children}</MstContext.Provider>;
}

export { MstProvider, MstContext };

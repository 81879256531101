/* eslint-disable @typescript-eslint/naming-convention */
import sortBy from 'lodash/sortBy';
import { IDevicesModel } from './model';

export default (self: IDevicesModel) => ({
  get thiamis() {
    return self.models.find((device) => device.isThiamis);
  },
  get airthinx() {
    return self.models.find((device) => device.isAirthinx);
  },
  get sensors() {
    return sortBy(
      self.models.filter((device) => !device.isThiamis),
      'name'
    );
  }
});

import { observer } from 'mobx-react-lite';
import { INode } from '~/mst/models/node';
import FormContainer from '~/pages/device_edit/containers/form_container';
import { HeaderCommon, BodyModule } from '~/pages/device_edit/components';
import { ModulesContainerStyled } from '~/pages/device_edit/components/styled';
import DescriptionForm from '~/pages/device_edit/modules/description';

type ThiamisFormType = {
  node: INode;
  handleSubmit: VoidFunction;
};

function DeviceForm({ node, handleSubmit }: ThiamisFormType) {
  return (
    <FormContainer handleSubmit={handleSubmit} model={node}>
      <ModulesContainerStyled>
        <HeaderCommon node={node} icon={node?.thiamis?.icon} />
        <BodyModule title="Settings" columns={1} expandable expanded>
          <DescriptionForm sx={{ pb: 2 }} />
        </BodyModule>
      </ModulesContainerStyled>
    </FormContainer>
  );
}

export default observer(DeviceForm);

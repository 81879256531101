import { Theme } from '@mui/material/styles';

export default function Tooltip(theme: Theme) {
  const isLight = theme.palette.mode === 'light';

  return {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: theme.palette.grey[isLight ? 800 : 500],
          color: theme.palette.primary.contrastText
        },
        arrow: {
          color: theme.palette.grey[isLight ? 800 : 500]
        }
      },
      '@media print': {
        display: 'none'
      }
    }
  };
}

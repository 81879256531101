import { Stack } from '@mui/material';

import withAutoupdate from '~/hocs/with_autoupdate';
import type { INode } from '~/mst/models/node';
import CloseIcon from '@mui/icons-material/Close';

import StatusIcon from '~/components/status_icon';
import NavLink from '~/components/nav_link';
import { WidgetTitleStyled } from '~/components/widgets/common/styled';

const ChartWidgetTitle = withAutoupdate(({ node, onClose }: { node: INode }) => {
  if (!node) {
    return null;
  }
  return (
    <Stack direction="row" spacing={0.5} alignItems="center" justifyContent="space-between">
      <Stack direction="row" spacing={0.5} alignItems="center">
        <StatusIcon statusType={node?.statusType()} statusText={node?.statusText()} size="medium" />
        <NavLink to={`devices/${node?._id}/monitor`}>
          <WidgetTitleStyled>{node?.presentName}</WidgetTitleStyled>
        </NavLink>
      </Stack>

      {!!onClose && <CloseIcon onClick={onClose} />}
    </Stack>
  );
});

export default ChartWidgetTitle;

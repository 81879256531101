import { Instance, SnapshotOut, types as t } from 'mobx-state-tree';
import { preProcessor, postProcessor } from '../snapshot_processors';
import CommonViews from '../views';
import model from './model';
import actions from './actions';

const AirthinxDevice = model.views(CommonViews).actions(actions);

export interface IAirthinxDevice extends Instance<typeof AirthinxDevice> {}
export interface IAirthinxDeviceSnapshot extends SnapshotOut<typeof AirthinxDevice> {}

export default t.snapshotProcessor(AirthinxDevice, {
  preProcessor,
  postProcessor
});

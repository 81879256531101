/* eslint-disable no-prototype-builtins */
import { observer } from 'mobx-react-lite';

import { ISensor } from '~/mst/models/device/sensor';
import { BodyModule } from '~/pages/device_edit/components';
import SwitchFields from '~/pages/device_edit/containers/sensor_form_container/sensors/sensor/common/switch_fields';
import useLocales from '~/hooks/use_locales';

type SensorType = {
  sensor: ISensor;
};

function Sensor({ sensor }: SensorType) {
  const { t } = useLocales();
  if (!sensor.formValues.hasOwnProperty('enabled')) {
    return null;
  }
  return (
    <BodyModule title={t('device_monitor.forms.settings')} columns={1} expandable expanded>
      <SwitchFields />
    </BodyModule>
  );
}

export default observer(Sensor);

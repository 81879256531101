import { observer } from 'mobx-react-lite';
import { INode } from '~/mst/models/node';

import { Connectivity, AirthinxDeviceSettings, Location } from '~/pages/device_edit/modules';
import FormContainer from '~/pages/device_edit/containers/form_container';
import { HeaderCommon, BodyModule } from '~/pages/device_edit/components';
import { ModulesContainerStyled } from '~/pages/device_edit/components/styled';
import SensorErrors from '~/pages/device_edit/containers/sensor_form_container/errors';
import SensorParameters from '~/pages/device_edit/containers/sensor_form_container/parameters';
import UserCalibration from '~/pages/device_edit/containers/user_calibration_container';
import Maintenance from '~/pages/device_edit/containers/sensor_form_container/sensors/sensor/common/maintenance';

import DescriptionForm from '../../modules/description';

type ThiamisFormType = {
  node: INode;
  handleSubmit: VoidFunction;
};

function AirthinxForm({ node, handleSubmit }: ThiamisFormType) {
  return (
    <FormContainer handleSubmit={handleSubmit} model={node?.thiamis} title="Device">
      <ModulesContainerStyled>
        <HeaderCommon node={node} icon={node?.thiamis?.name} iconMaxWidth="150px" />
        <BodyModule title="Settings" columns={1} expandable expanded>
          <DescriptionForm node={node} />
          <AirthinxDeviceSettings />
          <Connectivity node={node} />
          <Location node={node} />
        </BodyModule>
        <SensorErrors sensor={node?.thiamis} />
        <SensorParameters sensor={node?.thiamis} />
        <UserCalibration node={node} />
        <Maintenance sensor={node} />
      </ModulesContainerStyled>
    </FormContainer>
  );
}

export default observer(AirthinxForm);

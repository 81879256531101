import React, { useCallback, useState } from 'react';
import { IconButton, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import useLocales from '~/hooks/use_locales';
import useActionsPopover from '~/hooks/react_grid/use_actions_popover';
import ConfirmDialog from '~/components/confirm_dialog';
import useMst from '~/hooks/use_mst';
import useAuth from '~/hooks/use_auth';
import type { UserOrganizationRoleRow } from '~/mst/models/user/types';

const ICON = {
  mr: 2,
  width: 20,
  height: 20
};

function ActionsFormatter({ row }: { row: UserOrganizationRoleRow }) {
  const { ActionsMenu, handleClose, handleOpen } = useActionsPopover();
  const { t } = useLocales();
  const { userId, externalId, organizationName, role } = row;
  const { auth } = useAuth();
  const { users, organizations } = useMst();
  const user = users.getById(userId);

  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = useCallback(
    async (event) => {
      event.stopPropagation();
      try {
        await user.deletePermission(externalId);
        setIsDeleting(false);
        await user.fetch();
        await organizations.fetch();
      } catch (e) {}
    },
    [user, externalId, organizations]
  );

  const handleStartDelete = useCallback(
    (event) => {
      event.stopPropagation();
      setIsDeleting(true);
      handleClose();
    },
    [handleClose]
  );

  const handleCancelDelete = useCallback((event) => {
    event.stopPropagation();
    setIsDeleting(false);
  }, []);

  if (auth.user.isSupport) {
    return (
      <>
        <IconButton onClick={handleOpen}>
          <MoreVertIcon />
        </IconButton>
        <ActionsMenu>
          <MenuItem onClick={handleStartDelete} sx={{ color: 'error.main' }}>
            <DeleteIcon sx={ICON} />
            {t('base.buttons.delete')}
          </MenuItem>
        </ActionsMenu>
        <ConfirmDialog
          open={isDeleting}
          onClose={handleCancelDelete}
          onConfirm={handleDelete}
          title={t('users.remove_role')}
          content={t('users.remove_role_confirmation.text', { role: t(`users.roles.${role}`), name: organizationName })}
          confirmLabel={t('base.buttons.delete')}
          confirmButtonType="error"
        />
      </>
    );
  }
}

export default ActionsFormatter;

import { ContainerOwnProps } from '@mui/material';
import MContainer from '~/components/@material-extend/container';
import useCollapseDrawer from '~/hooks/use_collapse_drawer';

type ContainerProps =
  | {
      children: React.ReactElement[];
    }
  | ContainerOwnProps;

function Container({ children, ...rest }: ContainerProps) {
  const { isCollapse } = useCollapseDrawer();

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <MContainer maxWidth={isCollapse ? 'xl' : 'lg'} {...rest}>
      {children}
    </MContainer>
  );
}

export default Container;

import React, { useState, useCallback, useEffect, FunctionComponent } from 'react';
import debounce from 'lodash/debounce';
import { useTheme, Breakpoint } from '@mui/system';
import { Layout } from 'react-grid-layout';
import { IWidget } from '~/mst/models/widget';
import { IDashboard } from '~/mst/models/dashboard';

export type WidgetsContainerProps = {
  width: number;
  breakpoint: Breakpoint;
  editable: boolean;
  updateLayout: (layout: Layout, breakpoint: Breakpoint) => void;
  widgets: IWidget;
  dashboard: IDashboard;
};

const WidthProvider = (ComposedComponent: FunctionComponent<WidgetsContainerProps> | React.ComponentClass) => {
  function Wrapped(props: { addWidget: () => void }) {
    const {
      breakpoints: { values: breakpoints }
    } = useTheme();
    const [breakpoint, setBreakpoint] = useState<Breakpoint>();
    const [width, setWidth] = useState(window.innerWidth);

    const calculateBreakpoint = useCallback(
      (windowWidth) => {
        if (windowWidth >= breakpoints.xl) {
          return 'xl';
        }
        if (windowWidth >= breakpoints.lg) {
          return 'lg';
        }
        if (windowWidth >= breakpoints.md) {
          return 'md';
        }
        if (windowWidth >= breakpoints.sm) {
          return 'sm';
        }
        return 'xs';
      },
      [breakpoints]
    );

    const calculateWidth = useCallback(() => {
      const innerWindow = document.getElementById('widgets-region');
      if (innerWindow) {
        setWidth(innerWindow.offsetWidth);
        setBreakpoint(calculateBreakpoint(innerWindow.offsetWidth));
      }
    }, [setWidth, setBreakpoint, calculateBreakpoint]);

    const debouncedEditable = debounce(calculateWidth, 100);

    useEffect(() => {
      debouncedEditable();
      window.addEventListener('resize', debouncedEditable);
      return () => {
        debouncedEditable.cancel();
        window.removeEventListener('resize', debouncedEditable);
      };
    }, [debouncedEditable]);

    // eslint-disable-next-line react/jsx-props-no-spreading
    return <ComposedComponent {...props} width={width} breakpoint={breakpoint} />;
  }

  return Wrapped;
};

export default WidthProvider;

import React from 'react';
import ExternalLinkIcon from '@mui/icons-material/OpenInNew';
import { Box, Link } from '@mui/material';

const ICON = {
  mr: 2,
  marginLeft: '5px',
  width: 20,
  height: 20
};

function SerialFormatter({ value }: { value: string }) {
  return (
    <Box display="flex" alignItems="center">
      {value}
      <Link sx={ICON} target="_blank" rel="noreferrer" href={`https://blue.airthinx.io/${value?.toLowerCase()}.html`}>
        <ExternalLinkIcon sx={ICON} />
      </Link>
    </Box>
  );
}

export default SerialFormatter;

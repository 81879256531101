import { Grid } from '@mui/material';
import { INodeWidget } from '~/mst/models/widget/node_widget';
import Sensor from '~/pages/devices/grid_container/detail_row/sensor';

function DeviceSensors({ widget }: { widget: INodeWidget }) {
  return (
    <Grid container mt={0} spacing={2} sx={{ displayPrint: 'none' }}>
      {widget?.node?.devices?.sensors?.map((sensor) => (
        <Grid item xs={12} sm={12} md={6} lg={6} xl={3} key={sensor._id}>
          <Sensor device={sensor} />
        </Grid>
      ))}
    </Grid>
  );
}

export default DeviceSensors;

/* eslint-disable @typescript-eslint/no-use-before-define */
import { types as t, Instance } from 'mobx-state-tree';
import Sensor from '~/mst/models/device/sensor';
import BitConfigType from '~/mst/common/bit_config_type';

const DustTrakSensor = t.compose(
  t.model({
    photometric_calibration: t.maybeNull(t.union(t.null, t.number)),
    auto_zero: t.maybeNull(t.union(t.null, t.number)),
    enabled: t.maybeNull(t.union(t.null, BitConfigType)),
    auto_zero_enabled: t.maybeNull(t.union(t.null, BitConfigType)),
    auto_zero_time: t.maybeNull(t.union(t.null, t.number)),
    flow_calibration: t.maybeNull(t.union(t.null, t.number)),
    pm_microgram: t.maybeNull(t.union(t.null, BitConfigType)),
    pm1_stel_alert: t.maybeNull(t.union(t.null, t.number, t.string)),
    pm25_stel_alert: t.maybeNull(t.union(t.null, t.number, t.string)),
    pm4_stel_alert: t.maybeNull(t.union(t.null, t.number, t.string)),
    pm10_stel_alert: t.maybeNull(t.union(t.null, t.number, t.string)),
    total_stel_alert: t.maybeNull(t.union(t.null, t.number, t.string)),
    size_correction: t.maybeNull(t.union(t.null, t.number)),
    profile: t.maybeNull(t.union(t.null, t.string))
  }),
  Sensor
);

export interface IDustTrakSensor extends Instance<typeof DustTrakSensor> {}

export default DustTrakSensor;

/* eslint-disable react/jsx-props-no-spreading */
import { Stack } from '@mui/material';
import StatusWithAutoUpdate from '~/components/status_with_autoupdate';
import StatusIcon from '~/components/status_icon';
import type { IAlert } from '~/mst/models/alert';
import type { ValueLabelModelPair } from '~/mst/models/abstract/collection_types';

import { ListItemStyled } from '~/components/organizations_select/styled';

import NavigateDropdwon from './index';

type Value = ValueLabelModelPair<IAlert>;

function AlertsNavigateDropdown(props) {
  return (
    <NavigateDropdwon
      width={{ multiplier: 1.5, extra: 50 }}
      InputStartAdornment={StatusWithAutoUpdate}
      renderOption={(option: Value) => (
        <Stack direction="row" spacing={0.5} alignItems="center">
          <StatusIcon statusType={option?.model?.statusType()} statusText={option?.model?.statusText()} size="small" />
          <ListItemStyled>{option.label}</ListItemStyled>
        </Stack>
      )}
      {...props}
    />
  );
}

export default AlertsNavigateDropdown;

import { ReactNode } from 'react';
import { CardContent } from '@mui/material';
import { ModalActions } from '~/components/modal';
import I18n from '~/utils/i18n';

export type AddEditConfigurationProps = {
  children?: ReactNode;
  onClose: () => void;
  onSave: () => void;
  disabled?: boolean;
  isNew?: boolean;
};

function AddEditConfigurationModal({ children, onClose, disabled, onSave, isNew }: AddEditConfigurationProps) {
  return (
    <>
      <CardContent>{children}</CardContent>
      <ModalActions
        onSave={onSave}
        saveLabel={isNew ? I18n.t('base.buttons.create') : I18n.t('base.buttons.update')}
        onClose={onClose}
        disabled={disabled}
      />
    </>
  );
}

export default AddEditConfigurationModal;

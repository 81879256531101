import get from 'lodash/get';
import { observer } from 'mobx-react-lite';
import { Field as FinalField, useFormState } from 'react-final-form';
import { Box } from '@mui/material';

import useLocales from '~/hooks/use_locales';
import type { IAlert } from '~/mst/models/alert';
import useParameterUnit from '~/pages/alert_edit/conditions_block/condition/use_parameter_unit_hook';
import { PATHS } from '~/utils/constants';

type ConditionFieldType = {
  name: string;
  model: IAlert;
};

function ConditionField(props: ConditionFieldType) {
  const { name, model } = props;
  const unit = useParameterUnit(name, model);
  const { t } = useLocales();
  const { values } = useFormState({ subscription: { values: true } });
  const { dataPointId, deviceId } = get(values, name, {});
  const isStatus = model?.nodes?.getById(deviceId)?.data_points?.getById(dataPointId)?.shortPath === PATHS.ONLINE;

  return (
    <FinalField name={`${name}.value`}>
      {({ input }) => {
        return (
          <Box>
            <b>
              {isStatus ? (
                t(`data_points.status_${input.value ? 'true' : 'false'}`)
              ) : (
                <>
                  {input.value}
                  {unit && <small> {unit}</small>}
                </>
              )}
            </b>
          </Box>
        );
      }}
    </FinalField>
  );
}

export default observer(ConditionField);

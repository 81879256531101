const RecentAlertsWidgetLayout = {
  xl: {
    h: 3,
    w: 6,
    x: 6,
    y: 1
  },
  lg: {
    h: 3,
    w: 6,
    x: 6,
    y: 1
  },
  md: {
    h: 3,
    w: 4,
    x: 4,
    y: 1
  },
  sm: {
    h: 3,
    w: 5,
    x: 5,
    y: 1
  },
  xs: {
    h: 3,
    w: 4,
    x: 5,
    y: 1
  }
};

export default RecentAlertsWidgetLayout;

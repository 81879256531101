import { alpha, Theme, styled } from '@mui/material/styles';
import { ColorSchema } from '~/theme/types/palette';
import type { LabelColor, LabelVariant } from '.';

const LabelRootStyled = styled('span')(({
  theme,
  ownerState
}: {
  theme: Theme;
  ownerState: {
    color: LabelColor;
    variant: LabelVariant;
  };
}) => {
  const isLight = theme.palette.mode === 'light';
  const { color, variant } = ownerState;

  const styleFilled = (_color: ColorSchema) => ({
    color: theme.palette[_color].contrastText,
    backgroundColor: theme.palette[_color].main
  });

  const styleOutlined = (_color: ColorSchema) => ({
    color: theme.palette[_color].main,
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette[_color].main}`
  });

  const styleGhost = (_color: ColorSchema) => ({
    color: theme.palette[_color][isLight ? 'dark' : 'light'],
    backgroundColor: alpha(theme.palette[_color].main, 0.16)
  });

  return {
    height: 22,
    minWidth: 22,
    lineHeight: 0,
    borderRadius: 6,
    cursor: 'default',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    display: 'inline-flex',
    justifyContent: 'center',
    padding: theme.spacing(0, 1),
    color: theme.palette.grey[800],
    fontSize: theme.typography.pxToRem(12),
    fontFamily: theme.typography.fontFamily,
    backgroundColor: theme.palette.grey[300],
    fontWeight: theme.typography.fontWeightBold,

    ...(color !== 'default'
      ? {
          ...(variant === 'filled' && { ...styleFilled(color) }),
          ...(variant === 'outlined' && { ...styleOutlined(color) }),
          ...(variant === 'ghost' && { ...styleGhost(color) })
        }
      : {
          ...(variant === 'outlined' && {
            backgroundColor: 'transparent',
            color: theme.palette.text.primary,
            border: `1px solid ${theme.palette.grey[500_32]}`
          }),
          ...(variant === 'ghost' && {
            color: isLight ? theme.palette.text.secondary : theme.palette.common.white,
            backgroundColor: theme.palette.grey[500_16]
          })
        })
  };
});

export default LabelRootStyled;

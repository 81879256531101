import { Theme } from '@mui/material/styles';

export default function Card(theme: Theme) {
  return {
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: theme.palette.themeShadows.card,
          borderRadius: theme.shape.borderRadiusMd,
          position: 'relative',
          zIndex: 0,
          border: `1px solid ${theme.palette.grey[100]}`
        }
      },
      variants: [
        {
          props: { variant: 'dashed' },
          style: {
            borderWidth: '1px',
            borderStyle: 'dashed'
          }
        },
        {
          props: { variant: 'solid' },
          style: {
            borderWidth: '1px',
            borderStyle: 'solid'
          }
        }
      ]
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: { variant: 'h6' },
        subheaderTypographyProps: { variant: 'body2', marginTop: theme.spacing(0.5) }
      },
      styleOverrides: {
        root: {
          padding: theme.spacing(2, 3, 1, 3)
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: theme.spacing(3)
        }
      }
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%',
          marginTop: theme.spacing(1),
          padding: theme.spacing(2)
        }
      }
    }
  };
}

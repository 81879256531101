import { types as t, Instance } from 'mobx-state-tree';
import WidgetModel from '~/mst/models/widget';
import Layout from '~/mst/models/widget/layout';
import DefaultLayout from './layout';

export const options = [
  { value: 'pie_chart', label: 'Total Devices' },
  { value: 'column_chart', label: 'Total Devices By Type' }
];

const TotalDevicesWidgetModel = t
  .compose(
    WidgetModel,
    t.model({
      type: 'TotalDevicesOnlineWidget',
      chart_type: t.enumeration(
        'CharType',
        options.map(({ value }) => value)
      ),
      name: 'Total Devices By Type',
      data: t.maybeNull(t.frozen()),
      layout: t.optional(Layout, DefaultLayout)
    })
  )
  .named('TotalDevicesOnlineWidget');

export interface ITotalDevicesWidgetModel extends Instance<typeof TotalDevicesWidgetModel> {}

export default TotalDevicesWidgetModel;

import { Container } from '@mui/material';
import { styled } from '@mui/material/styles';

const MContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    padding: '0 20px'
  }
}));

export default MContainer;

import { styled } from '@mui/material/styles';
import cssStyles from '~/utils/css_styles';

export const TabsWrapperStyled = styled('div')(({ theme }) => ({
  zIndex: 9,
  bottom: 0,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.up('sm')]: {
    justifyContent: 'center'
  },
  [theme.breakpoints.up('md')]: {
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(3)
  }
}));

export const ProfileCoverRootStyled = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  '&:before': {
    ...cssStyles().bgBlur({ blur: 2, color: theme.palette.primary.darker }),
    top: 0,
    zIndex: 9,
    content: "''",
    width: '100%',
    height: '100%',
    position: 'absolute'
  }
}));

export const InfoStyled = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  left: 0,
  right: 0,
  zIndex: 99,
  position: 'absolute',
  userSelect: 'none',
  pointerEvents: 'none',
  [theme.breakpoints.up('md')]: {
    right: 'auto',
    display: 'flex',
    alignItems: 'center',
    left: theme.spacing(3),
    bottom: theme.spacing(3)
  }
}));

import get from 'lodash/get';
import { observer } from 'mobx-react-lite';
import { useFormState } from 'react-final-form';

import I18n from '~/utils/i18n';
import Select from '~/components/final_form/select';
import useLocales from '~/hooks/use_locales';
import TextInput from '~/components/final_form/text_input';
import type { IAlert } from '~/mst/models/alert';
import { PATHS } from '~/utils/constants';

type ConditionFieldType = {
  name: string;
  model: IAlert;
};

export const StatusOptions = [
  { value: true, label: I18n.t('data_points.status_true') },
  { value: false, label: I18n.t('data_points.status_false') }
];

function ConditionField(props: ConditionFieldType) {
  const { name, model } = props;
  const { t } = useLocales();
  const { values } = useFormState({ subscription: { values: true } });
  const { dataPointId, deviceId } = get(values, name, {});

  const isStatus = model?.nodes?.getById(deviceId)?.data_points?.getById(dataPointId)?.shortPath === PATHS.ONLINE;

  return isStatus ? (
    <Select
      searchable
      noHelperText
      name={`${name}.value`}
      data-testid={`${name}.valueSelect`}
      label={t('attributes.alert_rule.value')}
      options={StatusOptions}
    />
  ) : (
    <TextInput noHelperText name={`${name}.value`} data-testid={`${name}.valueInput`} label={t('attributes.alert_rule.value')} />
  );
}

export default observer(ConditionField);

import { Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function Button(theme: Theme) {
  const isMobile = useMediaQuery<Theme>(() => theme.breakpoints.down('md'));
  return {
    MuiButton: {
      defaultProps: {
        size: isMobile ? 'small' : 'medium'
      },
      styleOverrides: {
        root: {
          '&:hover': {
            boxShadow: 'none'
          }
        },
        sizeLarge: {
          height: 56
        },
        containedInherit: {
          boxShadow: theme.customShadows.z8,
          '&:hover': {
            backgroundColor: theme.palette.grey[500]
          }
        },
        containedPrimary: {
          backgroundColor: theme.palette.background.button,
          boxShadow: theme.customShadows.primary,
          '&:hover': {
            backgroundColor: theme.palette.background.button,
            boxShadow: theme.customShadows.z8
          }
        },
        containedSecondary: {
          boxShadow: theme.customShadows.secondary
        },
        containedInfo: {
          boxShadow: theme.customShadows.info
        },
        containedSuccess: {
          color: theme.palette.success.contrastText,
          boxShadow: theme.customShadows.success
        },
        containedWarning: {
          boxShadow: theme.customShadows.warning
        },
        containedError: {
          boxShadow: theme.customShadows.error
        },
        outlinedInherit: {
          border: `1px solid ${theme.palette.grey[500_32]}`,
          '&:hover': {
            backgroundColor: theme.palette.action.hover
          }
        },
        textInherit: {
          '&:hover': {
            backgroundColor: theme.palette.action.hover
          }
        }
      }
    }
  };
}

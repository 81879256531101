import { styled, Box, Toolbar, Theme, Card, TableRow } from '@mui/material';
import { VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import { transientOptions } from '~/utils/styled';

export const ContainerGridStyled = styled(Card)(() => ({
  flexDirection: 'column',
  padding: 0,
  '.TableContainer-root': {
    height: 'calc(100vh - 280px)'
  }
}));

export const ToolbarStyled = styled(Toolbar)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
  [theme.breakpoints.down('sm')]: {
    height: 'unset',
    flexDirection: 'column-reverse',
    padding: theme.spacing(0, 0, 1, 0),
    '> .MuiButton-root': {
      margin: 5,
      width: '100%'
    },
    '> .MuiFormControl-root': {
      margin: 5,
      width: '100%'
    }
  }
}));

type RowStyledProps = {
  theme: Theme;
  $expandable?: boolean;
  $disabled?: boolean;
};

export const HeaderStyled = styled(
  VirtualTable.Row,
  transientOptions
)<RowStyledProps>(() => ({
  position: 'sticky',
  top: 0,
  zIndex: 10
}));

export const RowStyled = styled(
  VirtualTable.Row,
  transientOptions
)<RowStyledProps>(({ $disabled = false, $expandable = true }: RowStyledProps) => ({
  ...($disabled && {
    '.MuiTableCell-root': {
      opacity: 0.6
    }
  }),
  ...($expandable && {
    cursor: 'pointer'
  })
}));

export const GridStyled = styled(Box)(({ theme }) => ({
  width: '100%',
  overflowY: 'auto',
  height: '100%',
  flex: 1,
  '.MuiTableCell-root:first-of-type': {
    boxShadow: 'unset !important'
  },
  '.MuiTableCell-root:last-of-type': {
    boxShadow: 'unset !important'
  },
  '.MuiTableCell-head:first-of-type': {
    borderRadius: 0
  },
  '.MuiTableCell-head:last-of-type': {
    borderRadius: 0
  },
  '.Pagination-button': {
    fontWeight: 'normal'
  },
  '.Pagination-activeButton': {
    fontWeight: 'bold',
    backgroundColor: theme.palette.action.focus
  },
  '.MuiTableBody-root': {
    '.MuiTableRow-root': {
      '.TableDetailToggleCell-toggleCell, .TableSelectCell-cell': {
        svg: {
          opacity: 0.25
        }
      },
      '&.TableSelectRow-selected td': {
        backgroundColor: 'rgba(145, 158, 171, 0.08)'
      },
      '&:hover, &.TableSelectRow-selected': {
        '.TableDetailToggleCell-toggleCell, .TableSelectCell-cell': {
          svg: {
            opacity: 1
          }
        }
      }
    }
  },
  [theme.breakpoints.down('sm')]: {
    overflowX: 'auto',
    '.MuiTableCell-body': {
      padding: '4px 8px 4px 8px',
      fontSize: '12px',
      height: '49px'
    },
    '.MuiTableBody-root .MuiTableRow-root': {
      '.TableDetailToggleCell-toggleCell, .TableSelectCell-cell': {
        svg: {
          opacity: 1
        }
      }
    }
  }
}));

export const DetailRowStyled = styled(TableRow)(({ theme }) => ({
  position: 'relative',
  background: theme.palette.grey[500_8],
  boxShadow: `inset 0 3px 3px ${theme.palette.action.focus}`,
  '.MuiTableCell-root': {
    padding: theme.spacing(2.5, 1)
  },
  '.MuiTableRow-head th': {
    background: theme.palette.background.paper
  }
}));

import { enqueueSnackbar, VariantType, closeSnackbar } from 'notistack';

export default {
  success(msg: string, options?) {
    return this.toast(msg, 'success', options);
  },
  warning(msg: string, options?) {
    return this.toast(msg, 'warning', options);
  },
  info(msg: string, options?) {
    return this.toast(msg, 'info', options);
  },
  error(msg: string, options?) {
    return this.toast(msg, 'error', options);
  },
  close(key?) {
    closeSnackbar(key);
  },
  toast(msg: string, variant: VariantType = 'default', options = {}) {
    return enqueueSnackbar(msg, {
      variant,
      autoHideDuration: 2000,
      ...options
    });
  }
};

import React, { useContext, useState, useCallback, useMemo, ReactNode } from 'react';
import { datadogRum } from '@datadog/browser-rum';

const stub = (): never => {
  throw new Error('You forgot to wrap your component in <ExportProvider>.');
};

export type ExportState = {
  isExporting: boolean;
  startExport: () => void;
  stopExport: () => void;
};

const initialState = {
  isExporting: false,
  startExport: stub,
  stopExport: stub
} as ExportState;

const ExportContext = React.createContext(initialState);

function ExportProvider({ children }: { children: ReactNode }) {
  const [isExporting, setIsExporting] = useState<boolean>(false);

  const handleStartExport = useCallback(() => {
    setIsExporting(true);
    datadogRum.addAction('export_table_data');
  }, [setIsExporting]);

  const handleStopExport = useCallback(() => {
    setIsExporting(false);
  }, [setIsExporting]);

  const value = useMemo(
    () => ({
      isExporting,
      startExport: handleStartExport,
      stopExport: handleStopExport
    }),
    [isExporting, handleStartExport, handleStopExport]
  );

  return <ExportContext.Provider value={value}>{children}</ExportContext.Provider>;
}

export { ExportProvider, ExportContext };

const useExport = () => useContext(ExportContext);

export default useExport;

/* eslint-disable @typescript-eslint/no-use-before-define */
import { types as t, Instance } from 'mobx-state-tree';
import isArray from 'lodash/isArray';
import model from './model';
import actions from './actions';
import views from './views';

const DataPointMetric = model.views(views).actions(actions);

export interface IDataPointMetric extends Instance<typeof DataPointMetric> {}

export default t.snapshotProcessor(DataPointMetric, {
  preProcessor(sn: any) {
    let { conditions } = sn;
    if (!isArray(conditions)) conditions = [conditions];
    const result = {
      ...sn,
      conditions
    };
    return result;
  }
});

/* eslint-disable no-param-reassign */
import { flow, applySnapshot } from 'mobx-state-tree';
import api from '~/api';
import { toMapById } from '~/mst/utils';
import type { IArchivesModel } from './model';

export default (self: IArchivesModel) => ({
  fetch: flow(function* fetch(params = {}, options = {}) {
    try {
      self.startFetching();
      const { data } = yield self.sync(api.snapshots.fetch, params, options);
      applySnapshot(self.models, toMapById(data, '_id'));
      self.finishFetching();
    } catch (error) {
      self.failFetching(error);
    }
  })
});

/* eslint-disable react/jsx-props-no-spreading */
import { SortingState, IntegratedSorting, SelectionState, IntegratedPaging, PagingState } from '@devexpress/dx-react-grid';
import { Grid, VirtualTable, TableHeaderRow, PagingPanel } from '@devexpress/dx-react-grid-material-ui';
import { observer } from 'mobx-react-lite';

import useSelection from '~/hooks/react_grid/use_selection';
import useLoading from '~/hooks/react_grid/use_loading';
import useSorting from '~/hooks/react_grid/use_sorting';
import usePaging from '~/hooks/react_grid/use_paging';

import { GridStyled } from '~/components/react_grid/styled';
import { Head, Container, Row } from '~/components/react_grid';

import { LastTriggeredProvider, NameProvider, StatusTypeProvider } from '~/pages/alerts/grid_container/info_providers';
import { IRecentAlertsWidget } from '../../store';

function compareDates(a: number, b: number) {
  // @ts-ignore
  return (b != null) - (a != null) || b - a;
}

const columns = [
  { name: 'lastTriggeredTs', title: 'Last Triggered' },
  { name: 'name', title: 'Name' },
  { name: 'statusType', title: 'Status' }
];

const getRowId = ({ _id }) => _id;
const PAGE_SIZES = [5, 10, 15];

function GridContainer({ widget }: { widget: IRecentAlertsWidget }) {
  const { selection, setSelection } = useSelection();
  const { sorting, handleSortingChange } = useSorting([{ columnName: 'lastTriggeredTs', direction: 'asc' }]);

  const { messages, RootComponent } = useLoading(widget);
  const { pageSizes, pageSize, handlePageSizeChange } = usePaging(PAGE_SIZES);
  return (
    <GridStyled>
      <Grid rows={widget?.data} columns={columns} getRowId={getRowId} rootComponent={RootComponent}>
        <SortingState sorting={sorting} onSortingChange={handleSortingChange} />
        <IntegratedSorting columnExtensions={[{ columnName: 'lastTriggeredTs', compare: compareDates }]} />
        <PagingState defaultCurrentPage={0} pageSize={pageSize} onPageSizeChange={handlePageSizeChange} />
        <IntegratedPaging />
        <SelectionState selection={selection} onSelectionChange={setSelection} />
        <LastTriggeredProvider />
        <NameProvider />
        <StatusTypeProvider />
        <VirtualTable
          messages={messages}
          rowComponent={Row}
          containerComponent={Container}
          columnExtensions={[{ columnName: 'statusType', width: 100 }]}
          headComponent={Head}
        />
        {widget?.data?.length > pageSize && <PagingPanel pageSizes={pageSizes} />}
        <TableHeaderRow showSortingControls />
      </Grid>
    </GridStyled>
  );
}

export default observer(GridContainer);

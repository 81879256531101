/* eslint-disable import/prefer-default-export */
import request from '../request';

export function read(id: string, params?, options?) {
  return request('get', `users/${id}`, params, options);
}

export function fetch(params, options) {
  return [request, 'get', 'users', params, options];
}

export function update(id: string, payload: any) {
  return request('put', `users/${id}`, payload);
}

export function deletePermission(id: string, externalId: string) {
  return request('delete', `users/${id}/permissions/${externalId}`);
}

import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
import { Box } from '@mui/material';

import useCollapseDrawer from '~/hooks/use_collapse_drawer';
import { MHidden } from '~/components/@material-extend';
import OrganizationsDropdown from '~/components/header/organizations_dropdown';

import AccountPopover from './account_popover';
import { IconButtonStyled, NavbarStyled, PopoversWrapperStyled, ToolbarStyled } from './styled';

type DashboardNavbarProps = {
  onOpenSidebar: VoidFunction;
};

export default function DashboardNavbar({ onOpenSidebar }: DashboardNavbarProps) {
  const { isCollapse } = useCollapseDrawer();

  return (
    <NavbarStyled $isCollapse={isCollapse} sx={{ displayPrint: 'none' }}>
      <ToolbarStyled>
        <MHidden width="lgUp">
          <IconButtonStyled onClick={onOpenSidebar}>
            <Icon icon={menu2Fill} />
          </IconButtonStyled>
        </MHidden>

        <OrganizationsDropdown />
        <Box sx={{ flexGrow: 1 }} />

        <PopoversWrapperStyled>
          <AccountPopover />
        </PopoversWrapperStyled>
      </ToolbarStyled>
    </NavbarStyled>
  );
}

import { NodeActions } from '~/mst/models/abstract/node_base/types';
import type { INode } from '../index';

export default (self: INode) => ({
  get isMonitorable() {
    return self.actions.includes(NodeActions.Read);
  },
  get isModifiable() {
    return self.actions.includes(NodeActions.Configure);
  },
  get isDeletable() {
    return self.actions.includes(NodeActions.Delete);
  },
  get isCreatable() {
    return self.actions.includes(NodeActions.Create);
  }
});

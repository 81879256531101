/* eslint-disable @typescript-eslint/no-use-before-define */
import { Instance } from 'mobx-state-tree';
import model from './model';
import views from './views';

const AdamSensor = model.views(views);

export interface IAdamSensor extends Instance<typeof AdamSensor> {}

export default AdamSensor;

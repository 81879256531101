import PhoenixStreams from '@netronixgroup/phoenix-streams';
import toasts from '~/utils/toasts';
import { getClusternetWSUrl } from '~/utils/helpers';
import { token } from '~/api/axios';

const toastOptions = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right'
  }
};

const socket = new PhoenixStreams(getClusternetWSUrl(), {
  params() {
    return {
      token: token.token
    };
  }
});

let lastTimeConnected;
socket.getStatus().subscribe({
  next(conected) {
    if (!conected) {
      lastTimeConnected = conected;
      toasts.warning('WebSocket connection is lost, reconnecting...', { ...toastOptions, autoHideDuration: null });
    }
    if (lastTimeConnected === false && conected) {
      toasts.close();
      toasts.success('WebSocket connection is established!', toastOptions);
    }
  }
});

export default socket;

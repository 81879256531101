/* eslint-disable no-param-reassign */
import { types as t, Instance } from 'mobx-state-tree';
import Calibration from '~/mst/models/calibration';
import Syncable from '~/mst/models/abstract/syncable';
import Fetchable from '~/mst/models/abstract/fetchable';
import Filterable from '~/mst/models/abstract/filterable';
import CollectionMap from '~/mst/models/abstract/collection_map';

const CalibrationsModel = t.compose(CollectionMap(Calibration), Fetchable({ cache: true }), Syncable, Filterable);

export interface ICalibrationsModel extends Instance<typeof CalibrationsModel> {}

export default CalibrationsModel;

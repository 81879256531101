import { types as t, Instance } from 'mobx-state-tree';
import { ModelV2 } from '~/mst/models/abstract/model';
import Syncable from '~/mst/models/abstract/syncable';
import MaybeString from '~/mst/models/abstract/maybe_string';
import Widgets from '~/mst/models/widgets';
import Range from '~/mst/models/range';

const DashboardModel = t.compose(
  t.model({
    name: t.maybeNull(t.string),
    description: t.maybeNull(MaybeString),
    organization_id: t.maybeNull(t.string),
    user_id: t.maybeNull(t.string),
    is_live_updates_on: t.optional(t.boolean, true),
    widgets: t.optional(Widgets, []),
    is_starred: t.optional(t.boolean, false),
    range: t.optional(Range, () =>
      // @ts-ignore
      Range.create({
        from: '30_minutes'
      })
    )
  }),
  ModelV2,
  Syncable
);

export interface IDashboardModel extends Instance<typeof DashboardModel> {}

export default DashboardModel;

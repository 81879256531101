import { observer } from 'mobx-react-lite';
import isEmpty from 'lodash/isEmpty';
import { Card, Stack, CircularProgress, Box, CardContent } from '@mui/material';
import UserAvatar from '~/components/user_avatar';
import type { IAlert } from '~/mst/models/alert';
import useMst from '~/hooks/use_mst';
import useFetch from '~/hooks/use_fetch';
import MCardHeader from '~/components/@material-extend/MCardHeader';
import { HeaderDeviceWrapperStyled } from '~/pages/device_edit/components/styled';
import Item from './components';

function SubscribersBlock({ alert }: { alert: IAlert }) {
  const { users } = useMst();

  const { isFetching } = useFetch(users, { organization_id: alert?.organization_id });

  return (
    <Card>
      <HeaderDeviceWrapperStyled className="AlertBlockTitleContainer">
        <MCardHeader title="Subscribers" />
      </HeaderDeviceWrapperStyled>
      <CardContent>
        <Stack spacing={3}>
          {isFetching ? (
            <Stack alignItems="center">
              <CircularProgress size={25} color="info" />
            </Stack>
          ) : (
            <Box>
              {isEmpty(alert?.subscribers?.users) ? (
                <Stack alignItems="center">No Subscribers</Stack>
              ) : (
                alert.subscribers.users.map((userId) => {
                  const user = users.getById(userId);
                  if (!user) {
                    return null;
                  }
                  return (
                    <Stack key={user} direction="row" spacing={2} alignItems="flex-start" mb={2}>
                      <UserAvatar user={user} />
                      <Item name={user.fullName} email={user.email} />
                    </Stack>
                  );
                })
              )}
            </Box>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}

export default observer(SubscribersBlock);

import { types as t } from 'mobx-state-tree';
import { toMapById } from '~/mst/utils';

export default (store) =>
  t.snapshotProcessor(store, {
    preProcessor(values) {
      return {
        models: toMapById(values)
      };
    },
    postProcessor(sn) {
      if (sn.models) {
        return Object.values(sn.models);
      }
      return sn;
    }
  });

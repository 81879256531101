import { DataTypeProvider } from '@devexpress/dx-react-grid';
import LastValueFormatter from './last_value_formatter';
import MeasurementsFormatter from './measurements_formatter';
import NameFormatter from './name_formatter';

export function LastValueProvider() {
  return <DataTypeProvider for={['lastValue']} formatterComponent={LastValueFormatter} />;
}

export function MeasurementsProvider() {
  return <DataTypeProvider for={['measurements']} formatterComponent={MeasurementsFormatter} />;
}

export function NameProvider() {
  return <DataTypeProvider for={['shortPresentName']} formatterComponent={NameFormatter} />;
}

import { observer } from 'mobx-react-lite';
import { Portal } from '@mui/material';

import StatusBar from '~/pages/device_monitor/monitor_chart/sidebar/status_bar';
import useResponsive from '~/hooks/use_responsive';
import { SidebarContainerStyled, SidebarInnerStyled, SidebarOuterStyled, CatcherStyled } from '~/pages/device_monitor/monitor_chart/sidebar/styled';
import type { IDeviceAdmin } from '../../../store';
import SidebarForm from './form';

type SidebarType = {
  isExpanded?: boolean;
  onToggleCollapse?: VoidFunction;
  handleSubmit?: VoidFunction;
  report?: IDeviceAdmin;
  width?: string | number;
};

function Sidebar({ report, isExpanded, width, onToggleCollapse, handleSubmit }: SidebarType) {
  const isMobile = useResponsive('down', 'md');

  return (
    <SidebarContainerStyled $isExpanded={isExpanded} $width={width}>
      {isMobile && (
        <Portal>
          <CatcherStyled $isExpanded={isExpanded} onClick={onToggleCollapse} />
        </Portal>
      )}

      <Portal disablePortal={!isMobile}>
        <SidebarOuterStyled $isExpanded={isExpanded} $width={width}>
          <SidebarInnerStyled $isExpanded={isExpanded} $width={width}>
            <SidebarForm handleSubmit={handleSubmit} report={report} submitting={report?.debugWidget?.node?.thiamis?.isSyncing} />
          </SidebarInnerStyled>
        </SidebarOuterStyled>
      </Portal>
      <StatusBar node={report?.nodeWidget?.node} isExpanded={isExpanded} />
    </SidebarContainerStyled>
  );
}

export default observer(Sidebar);

import { types as t, Instance } from 'mobx-state-tree';
import Fetchable from '~/mst/models/abstract/fetchable';
import MaybeString from '~/mst/models/abstract/maybe_string';

const VersionModel = t.compose(
  t.model({
    last_modified: t.maybeNull(MaybeString)
  }),
  Fetchable()
);

export interface IVersionModel extends Instance<typeof VersionModel> {}

export default VersionModel;

/* eslint-disable react/jsx-props-no-spreading */
import { memo } from 'react';
import { VirtualTable, Grid, TableSelection } from '@devexpress/dx-react-grid-material-ui';
import { useTheme } from '@mui/system';
import { RowStyled } from './styled';

export const Head = memo((props) => {
  const theme = useTheme();

  return (
    <VirtualTable.TableHead
      {...props}
      style={{
        position: 'sticky',
        top: '0px',
        zIndex: '2',
        background: theme.palette.background.neutral
      }}
    />
  );
});

export const Container = memo((props) => <VirtualTable.Container {...props} />);

type RootProps = {
  isSyncing: boolean;
};

export function Root(props: RootProps) {
  const { isSyncing, ...rest } = props;
  return <Grid.Root {...rest} style={{ height: '100%', opacity: isSyncing ? 0.5 : 1 }} />;
}

export function Row(props) {
  return <RowStyled {...props} hover />;
}

export function RowSelect({ onToggle, highlighted, ...rest }: TableSelection.RowProps) {
  return <RowStyled {...rest} className={highlighted && 'TableSelectRow-selected'} onClick={onToggle} hover />;
}

/* eslint-disable import/prefer-default-export */
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import TriggeredFormatter from '~/components/react_grid/triggered_formatter';
import DurationFormatter from './duration_formatter';

export function TriggeredProvider() {
  return <DataTypeProvider for={['from']} formatterComponent={TriggeredFormatter} />;
}
export function DurationProvider() {
  return <DataTypeProvider for={['duration']} formatterComponent={DurationFormatter} />;
}

/* eslint-disable import/prefer-default-export */
import { styled } from '@mui/system';

export const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '8px',
  color: theme.palette.primary.main,
  backgroundColor: '#fff'
}));

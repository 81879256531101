/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Switch, FormControlLabel } from '@mui/material';

import { IDeviceMonitor } from '~/pages/device_monitor/store';
import WindChart from '~/components/wind_chart';

function Chart({ report }: { report: IDeviceMonitor }) {
  const title = `${report?.chartWidget?.node?.presentName}<br /><small style="font-size: 12px; color: #444; padding-top: 10px;">${report?.range.toString}</small>`;

  return (
    <WindChart
      series={report?.chartWidget?.windChartSeries}
      isLoading={report?.chartWidget?.isLoading}
      options={{
        exporting: { enabled: false, chartOptions: { title: { useHTML: true, text: title } } }
      }}
    />
  );
}

export default observer(Chart);

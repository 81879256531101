import { types as t, Instance, SnapshotOut } from 'mobx-state-tree';
import WidgetModel from '~/mst/models/widget';
import Fetchable from '~/mst/models/abstract/fetchable';
import Layout from '~/mst/models/widget/layout';
import DefaultLayout from './layout';

const DevicesOnlineChartWidgetModel = t
  .compose(
    WidgetModel,
    t.model({
      type: 'DevicesOnlineChartWidget',
      name: 'Devices Online',
      hours: t.optional(t.number, 24),
      data: t.maybeNull(t.frozen()),
      layout: t.optional(Layout, DefaultLayout)
    }),
    Fetchable({ cache: true })
  )
  .named('DevicesOnlineChartWidget');

export interface IDevicesOnlineChartWidgetModel extends Instance<typeof DevicesOnlineChartWidgetModel> {}
export interface IDevicesOnlineChartWidgetModelSnapshotOut extends SnapshotOut<typeof DevicesOnlineChartWidgetModel> {}

export default DevicesOnlineChartWidgetModel;

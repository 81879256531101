/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import { observer } from 'mobx-react-lite';
import WidgetContainer from '~/components/widgets/common';
import { WidgetPropType } from '~/components/widgets/common/types';
import { INodeWidget } from '~/mst/models/widget/node_widget';
import DeviceWidgetTitle from '../common/device_widget_title';
import NodeWidgetContent from './content/with_device_status';
import NodeWidgetFooter from './content/footer';

function NodeWidgetWithDeviceStatus({ widget, dashboard, height }: WidgetPropType<INodeWidget>) {
  return (
    <WidgetContainer
      widget={widget}
      moveable={false}
      editable={false}
      dashboard={dashboard}
      Header={<DeviceWidgetTitle node={widget?.node} />}
      Footer={NodeWidgetFooter}
    >
      <NodeWidgetContent widget={widget} height={height} />
    </WidgetContainer>
  );
}

export default observer(NodeWidgetWithDeviceStatus);

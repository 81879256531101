import { useState, useCallback } from 'react';

const useModal = () => {
  const [open, setOpen] = useState(false);
  const handleCloseModal = useCallback(() => setOpen(false), [setOpen]);
  const handleOpenModal = useCallback(() => setOpen(true), [setOpen]);
  return {
    open,
    handleClose: handleCloseModal,
    handleOpen: handleOpenModal
  };
};

export default useModal;

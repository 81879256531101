import { useMemo, useCallback, useState } from 'react';
import { computed } from 'mobx';
import type { SelectProps } from '@mui/material/Select';
import { FormControl, Box, Stack, Divider } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { IRange } from '~/mst/models/range';

import CustomRangePicker from './custom_range_picker';
import { MenuItemsStyled, NoOptionLabelStyled, SelectStyled } from './styled';

export const CUSTOM_RANGE = 'CUSTOM_RANGE';

type OptionType = {
  label: string;
  value: {
    from: string | number;
    to?: string | number;
  };
};

type RangeSelectorProps = SelectProps<IRange> & {
  value?: IRange;
  setValue: (value: IRange) => void;
  allowCustom?: boolean;
  allowLive?: boolean;
  size?: 'medium' | 'small';
  options: OptionType[];
  adaptive?: boolean;
};

function RangeSelector({ value, setValue, allowCustom, options, size, adaptive, ...rest }: RangeSelectorProps) {
  const [open, setOpen] = useState(false);
  const [showCustomRange, setShowCustomRange] = useState(false);

  const mappedOptions = useMemo(() => {
    const optionsStringified = options.map((option) => ({ ...option, value: JSON.stringify(option.value) }));
    if (allowCustom) {
      return [
        ...optionsStringified,
        {
          value: CUSTOM_RANGE,
          label: 'Custom Range'
        }
      ];
    }
    return optionsStringified;
  }, [options, allowCustom]);

  const optionValue = useMemo(() => computed(() => mappedOptions.find((item) => item.value === JSON.stringify(value))), [mappedOptions, value]).get();

  const handleOpen = useCallback(() => {
    setOpen(true);
    if (!optionValue && value) {
      setShowCustomRange(true);
    }
  }, [setOpen, value, setShowCustomRange, optionValue]);

  const handleClose = useCallback(() => {
    setOpen(false);
    setTimeout(() => {
      setShowCustomRange(false);
    }, 300);
  }, [setOpen, setShowCustomRange]);

  const handleSetRange = useCallback(
    (newValue) => {
      setValue(newValue);
      setOpen(false);
    },
    [setValue, setOpen]
  );

  const handleChange = useCallback(
    (event) => {
      const newValue = event.target.dataset.value;
      if (newValue === CUSTOM_RANGE) {
        setShowCustomRange(true);
      } else if (newValue) {
        setValue(JSON.parse(newValue));
        setShowCustomRange(false);
        handleClose();
      }
    },
    [setValue, handleClose, setShowCustomRange]
  );

  const handleRenderValue = useCallback(() => {
    if (optionValue) {
      return optionValue.label;
    }
    return value?.toString || null;
  }, [optionValue, value?.toString]);

  const selectedValue = useMemo(() => {
    if (value && !optionValue) {
      return JSON.stringify(CUSTOM_RANGE);
    }
    return optionValue?.value || null;
  }, [value, optionValue]);

  return (
    <FormControl size={size}>
      <SelectStyled
        fullWidth
        {...rest} // eslint-disable-line react/jsx-props-no-spreading
        value={selectedValue}
        open={open}
        onOpen={handleOpen}
        MenuProps={{
          onClose: handleClose
        }}
        size={size}
        $adaptive={adaptive}
        renderValue={handleRenderValue}
      >
        <Stack direction="row">
          <Box>
            {!mappedOptions.length && <NoOptionLabelStyled>No options</NoOptionLabelStyled>}
            {mappedOptions.map((option) => (
              <MenuItemsStyled key={option.label} value={option.value} data-value={option.value} onClick={handleChange}>
                {option.label}
              </MenuItemsStyled>
            ))}
          </Box>
          {showCustomRange && (
            <>
              <Divider flexItem orientation="vertical" />
              <Box>
                <CustomRangePicker range={value} setRange={handleSetRange} onClose={handleClose} />
              </Box>
            </>
          )}
        </Stack>
      </SelectStyled>
    </FormControl>
  );
}

export default observer(RangeSelector);

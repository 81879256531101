import { Card, CardContent } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FieldArray } from 'react-final-form-arrays';
import MCardHeader from '~/components/@material-extend/MCardHeader';

import type { IAlert } from '~/mst/models/alert';
import useLocales from '~/hooks/use_locales';

import { HeaderDeviceWrapperStyled } from '~/pages/device_edit/components/styled';
import DataPointsField from './data_points';

type EditAlertFormType = {
  model: IAlert;
};

function EditAlertForm({ model }: EditAlertFormType) {
  const { t } = useLocales();

  return (
    <Card>
      <HeaderDeviceWrapperStyled>
        <MCardHeader title={t('attributes.alert.rules')} />
      </HeaderDeviceWrapperStyled>
      <CardContent>
        <FieldArray name="data_points">{({ fields }) => <DataPointsField fields={fields} model={model} />}</FieldArray>
      </CardContent>
    </Card>
  );
}

export default observer(EditAlertForm);

import { Theme } from '@mui/material/styles';

export default function Switch(theme: Theme) {
  const isLight = theme.palette.mode === 'light';

  return {
    MuiSwitch: {
      styleOverrides: {}
    }
  };
}

import { Box, Grid } from '@mui/material';
import { OnChange } from 'react-final-form-listeners';
import { useForm } from 'react-final-form';
import { ItemTitle } from '~/pages/device_edit/components';
import FormGrid from '~/components/@material-extend/form_grid';
import Switch from '~/components/final_form/switch';
import useLocales from '~/hooks/use_locales';
import Select from '~/components/final_form/select';
import I18n from '~/utils/i18n';
import { SensorProperty } from '~/pages/device_edit/containers/sensor_form_container/sensors/components';

const PM_CONFIG_OPTIONS = [
  { label: I18n.t('base.labels.off'), value: null },
  { label: I18n.t('thiamis.pm_config.dl'), value: 'dl' },
  { label: I18n.t('thiamis.pm_config.ft'), value: 'ft' },
  { label: I18n.t('thiamis.pm_config.m3'), value: 'm3' }
];

const VOC_UNIT_OPTIONS = [
  { label: I18n.t('thiamis.voc_unit.ppm'), value: 'ppm' },
  { label: I18n.t('thiamis.voc_unit.ppb'), value: 'ppb' }
];

function AirthinxDeviceSettings() {
  const { t } = useLocales();
  const { change } = useForm();
  return (
    <FormGrid sx={{ rowGap: 1 }}>
      <Box>
        <ItemTitle>Aethair LED</ItemTitle>
        <Grid container spacing={2}>
          <Grid item>
            <Switch label={t('attributes.thiamis.enable_led')} name="thiamis.led_enabled" type="number" data-testid="deviceLedEnabledSwitch" />
          </Grid>
          <Grid item>
            <Switch label={t('attributes.thiamis.led_aq')} name="thiamis.led_aq" type="number" data-testid="deviceLedAqSwitch" />
          </Grid>
        </Grid>
      </Box>
      <Box>
        <ItemTitle sx={{ mb: 1 }}>Units</ItemTitle>
        <Grid container spacing={2}>
          <SensorProperty name="thiamis.pm_config">
            <Grid item xs={12} sm={8} lg={6} md={6}>
              <Select
                name="thiamis.pm_config"
                label={t('attributes.thiamis.pm_config')}
                options={PM_CONFIG_OPTIONS}
                data-testid="devicePmConfigSelect"
              />
            </Grid>
          </SensorProperty>
          <SensorProperty name="thiamis.voc_unit">
            <Grid item xs={12} sm={8} lg={6} md={6}>
              <Select name="thiamis.voc_unit" label={t('attributes.thiamis.voc_unit')} options={VOC_UNIT_OPTIONS} data-testid="deviceVocUnitSelect" />
            </Grid>
          </SensorProperty>
        </Grid>
      </Box>
      <OnChange name="thiamis.led_enabled">
        {(value) => {
          if (!value) {
            change(`thiamis.led_aq`, 0);
          }
        }}
      </OnChange>
      <OnChange name="thiamis.led_aq">
        {(value) => {
          if (value) {
            change(`thiamis.led_enabled`, 1);
          }
        }}
      </OnChange>
    </FormGrid>
  );
}

export default AirthinxDeviceSettings;

/* eslint-disable @typescript-eslint/naming-convention */
import some from 'lodash/some';
import intersection from 'lodash/intersection';
import escapeRegExp from 'lodash/escapeRegExp';
import lflow from 'lodash/fp/flow';
import without from 'lodash/fp/without';
import map from 'lodash/fp/map';
import { IFilterableModel } from './model';

export default (self: IFilterableModel) => ({
  get selectedFilters() {
    return self.filterGroups.reduce((acc, next) => {
      const groupFiltered = next.filters
        .map((filter) => (filter.children?.length ? [filter, ...filter.children] : filter))
        .flat()
        .filter((filter) => filter.chosen)
        .map((filter) => filter.title.toLowerCase());
      if (groupFiltered.length) {
        return { ...acc, [next.title]: groupFiltered };
      }

      return acc;
    }, {});
  },
  get hasChosenFilters() {
    return self.filterGroups.some((filterGroup) =>
      filterGroup.filters
        .map((filter) => (filter.children?.length ? [filter, ...filter.children] : filter))
        .flat()
        .some((filter) => filter.chosen)
    );
  },
  get filteredModels() {
    // @ts-ignore
    let models = self.toArray;
    if (self.query) {
      const keywords = lflow(
        // @ts-ignore
        without(''),
        map((keyword: string) => keyword.toLowerCase())
      )(self.query.split(/\s+/));
      models = models.filter((model) => {
        return some(keywords, (kw) => model.searchString.match(escapeRegExp(kw)));
      });
    }

    if (self.hasChosenFilters) {
      models = models.filter((model) => {
        return Object.keys(self.selectedFilters).every(
          (filter) => intersection(model.filterTags, self.selectedFilters[filter]).length === self.selectedFilters[filter].length
        );
      });
    }

    return models;
  }
});

import { alpha } from '@mui/material/styles';
import createGradient from '~/theme/gradients';

import { DARK_MODE, createCustomShadow } from '~/theme/shadows';
import { STATUS_COLORS } from '../../types';

const GREY = {
  0: '#FFFFFF',
  100: '#444',
  200: '#E0E0E0',
  300: '#D6D6D6',
  350: '#FFFFFF',
  400: '#CCCCCC',
  500: '#919EAB',
  600: '#B8B8B8',
  700: '#ADADAD',
  800: '#A3A3A3',
  900: '#999999',
  950: '#303944',
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8)
};

const PRIMARY = {
  lighter: '#006aa4',
  light: '#1298e1',
  main: '#919EAB',
  dark: '#CCCCCC',
  darker: '#41dfff',
  contrastText: '#fff'
};
const SECONDARY = {
  lighter: '#D6E4FF',
  light: '#84A9FF',
  main: '#3366FF',
  dark: '#1939B7',
  darker: '#091A7A',
  contrastText: '#000',
  muted: '#637381'
};
const INFO = {
  lighter: '#D0F2FF',
  light: '#74CAFF',
  main: '#919EAB',
  dark: '#0C53B7',
  darker: '#04297A',
  contrastText: '#000'
};
const SUCCESS = {
  lighter: '#E9FCD4',
  light: '#AAF27F',
  main: '#54D62C',
  dark: '#229A16',
  darker: '#08660D',
  contrastText: GREY[800]
};
const WARNING = {
  lighter: '#FFF7CD',
  light: '#FFE16A',
  main: '#FFC107',
  dark: '#B78103',
  darker: '#7A4F01',
  contrastText: GREY[800]
};
const ERROR = {
  lighter: '#FFE7D9',
  light: '#FFA48D',
  main: '#FF4842',
  dark: '#B72136',
  darker: '#7A0C2E',
  contrastText: '#fff'
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main)
};

const CHART_COLORS = {
  violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
  blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
  green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
  yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
  red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4']
};

const dark = {
  common: { black: '#fff', white: '#919EAB' },
  background: {
    paper: 'rgb(33, 43, 54)',
    default: 'rgb(22, 28, 36)',
    neutral: GREY[950],
    button: '#008bd6',
    buttonHover: '#0C53B7',
    navbar: 'rgb(22, 28, 36)',
    avatar: 'rgb(33, 43, 54)',
    tableHead: 'rgba(145, 158, 171, 0.12)'
  },
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
  info: { ...INFO },
  success: { ...SUCCESS },
  warning: { ...WARNING },
  error: { ...ERROR },
  grey: GREY,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: GREY[500_24],
  statuses: STATUS_COLORS,
  action: {
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: 'rgba(0, 0, 0, 0.5)',
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
    selectedOpacity: 0.3
  },
  themeShadows: {
    navbar: 'none',
    card: 'none',
    filterPanel: createCustomShadow(DARK_MODE).z20
  },
  borders: {
    navbar: {
      borderRight: '1px dashed rgba(255, 255, 255, 0.1)'
    },
    header: {
      borderBottom: '1px dashed rgba(255, 255, 255, 0.1)'
    }
  },
  link: {
    default: '#008ed7'
  },
  scheduler: {
    brandBlue: '#00608F',
    brandGreen: '#3CB773',
    brandRed: '#DF5C4F',
    black: '#000000',
    darkGrey: '#343434',
    grey: '#9a9a9a',
    midGrey: '#CCCCCC',
    white: '#000',
    nodeStatusEmpty: '#ccc',
    nodeGray: '#a9a9a9',
    nodeGreen: '#2fd273',
    redColor: '#d87f77',
    panelGray: '#919191',
    powermodeOff: '#333',
    powermode25: 'linear-gradient(90deg, #2A74BB, #6ea8de)',
    powermode50: 'linear-gradient(90deg, #2A74BB, #6ea8de)',
    powermode75: 'linear-gradient(90deg, #2A74BB, #6ea8de)',
    powermode100: 'linear-gradient(90deg, #2A74BB, #6ea8de)',
    disabledColor: '#b7b7b7',
    autoOn: 'linear-gradient(90deg, #599bd9, #7aafe1)',
    autoOff: 'linear-gradient(90deg, #215b91, #2970b3)',
    textDefault: '#919191',
    timetable: 'repeating-linear-gradient(to right, #444, #444 1px, transparent 1px, transparent)',
    dropdownBg: '#333'
  },
  map: {
    water: '#161c24',
    waterLabelsText: '#444444',
    road: '#000000',
    administrativeCountryText: '#444444',
    administrativeCountryStroke: '#888888',
    landscapeManMade: '#363636',
    landscapeNatural: '#363636',
    roadArterial: '#00ff5b',
    roadHighway: '#555555',
    roadHighwayStroke: '#464646'
  }
};

export default dark;

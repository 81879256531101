import { types as t, Instance } from 'mobx-state-tree';
import WidgetModel from '~/mst/models/widget';
import Layout from '~/mst/models/widget/layout';
import DefaultLayout from './layout';

const RecentAlertsWidgetModel = t
  .compose(
    WidgetModel,
    t.model({
      type: 'RecentAlertsWidget',
      name: 'Recent Alerts',
      layout: t.optional(Layout, DefaultLayout)
    })
  )
  .named('RecentAlertsWidget');

export interface IRecentAlertsWidgetModel extends Instance<typeof RecentAlertsWidgetModel> {}

export default RecentAlertsWidgetModel;

import { Instance, SnapshotOut } from 'mobx-state-tree';
import CollectionArrayProcessor from '~/mst/models/abstract/collection_array_processor';
import model from './model';
import views from './views';

const UserCalibrationsStore = model.views(views);
export interface IUserCalibrationsStore extends Instance<typeof UserCalibrationsStore> {}
export interface IUserCalibrationsStoreSnapshot extends SnapshotOut<typeof UserCalibrationsStore> {}

export default CollectionArrayProcessor(UserCalibrationsStore);

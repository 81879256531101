/* eslint-disable @typescript-eslint/no-use-before-define */
import { types as t, Instance } from 'mobx-state-tree';
import ThiamisDevice from '~/mst/models/device/thiamis';
import BitConfigType from '~/mst/common/bit_config_type';
import UserCalibrations from './user_calibrations';

const AirthinxDeviceModel = t
  .compose(
    ThiamisDevice,
    t.model({
      led_enabled: t.maybeNull(BitConfigType),
      led_aq: t.maybeNull(BitConfigType),
      extensions: t.frozen(),
      pm_config: t.maybeNull(t.frozen()),
      voc_unit: t.maybeNull(t.frozen()),
      profiles: t.frozen(),
      user_calibration: t.maybeNull(UserCalibrations)
    })
  )
  .named('AirThinx');

export interface IAirthinxDeviceModel extends Instance<typeof AirthinxDeviceModel> {}

export default AirthinxDeviceModel;

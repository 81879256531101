import { styled, Stack } from '@mui/material';

const SettingsWrapperStyled = styled(Stack)(({ theme }) => ({
  minWidth: 'auto',
  px: '4px',
  position: 'absolute',
  top: -15,
  left: 0,
  zIndex: 10,
  label: {
    opacity: 0.75,
    '&:hover': {
      opacity: 1
    }
  },
  [theme.breakpoints.down('lg')]: {
    left: 0,
    right: 0,
    justifyContent: 'center'
  }
}));

export default SettingsWrapperStyled;

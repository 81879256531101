import React, { useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet-async';

import LoadingBox from '~/components/loading_box';
import useFetch from '~/hooks/use_fetch';
import useMst from '~/hooks/use_mst';
import Container from '~/components/container';
import DashboardHeader from './header';
import Widgets from './widgets';

function Dashboard() {
  const { dashboards } = useMst();
  const { isFetched } = useFetch(dashboards);

  const { id } = useParams();

  const dashboard = dashboards.getById(id);

  const [addWidgetSidebar, setAddWidgetSidebar] = useState(false);

  const handleVisibleSidebar = useCallback(() => {
    setAddWidgetSidebar(!addWidgetSidebar);
  }, [addWidgetSidebar]);

  return (
    <>
      <Helmet>
        {/* TODO: allow to set dynamic titles */}
        <title>{`${dashboard ? dashboard?.name : 'Dashboard'} | Environet Console`}</title>
      </Helmet>
      <Container maxWidth={false} disableGutters>
        {isFetched ? (
          <>
            <DashboardHeader widgetSidebarVisible={addWidgetSidebar} handleAddWidget={handleVisibleSidebar} />
            <Widgets dashboard={dashboard} addWidget={handleVisibleSidebar} />
          </>
        ) : (
          <LoadingBox />
        )}
      </Container>
    </>
  );
}

export default observer(Dashboard);

/* eslint-disable react/jsx-props-no-spreading */
import { filterText, renderInput, renderOption } from '~/components/organizations_select';
import { AutocompleteOrganizationsStyled } from './styled';

import NavigateDropdwon from './index';

function OrganizationsNavigateDropdown(props) {
  return (
    <AutocompleteOrganizationsStyled>
      <NavigateDropdwon filterText={filterText} renderInput={renderInput} renderOption={renderOption} {...props} />
    </AutocompleteOrganizationsStyled>
  );
}

export default OrganizationsNavigateDropdown;

import React, { useCallback, useState } from 'react';
import { IconButton, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';

import ConfirmDialog from '~/components/confirm_dialog';
import useActionsPopover from '~/hooks/react_grid/use_actions_popover';
import type { ISensor } from '~/mst/models/device/sensor';
import toasts from '~/utils/toasts';
import useLocales from '~/hooks/use_locales';

const ICON = {
  mr: 2,
  width: 20,
  height: 20
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function SensorActions({ sensor }: { sensor: ISensor }) {
  const [isDeleting, setIsDeleting] = useState(false);
  const { ActionsMenu, handleClose, handleOpen, open } = useActionsPopover();
  const { t } = useLocales();

  const handleStartDelete = useCallback(() => {
    handleClose();
    setIsDeleting(true);
  }, [handleClose]);

  const handleCancelDelete = useCallback((event) => {
    event.stopPropagation();
    setIsDeleting(false);
  }, []);

  const handleDelete = useCallback(
    async (event) => {
      event.stopPropagation();
      await sensor.node.devices.destroy(sensor);
      toasts.success(t('notifications.success.forms.update_node'));
      handleClose();
    },
    [sensor, handleClose, t]
  );

  return (
    <>
      <IconButton onClick={handleOpen} sx={{ visibility: open ? 'visible' : 'auto' }}>
        <MoreVertIcon />
      </IconButton>
      <ActionsMenu>
        <MenuItem disabled={sensor.isSyncing} onClick={handleStartDelete} sx={{ color: 'error.main' }}>
          <DeleteIcon sx={ICON} />
          Delete
        </MenuItem>
      </ActionsMenu>
      <ConfirmDialog
        open={isDeleting}
        onClose={handleCancelDelete}
        onConfirm={handleDelete}
        title={t(`device_monitor.dialogs.sensor_delete.title`)}
        content={t(`device_monitor.dialogs.sensor_delete.content`)}
        confirmLabel={t(`base.buttons.delete`)}
        confirmButtonType="error"
      />
    </>
  );
}

export default SensorActions;

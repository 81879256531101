/* eslint-disable */
import { useTheme } from '@mui/material/styles';
import { BoxProps } from '@mui/material';
import LabelRootStyled from './styled';

export type LabelColor = 'default' | 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error';
export type LabelVariant = 'filled' | 'outlined' | 'ghost';

interface Props extends BoxProps {
  color?: LabelColor;
  variant?: LabelVariant;
}

export default function Label({ color = 'default', variant = 'ghost', children, sx }: Props) {
  const theme = useTheme();

  return (
    <LabelRootStyled ownerState={{ color, variant }} sx={sx} theme={theme}>
      {children}
    </LabelRootStyled>
  );
}

import { WidgetTypes } from '~/mst/models/widget/types';
import DeviceIcon from '~/assets/icons/widgets/ic_device_widget';
import ChartIcon from '~/assets/icons/widgets/ic_chart_widget';
import HeatmapIcon from '~/assets/icons/widgets/ic_heatmap_widget';
import MapIcon from '~/assets/icons/widgets/ic_map_widget';

const WIDGET_TYPES = [
  {
    label: 'chart_widget',
    type: WidgetTypes.ChartWidget,
    Icon: ChartIcon
  },
  {
    label: 'node_widget',
    type: WidgetTypes.NodeWidget,
    Icon: DeviceIcon
  },
  {
    label: 'heatmap_widget',
    type: WidgetTypes.HeatmapWidget,
    Icon: HeatmapIcon
  },
  {
    label: 'map_widget',
    type: WidgetTypes.MapWidget,
    Icon: MapIcon
  }
];

export default WIDGET_TYPES;

/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback, useMemo } from 'react';
import { getSnapshot } from 'mobx-state-tree';
import { Form as FinalForm } from 'react-final-form';
import { observer } from 'mobx-react-lite';
import { computed } from 'mobx';
import I18n from '~/utils/i18n';
import useNavigate from '~/hooks/use_navigate';
import useMst from '~/hooks/use_mst';
import useAuth from '~/hooks/use_auth';
import useLocales from '~/hooks/use_locales';
import { useValidationSchema } from '~/hooks/use_validate';
import toasts from '~/utils/toasts';
import { IReport } from '~/mst/models/report';
import Yup, { parseDate } from '~/utils/yup';

import { captureException } from '~/services/sentry';
import Form from './form';

const ReportSchema = Yup.object().shape({
  name: Yup.string().required(I18n.t('validation.required', { name: 'Report name' })),
  organization_id: Yup.string().nullable().required('Organization is required'),
  configuration: Yup.object()
    .shape({
      from: Yup.date().transform(parseDate).required('Required'),
      to: Yup.date().transform(parseDate).nullable(),
      node_ids: Yup.array().required('Required').min(1, 'Device is required')
    })
    .required('This field is required.')
});

function ReportForm({ model }: { model: IReport }) {
  const { navigate } = useNavigate();
  const { t } = useLocales();

  const { reports } = useMst();
  const { auth } = useAuth();

  const validate = useValidationSchema(ReportSchema);

  const handleFormSubmit = useCallback(
    async (values) => {
      const {
        configuration: { node_ids: nodeIds, subscribers }
      } = values;
      const payload = {
        ...values,
        configuration: {
          ...values.configuration,
          node_ids: nodeIds.map((option) => (option?.value ? option?.value : option)),
          subscribers: subscribers?.map((option) => (option?.value ? option?.value : option))
        }
      };
      try {
        if (model.isNew) {
          await reports.create(payload);
        } else {
          await model.update(payload);
        }
        navigate(`reports`);
        toasts.success(t(`notifications.success.forms.${model.isNew ? 'create_report' : 'update_report'}`));
      } catch (e) {
        captureException(e);
        toasts.error(t('notifications.errors.server_error'));
      }
    },
    [model, t, navigate, reports]
  );

  const initialValues = useMemo(
    () =>
      computed(() => ({
        ...getSnapshot(model),
        ...(model.isNew && { organization_id: auth.organizationId, configuration: { standard: 'aqi', node_ids: [] } })
      })),
    [model, auth.organizationId]
  ).get();

  return (
    <FinalForm
      validate={validate}
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      subscription={{ submitting: true, pristine: true, dirty: true }}
      render={({ handleSubmit, invalid, submitting }) => <Form handleSubmit={handleSubmit} model={model} invalid={invalid} submitting={submitting} />}
    />
  );
}

export default observer(ReportForm);

import { types as t, Instance, SnapshotOut } from 'mobx-state-tree';
import WidgetModel from '~/mst/models/widget';
import NodesStore from '~/mst/models/nodes';

const ChartTableWidgetModel = t
  .compose(
    WidgetModel,
    t.model({
      type: 'ChartTableWidget',
      node_id: t.string,
      show_zones: t.optional(t.boolean, false),
      show_data_gaps: t.optional(t.boolean, false),
      nodes: t.maybeNull(NodesStore)
    })
  )
  .named('ChartTableWidget');

export interface IChartTableWidgetModel extends Instance<typeof ChartTableWidgetModel> {}
export interface IChartTableWidgetModelSnapshotOut extends SnapshotOut<typeof ChartTableWidgetModel> {}

export default ChartTableWidgetModel;

import { styled, Stack, Box, Typography } from '@mui/material';
import { transientOptions } from '~/utils/styled';
import { SIDEBAR_WIDTH } from '../styled';

export const ParamItemContainerStyled = styled(
  Stack,
  transientOptions
)(({ theme, $isExpanded, $isActive, $isOffline, $isSyncing }) => ({
  padding: '0.75rem 0.25rem',
  cursor: 'pointer',
  position: 'relative',
  '&:hover': {
    background: theme.palette.grey[500_8],
    '.MuiButtonBase-root': {
      opacity: 1
    }
  },
  ...($isOffline && {
    opacity: 0.6
  }),
  ...($isSyncing && {
    opacity: 0.5,
    cursor: 'default'
  }),
  ...(!$isExpanded && {
    display: 'none'
  }),
  ...($isActive && {
    background: theme.palette.grey[100],
    '&:hover': {
      background: theme.palette.grey[100],
      '.MuiButtonBase-root': {
        opacity: 1
      }
    }
  })
}));

export const ParamLabelStyled = styled(Typography)(() => ({
  lineHeight: 1,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  paddingRight: '2px',
  display: 'flex',
  flexDirection: 'column',
  gap: '6px',
  '.paramValue': {
    fontWeight: 600
  }
}));

export const ParamValueStyled = styled(Typography)(() => ({
  lineHeight: 1,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  paddingRight: '2px',
  flex: '1 0 50%',
  textAlign: 'right'
}));

export const BoxOverflowedStyled = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
  justifyContent: 'space-between',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
}));

export const CatcherStyled = styled(
  Box,
  transientOptions
)(({ $isExpanded }) => ({
  ...($isExpanded && {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'rgba(0, 0, 0, 0.5)',
    zIndex: '9998'
  })
}));

export const SidebarOuterStyled = styled('div')(({ theme }) => ({
  position: 'relative',
  background: theme.palette.background.paper,
  flex: 1,
  minHeight: 'calc(579px - 80px)',
  [theme.breakpoints.down('md')]: {
    minHeight: 0
  }
}));

export const SidebarInnerStyled = styled(
  Box,
  transientOptions
)(({ theme, $isExpanded, $width }) => ({
  position: 'absolute',
  right: 0,
  top: 0,
  bottom: 0,
  width: $width || SIDEBAR_WIDTH,
  zIndex: 1000,
  display: 'flex',
  flexDirection: 'column',
  transition: 'all .2s ease',
  [theme.breakpoints.down('md')]: {
    position: 'fixed',
    right: 0,
    top: '0',
    maxHeight: '100%',
    bottom: '0',
    overflow: 'hidden',
    width: $width || SIDEBAR_WIDTH,
    background: theme.palette.background.paper,
    zIndex: '9999',
    boxShadow: '0 5px 22px rgb(0 0 0 / 30%)',
    ...(!$isExpanded && {
      width: '0'
    })
  }
}));

export const SidebarContainerStyled = styled(
  Box,
  transientOptions
)(({ theme, $isExpanded, $width }) => ({
  display: 'flex',
  overflow: 'hidden',
  flexDirection: 'column',
  width: '100%',
  maxWidth: '0',
  transition: 'all .2s ease',
  borderLeft: `1px solid ${theme.palette.grey[100]}`,
  ...($isExpanded && {
    maxWidth: $width || SIDEBAR_WIDTH
  }),
  [theme.breakpoints.down('md')]: {
    width: '0 !important',
    maxWidth: '0 !important',
    overflow: 'hidden',
    ...($isExpanded && {
      maxWidth: '0'
    })
  },
  '@media print': {
    display: 'none'
  }
}));

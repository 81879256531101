/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import React from 'react';
import { Field as FinalField, FieldMetaState } from 'react-final-form';
import { SxProps } from '@mui/system';
import isEmpty from 'lodash/isEmpty';
import toNumber from 'lodash/toNumber';
import TextFieldStyled from './styled';

interface Props {
  name: string;
  label?: string;
  sx?: SxProps;
  parse?: ((value: any, name: string) => any) | undefined;
  format?: ((value: any, name: string) => any) | undefined;
  multiline?: boolean;
  helperText?: React.ReactNode;
  disabled?: boolean;
  formatOnBlur?: boolean;
  noHelperText?: boolean;
  type?: string;
  isEqual?: (prev: any, next: any) => boolean;
  validate?: (value?: any, allValues?: Object, meta?: FieldMetaState<any>) => any;
}

const numberParse = (value) => (isEmpty(value) ? null : toNumber(value));

export default function RFFTextInput(props: Props) {
  const { name, validate, formatOnBlur, helperText, type, parse, format, isEqual, noHelperText = false, ...rest } = props;
  return (
    <FinalField name={name} validate={validate} formatOnBlur={formatOnBlur} parse={type === 'number' ? numberParse : parse} format={format}>
      {({ input, meta }) => {
        const hasError = meta.touched && meta.error;
        return (
          <TextFieldStyled {...rest} {...input} fullWidth helperText={noHelperText ? null : hasError || helperText} error={!!hasError} type={type} />
        );
      }}
    </FinalField>
  );
}

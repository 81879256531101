import { observer } from 'mobx-react-lite';
import { INode } from '~/mst/models/node';

import { Connectivity, Location } from '~/pages/device_edit/modules';
import FormContainer from '~/pages/device_edit/containers/form_container';
import { HeaderCommon, BodyModule } from '~/pages/device_edit/components';
import { ModulesContainerStyled } from '~/pages/device_edit/components/styled';
import DescriptionForm from '../../modules/description';

type ThiamisFormType = {
  node: INode;
  handleSubmit: VoidFunction;
};

function AirthinxForm({ node, handleSubmit }: ThiamisFormType) {
  return (
    <FormContainer handleSubmit={handleSubmit} model={node?.thiamis}>
      <ModulesContainerStyled>
        <HeaderCommon node={node} icon={node?.thiamis?.name} />
        <BodyModule title="Settings" columns={1} expandable expanded>
          <DescriptionForm />
          <Connectivity node={node} />
          <Location node={node} />
        </BodyModule>
      </ModulesContainerStyled>
    </FormContainer>
  );
}

export default observer(AirthinxForm);

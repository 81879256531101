import React, { useEffect, useMemo } from 'react';
import { Box, Card } from '@mui/material';
import { useParams } from 'react-router-dom';
import { autorun } from 'mobx';
import { observer } from 'mobx-react-lite';

import useNavigate from '~/hooks/use_navigate';
import Container from '~/components/container';
import useLocales from '~/hooks/use_locales';
import Breadcrumbs from '~/components/breadcrumbs';
import LoadingBox from '~/components/loading_box';
import useMst from '~/hooks/use_mst';
import useFetch from '~/hooks/use_fetch';
import CertificateModel from '~/mst/models/certificate';
import FormContainer from './form_container';

function EditCertificatePage() {
  const { t } = useLocales();
  const { id } = useParams();
  const { navigate } = useNavigate();
  const { nodes, certificates, templates, organizations } = useMst();
  const certificate = id ? certificates.getById(id) : CertificateModel.create({});

  useFetch(certificates);
  useFetch(templates);
  useFetch(organizations, null, { excludeParams: ['organization_id'] });

  useEffect(() => {
    const disposer = autorun(() => {
      if (!nodes.isFetched && !nodes.isFetching && templates.isFetched) {
        nodes.fetch({
          organization_id: templates.blue?.organization_id
        });
      }
    });
    return () => {
      disposer();
      nodes.abortFetching();
    };
  }, [templates, nodes]);

  const links = useMemo(
    () => [
      { title: 'Certificates', to: 'certificates' },
      { title: t(`certificates.${id ? 'edit' : 'new'}`), to: `certificates/${id || 'create'}` }
    ],
    [id, t]
  );

  if (templates.isFetched && !templates.blue) {
    return navigate('certificates');
  }

  return (
    <Container>
      <Box mb={3}>
        <Breadcrumbs links={links} />
      </Box>
      <Card>
        {certificates.isFetched && templates.isFetched && nodes.isFetched ? (
          <FormContainer model={certificate} certificates={certificates} nodes={nodes} template={templates.blue} />
        ) : (
          <LoadingBox />
        )}
      </Card>
    </Container>
  );
}

export default observer(EditCertificatePage);

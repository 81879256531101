/* eslint-disable react/no-unstable-nested-components */
import React, { useMemo } from 'react';
import { Grid, VirtualTable, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import useLocales from '~/hooks/use_locales';
import { IAlertSnapshotOut } from '~/mst/models/alert';

type ActionsModalProps = {
  selected: IAlertSnapshotOut[];
};

const MAX_ALERTS_HEIGHT = 270;

function ModalTable({ selected }: ActionsModalProps) {
  const { t } = useLocales();
  const Root = useMemo(
    () =>
      React.memo((props: Grid.RootProps) => {
        // eslint-disable-next-line react/jsx-props-no-spreading
        return <Grid.Root {...props} style={{ maxHeight: MAX_ALERTS_HEIGHT, height: 'unset' }} />;
      }),
    []
  );

  const columns = useMemo(
    () => [
      { name: 'name', title: t('attributes.node.name') },
      { name: 'ownerName', title: t('thiamis.owner') }
    ],
    [t]
  );

  const tableHeight = useMemo(() => {
    let height = (selected.length + 1) * 54;

    if (height > MAX_ALERTS_HEIGHT) {
      height = MAX_ALERTS_HEIGHT;
    }
    return height;
  }, [selected]);

  return (
    <Grid rows={selected} columns={columns} rootComponent={Root}>
      <VirtualTable height={tableHeight} />
      <TableHeaderRow showSortingControls={false} />
    </Grid>
  );
}

export default ModalTable;

/* eslint-disable react/no-array-index-key */
import { autorun } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import type { IFilterable } from '~/mst/models/abstract/filterable';
import type { IFetchable } from '~/mst/models/abstract/fetchable';

import { SearchWrapperStyled } from './styled';
import FiltersPicker from './filters_picker';
import FiltersSearch from './filters_search';

type FiltersType = {
  collection: IFilterable & IFetchable;
  groups: string[];
  handleRefresh?: VoidFunction;
  sorting: {
    [key: string]: number;
  };
};

function Filters({ collection, groups, sorting, handleRefresh }: FiltersType) {
  useEffect(() =>
    autorun(() => {
      if (collection.isFetched && !collection.isFetching && isEmpty(groups) === false) {
        collection.buildFilters(groups);
      }
    })
  );

  return (
    <SearchWrapperStyled>
      <FiltersSearch collection={collection} />
      <FiltersPicker collection={collection} sorting={sorting} handleRefresh={handleRefresh} />
    </SearchWrapperStyled>
  );
}

export default observer(Filters);

import { FieldArray, FieldArrayRenderProps } from 'react-final-form-arrays';
import { Box } from '@mui/material';
import { observer } from 'mobx-react-lite';
import type { IAlert } from '~/mst/models/alert';
import { DividerStyled } from '~/pages/alert_edit/conditions_block/styled';
import ConditionsField from './conditions';

export type ConditionType = {
  func: string;
  device: string;
  dataPoint: string;
  value: string | number;
};

type DataPointFieldType = FieldArrayRenderProps<ConditionType, HTMLInputElement> & {
  name: string;
  model: IAlert;
  index: number;
};

function DataPointField(props: DataPointFieldType) {
  const { name, model, fields, index } = props;

  return (
    <Box>
      <FieldArray name={`${name}.conditions`}>{({ fields: conditions }) => <ConditionsField fields={conditions} model={model} />}</FieldArray>
      {fields.value.length - 1 !== index && (
        <Box alignItems="center" sx={{ width: '100%' }}>
          <DividerStyled orientation="vertical" flexItem>
            OR
          </DividerStyled>
        </Box>
      )}
    </Box>
  );
}

export default observer(DataPointField);

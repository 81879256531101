import { useCallback, useMemo } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { observer } from 'mobx-react-lite';
import Yup from '~/utils/yup';
import { IDashboard } from '~/mst/models/dashboard';
import useLocales from '~/hooks/use_locales';
import toasts from '~/utils/toasts';
import { useValidationSchema } from '~/hooks/use_validate';
import Modal from '~/components/modal';
import { captureException } from '~/services/sentry';
import EditForm from './form';

type DashboardModalProps = {
  handleModalClose: () => void;
  dashboard: IDashboard;
  open: boolean;
};

function DashboardModal({ dashboard, handleModalClose, open }: DashboardModalProps) {
  const { t } = useLocales();

  const EditDashboardSchema = Yup.object().shape({
    name: Yup.string()
      .required(t('validation.required', { name: 'Name' }))
      .min(3, t('validation.short', { name: 'Name' }))
      .max(50, t('validation.long', { name: 'Name' }))
  });

  const validate = useValidationSchema(EditDashboardSchema);

  const initialValues = useMemo(
    () => ({
      name: dashboard?.name,
      description: dashboard?.description
    }),
    [dashboard]
  );

  const handleSave = useCallback(
    async (values) => {
      try {
        await dashboard.update(values);
        toasts.success(t(`notifications.success.forms.update_dashboard`));
        handleModalClose();
      } catch (e) {
        captureException(e);
        toasts.error(t('notifications.errors.server_error'));
      }
    },
    [dashboard, handleModalClose, t]
  );

  return (
    <Modal open={open} handleClose={handleModalClose} title="Edit Dashboard">
      <FinalForm onSubmit={handleSave} initialValues={initialValues} validate={validate}>
        {({ handleSubmit, invalid }) => (
          <EditForm submitting={dashboard.isSyncing} invalid={invalid} handleSubmit={handleSubmit} handleClose={handleModalClose} />
        )}
      </FinalForm>
    </Modal>
  );
}

export default observer(DashboardModal);

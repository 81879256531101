import { styled } from '@mui/material/styles';

const LoadingRootStyled = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.default
}));

export default LoadingRootStyled;

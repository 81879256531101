/* eslint-disable @typescript-eslint/naming-convention */
import { IAlertModel } from '../model';

export default (self: IAlertModel) => {
  function alertStatusFilterTags() {
    const tags: string[] = [self.statusType()];
    return tags.map((tag) => tag.toLowerCase());
  }
  return {
    get alertStatusFilterTags() {
      return alertStatusFilterTags();
    },
    get filterTags() {
      return [...self.alertStatusFilterTags];
    },
    get searchString() {
      const { name, description } = self;
      return [name, description].join(' ').toLowerCase();
    }
  };
};

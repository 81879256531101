/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactNode } from 'react';
import { SxProps } from '@mui/material';
import MenuPopover, { Props as MenuProps } from '~/components/menu_popover';

interface ActionsMenuProps {
  // eslint-disable-next-line react/require-default-props
  sx?: SxProps;
  children: ReactNode;
  // eslint-disable-next-line react/require-default-props
  menuProps?: MenuProps;
}
const useActionsPopover = () => {
  const [open, setOpen] = React.useState<HTMLElement | null>(null);

  const handleOpen = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setOpen(event.currentTarget);
  }, []);

  const handleClose = React.useCallback((event?) => {
    event?.stopPropagation();
    setOpen(null);
  }, []);

  const ActionsMenu = React.useMemo(
    () =>
      React.memo(({ children, sx, menuProps }: ActionsMenuProps) => (
        <MenuPopover
          disabledArrow={false}
          anchorEl={open}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          arrow="right-top"
          {...(menuProps || {})}
          sx={{
            mt: -1,
            width: 160,
            '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
            ...sx
          }}
          open={Boolean(open)}
          onClose={handleClose}
        >
          {children}
        </MenuPopover>
      )),
    [handleClose, open]
  );
  return {
    ActionsMenu,
    handleOpen,
    handleClose,
    open
  };
};

export default useActionsPopover;

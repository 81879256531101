import { types as t, Instance, SnapshotOut } from 'mobx-state-tree';
import { ModelV1 as Model } from '~/mst/models/abstract/model';

const TemplateModel = t
  .compose(
    t.model({
      _type: t.frozen('template'),
      name: t.optional(t.string, ''),
      description: t.optional(t.string, ''),
      actions: t.frozen(t.array(t.string)),
      organization_id: t.maybeNull(t.string)
    }),
    Model
  )
  .named('Template');

export interface ITemplateModel extends Instance<typeof TemplateModel> {}
export interface ITemplateModelSnapshotOut extends SnapshotOut<typeof TemplateModel> {}

export default TemplateModel;

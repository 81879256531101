/* eslint-disable import/prefer-default-export */
import { AtLeastOne } from '../types';
import request from '../request';

type FetchNodesIncludes = 'data_points' | 'templates' | 'user_metadata' | 'profiles' | 'configuration';

type SearchStatsParams = Partial<{
  organization_id: string;
}>;

type SearchNodesParams = Partial<{
  organization_id: string;
  path: string;
  last: number;
  includes: FetchNodesIncludes[];
}> &
  AtLeastOne<{
    node_id: string | string[];
    type: 'thiamis' | 'alert' | 'report';
  }>;

export function nodes(params: SearchNodesParams, options) {
  return [request, 'post', 'search/nodes', params, options];
}

export function online(params: SearchNodesParams, options) {
  return [request, 'post', 'search/online', params, options];
}

export function status(params: SearchNodesParams, options) {
  return [request, 'post', 'search/status', params, options];
}

export type SearchDataPointsParams = Partial<{
  from: number; // unix timestamp in milliseconds
  to: number; // unix timestamp in milliseconds, can be skiped to fetch most recent measurements if only `from` is passed
  last: number; // number of last measurements to fetch
  path: string[]; // for example to fetch PM/PM10/PM25 across all devices path: ['132:18', '132:6', '132:5']
}> &
  AtLeastOne<{
    data_point_id: string[];
    node_id: string[];
  }>;

export function dataPoints(params: SearchDataPointsParams) {
  return [request, 'post', 'search/data_points', params];
}

export function measurements(params: SearchDataPointsParams) {
  return [request, 'post', 'search/measurements', params];
}

export function stats(params?: SearchStatsParams) {
  return [request, 'post', 'search/stats', params];
}

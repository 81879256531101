/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import useLocales from '~/hooks/use_locales';
import DeviceParameter from '~/components/device_parameter';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

type DeviceZoneType = {
  timezone: string;
  iconLeft?: boolean;
};

function DeviceZone({ timezone, iconLeft = false }: DeviceZoneType) {
  const { t } = useLocales();
  const [localDeviceTime, setLocalDeviceTime] = useState('');

  const updateLocalTime = useCallback(() => {
    if (timezone) {
      return DateTime.now().setLocale('en-US').setZone(timezone).toLocaleString(DateTime.TIME_SIMPLE);
    }
    return '';
  }, [timezone]);

  useEffect(() => {
    setLocalDeviceTime(updateLocalTime());
    const timer = setInterval(() => {
      setLocalDeviceTime(updateLocalTime());
    }, 60 * 1000);
    return () => {
      clearInterval(timer);
    };
  }, [timezone, updateLocalTime]);

  return (
    <DeviceParameter
      title={localDeviceTime}
      tooltipTitle={t('recent_measurements_widget.local_time_title', { timezone })}
      value={localDeviceTime}
      IconComponent={AccessTimeIcon}
      iconWidth="18px"
      iconHeight="100%"
      iconLeft={iconLeft}
    />
  );
}

export default DeviceZone;

import React, { useCallback } from 'react';
import { useField } from 'react-final-form';
import throttle from 'lodash/throttle';
import { Box } from '@mui/material';
import { observer } from 'mobx-react-lite';
import AceEditor from 'react-ace';
import useAuth from '~/hooks/use_auth';
import { THEMES } from '~/utils/constants';
import 'ace-builds/src-noconflict/mode-lua';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/theme-github_dark';

function FilesEditor() {
  const { auth } = useAuth();
  const {
    input: { value: content, onChange }
  } = useField('content', {
    subscription: { value: true }
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChange = useCallback(throttle(onChange, 300), []);
  return (
    <Box sx={{ width: '100%', height: '100%' }} id="editor">
      <AceEditor
        mode="lua"
        width="100%"
        height="100%"
        theme={auth.theme === THEMES.DARK ? 'github_dark' : 'github'}
        value={content}
        onChange={handleChange}
        editorProps={{ $blockScrolling: true }}
      />
    </Box>
  );
}

export default observer(FilesEditor);

/* eslint-disable @typescript-eslint/naming-convention */
import pick from 'lodash/pick';
import { getStore } from '~/mst/utils';
import type { IReportModel } from './model';

export default (self: IReportModel) => {
  return {
    get owner() {
      return getStore(self).organizations.getById(self.organization_id);
    },
    get creator() {
      return getStore(self).users.getById(self.user_id);
    },
    get searchString() {
      const searchFields = ['name', 'description'];
      return Object.values(pick(self, searchFields)).join(' ').toLowerCase();
    }
  };
};

import React, { useCallback } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { Grid, Stack } from '@mui/material';
import useLocales from '~/hooks/use_locales';
import useNavigate from '~/hooks/use_navigate';
import Filters from '~/components/filters';
import useMst from '~/hooks/use_mst';
import ButtonAdaptive from '~/components/adaptive_button';

function FormTop() {
  const { t } = useLocales();
  const { navigate } = useNavigate();
  const { reports } = useMst();

  const handleCreateNew = useCallback(() => {
    navigate(`reports/create`);
  }, [navigate]);

  return (
    <Grid container spacing={2} sx={{ py: 2, px: 3 }}>
      <Grid item xs={12} sm={4} lg={6} md={6}>
        <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }}>
          <Filters collection={reports} />
        </Stack>
      </Grid>
      <Grid item xs={12} sm={8} lg={6} md={6} sx={{ textAlign: 'right' }}>
        <ButtonAdaptive variant="contained" size="large" startIcon={<AddIcon />} $mobileFullWidth onClick={handleCreateNew} breakpoint="md">
          {t('reports.create_new')}
        </ButtonAdaptive>
      </Grid>
    </Grid>
  );
}

export default FormTop;

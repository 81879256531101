/* eslint-disable import/prefer-default-export */
import { categories } from '~/pages/device_monitor/store/chart_table_widget/views/wind_rose';

export const defaultChartOptions = {
  chart: {
    zooming: {
      mouseWheel: {
        enabled: false
      }
    },
    polar: true,
    type: 'column'
  },
  colors: ['#71B6E1', '#476FA6', '#81BCB9', '#419DA6', '#909ED0', '#69598E'],
  title: {
    text: null
  },
  pane: {
    size: '85%'
  },
  legend: {
    align: 'right',
    verticalAlign: 'top',
    y: 100,
    layout: 'vertical'
  },
  xAxis: {
    tickmarkPlacement: 'on',
    categories
  },
  yAxis: {
    min: 0,
    endOnTick: false,
    showLastLabel: true,
    title: {
      text: 'Frequency (%)'
    },
    labels: {
      formatter() {
        return `${this.value}%`;
      }
    },
    reversedStacks: false
  },
  exporting: {
    enabled: false
  },
  credits: {
    enabled: false
  },
  tooltip: {
    valueSuffix: '%',
    followPointer: true
  },
  noData: {
    style: {
      fontWeight: 'bold',
      fontSize: '15px'
    }
  },
  plotOptions: {
    series: {
      stacking: 'normal',
      shadow: false,
      groupPadding: 0,
      pointPlacement: 'on'
    }
  }
};

import { TableCell, Box } from '@mui/material';
import { observer } from 'mobx-react-lite';
import useMst from '~/hooks/use_mst';
import useFetch from '~/hooks/use_fetch';
import { DetailRowStyled } from '~/components/react_grid/styled';
import { INodeModelSnapshotOut } from '~/mst/models/node/model';
import { DevicesPopover } from '~/pages/organizations/grid_container/devices_formatter';

function GridDetailContainer({ row }: { row: INodeModelSnapshotOut }) {
  const { id } = row;
  const { partnerships } = useMst();
  const partnership = partnerships.getById(id);

  const { isFetching } = useFetch(partnership?.nodes, { organization_id: partnership.organizationId, includes: ['configuration'] });

  const devicesCount = partnership?.nodes?.toArray.reduce((acc, node) => {
    if (node.shared_to?.includes(partnership.partnerId)) {
      // eslint-disable-next-line no-param-reassign
      acc += 1;
    }
    return acc;
  }, 0);

  return (
    <DetailRowStyled>
      <TableCell />
      <TableCell />
      <TableCell colSpan={2} sx={{ opacity: isFetching ? 0.25 : 1 }}>
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
          {devicesCount ? <DevicesPopover count={devicesCount} title="Shared" /> : 'No Shared Devices'}
        </Box>
      </TableCell>
      <TableCell />
    </DetailRowStyled>
  );
}

export default observer(GridDetailContainer);

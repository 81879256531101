import { DateTime } from 'luxon';
import { getParent, getIdentifier } from 'mobx-state-tree';
import compact from 'lodash/compact';
import flatten from 'lodash/flatten';
import groupBy from 'lodash/fp/groupBy';
import flow from 'lodash/fp/flow';
import map from 'lodash/fp/map';
import type { IDeviceMonitor } from '../..';
import type { IChartTableWidgetStore } from '..';

export default (self: IChartTableWidgetStore) => ({
  get node() {
    return self.nodes?.getById(self.node_id);
  },
  get data() {
    const report = getParent<IDeviceMonitor>(self, 3);
    return compact(report?.params.selectedParamsChart?.map((id) => self.node?.data_points?.getById(id)));
  },
  get isLoading() {
    return self?.node?.data_points?.toArray.some((dp) => dp.isFetching);
  },
  get tableColumns() {
    const report = getParent<IDeviceMonitor>(self, 3);
    const data = report?.params.selectedParamsTable?.map((id) => self.node?.data_points?.getById(id));
    return [
      { name: 'ts', title: 'Timestamp', format: DateTime.DATETIME_SHORT_WITH_SECONDS },
      ...data.map((dp) => ({ name: getIdentifier(dp), title: dp.presentName }))
    ];
  },
  get tableData() {
    const report = getParent<IDeviceMonitor>(self, 3);
    const data = report?.params.selectedParamsTable?.map((id) => self.node?.data_points?.getById(id));
    return flow(
      compact,
      flatten,
      groupBy('ts'),
      map((columns, ts) => ({
        timezone: report.nodeWidget?.node?.timezone,
        ts,
        ...columns.reduce((acc, item) => {
          // eslint-disable-next-line no-param-reassign
          acc = { ...acc, ...item };
          return acc;
        }, {})
      }))
    )(data.map((dp) => dp.measurements.map(([ts, value]) => ({ ts, [getIdentifier(dp)]: value }))));
  }
});

import React, { useCallback, useMemo } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { Box } from '@mui/material';
import Yup from '~/utils/yup';
import { computed } from 'mobx';
import { observer } from 'mobx-react-lite';

import { useValidationSchema } from '~/hooks/use_validate';
import useNavigate from '~/hooks/use_navigate';
import useLocales from '~/hooks/use_locales';
import toasts from '~/utils/toasts';
import type { ICertificateModel } from '~/mst/models/certificate';
import type { ICertificatesStore } from '~/mst/models/certificates';
import type { INodesStore } from '~/mst/models/nodes';
import type { ITemplate } from '~/mst/models/template';
import { captureException } from '~/services/sentry';
import EditCertificateForm from './form';

type FormContainerProps = { model: ICertificateModel; certificates: ICertificatesStore; nodes: INodesStore; template: ITemplate };

function FormContainer({ model, certificates, nodes, template }: FormContainerProps) {
  const { t } = useLocales();
  const { navigate } = useNavigate();

  const AddEditCertificateSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    description: Yup.string().required('Description is required'),
    devices: Yup.array().required('Devices are required').min(1, 'Please select at least one device'),
    phone: Yup.string().phoneNumber('Enter a valid phone number'),
    address: Yup.string().required('Address is required'),
    photo: Yup.string().url().required('Photo is required').typeError('Photo is required')
  });

  const validate = useValidationSchema(AddEditCertificateSchema);

  const initialData = useMemo(
    () =>
      computed(() => ({
        name: model.name,
        description: model.description,
        devices: model.nodeIds || [],
        phone: model.user_metadata?.phone,
        address: model.user_metadata?.address,
        photo: model.user_metadata?.photo
      })),
    [model]
  ).get();

  const handleOnSubmit = useCallback(
    async (values) => {
      try {
        const { name, description, devices, ...rest } = values;
        const templates = [
          {
            _id: template._id,
            configuration: {
              node_id: devices.map((item) => item.value)
            }
          }
        ];
        const payload = {
          name,
          description,
          serial: 'AB',
          _type: 'report',
          organization_id: template.organization_id,
          templates,
          user_metadata: rest
        };
        if (model.isNew) {
          await certificates.create(payload, model);
        } else {
          await model.update(payload);
        }
        navigate('certificates');
        toasts.success(t(`notifications.success.forms.${model.isNew ? 'create_certificate' : 'update_certificate'}`));
      } catch (e) {
        captureException(e);
        toasts.error(t('notifications.errors.server_error'));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigate, t, model.isNew]
  );

  return (
    <Box sx={{ p: 3, width: '100%', minHeight: '350px' }}>
      <FinalForm onSubmit={handleOnSubmit} initialValues={initialData} validate={validate} subscription={{ submitting: true, pristine: true }}>
        {({ handleSubmit }) => (
          <EditCertificateForm
            handleSubmit={handleSubmit}
            nodes={nodes.monitorableAirthinxValueLabelPairs}
            model={model}
            submitting={model.isSyncing}
          />
        )}
      </FinalForm>
    </Box>
  );
}

export default observer(FormContainer);

/* eslint-disable no-param-reassign */
const macAddressToHex = (mac) => {
  if (mac) {
    mac = mac.toString(16).toUpperCase();
    if (mac.length >= 3 && mac.length <= 16) {
      mac = mac.replace(/(.{2})/g, '$1:').slice(0, -1);
    }
  }
  return mac;
};

export default macAddressToHex;

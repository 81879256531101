import { Stack, Grid } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { INode } from '~/mst/models/node';

import DeviceFormContainer from '~/pages/device_edit/containers/device_form_container';
import AqmsContainer from '~/pages/device_edit/containers/aqms_container';

type EditFormProps = {
  node: INode;
};

function EditForm({ node }: EditFormProps) {
  return (
    <Stack>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={7}>
          <DeviceFormContainer node={node} />
        </Grid>
        <Grid item xs={12} md={6} lg={5}>
          <AqmsContainer node={node} />
        </Grid>
      </Grid>
    </Stack>
  );
}

export default observer(EditForm);

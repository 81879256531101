import React, { useCallback } from 'react';
import { Stack, IconButton } from '@mui/material';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import StarRateIcon from '@mui/icons-material/StarRate';
import StarOutlineIcon from '@mui/icons-material/StarOutline';

import { PERMISSIONS } from '~/mst/models/permissions/types';
import useAuth from '~/hooks/use_auth';
import RefreshButton from '~/pages/device_monitor/monitor_chart/refresh_button';
import useNavigate from '~/hooks/use_navigate';
import useMst from '~/hooks/use_mst';
import RangeSelector from '~/components/range_selector';
import NavigateDropdown from '~/components/navigate_dropdown';
import ReportPauseUpdatesButton from '~/components/report_pause_updates_button';
import Actions from './actions';
import ranges from './ranges';
import AddWidgetsContainer from '../add_widget';

type DashboardHeaderProps = {
  widgetSidebarVisible: boolean;
  handleAddWidget: () => void;
};

function DashboardHeader({ widgetSidebarVisible, handleAddWidget }: DashboardHeaderProps) {
  const { dashboards } = useMst();
  const { navigate } = useNavigate();
  const { auth } = useAuth();

  const { id } = useParams();
  const dashboard = dashboards.getById(id);
  const toggleIsStarred = useCallback(() => {
    dashboard.toggleIsStarred();
  }, [dashboard]);
  const handleChangeRange = useCallback(
    (newRange) => {
      dashboard.setRange(newRange);
    },
    [dashboard]
  );

  if (!dashboard) {
    return null;
  }

  return (
    <Stack sx={{ pt: 0.5, mb: 2 }} alignItems="flex-start" justifyContent="space-between" direction={{ xs: 'column', md: 'row' }}>
      <Stack direction="row" alignItems="center">
        <IconButton onClick={toggleIsStarred}>{dashboard?.is_starred ? <StarRateIcon /> : <StarOutlineIcon />}</IconButton>
        <NavigateDropdown
          options={dashboards.valueLabelPairs}
          width={{ multiplier: 1.5, extra: 50 }}
          onChange={(value) => navigate(`dashboards/${value}`)}
          loading={dashboards.isSyncing}
          value={id}
        />
      </Stack>
      <Stack direction="column" alignItems="flex-end" justifyContent="flex-end" spacing={1}>
        <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1}>
          <RangeSelector options={ranges} value={dashboard?.range} setValue={handleChangeRange} sx={{ minWidth: 300, mr: 1 }} />
          <ReportPauseUpdatesButton report={dashboard} />
          <RefreshButton report={dashboard} />
        </Stack>
        {auth.user?.hasPermission(PERMISSIONS.ReadReports, dashboard.organization_id) && (
          <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1}>
            <AddWidgetsContainer isVisible={widgetSidebarVisible} handleVisible={handleAddWidget} />
            <Actions />
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}

export default observer(DashboardHeader);

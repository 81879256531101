import React, { useEffect, useRef, useCallback, useMemo } from 'react';
import { Box } from '@mui/material';
import RadioGroup from '~/components/final_form/radio_input';
import { observer } from 'mobx-react-lite';
import { computed } from 'mobx';
import { Field, useForm, useFormState } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { PATHS } from '~/utils/constants';
import { INode } from '~/mst/models/node';
import I18n from '~/utils/i18n';
import TextInput from '~/components/final_form/text_input';
import Map from '~/components/widgets/map_widget/content/map';

const LOCATION_MODES = [
  { label: I18n.t('base.labels.auto'), value: 'auto' },
  { label: I18n.t('base.labels.manual'), value: 'manual' }
];

const mapOptions = {
  streetViewControl: false,
  fullscreenControl: false
};

function LocationForm({ node }: { node: INode }) {
  const refSearchBox = useRef<HTMLInputElement>(null);
  const { change } = useForm();
  const {
    values: {
      thiamis: { location, locationType }
    }
  } = useFormState({ subscription: { values: true } });

  useEffect(() => {
    if (refSearchBox.current && window.google.maps.places) {
      const autocomplete = new window.google.maps.places.Autocomplete(refSearchBox.current);
      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        if (place.geometry && place.geometry.location) {
          const lat = place.geometry.location.lat();
          const lng = place.geometry.location.lng();
          change('thiamis.location', [+lat, +lng]);
          change('thiamis.location_description', place.formatted_address);
        }
      });
    }
  }, [change]);

  const handleDragEnd = useCallback(
    (event) => {
      const lat = event.latLng.lat();
      const lng = event.latLng.lng();
      change('thiamis.location', [+lat, +lng]);
      change('thiamis.location_description', `${lat}, ${lng}`);
    },
    [change]
  );

  const markers = useMemo(
    () =>
      computed(() => [
        {
          location: location || node?.data_points?.getByPath(PATHS.LOCATION)?.lastValue,
          name: node?.name,
          status: node?.statusType(),
          statusText: node?.statusText(),
          serial: node?.serial
        }
      ]),
    [location, node]
  ).get();

  return (
    <>
      <RadioGroup name="thiamis.locationType" options={LOCATION_MODES} />

      <Field component="input" name="thiamis.location" type="hidden" />
      <TextInput
        sx={{ marginTop: '10px' }}
        className={locationType === 'manual' ? '' : 'input-hidden'}
        inputRef={refSearchBox}
        name="thiamis.location_description"
        label="Address"
        hiddenLabel
      />
      {locationType === 'manual' && (
        <Box display="flex" justifyContent="center" alignItems="center">
          <Box mt={2} sx={{ width: '100%', height: '250px' }}>
            <Map nodes={markers} onDragEnd={handleDragEnd} options={mapOptions} mapId="e2b1b4ca24b54ab8" />
          </Box>
        </Box>
      )}
      <OnChange name="thiamis.locationType">
        {(value) => {
          if (value === 'auto') {
            change(`thiamis.location`, null);
            change(`thiamis.location_description`, null);
          }
        }}
      </OnChange>
    </>
  );
}

export default observer(LocationForm);

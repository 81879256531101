import { types as t, Instance } from 'mobx-state-tree';
import { ModelV2 } from '~/mst/models/abstract/model';
import Syncable from '~/mst/models/abstract/syncable';
import MaybeString from '~/mst/models/abstract/maybe_string';
import CreatedAtModel from '~/mst/models/abstract/created_at';
import ConfigurationModel from './configuration';

const ReportModel = t.compose(
  t.model({
    name: t.maybeNull(t.string),
    description: t.maybeNull(MaybeString),
    organization_id: t.maybeNull(t.string),
    external_id: t.maybeNull(t.string),
    user_id: t.maybeNull(t.string),
    configuration: t.maybeNull(ConfigurationModel)
  }),
  ModelV2,
  Syncable,
  CreatedAtModel
);

export interface IReportModel extends Instance<typeof ReportModel> {}

export default ReportModel;

/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, ReactElement, useState, useCallback } from 'react';
import { createFilterOptions, TextField, Autocomplete, CircularProgress, Paper, Box, FormControlLabel, Divider, Checkbox, Chip } from '@mui/material';
import capitalize from 'lodash/capitalize';
import get from 'lodash/get';
import { autorun } from 'mobx';

import { Iconify } from '~/components/iconify';
import { ValueLabelPair } from '~/mst/models/abstract/collection_types';
import useLocales from '~/hooks/use_locales';
import type { AutocompleteProps, FieldProps, Value } from './types';

export type Props = {
  allowSelectAll?: boolean;
  renderOption?: (option: ValueLabelPair) => ReactElement;
  filterText?: (option: ValueLabelPair) => string;
  renderInput?: (option: ValueLabelPair) => ReactElement;
  InputStartAdornment?: (option: ValueLabelPair) => ReactElement;
} & FieldProps &
  AutocompleteProps<Value>;

function PaperComponent(paperProps) {
  const { t } = useLocales();
  const { children, handleSelectAll, selectAll, ...restPaperProps } = paperProps;

  return (
    <Paper {...restPaperProps}>
      <Box onMouseDown={(e) => e.preventDefault()} pl={1.5} py={0.5}>
        <FormControlLabel
          label={t('base.labels.select_all')}
          onClick={(e) => {
            e.preventDefault();
            handleSelectAll();
          }}
          control={<Checkbox id="select-all-checkbox-for-autocomplete" checked={selectAll} />}
        />
      </Box>
      <Divider />
      {children}
    </Paper>
  );
}

export default function MuiAutocomplete(props: Props) {
  const {
    name,
    label,
    placeholder,
    disabled,
    value,
    multiple,
    allowSelectAll,
    InputStartAdornment,
    renderOption,
    renderInput,
    onInputChange,
    onChange,
    filterText,
    freeSolo,
    loading,
    model,
    error,
    helperText,
    options,
    ...rest
  } = props;
  const [selected, setSelected] = useState<boolean>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);

  const handleSelectAll = useCallback(() => {
    setSelectAll((prev) => {
      if (!prev) onChange([...options]);
      else onChange([]);
      return !prev;
    });
  }, [onChange, options]);

  useEffect(
    () =>
      autorun(() => {
        if (multiple) {
          return setSelected(
            get(value, 'value', Array.isArray(value) ? value : []).map((option: ValueLabelPair | string) => {
              if (option?.value !== undefined) {
                return option;
              }
              return options.find((o) => o.value === option);
            })
          );
        }
        if (value?.value !== undefined) {
          return setSelected(value);
        }
        const option = options.find((o) => {
          if (value == null && o?.value == null) {
            return true;
          }
          return o?.value === value;
        });
        if (freeSolo) {
          return setSelected(option || value || { value: null, label: '' });
        }
        if (loading) {
          return setSelected({ value: null, label: '' });
        }
        return setSelected(option || { value: null, label: '' });
      }),
    [multiple, options, value, freeSolo, loading]
  );

  return (
    <Autocomplete
      disabled={disabled}
      value={selected}
      multiple={multiple}
      filterOptions={createFilterOptions({
        stringify: filterText
      })}
      onChange={(_e, option, reason) => {
        if (reason === 'clear' || reason === 'removeOption') setSelectAll(false);
        if (reason === 'selectOption' && allowSelectAll && selected.length === options.length) setSelectAll(true);
        onChange(Object.hasOwn(option, 'value') ? option.value : option);
      }}
      getOptionLabel={(option) => {
        if (option) {
          return Object.hasOwn(option, 'label') ? option?.label : option;
        }
        return null;
      }}
      getOptionDisabled={() => {
        if (multiple && selected.some((option) => option?.value === 'all')) {
          return true;
        }
        return false;
      }}
      disableClearable={!multiple}
      renderOption={(propsInner, option = { label: '', value: null }) => (
        <li {...propsInner} key={option.value} data-testid={`${name}Option${capitalize(option.value?.toString() || 'Null')}`}>
          {renderOption ? renderOption(option) : option.label}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error}
          helperText={helperText}
          label={label}
          InputProps={{
            ...params.InputProps,
            ...(InputStartAdornment && { startAdornment: <InputStartAdornment model={model || selected?.model} /> }),
            endAdornment: (
              <>
                {loading && <CircularProgress color="info" size={18} />}
                {params.InputProps.endAdornment}
                {renderInput && renderInput(selected)}
              </>
            )
          }}
          onChange={(e) => {
            params.inputProps.onChange(e);
            if (onInputChange) {
              onInputChange(e);
            }
          }}
        />
      )}
      popupIcon={<Iconify icon="ep:arrow-down-bold" width={14} height={14} />}
      {...(allowSelectAll && { PaperComponent })}
      componentsProps={{
        paper: {
          selectAll,
          handleSelectAll
        }
      }}
      renderTags={(values, getTagProps) => (
        <Box sx={{ maxHeight: (theme) => theme.spacing(16), overflowY: 'auto' }}>
          {values.map((option, index) => (
            <Chip {...getTagProps({ index })} key={option?.value} label={option?.label} />
          ))}
        </Box>
      )}
      {...rest}
      freeSolo={freeSolo}
      options={options}
    />
  );
}

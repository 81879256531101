import { types as t, Instance, SnapshotOut } from 'mobx-state-tree';
import { PERMISSIONS } from '../types';

const UserPermissionsModel = t
  .model({
    [PERMISSIONS.AdminDevices]: t.maybeNull(t.array(t.string)),
    [PERMISSIONS.ManageDevices]: t.maybeNull(t.array(t.string)),
    [PERMISSIONS.ManageOrganizations]: t.maybeNull(t.array(t.string)),
    [PERMISSIONS.ManageReports]: t.maybeNull(t.array(t.string)),
    [PERMISSIONS.ManageTokens]: t.maybeNull(t.array(t.string)),
    [PERMISSIONS.ManageUsers]: t.maybeNull(t.array(t.string)),
    [PERMISSIONS.ReadDevices]: t.maybeNull(t.array(t.string)),
    [PERMISSIONS.ReadOrganizations]: t.maybeNull(t.array(t.string)),
    [PERMISSIONS.ReadReports]: t.maybeNull(t.array(t.string)),
    [PERMISSIONS.ReadUsers]: t.maybeNull(t.array(t.string)),
    [PERMISSIONS.AccessContext]: t.maybeNull(t.array(t.string)),
    [PERMISSIONS.CreateOrganization]: t.maybeNull(t.array(t.string))
  })
  .actions((self) => ({
    add(type, id) {
      if (self[type].length === 0) {
        Object.assign(self, { [type]: [id] });
        return;
      }
      self[type].push(id);
    }
  }));

export interface IUserPermissionsModel extends Instance<typeof UserPermissionsModel> {}
export interface IUserPermissionsModelSnapshotOut extends SnapshotOut<typeof UserPermissionsModel> {}

export default UserPermissionsModel;

import { Instance } from 'mobx-state-tree';
import CollectionMapProcessor from '~/mst/models/abstract/collection_map_processor';
import model from './model';
import actions from './actions';

const AvailableDevices = model.actions(actions);

export interface IAvailableDevices extends Instance<typeof AvailableDevices> {}

export default CollectionMapProcessor(AvailableDevices);

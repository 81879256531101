import React, { useCallback, useMemo } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { datadogRum } from '@datadog/browser-rum';

import Yup, { searchableSelectSchema } from '~/utils/yup';
import { useValidationSchema } from '~/hooks/use_validate';
import useLocales from '~/hooks/use_locales';
import useModal from '~/contexts/modal_context';
import useMst from '~/hooks/use_mst';
import useAuth from '~/hooks/use_auth';
import toasts from '~/utils/toasts';
import { captureException } from '~/services/sentry';
import AddPartnerFormContent from './content';

const AddPartnerSchema = Yup.object().shape({
  organization_id: searchableSelectSchema('Organization is required'),
  partner_id: searchableSelectSchema('Partner is required')
});

function AddPartnerForm() {
  const { t } = useLocales();
  const { partnerships, organizations } = useMst();
  const { auth } = useAuth();
  const { handleClose } = useModal();

  const handleFormSubmit = useCallback(
    async (values) => {
      try {
        const { organization_id: organizationId, partner_id: partnerName } = values;
        const payload = {
          external_id: organizations.findWhere({ name: partnerName })?.external_id || partnerName,
          organization_id: organizationId
        };
        await partnerships.create(payload);
        toasts.success(t('notifications.success.forms.partnership_created'));
        datadogRum.addAction('create_partnership', payload);
        organizations.fetch(null, { noCache: true });
        handleClose();
      } catch (e) {
        captureException(e);
        toasts.error(t('notifications.errors.server_error'));
      }
    },
    [handleClose, t, partnerships, organizations]
  );

  const initialValues = useMemo(
    () => ({
      organization_id: auth.organizationId,
      partner_id: null
    }),
    [auth.organizationId]
  );

  const validate = useValidationSchema(AddPartnerSchema);

  return (
    <FinalForm
      validate={validate}
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      render={({ handleSubmit }) => <AddPartnerFormContent handleSubmit={handleSubmit} />}
    />
  );
}

export default AddPartnerForm;

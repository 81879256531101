import { useState, useEffect } from 'react';
import { Stack, Typography } from '@mui/material';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import { observer } from 'mobx-react-lite';

import useChart from '~/hooks/use_chart';
import { ParamContainerStyled, ParamUnitItemStyled } from './styled';

function ParamItem({ dataPoint, color }: { dataPoint: any; color: string }) {
  const { chartRef } = useChart();
  const [hidden, setHidden] = useState(false);

  useEffect(() => {
    if (chartRef?.current && dataPoint) {
      chartRef?.current?.chart.series.find((serie) => serie.userOptions.id === dataPoint?._id)?.setVisible(!hidden, true);
    }
  }, [hidden, chartRef, dataPoint]);

  return (
    <ParamContainerStyled $hidden={hidden} onClick={() => setHidden((prevState) => !prevState)}>
      <Stack direction="column" alignItems="center">
        <Stack sx={{ textDecoration: hidden ? 'line-through' : 'normal' }} direction="row" alignItems="center" justifyContent="center">
          <HorizontalRuleIcon fontSize="large" sx={{ color, height: '100%', mr: '0.2rem' }} />
          <Typography>{dataPoint?.presentName}</Typography>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="center" spacing={2.5} sx={{ mt: '0.4rem' }}>
          <ParamUnitItemStyled>
            <Typography variant="caption">MIN</Typography> <Typography variant="overline">{dataPoint?.min}</Typography>
          </ParamUnitItemStyled>
          <ParamUnitItemStyled>
            <Typography variant="caption">AVG</Typography> <Typography variant="overline">{dataPoint?.avg}</Typography>
          </ParamUnitItemStyled>
          <ParamUnitItemStyled>
            <Typography variant="caption">MAX</Typography> <Typography variant="overline">{dataPoint?.max}</Typography>
          </ParamUnitItemStyled>
        </Stack>
      </Stack>
    </ParamContainerStyled>
  );
}

export default observer(ParamItem);

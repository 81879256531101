/* eslint-disable @typescript-eslint/naming-convention */
import { getStore } from '~/mst/utils';
import type { IWebhookModel } from '../model';

export default (self: IWebhookModel) => {
  function getOrganization() {
    return getStore(self).organizations.getById(self.organization_id);
  }
  return {
    get organization() {
      return getOrganization();
    },
    get organization_name() {
      return getOrganization().name;
    },
    get last_used_at() {
      if (self.last_response) {
        return self.last_response.timestamp;
      }
      return null;
    },
    get status() {
      if (self.last_response) {
        return self.last_response.status;
      }
      return null;
    }
  };
};

/* eslint-disable no-param-reassign */
import { applySnapshot, cast } from 'mobx-state-tree';
import { IAuthModel } from './model';

export default (self: IAuthModel) => ({
  setUser(user) {
    self.user = cast(user);
  },
  setToken(token) {
    self.token = token;
  },
  setLocale(locale) {
    self.locale = locale;
  },
  setTheme(newTheme: string) {
    self.theme = newTheme;
  },
  setOrganizationId(id) {
    self.organization_id = id;
  },
  logout() {
    self.startSyncing();
    try {
      applySnapshot(self, null);
      self.finishSyncing();
    } catch (error) {
      self.failSyncing(error);
    }
  }
});

import { Box, styled, BoxProps, Stack, Badge, ListSubheader } from '@mui/material';
import { transientOptions } from '~/utils/styled';
import FilterListIcon from '@mui/icons-material/FilterList';
import CloseIcon from '@mui/icons-material/Close';

interface IPanelType extends BoxProps {
  $isVisible: boolean;
}

const PanelStyled = styled(
  Box,
  transientOptions
)<IPanelType>(({ $isVisible, theme }) => ({
  position: 'fixed',
  top: 0,
  bottom: 0,
  right: '-350px',
  width: '350px',
  background: theme.palette.background.paper,
  zIndex: 9999,
  cursor: 'default',
  transition: 'right .2s',
  overflowY: 'auto',
  boxShadow: theme.palette.themeShadows.filterPanel,
  ...($isVisible && {
    right: 0
  }),
  ...(!$isVisible && {
    boxShadow: 'none'
  })
}));

export const PanelInnerStyled = styled(Stack)(() => ({
  position: 'relative'
}));

export const BadgeFilterItemStyled = styled(Badge)(({ theme }) => ({
  '.MuiBadge-badge': {
    color: theme.palette.primary.main,
    padding: theme.spacing(0, 1),
    borderRadius: '5px',
    transform: 'translate(75%, 0%)',
    backgroundColor: 'rgba(145, 158, 171, 24%)'
  }
}));

export const BadgeStyled = styled(Badge)(() => ({
  padding: 0
}));

export const FilterListIconStyled = styled(FilterListIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '1.6rem'
}));

export const CloseIconStyled = styled(CloseIcon)(() => ({
  fontSize: 16
}));

export const ListSubheaderStyled = styled(ListSubheader)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: '#fff'
}));

export default PanelStyled;

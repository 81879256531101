/* eslint-disable @typescript-eslint/no-use-before-define */
import { flow } from 'mobx-state-tree';
import type { IAirthinxDeviceModel } from './model';

export default (self: IAirthinxDeviceModel) => ({
  createUserCalibrationParameter: flow(function* create(payload: { path: string; location: string }) {
    self.startSyncing();
    try {
      const device = self.toJSON();
      yield self.update({ ...device, user_calibration: { ...device.user_calibration, [payload.path]: payload.params } });
      self.finishSyncing();
    } catch (error) {
      self.failSyncing(error);
    }
  })
});

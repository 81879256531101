/* eslint-disable no-param-reassign */
import { flow, getIdentifier, getSnapshot } from 'mobx-state-tree';

import api from '~/api';

import { IReportModel } from './model';

export default (self: IReportModel) => ({
  update: flow(function* update(values) {
    self.startSyncing();
    try {
      yield api.reports.update(getIdentifier(self)!, values || getSnapshot(self));
      if (values) {
        Object.assign(self, values);
      }
      self.finishSyncing();
    } catch (error) {
      self.failSyncing(error);
    }
  })
});

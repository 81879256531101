/* eslint-disable @typescript-eslint/no-use-before-define */
import { types as t, Instance } from 'mobx-state-tree';
import Sensor from '~/mst/models/device/sensor';

const GM460Sensor = t
  .compose(
    t.model({
      optional_measurements: t.maybeNull(t.frozen())
    }),
    Sensor
  )
  .named('GM460');

export interface IGM460Sensor extends Instance<typeof GM460Sensor> {}

export default GM460Sensor;

/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useRef, useMemo, useState } from 'react';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import saveAs from 'file-saver';
import { computed } from 'mobx';
import snakeCase from 'lodash/snakeCase';
import { SortingState, IntegratedSorting, IntegratedPaging, PagingState, FilteringState, IntegratedFiltering } from '@devexpress/dx-react-grid';
import { Grid, VirtualTable, TableHeaderRow, PagingPanel, TableFilterRow } from '@devexpress/dx-react-grid-material-ui';
import { observer } from 'mobx-react-lite';
import { formatDateFromTs } from '~/utils/format_date';
import useLoading from '~/hooks/react_grid/use_loading';
import useSorting from '~/hooks/react_grid/use_sorting';
import usePaging from '~/hooks/react_grid/use_paging';
import { IDeviceMonitor } from '~/pages/device_monitor/store';

import { GridStyled } from '~/components/react_grid/styled';
import { Container, Row } from '~/components/react_grid';
import useExport from '~/hooks/react_grid/use_export';
import * as operations from '~/utils/operations';
import dateTimeFormatter from '~/components/react_grid/datetime_formatter';
import { DateTimeProvider, ParametersProvider } from './info_providers';

const getRowId = ({ ts }) => ts;

function FilterIcon(props) {
  return <TableFilterRow.Icon {...props} />;
}

const customizeCell = (cell, { ts, timezone }, column) => {
  if (column.name === 'ts') {
    if (timezone) {
      cell.value = formatDateFromTs(ts, { timezone, format: column.format });
    }
    cell.value = formatDateFromTs(ts);
  }
};

function MonitorTable({ report }: { report: IDeviceMonitor }) {
  const { sorting, handleSortingChange } = useSorting([{ columnName: 'ts', direction: 'desc' }]);
  const { messages, RootComponent } = useLoading(report.chartWidget);
  const { pageSizes, pageSize, handlePageSizeChange } = usePaging();
  const exporterRef = useRef(null);
  const { isExporting, stopExport } = useExport();
  const [tsColumn] = useState(['ts']);
  const [tsFilterOperations] = useState(['contains', 'startsWith', 'endsWith']);
  const [parametersFilterOperations] = useState(['greaterThanOrEqual', 'greaterThan', 'lessThanOrEqual', 'lessThan', 'equal', 'notEqual']);
  const [filteringColumnExtensions] = useState([
    {
      columnName: 'ts',
      predicate: (value, filter, row) => {
        if (!filter.value.length) return true;
        const operator = operations[filter.operation];
        return operator(dateTimeFormatter({ value, row, column: report.chartWidget.tableColumns[0] }), filter.value);
      }
    }
  ]);

  useEffect(() => {
    if (isExporting) {
      stopExport();
      exporterRef.current.exportGrid();
    }
    return () => stopExport();
  }, [isExporting, exporterRef, stopExport]);

  const onSave = (workbook) => {
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${report.chartWidget.node?.serial}_${snakeCase(report.range.toString)}.xlsx`);
    });
  };

  const parametersColumns = useMemo(
    () => computed(() => report.chartWidget.tableColumns.filter(({ name }) => name !== 'ts')),
    [report.chartWidget.tableColumns]
  ).get();

  const parametersColumnNames = useMemo(() => parametersColumns.map(({ name }) => name), [parametersColumns]);

  return (
    <GridStyled>
      <Grid rows={report.chartWidget.tableData} columns={report.chartWidget.tableColumns} rootComponent={RootComponent}>
        <DateTimeProvider for={tsColumn} availableFilterOperations={tsFilterOperations} />
        <ParametersProvider for={parametersColumnNames} availableFilterOperations={parametersFilterOperations} />
        <FilteringState defaultFilters={[]} />
        <IntegratedFiltering columnExtensions={filteringColumnExtensions} />
        <SortingState sorting={sorting} onSortingChange={handleSortingChange} />
        <IntegratedSorting />
        <PagingState defaultCurrentPage={0} pageSize={pageSize} onPageSizeChange={handlePageSizeChange} />
        <IntegratedPaging />
        <VirtualTable containerComponent={Container} messages={messages} rowComponent={Row} />
        <TableHeaderRow showSortingControls />
        {report.chartWidget.tableData.length > pageSize && <PagingPanel pageSizes={pageSizes} />}
        {parametersColumns.length > 0 && <TableFilterRow showFilterSelector iconComponent={FilterIcon} />}
      </Grid>
      <GridExporter
        getRowId={getRowId}
        ref={exporterRef}
        columns={report.chartWidget.tableColumns}
        rows={report.chartWidget.tableData}
        customizeCell={customizeCell}
        onSave={onSave}
      />
    </GridStyled>
  );
}

export default observer(MonitorTable);

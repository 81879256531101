import { Theme } from '@mui/material/styles';
import { styled, Box, Typography, Card, CardActions, CardHeader, CardContent } from '@mui/material';
import { transientOptions } from '~/utils/styled';

export const WidgetWrapperStyled = styled(
  Card,
  transientOptions
)(({ theme, $isSyncing }: { $isSyncing: boolean; theme: Theme }) => ({
  height: '100%',
  boxShadow: theme.palette.themeShadows.card,
  flexDirection: 'column',
  width: '100%',
  opacity: $isSyncing ? 0.5 : 1,
  '&:hover': {
    '.MuiCardHeader-action': {
      visibility: 'visible'
    },
    '.action-move': {
      visibility: 'visible'
    }
  }
}));

export const WidgetBodyStyled = styled(CardContent)(({ theme }) => ({
  height: '100%',
  overflow: 'hidden',
  width: '100%',
  padding: theme.spacing(0, 1.5),
  paddingTop: 0,
  overflowY: 'auto'
}));

export const WidgetLoadingContainerStyled = styled(CardContent)(({ theme }) => ({
  height: '100%',
  width: '100%',
  overflow: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(1.5)
}));

export const WidgetTitleStyled = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: 600,
  color: theme.palette.common.black
}));

export const WidgetSubtitleStyled = styled(Typography)`
  font-weight: 600;
  font-size: 12px;
  min-height: 40px;
  color: #999;
`;

export const WidgetHeaderStyled = styled(CardHeader)(({ theme }: { theme: Theme }) => ({
  width: '100%',
  borderTopLeftRadius: '5px',
  borderTopRightRadius: '5px',
  minHeight: 46,
  position: 'relative',
  padding: theme.spacing(1, 1.5, 0, 1.5),
  '.MuiCardHeader-action': {
    [theme.breakpoints.up('sm')]: {
      visibility: 'hidden'
    },
    position: 'absolute',
    right: 10,
    top: '50%',
    transform: 'translateY(-50%)'
  }
}));

export const WidgetHeaderContainerStyled = styled(Box)(({ theme }: { theme: Theme }) => ({
  width: '100%',
  '.action-move': {
    svg: {
      fontSize: '1rem'
    },
    visibility: 'hidden',
    transform: 'rotate(-90deg)',
    position: 'absolute',
    height: '15px',
    padding: theme.spacing(1),
    top: 5,
    right: '50%',
    marginRight: theme.spacing(-3.5),
    cursor: 'move'
  }
}));

export const WidgetFooterStyled = styled(CardActions)(({ theme }) => ({
  width: '100%',
  borderTop: '1px solid #ececec',
  marginTop: 0,
  justifyContent: 'normal',
  padding: theme.spacing(0, 2),
  minHeight: '46px',
  fontSize: '0.85rem'
}));

export const HandleBoxStyled = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: '-2px',
  right: '2px',
  cursor: 'se-resize',
  '& svg': {
    fill: theme.palette.secondary.muted,
    fontSize: '1rem'
  }
}));

export const Icon = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'baseline',
  height: '100%',
  '& svg': {
    fill: theme.palette.secondary.muted,
    margin: '0 auto',
    display: 'block'
  }
}));

import React, { useMemo } from 'react';
import { computed } from 'mobx';
import { observer } from 'mobx-react-lite';
import { OnChange } from 'react-final-form-listeners';
import { LoadingButton } from '@mui/lab';
import { Box, CardContent, CardActions } from '@mui/material';
import { useForm } from 'react-final-form';

import { getValueLabelPairs } from '~/mst/utils';
import FormGrid from '~/components/@material-extend/form_grid';
import useLocales from '~/hooks/use_locales';
import Select from '~/components/final_form/select';
import type { IDeviceAdmin } from '../../../store';

type SidebarType = {
  handleSubmit: VoidFunction;
  report: IDeviceAdmin;
  submitting: boolean;
};

function FormSidebar({ report, handleSubmit, submitting }: SidebarType) {
  const { t } = useLocales();
  const debugLevelOptions = useMemo(() => computed(() => getValueLabelPairs(report?.filesWidget?.filenames)), [report?.filesWidget?.filenames]).get();
  const { change } = useForm();
  return (
    <Box>
      <CardContent>
        <FormGrid>
          <Select name="filename" label="Files" options={debugLevelOptions || []} />
        </FormGrid>
      </CardContent>
      <CardActions>
        <LoadingButton type="submit" disabled={submitting} variant="contained" loading={submitting} onClick={handleSubmit}>
          {t('base.buttons.save')}
        </LoadingButton>
      </CardActions>
      <OnChange name="filename">
        {(value) => {
          change('content', report?.filesWidget?.files[value]);
        }}
      </OnChange>
    </Box>
  );
}

export default observer(FormSidebar);

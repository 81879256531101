import i18n from '~/locales/i18n';

export default [
  {
    value: {
      from: '30_minutes'
    },
    label: i18n.t('dashboard.time.30_min')
  },
  {
    value: {
      from: '1_hours'
    },
    label: i18n.t('dashboard.time.1_hour')
  },
  {
    value: {
      from: '2_hours'
    },
    label: i18n.t('dashboard.time.hours', { hours: 2 })
  },
  {
    value: {
      from: '3_hours'
    },
    label: i18n.t('dashboard.time.hours', { hours: 3 })
  },
  {
    value: {
      from: '4_hours'
    },
    label: i18n.t('dashboard.time.hours', { hours: 4 })
  },
  {
    value: {
      from: '6_hours'
    },
    label: i18n.t('dashboard.time.hours', { hours: 6 })
  },
  {
    value: {
      from: '12_hours'
    },
    label: i18n.t('dashboard.time.hours', { hours: 12 })
  },
  {
    value: {
      from: '1_days'
    },
    label: i18n.t('dashboard.time.last_day', { hours: 2 })
  }
];

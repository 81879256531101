import round from 'lodash/round';

// eslint-disable-next-line import/prefer-default-export
export const palettes = {
  light: {
    plotOptions: {
      series: {
        legendSymbol: 'rectangle',
        dataLabels: {
          useHTML: true,
          color: '#fff',
          formatter() {
            const { points } = this.series;
            if (this.x === points[points.length - 1].x) {
              return `<span style="color:${this.series.color}">${this.series.name}</span>`;
            }
            return '';
          }
        }
      }
    },
    scrollbar: {
      rifleColor: 'white',
      barBackgroundColor: '#DCDCDD',
      buttonBackgroundColor: 'white',
      buttonArrowColor: '#DCDCDD',
      trackBackgroundColor: 'white',
      trackBorderColor: 'white'
    },
    loading: {
      style: {
        backgroundColor: '#fff',
        opacity: 1
      }
    },
    legend: {
      symbolHeight: 12,
      symbolWidth: 12,
      symbolRadius: 6,
      itemStyle: { color: '#333' }
    },
    xAxis: {
      labels: {
        style: {
          color: '#333'
        }
      }
    },
    yAxis: {
      labels: { style: { color: '#333', fontWeight: 'normal' } }
    },
    axisColor: '#333',
    gridLineColor: 'rgba(145, 158, 171, 0.14)',
    noData: {
      style: {
        color: '#333'
      }
    },
    tooltip: {
      style: {
        color: '#333'
      },
      borderColor: 'transparent',
      borderRadius: 6,
      padding: 12,
      backgroundColor: '#fff',
      pointFormatter() {
        const precision = this.series.options.precision || 0;
        let value = this.y;
        const decimalPlaces = (value.toString().split('.')[1] || []).length;
        if (decimalPlaces > 4) {
          value = round(value, precision);
        }
        return `<b>${this.series.name}</b>: ${value}`;
      }
    }
  },
  dark: {
    plotOptions: {
      series: {
        egendSymbol: 'rectangle',
        dataLabels: {
          useHTML: true,
          color: '#303030',
          formatter() {
            const { points } = this.series;
            if (this.x === points[points.length - 1].x) {
              return `<span style="color:${this.series.color}">${this.series.name}</span>`;
            }
            return '';
          }
        }
      }
    },
    scrollbar: {
      rifleColor: 'black',
      barBackgroundColor: '#DCDCDD',
      buttonBackgroundColor: 'black',
      buttonArrowColor: '#DCDCDD',
      trackBackgroundColor: 'black',
      trackBorderColor: 'black'
    },
    axisColor: '#DCDCDD',
    gridLineColor: 'rgba(145, 158, 171, 0.14)',
    legend: {
      symbolHeight: 12,
      symbolWidth: 12,
      symbolRadius: 6,
      itemStyle: { color: '#DCDCDD' },
      borderColor: 'DCDCDD'
    },
    xAxis: {
      labels: {
        style: {
          color: '#DCDCDD'
        }
      }
    },
    yAxis: {
      labels: { style: { color: '#DCDCDD', fontWeight: 'normal' } }
    },
    noData: {
      style: {
        color: '#DCDCDD'
      }
    },
    loading: {
      style: {
        backgroundColor: 'rgb(33, 43, 54)',
        opacity: 1
      }
    },
    tooltip: {
      style: {
        color: '#fff'
      },
      borderColor: 'transparent',
      borderRadius: 6,
      padding: 12,
      backgroundColor: 'rgb(22, 28, 36)',
      pointFormatter() {
        const precision = this.series.options.round || 2;
        let value = this.y;
        const decimalPlaces = (value.toString().split('.')[1] || []).length;
        if (decimalPlaces > 4) {
          value = round(value, precision);
        }
        return `<b>${this.series.name}</b>: ${value}`;
      }
    }
  }
};

import React, { forwardRef } from 'react';
import Scheduler from '@netronixgroup/air-purifier-scheduler';
import { Box, FormControl, FormLabel, styled, useTheme } from '@mui/material';
import { FieldMetaState, FieldInputProps } from 'react-final-form';
import presets from './presets/presets.json';

const FormLabelStyled = styled(FormLabel)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: theme.typography.pxToRem(14)
}));

const FormScheduler = forwardRef(({ input, meta }: { meta: FieldMetaState<any>; input: FieldInputProps<any> }, ref) => {
  const hasError = meta.touched && meta.error;
  const theme = useTheme();

  return (
    <FormControl error={Boolean(hasError)} fullWidth>
      <FormLabelStyled>Scheduler</FormLabelStyled>
      <Box sx={{ py: 1 }}>
        <Scheduler
          presets={presets}
          ref={ref}
          formatApply
          timeFormat="24"
          startFrom="sun"
          endFrom="mon"
          needConfirm={false}
          schedule={input.value}
          styles={{
            colors: theme.palette.scheduler
          }}
        />
      </Box>
    </FormControl>
  );
});

export default FormScheduler;

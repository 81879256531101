/* eslint-disable react/require-default-props */
import { SxProps } from '@mui/material';
import Wifi1 from '~/assets/icons/params/wifi_1.svg?react';
import Wifi2 from '~/assets/icons/params/wifi_2.svg?react';
import Wifi3 from '~/assets/icons/params/wifi_3.svg?react';
import Wifi4 from '~/assets/icons/params/wifi_4.svg?react';
import Wifi5 from '~/assets/icons/params/wifi_5.svg?react';
import DeviceParameter from '~/components/device_parameter';

type SignalLevelType = {
  value?: number | string;
  level?: number;
  title: string;
  tooltipTitle?: string;
  showLabel?: boolean;
  iconLeft?: boolean;
  sx?: SxProps;
};

function WifiSignalLevel({ value, level, title, tooltipTitle, showLabel = true, iconLeft = false, sx }: SignalLevelType) {
  const LevelIcons = {
    1: Wifi5,
    2: Wifi4,
    3: Wifi3,
    4: Wifi2,
    5: Wifi1
  };

  const SignalLevelComponent = LevelIcons[level || 5];

  return (
    <DeviceParameter
      title={title}
      tooltipTitle={tooltipTitle}
      value={value}
      IconComponent={SignalLevelComponent}
      iconWidth="16px"
      iconHeight="100%"
      align="flex-end"
      iconLeft={iconLeft}
      showLabel={showLabel}
      sx={sx}
    />
  );
}

export default WifiSignalLevel;

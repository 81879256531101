/* eslint-disable no-nested-ternary */
import { IDustTrakSensor } from './model';

export default (self: IDustTrakSensor) => ({
  get calibration() {
    return self.getDataPointByName('calibration')?.lastValue;
  },
  get ccCalibration() {
    return self.getDataPointByName('cumulative concentration (calibration)');
  },
  get filterLastChanged() {
    return self.getDataPointByName('filter last changed')?.lastValue;
  },
  get pumpRunTime() {
    return self.getDataPointByName('pump run time');
  },
  get ccFilterChange() {
    return self.getDataPointByName('cumulative concentration (filter change)')?.lastValue;
  }
});

/* eslint-disable @typescript-eslint/naming-convention */
import isEmpty from 'lodash/isEmpty';
import compact from 'lodash/compact';
import sortBy from 'lodash/sortBy';
import { getParent } from 'mobx-state-tree';

import { STATUS_WEIGHTS } from '~/utils/constants';

import { IDataPointsNodeModel } from './model';

export default (self: IDataPointsNodeModel) => {
  function getDefaultValueLabelPairs() {
    return self.valueLabelPairs.filter(({ model }) => model.isDefault || model.isMetric);
  }
  function getDefaultValueLabelPairsWithStatus() {
    return self.valueLabelPairs.filter(({ model }) => model.isDefault || model.isMetric || model.name === 'Status');
  }
  return {
    get node() {
      return getParent(self);
    },
    get defaultDataPoints() {
      return self.toArray.filter((model) => model.isDefault);
    },
    get rawDataPoints() {
      return self.toArray.filter((model) => model.isRaw);
    },
    get metricDataPoints() {
      return self.toArray.filter((model) => model.isMetric);
    },
    get defaultAndMetricDataPoints() {
      return self.toArray.filter((model) => model.isMetric || model.isDefault);
    },
    get defaultValueLabelPairs() {
      return getDefaultValueLabelPairs();
    },
    get defaultValueLabelPairsWithStatus() {
      return getDefaultValueLabelPairsWithStatus();
    },
    get defaultValueLabelPairsSortedByStatus() {
      return sortBy(getDefaultValueLabelPairs(), ({ model }) => -STATUS_WEIGHTS[model?.statusType() || 0]);
    },
    get defaultValueLabelPairsByPath() {
      return getDefaultValueLabelPairs().map(({ value, label }) => ({ value: self.getById(value).shortPath, label }));
    },
    get lastTimeReportedTs() {
      const measurements: number[] = compact(self.defaultDataPoints?.map((dp) => dp.lastTs));
      if (isEmpty(measurements)) {
        return null;
      }
      return Math.max(...measurements);
    },
    getByPath(path: string) {
      return self.toArray.find((dp) => dp.path?.endsWith(`:${path}`));
    },
    getByName(name: string) {
      return self.toArray.find((dp) => dp.name.toLowerCase() === name.toLowerCase());
    },
    getByPresentName(presentName: string) {
      return self.toArray.find((dp) => dp.presentName === presentName);
    }
  };
};

import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Typography, Stack } from '@mui/material';
import { getIdentifier } from 'mobx-state-tree';

import FormaldehydeIcon from '~/assets/icons/params/formaldehyde.svg?react';
import HumidityIcon from '~/assets/icons/params/humidity.svg?react';
import PmIcon from '~/assets/icons/params/pm.svg?react';
import Co2Icon from '~/assets/icons/params/co2.svg?react';
import TemperatureIcon from '~/assets/icons/params/temperature.svg?react';
import VocIcon from '~/assets/icons/params/voc.svg?react';

import { IDataPointNode } from '~/mst/models/data_point/node/default';
import { AirthixParameterPaths } from '~/utils/constants';
import { StatusIconWithAutoUpdate } from '~/components/widgets/node_widget/content/name_formatter';
import NavLink from '~/components/nav_link';
import { Icon, DeviceCard } from './components';

export const { AQ, PM, CO2, VOC_ETOH, CH2O, HUMIDITY, TEMPERATURE_C, TEMPERATURE_F, VOC_ISOBUTYLENE } = AirthixParameterPaths;
export const AIRTHINX_SHOW_PUBLIC_PARAMS = [AQ, PM, CO2, VOC_ETOH, CH2O, HUMIDITY, TEMPERATURE_C];

export const paramsIcons = {
  [CH2O]: FormaldehydeIcon,
  [CO2]: Co2Icon,
  [HUMIDITY]: HumidityIcon,
  [PM]: PmIcon,
  [TEMPERATURE_F]: TemperatureIcon,
  [VOC_ETOH]: VocIcon,
  [VOC_ISOBUTYLENE]: VocIcon
};

function AirthinxParameter({ dataPoint }: { dataPoint: IDataPointNode }) {
  const theme = useTheme();
  const ParamIcon = paramsIcons[dataPoint.shortPath];

  if (!ParamIcon) {
    return null;
  }

  return (
    <DeviceCard>
      <Icon $fixheight $sensor>
        <ParamIcon />
      </Icon>
      <Box
        sx={{
          display: 'flex',
          alignSelf: 'center',
          flexDirection: 'column',
          alignItems: 'flex-end',
          marginTop: theme.spacing(-0.25),
          paddingLeft: theme.spacing(0)
        }}
      >
        <Stack direction="row" alignItems="center">
          <StatusIconWithAutoUpdate model={dataPoint} size="xsmall" />
          <NavLink to={`devices/${dataPoint?.node_id}/monitor?parameter=${getIdentifier(dataPoint)}`}>
            <b>{dataPoint.name}</b>
          </NavLink>
        </Stack>

        <Typography variant="body3" sx={{ textAlign: 'right' }} color={theme.palette.grey[900]} mt={0.75}>
          {`${dataPoint.lastValue} ${dataPoint.unit}`}
        </Typography>
      </Box>
    </DeviceCard>
  );
}

export default AirthinxParameter;

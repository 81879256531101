/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-useless-fragment */
import { Stack, Chip, Tooltip } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { BodyModule } from '~/pages/device_edit/components';

function SensorErrors({ sensor }) {
  if (!sensor?.hasError) {
    return null;
  }
  return (
    <BodyModule title="Errors" columns={1}>
      <Stack direction="row" spacing={0} flexWrap="wrap" gap={1}>
        {sensor?.displayErrors.map((dp) => (
          <Tooltip title={dp.name}>
            <Chip color="error" label={dp.name} sx={{ maxWidth: '120px' }} />
          </Tooltip>
        ))}
      </Stack>
    </BodyModule>
  );
}

export default observer(SensorErrors);

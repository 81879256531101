import { DataTypeProvider } from '@devexpress/dx-react-grid';
import isEmpty from 'lodash/isEmpty';
import NavLink from '~/components/nav_link';
import { ListItemStyled, ExternalIdStyled } from '~/components/organizations_select/styled';

export default function NameFormatter(props: DataTypeProvider.ValueFormatterProps) {
  const {
    row: { name, serial, isMonitorable, _id: nodeId }
  } = props;
  if (isEmpty(name)) {
    return <ListItemStyled>{serial}</ListItemStyled>;
  }
  return (
    <ListItemStyled>
      {isMonitorable ? <NavLink to={`devices/${nodeId}/monitor`}>{name}</NavLink> : name}
      <ExternalIdStyled>{serial}</ExternalIdStyled>
    </ListItemStyled>
  );
}

/* eslint-disable @typescript-eslint/no-use-before-define */
import { types as t, Instance } from 'mobx-state-tree';
import Sensor from '~/mst/models/device/sensor';
import BitConfigType from '~/mst/common/bit_config_type';

const DavisSensor = t
  .compose(
    t.model({
      us_units: t.maybeNull(BitConfigType)
    }),
    Sensor
  )
  .named('Davis');

export interface IDavisSensor extends Instance<typeof DavisSensor> {}

export default DavisSensor;

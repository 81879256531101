import { Instance } from 'mobx-state-tree';
import actions from './actions';
import model from './model';
import views from './views';
import WidgetProcessor from '../processor';

const HeatmapWidget = model.views(views).actions(actions);

export interface IHeatmapWidget extends Instance<typeof HeatmapWidget> {}

export default WidgetProcessor(HeatmapWidget);

/* eslint-disable */
import React from 'react';
import { IconProps } from '../types';

export default function MapWidgetIcon({ sx }: IconProps) {
  return (
    <svg width={sx ? sx.width : '100%'} height={sx ? sx.height : '100%'} viewBox="0 0 22 32" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_42_5912)">
        <path
          d="M10.6 0C4.75 0 0 4.75 0 10.6C0 12.47 0.49 14.23 1.34 15.76L8.44 30.13C8.68 31.1 9.55 31.81 10.59 31.81C11.63 31.81 12.5 31.09 12.74 30.13L19.84 15.76C20.69 14.23 21.18 12.48 21.18 10.6C21.21 4.75 16.46 0 10.6 0ZM10.6 15.86C7.7 15.86 5.34 13.51 5.34 10.6C5.34 7.69 7.69 5.34 10.6 5.34C13.51 5.34 15.86 7.69 15.86 10.6C15.86 13.51 13.51 15.86 10.6 15.86Z"
          fill="#7998B5"
        />
      </g>
      <defs>
        <clipPath id="clip0_42_5912">
          <rect width="21.21" height="31.81" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

/* eslint-disable import/prefer-default-export */
import request from '../request';

export function read(serial: string, type: string) {
  return [request, 'get', `thiamis/${serial}/${type}`];
}

export function update(serial: string, type: string, payload) {
  return request('put', `thiamis/${serial}/${type}`, payload);
}

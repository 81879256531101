/* eslint-disable @typescript-eslint/naming-convention */

import { IWidgetModel } from './model';

export default (self: IWidgetModel) => ({
  get breakpointLayouts() {
    if (self.layout) {
      return self.layout.breakpointLayouts;
    }
    return null;
  }
});

/* eslint-disable react/jsx-props-no-spreading */
import { Props as ScrollbarProps } from 'simplebar-react';

import { Box, BoxProps, SxProps } from '@mui/material';

import { ScrollbarStyled, SimpleBarStyled } from './styled';

interface Props extends ScrollbarProps {
  sx?: SxProps; // eslint-disable-line react/require-default-props
}

export default function Scrollbar({ children, sx, ...other }: BoxProps & Props) {
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

  if (isMobile) {
    return (
      <Box sx={{ overflowX: 'auto', ...sx }} {...other}>
        {children}
      </Box>
    );
  }

  return (
    <ScrollbarStyled>
      <SimpleBarStyled timeout={500} clickOnTrack={false} sx={sx} {...other}>
        {children}
      </SimpleBarStyled>
    </ScrollbarStyled>
  );
}

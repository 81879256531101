/* eslint-disable no-param-reassign */
import { flow, applySnapshot } from 'mobx-state-tree';
import api from '~/api';
import { toMapById } from '~/mst/utils';
import { ITemplatesModel } from './model';

export default (self: ITemplatesModel) => ({
  fetch: flow(function* fetch(params = {}) {
    try {
      self.startFetching();
      const { data } = yield self.sync(api.search.nodes, { ...params, type: 'template' });
      // normilization can be handled in snapshotProcessor once https://github.com/mobxjs/mobx-state-tree/pull/1823 is merged
      applySnapshot(self.models, toMapById(data));
      self.finishFetching();
    } catch (error) {
      self.failFetching(error);
    }
  })
});

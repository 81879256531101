import React, { useLayoutEffect, useRef, useState, memo } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Stack } from '@mui/material';
import { getMarkerIcon } from '~/pages/map/configs';
import isArray from 'lodash/isArray';
import Image from '~/components/image';
import EmptyLocation from '~/assets/images/location_empty.png';

const { VITE_GOOGLE_MAPS_KEY } = process.env;

export const ImageStyled = styled(Image)(() => ({
  width: '100%',
  flex: 1
}));

function StaticMap({ status, location }: { status: string; location: number[] }) {
  const targetRef = useRef();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useLayoutEffect(() => {
    if (targetRef.current) {
      setDimensions({
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight
      });
    }
  }, []);

  if (!isArray(location)) {
    return (
      <Stack direction="row" justifyContent="center" alignItems="center">
        <ImageStyled disabledEffect alt="Location not available" src={EmptyLocation} />
      </Stack>
    );
  }
  const pinIcon = new URL(getMarkerIcon(status), import.meta.url).href;
  const latLng = encodeURI(location.join(','));
  const { width, height } = dimensions;
  const size = `${width}x${height}`;
  const url = `https://maps.googleapis.com/maps/api/staticmap?size=${size}&autoscale=2&zoom=15&scale=2&markers=scale:2%7Cicon:${pinIcon}%7C${latLng}&center=${latLng}&style=feature:landscape%7Celement:labels%7Cvisibility:off&style=feature:poi%7Celement:labels%7Cvisibility:off&style=feature:road%7Celement:geometry%7Clightness:57&style=feature:road%7Celement:labels.text.fill%7Cvisibility:on%7Clightness:24&style=feature:road%7Celement:labels.icon%7Cvisibility:off&style=feature:transit%7Celement:labels%7Cvisibility:off&style=feature:water%7Celement:labels%7Cvisibility:off&key=${VITE_GOOGLE_MAPS_KEY}`;

  return (
    <Box
      ref={targetRef}
      style={{
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        height: '100%',
        backgroundImage: `url("${url}")`
      }}
    />
  );
}

export default memo(StaticMap);

import { LoadingButton } from '@mui/lab';
import { Card, Box, Stack } from '@mui/material';

import UserAccountForm from '~/components/user_form/account_form';
import { timezones } from '~/utils/timezones';
import useLocales from '~/hooks/use_locales';

type Props = {
  handleSubmit: VoidFunction;
  submitting: boolean;
};

export default function UserNewForm({ handleSubmit, submitting }: Props) {
  const { t } = useLocales();
  const tzOptions: Array<{ label: string; value: string; group?: boolean }> = [];
  Object.entries(timezones).forEach(([key, values]) => {
    tzOptions.push({ value: '', label: key, group: true });
    tzOptions.push(...values.map((tz) => ({ label: tz.label, value: tz.timezone })));
  });

  return (
    <Card sx={{ mt: 4, display: 'block', overflow: 'hidden' }}>
      <Box sx={{ p: 3, display: 'block' }}>
        <UserAccountForm isAvatar />
        <Stack alignItems="flex-end" sx={{ mt: 3 }}>
          <LoadingButton onClick={handleSubmit} type="submit" variant="contained" loading={submitting}>
            {t('base.buttons.save_changes')}
          </LoadingButton>
        </Stack>
      </Box>
    </Card>
  );
}

/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/naming-convention */
import { flow, applySnapshot, getParent } from 'mobx-state-tree';
import pick from 'lodash/pick';
import api from '~/api';

import { IAvailableDevicesModel } from './model';

function mapById(data) {
  return data.reduce((acc: any, item: any) => {
    const { name, connection } = item;
    if (!acc[name]) {
      acc[name] = { name, connections: [] };
    }
    const [, com_port, com_type, address] = connection.split('.');
    acc[name].connections.push({ com_port, com_type, address });
    return acc;
  }, {});
}

export default (self: IAvailableDevicesModel) => ({
  fetch: flow(function* fetch() {
    try {
      self.startFetching();
      const node = getParent(self);
      const { data } = yield api.nodes.fetchAvailableDevices(pick(node.toJSON(), '_id', 'organization_id', 'serial', 'devices'));
      applySnapshot(self, {
        models: mapById(data)
      });
      self.finishFetching();
    } catch (error) {
      self.failFetching(error);
    }
  })
});

import { Chip } from '@mui/material';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import useLocales from '~/hooks/use_locales';
import DoneIconStyled from './styled';

export default function ReadyFormatter(props: DataTypeProvider.ValueFormatterProps) {
  const {
    value,
    row: { ready }
  } = props;
  const { t } = useLocales();
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Chip {...(value === 'yes' && { icon: <DoneIconStyled /> })} label={t(`base.labels.${ready}`)} />;
}

import React, { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ButtonDesktopOnly from '~/components/adaptive_button';

import useLocales from '~/hooks/use_locales';
import useActions from './context';
import { MenuStyled } from './styled';

type ActionsMenuProps = {
  btnSize?: string;
  children: React.ReactElement[];
};

export default function ActionsMenu({ children, btnSize = 'large' }: ActionsMenuProps) {
  const { t } = useLocales();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { toggleMenu, isOpen } = useActions();

  return (
    <ButtonDesktopOnly
      aria-haspopup="true"
      variant="contained"
      size={btnSize}
      onClick={(event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        toggleMenu();
      }}
      fullWidth
      endIcon={<KeyboardArrowDownIcon />}
      breakpoint="md"
    >
      {t('base.labels.actions')}
      <MenuStyled
        hideBackdrop
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button'
        }}
        open={isOpen}
        anchorEl={anchorEl}
      >
        {children}
      </MenuStyled>
    </ButtonDesktopOnly>
  );
}

/* eslint-disable no-param-reassign */
import { types as t, Instance } from 'mobx-state-tree';
import Fetchable from '~/mst/models/abstract/fetchable';
import CollectionArray from '~/mst/models/abstract/collection_array';
import DataPointAlert from '~/mst/models/data_point/alert/default';

const DataPointsAlertModel = t.compose(CollectionArray(DataPointAlert), Fetchable({ cache: true })).named('DataPointsAlert');

export interface IDataPointsAlertModel extends Instance<typeof DataPointsAlertModel> {}

export default DataPointsAlertModel;

/* eslint-disable react/jsx-props-no-spreading */
import { autorun } from 'mobx';
import { useEffect, useState } from 'react';

function getWidth(label, options: { multiplier?: number; extra?: number } = {}) {
  const { multiplier = 1, extra = 25 } = options;
  const length = label.split('').reduce((acc: number, letter) => {
    // eslint-disable-next-line no-param-reassign
    acc += /^[A-Z]*$/.test(letter) ? 17 : 10;
    return acc;
  }, 0);
  const width = extra + length * multiplier;
  return width > 200 ? width : width + extra;
}

const useWidth = (label, options) => {
  const [width, setWidth] = useState<number | null>(null);
  useEffect(() =>
    autorun(() => {
      if (label) {
        setWidth(getWidth(label, options));
      }
    })
  );

  return width;
};

export default useWidth;

/* eslint-disable import/prefer-default-export */
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { OrganizationName } from '~/pages/organizations/grid_container/name_formatter';
import RoleFormatter from '~/pages/invitations/grid_container/role_formatter';
import ActionsFormatter from './actions_formatter';

export function ActionsProvider(props) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <DataTypeProvider {...props} formatterComponent={ActionsFormatter} />;
}

export default function OrganizationFormatter({ row }: DataTypeProvider.ValueFormatterProps) {
  return <OrganizationName name={row.organizationName} externalId={row.externalId} />;
}

export function OrganizationProvider(props) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <DataTypeProvider {...props} formatterComponent={OrganizationFormatter} />;
}

export function RoleProvider(props) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <DataTypeProvider {...props} formatterComponent={RoleFormatter} />;
}

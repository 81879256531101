import { Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import useFetch from '~/hooks/use_fetch';
import useAuth from '~/hooks/use_auth';
import Container from '~/components/container';
import useLocales from '~/hooks/use_locales';
import useMst from '~/hooks/use_mst';

import Widgets from './widgets';

function Home() {
  const { t } = useLocales();
  const { auth } = useAuth();
  const { home } = useMst();

  useFetch(home?.recentAlertsWidget);
  useFetch(home?.devicesOnline24hChartWidget);
  useFetch(home?.totalDevicesOnlinePieChartWidget);
  useFetch(home?.totalDevicesOnlineColumnChartWidget);

  return (
    <Container maxWidth={false} disableGutters>
      <Typography variant="h4" component="h1" paragraph>
        {auth.user.first_name ? t('home.welcome', { user: auth.user.first_name }) : t('home.welcome')}!
      </Typography>
      <Widgets dashboard={home} />
    </Container>
  );
}

export default observer(Home);

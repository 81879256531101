import React from 'react';
import { LoadingButton } from '@mui/lab';
import { Box, Grid } from '@mui/material';
import useLocales from '~/hooks/use_locales';
import TextInput from '~/components/final_form/text_input';
import FormGrid from '~/components/@material-extend/form_grid';
import PhoneInput from '~/components/final_form/phone_input';
import ImageUpload from '~/components/final_form/image_upload';
import type { ICertificateModel } from '~/mst/models/certificate';
import { ValueLabelPair } from '~/mst/models/abstract/collection_types';
import DevicesSelect from '~/components/devices_select';

type EditCertificateFormType = {
  submitting: boolean;
  nodes: ValueLabelPair[];
  handleSubmit: VoidFunction;
  model: ICertificateModel;
};

function EditCertificateForm({ nodes, handleSubmit, submitting, model }: EditCertificateFormType) {
  const { t } = useLocales();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <FormGrid columns="2">
          <ImageUpload name="photo" />
          <FormGrid>
            <DevicesSelect name="devices" label={t('certificates.devices')} options={nodes} multiple />
            <TextInput
              name="name"
              label={t('certificates.name')}
              helperText={
                <small>
                  <b>Ex:</b> Apple Inc
                  <br />
                  <b>Note:</b> It will be a part of the page title
                </small>
              }
            />
            <TextInput multiline rows={3} name="description" label={t('certificates.description')} />
            <PhoneInput name="phone" label={t('certificates.phone')} />
            <TextInput
              name="address"
              multiline
              rows={3}
              label={t('certificates.address')}
              helperText={
                <Box display="flex">
                  <small>
                    <b>Ex:&nbsp;</b>
                  </small>
                  <small>
                    3401 Grays Ferry Avenue
                    <br />
                    Philadelphia, PA 19146, USA
                  </small>
                </Box>
              }
            />
          </FormGrid>
        </FormGrid>
      </Grid>
      <Grid item xs={12} md={12}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <LoadingButton type="submit" disabled={submitting} variant="contained" loading={submitting} onClick={handleSubmit}>
            {model.isNew ? t('base.buttons.create_new') : t('base.buttons.save_changes')}
          </LoadingButton>
        </Box>
      </Grid>
    </Grid>
  );
}

export default EditCertificateForm;

import FormGrid from '~/components/@material-extend/form_grid';
import { observer } from 'mobx-react-lite';
import { FieldArray } from 'react-final-form-arrays';

import type { IAlert } from '~/mst/models/alert';

import DataPointsField from './data_points';

type ConditionsFormType = {
  model: IAlert;
};

function ConditionsForm({ model }: ConditionsFormType) {
  return (
    <FormGrid sx={{ width: '100%', rowGap: '8px', p: 0 }}>
      <FieldArray name="data_points">{({ fields }) => <DataPointsField fields={fields} model={model} />}</FieldArray>
    </FormGrid>
  );
}

export default observer(ConditionsForm);

import { useState } from 'react';

import { Box, Card, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { observer } from 'mobx-react-lite';

import I18n from '~/utils/i18n';
import { Iconify } from '~/components/iconify';
import AccessForm from '~/components/user_form/access_form';
import useAuth from '~/hooks/use_auth';
import Container from '~/components/container';
import AccountForm from './account_form';
import ProfileCover from './profile_cover';
import PasswordResetForm from './password_reset_form';
import { TabsWrapperStyled } from './styled';

const TABS = {
  profile: {
    value: '1',
    title: I18n.t('users.profile_title')
  },
  access: {
    value: '2',
    title: I18n.t('users.access')
  },
  reset_password: {
    value: '3',
    title: I18n.t('users.reset_password')
  }
};

function UserProfile() {
  const [currentTab, setCurrentTab] = useState(TABS.profile.value);
  const { auth } = useAuth();

  return (
    <Container>
      <TabContext value={currentTab}>
        <Card
          sx={{
            mb: 3,
            p: 0,
            height: 280,
            position: 'relative',
            overflow: 'hidden'
          }}
        >
          <ProfileCover />

          <TabsWrapperStyled>
            <TabList onChange={(e, value) => setCurrentTab(value)}>
              <Tab
                disableRipple
                value={TABS.profile.value}
                icon={<Iconify icon="ic:round-account-box" width={20} height={20} />}
                label={TABS.profile.title}
              />
              <Tab
                disableRipple
                value={TABS.access.value}
                icon={<Iconify icon="eva:people-fill" width={20} height={20} />}
                label={TABS.access.title}
              />
              <Tab
                disableRipple
                value={TABS.reset_password.value}
                icon={<Iconify icon="ic:round-perm-media" width={20} height={20} />}
                label={TABS.reset_password.title}
              />
            </TabList>
          </TabsWrapperStyled>
        </Card>

        <TabPanel key={TABS.profile.value} value={TABS.profile.value}>
          <AccountForm />
        </TabPanel>
        <TabPanel key={TABS.access.value} value={TABS.access.value}>
          <Card sx={{ mt: 4, overflow: 'hidden' }}>
            <Box sx={{ p: 3, margin: '0 auto' }} maxWidth="md">
              <AccessForm user={auth.user} />
            </Box>
          </Card>
        </TabPanel>
        <TabPanel key={TABS.reset_password.value} value={TABS.reset_password.value}>
          <PasswordResetForm />
        </TabPanel>
      </TabContext>
    </Container>
  );
}

export default observer(UserProfile);

import { Instance } from 'mobx-state-tree';
import CollectionArrayProcessor from '~/mst/models/abstract/collection_array_processor';
import model from './model';
import actions from './actions';

const DataPointsAlert = model.actions(actions);

export interface IDataPointsAlert extends Instance<typeof DataPointsAlert> {}

export default CollectionArrayProcessor(DataPointsAlert);

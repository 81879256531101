import { types as t, Instance } from 'mobx-state-tree';
import WidgetModel from '~/mst/models/widget';
import NodesStore from '~/mst/models/nodes';

const MapWidgetModel = t
  .compose(
    WidgetModel,
    t.model({
      type: 'MapWidget',
      nodes: t.maybeNull(NodesStore),
      node_id: t.maybeNull(t.union(t.string, t.array(t.string)))
    })
  )
  .named('MapWidget');

export interface IMapWidgetModel extends Instance<typeof MapWidgetModel> {}

export default MapWidgetModel;

import { DataTypeProvider } from '@devexpress/dx-react-grid';
import DateFormatter from '~/components/react_grid/datetime_formatter';
import ActionsFormatter from './actions_formatter';

export function FromToProvider(props) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <DataTypeProvider {...props} formatterComponent={DateFormatter} />;
}

export function ActionsProvider(props) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <DataTypeProvider {...props} formatterComponent={ActionsFormatter} />;
}

import { styled, Box, Theme } from '@mui/material';
import { transientOptions } from '~/utils/styled';

const IconStyled = styled(
  Box,
  transientOptions
)(({ theme, $iconMaxWidth, $width, $height }: { theme: Theme; $iconMaxWidth?: string; $width: number; $height: number }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  maxWidth: '190px',
  minWidth: '40px',
  overflow: 'hidden',
  margin: '0',
  maxHeight: '160px',
  marginBottom: theme.spacing(1.5),
  ...($iconMaxWidth && {
    maxWidth: $iconMaxWidth,
    minWidth: '15px'
  }),
  ...($width && {
    width: `${$width}px !important`,
    '> svg': {
      width: `${$width}px !important`
    }
  }),
  ...($height && {
    height: `${$height}px !important`,
    img: {
      height: `${$height}px !important`
    }
  })
}));

export default IconStyled;

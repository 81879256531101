import { types as t, Instance } from 'mobx-state-tree';

const CertificateUserMetadataModel = t
  .model({
    phone: t.optional(t.string, ''),
    address: t.optional(t.string, ''),
    photo: t.optional(t.string, '')
  })
  .named('CertificateUserMetadata');

export interface ICertificateUserMetadataModel extends Instance<typeof CertificateUserMetadataModel> {}

export default CertificateUserMetadataModel;

import { observer } from 'mobx-react-lite';
import DeviceLastUpdate from '~/components/device_last_update';
import DeviceTimezone from '~/components/device_timezone';
import { INode } from '~/mst/models/node';
import { PATHS } from '~/utils/constants';
import StatubBarContainerStyled from './styled';

type StatusType = {
  node: INode;
};

function StatusBar({ node }: StatusType) {
  const timezone = node?.data_points?.getByPath(PATHS.TIMEZONE)?.lastValue;

  return (
    <StatubBarContainerStyled direction="row" alignItems="center" justifyContent="space-between">
      <DeviceTimezone timezone={timezone} iconLeft />
      <DeviceLastUpdate lastTimeReportedTs={node?.data_points?.lastTimeReportedTs} />
    </StatubBarContainerStyled>
  );
}

export default observer(StatusBar);

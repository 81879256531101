import React, { useMemo } from 'react';
import MuiModal, { ModalProps as MuiModalProps } from '@mui/material/Modal';
import { IconButton, Box, Button, CardHeader } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import I18n from '~/utils/i18n';

import { Iconify } from '~/components/iconify';
import { ModalContext } from '~/contexts/modal_context';
import { ModalStyled, ModalActionsStyled } from './styled';

type Sizes = 'sm' | 'md' | 'lg';

type ModalProps = {
  open: boolean;
  handleClose: VoidFunction;
  // eslint-disable-next-line react/require-default-props
  modal?: MuiModalProps;
  title: string;
  children: React.ReactElement;
  size?: Sizes; // eslint-disable-line react/require-default-props
};

type ModalActionsProps = {
  onSave: () => void;
  onClose: () => void;
  saveLabel: string;
  disabled?: boolean;
  loading?: boolean;
};

export function ModalActions({ onSave, onClose, saveLabel, disabled, loading }: ModalActionsProps) {
  return (
    <ModalActionsStyled>
      <Button disabled={loading} variant="outlined" onClick={onClose}>
        {I18n.t('base.buttons.cancel')}
      </Button>
      <LoadingButton type="submit" variant="contained" disabled={loading || disabled} loading={loading} onClick={onSave}>
        {saveLabel}
      </LoadingButton>
    </ModalActionsStyled>
  );
}

export default function Modal(props: ModalProps) {
  const { children, title, size, modal, open, handleClose } = props;
  const contextValue = useMemo(() => ({ open, handleClose }), [open, handleClose]);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <MuiModal open={open} {...modal}>
      <ModalStyled size={size}>
        <ModalContext.Provider value={contextValue}>
          <Box>
            <CardHeader title={title} />
            <IconButton onClick={handleClose} sx={{ position: 'absolute', top: 10, right: 10 }}>
              <Iconify icon="eva:close-fill" width={20} height={20} />
            </IconButton>
          </Box>
          <Box>{open && children}</Box>
        </ModalContext.Provider>
      </ModalStyled>
    </MuiModal>
  );
}

/* eslint-disable no-prototype-builtins */
import { observer } from 'mobx-react-lite';
import { useFormState } from 'react-final-form';
import toNumber from 'lodash/toNumber';

import Radio from '~/components/final_form/radio_input';
import { ISensor } from '~/mst/models/device/sensor';
import { BodyModule } from '~/pages/device_edit/components';
import { FormLabelStyled } from '~/pages/device_edit/components/styled';
import useLocales from '~/hooks/use_locales';
import FormGrid from '~/components/@material-extend/form_grid';
import Select from '~/components/final_form/select';
import SwitchFields from '../sensor/common/switch_fields';
import { SensorProperty } from '../components';
import StelAlerts from '../sensor/common/stel_alerts';
import { AutoZero, FlowCalibration, PhotometricCalibration, SizeCorrection } from './sections';

type SensorType = {
  sensor: ISensor;
};

const pmMicrogramOptions = [
  { value: 0, label: 'mg' },
  { value: 1, label: 'μg' }
];

function DustTrak({ sensor }: SensorType) {
  const {
    values: { profile }
  } = useFormState({ subscription: { values: true } });
  const { t } = useLocales();
  return (
    <BodyModule title={t('device_monitor.forms.settings')} columns={1} expandable expanded>
      <SwitchFields>
        <SensorProperty name="pm_microgram">
          <Radio
            label={<FormLabelStyled>PM Unit</FormLabelStyled>}
            name="pm_microgram"
            options={pmMicrogramOptions}
            parse={toNumber}
            row
            data-testid="pmMicrogramRadio"
          />
        </SensorProperty>
      </SwitchFields>
      <SensorProperty name="profile">
        <FormGrid columns={2}>
          <Select
            name="profile"
            data-testid="profileSelect"
            label={t('sensor.configuration.profile')}
            options={sensor.formValues.profile_options?.map((value) => ({ value, label: value })) || []}
          />
        </FormGrid>
      </SensorProperty>
      <SensorProperty name="auto_zero">
        <AutoZero sensor={sensor} />
      </SensorProperty>
      <SensorProperty name="flow_calibration">
        <FlowCalibration />
      </SensorProperty>
      {profile === 'custom' && (
        <>
          <SensorProperty name="photometric_calibration">
            <PhotometricCalibration sensor={sensor} />
          </SensorProperty>
          <SensorProperty name="size_correction">
            <SizeCorrection />
          </SensorProperty>
        </>
      )}
      <StelAlerts />
    </BodyModule>
  );
}

export default observer(DustTrak);

/* eslint-disable react/require-default-props */
import { SxProps } from '@mui/material';
import BatteryContainer from '~/components/device_power/battery_level';
import UsbContainer from '~/components/device_power/usb_source';

import useLocales from '~/hooks/use_locales';

type ConnectionType = {
  batteryLevel: number;
  iconLeft?: boolean;
  showLabel?: boolean;
  isAirthinx?: boolean;
  sx?: SxProps;
};

function DeviceConnection({ batteryLevel, iconLeft = false, sx, showLabel = true, isAirthinx }: ConnectionType) {
  const { t } = useLocales();
  if (isAirthinx) {
    return <UsbContainer showLabel={showLabel} iconLeft={iconLeft} />;
  }

  return (
    <BatteryContainer
      iconLeft={iconLeft}
      value={batteryLevel}
      title={t('thiamis.more_info.battery', { value: batteryLevel })}
      noValueText={t('base.labels.n_a')}
      unit="V"
      showLabel={showLabel}
      sx={sx}
    />
  );
}

export default DeviceConnection;

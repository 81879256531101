import React, { useMemo } from 'react';
import { Grid } from '@devexpress/dx-react-grid-material-ui';
import { computed } from 'mobx';

import useLocales from '~/hooks/use_locales';

import { Root } from '~/components/react_grid';
import type { IFetchable } from '~/mst/models/abstract/fetchable';

const useLoading = (collection: IFetchable) => {
  const { t } = useLocales();
  const RootComponent = useMemo(
    () =>
      computed(() =>
        React.memo((props: Grid.RootProps) => {
          // eslint-disable-next-line react/jsx-props-no-spreading
          return <Root {...props} isSyncing={collection.isFetching} />;
        })
      ),
    [collection.isFetching]
  ).get();

  const messages = useMemo(
    () =>
      computed(() => ({
        noData: collection.isFetching ? t('react_table.loading_data') : t('react_table.no_data')
      })),
    [t, collection.isFetching]
  ).get();
  return {
    messages,
    RootComponent
  };
};

export default useLoading;

import { observer } from 'mobx-react-lite';
import isUndefined from 'lodash/isUndefined';

import useLocales from '~/hooks/use_locales';
import { IDataPointNode } from '~/mst/models/data_point/node/default';

function LastValueFormatter({ row, value }: { value: any; row: IDataPointNode }) {
  const { t } = useLocales();
  if (isUndefined(value)) {
    return t('base.labels.n_a');
  }

  if (row?.unit) {
    return (
      <span>
        {value}
        <small> {row.unit}</small>
      </span>
    );
  }
  return value;
}

export default observer(LastValueFormatter);

import { FieldArray, FieldArrayRenderProps } from 'react-final-form-arrays';
import { Stack, Tooltip, Box } from '@mui/material';
import { observer } from 'mobx-react-lite';
import type { IAlert } from '~/mst/models/alert';
import { IconButtonAnimate } from '~/components/animate';
import { CircleRemoveStyled, DividerStyled } from './styled';
import ConditionsField from './conditions';

export type ConditionType = {
  func: string;
  device: string;
  dataPoint: string;
  value: string | number;
};

type DataPointFieldType = FieldArrayRenderProps<ConditionType, HTMLInputElement> & {
  name: string;
  model: IAlert;
  index: number;
};

function DataPointField(props: DataPointFieldType) {
  const { name, model, fields, index } = props;

  return (
    <Box>
      <Stack spacing={2} direction="row" justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
        <Tooltip title="Remove Condition">
          <IconButtonAnimate
            data-testid={`removeOrConditionBtn${index}`}
            onClick={() => {
              fields.remove(index);
            }}
          >
            <CircleRemoveStyled />
          </IconButtonAnimate>
        </Tooltip>
        <FieldArray name={`${name}.conditions`}>{({ fields: conditions }) => <ConditionsField fields={conditions} model={model} />}</FieldArray>
      </Stack>
      {fields.value.length - 1 !== index && (
        <Box alignItems="center" sx={{ width: '100%' }}>
          <DividerStyled orientation="vertical" flexItem>
            OR
          </DividerStyled>
        </Box>
      )}
    </Box>
  );
}

export default observer(DataPointField);

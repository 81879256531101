/* eslint-disable @typescript-eslint/naming-convention */
import pick from 'lodash/pick';
import get from 'lodash/get';
import flatten from 'lodash/fp/flatten';
import flow from 'lodash/fp/flow';
import map from 'lodash/fp/map';
import uniq from 'lodash/fp/uniq';

import { ITokenModel } from './model';

export default (self: ITokenModel) => {
  return {
    get searchString() {
      const searchFields = ['ownerName', 'permissions'];
      return flow(
        Object.values,
        flatten,
        map((field) => field.split('_')),
        flatten,
        uniq
      )(pick(self, searchFields))
        .join(' ')
        .toLowerCase();
    },
    get ownerName() {
      if (!self.owner) {
        return '';
      }
      return self.owner.name;
    },
    get deviceScope() {
      return get(self.query, 'node_id', '');
    }
  };
};

import React, { useEffect, useState, useRef } from 'react';
import { renderToString } from 'react-dom/server';
import Box from '@mui/material/Box';
import { autorun } from 'mobx';
import { useTheme } from '@mui/material/styles';
import { MarkerClusterer } from '@googlemaps/markerclusterer';

import { getMapConfig, getStreetViewConfig, getClusterConfig, CENTER_MAP, getPinConfig } from '~/pages/map/configs';
import { observer } from 'mobx-react-lite';
import Widget from './widget';
import DeviceIndicator from './DeviceIndicator';

type MarkerNode = {
  status: string;
  name: string;
  serial: string;
  statusText: string;
  location: [number, number];
  id: string;
};
type MapPropType = {
  nodes: MarkerNode[];
  options?: google.maps.MapOptions;
  mapId: string;
  onDragEnd?: VoidFunction;
  isPinnable?: boolean;
};

const Map = observer(({ nodes, options, onDragEnd, isPinnable, mapId }: MapPropType) => {
  const ref = useRef();
  const theme = useTheme();
  const [map, setMap] = useState(null);
  const [cluster, setCluster] = useState(null);
  const [nodeId, setNodeId] = useState(null);
  const [selectedeMarker, setSelectedeMarker] = useState(null);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const newMap = new window.google.maps.Map(ref.current, getMapConfig({ ...options, mapId }, theme));
    setMap(newMap);
    // eslint-disable-next-line no-undef
    newMap.get('streetView')?.setOptions(getStreetViewConfig(google.maps));
    setCluster(new MarkerClusterer(getClusterConfig(theme, newMap, [])));
  }, [theme, options, mapId]);

  const showMarkerWidget = (node, marker) => {
    setNodeId(node?._id);
    setSelectedeMarker(marker);
    const path = marker.content.querySelector('path[class*="maps-pin-view-border"]');
    path.setAttribute('style', `stroke-width: 2;stroke: ${getPinConfig(node?.status).borderColor};`);
  };

  const hideMarkerWidget = () => {
    setNodeId(null);
    const path = selectedeMarker.content.querySelector('path[class*="maps-pin-view-border"]');
    path.setAttribute('style', 'stroke-width: 0;');
    setSelectedeMarker(null);
  };

  useEffect(
    () =>
      autorun(() => {
        if (map && cluster) {
          cluster.clearMarkers();
          // eslint-disable-next-line no-undef
          const bounds = new google.maps.LatLngBounds();
          const markers = [];
          nodes.forEach((node) => {
            const [lat, lng] = Array.isArray(node.location) ? node.location : [];

            // const [lat, lng] = [34.382360, -118.382953];
            if (lat && lng) {
              let latLng = new google.maps.LatLng(lat, lng);
              if (markers.some((marker) => marker.position?.equals(latLng))) {
                const newLat = lat + (Math.random() - 0.5) / 5000;
                const newLng = lng + (Math.random() - 0.5) / 5000;

                latLng = new google.maps.LatLng(newLat, newLng);
              }

              const pin = new google.maps.marker.PinElement(getPinConfig(node.status));
              const marker = new google.maps.marker.AdvancedMarkerElement({
                map,
                content: pin.element,
                position: latLng,
                ...(onDragEnd && { gmpDraggable: true })
              });

              const infoWindowId = `info-window-${node._id}`;

              // eslint-disable-next-line no-undef
              const infoWindow = new google.maps.InfoWindow({
                content: renderToString(DeviceIndicator({ infoWindowId, node, isShowTitle: true }))
              });

              if (isPinnable) {
                // eslint-disable-next-line func-names
                google.maps.event.addListener(marker, 'click', function () {
                  showMarkerWidget(node, marker);
                  map.setCenter(marker.position);
                });
                marker.content.addEventListener('mouseenter', function () {
                  infoWindow.open(map, marker);
                });
                // eslint-disable-next-line no-undef
                marker.content.addEventListener('mouseleave', () => {
                  infoWindow.close();
                });
                // eslint-disable-next-line no-undef
                google.maps.event.addListener(infoWindow, 'domready', () => {
                  const button = document.getElementById(infoWindowId).parentNode.parentNode.parentNode.querySelector('button');
                  button.remove();
                });
              }
              // eslint-disable-next-line func-names
              if (onDragEnd) {
                marker.addListener('dragend', onDragEnd);
              }
              bounds.extend(marker.position);

              markers.push(marker);
            }
          });

          cluster.addMarkers(markers);

          if (nodes.length) {
            // eslint-disable-next-line func-names
            google.maps.event.addListenerOnce(map, 'bounds_changed', function () {
              map.setZoom(map.getZoom() - 1);

              if (map.getZoom() > 16) {
                map.setZoom(16);
              }
            });
            map.fitBounds(bounds);
          } else {
            map.setCenter(CENTER_MAP);
          }
        }
      }),
    [nodes, map, cluster, onDragEnd, isPinnable]
  );

  return (
    <>
      <Box ref={ref} id="map" sx={{ width: '100%', height: '100%' }} />
      {nodeId && <Widget nodeId={nodeId} onHideWidget={hideMarkerWidget} ref={ref.current} />}
    </>
  );
});

export default Map;

/* eslint-disable no-prototype-builtins */
import { Box } from '@mui/material';
import { observer } from 'mobx-react-lite';
import Select from '~/components/final_form/select';
import { getValueLabelPairs } from '~/mst/utils';
import Radio from '~/components/final_form/radio_input';
import { PRECIPITATION_UNITS, PRESSURE_UNITS, TEMPERATURE_UNITS, WINDSPEED_UNITS } from '~/mst/models/device/sensor/airmar/config';
import { BodyModule } from '~/pages/device_edit/components';

import useLocales from '~/hooks/use_locales';
import { SensorProperty } from '../components';
import SwitchFields from '../sensor/common/switch_fields';

const precipitationUnitsOptions = [{ value: null, label: 'Auto' }, ...getValueLabelPairs(PRECIPITATION_UNITS)];
const pressureUnitsOptions = [{ value: null, label: 'Auto' }, ...getValueLabelPairs(PRESSURE_UNITS)];
const temperatureUnitsOptions = [{ value: null, label: 'Auto' }, ...getValueLabelPairs(TEMPERATURE_UNITS)];
const windspeedUnitsOptions = [{ value: null, label: 'Auto' }, ...getValueLabelPairs(WINDSPEED_UNITS)];

function Airmar() {
  const { t } = useLocales();

  return (
    <BodyModule title={t('device_monitor.forms.settings')} columns={1} expandable expanded>
      <SwitchFields />
      <Box>
        <SensorProperty name="pressure_unit">
          <Select name="pressure_unit" label={t('thiamis.configuration.pressure_unit')} options={pressureUnitsOptions} />
        </SensorProperty>
      </Box>
      <Box>
        <SensorProperty name="windspeed_unit">
          <Select name="windspeed_unit" label={t('thiamis.configuration.windspeed_unit')} options={windspeedUnitsOptions} />
        </SensorProperty>
      </Box>
      <Box>
        <SensorProperty name="precipitation_unit">
          <Radio name="precipitation_unit" label={t('thiamis.configuration.precipitation_unit')} options={precipitationUnitsOptions} />
        </SensorProperty>
      </Box>
      <Box>
        <SensorProperty name="temperature_unit">
          <Radio name="temperature_unit" label={t('thiamis.configuration.temperature_unit')} options={temperatureUnitsOptions} />
        </SensorProperty>
      </Box>
    </BodyModule>
  );
}

export default observer(Airmar);

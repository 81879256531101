import { types as t } from 'mobx-state-tree';

export default (store) =>
  t.snapshotProcessor(store, {
    preProcessor(values) {
      if (Array.isArray(values)) {
        return { models: values };
      }
      return values;
    },
    postProcessor(sn) {
      if (sn.models) {
        return Object.values(sn.models);
      }
      return sn;
    }
  });

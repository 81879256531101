import React from 'react';
import { observer } from 'mobx-react-lite';
import { CardContent, Typography, Box, Stack, IconButton } from '@mui/material';
import { Iconify } from '~/components/iconify';
import type { INode } from '~/mst/models/node';
import useLocales from '~/hooks/use_locales';
import useFetch from '~/hooks/use_fetch';
import LoadingBox from '~/components/loading_box';
import CopyToClipboard from '~/components/copy_to_clipboard';
import { FormLabelStyled } from '~/pages/device_edit/components/styled';

type DefaultWifiModalContentProps = {
  node: INode;
};

export function DefaultWifiModal({ node }: DefaultWifiModalContentProps) {
  const { t } = useLocales();

  const { isFetching } = useFetch(node.default_wifi);
  if (isFetching) {
    return <LoadingBox />;
  }

  return (
    <CardContent>
      <Typography pb={3}>{t('thiamis.default_wifi_dialog.help_text')}</Typography>
      <Stack direction="row" gap={5} justifyContent="center">
        <Box>
          <FormLabelStyled>{t('attributes.thiamis.wifi_ssid')}</FormLabelStyled>
          <Stack direction="row" gap={1} alignItems="center">
            <Typography>{node.default_wifi.wifi_ssid}</Typography>
            <CopyToClipboard value={node.default_wifi.wifi_ssid} data-dd-action-name="Copy Default WiFi SSID">
              <IconButton color="info">
                <Iconify icon="eva:copy-outline" />
              </IconButton>
            </CopyToClipboard>
          </Stack>
        </Box>
        <Box>
          <FormLabelStyled>{t('attributes.thiamis.wifi_password')}</FormLabelStyled>
          <Stack direction="row" gap={1} alignItems="center">
            <Typography>{node.default_wifi.wifi_password}</Typography>
            <CopyToClipboard value={node.default_wifi.wifi_password} data-dd-action-name="Copy Default WiFi Password">
              <IconButton color="info">
                <Iconify icon="eva:copy-outline" />
              </IconButton>
            </CopyToClipboard>
          </Stack>
        </Box>
      </Stack>
    </CardContent>
  );
}

export default observer(DefaultWifiModal);

import { SortingState, IntegratedSorting, RowDetailState } from '@devexpress/dx-react-grid';
import { Grid, VirtualTable, TableHeaderRow, TableRowDetail } from '@devexpress/dx-react-grid-material-ui';

import { observer } from 'mobx-react-lite';

import { GridStyled } from '~/components/react_grid/styled';
import { Container } from '~/components/react_grid';
import useLoading from '~/hooks/react_grid/use_loading';
import useSorting from '~/hooks/react_grid/use_sorting';
import useMst from '~/hooks/use_mst';
import useExpandRow from '~/hooks/react_grid/use_expand_row';
import GridDetailContainer from './detail_row';

import { CreatedAtProvider, ActionsProvider, OrganizatioNameProvider, PartnerNameProvider } from './info_providers';

function compareDates(a: any, b: any) {
  // @ts-ignore
  return (b != null) - (a != null) || b - a;
}

const columns = [
  { name: 'organizationName', title: 'Organization Name' },
  { name: 'partnerName', title: 'Partner Name' },
  { name: 'created_at', title: 'Date Created' },
  { name: 'actions', title: ' ', sortingEnabled: false }
];

const getRowId = (row) => row.id;

function GridContainer() {
  const { partnerships } = useMst();
  const { expandedRowIds, handleSetExpandedRowIds, Row } = useExpandRow();

  const { messages, RootComponent } = useLoading(partnerships);
  const { sorting, handleSortingChange } = useSorting([
    { columnName: 'partnerName', direction: 'asc' },
    { columnName: 'created_at', direction: 'desc' }
  ]);

  return (
    <GridStyled>
      <Grid rows={partnerships.filteredList} columns={columns} getRowId={getRowId} rootComponent={RootComponent}>
        <SortingState sorting={sorting} onSortingChange={handleSortingChange} />
        <IntegratedSorting columnExtensions={[{ columnName: 'created_at', compare: compareDates }]} />
        <OrganizatioNameProvider for={['organizationName']} />
        <PartnerNameProvider for={['partnerName']} />
        <CreatedAtProvider for={['created_at']} />
        <ActionsProvider for={['actions']} />
        <RowDetailState expandedRowIds={expandedRowIds} onExpandedRowIdsChange={handleSetExpandedRowIds} />
        <VirtualTable
          messages={messages}
          columnExtensions={[{ columnName: 'actions', width: 65 }]}
          height="auto"
          containerComponent={Container}
          rowComponent={Row}
        />
        <TableHeaderRow showSortingControls />
        <TableRowDetail rowComponent={GridDetailContainer} />
      </Grid>
    </GridStyled>
  );
}

export default observer(GridContainer);

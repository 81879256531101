/* eslint-disable @typescript-eslint/naming-convention */
import type { IFilesWidgetModel } from './model';

export default (self: IFilesWidgetModel) => ({
  get filenames() {
    if (self.files) {
      return Object.entries(self.files).map(([filename]) => filename);
    }
    return [];
  }
});

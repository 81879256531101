import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getIdentifier } from 'mobx-state-tree';
import { datadogRum } from '@datadog/browser-rum';
import { Form as FinalForm } from 'react-final-form';
import compact from 'lodash/compact';

import useAuth from '~/hooks/use_auth';
import Yup, { searchableSelectSchema } from '~/utils/yup';
import Modal from '~/components/modal';
import { useValidationSchema } from '~/hooks/use_validate';
import useMst from '~/hooks/use_mst';
import useNavigate from '~/hooks/use_navigate';
import { captureException } from '~/services/sentry';
import toasts from '~/utils/toasts';
import ModalForm from './modal_form';

type ModalProps = {
  open: boolean;
  toggleModal: () => void;
};

function MultiAlertModal({ open, toggleModal }: ModalProps) {
  const { t } = useTranslation();
  const { navigate } = useNavigate();
  const { auth } = useAuth();

  const DownloadMultiAlertSchema = Yup.object().shape({
    organizationId: searchableSelectSchema('Organization is required'),
    devices: Yup.array().required('Required').min(1, 'Devices are required'),
    parameter: searchableSelectSchema('Parameter is required'),
    value: Yup.string().required('Required')
  });

  const validate = useValidationSchema(DownloadMultiAlertSchema);
  const { nodes: nodesStore } = useMst();
  const handleClose = useCallback(() => {
    toggleModal();
  }, [toggleModal]);

  const handleDownload = useCallback(
    (values) => {
      try {
        const { expr, value, parameter, devices, organizationId } = values;
        const nodes = compact(devices?.map(({ value: nodeId }) => nodesStore.getById(nodeId)));
        const dataPoints = compact(
          nodes.map((node) => {
            const dataPoint = node?.data_points?.toArray.find((dp) => dp.presentName === parameter);
            if (dataPoint) {
              return {
                nodeId: getIdentifier(node),
                conditions: {
                  [expr]: [getIdentifier(dataPoint), value]
                }
              };
            }
            return null;
          })
        );
        datadogRum.addAction('create_group_alert', dataPoints);
        navigate('alerts/create', { state: { alert: { data_points: dataPoints, organization_id: organizationId } } });
      } catch (e) {
        captureException(e);
        toasts.error(t('notifications.errors.server_error'));
      }
    },
    [navigate, nodesStore, t]
  );

  const initialValues = useMemo(
    () => ({
      organizationId: auth.organizationId,
      devices: []
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [auth.organizationId]
  );

  return (
    <Modal open={open} handleClose={handleClose} title={t('alerts.create_new_group_alert')}>
      <FinalForm onSubmit={handleDownload} validate={validate} subscription={{ submitting: true, pristine: true }} initialValues={initialValues}>
        {({ handleSubmit }) => <ModalForm handleCancel={handleClose} handleSubmit={handleSubmit} />}
      </FinalForm>
    </Modal>
  );
}

export default MultiAlertModal;

import { Box, Grid } from '@mui/material';
import { ItemTypographyStyled, TitleStyled } from './styled';

function ItemTitle({ children }: { children: React.ReactElement | string }) {
  return (
    <Grid item md={5} sx={{ whiteSpace: 'break-spaces' }}>
      <TitleStyled component="div">{children}</TitleStyled>
    </Grid>
  );
}

function ItemValue({ children }: { children: React.ReactElement | string }) {
  return <ItemTypographyStyled component="div">{children}</ItemTypographyStyled>;
}

function Item({ name, value }: { name: string; value: string }) {
  if (!value) {
    return null;
  }

  return (
    <Box>
      <ItemTitle>{name}</ItemTitle>
      <ItemValue>{value}</ItemValue>
    </Box>
  );
}

export default Item;

import React from 'react';
import * as Sentry from '@sentry/react';
import { useRoutes as useRoutesRouter } from 'react-router-dom';
import ErrorPage from '~/components/error_page';
import withPageTitle from '~/hocs/with_page_title';
import withLoadable from '~/hocs/with_loadable';
import ProtectedRoute from '~/components/protected_route';
import { PERMISSIONS } from '~/mst/models/permissions/types';
import PageInitial from '~/pages/initial';
import PageHome from '~/pages/home';
import PageDashboardView from '~/pages/dashboard_view';
import PageDashboards from '~/pages/dashboards';
import PageCalibrations from '~/pages/calibrations';
import PageReportEdit from '~/pages/report_edit';
import PageReportView from '~/pages/report_view';
import PageReports from '~/pages/reports';
import PageMap from '~/pages/map';
import PageProfile from '~/pages/profile';
import PageDevices from '~/pages/devices';
import PageDeviceClaim from '~/pages/device_claim';
import PageDeviceAdmin from '~/pages/device_admin';
import PageCertificates from '~/pages/certificates';
import PageCertificateEdit from '~/pages/certificate_edit';
import PageAlerts from '~/pages/alerts';
import PageAlertEdit from '~/pages/alert_edit';
import PageAlertMonitor from '~/pages/alert_monitor';
import PageUsers from '~/pages/users';
import PageInvitations from '~/pages/invitations';
import PageApi from '~/pages/api';
import PageWebhooks from '~/pages/webhooks';
import PageArchives from '~/pages/archives';
import PageOrganizations from '~/pages/organizations';
import PagePartnerships from '~/pages/partnerships';
import PageOrganizationEdit from '~/pages/organization_edit';
import PageDeviceEdit from '~/pages/device_edit';
import PageDeviceCreate from '~/pages/device_create';
import PageDeviceMonitor from '~/pages/device_monitor';
import PagePage_404 from '~/pages/page_404';
import Layout from '~/layouts/default_layout';
import ContentLayout from '~/layouts/content_layout';
import LogoOnly from '~/layouts/logo_only';

const withPage = (page: React.ComponentType) => withPageTitle(Sentry.withErrorBoundary(page, { fallback: <ErrorPage /> }));

const Initial = Sentry.withErrorBoundary(withLoadable(PageInitial), { fallback: <ErrorPage /> });
const Home = withPage(PageHome);
const Dashboard = withPage(PageDashboardView);
const Dashboards = withPage(PageDashboards);
const Calibrations = withPage(PageCalibrations);
const ReportEdit = withPage(PageReportEdit);
const ReportView = withPage(PageReportView);
const Reports = withPage(PageReports);
const Map = withPage(PageMap);
const UserProfile = withPage(PageProfile);
const Devices = withPage(PageDevices);
const DevicesClaim = withPage(PageDeviceClaim);
const DevicesAdmin = withPage(PageDeviceAdmin);
const Certificates = withPage(PageCertificates);
const CertificateEdit = withPage(PageCertificateEdit);
const Alerts = withPage(PageAlerts);
const AlertEdit = withPage(PageAlertEdit);
const AlertMonitor = withPage(PageAlertMonitor);
const Users = withPage(PageUsers);
const Invitations = withPage(PageInvitations);
const Api = withPage(PageApi);
const Webhooks = withPage(PageWebhooks);
const Archives = withPage(PageArchives);
const Organizations = withPage(PageOrganizations);
const Partnerships = withPage(PagePartnerships);
const OrganizationsEdit = withPage(PageOrganizationEdit);
const DeviceEdit = withPage(PageDeviceEdit);
const DeviceCreate = withPage(PageDeviceCreate);
const DeviceMonitor = withPage(PageDeviceMonitor);
const Page404 = withPage(PagePage_404);

export const getSlugPath = (path: string) => `/:slug${path}`;

export const routes = [
  {
    path: '/',
    children: [
      {
        element: <Layout />,
        children: [
          {
            element: <ContentLayout $noPadding />,
            children: [
              {
                path: '',
                element: <Initial />
              },
              {
                path: getSlugPath('/home'),
                element: (
                  <ProtectedRoute>
                    <Home title="Home" />
                  </ProtectedRoute>
                )
              },
              {
                path: getSlugPath('/dashboards'),
                children: [
                  {
                    path: '',
                    element: (
                      <ProtectedRoute permission={PERMISSIONS.ReadReports}>
                        <Dashboards title="dashboards.list.title" />
                      </ProtectedRoute>
                    )
                  },
                  {
                    path: getSlugPath('/dashboards/:id'),
                    element: (
                      <ProtectedRoute permission={PERMISSIONS.ReadReports}>
                        <Dashboard title="dashboard.title" />
                      </ProtectedRoute>
                    )
                  }
                ]
              },
              {
                path: getSlugPath('/devices'),
                children: [
                  {
                    path: '',
                    element: (
                      <ProtectedRoute permission={PERMISSIONS.ReadDevices}>
                        <Devices title="devices_page.title" />
                      </ProtectedRoute>
                    )
                  },
                  {
                    path: 'claim',
                    element: (
                      <ProtectedRoute permission={PERMISSIONS.ManageDevices}>
                        <DevicesClaim title="devices_page.title" />
                      </ProtectedRoute>
                    )
                  },
                  {
                    path: ':id/monitor',
                    element: (
                      <ProtectedRoute permission={PERMISSIONS.ReadDevices}>
                        <DeviceMonitor title="device_monitor_page.title" />
                      </ProtectedRoute>
                    )
                  },
                  {
                    path: ':id/create',
                    element: (
                      <ProtectedRoute permission={PERMISSIONS.ManageDevices}>
                        <DeviceCreate title="device_create_configuration_page.title" />
                      </ProtectedRoute>
                    )
                  },
                  {
                    path: ':id/admin',
                    element: (
                      <ProtectedRoute permission={PERMISSIONS.ManageDevices}>
                        <DevicesAdmin title="device_admin_configuration_page.title" />
                      </ProtectedRoute>
                    )
                  },
                  {
                    path: ':id/edit',
                    element: (
                      <ProtectedRoute permission={PERMISSIONS.ManageDevices}>
                        <DeviceEdit title="device_configuration_page.title" />
                      </ProtectedRoute>
                    )
                  }
                ]
              },
              {
                path: getSlugPath('/certificates'),
                children: [
                  {
                    path: '',
                    element: (
                      <ProtectedRoute permission={PERMISSIONS.ReadDevices}>
                        <Certificates title="certificates.title" />
                      </ProtectedRoute>
                    )
                  },
                  {
                    path: 'create',
                    element: (
                      <ProtectedRoute permission={PERMISSIONS.ManageDevices}>
                        <CertificateEdit title="certificates.new" />
                      </ProtectedRoute>
                    )
                  },
                  {
                    path: ':id',
                    element: (
                      <ProtectedRoute permission={PERMISSIONS.ManageDevices}>
                        <CertificateEdit title="certificates.edit" />
                      </ProtectedRoute>
                    )
                  }
                ]
              },
              {
                path: getSlugPath('/reports'),
                children: [
                  {
                    path: '',
                    element: (
                      <ProtectedRoute permission={PERMISSIONS.ReadDevices}>
                        <Reports title="reports.title" />
                      </ProtectedRoute>
                    )
                  },
                  {
                    path: 'create',
                    element: (
                      <ProtectedRoute permission={PERMISSIONS.ManageDevices}>
                        <ReportEdit title="reports.new_title" />
                      </ProtectedRoute>
                    )
                  },
                  {
                    path: ':id',
                    element: (
                      <ProtectedRoute permission={PERMISSIONS.ManageDevices}>
                        <ReportEdit title="reports.edit_title" />
                      </ProtectedRoute>
                    )
                  },
                  {
                    path: ':id/view',
                    element: (
                      <ProtectedRoute permission={PERMISSIONS.ManageDevices}>
                        <ReportView title="reports.view_title" />
                      </ProtectedRoute>
                    )
                  }
                ]
              },
              {
                path: getSlugPath('/alerts'),
                children: [
                  {
                    path: '',
                    element: (
                      <ProtectedRoute permission={PERMISSIONS.ReadDevices}>
                        <Alerts title="alerts.title" />
                      </ProtectedRoute>
                    )
                  },
                  {
                    path: 'create',
                    element: (
                      <ProtectedRoute permission={PERMISSIONS.ManageDevices}>
                        <AlertEdit title="alerts.new" />
                      </ProtectedRoute>
                    )
                  },
                  {
                    path: ':id/monitor',
                    element: (
                      <ProtectedRoute permission={PERMISSIONS.ReadDevices}>
                        <AlertMonitor title="alerts.monitor_page_title" />
                      </ProtectedRoute>
                    )
                  },
                  {
                    path: ':id',
                    element: (
                      <ProtectedRoute permission={PERMISSIONS.ManageDevices}>
                        <AlertEdit title="alerts.config_page_title" />
                      </ProtectedRoute>
                    )
                  }
                ]
              },
              {
                path: getSlugPath('/calibrations'),
                children: [
                  {
                    path: '',
                    element: (
                      <ProtectedRoute permission={PERMISSIONS.ManageDevices}>
                        <Calibrations title="calibrations.title" />
                      </ProtectedRoute>
                    )
                  }
                ]
              },
              {
                path: getSlugPath('/profile'),
                element: <UserProfile title="user_profile.title" />
              },
              {
                path: getSlugPath('/users'),
                element: (
                  <ProtectedRoute permission={PERMISSIONS.ReadUsers}>
                    <Users title="users.title" />
                  </ProtectedRoute>
                )
              },
              {
                path: getSlugPath('/invitations'),
                element: (
                  <ProtectedRoute permission={PERMISSIONS.ReadUsers}>
                    <Invitations title="user_invitations.title" />
                  </ProtectedRoute>
                )
              },
              {
                path: getSlugPath('/api'),
                element: (
                  <ProtectedRoute permission={PERMISSIONS.ManageTokens}>
                    <Api title="api.title" />
                  </ProtectedRoute>
                )
              },
              {
                path: getSlugPath('/webhooks'),
                element: (
                  <ProtectedRoute permission={PERMISSIONS.ManageTokens}>
                    <Webhooks title="Web Hooks" />
                  </ProtectedRoute>
                )
              },
              {
                path: getSlugPath('/archives'),
                element: (
                  <ProtectedRoute permission={PERMISSIONS.ReadDevices}>
                    <Archives title="archives.title" />
                  </ProtectedRoute>
                )
              },
              {
                path: getSlugPath('/partnerships'),
                element: (
                  <ProtectedRoute permission={PERMISSIONS.ReadOrganizations}>
                    <Partnerships title="partnerships.title" />
                  </ProtectedRoute>
                )
              },
              {
                path: getSlugPath('/organizations'),
                children: [
                  {
                    path: '',
                    element: <Organizations title="organizations.title" />
                  },
                  {
                    path: 'new',
                    element: <OrganizationsEdit title="organizations.new" />
                  },
                  {
                    path: ':id',
                    element: <OrganizationsEdit title="organizations.edit" />
                  }
                ]
              }
            ]
          },
          {
            element: <ContentLayout $noPadding />,
            children: [
              {
                path: getSlugPath('/map'),
                element: (
                  <ProtectedRoute permission={PERMISSIONS.ReadDevices}>
                    <Map title="map.title" />
                  </ProtectedRoute>
                )
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '*',
    element: <LogoOnly />,
    children: [
      {
        path: '*',
        element: <Page404 title="Page Not Found" />
      }
    ]
  }
];

const useRoutes = Sentry.wrapUseRoutes(useRoutesRouter);

export default () => useRoutes(routes);

import { useEffect } from 'react';
import { autorun } from 'mobx';
import useMst from '~/hooks/use_mst';
import toasts from '~/utils/toasts';
import useLocales from '~/hooks/use_locales';

const CHECK_NEW_RELEASE_INTERVAL = 60000;
const AUTO_PAGE_RELOAD_TIMEOUT = 60000;
const { CI } = process.env;

const useCheckRelease = () => {
  const { version } = useMst();
  const { t } = useLocales();
  useEffect(() => {
    if (!CI) {
      return () => {};
    }
    const disposer = autorun(async () => {
      if (!version?.last_modified) {
        const lastModified = await version.fetch();
        version.setLastModified(lastModified);
      }
    });
    const interval = setInterval(async () => {
      const lastModified = await version.fetch();
      if (lastModified !== version.last_modified) {
        toasts.info(t('notifications.info.new_release_available', { min: AUTO_PAGE_RELOAD_TIMEOUT / 60000 }));
        setTimeout(() => window.location.reload(), AUTO_PAGE_RELOAD_TIMEOUT);
      }
    }, CHECK_NEW_RELEASE_INTERVAL);
    return () => {
      disposer();
      clearInterval(interval);
    };
  });
};

export default useCheckRelease;

/* eslint-disable @typescript-eslint/naming-convention */
import type { IToken } from '~/mst/models/token';
import type { ITokensModel } from './model';

export default (self: ITokensModel) => {
  return {
    get filteredList() {
      return self.filteredModels.map((token: IToken) => ({
        id: token.id,
        external_id: token.external_id,
        ownerName: token.ownerName,
        permissions: token.permissions,
        device_scope: token.device_scope,
        requests_number: token.requests_number,
        external_id_revealed: token.external_id_revealed,
        last_used_at: token.last_used_at,
        created_at: token.created_at
      }));
    }
  };
};

import { useEffect, useState, useCallback } from 'react';
import { Stack, Box } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { DateTime } from 'luxon';
import isNull from 'lodash/isNull';
import isNaN from 'lodash/isNaN';

import useLocales from '~/hooks/use_locales';
import type { IRange } from '~/mst/models/range';
import { ButtonStyled, DashStyled } from './styled';
import DateTimePicker from './date_time_picker';

type CustomRangePickerProps = {
  range?: IRange;
  setRange: (value: { from?: number; to?: number }) => void;
  onClose: () => void;
};

function CustomRangePicker({ range, setRange, onClose }: CustomRangePickerProps) {
  const { t } = useLocales();
  const [from, setFrom] = useState<DateTime | null>(null);
  const [to, setTo] = useState<DateTime | null>(null);

  useEffect(() => {
    if (range?.fromTs) {
      setFrom(DateTime.fromMillis(range?.fromTs));
    }
    if (!isNull(range.toTs) && !isNaN(range.toTs)) {
      setTo(DateTime.fromMillis(range.toTs));
    }
  }, [range]);

  const handleSetRange = useCallback(() => {
    setRange({
      from: DateTime.fromISO(from).toMillis(),
      to: DateTime.fromISO(to).toMillis()
    });
  }, [from, to, setRange]);

  return (
    <Box mt={2}>
      <Stack direction={{ sm: 'column', md: 'row' }} alignItems="center" spacing={{ xs: 0, sm: 2 }} gap={{ xs: 2, sm: 0 }}>
        <DateTimePicker containerTestId="RangePicker--calendar-left" value={from} onChange={setFrom} timezone={range?.timezone} />
        <DashStyled>&#8211;</DashStyled>
        <DateTimePicker containerTestId="RangePicker--calendar-right" value={to} onChange={setTo} timezone={range?.timezone} />
      </Stack>
      <Stack direction="row" justifyContent="flex-end" alignItems="center">
        <Stack direction="row" justifyContent="flex-end" spacing={1} sx={{ m: 2 }}>
          <ButtonStyled size="small" variant="outlined" onClick={onClose}>
            {t('base.buttons.cancel')}
          </ButtonStyled>
          <ButtonStyled size="small" variant="contained" onClick={handleSetRange} data-testid="RangePicker--apply">
            {t('base.buttons.apply')}
          </ButtonStyled>
        </Stack>
      </Stack>
    </Box>
  );
}

export default observer(CustomRangePicker);

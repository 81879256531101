import { styled, Divider, Box } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import CirclePlusIcon from '@mui/icons-material/AddCircleOutline';
import CircleRemoveIcon from '@mui/icons-material/RemoveCircleOutline';

const CircleStyles = (theme) => ({
  fontSize: '1rem',
  width: 'auto',
  height: '12px',
  lineHeight: 1,
  color: theme.palette.primary.main,
  transform: 'scale(2)'
});
// eslint-disable-next-line import/prefer-default-export
export const CircleStyled = styled(CircleIcon)(({ theme }) => CircleStyles(theme));

export const CirclePlusStyled = styled(CirclePlusIcon)(({ theme }) => CircleStyles(theme));
export const CircleRemoveStyled = styled(CircleRemoveIcon)(({ theme }) => CircleStyles(theme));

export const CirclePlusSmallStyled = styled(CirclePlusIcon)(({ theme }) => CircleStyles(theme));
export const CircleRemoveSmallStyled = styled(CircleRemoveIcon)(({ theme }) => CircleStyles(theme));

export const DividerStyled = styled(Divider)(({ theme }) => ({
  color: theme.palette.primary.main,
  height: '40px',
  fontSize: '12px',
  '&::before, &::after': {
    borderLeftColor: theme.palette.primary.main,
    borderLeftStyle: 'dashed'
  },
  '.MuiDivider-wrapperVertical': {
    paddingTop: 'calc(4px * 1.2)',
    paddingBottom: 'calc(4px * 1.2)'
  }
}));

export const ButtonTextStyled = styled('span')(({ theme }) => ({ color: theme.palette.primary.main, paddingLeft: '5px', cursor: 'pointer' }));
export const ButtonTextSmallStyled = styled('span')(({ theme }) => ({
  fontSize: '0.85rem',
  color: theme.palette.primary.main,
  paddingLeft: '5px',
  cursor: 'pointer'
}));

export const ButtonContainerStyled = styled(Box)(() => ({ display: 'flex', alignItems: 'center' }));

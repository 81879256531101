/* eslint-disable react/jsx-props-no-spreading */
import { ReactNode } from 'react';
import { alpha, styled } from '@mui/material/styles';
import { LinkProps, ListItemText, ListItemButton, ListItemIcon, ListItemButtonProps, ListSubheader, Box } from '@mui/material';
import { transientOptions } from '~/utils/styled';

type IProps = LinkProps & ListItemButtonProps;

interface ListItemStyledProps extends IProps {
  component?: ReactNode;
  to?: string;
  $activeRoot?: boolean;
  $activeSub?: boolean;
  $subItem?: boolean;
}

export const ListItemStyled = styled(
  ListItemButton,
  transientOptions
)<ListItemStyledProps>(({ $activeRoot, $activeSub, $subItem, $isCollapse, theme }) => ({
  ...theme.typography.body2,
  position: 'relative',
  minHeight: 48,
  textTransform: 'capitalize',
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(1.5),
  marginBottom: theme.spacing(0.5),
  color: theme.palette.text.secondary,
  borderRadius: theme.shape.borderRadius,
  ...($activeRoot && {
    ...theme.typography.subtitle2,
    color: theme.palette.primary.dark,
    backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
  }),
  ...($activeSub && {
    ...theme.typography.subtitle2,
    color: theme.palette.text.primary
  }),
  ...($subItem && {
    minHeight: 48
  }),
  ...($isCollapse && {
    paddingLeft: '8px',
    paddingRight: '8px',
    fontSize: '0.7rem',
    display: 'flex',
    textAlign: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  }),
  sup: {
    fontWeight: 600,
    padding: theme.spacing(0.2, 0.6),
    color: theme.palette.primary.dark,
    backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    marginLeft: theme.spacing(0.5),
    borderRadius: '5px'
  }
}));

interface ListItemTextStyledProps extends ListItemButtonProps {
  $isCollapse?: boolean;
}

export const ListItemTextStyled = styled(
  ListItemText,
  transientOptions
)<ListItemTextStyledProps>(({ $isCollapse }) => ({
  whiteSpace: 'nowrap',
  transition: 0,
  ...($isCollapse && {
    fontSize: '0.65rem'
  })
}));

export const ListItemIconStyled = styled(
  ListItemIcon,
  transientOptions
)(({ $isCollapse }) => ({
  width: 22,
  height: 22,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  ...($isCollapse && {
    marginRight: 0
  })
}));

export const ListItemDotStyled = styled(
  (props) => <Box component="span" {...props} />,
  transientOptions
)(({ theme, $active }) => ({
  width: 4,
  height: 4,
  borderRadius: '50%',
  backgroundColor: theme.palette.text.disabled,
  transition: theme.transitions.create('transform', {
    duration: `${theme.animations.duration}s`
  }),
  ...($active && {
    transform: 'scale(2)',
    backgroundColor: theme.palette.text.primary
  })
}));

export const ListSubheaderStyled = styled(
  (props) => <ListSubheader disableSticky disableGutters {...props} />,
  transientOptions
)(({ theme, $isCollapse }) => ({
  ...theme.typography.overline,
  paddingTop: theme.spacing(3),
  paddingLeft: theme.spacing(2),
  paddingBottom: theme.spacing(1),
  color: theme.palette.text.primary,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: 'block',
  transition: 0,
  ...($isCollapse && {
    opacity: 0,
    height: '0',
    padding: '0.6rem 0'
  })
}));

export const ChildrenIconStyled = styled(
  Box,
  transientOptions
)(({ $isCollapse }) => ({
  ...($isCollapse && {
    position: 'absolute',
    right: '0',
    top: '0.75rem'
  })
}));

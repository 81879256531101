/* eslint-disable react/jsx-props-no-spreading */
import { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import orderBy from 'lodash/orderBy';
import first from 'lodash/first';
import Autocomplete from '~/components/final_form/select/autocomplete';
import type { AutocompleteProps, Value } from '~/components/final_form/select/types';
import { AutocompleteStyled } from './styled';
import useWidth from './use_width';

type NavigateDropdownProps = {
  width?: {
    extra?: number;
    multiplier?: number;
  };
} & AutocompleteProps<Value>;

function NavigateDropdown(props: NavigateDropdownProps) {
  const { value, options, width } = props;
  const defaultLabel = value?.label ? value?.label : options.find((option) => option.value === value)?.label;
  const [label, setLabel] = useState(defaultLabel);

  useEffect(() => setLabel(defaultLabel), [defaultLabel]);
  const labelWidth = useWidth(label, width);

  return (
    <Box sx={{ width: labelWidth, minWidth: 200 }}>
      <AutocompleteStyled>
        <Autocomplete
          {...props}
          componentsProps={{
            popper: {
              style: { width: labelWidth }
            }
          }}
          disableClearable
          disablePortal
          onOpen={() => {
            const longestLabel = first(orderBy(options, (option) => option.label.length, ['desc']))?.label;
            setLabel(longestLabel);
          }}
          onClose={() => setLabel(defaultLabel)}
        />
      </AutocompleteStyled>
    </Box>
  );
}

export default NavigateDropdown;

/* eslint-disable no-param-reassign */
import { flow, applySnapshot } from 'mobx-state-tree';
import api from '~/api';
import type { IAlert } from '~/mst/models/alert';
import { toMapById } from '~/mst/utils';
import type { IAlertsModel } from './model';

export default (self: IAlertsModel) => ({
  fetch: flow(function* fetch(params = {}, options = {}) {
    try {
      self.startFetching();
      const { data } = yield self.sync(api.search.nodes, { ...params, last: 1, type: 'alert' }, options);
      self.detach();
      applySnapshot(self.models, toMapById(data));
      self.finishFetching();
    } catch (error) {
      self.failFetching(error);
    }
  }),
  create: flow(function* create(values, model) {
    try {
      model.startSyncing();
      const { data } = yield api.alerts.create(values);
      self.models.put(data);
      model.finishSyncing();
    } catch (error) {
      model.failSyncing(error);
    }
  }),
  activate: flow(function* activate(models: IAlert[]) {
    // TODO: API - bulk activate/deactivate
    yield* models.map((alert) => alert.activate());
  }),
  deactivate: flow(function* deactivate(models: IAlert[]) {
    yield* models.map((alert) => alert.deactivate());
  })
});

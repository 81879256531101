import React, { useContext } from 'react';

const stub = (): never => {
  throw new Error('You forgot to wrap your component in <ModalProvider>.');
};

export type ModalContextState = {
  handleClose: VoidFunction;
};

const initialState: ModalContextState = {
  handleClose: stub
};

export const ModalContext = React.createContext(initialState);

export default () => useContext(ModalContext);

/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { makeAutoObservable } from 'mobx';

class Timer {
  interval = 1000;

  secondsPassed = 0;

  constructor(intervalInSecs = 15) {
    if (intervalInSecs) {
      this.interval = intervalInSecs * 1000;
    }
    makeAutoObservable(this);
  }

  increaseTimer() {
    this.secondsPassed += this.interval / 1000;
  }
}

const withAutoupdate = (WrappedComponent: React.FunctionComponent) =>
  observer((props) => {
    const { interval } = props;

    const [timer] = useState(() => new Timer(interval));

    useEffect(() => {
      const handle = setInterval(() => timer.increaseTimer(), timer.interval);
      return () => clearInterval(handle);
    }, [timer]);

    return <WrappedComponent {...props} timer={timer.secondsPassed} />;
  });

export default withAutoupdate;

import { Instance } from 'mobx-state-tree';
import model from './model';
import views from './views';
import viewsFilters from './views/filters';

const Partnership = model.views(views).views(viewsFilters);

export interface IPartnership extends Instance<typeof Partnership> {}

export default Partnership;

import { Grid, Box } from '@mui/material';

import { IReport } from '~/mst/models/report';
import DeviceSensors from '~/pages/device_monitor/device_sensors';
import DeviceDetails from '~/pages/device_monitor/device_details';

import Tabs from './tabs';

function DeviceMonitor({ report }: { report: IReport }) {
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Tabs report={report} />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <DeviceDetails node={report?.nodeWidget?.node} />
        </Grid>
      </Grid>
      <DeviceSensors widget={report?.nodeWidget} />
    </Box>
  );
}

export default DeviceMonitor;

import React from 'react';
import { PickersDay } from '@mui/x-date-pickers';
import { Box, styled, Button, Typography, Theme, MenuItem, Select } from '@mui/material';
import { transientOptions } from '~/utils/styled';
import { Popper } from '@mui/base';
import { Stack } from '@mui/system';

export const CalendarContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  '.MuiPickersLayout-root': {
    minWidth: 0
  },
  '.MuiFormControl-root.MuiTextField-root': {
    margin: '0 15px'
  },
  [theme.breakpoints.up('md')]: {
    '.MuiPickersArrowSwitcher-root .MuiPickersArrowSwitcher-button:nth-child(3)': {
      display: 'none'
    }
  }
}));

export const ButtonStyled = styled(Button)(({ theme }) => ({
  margin: '20px 30px 20px 0px',
  [theme.breakpoints.down('md')]: {
    margin: '10px 15px 10px 15px'
  }
}));

export const ValueStyled = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  margin: '0 30px',
  [theme.breakpoints.down('md')]: {
    display: 'none'
  }
}));

export const MenuItemsStyled = styled(MenuItem)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    fontSize: '11px',
    paddingTop: 0,
    paddingBottom: 0,
    minHeight: '38px'
  }
}));

export const NoInput = React.memo(() => {
  return null;
});

type CustomPickersDayProps = {
  theme: Theme;
  $dayIsBetween?: boolean;
  $isFirstDay?: boolean;
  $isLastDay?: boolean;
};

export const CustomPickersDayStyled = styled(
  PickersDay,
  transientOptions
)(({ theme, $dayIsBetween, $isFirstDay, $isLastDay }: CustomPickersDayProps) => ({
  ...($dayIsBetween && {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.dark
    }
  }),
  ...($isFirstDay && {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.dark
    },
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%'
  }),
  ...($isLastDay && {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.dark
    },
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%'
  })
}));

export const SelectStyled = styled(
  Select,
  transientOptions
)(({ theme, size, $adaptive }) => ({
  '& .MuiSelect-select': {
    padding: theme.spacing(1, 2)
  },
  ...(size &&
    size === 'small' && {
      fontSize: '13px',
      minWidth: 'auto !important',
      '& .MuiSelect-select': {
        padding: theme.spacing(0.6, 1.4)
      }
    }),
  ...($adaptive && {
    [theme.breakpoints.down('md')]: {
      fontSize: '11px',
      maxWidth: '165px'
    },
    '.MuiMenuItem-root': {
      fontSize: '11px'
    }
  })
}));

export const NoOptionLabelStyled = styled(Typography)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.text.disabled
}));

export const PopperStyled = styled(Popper)(() => ({
  zIndex: 2001
}));

export const DatePickerRootStyled = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  marginTop: '15px',
  gap: '15px',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column'
  }
}));

export const DesktopOnlyStyled = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none'
  }
}));

export const DashStyled = styled(Box)(({ theme }) => ({
  fontSize: '24px',
  lineHeight: 0.5,
  [theme.breakpoints.down('md')]: {
    display: 'none'
  }
}));

import { Box, Link } from '@mui/material';
import { ItemTypographyStyled, TitleStyled } from './styled';

function ItemTitle({ children }: { children: React.ReactElement | string }) {
  return <TitleStyled component="div">{children}</TitleStyled>;
}

function ItemValue({ children }: { children: React.ReactElement | string }) {
  return <ItemTypographyStyled component="div">{children}</ItemTypographyStyled>;
}

function Item({ name, email }: { name: string; email: string }) {
  if (!email) {
    return null;
  }

  return (
    <Box>
      <ItemTitle>{name}</ItemTitle>
      <Link target="_blank" href={`emailto: ${email}`} sx={{ textDecoration: 'none' }}>
        <ItemValue>{email}</ItemValue>
      </Link>
    </Box>
  );
}

export default Item;

/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/naming-convention */
import { flow, getIdentifier, applySnapshot } from 'mobx-state-tree';
import api from '~/api';
import pick from 'lodash/pick';
import get from 'lodash/get';
import type { INodeModel } from './model';

export default (self: INodeModel) => ({
  fetch: flow(function* fetch(params = {}) {
    try {
      self.startFetching();
      const { data } = yield self.sync(api.search.nodes, {
        node_id: [getIdentifier(self)!],
        ...params
      });
      applySnapshot(self, get(data, '[0]', {}));
      self.finishFetching();
    } catch (error) {
      self.failFetching(error);
    }
  }),
  reset: flow(function* reset() {
    self.startSyncing();
    try {
      yield api.nodes.destroy(getIdentifier(self)!);
      self.finishSyncing();
    } catch (error) {
      self.failSyncing(error);
    }
  }),
  activate: flow(function* activate() {
    self.startSyncing();
    try {
      yield api.nodes.activate(getIdentifier(self));
      self.finishSyncing();
    } catch (error) {
      self.failSyncing(error);
    }
  }),
  deactivate: flow(function* deactivate() {
    self.startSyncing();
    try {
      yield api.nodes.deactivate(getIdentifier(self));
      self.finishSyncing();
    } catch (error) {
      self.failSyncing(error);
    }
  }),
  share: flow(function* share(organizationId) {
    self.startSyncing();
    try {
      yield api.nodes.share(getIdentifier(self), organizationId);
      self.finishSyncing();
    } catch (error) {
      self.failSyncing(error);
    }
  }),
  removeShare: flow(function* removeShare(organizationId) {
    self.startSyncing();
    try {
      yield api.nodes.removeShare(getIdentifier(self), organizationId);
      self.finishSyncing();
    } catch (error) {
      self.failSyncing(error);
    }
  }),
  lease: flow(function* lease(organizationId) {
    self.startSyncing();
    try {
      yield api.nodes.lease(getIdentifier(self), organizationId);
      self.finishSyncing();
    } catch (error) {
      self.failSyncing(error);
    }
  }),
  removeLease: flow(function* removeLease() {
    self.startSyncing();
    try {
      yield api.nodes.removeLease(getIdentifier(self));
      self.finishSyncing();
    } catch (error) {
      self.failSyncing(error);
    }
  }),
  transfer: flow(function* transfer(organizationId, billingId) {
    self.startSyncing();
    try {
      yield api.nodes.transfer(getIdentifier(self), organizationId, billingId);
      self.finishSyncing();
    } catch (error) {
      self.failSyncing(error);
    }
  }),
  blink: flow(function* blink() {
    self.startSyncing();
    try {
      yield api.nodes.runAction(getIdentifier(self), 'led', { value: 'FFFFFF1A' });
      self.finishSyncing();
    } catch (error) {
      self.failSyncing(error);
    }
  }),
  update: flow(function* update(payload) {
    try {
      self.startSyncing();
      const { data } = yield api.nodes.update(getIdentifier(self), payload);
      Object.assign(self, pick(data, Object.keys(payload)));
      self.finishSyncing();
    } catch (error) {
      self.failSyncing(error);
    }
  }),
  changeEndpoint: flow(function* changeEndpoint(endpoint) {
    try {
      self.startSyncing();
      yield api.nodes.changeEndpoint(self.serial, { value: endpoint });
      self.endpoint = endpoint;
      self.finishSyncing();
    } catch (error) {
      self.failSyncing(error);
    }
  }),
  runAction: flow(function* runAction(action) {
    try {
      self.startSyncing();
      yield api.nodes.runAction(getIdentifier(self), action);
      self.finishSyncing();
    } catch (error) {
      self.failSyncing(error);
    }
  }),
  defaultWifi: flow(function* defaultWifi() {
    try {
      self.startSyncing();
      yield api.nodes.defaultWifi(getIdentifier(self));
      self.finishSyncing();
    } catch (error) {
      self.failSyncing(error);
    }
  }),
  create: flow(function* create(formData = {}) {
    try {
      self.startSyncing();
      const { name, description, interval } = formData;
      const payload = {
        name,
        description,
        interval
      };
      const { data } = yield api.nodes.create({ ...payload, _type: 'thiamis', serial: self.serial, devices: [] });
      self.finishSyncing();
      return data;
    } catch (error) {
      self.failSyncing(error);
    }
    return null;
  })
});

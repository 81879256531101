import groupBy from 'lodash/fp/groupBy';
import map from 'lodash/fp/map';
import filter from 'lodash/fp/filter';
import flatten from 'lodash/fp/flatten';
import flow from 'lodash/fp/flow';
import I18n from '~/utils/i18n';

import type { IDevicesOnlineChartWidgetStore } from '.';

export default (self: IDevicesOnlineChartWidgetStore) => ({
  get chartData() {
    return flow(
      map(([ts, data]) =>
        Object.entries(data).map(([name, count]) => ({
          name: I18n.t(`filters.filter-tags.device-type.${name.toLowerCase()}`, {
            defaultValue: name
          }),
          count,
          ts
        }))
      ),
      flatten,
      groupBy('name'),
      Object.entries,
      map(([name, data]) => ({ name, data: data.map(({ ts, count }) => [+ts, count]) })),
      filter(({ data }) => data.some(([, count]) => count > 0))
    )(Object.entries(self.data));
  },
  get maxCount() {
    const counts = flow(
      map(({ data }) => data.map(([, count]) => count)),
      flatten
    )(self.chartData);
    return Math.max(...counts);
  }
});

import { Instance } from 'mobx-state-tree';
import actions from './actions';
import model from './model';
import views from './views';

const ChartTableWidget = model.views(views).actions(actions);

export interface IChartTableWidget extends Instance<typeof ChartTableWidget> {}

export default ChartTableWidget;

/* eslint-disable */
import React from 'react';
import { IconProps } from './types';

export default function PartnershipIcon({ sx }: IconProps) {
  return (
    <svg
      width={sx ? sx.width : '100%'}
      height={sx ? sx.height : '100%'}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 70.4 51.7"
      fill="currentColor"
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M0,26.3L15.4,0l8.1,4.7l-1.3,2.2c1.9-0.6,4-0.9,6.3-1.2l0,0c1.6-0.2,3.5-0.4,4.6-0.1l0,0  c0.8,0.2,1.5,0.5,2.1,0.9c0.7-0.4,1.5-0.6,2.3-0.8C39.9,5.3,46,6.3,48.2,7l-1.3-2.3L55,0l15.4,26.3L62.3,31l-1.6-2.8  c-0.4,1.5-1.2,3-2.2,4c1.2,3-1,6.1-4,6.6c-0.2,2.5-1.7,4.1-4.3,4.5c-0.5,3-2.6,4.6-5.6,4.9c-0.6,1.3-1.7,2.3-3,2.9  c-2.8,1.2-4.8,0-7.1-1.3c-2.5,1.4-6.1,0.4-7-2.5c-2.7,1-5.9-0.6-6.4-3.5c-3.1,0.9-6.4-1.3-6-4.7c-2.3,0.1-4.3-1.4-4.8-3.6l0,0  c-0.4-1.4-0.2-2.9,0.8-4.3c-0.6-0.8-1.1-1.8-1.5-2.8L8.1,31L0,26.3L0,26.3z M19.4,11.7l-7.5,12.7c-0.1,1.2-0.2,2.9,1.3,4.9  c3.3-1.6,7.2,0.8,7.4,4.4c2.5-0.2,4.7,1.8,5,4.3c3.3-0.3,6.3,2.9,5.2,6.2l7.3,4.3c1.2,0.6,2.8,0.3,3.6-0.8l-3.9-2.6  c-1.4-1.2,0.5-3.4,1.9-2.2c0.9,0.7,3.1,2.3,4.2,2.5c1.2-0.1,2.1-0.3,2.7-0.9c0.4-0.4,0.7-0.9,0.8-1.6L45.5,42l0,0  c-1.2-0.7-4.1-2.2-2.9-3.8c1.2-1.5,3,0.6,4.3,1.4l0,0c0.7,0.4,1.4,0.7,2.1,0.9c1.6,0.2,2.5-0.7,2.6-2.1c-0.6-0.2-1.3-0.6-1.9-0.9  c-1.1-0.7-4.1-2.3-2.8-3.9c1.5-1.9,3.9,2,6.3,2.3c1.9,0.3,3.5-2,2.4-3.1c-3.2-2.7-6-5.3-8.6-7.8c-2.5-2.5-4.9-5-7.2-7.5  c-0.6,0-1.3,0.1-2,0.2c-1,0.4-8.3,5.5-10.1,6.7c-4,2.6-8.7-2.9-5.7-6.6l0,0c1.7-2.1,3.6-4.1,5.7-6l0,0c1.4-1.2,2.9-2.4,4.5-3.5  l-3.4,0.2l0,0C25,9.2,21.7,9.5,19.4,11.7L19.4,11.7z M50.9,11.5L58.2,24c0.6,1.5-0.5,4.8-1.5,6.1c-4.6-3.9-8.5-7.8-12.4-12  c1,0.3,2.6,0.9,3.1-0.6c0.2-0.8-0.2-1.6-1-1.8c-3.4-1-5.8-1.2-9.1-0.7c-1.2,0.2-8.9,5.7-11,7.1c-1.3,0.9-3-1.2-2-2.4l0,0l5.4-5.6  l0,0c1.8-1.6,5.9-4.9,8.5-5.4c1.3-0.3,3.1,0.1,4.5,0.3C45.8,9.3,49.2,9.7,50.9,11.5L50.9,11.5z M16.2,31.9c-1.8-0.7-3.6,1.3-3.1,3  l0,0c0.9,3.4,6.6,0.3,4-2.4C16.8,32.2,16.5,32,16.2,31.9L16.2,31.9z M19.2,37.4c-3.2,3.4,0.7,5.3,3,2.5  C23.9,37.8,21.2,35.5,19.2,37.4L19.2,37.4z M26.5,41c-2.3-0.6-3.8,3.4-1.1,3.9c1.9,0.3,3.9-2,2-3.5C27.1,41.2,26.8,41,26.5,41  L26.5,41z"
      />
    </svg>
  );
}

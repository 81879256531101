import { Instance, SnapshotOut } from 'mobx-state-tree';
import CollectionMapProcessor from '~/mst/models/abstract/collection_map_processor';
import model from './model';
import views from './views';
import actions from './actions';

const CalibrationsStore = model.views(views).actions(actions);

export interface ICalibrationsStore extends Instance<typeof CalibrationsStore> {}
export interface ICalibrationsStoreSnapshot extends SnapshotOut<typeof CalibrationsStore> {}

export default CollectionMapProcessor(CalibrationsStore);

import { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import { IDashboard } from '~/mst/models/dashboard';
import useDashboardSubscribe from '~/hooks/use_dashboard_subscribe';

import GridLayout from './grid_layout';

export type WidgetsProps = {
  dashboard: IDashboard;
  addWidget: () => void;
};

function Widgets({ dashboard, addWidget }: WidgetsProps) {
  useDashboardSubscribe(dashboard);

  const handleUpdateLayout = useCallback(
    (updatedLayout, breakpoint) => {
      dashboard.updateWidgets(updatedLayout, breakpoint);
    },
    [dashboard]
  );

  return <GridLayout widgets={dashboard?.widgets} updateLayout={handleUpdateLayout} dashboard={dashboard} addWidget={addWidget} />;
}

export default observer(Widgets);

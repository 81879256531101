import { SnackbarProvider as SnackbarProviderBase, SnackbarOrigin, closeSnackbar } from 'notistack';

import { IconButton } from '@mui/material';
import CloseIconStyled from './styled';

type CloseButtonProps = {
  id: string;
};

function CloseButton({ id }: CloseButtonProps) {
  return (
    <IconButton onClick={() => closeSnackbar(id)}>
      <CloseIconStyled fontSize="small" />
    </IconButton>
  );
}

function CloseSnackbarButton(key) {
  return <CloseButton id={key} />;
}

const snackbarPosition: SnackbarOrigin = {
  vertical: 'top',
  horizontal: 'center'
};

// eslint-disable-next-line react/prop-types
export default function SnackbarProvider({ children }) {
  return (
    <SnackbarProviderBase
      autoHideDuration={2000}
      maxSnack={3}
      hideIconVariant
      anchorOrigin={snackbarPosition}
      preventDuplicate
      action={CloseSnackbarButton}
    >
      {children}
    </SnackbarProviderBase>
  );
}

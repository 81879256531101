/* eslint-disable no-param-reassign */
import { types as t, Instance } from 'mobx-state-tree';
import Syncable from '~/mst/models/abstract/syncable';
import CalibrationMeasurement from './measurement';

const CalibrationModel = t
  .compose(
    t.model({
      node_id: t.identifier,
      serial: t.string,
      measurements: t.maybeNull(t.array(CalibrationMeasurement)),
      calibrated: t.boolean,
      ready: t.maybeNull(t.boolean),
      timestamp: t.maybeNull(t.number)
    }),
    Syncable
  )
  .named('Calibration');

export interface ICalibrationModel extends Instance<typeof CalibrationModel> {}

export default CalibrationModel;

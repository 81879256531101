/* eslint-disable no-prototype-builtins */
import { observer } from 'mobx-react-lite';
import { FormLabelStyled } from '~/pages/device_edit/components/styled';
import { SliderProps } from '@mui/material/Slider';

import Slider from '~/components/final_form/slider';
import useLocales from '~/hooks/use_locales';

const sliderDefaults = {
  marks: [{ value: 0.5 }, { value: 1.5 }],
  min: 0.5,
  max: 1.5,
  step: 0.01
};

function FlowCalibration({ slider = sliderDefaults }: { slider?: SliderProps }) {
  const { t } = useLocales();

  return (
    <Slider
      label={<FormLabelStyled>{t('sensor.configuration.flow_calibration')}</FormLabelStyled>}
      name="flow_calibration"
      data-testid="flowCalibrationSlider"
      showInput
      slider={slider}
    />
  );
}

export default observer(FlowCalibration);

import { Instance } from 'mobx-state-tree';
import WidgetProcessor from '~/mst/models/widget/processor';
import actions from './actions';
import model from './model';
import views from './views';

const TotalDevicesOnlineWidget = model.actions(actions).views(views);

export interface ITotalDevicesWidget extends Instance<typeof TotalDevicesOnlineWidget> {}

export default WidgetProcessor(TotalDevicesOnlineWidget);

/* eslint-disable no-param-reassign */
import { onAction } from 'mobx-state-tree';
import type { IDeviceAdmin } from '.';

export default (self: IDeviceAdmin) => ({
  fetch() {
    try {
      self.debugWidget.fetch(self?.range.fetchParams());
    } catch (error) {}
  },
  toggleLiveUpdates() {
    self.is_live_updates_on = !self.is_live_updates_on;
  },
  setSelectedTab(tab) {
    self.selected_tab = tab;
  },
  setRange(range) {
    self.range = range;
  },
  afterCreate() {
    onAction(
      self,
      async (action) => {
        const { name } = action;
        if (name === 'setRange') {
          self.debugWidget.fetch(self?.range.fetchParams());
        }
      },
      true
    );
  }
});

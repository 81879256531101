import { types as t, Instance } from 'mobx-state-tree';
import Webhook from '~/mst/models/webhook';
import Fetchable from '~/mst/models/abstract/fetchable';
import Filterable from '~/mst/models/abstract/filterable';
import CollectionMap from '~/mst/models/abstract/collection_map';

const WebhooksModel = t.compose(CollectionMap(Webhook), Fetchable({ cache: true }), Filterable);

export interface IWebhooksModel extends Instance<typeof WebhooksModel> {}

export default WebhooksModel;

import { styled } from '@mui/material/styles';
import { Box, MenuItem, Divider, Avatar } from '@mui/material';
import { IconButtonAnimate } from '~/components/animate';
import { transientOptions } from '~/utils/styled';
import MenuPopover from '~/components/menu_popover';

export const IconButtonAnimateStyled = styled(
  IconButtonAnimate,
  transientOptions
)(({ theme, $isAccessContextUsed, $open }) => ({
  width: 60,
  height: 60,
  padding: 0,
  backgroundColor: theme.palette.background.paper,
  ...($isAccessContextUsed && {
    boxShadow: `0 0 0 4px ${theme.palette.warning.light}`
  }),
  ...($open && {
    '&:before': {
      zIndex: 1,
      content: "''",
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      position: 'absolute'
    }
  })
}));

export const MenuPopoverStyled = styled(MenuPopover)(({ theme }) => ({
  '.MuiPaper-root': {
    width: '240px',
    padding: 0,
    marginTop: theme.spacing(1.5),
    marginLeft: theme.spacing(0.75)
  },
  '& .MuiMenuItem-root': {
    ...theme.typography.body2,
    borderRadius: 0.75
  }
}));

export const InfoWrapperStyled = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1.5),
  marginBottom: theme.spacing(1.5),
  paddingLeft: theme.spacing(2.5),
  paddingRight: theme.spacing(2.5)
}));

export const MenuItemStyled = styled(MenuItem)(({ theme }) => ({
  ...theme.typography.body2,
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(2.5),
  paddingRight: theme.spacing(2.5)
}));

export const IconWrapperStyled = styled(Box)(({ theme }) => ({
  marginRight: theme.spacing(2),
  width: 24,
  height: 24
}));

export const FooterWrapperStyled = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingTop: theme.spacing(1.5)
}));

export const DividerStyled = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1)
}));

export const AvatarStyled = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.background.avatar
}));

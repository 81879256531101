/* eslint-disable @typescript-eslint/naming-convention */
import pick from 'lodash/pick';
import flatten from 'lodash/flatten';
import { IInvitationModel } from './model';

export default (self: IInvitationModel) => ({
  get searchString() {
    const searchFields = ['email', 'role', 'organizationName'];
    return flatten(Object.values(pick(self, searchFields)))
      .join(' ')
      .toLowerCase();
  },
  get organizationName() {
    if (!self.organization) {
      return '';
    }
    return self.organization.name;
  },
  get userName() {
    if (!self.user) {
      return '';
    }
    return self.user.presentName;
  }
});

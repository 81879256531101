import { EnumAsUnion } from '~/utils/enum_as_union';

export enum DataPointTypes {
  Info = 'info',
  Status = 'status',
  Location = 'location',
  Debug = 'debug',
  Error = 'error',
  Raw = 'raw',
  Config = 'config',
  Metric = 'metric',
  Alert = 'alert'
}

export type DataPointTypesUnionType = EnumAsUnion<typeof DataPointTypes>;

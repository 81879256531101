import React from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import Input from '@mui/material/Input';
import DateTimeFormatter from '~/components/react_grid/datetime_formatter';

function ParametersEditor({ value, onValueChange }: { value?: string; onValueChange: (value: number) => void }) {
  const handleChange = (event) => {
    const { value: targetValue } = event.target;
    if (targetValue.trim() === '') {
      onValueChange();
      return;
    }
    onValueChange(parseFloat(targetValue));
  };
  return (
    <Input
      type="number"
      fullWidth
      value={value === undefined ? '' : value}
      inputProps={{
        min: 0,
        step: 'any',
        placeholder: 'Filter...'
      }}
      onChange={handleChange}
    />
  );
}

export function DateTimeProvider(props) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <DataTypeProvider {...props} formatterComponent={DateTimeFormatter} />;
}

export function ParametersProvider(props) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <DataTypeProvider {...props} editorComponent={ParametersEditor} />;
}

import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

type StyledProps = {
  columns?: number;
  rowGap?: number;
};

const FormGrid = styled(Box)<StyledProps>(({ columns = 1, theme, rowGap = 2 }) => ({
  display: 'grid',
  width: '100%',
  columnGap: theme.spacing(2),
  rowGap: theme.spacing(rowGap),
  gridTemplateColumns: `repeat(${columns}, 1fr)`,
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: `repeat(1, 1fr)`,
    gridColumn: '1 / span 3',
    '& > *': {
      gridColumn: '1 / span 3'
    }
  }
}));

export default FormGrid;

/* eslint-disable @typescript-eslint/no-use-before-define */
import { types as t, getType, hasParent, getParent } from 'mobx-state-tree';
import DataPointDefault from './default';

export function getParentByType(model, type, depth = 1) {
  if (getType(model)?.name === type) {
    return model;
  }
  if (hasParent(model, depth)) {
    const parent = getParent(model, depth);
    if (getType(parent)?.name === type) {
      return parent;
    }
    return getParentByType(model, type, depth + 1);
  }
  return null;
}

const DataPointNodeReference = t.safeReference(DataPointDefault, {
  get(identifier, parent) {
    return getParentByType(parent, 'DataPointsNode')?.getById(identifier);
  },
  set(values) {
    return values._id;
  }
});

export default DataPointNodeReference;

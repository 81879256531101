/* eslint-disable react/prop-types */
import React, { useCallback, SyntheticEvent, useState, useEffect } from 'react';
import { Box, Stack } from '@mui/material';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import { observable, action, autorun } from 'mobx';
import { observer } from 'mobx-react-lite';

import RefreshButton from '~/pages/device_monitor/monitor_chart/refresh_button';
import { TabPanel } from '~/pages/device_monitor/monitor_chart/components';
import {
  MonitorHeadStyled,
  TabsStyled,
  TabStyled,
  SettingsStyled,
  ChartContainerStyled,
  SidebarHeaderStyled,
  TopbarPanelStyled,
  TabContainerStyled
} from '~/pages/device_monitor/monitor_chart/styled';
import useFetch from '~/hooks/use_fetch';
import RangeSelect from './range_select';

import Chart from './chart';
import Table from './table';

const params = observable(
  {
    from: null,
    to: null,
    node_id: null,
    setParams({ from, to, node_id }) {
      this.from = from;
      this.to = to;
      this.node_id = node_id;
    }
  },
  {
    setParams: action
  }
);

function ChartTableBlock({ widget, monitor, alert }) {
  const [tab, setTab] = useState(0);

  const handleTabChange = useCallback((event: SyntheticEvent, newTab: number) => {
    setTab(newTab);
  }, []);

  useEffect(
    () =>
      autorun(() => {
        params.setParams({ ...monitor.range.fetchParams(), node_id: alert._id });
      }),
    [monitor.range, alert._id]
  );

  useFetch(widget?.data_points, params);

  return (
    <Box>
      <ChartContainerStyled sx={{ minHeight: '200px' }}>
        <MonitorHeadStyled>
          <TopbarPanelStyled $isBorderless>
            <TabsStyled value={tab} onChange={handleTabChange} variant="scrollable" allowScrollButtonsMobile>
              <TabStyled icon={<ShowChartIcon />} label={<span className="tab-label">Event Graph</span>} />
            </TabsStyled>
            <SidebarHeaderStyled direction="row" $justify="flex-end">
              <SettingsStyled>
                <RangeSelect monitor={monitor} allowCustom adaptive />
                <RefreshButton report={monitor} />
              </SettingsStyled>
            </SidebarHeaderStyled>
          </TopbarPanelStyled>
        </MonitorHeadStyled>
        <Stack direction="row">
          <TabContainerStyled>
            <TabPanel value={tab} index={0} sx={{ p: 0, pt: 3 }}>
              <Chart widget={widget} alert={alert} />
              <Table widget={widget} monitor={monitor} alert={alert} />
            </TabPanel>
          </TabContainerStyled>
        </Stack>
      </ChartContainerStyled>
    </Box>
  );
}

export default observer(ChartTableBlock);

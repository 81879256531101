/* eslint-disable @typescript-eslint/naming-convention */
import type { IWidget } from '~/mst/models/widget';
import type { IWidgetsModel } from './model';

export default (self: IWidgetsModel) => {
  return {
    get widgetLayouts() {
      const layout = {
        xl: [],
        lg: [],
        md: [],
        sm: [],
        xs: []
      };
      self.toArray.forEach((widget: IWidget) => {
        const widgetLayout = widget.breakpointLayouts;
        if (widgetLayout) {
          layout.xl.push(widgetLayout.xl);
          layout.lg.push(widgetLayout.lg);
          layout.md.push(widgetLayout.md);
          layout.sm.push(widgetLayout.sm);
          layout.xs.push(widgetLayout.xs);
        }
      });
      return layout;
    }
  };
};

/* eslint-disable no-nested-ternary */
import I18n from '~/utils/i18n';

import { IYsiSensor } from './model';

export default (self: IYsiSensor) => ({
  get measurementsValueLabelPairs() {
    const options = self.configuration.available_channels?.map(({ unit, name }) => {
      const label = `${name}, ${unit}`;
      return { value: label, label };
    });
    return [{ label: I18n.t('base.labels.disabled'), value: null }, ...options];
  }
});

import { types as t, Instance, SnapshotOut } from 'mobx-state-tree';

const OrganizationAddressModel = t.model({
  type: t.enumeration('OrganizationAddressType', ['billing', 'shipping']),
  city: t.maybeNull(t.string),
  country: t.maybeNull(t.string),
  state: t.maybeNull(t.string),
  street: t.maybeNull(t.string),
  zip_code: t.maybeNull(t.string)
});

export interface IOrganizationAddressModel extends Instance<typeof OrganizationAddressModel> {}
export interface IOrganizationAddressModelSnapshotOut extends SnapshotOut<typeof OrganizationAddressModel> {}

export default OrganizationAddressModel;

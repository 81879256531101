/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid';
import TimeAgoFormatter from '~/components/react_grid/time_ago_formatter';
import StatusFormatter from './status_formatter';
import ReadyFormatter from './ready_formatter';
import ParametersFormatter from './parameters_formatter';

export function LastRunProvider(props: DataTypeProviderProps) {
  return <DataTypeProvider {...props} formatterComponent={TimeAgoFormatter} />;
}

export function StatusTypeProvider(props: DataTypeProviderProps) {
  return <DataTypeProvider {...props} formatterComponent={StatusFormatter} />;
}
export function ReadyTypeProvider(props: DataTypeProviderProps) {
  return <DataTypeProvider {...props} formatterComponent={ReadyFormatter} />;
}
export function ParametersTypeProvider(props: DataTypeProviderProps) {
  return <DataTypeProvider {...props} formatterComponent={ParametersFormatter} />;
}

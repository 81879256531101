/* eslint-disable react/require-default-props */
import { Card } from '@mui/material';
import { useForm, useFormState } from 'react-final-form';
import { StatusType } from '~/components/status_icon/options';
import { ISyncable } from '~/mst/models/abstract/syncable';
import { Header, CardFooter } from '~/pages/device_edit/components';
import { HeaderDeviceWrapperStyled } from '~/pages/device_edit/components/styled';
import MCardHeader from '~/components/@material-extend/MCardHeader';

type FormContainerType = {
  title?: string;
  tag?: string;
  actions?: React.ReactElement;
  model?: ISyncable;
  handleSubmit?: VoidFunction;
  children: React.ReactElement | React.ReactElement[];
  statusType?: StatusType;
  statusText?: string;
};

function FormContainer({ title, tag, actions, handleSubmit, children, model, statusType, statusText, ...rest }: FormContainerType) {
  const { reset } = useForm();
  const { dirty } = useFormState({ subscription: { dirty: true } });

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Card {...rest}>
      {!!title && (
        <HeaderDeviceWrapperStyled>
          <MCardHeader title={<Header title={title} tag={tag} statusType={statusType} statusText={statusText} />} action={actions} />
        </HeaderDeviceWrapperStyled>
      )}
      {children}
      {handleSubmit && model && dirty && <CardFooter submitting={model.isSyncing} handleReset={() => reset()} handleSubmit={handleSubmit} />}
    </Card>
  );
}

export default FormContainer;

import { Instance } from 'mobx-state-tree';
import model from './model';
import views from './views';
import actions from './actions';

const CertificateStore = model.views(views).actions(actions);

export interface ICertificateModel extends Instance<typeof CertificateStore> {}

export default CertificateStore;

import { styled } from '@mui/material/styles';
import Select from '~/components/final_form/select';

const SelectStyled = styled(Select)(({ theme }) => ({
  '.MuiOutlinedInput-input': {
    textAlign: 'center',
    fontSize: '1.25rem',
    paddingTop: theme.spacing(1.75),
    paddingBottom: theme.spacing(1.75)
  }
}));

export default SelectStyled;

import { observer } from 'mobx-react-lite';

import type { IAlert } from '~/mst/models/alert';
import { FUNCTIONS } from '~/mst/models/condition/types';
import DevicesSelect from '~/components/devices_select';

type ConditionFieldType = {
  name: string;
  condition: string;
  model: IAlert;
};

function ParameterField(props: ConditionFieldType) {
  const { name, condition, model } = props;
  return (
    <DevicesSelect
      loading={model.isFetching}
      name={`${condition}.${name}`}
      data-testid={`${condition}.${name}Select`}
      options={model.nodesValueLabelPairsWithFunctions}
      groupBy={({ value }) => {
        if (FUNCTIONS.includes(value)) {
          return 'Functions';
        }
        return 'Devices';
      }}
      label="Device or Function"
    />
  );
}

export default observer(ParameterField);

import React, { useCallback } from 'react';
import { IconButton, MenuItem, Divider } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import useLocales from '~/hooks/use_locales';

import useActionsPopover from '~/hooks/react_grid/use_actions_popover';
import type { IWidget } from '~/mst/models/widget';
import type { IDashboard } from '~/mst/models/dashboard';

const ICON = {
  mr: 2,
  width: 20,
  height: 20
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function WidgetActions({ onEdit, widget, disabled, dashboard }: { onEdit: () => void; widget: IWidget; disabled: boolean; dashboard: IDashboard }) {
  const { t } = useLocales();
  const { ActionsMenu, handleClose, handleOpen, open } = useActionsPopover();

  const handleDelete = useCallback(
    (event) => {
      event.stopPropagation();
      dashboard.widgets.destroy(widget);
      handleClose();
    },
    [handleClose, widget, dashboard]
  );

  const handleModify = useCallback(
    (event) => {
      event.stopPropagation();
      onEdit();
      handleClose();
    },
    [onEdit, handleClose]
  );

  return (
    <>
      <IconButton disabled={disabled} onClick={handleOpen} sx={{ visibility: open ? 'visible' : 'auto' }}>
        <MoreVertIcon />
      </IconButton>
      <ActionsMenu>
        <MenuItem onClick={handleModify}>
          <EditIcon sx={ICON} />
          {t('base.buttons.edit')}
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleDelete} sx={{ color: 'error.main' }}>
          <DeleteIcon sx={ICON} />
          {t('base.buttons.delete')}
        </MenuItem>
      </ActionsMenu>
    </>
  );
}

WidgetActions.defaultProps = {};

export default WidgetActions;

/* eslint-disable no-prototype-builtins */
import { FormLabelStyled } from '~/pages/device_edit/components/styled';

import Slider from '~/components/final_form/slider';
import useLocales from '~/hooks/use_locales';

function FlowCalibration() {
  const { t } = useLocales();

  return (
    <Slider
      label={<FormLabelStyled>{t('sensor.configuration.photometric_calibration')}</FormLabelStyled>}
      name="photometric_calibration"
      data-testid="photometricCalibrationSlider"
      showInput
      slider={{
        min: 0.05,
        max: 10,
        step: 0.001
      }}
    />
  );
}

export default FlowCalibration;

import { Instance, SnapshotOut } from 'mobx-state-tree';
import model from './model';
import views from './views';
import viewsFilters from './views/filters';

const Calibration = model.views(views).views(viewsFilters);

export interface ICalibration extends Instance<typeof Calibration> {}
export interface ICalibrationSnapshotOut extends SnapshotOut<typeof Calibration> {}

export default Calibration;

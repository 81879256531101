import React, { useCallback } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { observer } from 'mobx-react-lite';
import { Form as FinalForm } from 'react-final-form';
import Yup, { searchableSelectSchema } from '~/utils/yup';
import { IDashboard } from '~/mst/models/dashboard';
import toasts from '~/utils/toasts';
import { useValidationSchema } from '~/hooks/use_validate';
import Modal from '~/components/modal';
import useMst from '~/hooks/use_mst';
import useLocales from '~/hooks/use_locales';
import { captureException } from '~/services/sentry';
import ShareForm from './form';

export type ShareModalProps = {
  dashboard: IDashboard;
  open: boolean;
  handleModalClose: () => void;
};

function ShareModal({ open, dashboard, handleModalClose }: ShareModalProps) {
  const { organizations } = useMst();
  const { t } = useLocales();

  const ShareDashboardSchema = Yup.object().shape({
    organization: searchableSelectSchema('Organization is required')
  });

  const validate = useValidationSchema(ShareDashboardSchema);

  const handleShare = useCallback(
    async (values) => {
      try {
        const organization = organizations.getById(values.organization);
        if (organization) {
          await dashboard.share(organization?.external_id);
        }
        toasts.success(t('notifications.success.report_shared'));
        datadogRum.addAction('share_report', { external_id: organization?.external_id });
        handleModalClose();
      } catch (e) {
        captureException(e);
        toasts.error(t('notifications.errors.server_error'));
      }
    },
    [organizations, dashboard, handleModalClose, t]
  );

  return (
    <Modal open={open} handleClose={handleModalClose} title="Share Dashboard">
      <FinalForm onSubmit={handleShare} validate={validate}>
        {({ handleSubmit, invalid }) => (
          <ShareForm handleModalClose={handleModalClose} handleSubmit={handleSubmit} invalid={invalid} dashboard={dashboard} />
        )}
      </FinalForm>
    </Modal>
  );
}

export default observer(ShareModal);

import { observer } from 'mobx-react-lite';
import { Box, useMediaQuery } from '@mui/material';
import { Theme } from '@mui/system';
import { useEffect, useState } from 'react';
import { autorun, observable } from 'mobx';
import pick from 'lodash/pick';
import type { IDeviceMonitor } from '~/pages/device_monitor/store';
import ChartStock from '~/components/chart';
import ChartSettingsButton from './settings_button';
import Params from './params';

function Chart({ report }: { report: IDeviceMonitor }) {
  const hiddenDown = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'));
  const [selectedParams, setSelectedParams] = useState([]);
  const [selectedColors] = useState(observable.map(new Map()));

  useEffect(
    () =>
      autorun(() => {
        const dataPoints = report?.params.selected_params_chart.map((id) =>
          pick(report?.chartWidget?.node?.data_points?.getById(id), ['presentName', 'min', 'max', 'avg', '_id'])
        );
        setSelectedParams(dataPoints);
      }),
    [report?.params.selected_params_chart, report?.chartWidget?.node?.data_points]
  );
  return (
    <Box style={{ position: 'relative' }}>
      <ChartSettingsButton report={report} />
      <ChartStock
        data={report?.chartWidget?.data}
        height={hiddenDown ? '300px' : '480px'}
        isLoading={report?.isFetching || report?.chartWidget?.isLoading}
        showZones={report?.chartWidget?.show_zones}
        showDataGaps={report?.chartWidget?.show_data_gaps}
        showLabels={!hiddenDown}
        options={{
          chart: {
            marginRight: 0,
            events: {
              redraw() {
                // eslint-disable-next-line react/no-this-in-sfc
                this.series.forEach((serie) => {
                  selectedColors.set(serie.userOptions.id, serie.color);
                });
              }
            }
          },
          time: {
            timezone: report?.nodeWidget?.node?.timezone
          },
          lang: {
            noData: report?.params.selected_params_chart?.length ? 'No data to display' : 'No data to display. Please select parameters.'
          },
          plotOptions: {
            series: {
              dataLabels: {
                enabled: false
              }
            }
          },
          legend: { enabled: false }
        }}
      />
      <Params dataPoints={selectedParams} selectedColors={selectedColors} />
    </Box>
  );
}

export default observer(Chart);

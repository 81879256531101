/* eslint-disable @typescript-eslint/naming-convention */
import { getIdentifier } from 'mobx-state-tree';
import pick from 'lodash/pick';
import { IPartnershipModel } from '../model';

export default (self: IPartnershipModel) => ({
  get organizationId() {
    return getIdentifier(self.organization);
  },
  get partnerId() {
    return getIdentifier(self.partner);
  },
  get partnerName() {
    return self.partner?.name;
  },
  get partnerExternalId() {
    return self.partner?.external_id;
  },
  get organizationName() {
    return self.organization?.name;
  },
  get organizationExternalId() {
    return self.organization?.external_id;
  },
  get searchString() {
    const searchFields = ['partnerName', 'partnerExternalId'];
    return Object.values(pick(self, searchFields)).join(' ').toLowerCase();
  }
});

import React, { useMemo } from 'react';
import { computed } from 'mobx';
import { observer } from 'mobx-react-lite';
import defaults from 'lodash/defaults';
import { useParams, useLocation } from 'react-router-dom';
import { Stack, CircularProgress, Box } from '@mui/material';

import useNavigate from '~/hooks/use_navigate';
import Breadcrumbs from '~/components/breadcrumbs';
import AlertsDropdown from '~/components/navigate_dropdown/alerts_dropdown';
import { ActionProvider } from '~/components/actions_dropdown/context';
import { SelectionProvider } from '~/hooks/react_grid/use_selection';
import useMst from '~/hooks/use_mst';
import useAuth from '~/hooks/use_auth';
import useLocales from '~/hooks/use_locales';
import useFetch from '~/hooks/use_fetch';
import Alert from '~/mst/models/alert';
import Container from '~/components/container';

import ActionsContainer from './actions_container';

import FormContainer from './form_container';

function EditAlertPage() {
  const { t } = useLocales();
  const { id } = useParams();
  const { alerts } = useMst();
  const { auth } = useAuth();
  const { state } = useLocation();
  const { navigate } = useNavigate();
  const { isFetched, isFetching } = useFetch(alerts);

  const alert = useMemo(
    () =>
      computed(() => {
        if (isFetched) {
          if (id) {
            return alerts.getById(id);
          }
          return Alert.create(defaults(state?.alert, { organization_id: auth.organizationId }));
        }
        return null;
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }),
    [id, alerts, isFetched, state, auth.organizationId]
  ).get();

  const links = useMemo(
    () => [
      { title: t('alerts.title'), to: 'alerts' },
      id
        ? [
            { title: t('alerts.config_page_breadcrumb'), to: `alerts/${id}`, hasAccess: alert?.isModifiable },
            { title: t('alerts.monitor_page_breadcrumb'), to: `alerts/${id}/monitor`, hasAccess: alert?.isMonitorable }
          ]
        : { title: t('alerts.new'), to: 'alerts/create' }
    ],
    [id, t, alert?.isModifiable, alert?.isMonitorable]
  );

  return (
    <Container>
      <Breadcrumbs links={links} />
      <Stack direction="column" spacing={{ xs: 0, sm: 2, md: 2 }}>
        {!!id && (
          <Stack direction="row" justifyContent="space-between">
            <AlertsDropdown
              options={alerts.valueLabelPairsSortedByLastTriggeredTs}
              loading={isFetching}
              onChange={(value) => navigate(`alerts/${value}`)}
              value={id}
            />
            <Box>
              <Box display="flex" justifyContent="flex-end">
                <SelectionProvider selected={[id]}>
                  <ActionProvider>
                    <ActionsContainer />
                  </ActionProvider>
                </SelectionProvider>
              </Box>
            </Box>
          </Stack>
        )}
        {isFetched && alert ? (
          <Stack>
            <FormContainer model={alert} alerts={alerts} />
          </Stack>
        ) : (
          <Stack alignItems="center">
            <CircularProgress size={25} color="info" />
          </Stack>
        )}
      </Stack>
    </Container>
  );
}

export default observer(EditAlertPage);

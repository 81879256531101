import { getParent, getIdentifier, hasParent } from 'mobx-state-tree';
import type { INode } from '~/mst/models/node';
import type { IUserCalibrationModel } from './model';

export default (self: IUserCalibrationModel) => ({
  get presentName() {
    if (hasParent(self, 6)) {
      const node = getParent<INode>(self, 6);
      return `${node.getDataPointByPath(getIdentifier(self))?.presentName} (${self.params})`;
    }
    return null;
  }
});

import { FieldArrayRenderProps } from 'react-final-form-arrays';
import { Stack, IconButton, Tooltip } from '@mui/material';
import type { IAlert } from '~/mst/models/alert';
import useLocales from '~/hooks/use_locales';
import DataPoint from './data_point';
import { CirclePlusStyled, ButtonTextStyled, ButtonContainerStyled } from './styled';
import type { ConditionType } from './data_point';

type ConditionsField = {
  conditions: ConditionType[];
};

type DataPointsFieldType = FieldArrayRenderProps<ConditionsField, HTMLInputElement> & {
  model: IAlert;
};

function DataPointsField(props: DataPointsFieldType) {
  const { fields, model } = props;
  const { t } = useLocales();
  const hasConditions = !!fields.value?.length;

  return (
    <>
      {fields.map((name, index) => (
        <DataPoint name={name} key={name} model={model} index={index} fields={fields} />
      ))}
      <Stack spacing={1} direction="row" justifyContent="space-between" alignItems="center" sx={{ width: '100%', ...(hasConditions && { pt: 3 }) }}>
        <ButtonContainerStyled
          data-testid="addNewOrConditionBtn"
          onClick={() => {
            fields.push({ conditions: [{ deviceId: '', dataPointId: '', func: '', expr: '' }] });
          }}
        >
          <Tooltip title={<span>{t(`conditions.${hasConditions ? 'conditions_or' : 'add_condition'}`)}</span>}>
            <IconButton>
              <CirclePlusStyled />
            </IconButton>
          </Tooltip>
          <ButtonTextStyled>{t(`conditions.${hasConditions ? 'conditions_or' : 'add_condition'}`)}</ButtonTextStyled>
        </ButtonContainerStyled>
      </Stack>
    </>
  );
}

export default DataPointsField;

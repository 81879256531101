/* eslint-disable @typescript-eslint/naming-convention */
import flow from 'lodash/fp/flow';
import filter from 'lodash/fp/filter';
import map from 'lodash/fp/map';
import sortBy from 'lodash/fp/sortBy';
import { IStatsModel } from './model';

export default (self: IStatsModel) => ({
  get groupByType() {
    return Object.entries(self.stats || {}).reduce((acc, [key, value]) => {
      const [type, device] = key.split('.');
      // eslint-disable-next-line no-param-reassign
      acc[device] = { ...(acc[device] || {}), [type]: value };
      return acc;
    }, {});
  },
  get devicesTotalByType() {
    return flow(
      map(([name, { online = 0, total, deactivated = 0 }]) => ({
        name,
        online,
        total,
        deactivated
      })),
      filter(({ name }) => /^[A-Z].*/.test(name)),
      sortBy(({ name }) => name)
    )(Object.entries(self.groupByType));
  }
});

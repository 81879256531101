/* eslint-disable @typescript-eslint/naming-convention */
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import compact from 'lodash/compact';
import { getOnlineStatusFromAQ } from '~/mst/models/device/utils';
import { INodeModel } from '../model';

export default (self: INodeModel) => ({
  get deviceTypeFilterTags() {
    let tags: string[] = [];

    if (self.isCreatable) {
      return ['inventory'];
    }
    if (self.thiamis && !isEmpty(self.thiamis.name)) {
      tags = [self.thiamis.name];
    }
    return tags.map((tag) => tag);
  },
  get deviceStatusFilterTags() {
    const tags: string[] = [getOnlineStatusFromAQ(self.statusType()), self.statusType()];

    if (self.shared_to?.length) {
      tags.push('shared');
    }
    if (self.leased_to) {
      tags.push('leased');
    }
    return uniq(compact(tags));
  },
  get sensorsFilterTags() {
    return uniq(compact(self.devices?.sensors?.map(({ name }) => name)));
  },
  get filterTags() {
    return [...self.deviceStatusFilterTags, ...self.deviceTypeFilterTags, ...self.sensorsFilterTags].map((filter) => filter.toLowerCase());
  },
  get searchString() {
    const { name, description, serial } = self;
    return [name, description, serial].join(' ').toLowerCase();
  }
});

import { observer } from 'mobx-react-lite';
import { CardContent, Typography } from '@mui/material';
import { useFormState } from 'react-final-form';

import useLocales from '~/hooks/use_locales';
import FormGrid from '~/components/@material-extend/form_grid';
import { ModalActions } from '~/components/modal';
import Select from '~/components/final_form/select';
import type { IYsiSensor } from '~/mst/models/device/sensor/gx_6000';
import type { IDataPointNodeBase } from '~/mst/models/data_point/node/base';

type FormProps = {
  handleSubmit: () => void;
  handleClose: () => void;
  sensor: IYsiSensor;
  dataPoint: IDataPointNodeBase;
};

function ConfigureMeasurementForm({ sensor, dataPoint, handleSubmit, handleClose }: FormProps) {
  const { t } = useLocales();
  const { invalid } = useFormState({ subscription: { invalid: true } });
  return (
    <>
      <CardContent>
        <FormGrid>
          <Select name="name" label={t('sensor.data_point.name')} options={sensor?.measurementsValueLabelPairs} />
          {dataPoint?.lastValue != null && <Typography>{`${t('sensor.data_point.last_measurement')}: ${dataPoint?.lastValue}`}</Typography>}
        </FormGrid>
      </CardContent>
      <ModalActions saveLabel={t('base.buttons.create')} onClose={handleClose} onSave={handleSubmit} loading={sensor.isSyncing} disabled={invalid} />
    </>
  );
}

export default observer(ConfigureMeasurementForm);

import { Instance } from 'mobx-state-tree';
import model from './model';
import views from './views';
import actions from './actions';

const DeviceAdmin = model.views(views).actions(actions);

export interface IDeviceAdmin extends Instance<typeof DeviceAdmin> {}

export default DeviceAdmin;

/* eslint-disable no-param-reassign */
import { flow, applySnapshot, getIdentifier } from 'mobx-state-tree';
import api from '~/api';
import { toMapById } from '~/mst/utils';
import type { IReportsModel } from './model';

type CreateReportPayload = {
  organization_name: string;
  name: string;
  description?: string;
  configuration: any;
};

export default (self: IReportsModel) => ({
  fetch: flow(function* fetch(params, options) {
    try {
      self.startFetching();
      const { data } = yield self.sync(api.reports.fetch, params, options);
      applySnapshot(self.models, toMapById(data, 'id'));
      self.finishFetching();
    } catch (error) {
      self.failFetching(error);
    }
  }),

  destroy: flow(function* destroy(report) {
    report.startSyncing();
    try {
      yield api.reports.destroy(getIdentifier(report)!);
      report.finishSyncing();
      self.remove(report);
    } catch (error) {
      report.failSyncing(error);
      throw new Error(error);
    }
  }),

  create: flow(function* create(payload: CreateReportPayload) {
    self.startSyncing();
    try {
      const { data } = yield api.reports.create(payload);
      self.models.put(data);
      self.finishSyncing();
      return data;
    } catch (error) {
      self.failSyncing(error);
    }
    return null;
  })
});

/* eslint-disable */
import React from 'react';
import { IconProps } from './types';

export default function ApiIcon({ sx }: IconProps) {
  return (
    <svg
      width={sx ? sx.width : '100%'}
      height={sx ? sx.height : '100%'}
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 70.4 52"
      viewBox="0 0 70.4 52"
      fill="currentColor"
    >
      <g xmlns="http://www.w3.org/2000/svg">
        <path d="M35.2,26.1c-3.1,0-5.7,2.6-5.7,5.7c0,3.1,2.6,5.7,5.7,5.7c3.1,0,5.7-2.6,5.7-5.7   C40.9,28.6,38.4,26.1,35.2,26.1z" />
        <path d="M59.6,24c0.9-1.4,1.4-3.1,1.4-4.9c0-5.2-4.3-9.5-9.5-9.5c-2.3,0-4.3,0.8-6,2.2C42.7,4.9,36,0,28,0   C17.6,0,9.2,8.5,9.2,18.9c0,0.6,0.1,1.1,0.2,1.7C3.8,23.3,0,28.9,0,35.4C0,44.6,7.4,52,16.6,52h39.6c7.8,0,14.2-6.4,14.2-14.2   C70.4,31.1,65.8,25.6,59.6,24z M47.1,41.4l-3.9-1.2c-1.3,1.3-2.9,2.2-4.7,2.7l-0.9,4h-4.8l-0.9-4c-1.8-0.5-3.3-1.4-4.6-2.7   l-3.9,1.3L21,37.3l3-2.8c-0.2-0.9-0.3-1.8-0.3-2.8c0-0.9,0.1-1.8,0.3-2.7l-3-2.7l2.4-4.2l3.9,1.2c1.3-1.3,2.9-2.2,4.7-2.7l0.9-4   h4.8l0.9,4c1.8,0.5,3.3,1.4,4.6,2.7L47,22l2.5,4.2l-3,2.8c0.2,0.9,0.3,1.8,0.3,2.8c0,0.9-0.1,1.8-0.3,2.7l3,2.7L47.1,41.4z" />
      </g>
    </svg>
  );
}

import { types as t, Instance, SnapshotOut } from 'mobx-state-tree';
import WidgetModel from '~/mst/models/widget';
import Filterable from '~/mst/models/abstract/filterable';
import NodesStore from '~/mst/models/nodes';

const DebugWidgetModel = t
  .compose(
    WidgetModel,
    Filterable,
    t.model({
      type: 'DebugWidget',
      node_id: t.string,
      nodes: t.maybeNull(NodesStore)
    })
  )
  .named('DebugWidget');

export interface IDebugWidgetModel extends Instance<typeof DebugWidgetModel> {}
export interface IDebugWidgetModelSnapshotOut extends SnapshotOut<typeof DebugWidgetModel> {}

export default DebugWidgetModel;

/* eslint-disable react/require-default-props */
import { useMemo } from 'react';
import { SxProps } from '@mui/material/styles';
import BatteryNA from '~/assets/icons/params/battery_na.svg?react';
import Battery1 from '~/assets/icons/params/battery_1.svg?react';
import Battery2 from '~/assets/icons/params/battery_2.svg?react';
import Battery3 from '~/assets/icons/params/battery_3.svg?react';
import Battery4 from '~/assets/icons/params/battery_4.svg?react';
import Battery5 from '~/assets/icons/params/battery_5.svg?react';
import DeviceParameter from '~/components/device_parameter';

type BatteryContainerType = {
  value?: number | string;
  title: string;
  noValueText: string;
  unit?: string;
  showLabel?: boolean;
  iconLeft?: boolean;
  sx?: SxProps;
};

const getBatteryLevel = (value?: number | string) => {
  if (!value) return 0;
  if (value >= 12) return 5;
  if (value >= 11.5 && value < 12) return 4;
  if (value >= 11 && value < 11.5) return 3;
  if (value >= 10.6 && value < 11) return 2;
  return '1';
};

function BatteryContainer({ value, title, noValueText, unit, showLabel = true, iconLeft = false, sx }: BatteryContainerType) {
  const BatteryLevel = useMemo(() => {
    const BatteryIcons = {
      0: BatteryNA,
      1: Battery1,
      2: Battery2,
      3: Battery3,
      4: Battery4,
      5: Battery5
    };

    const ToRender = BatteryIcons[getBatteryLevel(value)];
    return <ToRender height="13px" sx={{ width: 'auto', height: '13px', transform: 'rotate(90deg)' }} />;
  }, [value]);

  return (
    <DeviceParameter
      title={value ? `${value}${unit}` : ''}
      tooltipTitle={title}
      noValueText={noValueText}
      value={value}
      IconComponent={() => BatteryLevel}
      iconWidth="auto"
      iconHeight="13px"
      iconLeft={iconLeft}
      showLabel={showLabel}
      sx={sx}
    />
  );
}

export default BatteryContainer;

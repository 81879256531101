import { useEffect, useMemo } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { computed } from 'mobx';

import { Box, Stack, Drawer, Tooltip, CardActionArea, SxProps } from '@mui/material';
import { Icon } from '@iconify/react';
import arrowIosForwardOutline from '@iconify/icons-eva/arrow-ios-forward-outline';
import { observer } from 'mobx-react-lite';

import useCollapseDrawer from '~/hooks/use_collapse_drawer';

import Logo from '~/components/sidebar/logo';
import Scrollbar from '~/components/sidebar/scrollbar';
import NavSection from '~/components/sidebar/nav';
import { MHidden } from '~/components/@material-extend';

import { DRAWER_WIDTH, COLLAPSE_WIDTH } from '~/theme/values';
import useAuth from '~/hooks/use_auth';
import { PERMISSIONS } from '~/mst/models/permissions/types';

import getSidebarConfig from './config';
import { SidebarStyled } from './styled';

type IconCollapseProps = {
  onToggleCollapse: VoidFunction;
  isCollapse: boolean;
  title?: string;
  sx?: SxProps;
};

export function IconCollapse({ onToggleCollapse, isCollapse, title = 'Mini Menu', sx }: IconCollapseProps) {
  return (
    <Tooltip title={title}>
      <CardActionArea
        onClick={onToggleCollapse}
        sx={{
          position: 'absolute',
          displayPrint: 'none',
          left: '82%',
          top: '1.7rem',
          width: 35,
          height: 35,
          display: 'flex',
          cursor: 'pointer',
          borderRadius: '50%',
          alignItems: 'center',
          fontSize: 26,
          zIndex: 1299,
          border: '1px solid rgba(0, 0, 0, 0.05)',
          color: (theme) => theme.palette.secondary.muted,
          background: (theme) => theme.palette.background.paper,
          justifyContent: 'center',
          transformOrigin: 'center',
          transition: 0,
          ...(!isCollapse && {
            left: '94%',
            transform: 'rotateZ(180deg)'
          }),
          ...sx
        }}
      >
        <Icon icon={arrowIosForwardOutline} />
      </CardActionArea>
    </Tooltip>
  );
}

type SidebarProps = {
  isOpenSidebar: boolean;
  onCloseSidebar: VoidFunction;
};

function Sidebar({ isOpenSidebar, onCloseSidebar }: SidebarProps) {
  const { pathname } = useLocation();

  const { isCollapse, collapseClick, onToggleCollapse } = useCollapseDrawer();

  const { auth } = useAuth();

  const sidebarConfig = useMemo(
    () =>
      computed(() =>
        getSidebarConfig({
          canReadTokens: auth.user?.hasPermission(PERMISSIONS.ManageTokens),
          canReadDevices: auth.user?.hasPermission(PERMISSIONS.ReadDevices),
          canReadUsers: auth.user?.hasPermission(PERMISSIONS.ReadUsers),
          canReadReports: auth.user?.hasPermission(PERMISSIONS.ReadReports),
          canAdmin: auth?.user?.hasPermission(PERMISSIONS.AccessContext)
        })
      ),
    [auth.user]
  ).get();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: '100%',
        '& .simplebar-content': { height: '100%', display: 'flex', flexDirection: 'column' }
      }}
    >
      <Stack spacing={3} sx={{ px: 2.5, pt: 3, pb: 1 }} alignItems="center">
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Box component={RouterLink} to="/" sx={{ display: 'inline-flex' }}>
            <Logo isMini={isCollapse} />
          </Box>
        </Stack>
      </Stack>

      <NavSection sx={{ px: 2.5 }} navConfig={sidebarConfig} isCollapse={isCollapse} />
    </Scrollbar>
  );

  return (
    <SidebarStyled
      sx={{
        displayPrint: 'none',
        position: 'relative',
        width: {
          lg: isCollapse ? COLLAPSE_WIDTH : DRAWER_WIDTH
        },
        borderRight: 0,
        ...(collapseClick && {
          position: 'absolute'
        })
      }}
    >
      <MHidden width="lgDown">
        <IconCollapse onToggleCollapse={onToggleCollapse!} isCollapse={isCollapse} />
      </MHidden>
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          sx={{ overflow: 'visible' }}
          PaperProps={{
            sx: {
              bgcolor: (theme) => theme.palette.background.navbar,
              width: DRAWER_WIDTH,
              boxShadow: (theme) => theme.palette.themeShadows.navbar,
              borderRight: (theme) => theme.palette.borders.navbar
            }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          sx={{ overflow: 'visible' }}
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: (theme) => theme.palette.background.navbar,
              boxShadow: (theme) => theme.palette.themeShadows.navbar,
              ...(isCollapse && {
                width: COLLAPSE_WIDTH
              }),
              borderRight: (theme) => theme.palette.borders.navbar.borderRight,
              transition: 0
            }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </SidebarStyled>
  );
}

export default observer(Sidebar);

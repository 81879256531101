const DevicesOnlineWidgetLayout = {
  xl: {
    h: 3,
    w: 6,
    x: 0,
    y: 0
  },
  lg: {
    h: 3,
    w: 6,
    x: 0,
    y: 0
  },
  md: {
    h: 3,
    w: 4,
    x: 0,
    y: 0
  },
  sm: {
    h: 3,
    w: 4,
    x: 0,
    y: 0
  },
  xs: {
    h: 3,
    w: 4,
    x: 0,
    y: 0
  }
};

export default DevicesOnlineWidgetLayout;

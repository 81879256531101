import { Theme } from '@mui/material/styles';

export default function Table(theme: Theme) {
  return {
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: theme.palette.action.selected,
            '&:hover': {
              backgroundColor: theme.palette.action.hover
            }
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          color: theme.palette.text.secondary,
          backgroundColor: `${theme.palette.background.tableHead} !important`,
          '&:first-of-type': {
            paddingLeft: theme.spacing(3),
            borderTopLeftRadius: theme.shape.borderRadius,
            borderBottomLeftRadius: theme.shape.borderRadius
          },
          '&:last-of-type': {
            paddingRight: theme.spacing(3),
            borderTopRightRadius: theme.shape.borderRadius,
            borderBottomRightRadius: theme.shape.borderRadius
          },
          '&.TableFilterCell-cell': {
            backgroundColor: 'transparent !important'
          }
        },
        body: {
          '&:first-of-type': {
            paddingLeft: theme.spacing(3)
          },
          '&:last-of-type': {
            paddingRight: theme.spacing(3)
          }
        },
        root: {
          head: {
            backgroundColor: `${theme.palette.background.tableHead} !important`,
            '&.TableFilterCell-cell': {
              backgroundColor: 'transparent !important'
            }
          }
        }
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          borderTop: `solid 1px ${theme.palette.divider}`
        },
        toolbar: {
          height: 64
        },
        select: {
          '&:focus': {
            borderRadius: theme.shape.borderRadius
          }
        },
        selectIcon: {
          width: 20,
          height: 20,
          marginTop: 2
        }
      }
    }
  };
}

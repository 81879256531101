import { styled, Stack } from '@mui/material';
import { transientOptions } from '~/utils/styled';

const StatubBarContainerStyled = styled(
  Stack,
  transientOptions
)(({ theme }) => ({
  padding: '0 1rem 0 0.8rem',
  height: '40px',
  boxShadow: '0px -2px 9px rgba(0, 0, 0, 0.12)',
  borderTop: `1px solid ${theme.palette.grey[100]}`
}));

export default StatubBarContainerStyled;

import { types as t, Instance } from 'mobx-state-tree';
import { getStore } from '~/mst/utils';
import { ModelV2 } from '~/mst/models/abstract/model';
import Syncable from '~/mst/models/abstract/syncable';
import MaybeString from '~/mst/models/abstract/maybe_string';
import CreatedAtModel from '~/mst/models/abstract/created_at';
import { RolesEnum } from '~/utils/constants';

const InvitationModel = t
  .compose(
    t.model({
      email: t.maybeNull(MaybeString),
      role: t.maybeNull(t.enumeration('Roles', [RolesEnum.User, RolesEnum.Manager, RolesEnum.Owner])),
      user_id: t.maybeNull(MaybeString),
      organization_id: t.maybeNull(MaybeString)
    }),
    ModelV2,
    Syncable,
    CreatedAtModel
  )
  .views((self) => ({
    get organization() {
      return getStore(self).organizations.getById(self.organization_id);
    },
    get user() {
      return getStore(self).users.getById(self.user_id);
    }
  }));

export interface IInvitationModel extends Instance<typeof InvitationModel> {}

export default InvitationModel;

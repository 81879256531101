const TotalDevicesOnlineWidgetLayout = {
  xl: {
    h: 3,
    w: 6,
    x: 6,
    y: 0
  },
  lg: {
    h: 3,
    w: 6,
    x: 6,
    y: 0
  },
  md: {
    h: 3,
    w: 4,
    x: 4,
    y: 2
  },
  sm: {
    h: 3,
    w: 4,
    x: 0,
    y: 2
  },
  xs: {
    h: 3,
    w: 4,
    x: 0,
    y: 2
  }
};

export default TotalDevicesOnlineWidgetLayout;

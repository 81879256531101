import { types as t, Instance } from 'mobx-state-tree';
import Token from '~/mst/models/token';
import Syncable from '~/mst/models/abstract/syncable';
import Fetchable from '~/mst/models/abstract/fetchable';
import Filterable from '~/mst/models/abstract/filterable';
import CollectionMap from '~/mst/models/abstract/collection_map';

const TokensModel = t.compose(CollectionMap(Token), Syncable, Fetchable({ cache: true }), Filterable);

export interface ITokensModel extends Instance<typeof TokensModel> {}

export default TokensModel;

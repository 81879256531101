import AddIcon from '@mui/icons-material/Add';
import useModal from '~/hooks/use_modal';
import useLocales from '~/hooks/use_locales';

import Form from './create/form';
import { AddPlaceholder } from './styled';

function CreateDashboard() {
  const { t } = useLocales();
  const { open, handleOpen, handleClose } = useModal();
  return (
    <>
      <AddPlaceholder onClick={() => handleOpen()}>
        <AddIcon />
        {t('dashboards.new_dashboard')}
      </AddPlaceholder>
      <Form open={open} onClose={handleClose} />
    </>
  );
}

export default CreateDashboard;

/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import { Box, FormControl } from '@mui/material';
import { Field as FinalField } from 'react-final-form';

import Autocomplete from './autocomplete';
import Simple from './simple';
import type { AutocompleteProps } from './types';

export default function RFFSelect(props: AutocompleteProps) {
  const { name, searchable, sx = {}, validate, ...rest } = props;

  return (
    <FinalField name={name} validate={validate} allowNull>
      {({ input, meta }) => {
        const hasError = meta.touched && meta.error;
        return (
          <Box sx={{ ...sx, width: '100%' }}>
            <FormControl error={Boolean(hasError)} fullWidth>
              {searchable ? (
                <Autocomplete name={name} {...input} {...rest} error={Boolean(hasError)} />
              ) : (
                <Simple name={name} {...input} {...rest} error={Boolean(hasError)} />
              )}
            </FormControl>
          </Box>
        );
      }}
    </FinalField>
  );
}

/* eslint-disable */
import React from 'react';
import { IconProps } from '../types';

export default function ChartWidgetIcon({ sx }: IconProps) {
  return (
    <svg width={sx ? sx.width : '100%'} height={sx ? sx.height : '100%'} viewBox="0 0 42 32" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_42_5950)">
        <g clipPath="url(#clip1_42_5950)">
          <path
            d="M6.04008 28.84L16.0901 18.79L19.8601 22.56C20.8401 23.54 22.4201 23.54 23.4001 22.56L36.0601 9.89997V11.95C36.0601 12.64 36.6201 13.2 37.3101 13.2C38.0001 13.2 38.5601 12.64 38.5601 11.95V4.96997C38.5601 4.27997 38.0001 3.71997 37.3101 3.71997H30.3301C29.6401 3.71997 29.0801 4.27997 29.0801 4.96997C29.0801 5.65997 29.6401 6.21997 30.3301 6.21997H32.6601L21.6301 17.25L17.8601 13.48C16.8801 12.5 15.3001 12.5 14.3201 13.48L2.50008 25.3L2.08008 29.07L6.04008 28.83V28.84Z"
            fill="#C0D5E8"
          />
          <path
            d="M2.5 1.25C2.5 0.56 1.94 0 1.25 0C0.56 0 0 0.56 0 1.25V31.34H40.51C41.2 31.34 41.76 30.78 41.76 30.09C41.76 29.4 41.2 28.84 40.51 28.84H2.5V1.25Z"
            fill="#7998B5"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_42_5950">
          <rect width="41.76" height="31.34" fill="white" />
        </clipPath>
        <clipPath id="clip1_42_5950">
          <rect width="41.76" height="31.34" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

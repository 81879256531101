import { DEVICE_STATUSES } from '~/utils/constants';
import { STATUS_COLORS } from '~/theme/types/palette';
import MarkerOnlineIcon from '~/assets/images/marker_online.png';
import MarkerPoorIcon from '~/assets/images/marker_poor.png';
import MarkerModerateIcon from '~/assets/images/marker_moderate.png';
import MarkerDeactivatedIcon from '~/assets/images/marker_deactivated.png';
import MarkerOfflineIcon from '~/assets/images/marker_offline.png';
import ClusterIcon0 from '~/assets/images/mcluster_0.png';
import ClusterIcon1 from '~/assets/images/mcluster_1.png';
import ClusterIcon2 from '~/assets/images/mcluster_2.png';
import ClusterIcon3 from '~/assets/images/mcluster_3.png';
import ClusterIcon4 from '~/assets/images/mcluster_4.png';
import ClusterIcon5 from '~/assets/images/mcluster_5.png';
import mapStyles from '~/theme/map_styles';

export const CENTER_MAP = { lat: 44.401994, lng: -35.26227 };

export const getClusterIcon = (count) => {
  if (count < 20) {
    return ClusterIcon0;
  }
  if (count < 50) {
    return ClusterIcon1;
  }
  if (count < 100) {
    return ClusterIcon2;
  }
  if (count < 500) {
    return ClusterIcon3;
  }
  if (count < 1000) {
    return ClusterIcon4;
  }

  return ClusterIcon5;
};

export const getMarkerIcon = (status) => {
  if (status === 'deactivated') {
    return MarkerDeactivatedIcon;
  }
  if (status === 'online') {
    return MarkerOnlineIcon;
  }

  if ([DEVICE_STATUSES.EXCELLENT, DEVICE_STATUSES.GOOD, DEVICE_STATUSES.ONLINE, DEVICE_STATUSES.SATISFACTORY].includes(status)) {
    return MarkerOnlineIcon;
  }

  if ([DEVICE_STATUSES.MODERATE].includes(status)) {
    return MarkerModerateIcon;
  }

  if ([DEVICE_STATUSES.POOR, DEVICE_STATUSES.VERYPOOR].includes(status)) {
    return MarkerPoorIcon;
  }

  return MarkerOfflineIcon;
};

export const getPinConfig = (status) => {
  const config = {
    glyph: '',
    background: STATUS_COLORS[status]
  };

  if ([DEVICE_STATUSES.EXCELLENT, DEVICE_STATUSES.GOOD, DEVICE_STATUSES.ONLINE, DEVICE_STATUSES.SATISFACTORY].includes(status)) {
    return {
      ...config,
      borderColor: '#003E6D'
    };
  }

  if (DEVICE_STATUSES.MODERATE === status) {
    return {
      ...config,
      borderColor: '#E88400'
    };
  }

  if ([DEVICE_STATUSES.POOR, DEVICE_STATUSES.VERYPOOR].includes(status)) {
    return {
      ...config,
      borderColor: '#8E1313'
    };
  }

  if (DEVICE_STATUSES.DEACTIVATED === status) {
    return {
      ...config,
      borderColor: '#ABABAB'
    };
  }

  return {
    background: STATUS_COLORS[DEVICE_STATUSES.ONLINE],
    glyphColor: STATUS_COLORS[DEVICE_STATUSES.OFFLINE],
    borderColor: '#003E6D'
  };
};

export const getMapConfig = (params, theme) => ({
  center: CENTER_MAP,
  zoom: 3,
  mapTypeControl: true,
  mapTypeControlOptions: {
    position: google.maps.ControlPosition.TOP_RIGHT
  },
  zoomControl: true,
  streetViewControl: true,
  streetViewControlOptions: {
    position: google.maps.ControlPosition.LEFT_BOTTOM
  },
  zoomControlOptions: {
    position: google.maps.ControlPosition.LEFT_BOTTOM
  },
  fullscreenControl: true,
  fullscreenControlOptions: {
    position: google.maps.ControlPosition.LEFT_BOTTOM
  },
  styles: mapStyles(theme),
  ...params
});

export const getClusterConfig = (theme, map, markers) => ({
  map,
  markers,
  renderer: {
    // eslint-disable-next-line no-undef
    render: ({ count, position }) =>
      new google.maps.Marker({
        position,
        icon: {
          url: getClusterIcon(count)
        },
        label: {
          text: String(count),
          color: theme.palette.common.white,
          fontSize: `${theme.typography.mapClusterFontSize}px`
        },
        // eslint-disable-next-line no-undef
        zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count
      })
  }
});

export const getStreetViewConfig = (googleMaps) => ({
  fullscreenControl: false,
  addressControlOptions: {
    position: googleMaps.ControlPosition.BOTTOM_CENTER
  },
  zoomControlOptions: {
    position: googleMaps.ControlPosition.LEFT_BOTTOM
  },
  panControlOptions: {
    position: googleMaps.ControlPosition.LEFT_BOTTOM
  }
});

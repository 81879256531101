import { Instance } from 'mobx-state-tree';
import WidgetProcessor from '~/mst/models/widget/processor';
import actions from './actions';
import model from './model';
import views from './views';

const RecentAlertsWidget = model.views(views).actions(actions);

export interface IRecentAlertsWidget extends Instance<typeof RecentAlertsWidget> {}

export default WidgetProcessor(RecentAlertsWidget);

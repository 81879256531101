import React, { useCallback, useMemo } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { observer } from 'mobx-react-lite';

import toasts from '~/utils/toasts';
import Yup, { searchableSelectSchema, parseDate } from '~/utils/yup';
import useMst from '~/hooks/use_mst';
import useSelection from '~/hooks/react_grid/use_selection';
import { useValidationSchema } from '~/hooks/use_validate';
import useLocales from '~/hooks/use_locales';
import { getOptionsForPayload } from '~/components/final_form/select/utils';
import { captureException } from '~/services/sentry';
import RunCalibrationForm from './form';

type NewCalibrationModalProps = {
  toggleModal: VoidFunction;
};

const NewCalibrationSchema = Yup.object().shape({
  serials: Yup.array().required('Required').min(1, 'Serials are required'),
  reference: searchableSelectSchema('Reference device is required'),
  from: Yup.date().transform(parseDate).required('Required'),
  to: Yup.date().transform(parseDate).nullable()
});

function NewCalibrationModal({ toggleModal }: NewCalibrationModalProps) {
  const { selection: selectedCalibrations } = useSelection();
  const { t } = useLocales();

  const { calibrations } = useMst();
  const selectedSerials = useMemo(() => selectedCalibrations.map((id) => calibrations.getById(id)?.serial), [calibrations, selectedCalibrations]);

  const validate = useValidationSchema(NewCalibrationSchema);
  const initialValues = useMemo(() => ({ serials: selectedSerials }), [selectedSerials]);

  const handleSubmitCalibration = useCallback(
    async (values) => {
      try {
        const { serials, from, to, reference } = values;
        const payload = {
          serials: getOptionsForPayload(serials),
          from,
          to,
          reference
        };
        const { data } = await calibrations.create(payload);
        toasts.success(t('notifications.success.calibration_run', { calibrated: data?.calibrated.join(', '), missing: data?.missing.join(', ') }), {
          toastId: 'success_calibration_request',
          closeOnClick: true,
          autoClose: false
        });
        toggleModal();
      } catch (e) {
        captureException(e);
        toasts.error(t('notifications.errors.server_error'));
      }
    },
    [toggleModal, t, calibrations]
  );

  return (
    <FinalForm
      subscription={{ submitting: true, pristine: true, dirty: true }}
      initialValues={initialValues}
      onSubmit={handleSubmitCalibration}
      validate={validate}
      render={({ handleSubmit }) => <RunCalibrationForm handleSubmit={handleSubmit} toggleModal={toggleModal} />}
    />
  );
}

export default observer(NewCalibrationModal);

import React, { useCallback } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { CardContent } from '@mui/material';

import toasts from '~/utils/toasts';
import useMst from '~/hooks/use_mst';
import useLocales from '~/hooks/use_locales';
import useSelection from '~/hooks/react_grid/use_selection';

import FormGrid from '~/components/@material-extend/form_grid';
import { ModalActions } from '~/components/modal';
import { captureException } from '~/services/sentry';
import ModalTable from './modal_table';

type ModalProps = {
  toggleModal: VoidFunction;
};

function ResetModal({ toggleModal }: ModalProps) {
  const { t } = useLocales();
  const { selection, setSelection } = useSelection();
  const { nodes, calibrations } = useMst();

  const handleResetDevice = useCallback(async () => {
    const serials = selection.map((nodeId) => nodes.getById(nodeId).serial);
    try {
      await calibrations.reset(serials);
      setSelection([]);
      toggleModal();
      toasts.success(t('notifications.success.calibration_device_reset'));
    } catch (e) {
      captureException(e);
      toasts.error(t('notifications.errors.server_error'));
    }
  }, [t, selection, nodes, calibrations, toggleModal, setSelection]);

  return (
    <FinalForm
      initialValues={{
        selectedBilling: null,
        selectedOrganization: null,
        disabledNodes: null
      }}
      onSubmit={handleResetDevice}
      render={({ handleSubmit }) => (
        <>
          <CardContent>
            <FormGrid>
              <ModalTable showBilling />
            </FormGrid>
          </CardContent>
          <ModalActions onSave={handleSubmit} saveLabel={t('base.buttons.run')} onClose={toggleModal} />
        </>
      )}
    />
  );
}

export default ResetModal;

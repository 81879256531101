import { Typography, Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import I18n from '~/utils/i18n';
import Container from '~/components/container';
import SeverErrorIllustration from '~/assets/illustrations/SeverErrorIllustration';

export default function ErrorPage() {
  const navigate = useNavigate();

  return (
    <Container>
      <Box sx={{ margin: 'auto', textAlign: 'center' }}>
        <Typography variant="h5" paragraph>
          {I18n.t('notifications.errors.unknown')}
        </Typography>
        <Typography sx={{ color: 'text.secondary' }}>Sorry, unexpected error occured, please try again or contact Support</Typography>
        <SeverErrorIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />

        <Button onClick={() => navigate(0)} size="large" variant="contained">
          Refresh
        </Button>
      </Box>
    </Container>
  );
}

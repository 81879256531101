import { DataTypeProvider } from '@devexpress/dx-react-grid';

import { ListItemStyled, ExternalIdStyled } from '~/components/organizations_select/styled';

export function OrganizationName({ name, externalId }: { name: string; externalId: string }) {
  return (
    <ListItemStyled>
      {name}
      <ExternalIdStyled>{externalId}</ExternalIdStyled>
    </ListItemStyled>
  );
}

export default function NameFormatter({ row }: DataTypeProvider.ValueFormatterProps) {
  return <OrganizationName name={row.name} externalId={row.external_id} />;
}

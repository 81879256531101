/* eslint-disable react/require-default-props */
import { SxProps } from '@mui/material';
import Cell0 from '~/assets/icons/params/cell_0.svg?react';
import Cell1 from '~/assets/icons/params/cell_1.svg?react';
import Cell2 from '~/assets/icons/params/cell_2.svg?react';
import Cell3 from '~/assets/icons/params/cell_3.svg?react';
import Cell4 from '~/assets/icons/params/cell_4.svg?react';
import Cell5 from '~/assets/icons/params/cell_5.svg?react';
import DeviceParameter from '~/components/device_parameter';

type SignalLevelType = {
  value?: number | string;
  level?: number;
  title: string;
  noValueText: string;
  tooltipTitle?: string;
  showLabel?: boolean;
  iconLeft?: boolean;
  sx?: SxProps;
};

function SignalLevel({ value, level = 0, title, tooltipTitle, noValueText, showLabel = true, iconLeft = false, sx }: SignalLevelType) {
  const LevelIcons = {
    0: Cell0,
    1: Cell5,
    2: Cell4,
    3: Cell3,
    4: Cell2,
    5: Cell1
  };

  const SignalLevelComponent = LevelIcons[level || 0];

  return (
    <DeviceParameter
      title={title}
      tooltipTitle={tooltipTitle}
      noValueText={noValueText}
      value={value}
      IconComponent={SignalLevelComponent}
      iconWidth="16px"
      iconHeight="100%"
      align="flex-end"
      iconLeft={iconLeft}
      showLabel={showLabel}
      sx={sx}
    />
  );
}

export default SignalLevel;

/* eslint-disable */
import React from 'react';
import { IconProps } from './types';

export default function DashboardIcon({ sx }: IconProps) {
  return (
    <svg width={sx ? sx.width : '100%'} height={sx ? sx.height : '100%'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z" />
    </svg>
  );
}

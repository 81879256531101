import { observer } from 'mobx-react-lite';
import get from 'lodash/get';
import { Field as FinalField, useFormState } from 'react-final-form';
import { Box } from '@mui/material';

import type { IAlert } from '~/mst/models/alert';

type ConditionFieldType = {
  name: string;
  condition: string;
  model: IAlert;
};

function ParameterField(props: ConditionFieldType) {
  const { name, condition, model } = props;
  const { values } = useFormState({ subscription: { values: true } });

  const { deviceId } = get(values, condition);

  return (
    <FinalField name={`${condition}.${name}`}>
      {({ input }) => {
        return (
          <Box>
            <b>{model?.nodes?.getById(deviceId)?.defaultParamValueLabelPairs?.find(({ value }) => input.value === value)?.model?.name}</b>
          </Box>
        );
      }}
    </FinalField>
  );
}

export default observer(ParameterField);

import { useFormState } from 'react-final-form';
import { Box } from '@mui/material';
import TextInput from '~/components/final_form/text_input';
import FormGrid from '~/components/@material-extend/form_grid';
import useLocales from '~/hooks/use_locales';
import { ItemTitle } from '~/pages/device_edit/components';
import { SensorProperty } from '~/pages/device_edit/containers/sensor_form_container/sensors/components';

function StelAlerts() {
  const { t } = useLocales();
  const { values } = useFormState({ subscription: { values: true } });

  return (
    <Box>
      <ItemTitle>{t('sensor.stel_alert_conf')}</ItemTitle>
      <FormGrid columns={2} mt={2}>
        {Object.keys(values)
          .filter((field) => field.includes('stel_'))
          .map((field) => (
            <SensorProperty name={field} key={field}>
              <TextInput label={t(`sensor.configuration.${field}`)} name={field} type="number" data-testid={`${field}Input`} />
            </SensorProperty>
          ))}
      </FormGrid>
    </Box>
  );
}

export default StelAlerts;

/* eslint-disable jsx-a11y/control-has-associated-label */
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { observer } from 'mobx-react-lite';
import useNavigate from '~/hooks/use_navigate';
import useMst from '~/hooks/use_mst';
import useLoading from '~/hooks/react_grid/use_loading';
import LoadingBox from '~/components/loading_box';
import { EmptyAirthinxContainerStyled, EmptyDevicesContainerStyled, EmptyDevicesTextStyled, EmptyScreenContainerStyled } from './styled';

function NoDevicesScreen() {
  const { t } = useTranslation();
  const { navigate } = useNavigate();
  const handleClaimDevices = useCallback(() => navigate('devices/claim'), [navigate]);
  const { nodes } = useMst();
  const { messages } = useLoading(nodes);

  if (nodes.isFetching) {
    return (
      <tr>
        <td>
          <EmptyScreenContainerStyled>
            <LoadingBox />
          </EmptyScreenContainerStyled>
        </td>
      </tr>
    );
  }

  return (
    <tr>
      <td>
        {nodes.isFetched && !nodes.isEmpty ? (
          <EmptyDevicesContainerStyled>
            <EmptyDevicesTextStyled>{messages.noData || t('devices.not_found')}</EmptyDevicesTextStyled>
          </EmptyDevicesContainerStyled>
        ) : (
          <EmptyAirthinxContainerStyled>
            {t('claim.no_claimed_devices')}
            <Button variant="contained" startIcon={<AddIcon />} onClick={handleClaimDevices}>
              {t('claim.claim_devices')}
            </Button>
          </EmptyAirthinxContainerStyled>
        )}
      </td>
    </tr>
  );
}

export default observer(NoDevicesScreen);

import { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { CircularProgress, Stack, Typography } from '@mui/material';
import StarRateIcon from '@mui/icons-material/StarRate';
import uniqBy from 'lodash/uniqBy';

import useNavigate from '~/hooks/use_navigate';
import { IDashboard } from '~/mst/models/dashboard';
import { IconButtonAnimate } from '~/components/animate';
import WIDGET_TYPES from '~/pages/dashboard_view/add_widget/widget_types';
import {
  WidgetWrapperStyled,
  WidgetHeaderStyled,
  WidgetTitleStyled,
  WidgetLoadingContainerStyled,
  WidgetBodyStyled
} from '~/components/widgets/common/styled';
import useLocales from '~/hooks/use_locales';
import Actions from './actions';
import SharedLeasedStyled from '../devices/grid_container/shared_leased_formatter/styled';

function GridItem({ dashboard, id }: { dashboard: IDashboard; id: string }) {
  const { t } = useLocales();
  const { navigate } = useNavigate();
  const handleClickItem = useCallback(() => navigate(`dashboards/${id}`), [navigate, id]);

  const { isShared } = dashboard;

  return (
    <WidgetWrapperStyled $isSyncing={dashboard.isSyncing}>
      <WidgetHeaderStyled
        title={
          <Stack direction="column" spacing={1} justifyContent="center" alignItems="center">
            <Stack direction="row" spacing={1}>
              {dashboard.is_starred && <StarRateIcon fontSize="small" />}
              <WidgetTitleStyled>{dashboard.name}</WidgetTitleStyled>
            </Stack>
            <Typography sx={{ fontStyle: 'italic' }} variant="body3">
              {dashboard.description}
              {isShared && <SharedLeasedStyled>Shared</SharedLeasedStyled>}
            </Typography>
          </Stack>
        }
        action={<Actions dashboard={dashboard} />}
      />
      {dashboard.isSyncing ? (
        <WidgetLoadingContainerStyled>
          <CircularProgress size={30} color="info" />
        </WidgetLoadingContainerStyled>
      ) : (
        <WidgetBodyStyled sx={{ display: 'flex', justifyContent: 'center', height: 'calc(100% - 92px)' }}>
          <IconButtonAnimate
            onClick={handleClickItem}
            title={t('dashboards.open_dashboard')}
            sx={{
              backgroundColor: 'transparent !important',
              height: 233,
              width: 350,
              maxHeight: { xs: 233, md: 167 },
              maxWidth: { xs: 350, md: 250 }
            }}
          >
            <Stack direction="row" gap={2}>
              {uniqBy(dashboard.widgets.toArray, 'type').map((widget) => {
                const { Icon } = WIDGET_TYPES.find(({ type }) => type === widget.type) || {};
                return Icon ? <Icon sx={{ height: 30 }} /> : null;
              })}
            </Stack>
          </IconButtonAnimate>
        </WidgetBodyStyled>
      )}
    </WidgetWrapperStyled>
  );
}

export default observer(GridItem);

import React, { useMemo, useEffect } from 'react';
import { Stack, Box, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { persist } from 'mobx-state-tree-persist';
import { autorun } from 'mobx';

import { PATHS } from '~/utils/constants';
import useLocales from '~/hooks/use_locales';
import useMst from '~/hooks/use_mst';
import useFetch from '~/hooks/use_fetch';
import useReportSubscribe from '~/hooks/use_dashboard_subscribe';
import { SelectionProvider } from '~/hooks/react_grid/use_selection';
import { ExportProvider } from '~/hooks/react_grid/use_export';
import { ChartProvider } from '~/hooks/use_chart';
import useQuery from '~/hooks/use_query';
import useAuth from '~/hooks/use_auth';
import useNavigate from '~/hooks/use_navigate';
import Breadcrumbs from '~/components/breadcrumbs';
import DevicesDropdown from '~/components/navigate_dropdown/devices_dropdown';
import ActionsButton from '~/pages/devices/form_top/actions_button';
import Container from '~/components/container';
import { findTimezoneOption } from '~/utils/timezones';

import MonitorLayout from './layout';
import DeviceMonitorModel from './store';

function DeviceMonitor() {
  const { id } = useParams();
  const query = useQuery();
  const { t } = useLocales();
  const { nodes } = useMst();
  const { auth } = useAuth();
  const { navigate } = useNavigate();

  const { isFetching } = useFetch(nodes, { includes: ['profiles'] });

  useEffect(
    () =>
      autorun(() => {
        if (nodes.isFetched && nodes.isFetching === false) {
          nodes.fetchDataPoints({ last: 1, path: [PATHS.AQ, PATHS.ONLINE], organization_id: auth.organizationId });
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const node = nodes.getById(id);

  const monitor = useMemo(() => {
    const deviceMonitor = DeviceMonitorModel.create({
      widgets: [
        {
          id: 'chartWidget',
          type: 'ChartTableWidget',
          node_id: id
        },
        {
          id: 'nodeWidget',
          type: 'NodeWidget',
          node_id: id
        },
        {
          id: 'mapWidget',
          type: 'MapWidget',
          node_id: [id]
        }
      ]
    });

    if (query.has('parameter')) {
      deviceMonitor.params.setSelectedParamsChart([query.get('parameter')]);
      deviceMonitor.params.setSelectedParamsTable([query.get('parameter')]);
      localStorage.setItem(`device_monitor_${id}_params`, JSON.stringify(deviceMonitor.params));
    }

    persist([
      [deviceMonitor.range, { key: `device_monitor_${id}_range`, storage: localStorage, whitelist: ['from', 'to'] }],
      [deviceMonitor.params, { key: `device_monitor_${id}_params`, storage: localStorage }]
    ]);

    return deviceMonitor;
  }, [id, query]);

  useReportSubscribe(monitor);

  useFetch(monitor?.nodeWidget, { includes: ['configuration', 'profiles', 'calibrations'], last: 20 });

  const timezone = monitor?.nodeWidget?.node?.timezone;

  useEffect(() => {
    if (timezone) {
      monitor?.range?.setTimezone(timezone);
    }
  }, [timezone, monitor]);

  const isAdmin = auth.hasAdminDevice(node?.organization_id);

  const breadcrumbs = useMemo(
    () => [
      { title: t('devices_page.title'), to: 'devices' },
      [
        { title: t('thiamis.monitor_page_breadcrumb'), to: `devices/${id}/monitor`, hasAccess: node?.isMonitorable },
        { title: t('thiamis.config_page_breadcrumb'), to: `devices/${id}/edit`, hasAccess: node?.isModifiable },
        { title: t('thiamis.admin_page_breadcrumb'), to: `devices/${id}/admin`, hasAccess: isAdmin }
      ]
    ],
    [id, t, node?.isModifiable, isAdmin, node?.isMonitorable]
  );

  if (node?.isCreatable) {
    return navigate(`devices/${id}/create`);
  }

  return (
    <Container>
      <Breadcrumbs links={breadcrumbs} />
      <ChartProvider>
        <ExportProvider>
          <Stack direction="column" spacing={{ xs: 1, sm: 2, md: 2 }}>
            <Stack direction="row" justifyContent="space-between">
              <DevicesDropdown
                onChange={(value) => navigate(`devices/${value}/monitor`)}
                options={nodes.monitorableValueLabelPairsSortedByStatus}
                loading={isFetching}
                value={id}
                model={monitor?.nodeWidget?.node}
              />
              <Box display="flex" justifyContent="center" flexDirection="column" sx={{ displayPrint: 'flex', display: 'none', textAlign: 'right' }}>
                <Typography variant="h5" sx={{ fontWeight: 400 }}>
                  {monitor.range.toString}
                </Typography>
                <Typography variant="subtitle2">{findTimezoneOption(timezone)?.label}</Typography>
              </Box>
              {node?.isModifiable && (
                <Box display="flex" justifyContent="flex-end" alignItems="center" alignContent="center" sx={{ displayPrint: 'none' }}>
                  <SelectionProvider selected={[id]}>
                    <ActionsButton canManage />
                  </SelectionProvider>
                </Box>
              )}
            </Stack>
            <MonitorLayout monitor={monitor} />
          </Stack>
        </ExportProvider>
      </ChartProvider>
    </Container>
  );
}

export default observer(DeviceMonitor);

import { DEVICE_STATUSES, ALERT_STATUSES, CALIBRATION_STATUSES } from '~/utils/constants';

export type ColorSchema = 'default' | 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error';

export const STATUS_COLORS = {
  [DEVICE_STATUSES.EXCELLENT]: '#43aae6',
  [DEVICE_STATUSES.GOOD]: '#1789F2',
  [DEVICE_STATUSES.SATISFACTORY]: '#8fbf3b',
  [DEVICE_STATUSES.MODERATE]: '#FFAB20',
  [DEVICE_STATUSES.POOR]: '#ee3c3c',
  [DEVICE_STATUSES.VERYPOOR]: '#bf0000',
  [DEVICE_STATUSES.ERROR]: '#ee3c3c',
  [DEVICE_STATUSES.OFFLINE]: '#bbceca',
  [DEVICE_STATUSES.ONLINE]: '#1789F2',
  [DEVICE_STATUSES.ACTIVATED]: '#54D62C',
  [DEVICE_STATUSES.DEACTIVATED]: '#90A6AD',
  [DEVICE_STATUSES.HIBERNATED]: '#bbceca',
  [DEVICE_STATUSES.INVENTORY]: '#81BCB9',
  [ALERT_STATUSES.ACTIVE]: '#FF4842',
  [CALIBRATION_STATUSES.FAILED]: '#FF4842',
  [CALIBRATION_STATUSES.NOT_RUN]: '#90A6AD',
  [CALIBRATION_STATUSES.COMPLETED]: '#54D62C',
  [ALERT_STATUSES.RESOLVED]: '#54D62C',
  [ALERT_STATUSES.IDLE]: '#bbceca'
};

/* eslint-disable no-param-reassign */
import { IHomeModel } from './model';

export default (self: IHomeModel) => ({
  get recentAlertsWidget() {
    return self.widgets.toArray.find((widget) => widget.type === 'RecentAlertsWidget');
  },
  get devicesOnline24hChartWidget() {
    return self.widgets.toArray.find((widget) => widget.type === 'DevicesOnlineChartWidget');
  },
  get totalDevicesOnlinePieChartWidget() {
    return self.widgets.toArray.find((widget) => widget.type === 'TotalDevicesOnlinePieChartWidget');
  },
  get totalDevicesOnlineColumnChartWidget() {
    return self.widgets.toArray.find((widget) => widget.type === 'TotalDevicesOnlineColumnChartWidget');
  }
});

import { Instance } from 'mobx-state-tree';
import model from './model';
import actions from './actions';
import views from './views';

const DataPoint = model.views(views).actions(actions);

export interface IDataPoint extends Instance<typeof DataPoint> {}

export default DataPoint;

export type SizeType = 'large' | 'medium' | 'xsmall';
export type StatusType = 'online' | 'offline' | 'activated' | 'disabled' | 'hibernated' | 'resolved' | 'active' | 'idle' | 'deactivated' | 'error';

export const getScale = (size: SizeType) => {
  switch (size) {
    case 'large':
      return '1.2';
    case 'medium':
      return '1';
    case 'xsmall':
      return '0.8';
    default:
      return '0.6';
  }
};

/* eslint-disable no-param-reassign */
import { flow, applySnapshot } from 'mobx-state-tree';
import api from '~/api';
import { IReferenceDevicesModel } from './model';

export default (self: IReferenceDevicesModel) => ({
  fetch: flow(function* fetch() {
    try {
      self.startFetching();
      const { data } = yield self.sync(api.calibrations.references);
      applySnapshot(self.models, data);
      self.finishFetching();
    } catch (error) {
      self.failFetching(error);
    }
  })
});

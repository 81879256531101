/* eslint-disable no-prototype-builtins */
import Switch from '~/components/final_form/switch';
import FormGrid from '~/components/@material-extend/form_grid';
import has from 'lodash/has';
import { useFormState } from 'react-final-form';
import { BodyColumnStyled } from '~/pages/device_edit/components/styled';

import { Box } from '@mui/material';
import { ItemTitle } from '~/pages/device_edit/components';
import { SensorProperty } from '../../components';

type SensorType = {
  children?: React.ReactElement;
};

function SwitchFields({ children }: SensorType) {
  const { values } = useFormState({ subscription: { values: true } });

  if (!children && has(values, 'enabled') === false) {
    return null;
  }

  return (
    <BodyColumnStyled columns={1}>
      <FormGrid columns={2}>
        <SensorProperty name="enabled">
          <Box>
            <ItemTitle>Status</ItemTitle>
            <Switch label="Enabled" name="enabled" type="number" data-testid={`${values.name}EnabledSwitch`} />
          </Box>
        </SensorProperty>
        <SensorProperty name="debug">
          <Box>
            <ItemTitle>Debug</ItemTitle>
            <Switch label="Debug" name="debug" type="number" data-testid={`${values.name}DebugSwitch`} />
          </Box>
        </SensorProperty>
        {children}
      </FormGrid>
    </BodyColumnStyled>
  );
}

export default SwitchFields;

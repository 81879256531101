import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from '../locales/en';
import zh from '../locales/zh';
import es from '../locales/es';
import pt from '../locales/pt';

i18next.use(initReactI18next).init({
  resources: {
    en: { translation: en },
    zh: { translation: zh },
    es: { translation: es },
    pt: { translation: pt }
  },
  lng: 'en-US',
  interpolation: { prefix: '%{', suffix: '}', escapeValue: false },
  fallbackLng: 'en-US',
  nsSeparator: '::'
});

export default i18next;

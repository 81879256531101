import { Instance } from 'mobx-state-tree';
import actions from './actions';
import model from './model';
import views from './views';

const Widget = model.views(views).actions(actions);

export interface IWidget extends Instance<typeof Widget> {}

export default Widget;

import React from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import StatusIcon from '~/components/status_icon';
import useLocales from '~/hooks/use_locales';

export default function StatusFormatter(props: DataTypeProvider.ValueFormatterProps) {
  const {
    row: { statusType }
  } = props;
  const { t } = useLocales();
  return <StatusIcon statusType={statusType} statusText={t(`alerts.statuses.${statusType}`)} size="large" />;
}

import Yup from '~/utils/yup';
import { SensorSchema } from '../validation';

export default SensorSchema.shape({
  pm_microgram: Yup.number().oneOf([0, 1]),
  pm1_stel_alert: Yup.number().nullable().notRequired(),
  pm25_stel_alert: Yup.number().nullable().notRequired(),
  pm4_stel_alert: Yup.number().nullable().notRequired(),
  pm10_stel_alert: Yup.number().nullable().notRequired(),
  total_stel_alert: Yup.number().nullable().notRequired()
});

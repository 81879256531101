/* eslint-disable no-param-reassign */
import { flow, applySnapshot } from 'mobx-state-tree';
import api from '~/api';
import { toMapById } from '~/mst/utils';
import type { ITokensModel } from './model';

type CreateTokenPayload = {
  organization: string;
  capabilities: string[];
  node_id?: string;
};

export default (self: ITokensModel) => ({
  fetch: flow(function* fetch(params, options) {
    try {
      self.startFetching();
      const { data } = yield self.sync(api.tokens.fetch, params, options);
      applySnapshot(self.models, toMapById(data, 'id'));
      self.finishFetching();
    } catch (error) {
      self.failFetching(error);
    }
  }),
  create: flow(function* create(payload: CreateTokenPayload) {
    self.startSyncing();
    try {
      const body = {
        organization_id: payload.organization,
        capabilities: payload.capabilities,
        query: {
          node_id: payload.node_id,
          permissions: 'read',
          type: ['thiamis', 'alert']
        }
      };
      const { data } = yield api.tokens.create(body);
      self.models.put(data);
      self.finishSyncing();
    } catch (error) {
      self.failSyncing(error);
    }
  })
});

import React, { useMemo } from 'react';
import includes from 'lodash/includes';

import useLocales from '~/hooks/use_locales';

import { Box } from '@mui/material';
import ModalOrganizationDropdown from './modal_organization';
import ModalTable from './modal_table';

type ActionsModalFormProps = {
  type: string;
};

function ActionsModalForm({ type }: ActionsModalFormProps) {
  const { t } = useLocales();

  const isShareLeaseType = useMemo(() => includes(['share', 'remove_share', 'lease'], type), [type]);

  const helpText = useMemo(() => {
    switch (type) {
      case 'share':
        return t('thiamis.manage_dialog.share_help_text');
      case 'lease':
        return t('thiamis.manage_dialog.lease_help_text');
      case 'transfer':
        return t('thiamis.manage_dialog.transfer_help_text');
      case 'delete':
        return t('thiamis.manage_dialog.reset_help_text');
      default:
        return '';
    }
  }, [t, type]);

  return (
    <Box sx={{ p: 3 }}>
      <div className="form-region">{isShareLeaseType && <ModalOrganizationDropdown type={type} />}</div>
      <div className="form-group">
        <div className="row">
          <div className="col-sm-12">
            <div className="table-responsive manage-node-table table-region">
              <ModalTable type={type} />
            </div>
            <p className="help-block">{helpText}</p>
          </div>
        </div>
      </div>
    </Box>
  );
}

export default ActionsModalForm;

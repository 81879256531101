import { styled, Typography } from '@mui/material';

export const TitleStyled = styled(Typography)(() => ({
  fontWeight: '600',
  fontSize: '14px',
  color: '#637381'
}));

export const ItemTypographyStyled = styled(Typography)(() => ({
  fontSize: '14px',
  textOverflow: 'ellipsis',
  display: 'block',
  overflow: 'hidden'
}));

/* eslint-disable @typescript-eslint/no-use-before-define */
import { types as t, Instance } from 'mobx-state-tree';
import Sensor from '~/mst/models/device/sensor';

const GX6000Sensor = t
  .compose(
    t.model({
      optional_measurements: t.maybeNull(t.frozen())
    }),
    Sensor
  )
  .named('GX6000');

export interface IGX6000Sensor extends Instance<typeof GX6000Sensor> {}

export default GX6000Sensor;

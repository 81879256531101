interface AnimationOptions {
  duration: number;
}

declare module '@mui/material/styles' {
  interface Theme {
    animations: AnimationOptions;
  }
  interface ThemeOptions {
    animations?: AnimationOptions;
  }
}

const animations = {
  duration: '0.2'
};

export default animations;

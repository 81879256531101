/* eslint-disable @typescript-eslint/no-use-before-define */
import { types as t, Instance } from 'mobx-state-tree';
import Sensor from '~/mst/models/device/sensor';

const YsiSensor = t
  .compose(
    t.model({
      available_channels: t.maybeNull(t.frozen())
    }),
    Sensor
  )
  .named('Ysi');

export interface IYsiSensor extends Instance<typeof YsiSensor> {}

export default YsiSensor;

import React from 'react';
import type { INode } from '~/mst/models/node';
import Modal from '~/components/modal';
import useLocales from '~/hooks/use_locales';
import ModalContent from './content';

type DefaultWifiModalProps = {
  open: boolean;
  onClose: () => void;
  node: INode;
};

export function DefaultWifiModal({ node, open, onClose }: DefaultWifiModalProps) {
  const { t } = useLocales();

  return (
    <Modal open={open} handleClose={onClose} title={t('thiamis.default_wifi_dialog.title')}>
      <ModalContent node={node} />
    </Modal>
  );
}

export default DefaultWifiModal;

/* eslint-disable @typescript-eslint/no-use-before-define */
import { types as t, Instance, getParent } from 'mobx-state-tree';
import isString from 'lodash/isString';
import has from 'lodash/has';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import isObject from 'lodash/isObject';
import I18n from '~/utils/i18n';
import { getParentByType } from '~/mst/models/data_point/node/reference';
import humanizer from '~/utils/humanize_duration';
import { PATHS } from '~/utils/constants';
import { Functions, Expressions, Value, getFuncName, EXPRESSIONS, FuncType } from '../types';

export function getValueForTimePeriod(value: any, func: FuncType) {
  if (['avg', 'min', 'max'].includes(func)) {
    return humanizer.humanizeDuration(value * 60 * 1000, { units: ['d', 'h', 'm'] });
  }
  return value;
}

export function getValueWithUnit(value: any, unit?: any) {
  if (unit) {
    return `${value} ${unit}`;
  }
  return value;
}

export function getUnit(dataPoint) {
  if (dataPoint?.dataPoint) {
    return getUnit(dataPoint?.dataPoint);
  }
  if (dataPoint?.dataPointId) {
    return getUnit(dataPoint?.dataPointId);
  }
  return dataPoint?.unit;
}

export const SimpleCondition = t
  .model({
    dataPointId: t.string,
    expr: t.maybeNull(Expressions),
    func: t.maybeNull(Functions),
    value: Value
  })
  .views((self) => {
    function getDataPoint() {
      return getParentByType(getParent(self), 'Alert')?.nodes?.getNodeByDataPointId(self.dataPointId)?.data_points?.getById(self.dataPointId);
    }
    return {
      get dataPoint() {
        return getDataPoint();
      },
      get presentName() {
        const dataPoint = getDataPoint();
        const name = dataPoint ? `${dataPoint?.node?.presentName} - ${dataPoint?.shortPresentName}` : '...';
        if (self.func) {
          if (self.value != null) {
            return `${getFuncName(self.func)}(${name}, ${getValueForTimePeriod(self.value, self.func)})`;
          }
          return `${getFuncName(self.func)}(${name})`;
        }
        if (dataPoint?.shortPath === PATHS.ONLINE) {
          return `${name} ${getFuncName(self.expr)} ${getValueWithUnit(I18n.t(`data_points.status_${self.value ? 'true' : 'false'}`))}`;
        }
        return `${name} ${getFuncName(self.expr)} ${getValueWithUnit(self.value, getUnit(dataPoint))}`;
      }
    };
  });

export const NestedCondition = t
  .model({
    dataPointId: t.late(() => Condition),
    expr: t.maybeNull(Expressions),
    func: t.maybeNull(Functions),
    value: Value
  })
  .views((self) => ({
    get presentName() {
      if (self.func) {
        if (self.value != null) {
          return `${getFuncName(self.func)}(${self.dataPointId.presentName}, ${getValueForTimePeriod(self.value, self.func)})`;
        }
        return `${getFuncName(self.func)}(${self.dataPointId.presentName}`;
      }
      return `${self.dataPointId.presentName} ${getFuncName(self.expr)} ${getValueWithUnit(self.value, getUnit(self.dataPointId))}`;
    }
  }));

export const AbsCondition = t
  .model({
    dataPointId: t.string,
    func: Functions
  })
  .views((self) => {
    function getDataPoint() {
      return getParentByType(getParent(self), 'Alert')?.nodes?.getNodeByDataPointId(self.dataPointId)?.data_points?.getById(self.dataPointId);
    }
    return {
      get dataPoint() {
        return getDataPoint();
      },
      get presentName() {
        return `${getFuncName(self.func)}(${getDataPoint()?.shortPresentName})`;
      }
    };
  });

export const AbsNestedCondition = t
  .model({
    dataPointId: t.late(() => Condition),
    func: Functions
  })
  .views((self) => ({
    get presentName() {
      return `${getFuncName(self.func)}(${self.dataPointId?.presentName})`;
    }
  }));

export const DiffCondition = t
  .model({
    dataPointId: t.string,
    dataPointId2: t.string,
    func: Functions
  })
  .views((self) => {
    function getDataPoint() {
      return getParentByType(getParent(self), 'Alert')?.nodes?.getNodeByDataPointId(self.dataPointId)?.data_points?.getById(self.dataPointId);
    }
    function getDataPoint2() {
      return getParentByType(getParent(self), 'Alert')?.nodes?.getNodeByDataPointId(self.dataPointId2)?.data_points?.getById(self.dataPointId2);
    }
    return {
      get dataPoint() {
        return getDataPoint();
      },
      get dataPoint2() {
        return getDataPoint2;
      },
      get presentName() {
        return `Diff(${getDataPoint()?.node?.presentName} - ${getDataPoint()?.shortPresentName}, ${getDataPoint2()?.node?.presentName} - ${
          getDataPoint2()?.shortPresentName
        })`;
      }
    };
  });

export const DiffNestedCondition = t
  .model({
    dataPointId: t.late(() => Condition),
    dataPointId2: t.late(() => Condition),
    func: Functions
  })
  .views((self) => ({
    get presentName() {
      return `Diff(${self.dataPointId?.presentName}, ${self.dataPointId2?.presentName})`;
    }
  }));

export const DiffNestedFirstCondition = t
  .model({
    dataPointId: t.late(() => Condition),
    dataPointId2: t.string,
    func: Functions
  })
  .views((self) => {
    function getDataPoint2() {
      return getParentByType(getParent(self), 'Alert')?.nodes?.getNodeByDataPointId(self.dataPointId2)?.data_points?.getById(self.dataPointId2);
    }
    return {
      get presentName() {
        return `Diff(${self.dataPointId?.presentName}, ${getDataPoint2()?.node?.presentName} - ${getDataPoint2()?.shortPresentName})`;
      }
    };
  });

export const DiffNestedSecondCondition = t
  .model({
    dataPointId: t.string,
    dataPointId2: t.late(() => Condition),
    func: Functions
  })
  .views((self) => {
    function getDataPoint() {
      return getParentByType(getParent(self), 'Alert')?.nodes?.getNodeByDataPointId(self.dataPointId)?.data_points?.getById(self.dataPointId);
    }
    return {
      get dataPoint() {
        return getDataPoint();
      },
      get presentName() {
        return `Diff(${getDataPoint()?.node?.presentName} - ${getDataPoint()?.shortPresentName}, ${self.dataPointId2?.presentName})`;
      }
    };
  });

const Condition = t.union({
  dispatcher(snapshot) {
    const { func, dataPointId, dataPointId2 } = snapshot;
    if (func === 'diff') {
      if (isString(dataPointId) && isString(dataPointId2)) {
        return DiffCondition;
      }
      if (isObject(dataPointId) && isObject(dataPointId2)) {
        return DiffNestedCondition;
      }
      if (isString(dataPointId) && isObject(dataPointId2)) {
        return DiffNestedSecondCondition;
      }
      if (isObject(dataPointId) && isString(dataPointId2)) {
        return DiffNestedFirstCondition;
      }
    }
    if (func === 'abs') {
      if (isObject(dataPointId)) {
        return AbsNestedCondition;
      }
      return AbsCondition;
    }
    if (isObject(dataPointId)) {
      return NestedCondition;
    }
    return SimpleCondition;
  }
});

export interface ICondition extends Instance<typeof Condition> {}

function preProcessCondition(cnd: any): ICondition {
  if ((has(cnd, 'func') || has(cnd, 'expr')) && has(cnd, 'dataPointId')) {
    return cnd;
  }
  const [funcOrExpr] = Object.keys(cnd);
  // @ts-ignore
  const [[dataPointId, ...values]] = Object.values(cnd);

  let result: ICondition = { dataPointId, value: values[0] };

  if (EXPRESSIONS.includes(funcOrExpr)) {
    result.expr = funcOrExpr;
  } else {
    result.func = funcOrExpr;
  }
  if (funcOrExpr === 'diff') {
    if (isObject(dataPointId)) {
      result = { ...result, dataPointId: preProcessCondition(dataPointId), dataPointId2: values[0] };
    }
    if (isObject(values[0])) {
      result = { ...result, dataPointId2: preProcessCondition(values[0]) };
    }
    if (isString(dataPointId) && isString(values[0])) {
      result = { ...result, dataPointId2: values[0] };
    }
    return result;
  }
  if (isObject(dataPointId)) {
    result = { ...result, dataPointId: preProcessCondition(dataPointId) };
  }
  return result;
}

function postProcessCondition(sn) {
  let result = omitBy(sn, isNil);
  if (isObject(result.dataPointId)) {
    result = { ...result, dataPointId: postProcessCondition(result.dataPointId) };
  }
  if (isObject(result.dataPointId2)) {
    result = { ...result, dataPointId2: postProcessCondition(result.dataPointId2) };
  }
  return result;
}

export default t.snapshotProcessor(Condition, {
  preProcessor: preProcessCondition,
  postProcessor: postProcessCondition
});

import { Box, CardContent } from '@mui/material';
import TextInput from '~/components/final_form/text_input';
import useLocales from '~/hooks/use_locales';
import FormGrid from '~/components/@material-extend/form_grid';
import { ModalActions } from '~/components/modal';

type EditDashboardProps = {
  handleSubmit: () => void;
  handleClose: () => void;
  invalid: boolean;
  submitting: boolean;
};

function EditDashboard({ handleSubmit, invalid, submitting, handleClose }: EditDashboardProps) {
  const { t } = useLocales();

  return (
    <Box>
      <CardContent>
        <FormGrid>
          <TextInput label={t('attributes.dashboard.dashboard_name')} name="name" />
          <TextInput multiline label={t('attributes.dashboard.description')} name="description" />
        </FormGrid>
      </CardContent>
      <ModalActions onSave={handleSubmit} onClose={handleClose} disabled={invalid} loading={submitting} saveLabel={t('base.buttons.save')} />
    </Box>
  );
}

export default EditDashboard;

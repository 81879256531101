import { useState } from 'react';
import { Sorting } from '@devexpress/dx-react-grid';

const useSorting = (columns: Sorting[]) => {
  const [sorting, setSorting] = useState<Sorting[]>(columns);

  return {
    sorting,
    handleSortingChange: setSorting
  };
};

export default useSorting;

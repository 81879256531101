import React, { useCallback } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { CardContent } from '@mui/material';

import toasts from '~/utils/toasts';
import useMst from '~/hooks/use_mst';
import useLocales from '~/hooks/use_locales';
import useSelection from '~/hooks/react_grid/use_selection';

import FormGrid from '~/components/@material-extend/form_grid';
import { ModalActions } from '~/components/modal';
import { captureException } from '~/services/sentry';
import ModalTable from './modal_table';

type ModalProps = {
  toggleModal: VoidFunction;
};

function IdentifyWithLedModal({ toggleModal }: ModalProps) {
  const { t } = useLocales();
  const { selection: selectedCalibrations, setSelection } = useSelection();
  const { nodes } = useMst();

  const handleBlinkDevice = useCallback(async () => {
    const devices = selectedCalibrations.map((nodeId) => nodes.getById(nodeId).blink());
    try {
      await Promise.all(devices);
      setSelection([]);
      toggleModal();
      toasts.success(t('notifications.success.device_blink_led'));
    } catch (e) {
      captureException(e);
      toasts.error(t('notifications.errors.server_error'));
    }
  }, [t, selectedCalibrations, nodes, toggleModal, setSelection]);

  return (
    <FinalForm
      initialValues={{ selectedBilling: null, selectedOrganization: null, disabledNodes: [] }}
      onSubmit={handleBlinkDevice}
      render={({ handleSubmit }) => (
        <>
          <CardContent>
            <FormGrid>
              <ModalTable showBilling />
            </FormGrid>
          </CardContent>
          <ModalActions onSave={handleSubmit} saveLabel={t('base.buttons.run')} onClose={toggleModal} />
        </>
      )}
    />
  );
}

export default IdentifyWithLedModal;

/* eslint-disable no-param-reassign */
import { flow, getIdentifier, getParent, applySnapshot, onAction, getSnapshot } from 'mobx-state-tree';

import api from '~/api';
import type { IDashboardsModel } from '~/mst/models/dashboards/model';

import { IDashboardModel } from './model';

export default (self: IDashboardModel) => ({
  share: flow(function* share(external_org_id) {
    self.startSyncing();
    try {
      const { data } = yield api.dashboards.share(getIdentifier(self)!, external_org_id);
      applySnapshot(self, data);

      const dashboards = getParent<IDashboardsModel>(self, 2);
      dashboards.fetch();
      self.finishSyncing();
    } catch (error) {
      self.failSyncing(error);
    }
  }),
  fetch() {
    self.widgets.toArray.forEach((widget) => widget.fetch(self.range.fetchParams()));
  },
  clone: flow(function* clone() {
    self.startSyncing();
    try {
      const payload = {
        name: `Copy ${self.name}`
      };
      const { data } = yield api.dashboards.duplicate(getIdentifier(self)!, payload);
      const dashboards = getParent<IDashboardsModel>(self, 2);
      dashboards.add(data);
      self.finishSyncing();
      return data;
    } catch (error) {
      self.failSyncing(error);
    }
    return null;
  }),
  setRange(range) {
    self.range = range;
  },
  toggleLiveUpdates() {
    self.is_live_updates_on = !self.is_live_updates_on;
  },
  toggleIsStarred() {
    self.is_starred = !self.is_starred;
  },
  updateWidgets(layouts, breakpoint) {
    layouts.forEach((layout) => {
      const widget = self.widgets.getById(layout.i);
      if (widget) {
        widget.layout.setBreakpoint(breakpoint, layout);
      }
    });
  },
  update: flow(function* update(values) {
    try {
      yield api.dashboards.update(getIdentifier(self)!, values || getSnapshot(self));
      if (values) {
        Object.assign(self, values);
      }
    } catch (error) {
      self.failSyncing(error);
    }
  }),
  afterCreate() {
    onAction(
      self,
      (action) => {
        const { name } = action;
        if (['setRange', 'updateWidgets', 'toggleIsStarred'].includes(name)) {
          self.update();
        }
        if (name === 'setRange') {
          self.widgets.toArray.forEach((widget) => widget.fetch({ ...self.range.fetchParams(), last: null }));
        }
      },
      true
    );
  }
});

import { DateTime } from 'luxon';
import { formatDateFromTs } from '~/utils/format_date';

function DateTimeFormatter({ value, row, column }: { value: number; row: any; column: any }) {
  if (value && DateTime.fromMillis(value)?.isValid) {
    if (row.timezone) {
      return formatDateFromTs(value, { timezone: row.timezone, format: column.format });
    }
    return formatDateFromTs(value);
  }
}

export default DateTimeFormatter;

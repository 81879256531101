import React from 'react';
import { Grid } from '@mui/material';
import useLocales from '~/hooks/use_locales';
import TextInput from '~/components/final_form/text_input';
import PhoneInput from '~/components/final_form/phone_input';
import Select from '~/components/final_form/select';
import { timezones } from '~/utils/timezones';
import FormGrid from '~/components/@material-extend/form_grid';
import ImageUpload from '~/components/final_form/image_upload';

// eslint-disable-next-line react/require-default-props
export default function UserEditForm({ isAvatar = false }: { isAvatar?: boolean }) {
  const { t } = useLocales();

  const tzOptions: Array<{ label: string; value: string; group?: boolean }> = [];
  Object.entries(timezones).forEach(([key, values]) => {
    tzOptions.push({ value: '', label: key, group: true });
    tzOptions.push(...values.map((tz) => ({ label: tz.label, value: tz.timezone })));
  });

  return (
    <Grid container spacing={3}>
      {isAvatar && (
        <Grid item xs={12} md={4}>
          <ImageUpload name="picture" />
        </Grid>
      )}
      <Grid item xs={12} md={isAvatar ? 8 : 12}>
        <FormGrid columns="2">
          <TextInput name="firstName" label={t('attributes.user.given_name')} />
          <TextInput name="lastName" label={t('attributes.user.family_name')} />
          <Select name="timezone" label={t('attributes.user.timezone')} options={tzOptions} />
          <PhoneInput name="mobile" label={t('attributes.user.phone')} />
        </FormGrid>
      </Grid>
    </Grid>
  );
}

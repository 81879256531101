import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { OrganizationName } from '~/pages/organizations/grid_container/name_formatter';

export function OrganizationNameFormatter({ row }: DataTypeProvider.ValueFormatterProps) {
  return <OrganizationName name={row.organizationName} externalId={row.organizationExternalId} />;
}

export function PartnerNameFormatter({ row }: DataTypeProvider.ValueFormatterProps) {
  return <OrganizationName name={row.partnerName} externalId={row.partnerExternalId} />;
}

import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { transientOptions } from '~/utils/styled';
import type { Sizes } from '.';

type StyledProps = {
  breakpoint: Sizes;
  $mobileFullWidth?: boolean;
};

const ButtonDesktopOnlyStyled = styled(
  Button,
  transientOptions
)<StyledProps>(({ $mobileFullWidth, breakpoint, theme }) => ({
  [theme.breakpoints.down(breakpoint)]: {
    padding: theme.spacing(1),
    height: '38px',
    fontSize: '14px',
    ...($mobileFullWidth && {
      width: '100%'
    })
  }
}));

export default ButtonDesktopOnlyStyled;

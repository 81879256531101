import { useCallback } from 'react';
import { Grid } from '@mui/material';
import { observer } from 'mobx-react-lite';
import useMst from '~/hooks/use_mst';
import Filters from '~/components/filters';
import { ActionsWrapperStyled } from '~/components/actions_dropdown/styled';
import { ActionProvider } from '~/components/actions_dropdown/context';

import ActionsContainer from './actions_container';

const calibrationFilterGroups = ['calibration-status', 'calibration-ready'];

function FormTop() {
  const { calibrations, nodes } = useMst();

  const handleRefresh = useCallback(async () => {
    calibrations.fetch({}, { noCache: true });
    nodes.fetch({}, { excludeParams: ['organization_id'], noCache: true });
  }, [nodes, calibrations]);

  return (
    <Grid container spacing={2} sx={{ py: 2, px: 3 }}>
      <Grid item xs={12} sm={12} lg={5} md={6} sx={{ width: '100%' }}>
        <Filters collection={calibrations} groups={calibrationFilterGroups} handleRefresh={handleRefresh} />
      </Grid>

      <Grid item xs={12} sm={12} lg={5} md={6}>
        <ActionsWrapperStyled spacing={2} direction="row">
          <ActionProvider>
            <ActionsContainer />
          </ActionProvider>
        </ActionsWrapperStyled>
      </Grid>
    </Grid>
  );
}

export default observer(FormTop);

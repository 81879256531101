/* eslint-disable no-param-reassign */
import { flow, getIdentifier, getParent, onAction, getSnapshot, hasParent } from 'mobx-state-tree';
import api from '~/api';
import type { IDashboard } from '~/mst/models/dashboard';
import type { IWidgetModel } from './model';
import Layout from './layout';

export default (self: IWidgetModel) => ({
  update: flow(function* update(payload) {
    self.startSyncing();
    try {
      Object.assign(self, payload);
      const dashboard = getParent<IDashboard>(self, 3);
      yield api.dashboards.update(getIdentifier(dashboard)!, getSnapshot(dashboard));
      self.finishSyncing();
    } catch (error) {
      self.failSyncing(error);
    }
  }),
  setLayout(breakpoints) {
    self.layout = Layout.create(breakpoints);
  },
  afterCreate() {
    onAction(
      self,
      (action) => {
        const { name } = action;
        if (name === 'update') {
          if (hasParent(self, 3)) {
            const dashboard = getParent<IDashboard>(self, 3);
            self.fetch({ organization_id: dashboard.organization_id, ...dashboard.range.fetchParams() });
          }
        }
      },
      true
    );
  }
});

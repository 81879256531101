import { types as t, Instance } from 'mobx-state-tree';
import Fetchable from '~/mst/models/abstract/fetchable';
import { Model } from '~/mst/models/abstract/model';

const StatsModel = t.compose(
  t.model({
    stats: t.frozen()
  }),
  Model,
  Fetchable({ cache: true })
);

export interface IStatsModel extends Instance<typeof StatsModel> {}

export default StatsModel;

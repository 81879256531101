/* eslint-disable react/no-unstable-nested-components */
import { Grid, VirtualTable, TableHeaderRow, TableRowDetail } from '@devexpress/dx-react-grid-material-ui';
import { SortingState, RowDetailState } from '@devexpress/dx-react-grid';
import { observer } from 'mobx-react-lite';

import { GridStyled } from '~/components/react_grid/styled';
import { Container } from '~/components/react_grid';
import useSorting from '~/hooks/react_grid/use_sorting';
import useLoading from '~/hooks/react_grid/use_loading';
import { IWebhook } from '~/mst/models/webhook';
import useExpandRow from '~/hooks/react_grid/use_expand_row';
import useMst from '~/hooks/use_mst';
import { ResultProvider, TimeAgoProvider, ActionsProvider, EnabledProvider } from './info_providers';
import GridDetailContainer from './detail_row';

const columns = [
  { name: 'url', title: 'Url', sortingEnabled: false },
  { name: 'organization_name', title: 'Organization' },
  { name: 'enabled', title: 'Enabled' },
  { name: 'last_used_at', title: 'Last Updated' },
  { name: 'status', title: 'Result' },
  { name: 'created_at', title: 'Created At' },
  { name: 'actions', title: ' ' }
];

const getRowId = ({ _id }: IWebhook) => _id;

function WebhooksGrid() {
  const { webhooks } = useMst();

  const { expandedRowIds, handleSetExpandedRowIds, Row } = useExpandRow();

  const { messages, RootComponent } = useLoading(webhooks);
  const { sorting, handleSortingChange } = useSorting([{ columnName: 'created_at', direction: 'desc' }]);

  const rows = webhooks.filteredList;

  return (
    <GridStyled>
      <Grid rows={rows} columns={columns} getRowId={getRowId} rootComponent={RootComponent}>
        <SortingState sorting={sorting} onSortingChange={handleSortingChange} />
        <TimeAgoProvider />
        <ResultProvider />
        <ActionsProvider />
        <EnabledProvider />
        <VirtualTable
          height="auto"
          containerComponent={Container}
          messages={messages}
          columnExtensions={[
            {
              columnName: 'actions',
              width: 60
            },
            {
              columnName: 'device_scope',
              width: 150
            },
            {
              columnName: 'requests_number',
              width: 110
            },
            {
              columnName: 'created_at',
              width: 150
            },
            {
              columnName: 'last_used_at',
              width: 150
            }
          ]}
          rowComponent={Row}
        />
        <RowDetailState expandedRowIds={expandedRowIds} onExpandedRowIdsChange={handleSetExpandedRowIds} />
        <TableRowDetail rowComponent={GridDetailContainer} />
        <TableHeaderRow showSortingControls />
      </Grid>
    </GridStyled>
  );
}

export default observer(WebhooksGrid);

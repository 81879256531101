import { Instance } from 'mobx-state-tree';
import actions from './actions';
import model from './model';
import views from './views';
import WidgetProcessor from '../processor';

const NodeWidget = model.views(views).actions(actions);

export interface INodeWidget extends Instance<typeof NodeWidget> {}

export default WidgetProcessor(NodeWidget);

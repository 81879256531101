import { SortingState, IntegratedSorting } from '@devexpress/dx-react-grid';
import { Grid, VirtualTable, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { observer } from 'mobx-react-lite';

import { GridStyled } from '~/components/react_grid/styled';
import { Container, Row } from '~/components/react_grid';
import useLoading from '~/hooks/react_grid/use_loading';
import useSorting from '~/hooks/react_grid/use_sorting';
import useMst from '~/hooks/use_mst';

import { CreatedAtProvider, ActionsProvider, NameProvider } from './info_providers';

function compareDates(a: any, b: any) {
  // @ts-ignore
  return (b != null) - (a != null) || b - a;
}

const columns = [
  { name: 'name', title: 'Name' },
  { name: 'description', title: 'Description' },
  { name: 'created_at', title: 'Date Created' },
  { name: 'actions', title: ' ', sortingEnabled: false }
];

const getRowId = (row) => row.id;

function GridContainer() {
  const { reports } = useMst();

  const { messages, RootComponent } = useLoading(reports);
  const { sorting, handleSortingChange } = useSorting([{ columnName: 'name', direction: 'asc' }]);

  return (
    <GridStyled>
      <Grid rows={reports.filteredList} columns={columns} getRowId={getRowId} rootComponent={RootComponent}>
        <SortingState sorting={sorting} onSortingChange={handleSortingChange} />
        <IntegratedSorting columnExtensions={[{ columnName: 'created_at', compare: compareDates }]} />
        <CreatedAtProvider for={['created_at']} />
        <ActionsProvider for={['actions']} />
        <NameProvider for={['name']} />
        <VirtualTable
          rowComponent={Row}
          messages={messages}
          columnExtensions={[{ columnName: 'actions', width: 65 }]}
          height="auto"
          containerComponent={Container}
        />
        <TableHeaderRow showSortingControls />
      </Grid>
    </GridStyled>
  );
}

export default observer(GridContainer);

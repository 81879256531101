import { types as t, Instance, SnapshotOut } from 'mobx-state-tree';
import { ModelV2 } from '~/mst/models/abstract/model';
import NodesStore from '~/mst/models/nodes';
import OrganizationModel from '~/mst/models/organization';
import Syncable from '~/mst/models/abstract/syncable';
import CreatedAtModel from '~/mst/models/abstract/created_at';
import PermissionsOrganization from '~/mst/models/permissions/organization';

const PartnershipModel = t.compose(
  t
    .model({
      partner: OrganizationModel,
      organization: OrganizationModel,
      permissions: PermissionsOrganization
    })
    .props({
      nodes: t.optional(NodesStore, [])
    }),
  ModelV2,
  Syncable,
  CreatedAtModel
);

export interface IPartnershipModel extends Instance<typeof PartnershipModel> {}
export interface IPartnershipModelSnapshotOut extends SnapshotOut<typeof PartnershipModel> {}

export default PartnershipModel;

/* eslint-disable no-param-reassign */
import { types as t, Instance } from 'mobx-state-tree';
import Range from '~/mst/models/range';
import Widgets from './widgets';

export const TABS = {
  DEBUG: {
    name: 'debug',
    index: 0
  },
  FILES: {
    index: 1,
    name: 'files'
  }
};

const DeviceAdminModel = t.model({
  range: t.optional(Range, () =>
    // @ts-ignore
    Range.create({
      from: '3_hours'
    })
  ),
  selected_tab: t.optional(
    t.enumeration(
      'Tab',
      Object.entries(TABS).map(([, { name }]) => name)
    ),
    TABS.DEBUG.name
  ),
  is_live_updates_on: t.optional(t.boolean, true),
  widgets: t.optional(Widgets, [])
});

export interface IDeviceAdminModel extends Instance<typeof DeviceAdminModel> {}

export default DeviceAdminModel;

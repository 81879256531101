/* eslint-disable no-param-reassign */
import { types as t, Instance } from 'mobx-state-tree';
import Alert from '~/mst/models/alert';
import Fetchable from '~/mst/models/abstract/fetchable';
import Filterable from '~/mst/models/abstract/filterable';
import CollectionMap from '~/mst/models/abstract/collection_map';

const AlertsModel = t.compose(CollectionMap(Alert), Filterable, Fetchable({ cache: true }));

export interface IAlertsModel extends Instance<typeof AlertsModel> {}

export default AlertsModel;

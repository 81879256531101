import React, { useState, useCallback, useMemo, ReactNode, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { autorun } from 'mobx';
import socket from '~/services/socket';
import { token } from '~/api/axios';

type SubscribableState = {
  subscribe: (newValue: string[]) => void;
  unsubscribe: () => void;
};

const initialState: SubscribableState = {
  subscribe: () => {},
  unsubscribe: () => {}
};

const SubscribableContext = React.createContext(initialState);

const SubscribableProvider = observer(({ children }: { children: ReactNode }) => {
  const [nodeIds, setNodeIds] = useState<string[] | null>(null);

  const handleSubscribeToNodeIds = useCallback((newNodeIds: string[]) => setNodeIds(newNodeIds), [setNodeIds]);
  const handleUnsubscribeToNodeIds = useCallback(() => setNodeIds([]), []);

  const value = useMemo(
    () => ({
      subscribe: handleSubscribeToNodeIds,
      unsubscribe: handleUnsubscribeToNodeIds
    }),
    [handleSubscribeToNodeIds, handleUnsubscribeToNodeIds]
  );

  useEffect(
    () =>
      autorun(() => {
        if (token.token && token.isAuthorized()) {
          socket.connect();
        }
      }),
    []
  );

  useEffect(() => {
    const onlineListener = async () => {
      if (token.isExpired()) {
        await token.refreshToken();
        socket.connect();
      }
    };
    const offlineListener = () => {};

    window.addEventListener('online', onlineListener);
    window.addEventListener('offline', offlineListener);

    return () => {
      window.removeEventListener('online', onlineListener);
      window.removeEventListener('offline', offlineListener);
    };
  }, []);

  useEffect(() => {
    if (nodeIds) {
      socket.send('update', { node_ids: nodeIds });
    }
  }, [nodeIds]);

  return <SubscribableContext.Provider value={value}>{children}</SubscribableContext.Provider>;
});

export { SubscribableProvider, SubscribableContext };

import { observer } from 'mobx-react-lite';
import { CardContent } from '@mui/material';
import { useFormState } from 'react-final-form';

import useLocales from '~/hooks/use_locales';
import FormGrid from '~/components/@material-extend/form_grid';
import { ModalActions } from '~/components/modal';
import TextInput from '~/components/final_form/text_input';
import type { IAdamSensor } from '~/mst/models/device/sensor/adam';

type FormProps = {
  handleSubmit: () => void;
  handleClose: () => void;
  sensor: IAdamSensor;
};

function ConfigureMeasurementForm({ sensor, handleSubmit, handleClose }: FormProps) {
  const { t } = useLocales();
  const { invalid } = useFormState({ subscription: { invalid: true } });
  return (
    <>
      <CardContent>
        <FormGrid>
          <TextInput name="name" label={t('sensor.data_point.name')} />
          <TextInput name="unit" label={t('sensor.data_point.unit')} />
          <TextInput name="min" label={t('sensor.data_point.min')} />
          <TextInput name="max" label={t('sensor.data_point.max')} />
        </FormGrid>
      </CardContent>
      <ModalActions saveLabel={t('base.buttons.create')} onClose={handleClose} onSave={handleSubmit} loading={sensor.isSyncing} disabled={invalid} />
    </>
  );
}

export default observer(ConfigureMeasurementForm);

import { observer } from 'mobx-react-lite';

import type { IHeatmapWidget } from '~/mst/models/widget/heatmap_widget';
import { WidgetTitleStyled } from '~/components/widgets/common/styled';
import { TitleContainer } from './styled';

type HeatmapWidgetTitleProps = {
  widget: IHeatmapWidget;
};

function HeatmapWidgetTitle({ widget }: HeatmapWidgetTitleProps) {
  return (
    <TitleContainer direction="row" spacing={1}>
      <WidgetTitleStyled>{widget?.name}</WidgetTitleStyled>
    </TitleContainer>
  );
}

export default observer(HeatmapWidgetTitle);

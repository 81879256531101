/* eslint-disable @typescript-eslint/no-use-before-define */
import { types as t, Instance, getParent } from 'mobx-state-tree';
import has from 'lodash/has';
import isObject from 'lodash/isObject';

import DataPointNodeReference, { getParentByType } from '~/mst/models/data_point/node/reference';
import { MetricFunctions, Value, getFuncName } from '../types';
import { getValueForTimePeriod } from '../alert';

export const SimpleCondition = t
  .model({
    dataPointId: DataPointNodeReference,
    func: MetricFunctions,
    value: Value
  })
  .views((self) => {
    function getDataPoint() {
      return getParentByType(getParent(self), 'DataPointsNode')?.getById(self.dataPointId);
    }
    return {
      get dataPoint() {
        return getDataPoint();
      },
      get presentName() {
        if (self.value != null) {
          return `${getFuncName(self.func)}(${self.dataPointId?.presentName || '...'}, ${getValueForTimePeriod(self.value, self.func)})`;
        }
        return `${getFuncName(self.func)}(${self.dataPointId?.presentName || '...'})`;
      },
      get shortPresentName() {
        if (self.value != null) {
          return `${getFuncName(self.func)}(${self.dataPointId?.shortPresentName || '...'}, ${getValueForTimePeriod(self.value, self.func)})`;
        }
        return `${getFuncName(self.func)}(${self.dataPointId?.shortPresentName || '...'})`;
      }
    };
  });

export const NestedCondition = t
  .model({
    dataPointId: t.late(() => Condition),
    func: MetricFunctions,
    value: Value
  })
  .views((self) => {
    return {
      get presentName() {
        if (self.value != null) {
          return `${getFuncName(self.func)}(${self.dataPointId.presentName}) ${getValueForTimePeriod(self.value, self.func)}`;
        }
        return `${getFuncName(self.func)}(${self.dataPointId.presentName})`;
      },
      get shortPresentName() {
        if (self.value != null) {
          return `${getFuncName(self.func)}(${self.dataPointId.shortPresentName}) ${getValueForTimePeriod(self.value, self.func)}`;
        }
        return `${getFuncName(self.func)}(${self.dataPointId.shortPresentName})`;
      }
    };
  });

export const ScaleCondition = t
  .model({
    dataPointId: DataPointNodeReference,
    func: MetricFunctions,
    range1: t.number,
    range2: t.number,
    min: t.number,
    max: t.number
  })
  .views((self) => ({
    get presentName() {
      return `${getFuncName(self.func)}(${self.dataPointId?.presentName}, ${self.range1}, ${self.range2}, ${self.min}, ${self.max})`;
    },
    get shortPresentName() {
      return `${getFuncName(self.func)}(${self.dataPointId?.shortPresentName}, ${self.range1}, ${self.range2}, ${self.min}, ${self.max})`;
    }
  }));

export const LinkCondition = t
  .model({
    dataPointId: DataPointNodeReference,
    func: MetricFunctions
  })
  .views((self) => ({
    get presentName() {
      return `${getParentByType(getParent(self), 'DataPointMetric')?.name}(${self.dataPointId?.presentName})`;
    },
    get shortPresentName() {
      return `${getParentByType(getParent(self), 'DataPointMetric')?.name}(${self.dataPointId?.shortPresentName})`;
    }
  }));

const Condition = t.union({
  dispatcher(snapshot) {
    const { func, dataPointId } = snapshot;
    if (func === 'scale') {
      return ScaleCondition;
    }
    if (func === 'link') {
      return LinkCondition;
    }
    if (isObject(dataPointId)) {
      return NestedCondition;
    }
    return SimpleCondition;
  }
});

export interface ICondition extends Instance<typeof Condition> {}

function preProcessCondition(cnd: any): ICondition {
  if (has(cnd, 'func') && has(cnd, 'dataPointId')) {
    return cnd;
  }
  const [func] = Object.keys(cnd);
  // @ts-ignore
  const [[dataPointId, ...values]] = Object.values(cnd);
  let result: ICondition = { func, dataPointId, value: values[0] };
  if (func === 'scale') {
    const [range1, range2, min, max] = values;
    result = { func, dataPointId, range1, range2, min, max };
  }
  if (func === 'link') {
    result = { func, dataPointId };
  }
  if (isObject(dataPointId)) {
    result = { ...result, dataPointId: preProcessCondition(dataPointId) };
  }
  return result;
}

export default t.snapshotProcessor(Condition, {
  preProcessor: preProcessCondition
});

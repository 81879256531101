import { Instance, SnapshotOut } from 'mobx-state-tree';
import model from './model';
import actions from './actions';
import views from './views';
import viewsFilters from './views/filters';

const Webhook = model.views(views).views(viewsFilters).actions(actions);

export interface IWebhook extends Instance<typeof Webhook> {}
export interface IWebhookSnapshotOut extends SnapshotOut<typeof Webhook> {}

export default Webhook;

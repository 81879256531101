import React, { useState, useCallback } from 'react';
import { IconButton, MenuItem, Divider } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ViewIcon from '@mui/icons-material/BarChart';
import { observer } from 'mobx-react-lite';

import ConfirmDialog from '~/components/confirm_dialog';
import useMst from '~/hooks/use_mst';
import useNavigate from '~/hooks/use_navigate';
import useActionsPopover from '~/hooks/react_grid/use_actions_popover';
import useLocales from '~/hooks/use_locales';
import toasts from '~/utils/toasts';

import { ReportRowProps } from './types';

const ICON = {
  mr: 2,
  width: 20,
  height: 20
};

function ActionsFormatter({ row }: ReportRowProps) {
  const { name, id } = row;
  const { reports } = useMst();
  const report = reports.getById(id);

  const { t } = useLocales();

  const { ActionsMenu, handleClose, handleOpen } = useActionsPopover();
  const [isDeleting, setIsDeleting] = useState(false);
  const { navigate } = useNavigate();

  const handleDelete = useCallback(
    async (event) => {
      event.stopPropagation();
      await reports.destroy(report);
      toasts.success(t('notifications.success.forms.delete_report'));
    },
    [reports, report, t]
  );

  const handleModify = useCallback(
    (event) => {
      event.stopPropagation();
      navigate(`reports/${id}`);
    },
    [navigate, id]
  );

  const handleView = useCallback(
    (event) => {
      event.stopPropagation();
      navigate(`reports/${id}/view`);
    },
    [navigate, id]
  );

  const handleStartDelete = useCallback(
    (event) => {
      event.stopPropagation();
      setIsDeleting(true);
      handleClose();
    },
    [handleClose]
  );

  const handleCancelDelete = useCallback((event) => {
    event.stopPropagation();
    setIsDeleting(false);
  }, []);

  return (
    <>
      <IconButton onClick={handleOpen}>
        <MoreVertIcon />
      </IconButton>
      <ActionsMenu>
        <MenuItem onClick={handleModify}>
          <EditIcon sx={ICON} />
          {t('base.buttons.edit')}
        </MenuItem>
        <MenuItem onClick={handleView}>
          <ViewIcon sx={ICON} />
          {t('base.buttons.view')}
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleStartDelete} sx={{ color: 'error.main' }}>
          <DeleteIcon sx={ICON} />
          {t('base.buttons.delete')}
        </MenuItem>
      </ActionsMenu>
      <ConfirmDialog
        open={isDeleting}
        onClose={handleCancelDelete}
        onConfirm={handleDelete}
        title={t('reports.dialogs.delete.title')}
        content={t('reports.dialogs.delete.content', { name })}
        confirmLabel="Delete"
        confirmButtonType="error"
      />
    </>
  );
}

export default observer(ActionsFormatter);

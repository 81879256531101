import { TableCell, Stack, Box, CircularProgress, useMediaQuery, Theme } from '@mui/material';
import { observer } from 'mobx-react-lite';
import useMst from '~/hooks/use_mst';
import useFetch from '~/hooks/use_fetch';
import { DetailRowStyled } from '~/components/react_grid/styled';
import { INodeModelSnapshotOut } from '~/mst/models/node/model';
import Device from './device';

function GridDetailContainer({ row }: { row: INodeModelSnapshotOut }) {
  const { id } = row;
  const hiddenDown = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'));
  const { organizations } = useMst();
  const organization = organizations.getById(id);

  const { isFetching } = useFetch(organization?.stats, { organization_id: id });

  const devices = organization?.stats?.devicesTotalByType;

  return (
    <DetailRowStyled>
      {!organization?.stats || isFetching ? (
        <TableCell colSpan={5}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '102px' }}>
            <CircularProgress size={30} color="info" />
          </Box>
        </TableCell>
      ) : (
        <>
          {!hiddenDown && <TableCell />}
          <TableCell colSpan={4}>
            {devices.length ? (
              <Stack direction="row" justifyContent="flex-start" flexWrap="wrap">
                {devices.map(({ total, name }) => (
                  <Device key={name} name={name} total={total} />
                ))}
              </Stack>
            ) : (
              <>No Devices</>
            )}
          </TableCell>
          {hiddenDown && (
            <>
              <TableCell colSpan={1} />
              <TableCell colSpan={1} />
            </>
          )}
        </>
      )}
    </DetailRowStyled>
  );
}

export default observer(GridDetailContainer);

/* eslint-disable @typescript-eslint/naming-convention */
import groupBy from 'lodash/fp/groupBy';
import flow from 'lodash/fp/flow';
import flatten from 'lodash/flatten';
import map from 'lodash/fp/map';
import type { IAlertDurationsTableRowModel } from './model';

export default (self: IAlertDurationsTableRowModel) => ({
  get chartData() {
    return flow(
      // @ts-ignore
      map(({ data_point_id: dataPointId, data }) => data.map(({ ts, value }) => ({ ts, [dataPointId]: value }))),
      flatten,
      groupBy('ts'),
      map((group, ts) => ({
        ts,
        ...group.reduce((acc, value) => ({ ...acc, ...value }), {})
      }))
    )(self.measurements.toArray);
  }
});

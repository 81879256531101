/* eslint-disable react/jsx-props-no-spreading */
import { filterText, renderInput, renderOption } from '~/components/devices_select';
import StatusWithAutoUpdate from '~/components/status_with_autoupdate';
import NavigateDropdwon from './index';

function DevicesNavigateDropdown(props) {
  return (
    <NavigateDropdwon
      width={{
        multiplier: 1.5,
        extra: 50
      }}
      filterText={filterText}
      renderInput={renderInput}
      renderOption={renderOption}
      InputStartAdornment={StatusWithAutoUpdate}
      {...props}
    />
  );
}

export default DevicesNavigateDropdown;

/* eslint-disable import/prefer-default-export */
export const defaultChartOptions = {
  title: {
    text: null
  },
  chart: {
    type: 'bar',
    marginTop: 0,
    marginRight: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.1)'
  },
  plotOptions: {
    series: {
      stacking: 'normal'
    }
  },
  credits: {
    enabled: false
  },
  exporting: {
    enabled: false
  },
  tooltip: {
    format: '<b>{key}</b><br/>{series.name}: {y}<br/>'
  },
  yAxis: {
    allowDecimals: false,
    min: 0,
    stackLabels: {
      enabled: true,
      formatter() {
        return this.total;
      },
      style: {
        color: '#333',
        fontWeight: 'bold'
      }
    },
    title: {
      text: ''
    }
  }
};

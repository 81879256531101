/* eslint-disable no-param-reassign */
import { flow, applySnapshot } from 'mobx-state-tree';
import api from '~/api';
import { toMapById } from '~/mst/utils';
import type { ICalibrationsModel } from './model';

export default (self: ICalibrationsModel) => ({
  fetch: flow(function* fetch(params, options) {
    try {
      self.startFetching();
      const { data } = yield self.sync(api.calibrations.fetch, params, options);
      applySnapshot(self.models, toMapById(data, 'node_id'));
      self.finishFetching();
    } catch (error) {
      self.failFetching(error);
    }
  }),
  create: flow(function* create(payload) {
    self.startSyncing();
    try {
      const { data } = yield api.calibrations.create(payload);
      self.finishSyncing();
      return data;
    } catch (error) {
      self.failSyncing(error);
    }
    return null;
  }),
  reset: flow(function* reset(serials) {
    self.startSyncing();
    try {
      const { data } = yield api.calibrations.reset({ serials });
      self.finishSyncing();
      return data;
    } catch (error) {
      self.failSyncing(error);
    }
    return null;
  })
});

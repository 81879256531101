/* eslint-disable no-param-reassign */
import { flow, getIdentifier, getParent } from 'mobx-state-tree';
import api from '~/api';
import { getStore } from '~/mst/utils';
import type { IWebhooksStore } from '~/mst/models/webhooks';
import type { IWebhookModel } from './model';

export default (self: IWebhookModel) => ({
  fetch: flow(function* fetch() {
    if (!self.conditions?.node_id.length) {
      return;
    }
    try {
      self.startFetching();
      const [search, ...args] = api.search.nodes({
        node_id: self.conditions.node_id,
        type: 'thiamis'
      });
      const { data } = yield search(...args);
      const { nodes } = getStore(self);
      data.forEach((element) => {
        if (!nodes.getById(element._id)) {
          nodes.add(element);
        }
      });
      self.finishFetching();
    } catch (error) {
      self.failFetching(error);
    }
  }),
  destroy: flow(function* destroy() {
    self.startSyncing();
    try {
      yield api.webhooks.destroy(getIdentifier(self)!);
      const webhooks = getParent<IWebhooksStore>(self, 2);
      webhooks.remove(self);
      self.finishSyncing();
    } catch (error) {
      self.failSyncing(error);
    }
  }),
  toggle: flow(function* toggle() {
    self.startSyncing();
    try {
      const action = self.enabled ? 'disable' : 'enable';
      yield api.webhooks.toggle(getIdentifier(self)!, action);
      self.enabled = !self.enabled;
      self.finishSyncing();
    } catch (error) {
      self.failSyncing(error);
    }
  })
});

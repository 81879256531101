/* eslint-disable import/prefer-default-export */
import request from '../request';

export function fetch(params?) {
  return [request, 'get', 'dashboards', params];
}

export function create(payload: any) {
  return request('post', 'dashboards', payload);
}

export function update(id: string, payload: any) {
  return request('put', `dashboards/${id}`, payload);
}

export function duplicate(id: string, payload: any) {
  return request('post', `dashboards/${id}/clone`, payload);
}

export function destroy(id: string) {
  return request('delete', `dashboards/${id}`);
}

export function share(id: string, external_org_id: string) {
  return request('put', `dashboards/${id}/share/${external_org_id}`);
}

export function read(id) {
  return [request, 'get', `dashboards/${id}`];
}

import { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { List, Collapse } from '@mui/material';

import { NavListProps } from './type';

import { NavItemRoot } from './nav_item';
import NavPopover from './nav_popover';
import { getActive } from '../config';
import NavListSub from './nav_list_sub';

type NavListRootProps = {
  list: NavListProps;
  isCollapse: boolean;
};

function NavListRoot({ list, isCollapse }: NavListRootProps) {
  const { pathname } = useLocation();
  const { slug } = useParams();

  const active = getActive(slug!, list.path, pathname);

  const [open, setOpen] = useState(active);

  if (list.children) {
    if (!isCollapse) {
      return (
        <>
          <NavItemRoot item={list} isCollapse={isCollapse} active={active} open={open} onOpen={() => setOpen(!open)} />
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {list.children?.map((item) => <NavListSub key={item.title} list={item} root={list.path} />)}
            </List>
          </Collapse>
        </>
      );
    }

    return <NavPopover item={list} />;
  }

  return <NavItemRoot item={list} active={active} isCollapse={isCollapse} />;
}

export default NavListRoot;

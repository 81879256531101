import AdamComponent from './adam';
import AirmarComponent from './airmar';
import DavisComponent from './davis';
import DustTrakComponent from './dust_trak';
import DataRamComponent from './data_ram';
import GX6000Component from './gx_6000';
import GM460Component from './gm_460';
import ParticlesPlusComponent from './particles_plus';
import SoundProComponent from './sound_pro';
import YsiComponent from './ysi';
import AlphaSenseComponent from './alpha_sense';
import SensorComponent from './sensor';
import AirmarValidation from './airmar/validation';
import DavisValidation from './davis/validation';
import DustTrakValidation from './dust_trak/validation';
import DataRamValidation from './data_ram/validation';
import AdamValidation from './adam/validation';
import ParticlesPlusValidation from './particles_plus/validation';
import SoundProValidation from './sound_pro/validation';
import AlphaSenseValidation from './alpha_sense/validation';
import { SensorSchema } from './validation';

type SensorArtefact = {
  component: any;
  schema?: any;
};

function getSensorArtefacts(name?: string): SensorArtefact {
  switch (name) {
    case 'adam':
      return { component: AdamComponent, schema: AdamValidation };
    case 'gx-6000':
      return { component: GX6000Component, schema: SensorSchema };
    case 'gm-460':
      return { component: GM460Component, schema: AdamValidation };
    case 'airmar':
      return { component: AirmarComponent, schema: AirmarValidation };
    case 'data-ram':
      return { component: DataRamComponent, schema: DataRamValidation };
    case 'davis':
      return { component: DavisComponent, schema: DavisValidation };
    case 'dust-trak':
      return { component: DustTrakComponent, schema: DustTrakValidation };
    case 'particles-plus':
      return { component: ParticlesPlusComponent, schema: ParticlesPlusValidation };
    case 'sound-pro':
      return { component: SoundProComponent, schema: SoundProValidation };
    case 'alpha-sense':
      return { component: AlphaSenseComponent, schema: AlphaSenseValidation };
    case 'ysi':
      return { component: YsiComponent, schema: SensorSchema };
    default:
      return { component: SensorComponent, schema: SensorSchema };
  }
}

export default getSensorArtefacts;

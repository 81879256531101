/* eslint-disable no-prototype-builtins */
import { useCallback, useState } from 'react';
import { Box, Stack, IconButton, Tooltip, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import isEmpty from 'lodash/isEmpty';
import { getIdentifier } from 'mobx-state-tree';
import { Icon } from '@iconify/react';
import plus from '@iconify/icons-eva/settings-outline';
import { ISensor } from '~/mst/models/device/sensor';
import { BodyModule } from '~/pages/device_edit/components';
import FormGrid from '~/components/@material-extend/form_grid';
import useLocales from '~/hooks/use_locales';
import useModal from '~/hooks/use_modal';

import SwitchFields from '~/pages/device_edit/containers/sensor_form_container/sensors/sensor/common/switch_fields';

import { SensorProperty } from '../components';
import ConfigureMeasurementModal from './configure_measurement_modal';

type SensorType = {
  sensor: ISensor;
};

const MeasurementItem = observer(({ dataPoint, handleClick, sensor }) => {
  const { t } = useLocales();
  const metricDataPoint = sensor?.metricDataPoints.find(({ linked }) => linked.includes(getIdentifier(dataPoint)));

  return (
    <Stack direction="row">
      <Tooltip title={t('sensor.data_point.add_scale_title_wo_channel')}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton sx={{ width: 40, height: 40 }} onClick={() => handleClick(getIdentifier(dataPoint))} size="small">
            <Icon icon={plus} />
          </IconButton>
        </Box>
      </Tooltip>
      <Box sx={{ width: '80%', display: 'flex', alignItems: 'center' }}>
        <Typography variant="body1">
          {dataPoint.presentName}
          <Typography variant="body2">{metricDataPoint && `(${metricDataPoint.presentName})`}</Typography>
        </Typography>
      </Box>
    </Stack>
  );
});

function Ysi({ sensor }: SensorType) {
  const { open, handleClose, handleOpen } = useModal();
  const [measurementId, setMeasurementId] = useState(null);
  const { t } = useLocales();
  const handleMeasurementItemClick = useCallback(
    (id) => {
      setMeasurementId(id);
      handleOpen();
    },
    [setMeasurementId, handleOpen]
  );

  if (isEmpty(sensor.rawDataPoints) && !sensor.formValues.hasOwnProperty('enabled')) {
    return null;
  }

  return (
    <BodyModule title={t('device_monitor.forms.settings')} columns={1} expandable expanded>
      <SwitchFields />
      <SensorProperty name="available_channels">
        <FormGrid columns="2">
          {sensor.rawDataPoints?.map((dp) => (
            <MeasurementItem dataPoint={dp} sensor={sensor} key={getIdentifier(dp)} handleClick={handleMeasurementItemClick} />
          ))}
        </FormGrid>
      </SensorProperty>
      <ConfigureMeasurementModal
        open={open}
        onClose={handleClose}
        sensor={sensor}
        dataPoint={sensor?.rawDataPoints?.find((dp) => getIdentifier(dp) === measurementId)}
      />
    </BodyModule>
  );
}

export default observer(Ysi);

import { Box } from '@mui/material';
import { ItemTitle } from '~/pages/device_edit/components';
import FormGrid from '~/components/@material-extend/form_grid';
import Switch from '~/components/final_form/switch';
import { SensorProperty } from '~/pages/device_edit/containers/sensor_form_container/sensors/components';
import useLocales from '~/hooks/use_locales';

function Hardware() {
  const { t } = useLocales();

  return (
    <FormGrid rowGap={0}>
      <Box>
        <ItemTitle>{t('device_monitor.forms.sensors')}</ItemTitle>
        <FormGrid columns="2" rowGap={0}>
          <SensorProperty name="thiamis.enable_battery">
            <Switch label="Bat.voltage" name="thiamis.enable_battery" type="number" data-testid="deviceEnableBatterySwitch" />
          </SensorProperty>
          <SensorProperty name="thiamis.enable_temperature">
            <Switch
              label={t('device_monitor.forms.temperature')}
              name="thiamis.enable_temperature"
              type="number"
              data-testid="deviceTemperatureSwitch"
            />
          </SensorProperty>
          <SensorProperty name="thiamis.enable_pressure">
            <Switch label={t('device_monitor.forms.pressure')} name="thiamis.enable_pressure" type="number" data-testid="devicePressureSwitch" />
          </SensorProperty>
        </FormGrid>
      </Box>
    </FormGrid>
  );
}

export default Hardware;

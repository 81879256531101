import { observer } from 'mobx-react-lite';

import { WidgetTypes } from '~/mst/models/widget/types';
import { WidgetPropType } from '~/components/widgets/common/types';
import NodeWidget from '~/components/widgets/node_widget';
import HeatmapWidget from '~/components/widgets/heatmap_widget';
import ChartWidget from '~/components/widgets/chart_widget';
import MapWidget from '~/components/widgets/map_widget';

function WidgetWrapper({ widget, moveable, dashboard, editable }: WidgetPropType) {
  switch (widget.type) {
    case WidgetTypes.NodeWidget:
      return <NodeWidget moveable={moveable} editable={editable} widget={widget} dashboard={dashboard} />;
    case WidgetTypes.ChartWidget:
      return <ChartWidget moveable={moveable} editable={editable} widget={widget} dashboard={dashboard} />;
    case WidgetTypes.HeatmapWidget:
      return <HeatmapWidget moveable={moveable} editable={editable} widget={widget} dashboard={dashboard} />;
    case WidgetTypes.MapWidget:
      return <MapWidget moveable={moveable} editable={editable} widget={widget} dashboard={dashboard} />;
    default:
      return null;
  }
}

export default observer(WidgetWrapper);

import React, { useCallback } from 'react';
import Yup, { searchableSelectSchema } from '~/utils/yup';
import { CardContent } from '@mui/material';
import { Form as FinalForm } from 'react-final-form';

import toasts from '~/utils/toasts';

import { useValidationSchema } from '~/hooks/use_validate';
import useLocales from '~/hooks/use_locales';
import useModal from '~/contexts/modal_context';
import useMst from '~/hooks/use_mst';

import OrganizationSelect from '~/components/organizations_select';
import Autocomplete from '~/components/final_form/select';
import { ModalActions } from '~/components/modal';
import FormGrid from '~/components/@material-extend/form_grid';
import { captureException } from '~/services/sentry';

function CreateApiForm() {
  const { t } = useLocales();
  const { organizations, tokens } = useMst();
  const { handleClose } = useModal();

  const handleFormSubmit = useCallback(
    async ({ organization, capabilities }) => {
      try {
        await tokens.create({
          organization,
          capabilities: capabilities.map((item) => item.value)
        });
        toasts.success(t('notifications.success.forms.api_token'));
      } catch (e) {
        captureException(e);
        toasts.error(t('notifications.errors.server_error'));
      }
      handleClose();
    },
    [tokens, handleClose, t]
  );

  const CreateTokenSchema = Yup.object().shape({
    organization: searchableSelectSchema('Organization is required'),
    capabilities: Yup.array().required('Required').min(1, 'Required')
  });
  const validate = useValidationSchema(CreateTokenSchema);

  return (
    <FinalForm
      validate={validate}
      onSubmit={handleFormSubmit}
      render={({ handleSubmit, invalid, values: { organization: organizationId } }) => {
        const organization = organizations.getById(organizationId);
        return (
          <>
            <CardContent>
              <FormGrid>
                <OrganizationSelect name="organization" label={t('models.organization')} options={organizations.valueLabelPairsManagerAccess} />
                <Autocomplete
                  searchable
                  name="capabilities"
                  label={t('models.permission_plural')}
                  options={organization?.permissionValueLabelPairs || []}
                  multiple
                />
              </FormGrid>
            </CardContent>
            <ModalActions
              saveLabel={t('base.buttons.create')}
              onClose={handleClose}
              onSave={handleSubmit}
              loading={tokens.isSyncing}
              disabled={invalid}
            />
          </>
        );
      }}
    />
  );
}

export default CreateApiForm;

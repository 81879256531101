/* eslint-disable react/require-default-props */
import { Wrapper } from '@googlemaps/react-wrapper';
import { FormControl, Box } from '@mui/material';
import { ItemTitle } from '~/pages/device_edit/components';
import FormGrid from '~/components/@material-extend/form_grid';
import { INode } from '~/mst/models/node';
import useLocales from '~/hooks/use_locales';

import LocationForm from './form';

const { VITE_GOOGLE_MAPS_KEY } = process.env;

function LocationModule({ node }: { node: INode }) {
  const { t } = useLocales();

  return (
    <FormGrid>
      <Box sx={{ mt: -2 }}>
        <ItemTitle>{t('device_monitor.forms.location')}</ItemTitle>
        <FormControl style={{ width: '100%', marginTop: 0 }}>
          <Wrapper apiKey={VITE_GOOGLE_MAPS_KEY!} libraries={['places', 'marker']}>
            <LocationForm node={node} />
          </Wrapper>
        </FormControl>
      </Box>
    </FormGrid>
  );
}

export default LocationModule;

import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { parse } from 'mathjs/lib/esm/number';
import { CardContent, Button, Grid, Typography, Stack } from '@mui/material';
import { useFormState, useForm } from 'react-final-form';
import useLocales from '~/hooks/use_locales';
import Select from '~/components/final_form/select';
import FormGrid from '~/components/@material-extend/form_grid';
import TextInput from '~/components/final_form/text_input';
import { ModalActions } from '~/components/modal';
import { INode } from '~/mst/models/node';
import I18n from '~/utils/i18n';

type FormProps = {
  handleCancel: VoidFunction;
  handleSubmit: VoidFunction;
  node: INode;
};

function getHtmlFromExpr(val: string) {
  try {
    if (val) {
      let expr = val.toString().replace(/\s/g, '');
      expr = expr.replace(/=/g, ` ${I18n.t('sings.equal')} `);
      expr = expr.replace(/\+-/g, ` ${I18n.t('sings.minus')} `);
      expr = expr.replace(/\+/g, ` ${I18n.t('sings.plus')} `);
      expr = expr.replace(/x\^(\d+)/g, (original, pow) => `x<sup>${pow}</sup>`);
      return expr;
    }
  } catch (e) {
    console.error(e);
  }
  return val;
}

export function AddStatisticalParameterForm({ handleCancel, handleSubmit, node }: FormProps) {
  const { t } = useLocales();
  const { change } = useForm();
  const [isTesting, setIsTesting] = useState(false);
  const [expr, setExpr] = useState(null);

  const {
    hasValidationErrors,
    values: { params, value }
  } = useFormState({ subscription: { hasValidationErrors: true, values: true } });

  useEffect(() => {
    if (isTesting && params?.length) {
      const paramsArr = params.split(',');
      const result = [];
      /* eslint-disable */
      for (let i = 0; i < paramsArr.length; i++) {
        if (paramsArr[i] !== '') {
          if (i === 0) {
            result.push(`${paramsArr[i]}`);
          } else if (i === 1) {
            result.push(`${paramsArr[i]} * x`);
          } else {
            result.push(`${paramsArr[i]} * x^${i}`);
          }
        }
      }
      /* eslint-enable */
      if (result.length) {
        try {
          setExpr(parse(`f(x) = ${result.join('+')}`));
        } catch (e) {
          console.error(e);
        }
      }
    }
  }, [params, isTesting]);

  useEffect(() => {
    try {
      if (expr && value != null) {
        const f = expr.compile().evaluate();
        change('result', f(value));
      }
    } catch (e) {
      console.error(e);
    }
  }, [value, expr, change]);

  return (
    <>
      <CardContent>
        <FormGrid>
          <Select label={t('thiamis.download_csv.parameters')} name="path" options={node?.data_points?.defaultValueLabelPairsByPath} />
          <TextInput name="params" label={t('sensor.data_point.calibration_params')} />
          <Stack justifyContent="center" alignItems="center">
            <Button variant="outlined" onClick={() => setIsTesting(!isTesting)}>
              Test
            </Button>
          </Stack>
          {isTesting && (
            <Grid container spacing={3}>
              <Grid item xs={12} md={3}>
                <TextInput name="value" label={t('sensor.data_point.value')} />
              </Grid>
              <Grid item xs={12} md={6} justifyContent="center" flexDirection="column" display="flex">
                <Typography
                  sx={{ color: 'text.secondary' }}
                  textAlign="center"
                  dangerouslySetInnerHTML={{ __html: getHtmlFromExpr(expr?.toString()) }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextInput name="result" label={t('sensor.data_point.result')} disabled />
              </Grid>
            </Grid>
          )}
        </FormGrid>
      </CardContent>
      <ModalActions onSave={handleSubmit} onClose={handleCancel} saveLabel={t('base.buttons.create')} disabled={hasValidationErrors} />
    </>
  );
}

export default observer(AddStatisticalParameterForm);

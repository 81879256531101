/* eslint-disable @typescript-eslint/no-use-before-define */
import { types as t, Instance } from 'mobx-state-tree';
import Sensor from '~/mst/models/device/sensor';
import BitConfigType from '~/mst/common/bit_config_type';

const AirmarSensor = t
  .compose(
    t.model({
      enabled: t.maybeNull(BitConfigType),
      precipitation_unit: t.maybeNull(t.string),
      pressure_unit: t.maybeNull(t.string),
      temperature_unit: t.maybeNull(t.string),
      windspeed_unit: t.maybeNull(t.string)
    }),
    Sensor
  )
  .named('Airmar');

export interface IAirmarSensor extends Instance<typeof AirmarSensor> {}

export default AirmarSensor;

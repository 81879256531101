/* eslint-disable import/prefer-default-export */
/* eslint-disable no-prototype-builtins */
import get from 'lodash/get';
import { useFormState } from 'react-final-form';
import { HeaderSensor } from '~/pages/device_edit/components';
import { SensorContainerStyled } from '~/pages/device_edit/components/styled';
import { ISensor } from '~/mst/models/device/sensor';

type SensorPropertyType = {
  name: string;
  children: React.ReactElement | React.ReactElement[];
};

export function SensorProperty({ name, children }: SensorPropertyType) {
  const { values } = useFormState({ subscription: { values: true } });

  if (get(values, name) !== undefined) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }
  return null;
}

type SensorContainerType = {
  sensor: ISensor;
  children: React.ReactElement | React.ReactElement[];
};

export function SensorContainer({ sensor, children }: SensorContainerType) {
  return (
    <SensorContainerStyled>
      <HeaderSensor sensor={sensor} />
      {children}
    </SensorContainerStyled>
  );
}

import React from 'react';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import { Stack } from '@mui/material';
import { DataTypeProvider } from '@devexpress/dx-react-grid';

import humanizer from '~/utils/humanize_duration';
import { DEVICE_STATUSES } from '~/utils/constants';
import StatusIcon from '~/components/status_icon';
import type { IDataPointNode } from '~/mst/models/data_point/node/default';
import withAutoupdate from '~/hocs/with_autoupdate';
import NavLink from '~/components/nav_link';
import useLocales from '~/hooks/use_locales';
import { getOnlineStatusFromAQ } from '~/mst/models/device/utils';

export const StatusIconWithAutoUpdate = withAutoupdate(
  observer(({ model, size = 'small', placement = 'top', label, showAQStatus = true }: { model: IDataPointNode }) => {
    const { t } = useLocales();
    let statusText = model?.statusText({ showAQStatus });
    const statusType = model?.statusType();
    if (statusType === DEVICE_STATUSES.OFFLINE) {
      const now = DateTime.now().toUTC().toMillis();
      const delta = now - model.lastTs;
      statusText = t('node_widget.last_time_reported', {
        value: model.lastTs ? humanizer.humanizeDuration(delta, { smallestUnit: 'min' }) : t('base.labels.n_a')
      });
    }
    return (
      <StatusIcon
        containerSx={{ height: '14px', alignItems: 'center' }}
        statusType={showAQStatus ? statusType : getOnlineStatusFromAQ(statusType)}
        size={size}
        statusText={statusText}
        placement={placement}
        label={label}
      />
    );
  })
);

export default function NameFormatter(props: DataTypeProvider.ValueFormatterProps) {
  const { row: model, value } = props;
  return (
    <Stack direction="row" alignItems="center">
      <StatusIconWithAutoUpdate model={model} placement="left" />
      <NavLink to={`devices/${model?.node_id}/monitor?parameter=${model?._id}`}>{value}</NavLink>
    </Stack>
  );
}

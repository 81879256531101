import { types as t, Instance } from 'mobx-state-tree';
import Report from '~/mst/models/report';
import Syncable from '~/mst/models/abstract/syncable';
import Fetchable from '~/mst/models/abstract/fetchable';
import Filterable from '~/mst/models/abstract/filterable';
import CollectionMap from '~/mst/models/abstract/collection_map';

const ReportsModel = t.compose(CollectionMap(Report), Syncable, Fetchable({ cache: true }), Filterable);

export interface IReportsModel extends Instance<typeof ReportsModel> {}

export default ReportsModel;

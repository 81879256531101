import { AnalyticsBrowser } from '@segment/analytics-next';

const { VITE_SEGMENT_WRITE_KEY, CI } = process.env;

let analytics;

(() => {
  if (CI) {
    analytics = AnalyticsBrowser.load({ writeKey: VITE_SEGMENT_WRITE_KEY });
  }
})();

export async function analyticsIdentify(userId, traits, options) {
  analytics?.identify(userId, traits, options);
}

export function analyticsTrack(...args) {
  analytics?.track(...args);
}

export function analyticsPage(...args) {
  analytics?.page(...args);
}

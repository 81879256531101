/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import { Field as FinalField, FieldMetaState } from 'react-final-form';
import { Switch, FormHelperText } from '@mui/material';
import { SxProps } from '@mui/system';
import toNumber from 'lodash/toNumber';
import { SwitchLabelStyled } from './styled';

interface Props {
  name: string;
  label?: string;
  parse?: any;
  format?: any;
  sx?: SxProps;
  disabled?: boolean;
  validate?: (value?: any, allValues?: Object, meta?: FieldMetaState<any>) => any;
  type?: string;
}

export default function RFFSwitch(props: Props) {
  const { name, label, parse, format, validate, type, ...rest } = props;
  return (
    <FinalField name={name} type="checkbox" validate={validate} {...(type === 'number' && { parse: toNumber })}>
      {({ input, meta }) => {
        const hasError = meta.touched && meta.error;
        return (
          <>
            <SwitchLabelStyled control={<Switch {...input} {...rest} />} label={label} labelPlacement="end" />
            {hasError && <FormHelperText>{hasError}</FormHelperText>}
          </>
        );
      }}
    </FinalField>
  );
}

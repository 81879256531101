/* eslint-disable no-param-reassign */
import { flow, applySnapshot, getIdentifier } from 'mobx-state-tree';
import api from '~/api';
import { toMapById } from '~/mst/utils';
import type { IDashboardsModel } from './model';

type CreateDashboardPayload = {
  organization_id: string;
  name: string;
  description?: string;
  configuration: any;
};

export default (self: IDashboardsModel) => ({
  fetch: flow(function* fetch(params?) {
    try {
      self.startFetching();
      const { data } = yield self.sync(api.dashboards.fetch, params);
      self.reset();
      applySnapshot(
        self.models,
        toMapById(
          data.map((dashboard) => ({
            ...dashboard,
            ...(dashboard.configuration && {
              configuration: {
                ...dashboard.configuration,
                widgets: dashboard.configuration?.widgets?.filter((widget) => !!widget.layout)
              }
            })
          })),
          'id'
        )
      );
      self.finishFetching();
    } catch (error) {
      self.failFetching(error);
    }
  }),
  destroy: flow(function* destroy(report) {
    report.startSyncing();
    try {
      yield api.dashboards.destroy(getIdentifier(report)!);
      self.remove(report);
    } catch (error) {
      report.failSyncing(error);
      throw new Error(error);
    }
  }),

  create: flow(function* create(payload: CreateDashboardPayload) {
    self.startSyncing();
    try {
      const { data } = yield api.dashboards.create(payload);
      self.models.put(data);
      self.finishSyncing();
      return data;
    } catch (error) {
      self.failSyncing(error);
    }
    return null;
  })
});

import React from 'react';
import { WidgetTypes } from '~/mst/models/widget/types';
import AddNodeWidget from '~/components/widgets/node_widget/configuration';
import AddHeatmapWidget from '~/components/widgets/heatmap_widget/configuration';
import AddChartWidget from '~/components/widgets/chart_widget/configuration';
import AddMapWidget from '~/components/widgets/map_widget/configuration';

type AddWidgetWrapperProps = {
  widgetType: WidgetTypes | null;
  open: boolean;
  onClose: () => void;
};

function AddWidgetWrapper({ widgetType, open, onClose }: AddWidgetWrapperProps) {
  switch (widgetType) {
    case WidgetTypes.NodeWidget:
      return <AddNodeWidget open={open} onClose={onClose} />;
    case WidgetTypes.ChartWidget:
      return <AddChartWidget open={open} onClose={onClose} />;
    case WidgetTypes.HeatmapWidget:
      return <AddHeatmapWidget open={open} onClose={onClose} />;
    case WidgetTypes.MapWidget:
      return <AddMapWidget open={open} onClose={onClose} />;
    default:
      return null;
  }
}

export default AddWidgetWrapper;

import { types as t, Instance } from 'mobx-state-tree';
import Range from '~/mst/models/range';
import Fetchable from '~/mst/models/abstract/fetchable';
import ChartTableWidget from './chart_table_widget';

const AlertMonitorModel = t.compose(
  t.model({
    range: t.optional(Range, () => Range.create('today')),
    alert_id: t.string,
    chartTableWidget: t.optional(ChartTableWidget, {})
  }),
  Fetchable()
);

export interface IAlertMonitorModel extends Instance<typeof AlertMonitorModel> {}

export default AlertMonitorModel;

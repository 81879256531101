/* eslint-disable no-param-reassign */
import { types as t, Instance } from 'mobx-state-tree';
import Syncable from '~/mst/models/abstract/syncable';
import CollectionArray from '~/mst/models/abstract/collection_array';
import UserCalibration from './user_calibration';

const UserCalibrationsModel = t.compose(CollectionArray(UserCalibration), Syncable);

export interface IUserCalibrationsModel extends Instance<typeof UserCalibrationsModel> {}

export default UserCalibrationsModel;

import { observer } from 'mobx-react-lite';

import { WidgetTypes } from '~/mst/models/widget/types';
import { WidgetPropType } from '~/components/widgets/common/types';
import TotalDevicesOnlineColumnChartWidget from './total_devices_online_column_chart_widget';
import TotalDevicesOnlinePieChartWidget from './total_devices_online_pie_chart_widget';
import RecentAlertsWidget from './recent_alerts_widget';
import DevicesOnlineChartWidget from './devices_online_chart_widget';

function WidgetWrapper({ widget, moveable, dashboard, editable }: WidgetPropType) {
  switch (widget.type) {
    case WidgetTypes.TotalDevicesOnlinePieChartWidget:
      return <TotalDevicesOnlinePieChartWidget moveable={moveable} editable={editable} widget={widget} dashboard={dashboard} />;
    case WidgetTypes.RecentAlertsWidget:
      return <RecentAlertsWidget moveable={moveable} editable={editable} widget={widget} dashboard={dashboard} />;
    case WidgetTypes.DevicesOnlineChartWidget:
      return <DevicesOnlineChartWidget moveable={moveable} editable={editable} widget={widget} dashboard={dashboard} />;
    case WidgetTypes.TotalDevicesOnlineColumnChartWidget:
      return <TotalDevicesOnlineColumnChartWidget moveable={moveable} editable={editable} widget={widget} dashboard={dashboard} />;
    default:
      return null;
  }
}

export default observer(WidgetWrapper);

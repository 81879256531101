import { alpha } from '@mui/material/styles';
import { STATUS_COLORS } from '../types';

// ----------------------------------------------------------------------

function createGradient(color1: string, color2: string) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

// SETUP COLORS
const GREY = {
  0: '#FFFFFF',
  100: '#EBEBEB',
  200: '#E0E0E0',
  300: '#D6D6D6',
  350: '#757575',
  400: '#CCCCCC',
  500: '#919EAB',
  600: '#B8B8B8',
  700: '#ADADAD',
  800: '#A3A3A3',
  900: '#999999',
  950: '#333333',
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8)
};

const PRIMARY = {
  lighter: '#d8e6ee',
  light: '#7eaec8',
  main: '#006092',
  dark: '#00507A',
  darker: '#003552',
  contrastText: '#fff',
  muted: '#637381'
};
const SECONDARY = {
  lighter: '#e2f5f9',
  light: '#9fdeed',
  main: '#42BFDD',
  dark: '#229FBF',
  darker: '#18748B',
  contrastText: '#fff',
  muted: '#637381'
};
const INFO = {
  lighter: '#edf1f0',
  light: '#c4d3cf',
  main: '#8AA8A1',
  dark: '#5F8179',
  darker: '#455E58',
  contrastText: '#fff',
  muted: '#637381'
};
const SUCCESS = {
  lighter: '#edefe7',
  light: '#c4ccb2',
  main: '#8A9B68',
  dark: '#6D7A52',
  darker: '#4C5639',
  contrastText: '#fff',
  muted: '#637381'
};
const WARNING = {
  lighter: '#fff1e0',
  light: '#ffd29a',
  main: '#FFA737',
  dark: '#F58B00',
  darker: '#B86800',
  contrastText: '#fff',
  muted: '#637381'
};
const ERROR = {
  lighter: '#fbe0e2',
  light: '#f29ba1',
  main: '#E63946',
  dark: '#DA1B2B',
  darker: '#A31420',
  contrastText: '#fff',
  muted: '#637381'
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main)
};

const CHART_COLORS = {
  violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
  blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
  green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
  yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
  red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4']
};

const COMMON = {
  common: { black: '#000', white: '#fff' },
  background: { paper: '#fff', default: '#fff', neutral: GREY[500_16] },
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
  info: { ...INFO },
  success: { ...SUCCESS },
  warning: { ...WARNING },
  error: { ...ERROR },
  grey: GREY,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: GREY[500_24],
  statuses: STATUS_COLORS,
  action: {
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48
  }
};

const defaultConfig = {
  name: 'environet',
  palette: {
    light: {
      ...COMMON
    },
    dark: {
      ...COMMON
    }
  }
};

export default defaultConfig;

import isString from 'lodash/isString';
import Yup from '~/utils/yup';

const condition = Yup.object().shape({
  value: Yup.string().required('Value is required'),
  deviceId: Yup.string().when('dataPointId', {
    is: (dataPointId) => isString(dataPointId),
    then: (schema) => schema.required('Device or Function is required')
  }),
  dataPointId: Yup.lazy((item) => {
    if (isString(item)) {
      return Yup.string().required('Parameter is required');
    }
    if (item.func === 'diff') {
      return condition.omit(['expr', 'value']).shape({
        dataPointId2: condition.omit(['expr', 'value'])
      });
    }
    return condition.omit(['expr']);
  }),
  expr: Yup.string().required('Expression is required')
});

export default Yup.object({
  organization_id: Yup.string().nullable().required('Organization is required'),
  name: Yup.string().required('Name is required'),
  data_points: Yup.array()
    .of(
      Yup.object({
        conditions: Yup.array().of(condition).nullable()
      })
    )
    .nullable()
});

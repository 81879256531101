/* eslint-disable react/jsx-props-no-spreading */
import { Field as FinalField } from 'react-final-form';
import ImageUpload from '~/components/image_upload';
import { FormHelperTextStyled } from './styled';

interface Props {
  name: string;
}

export default function RFFImageUpload(props: Props) {
  const { name } = props;
  return (
    <FinalField name={name}>
      {({ input, meta }) => {
        const hasError = meta.touched && meta.error;
        return (
          <div>
            <ImageUpload hasError={hasError} photo={input.value} setPhoto={input.onChange} />
            {hasError && <FormHelperTextStyled error>{hasError}</FormHelperTextStyled>}
          </div>
        );
      }}
    </FinalField>
  );
}

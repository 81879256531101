import { Link as RouterLink } from 'react-router-dom';

import { Box, Button, Typography, Container } from '@mui/material';

import PageNotFoundIllustration from '~/assets/illustrations/PageNotFoundIllustration';
import Page404S from './styled';

export default function Page404() {
  return (
    <Page404S title="404 Page Not Found">
      <Container>
        <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
          <Typography variant="h3" paragraph>
            Sorry, page not found!
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>
            Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check your spelling.
          </Typography>

          <PageNotFoundIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />

          <Button to="/" size="large" variant="contained" component={RouterLink}>
            Go to Home
          </Button>
        </Box>
      </Container>
    </Page404S>
  );
}

import { Instance } from 'mobx-state-tree';
import WidgetProcessor from '~/mst/models/widget/processor';
import actions from './actions';
import model from './model';
import base from './views/base';
import windRose from './views/wind_rose';

const ChartTableWidgetStore = model.views(base).views(windRose).actions(actions);

export interface IChartTableWidgetStore extends Instance<typeof ChartTableWidgetStore> {}

export default WidgetProcessor(ChartTableWidgetStore);

/* eslint-disable @typescript-eslint/no-use-before-define */
import { getParent, getSnapshot } from 'mobx-state-tree';
import defaults from 'lodash/defaults';
import omitBy from 'lodash/omitBy';
import isUndefined from 'lodash/isUndefined';

import isEmpty from 'lodash/isEmpty';

import I18n from '~/utils/i18n';
import { PATHS } from '~/utils/constants';
import type { INode } from '~/mst/models/node';
import type { IThiamisModel } from './model';

export default (self: IThiamisModel) => {
  function hasManualLocation() {
    return !isEmpty(self.location) && !isEmpty(self.location_description);
  }
  return {
    get formValues() {
      return defaults({}, omitBy(getSnapshot(self)!, isUndefined), self.configuration);
    },
    get hasManualLocation() {
      return hasManualLocation();
    },
    get locationType() {
      return hasManualLocation() ? 'manual' : 'auto';
    },
    get locationLatLng() {
      const node = getParent<INode>(self, 3);
      return hasManualLocation() ? self.location : node?.data_points?.getByPath(PATHS.LOCATION)?.lastValue;
    },
    get locationDescription() {
      const node = getParent<INode>(self, 3);
      return hasManualLocation() ? self.location_description : node?.data_points?.getByPath(PATHS.LOCATION_DESCRIPTION)?.lastValue;
    },
    get hasWiFi() {
      return !isEmpty(self?.wifi_ssid);
    },
    get modelName() {
      return I18n.t(`filters.filter-tags.device-type.${self.name.toLowerCase()}`, {
        defaultValue: self.name
      });
    }
  };
};

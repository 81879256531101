import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Form as FinalForm } from 'react-final-form';
import { computed } from 'mobx';
import pick from 'lodash/pick';
import { INode } from '~/mst/models/node';
import toasts from '~/utils/toasts';
import useLocales from '~/hooks/use_locales';

import { captureException } from '~/services/sentry';
import AirthinxForm from './airthinx_form';
import ThiamisForm from './thiamis_form';
import HealthwayForm from './healthway_form';

function DeviceContainer({ node }: { node: INode }) {
  const { t } = useLocales();

  const initialValues = useMemo(
    () =>
      computed(() => ({
        name: node?.name,
        description: node?.description,
        interval: node?.interval,
        thiamis: {
          ...node?.thiamis?.formValues,
          locationType: node?.thiamis?.locationType,
          hasWiFi: node?.thiamis?.hasWiFi
        }
      })),
    [node]
  ).get();

  const handleOnSubmit = useCallback(
    async (values) => {
      try {
        const { thiamis = {}, ...rest } = values;
        const payload = pick(rest, 'name', 'description', 'interval');
        await Promise.all([node.update(payload), node.thiamis.update(thiamis)]);
        toasts.success(t(`notifications.success.forms.update_node`));
      } catch (e) {
        captureException(e);
        toasts.error(t('notifications.errors.server_error'));
      }
    },
    [node, t]
  );

  return (
    <FinalForm onSubmit={handleOnSubmit} initialValues={initialValues} subscription={{ submitting: true, pristine: true, dirty: true }}>
      {({ handleSubmit }) => {
        if (node?.isAirthinx) {
          return <AirthinxForm node={node} handleSubmit={handleSubmit} />;
        }
        if (node?.isHealthway) {
          return <HealthwayForm node={node} handleSubmit={handleSubmit} />;
        }
        return <ThiamisForm node={node} handleSubmit={handleSubmit} />;
      }}
    </FinalForm>
  );
}

export default observer(DeviceContainer);

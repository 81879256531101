import some from 'lodash/some';
import escapeRegExp from 'lodash/escapeRegExp';
import lflow from 'lodash/fp/flow';
import without from 'lodash/fp/without';
import map from 'lodash/fp/map';
import { PATHS } from '~/utils/constants';
import type { IDebugWidgetModel } from './model';

export default (self: IDebugWidgetModel) => ({
  get node() {
    return self.nodes?.getById(self.node_id);
  },
  get filteredModels() {
    // @ts-ignore
    let models = self.node?.data_points?.getByPath(PATHS.DEBUG)?.measurements;
    if (self.query) {
      const keywords = lflow(
        // @ts-ignore
        without(''),
        map((keyword: string) => keyword.toLowerCase())
      )(self.query.split(/\s+/));
      models = models.filter(([, { message, tag }]) => {
        return some(keywords, (kw) => [message, tag].join(' ').toLowerCase().match(escapeRegExp(kw)));
      });
    }
    return models;
  },
  get filteredList() {
    return (
      self.filteredModels?.map(([ts, { message, tag }]) => ({
        ts,
        message,
        tag
      })) || []
    );
  }
});

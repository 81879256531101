import React from 'react';
import { Stack, Box } from '@mui/material';
import { observer } from 'mobx-react-lite';

import type { INodeWidget } from '~/mst/models/widget/node_widget';
import useLocales from '~/hooks/use_locales';
import { STATUS_COLORS } from '~/theme/types';
import { LegendItemStyled, LegendCircleStyled, LegendCircleBoxStyled } from './styled';

function HeatmapWidgetFooter({ widget }: { widget: INodeWidget }) {
  const { t } = useLocales();

  return (
    <Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }}>
      <Stack direction="row" spacing={1.5} alignItems="flex-end">
        {widget.legend?.map((status) => (
          <LegendItemStyled
            key={`${widget.id}_${status}`}
            onClick={() => widget.setFilter(widget.filter === status ? null : status)}
            direction="row"
            $isSelected={widget.filter === status}
          >
            <LegendCircleBoxStyled className="heatmap-legend-item-circle">
              <LegendCircleStyled sx={{ backgroundColor: STATUS_COLORS[status] }} />
            </LegendCircleBoxStyled>
            {t(`heatmap_legend.${status}`)}
          </LegendItemStyled>
        ))}
      </Stack>
      <Box>{widget?.selectedPath}</Box>
    </Stack>
  );
}

export default observer(HeatmapWidgetFooter);

/* eslint-disable */
import React from 'react';
import { IconProps } from './types';

export default function AnalyticsIcon({ sx }: IconProps) {
  return (
    <svg width={sx ? sx.width : '100%'} height={sx ? sx.height : '100%'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zM9 17H7v-5h2v5zm4 0h-2v-3h2v3zm0-5h-2v-2h2v2zm4 5h-2V7h2v10z" />
    </svg>
  );
}

import React, { FunctionComponent, ReactNode } from 'react';
import { observer } from 'mobx-react-lite';
import { CircularProgress } from '@mui/material';
import { SxProps } from '@mui/system';
import isEmpty from 'lodash/isEmpty';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import useFetch from '~/hooks/use_fetch';
import ResizeIcon from '~/assets/images/resize-corner-icon.svg?react';
import { IWidget } from '~/mst/models/widget';
import { IDashboard } from '~/mst/models/dashboard';

import {
  HandleBoxStyled,
  WidgetWrapperStyled,
  WidgetHeaderStyled,
  WidgetTitleStyled,
  WidgetLoadingContainerStyled,
  WidgetFooterStyled,
  WidgetBodyStyled,
  WidgetHeaderContainerStyled,
  Icon
} from './styled';
import WidgetActions from './widget_actions';

type ResizeHandleType = 's' | 'w' | 'e' | 'n' | 'sw' | 'nw' | 'se' | 'ne';

export type ResizeHandleProps = {
  handleAxis: ResizeHandleType;
};

export const ResizeHandle = React.forwardRef((props: ResizeHandleProps, ref) => {
  const { handleAxis, ...restProps } = props;

  return (
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    <HandleBoxStyled ref={ref} className={`handle-${handleAxis}`} {...restProps} title="Resize Widget">
      <ResizeIcon width="10px" />
    </HandleBoxStyled>
  );
});

export type WidgetContainerProps = {
  children: ReactNode;
  Header?: JSX.Element;
  sx?: SxProps;
  sxWidgetBody?: SxProps;
  Footer?: FunctionComponent<{ widget: IWidget }>;
  widget: IWidget;
  dashboard?: IDashboard;
  moveable?: boolean;
  editable?: boolean;
  onEdit?: () => void;
};

function WidgetContainer({
  Header,
  children,
  Footer,
  moveable,
  widget,
  onEdit,
  dashboard,
  sx = {},
  sxWidgetBody = {},
  editable
}: WidgetContainerProps) {
  const { name, title, isSyncing } = widget || {};

  const { isFetching, isFetched } = useFetch(widget, dashboard?.range?.fetchParams());

  return (
    <WidgetWrapperStyled $isSyncing={isSyncing || isFetching} sx={{ ...(!Footer && { pb: '20px' }), ...sx }}>
      <WidgetHeaderContainerStyled>
        <WidgetHeaderStyled
          title={Header || <WidgetTitleStyled>{isEmpty(name) ? title : name}</WidgetTitleStyled>}
          action={editable && <WidgetActions disabled={isSyncing} onEdit={onEdit} widget={widget} dashboard={dashboard} />}
        />
        {moveable && (
          <Icon title="Move Widget" className="action-move">
            <DragIndicatorIcon />
          </Icon>
        )}
      </WidgetHeaderContainerStyled>
      {isFetching || !isFetched ? (
        <WidgetLoadingContainerStyled>
          <CircularProgress size={30} color="info" />
        </WidgetLoadingContainerStyled>
      ) : (
        <WidgetBodyStyled sx={{ ...(Footer && { height: 'calc(100% - 92px)' }), ...sxWidgetBody }}>{children}</WidgetBodyStyled>
      )}

      {Footer && (
        <WidgetFooterStyled>
          <Footer widget={widget} />
        </WidgetFooterStyled>
      )}
    </WidgetWrapperStyled>
  );
}

export default observer(WidgetContainer);

import { observer } from 'mobx-react-lite';
import { FieldArrayRenderProps } from 'react-final-form-arrays';
import { Grid, Tooltip, Box } from '@mui/material';
import type { IAlert } from '~/mst/models/alert';
import { IconButtonAnimate } from '~/components/animate';
import { CircleRemoveSmallStyled, DividerStyled } from '../styled';
import type { ConditionType } from '../data_point';
import ExpressionField from './expression_field';
import ValueField from './value_field';
import ParameterField from './parameter_field';

type ConditionFieldType = FieldArrayRenderProps<ConditionType, HTMLInputElement> & {
  name: string;
  model: IAlert;
  index: number;
};

function ConditionField(props: ConditionFieldType) {
  const { name, model, fields, index } = props;

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12} md={7}>
        <Grid item container spacing={1}>
          <Grid item xs={2} md={1} alignItems="center" alignContent="center" sx={{ display: 'flex' }}>
            <Tooltip title={<span>Remove Condition</span>}>
              <IconButtonAnimate
                data-testid={`removeAndConditionBtn${index}`}
                onClick={() => {
                  fields.remove(index);
                }}
              >
                <CircleRemoveSmallStyled />
              </IconButtonAnimate>
            </Tooltip>
          </Grid>
          <Grid item xs={10} md={11}>
            <ParameterField name={name} model={model} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={2}>
        <ExpressionField model={model} name={name} />
      </Grid>
      <Grid item md={3}>
        <ValueField name={name} model={model} />
      </Grid>
      {fields.value.length - 1 !== index && (
        <Box alignItems="center" sx={{ width: '100%' }}>
          <DividerStyled orientation="vertical" flexItem>
            AND
          </DividerStyled>
        </Box>
      )}
    </Grid>
  );
}

export default observer(ConditionField);

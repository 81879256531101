/* eslint-disable no-param-reassign */
import { flow } from 'mobx-state-tree';
import request from '~/api/request';
import { IVersionModel } from './model';

export default (self: IVersionModel) => ({
  fetch: flow(function* fetch() {
    try {
      self.startFetching();
      const {
        headers: { 'last-modified': lastModified }
      } = yield request('head', '/', null, { api: false });
      self.finishFetching();
      return lastModified;
    } catch (error) {
      self.failFetching(error);
    }
    debugger; // eslint-disable-line no-debugger
    return null;
  }),
  setLastModified(lastModified) {
    self.last_modified = lastModified;
  }
});

import React from 'react';
import useLocales from '~/hooks/use_locales';
import useMst from '~/hooks/use_mst';

function RolesFormatter({ value }: { value: any }) {
  const { t } = useLocales();
  const { organizations } = useMst();
  if (value[0]?.organizationId) {
    const organization = organizations.getById(value[0].organizationId);
    return <span>{t(`users.roles.${value[0]?.role}_at`, { org_name: organization?.name || 'Unknown' })}</span>;
  }
}

export default RolesFormatter;

import { types as t, Instance, SnapshotOut } from 'mobx-state-tree';
import isEqual from 'lodash/isEqual';
import model from './model';
import views from './views';

const AlertSubscribers = model.views(views);

export interface IAlertSubscribers extends Instance<typeof AlertSubscribers> {}
export interface IAlertSubscribersSnapshotOut extends SnapshotOut<typeof AlertSubscribers> {}

export default t.snapshotProcessor(AlertSubscribers, {
  preProcessor(values) {
    const { email, sms } = values;
    if (isEqual(email, sms)) {
      return {
        users: email,
        notification_method: 'email_sms'
      };
    }
    if (sms?.length) {
      return {
        users: sms,
        notification_method: 'sms'
      };
    }
    if (email?.length) {
      return {
        users: email,
        notification_method: 'email'
      };
    }
    return {
      users: [],
      notification_method: 'email_sms'
    };
  }
});

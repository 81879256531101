import React, { useCallback, useState } from 'react';
import { IconButton, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ConfirmDialog from '~/components/confirm_dialog';
import ViewIcon from '@mui/icons-material/InsertChartOutlined';

import useLocales from '~/hooks/use_locales';
import toasts from '~/utils/toasts';
import useNavigate from '~/hooks/use_navigate';
import useActionsPopover from '~/hooks/react_grid/use_actions_popover';
import { IAlert } from '~/mst/models/alert';
import useMst from '~/hooks/use_mst';

const ICON = {
  mr: 2,
  width: 20,
  height: 20
};

function ActionsFormatter({ row }: { row: IAlert }) {
  const [isDeleting, setIsDeleting] = useState(false);
  const { t } = useLocales();
  const { isModifiable, isDeletable, isMonitorable, _id: alertId } = row;
  const { alerts } = useMst();
  const alert = alerts.getById(alertId);
  const { navigate } = useNavigate();

  const { ActionsMenu, handleOpen, handleClose } = useActionsPopover();

  const handleStartDelete = useCallback(() => {
    handleClose();
    setIsDeleting(true);
  }, [handleClose]);

  const handleCancelDelete = useCallback((event) => {
    event.stopPropagation();
    setIsDeleting(false);
  }, []);

  const handleModify = useCallback(
    (event) => {
      navigate(`alerts/${alertId}`);
      event.stopPropagation();
    },
    [navigate, alertId]
  );

  const handleMonitor = useCallback(
    (event) => {
      navigate(`alerts/${alertId}/monitor`);
      event.stopPropagation();
    },
    [navigate, alertId]
  );

  const handleDelete = useCallback(
    async (event) => {
      try {
        event.stopPropagation();
        await alert.destroy();
        toasts.success(t('notifications.success.forms.delete_alert'));
      } catch (e) {}
    },
    [alert, t]
  );

  return (
    <>
      {(isModifiable || isDeletable || isMonitorable) && (
        <IconButton onClick={handleOpen}>
          <MoreVertIcon />
        </IconButton>
      )}
      <ActionsMenu>
        {isMonitorable && (
          <MenuItem onClick={handleMonitor}>
            <ViewIcon sx={ICON} />
            Monitor
          </MenuItem>
        )}
        {isModifiable && (
          <MenuItem onClick={handleModify}>
            <EditIcon sx={ICON} />
            Edit
          </MenuItem>
        )}
        {isDeletable && (
          <MenuItem sx={{ color: 'error.main' }} onClick={handleStartDelete}>
            <DeleteIcon sx={ICON} />
            Delete
          </MenuItem>
        )}
      </ActionsMenu>
      <ConfirmDialog
        open={isDeleting}
        onClose={handleCancelDelete}
        onConfirm={handleDelete}
        title="Are you sure?"
        content={`Are you sure you want to delete this alert? You can't undo this action`}
        confirmLabel="Delete"
        confirmButtonType="error"
      />
    </>
  );
}

export default ActionsFormatter;

import { FieldArrayRenderProps } from 'react-final-form-arrays';
import type { IAlert } from '~/mst/models/alert';
import DataPoint from './data_point';
import type { ConditionType } from './data_point';

type ConditionsField = {
  conditions: ConditionType[];
};

type DataPointsFieldType = FieldArrayRenderProps<ConditionsField, HTMLInputElement> & {
  model: IAlert;
};

function DataPointsField(props: DataPointsFieldType) {
  const { fields, model } = props;
  return fields.map((name, index) => <DataPoint name={name} key={name} model={model} index={index} fields={fields} />);
}

export default DataPointsField;

/* eslint-disable react/require-default-props */
import { motion } from 'framer-motion';

import { alpha, useTheme } from '@mui/material/styles';
import { Box, GlobalStyles, SxProps } from '@mui/material';

import Logo from '../sidebar/logo';
import ProgressBar from '../progress_bar';
import LoadingRootStyled from './styled';

export function ProgressBarStyle() {
  const theme = useTheme();

  return (
    <GlobalStyles
      styles={{
        '#nprogress': {
          pointerEvents: 'none',
          '& .bar': {
            top: 0,
            left: 0,
            height: 2,
            width: '100%',
            position: 'fixed',
            zIndex: theme.zIndex.snackbar,
            backgroundColor: theme.palette.primary.main,
            boxShadow: `0 0 2px ${theme.palette.primary.main}`
          },
          '& .peg': {
            right: 0,
            opacity: 1,
            width: 100,
            height: '100%',
            display: 'block',
            position: 'absolute',
            transform: 'rotate(3deg) translate(0px, -4px)',
            boxShadow: `0 0 10px ${theme.palette.primary.main}, 0 0 5px ${theme.palette.primary.main}`
          }
        }
      }}
    />
  );
}

type Props = {
  isStarting?: boolean;
  sx?: SxProps;
};

export default function LoadingScreen({ isStarting, ...other }: Props) {
  return (
    <>
      <ProgressBar />

      {isStarting && (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <LoadingRootStyled {...other}>
          <Logo sx={{ width: '256px' }} />

          <Box
            component={motion.div}
            animate={{
              scale: [1, 2, 3],
              opacity: [0, 1, 0]
            }}
            transition={{
              ease: 'linear',
              duration: 1,
              repeat: Infinity
            }}
            sx={{
              width: 120,
              height: 120,
              borderRadius: '50%',
              position: 'absolute',
              border: (theme) => `solid 1px ${alpha(theme.palette.primary.light, 0.25)}`
            }}
          />
        </LoadingRootStyled>
      )}
    </>
  );
}

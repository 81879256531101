import { types as t, Instance } from 'mobx-state-tree';

const QueryModel = t
  .model({
    node_id: t.optional(t.string, ''),
    permissions: t.optional(t.string, ''),
    type: t.array(t.string)
  })
  .named('TokenQuery');

export interface IQueryModel extends Instance<typeof QueryModel> {}

export default QueryModel;

/* eslint-disable @typescript-eslint/naming-convention */
import type { IWidget } from '../widget';
import type { IWidgetsModel } from './model';

const BREAKPOINT_COLUMNS = {
  xl: 16,
  lg: 12,
  md: 8,
  sm: 4,
  xs: 4
};

export default (self: IWidgetsModel) => {
  function sortLayoutByPosition(a, b) {
    if (a.y < b.y) {
      return -1;
    }
    if (a.y > b.y) {
      return 1;
    }
    if (a.x < b.x) {
      return -1;
    }
    if (a.x > b.x) {
      return 1;
    }

    return 0;
  }
  function getLastWidgetPosition(layout, breakpoint, widgetWidth, widgetHeight) {
    layout.sort(sortLayoutByPosition);
    const lastWidget = layout[layout.length - 1];
    if (!lastWidget) {
      return {
        x: 0,
        y: 0,
        w: widgetWidth,
        h: widgetHeight
      };
    }
    if (lastWidget.x + lastWidget.w + widgetWidth <= BREAKPOINT_COLUMNS[breakpoint]) {
      return {
        x: lastWidget.x + lastWidget.w,
        y: lastWidget.y,
        w: widgetWidth,
        h: widgetHeight
      };
    }
    return {
      x: 0,
      y: lastWidget.y + lastWidget.h,
      w: widgetWidth,
      h: widgetHeight
    };
  }
  function getAddWidgetPosition(layout, breakpoint) {
    const lastPosition = getLastWidgetPosition(layout, breakpoint, 4, 3);
    return {
      ...lastPosition,
      i: 'new-widget',
      isResizable: false
    };
  }
  return {
    lastPositions(widgetWidth, widgetHeight) {
      const layout = {
        xl: [],
        lg: [],
        md: [],
        sm: [],
        xs: []
      };
      self.models.forEach((widget: IWidget) => {
        const widgetLayout = widget.breakpointLayouts;
        if (widgetLayout) {
          layout.xl.push(widgetLayout.xl);
          layout.lg.push(widgetLayout.lg);
          layout.md.push(widgetLayout.md);
          layout.sm.push(widgetLayout.sm);
          layout.xs.push(widgetLayout.xs);
        }
      });
      return {
        xl: getLastWidgetPosition(layout.xl, 'xl', widgetWidth, widgetHeight),
        lg: getLastWidgetPosition(layout.lg, 'lg', widgetWidth, widgetHeight),
        md: getLastWidgetPosition(layout.md, 'md', widgetWidth, widgetHeight),
        sm: getLastWidgetPosition(layout.sm, 'sm', 4, 3),
        xs: getLastWidgetPosition(layout.xs, 'xs', 4, 3)
      };
    },
    get ids() {
      return self.toArray.map(({ id }) => id);
    },
    get widgetLayouts() {
      const layout = {
        xl: [],
        lg: [],
        md: [],
        sm: [],
        xs: []
      };
      self.toArray.forEach((widget: IWidget) => {
        const widgetLayout = widget.breakpointLayouts;
        if (widgetLayout) {
          layout.xl.push(widgetLayout.xl);
          layout.lg.push(widgetLayout.lg);
          layout.md.push(widgetLayout.md);
          layout.sm.push(widgetLayout.sm);
          layout.xs.push(widgetLayout.xs);
        }
      });
      layout.xl.push(getAddWidgetPosition(layout.xl, 'xl'));
      layout.lg.push(getAddWidgetPosition(layout.lg, 'lg'));
      layout.md.push(getAddWidgetPosition(layout.md, 'md'));
      layout.sm.push(getAddWidgetPosition(layout.sm, 'sm'));
      layout.xs.push(getAddWidgetPosition(layout.xs, 'xs'));
      return layout;
    }
  };
};

import { Theme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface PaperPropsVariant {
    dashed: true;
    solid: true;
  }
}

declare module '@mui/material/Paper' {
  interface PaperPropsVariantOverrides {
    dashed: true;
    solid: true;
  }
}

export default function Paper(theme: Theme) {
  return {
    MuiPaper: {
      defaultProps: {
        elevation: 0
      },

      styleOverrides: {
        root: {
          backgroundImage: 'none',
          overflow: 'visible'
        }
      }
    }
  };
}

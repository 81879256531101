/* eslint-disable @typescript-eslint/no-use-before-define */
import { types as t, Instance } from 'mobx-state-tree';
import Sensor from '~/mst/models/device/sensor';

const SoundProSensor = t
  .compose(
    t.model({
      session_time: t.maybeNull(t.number),
      filters: t.maybeNull(t.array(t.string)),
      measurement_mode: t.maybeNull(t.string)
    }),
    Sensor
  )
  .named('SoundPro');

export interface ISoundProSensor extends Instance<typeof SoundProSensor> {}

export default SoundProSensor;

import React, { useState, useEffect, ReactNode, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { RedirectLoginOptions, LogoutOptions } from '@auth0/auth0-spa-js';
import { Stack } from '@mui/material';
import { observer } from 'mobx-react-lite';

import getAuth0Client from '~/services/auth0';
import { read as readUser } from '~/api/rest/users';
import { token } from '~/api/axios';
import auth from '~/mst/models/auth/store';
import useLocales from '~/hooks/use_locales';
import LoadingScreen from '~/components/loading_screen';

const CODE_RE = /[?&]code=[^&]+/;
const STATE_RE = /[?&]state=[^&]+/;
const ERROR_RE = /[?&]error=[^&]+/;

export const hasAuthParams = (searchParams = window.location.search) =>
  (CODE_RE.test(searchParams) || ERROR_RE.test(searchParams)) && STATE_RE.test(searchParams);

const stub = (): never => {
  throw new Error('You forgot to wrap your component in <AuthProvider>.');
};

const initialContext = {
  loginWithRedirect: stub,
  logout: stub,
  auth
};

export interface Auth0ContextInterface {
  loginWithRedirect?: (options?: RedirectLoginOptions) => Promise<void>;
  logout?: (options?: LogoutOptions) => void;
  auth: typeof auth;
}

const Auth0Context = React.createContext<Auth0ContextInterface>(initialContext);

type Auth0ProviderProps = {
  children: ReactNode;
};

const logoutDefaults = {
  logoutParams: {
    returnTo: `${window.location.origin}/login.html`
  }
};

const AuthProvider = observer(({ children }: Auth0ProviderProps) => {
  const navigate = useNavigate();
  const [client] = useState(getAuth0Client());
  const { changeLanguage } = useLocales();
  useEffect(() => {
    (async () => {
      try {
        auth.startFetching();
        if (hasAuthParams()) {
          await client.handleRedirectCallback();
          navigate('/');
        }
        await client.getTokenSilently();
        const { __raw: idToken } = await client.getIdTokenClaims();
        const user = await client.getUser();
        token.setToken(idToken);
        const { data } = await readUser(user?.sub!);
        auth.setUser(data);
        if (auth.locale) {
          changeLanguage(auth.locale);
        }
        auth.finishFetching();
      } catch (e) {
        if (e.error === 'login_required') {
          client.logout(logoutDefaults);
        } else if (e.error === 'missing_refresh_token') {
          client.loginWithRedirect();
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  const contextValue = useMemo(
    () => ({
      logout: (options) => {
        client.logout({ ...logoutDefaults, ...options });
      },
      loginWithRedirect: (...p) => client.loginWithRedirect(...p),
      auth,
      token
    }),
    [client]
  );

  if (token.isAuthorized() === false || auth.isFetching) {
    return (
      <Stack direction="column" height="100vh" alignItems="center" justifyContent="center">
        <LoadingScreen isStarting />
      </Stack>
    );
  }

  return <Auth0Context.Provider value={contextValue}>{children}</Auth0Context.Provider>;
});

export { AuthProvider, Auth0Context };

/* eslint-disable no-param-reassign */
import { types as t, Instance } from 'mobx-state-tree';
import DataPointNodeDefault from '~/mst/models/data_point/node/default';
import DataPointNodeMetric from '~/mst/models/data_point/node/metric';
import Fetchable from '~/mst/models/abstract/fetchable';
import Syncable from '~/mst/models/abstract/syncable';
import CollectionArray from '~/mst/models/abstract/collection_array';

const DataPointsNodeModel = t
  .compose(
    CollectionArray(
      t.union({
        dispatcher(snapshot) {
          const { _type: type } = snapshot;
          switch (type) {
            case 'metric':
              return DataPointNodeMetric;
            default:
              return DataPointNodeDefault;
          }
        }
      })
    ),
    Fetchable({ cache: true }),
    Syncable
  )
  .named('DataPointsNode');

export interface IDataPointsNodeModel extends Instance<typeof DataPointsNodeModel> {}

export default DataPointsNodeModel;

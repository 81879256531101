/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useMemo } from 'react';
import Yup, { searchableSelectSchema } from '~/utils/yup';
import { getSnapshot } from 'mobx-state-tree';
import { useParams } from 'react-router-dom';
import isString from 'lodash/isString';
import pick from 'lodash/pick';
import isEmpty from 'lodash/isEmpty';
import { computed } from 'mobx';
import { Form as FinalForm } from 'react-final-form';
import useMst from '~/hooks/use_mst';
import { useValidationSchema } from '~/hooks/use_validate';

import Modal from '~/components/modal';
import NodeWidgetStore, { INodeWidget } from '~/mst/models/widget/node_widget';
import { getOptionsForPayload } from '~/components/final_form/select/utils';
import { captureException } from '~/services/sentry';
import toasts from '~/utils/toasts';
import useLocales from '~/hooks/use_locales';
import useFetch from '~/hooks/use_fetch';
import NodeWidgetForm from './form';

type NodeWidgetConfigurationProps = {
  open: boolean;
  onClose: () => void;
  widget?: INodeWidget;
};

const AddEditNodeSchema = Yup.object().shape({
  name: searchableSelectSchema('Name is required'),
  node_id: Yup.array().required('Required').min(1, 'Required'),
  selected_data_point_ids: Yup.array().required('Required').min(1, 'Required')
});

function NodeWidgetConfiguration({ open, onClose, widget }: NodeWidgetConfigurationProps) {
  const { dashboards, nodes } = useMst();
  const { id } = useParams();
  const { t } = useLocales();
  const dashboard = dashboards.getById(id);
  const isNew = !widget;

  useFetch(nodes, { organization_id: dashboard?.organization_id });

  const initialValues = useMemo(
    () =>
      computed(() => {
        if (isNew) {
          return {};
        }
        const values = pick(getSnapshot(widget), 'name', 'selected_data_point_ids');
        values.node_id = widget.nodeId;
        if (isEmpty(values.name)) {
          values.name = 'Device widget';
        }
        return values;
      }),
    [widget, isNew]
  ).get();

  const validate = useValidationSchema(AddEditNodeSchema);

  const handleSave = useCallback(
    (values) => {
      try {
        const mappedDataPoints = getOptionsForPayload(values.selected_data_point_ids);
        const mappedNodeIds = getOptionsForPayload(values.node_id);
        const paylaod = {
          name: values.name,
          node_id: mappedNodeIds,
          selected_data_point_ids: mappedDataPoints
        };
        if (widget) {
          widget.update(paylaod);
        } else {
          const newWidget = NodeWidgetStore.create(paylaod);
          const breakpoints = dashboard.widgets.lastPositions(4, 3);
          newWidget.setLayout(breakpoints);
          dashboard.widgets.create(newWidget);
        }
      } catch (e) {
        captureException(e);
        toasts.error(t('notifications.errors.server_error'));
      }
    },
    [widget, dashboard, t]
  );

  return (
    <Modal open={open} handleClose={onClose} title={isNew ? t('models.node_widget_create') : t('models.node_widget_update')}>
      <FinalForm onSubmit={handleSave} initialValues={initialValues} validate={validate}>
        {({ handleSubmit }) => <NodeWidgetForm isNew={isNew} onClose={onClose} onSave={handleSubmit} />}
      </FinalForm>
    </Modal>
  );
}

NodeWidgetConfiguration.defaultProps = {
  widget: undefined
};

export default NodeWidgetConfiguration;

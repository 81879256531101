/* eslint-disable no-param-reassign */
import I18n from '~/utils/i18n';
import sortBy from 'lodash/sortBy';
import { CALIBRATION_STATUSES } from '~/utils/constants';
import type { ICalibrationModel } from '../model';

export function getStatus(calibration) {
  const { calibrated, timestamp } = calibration;
  if (timestamp) {
    return calibrated ? CALIBRATION_STATUSES.COMPLETED : CALIBRATION_STATUSES.FAILED;
  }
  return CALIBRATION_STATUSES.NOT_RUN;
}

export default (self: ICalibrationModel) => {
  return {
    get status() {
      return getStatus(self);
    },
    get statusType() {
      return self.status;
    },
    get statusText() {
      return I18n.t(`attributes.calibration.statuses.${self.status}`);
    },
    get parameters() {
      return sortBy(self.measurements, 'name');
    },
    get valueLabelPair() {
      return { label: self.serial, value: self.serial, model: self };
    }
  };
};

import { types as t, Instance, SnapshotOut } from 'mobx-state-tree';
import WidgetModel from '~/mst/models/widget';

const FilesWidgetModel = t
  .compose(
    WidgetModel,
    t.model({
      type: 'FilesWidget',
      serial: t.maybe(t.frozen(t.string)),
      files: t.frozen()
    })
  )
  .named('FilesWidget');

export interface IFilesWidgetModel extends Instance<typeof FilesWidgetModel> {}
export interface IFilesWidgetModelSnapshotOut extends SnapshotOut<typeof FilesWidgetModel> {}

export default FilesWidgetModel;

import { useEffect } from 'react';
import { analyticsIdentify } from '~/services/segment';
import { sentryIdentity } from '~/services/sentry';
import { datadogIdentity } from '~/services/datadog';
import useAuth from '~/hooks/use_auth';

const useAnalytics = () => {
  const {
    auth: { user }
  } = useAuth();
  useEffect(() => {
    if (user?.id) {
      analyticsIdentify(
        user?.id,
        {
          email: user?.email,
          name: user?.fullName,
          external_id: user?.external_id
        },
        {
          Intercom: {
            user_hash: user?.intercom_identity
          }
        }
      );
      sentryIdentity(user);
      datadogIdentity(user);
    }
  }, [user]);
};

export default useAnalytics;

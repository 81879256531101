import { TableCell } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { computed } from 'mobx';

import { DetailRowStyled } from '~/components/react_grid/styled';
import useFetch from '~/hooks/use_fetch';
import useMst from '~/hooks/use_mst';
import ChartStock from '~/components/chart';
import { getZones } from '~/components/chart/chart_options';
import { IAlertDurationsTableRow } from '../../../store/chart_table_widget/durations_table_row';

const EXTRA_MINUTES = 30 * 60 * 1000;

function GridDetailContainer({ row: model }: { row: IAlertDurationsTableRow }) {
  const { alert_id: alertId, data_point_id: alertDataPointId, from, to } = model;
  const { alerts } = useMst();
  const alert = alerts.getById(alertId);
  const alertDataPoint = alert.data_points.getById(alertDataPointId);

  const { isFetching } = useFetch(model, { data_point_id: alertDataPoint?.linked, from: from - EXTRA_MINUTES, to: to + EXTRA_MINUTES });
  const chartData = useMemo(
    () =>
      computed(() =>
        model?.measurements?.toArray.map(({ data_point_id: dataPointId, data }) => {
          const dataPoint = alert?.nodes.getNodeByDataPointId(dataPointId)?.data_points?.getById(dataPointId);
          const condition = alertDataPoint.conditions.find((c) => c.dataPointId === dataPointId);
          return {
            ...dataPoint,
            ...(condition && { zones: getZones([{ [condition.expr]: condition.value, status: 0 }], { 0: 'Poor' }) }),
            presentName: dataPoint?.presentName,
            measurements: data?.toJSON()?.map((measurement) => measurement?.toJSON()),
            shortPath: dataPoint?.shortPath
          };
        })
      ),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  ).get();

  return (
    <DetailRowStyled>
      <TableCell />
      <TableCell colSpan={4}>
        <ChartStock data={chartData} height="380px" isLoading={isFetching} />
      </TableCell>
    </DetailRowStyled>
  );
}

export default observer(GridDetailContainer);

import { types as t, Instance } from 'mobx-state-tree';
import NodeWidget from '~/mst/models/widget/node_widget';
import ChartWidget from '~/mst/models/widget/chart_widget';
import HeatmapWidget from '~/mst/models/widget/heatmap_widget';
import MapWidget from '~/mst/models/widget/map_widget';
import BaseWidget from '~/mst/models/widget';
import Syncable from '~/mst/models/abstract/syncable';
import CollectionMap from '~/mst/models/abstract/collection_map';

const WidgetsModel = t.compose(
  CollectionMap(
    t.union(
      {
        dispatcher(snapshot) {
          const { type } = snapshot;
          switch (type) {
            case 'NodeWidget':
              return NodeWidget;
            case 'HeatmapWidget':
              return HeatmapWidget;
            case 'MapWidget':
              return MapWidget;
            case 'ChartWidget':
              return ChartWidget;
            default:
              return BaseWidget;
          }
        }
      },
      ChartWidget,
      HeatmapWidget,
      ChartWidget,
      NodeWidget,
      MapWidget
    )
  ),
  Syncable
);

export interface IWidgetsModel extends Instance<typeof WidgetsModel> {}

export default WidgetsModel;

/* eslint-disable no-param-reassign */
import { types as t, Instance } from 'mobx-state-tree';
import Syncable from '~/mst/models/abstract/syncable';
import Fetchable from '~/mst/models/abstract/fetchable';
import { ModelV1 as Model } from '~/mst/models/abstract/model';
import NodeBase from '~/mst/models/abstract/node_base';
import DataPointsAlert from '~/mst/models/data_points/alert';
import NodesStore from '~/mst/models/nodes';
import SubscribersModel from './subscribers';

const AlertModel = t
  .compose(
    t.model({
      _type: 'alert',
      data_points: t.maybeNull(DataPointsAlert),
      status: t.optional(t.enumeration('Status', ['activated', 'deactivated', 'idle']), 'idle'),
      subscribers: t.optional(SubscribersModel, {}),
      nodes: t.maybeNull(NodesStore)
    }),
    Model,
    NodeBase,
    Syncable,
    Fetchable({ cache: true })
  )
  .named('Alert');

export interface IAlertModel extends Instance<typeof AlertModel> {}

export default AlertModel;

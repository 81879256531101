/* eslint-disable no-param-reassign */
import { types as t, Instance } from 'mobx-state-tree';

export const NOTIFICATION_METHODS = ['email', 'sms', 'email_sms'];

const SubscribersModel = t.model({
  users: t.array(t.string),
  notification_method: t.optional(t.enumeration('Type', NOTIFICATION_METHODS), 'email')
});

export interface ISubscribersModelModel extends Instance<typeof SubscribersModel> {}

export default SubscribersModel;

/* eslint-disable no-param-reassign */
import { flow } from 'mobx-state-tree';
import api from '~/api';
import type { IDevicesOnlineChartWidgetModel } from './model';

export default (self: IDevicesOnlineChartWidgetModel) => ({
  fetch: flow(function* fetch(params = {}) {
    try {
      self.startFetching();
      const { data } = yield self.sync(api.search.online, params);
      self.data = data;
      self.finishFetching();
    } catch (error) {
      self.failFetching(error);
    }
  })
});

import { useCallback } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Button, Grid, Stack } from '@mui/material';
import useAuth from '~/hooks/use_auth';
import useLocales from '~/hooks/use_locales';
import useMst from '~/hooks/use_mst';

import Filters from '~/components/filters';

function FilterBar() {
  const { users } = useMst();
  const { t } = useLocales();
  const { isManager } = useAuth();

  const handleCreateNew = useCallback(() => {
    // TO DO: redirect to create form
  }, []);

  return (
    <Grid container spacing={2} sx={{ py: 2, px: 3 }}>
      <Grid item xs={12} sm={4} lg={6} md={6}>
        <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }}>
          <Filters collection={users} />
        </Stack>
      </Grid>
      <Grid item xs={12} sm={8} lg={6} md={6} sx={{ textAlign: 'right' }}>
        {isManager && (
          <Button variant="contained" size="large" startIcon={<AddIcon />} onClick={handleCreateNew}>
            {t('users.create_new')}
          </Button>
        )}
      </Grid>
    </Grid>
  );
}

export default FilterBar;

/* eslint-disable no-param-reassign */
import { flow, applySnapshot } from 'mobx-state-tree';
import api from '~/api';
import { toMapById } from '~/mst/utils';
import type { IPartnership } from '../partnership';
import type { IPartnershipsModel } from './model';

export default (self: IPartnershipsModel) => ({
  fetch: flow(function* fetch(params, options) {
    try {
      self.startFetching();
      const { data } = yield self.sync(api.partnerships.fetch, params, options);
      applySnapshot(self.models, toMapById(data, 'id'));
      self.finishFetching();
    } catch (error) {
      self.failFetching(error);
    }
  }),
  create: flow(function* create(payload: { external_id: string; organization_id: string }) {
    self.startSyncing();
    try {
      const { data } = yield api.partnerships.create({ ...payload, role: 'partner' });
      self.models.put(data);
      self.finishSyncing();
    } catch (error) {
      self.failSyncing(error);
    }
  }),
  destroy: flow(function* destroy(model: IPartnership) {
    model.startSyncing();
    try {
      yield api.partnerships.destroy(model.organizationId, { external_id: model.partnerExternalId });
      model.finishSyncing();
      self.remove(model);
    } catch (error) {
      self.failSyncing(error);
    }
  })
});

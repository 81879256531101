import { types as t, Instance } from 'mobx-state-tree';
import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';
import { getStore } from '~/mst/utils';
import { ModelV1 as Model } from '~/mst/models/abstract/model';

const WebhookConditionsModel = t
  .compose(
    t.model({
      node_id: t.optional(t.array(t.string), []),
      _type: t.optional(t.array(t.string), [])
    }),
    Model
  )
  .views((self) => ({
    get nodes() {
      return compact(self.node_id.map((id) => getStore(self).nodes.getById(id)));
    },
    get type() {
      return isEmpty(self._type) ? null : self._type[0];
    }
  }))
  .named('WebhookCondition');

export interface IWebhookConditionsModel extends Instance<typeof WebhookConditionsModel> {}

export default WebhookConditionsModel;

import { Instance } from 'mobx-state-tree';
import model from './model';
import actions from './actions';
import views from './views';

const StatsStore = model.views(views).actions(actions);

export interface IStatsStore extends Instance<typeof StatsStore> {}

export default StatsStore;

const mapInlineStyles = {
  '.widget-map_popover_content': {
    minWidth: '200px',
    padding: '0 !important'
  },
  '.widget-map_popover_details__container': {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    gap: '10px'
  },
  '.widget-map_popover_model': {
    fontWeight: 'bold',
    fontSize: '14px'
  },
  '.widget-map_status': {
    display: 'block'
  },
  '.widget-map_status__wrapper': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '0.5rem',
    justifyContent: 'flex-start'
  },
  '[class^=widget-map_status__solid_icon]': {
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    position: 'relative',
    lineHeight: 1,
    flex: '0 0 10px'
  },
  '[class^=widget-map_status__half_icon]': {
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    position: 'relative',
    backgroundColor: 'transparent',
    border: '3px solid',
    lineHeight: 1,
    flex: '0 0 10px'
  },
  '.widget-map_status__solid_icon': {
    '&--online': {
      backgroundColor: '#68CE7C'
    },
    '&--offline': {
      backgroundColor: '#AAAAAA'
    },
    '&--deactivated': {
      backgroundColor: '#AAAAAA'
    },
    '&--good': {
      backgroundColor: '#047AF4'
    },
    '&--moderate': {
      backgroundColor: '#F7BF4E'
    },
    '&--poor': {
      backgroundColor: '#D4484C'
    }
  },
  '.widget-map_status__half_icon': {
    '&--offline': {
      borderColor: '#AAAAAA'
    }
  },
  '.aq-body_name': {
    fontWeight: 'normal',
    fontSize: '9px',
    lineHeight: '1',
    color: '#fff'
  },
  '.aq-body_value': {
    fontSize: '11px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    textAlign: 'center'
  },
  '.aq-badge': {
    flex: '0 0 80px',
    width: '80px',
    height: '80px',
    borderRadius: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  '.aq-icon': {
    width: '40px',
    height: '40px'
  },
  '.widget-map_icon__thiamis': {
    width: '100px',
    height: '100px'
  },
  '.state-good': {
    background: 'linear-gradient(180deg, #01C2F7 0%, #047AF4 100%)',
    '.aq-body_value': {
      color: '#fff'
    }
  },
  '.state-moderate': {
    background: 'linear-gradient(rgba(255, 255, 255, 0.35) 0%, rgba(255, 255, 255, 0) 100%) rgb(255, 171, 32)',
    '.aq-body_value': {
      color: '#fff'
    }
  },
  '.state-poor': {
    background: 'linear-gradient(rgba(255, 255, 255, 0.35) 0%, rgba(255, 255, 255, 0) 100%) rgb(238, 60, 60)',
    '.aq-body_value': {
      color: '#fff'
    }
  },
  '.state-offline': {
    background: 'linear-gradient(rgba(255, 255, 255, 0.35) 0%, rgba(255, 255, 255, 0) 100%) #5a5a5a',
    '.aq-body_value': {
      color: '#fff'
    }
  },
  '.widget-map_popover_title': {
    fontSize: '16px',
    fontWeight: 'bold',
    paddingBottom: '10px',
    textAlign: 'center'
  }
};

export default mapInlineStyles;

/* eslint-disable @typescript-eslint/no-use-before-define */
import { getParent } from 'mobx-state-tree';
import type { INode } from '~/mst/models/node';
import type { IHealthwayModel } from './model';

export default (self: IHealthwayModel) => ({
  get nodeIds() {
    const node: INode = getParent(self, 3);
    return node?.templates && node.templates[0]?.configuration?.node_id;
  }
});

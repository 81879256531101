/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable react/require-default-props */
import React, { useCallback } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import uploadcare from 'uploadcare-widget';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { useTheme } from '@mui/material/styles';
import useLocales from '~/hooks/use_locales';
import { ImageUploadInnerStyled, ImageUploadWrapperStyled } from './styled';

type PhotoUploadType = {
  photo?: string;
  setPhoto: void;
  cropConfig?: string;
  uploadcareKey?: string;
  hasError?: boolean;
};

const PhotoUpload = React.memo(
  ({ hasError, photo, setPhoto, cropConfig = '1:1', uploadcareKey = process.env.VITE_UPLOADCARE_PUBLIC_KEY }: PhotoUploadType) => {
    const { t } = useLocales();
    const theme = useTheme();
    const handleChangePicture = useCallback(
      () =>
        uploadcare
          .openDialog(null, {
            publicKey: uploadcareKey,
            crop: cropConfig,
            imagesOnly: true
          })
          .done((file) =>
            file.promise().done((fileInfo) => {
              const { originalUrl, crop } = fileInfo;
              const url = `${originalUrl}-/crop/${crop.width}x${crop.height}/${crop.left},${crop.top}/`;
              setPhoto(url);
            })
          ),
      [setPhoto, cropConfig, uploadcareKey]
    );
    return (
      <>
        <Box role="button" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={handleChangePicture}>
          {photo ? (
            <ImageUploadWrapperStyled $hasError={hasError}>
              <ImageUploadInnerStyled>
                <img className="img-responsive" src={photo} alt="" />
              </ImageUploadInnerStyled>
            </ImageUploadWrapperStyled>
          ) : (
            <ImageUploadWrapperStyled $hasError={hasError}>
              <ImageUploadInnerStyled>
                <Stack direction="column" alignItems="center" spacing={1}>
                  <AddAPhotoIcon />
                  <Typography variant="body3">{` ${t('user_profile.change_picture')}`}</Typography>
                </Stack>
              </ImageUploadInnerStyled>
            </ImageUploadWrapperStyled>
          )}
        </Box>
        <Typography variant="body3" color={theme.palette.grey[900]} sx={{ display: 'block', textAlign: 'center', marginTop: theme.spacing(2) }}>
          Allowed *.jpeg, *.jpg, *.png, *.gif max size of 3.1 MB
        </Typography>
      </>
    );
  }
);

export default PhotoUpload;

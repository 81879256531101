/* eslint-disable import/prefer-default-export */
import { Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

export const MeasurementsContainer = styled(Paper)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  flexWrap: 'wrap',
  listStyle: 'none',
  p: 0.5,
  m: 0
}));

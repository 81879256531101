/* eslint-disable @typescript-eslint/naming-convention */
import type { IWebhooksModel } from './model';

export default (self: IWebhooksModel) => {
  return {
    get filteredList() {
      return self.filteredModels;
    }
  };
};

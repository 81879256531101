import { types as t, Instance } from 'mobx-state-tree';

const RangeModel = t.model({
  timezone: t.maybeNull(t.string),
  from: t.union(t.string, t.number),
  to: t.maybeNull(t.maybeNull(t.union(t.string, t.number)))
});

export interface IRangeModel extends Instance<typeof RangeModel> {}

export default RangeModel;

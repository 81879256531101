/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import { Field as FinalField, FieldMetaState } from 'react-final-form';
import { SxProps } from '@mui/system';
import isString from 'lodash/isString';
import { RadioGroup, FormLabel, FormControlLabel, Radio, FormHelperText, FormControl } from '@mui/material';
import { ValueLabelPair } from '~/mst/models/abstract/collection_types';

interface Props {
  name: string;
  label?: string | Element;
  sx?: SxProps;
  disabled?: boolean;
  type?: string;
  row?: boolean;
  validate?: (value?: any, allValues?: Object, meta?: FieldMetaState<any>) => any;
  options: ValueLabelPair[];
  parse?: (string) => any;
}

export default function RFFRadio(props: Props) {
  const { name, label, parse, options, row = true, validate, ...rest } = props;

  return (
    <FormControl>
      {label && isString(label) ? <FormLabel>{label}</FormLabel> : label}
      <RadioGroup row={row} {...rest}>
        {options?.map((option) => (
          <FinalField name={name} type="radio" value={option.value} key={option.value} validate={validate} parse={parse}>
            {({ input, meta }) => {
              const hasError = meta.touched && meta.error;
              return (
                <>
                  <FormControlLabel control={<Radio />} label={option.label} {...input} />
                  <FormHelperText>{hasError}</FormHelperText>
                </>
              );
            }}
          </FinalField>
        ))}
      </RadioGroup>
    </FormControl>
  );
}

import { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { List, Collapse } from '@mui/material';
import { NavItemSub } from './nav_item';
import { NavListSubProps } from './type';
import { getActive } from '../config';

function NavListSub({ list, root }: NavListSubProps) {
  const { pathname } = useLocation();
  const { slug } = useParams();
  const childrenPath = `${root}/${list.path}`;

  const active = getActive(slug!, childrenPath, pathname);

  const [open, setOpen] = useState(active);

  if (list.children) {
    return (
      <>
        <NavItemSub item={list} root={root} onOpen={() => setOpen(!open)} open={open} active={active} />

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding sx={{ pl: 3 }}>
            {list.children?.map((item) => <NavItemSub key={item.title} item={item} root={root} active={getActive(slug!, item.path, pathname)} />)}
          </List>
        </Collapse>
      </>
    );
  }

  return <NavItemSub root={root} item={list} active={active} />;
}

export default NavListSub;

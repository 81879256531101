import { Instance, types as t, SnapshotOut } from 'mobx-state-tree';
import omit from 'lodash/omit';
import isString from 'lodash/isString';
import actions from './actions';
import model from './model';
import views from './views';

const ChartWidgetStore = model.views(views).actions(actions);

export interface IChartWidgetStore extends Instance<typeof ChartWidgetStore> {}
export interface IChartWidgetStoreSnapshotOut extends SnapshotOut<typeof ChartWidgetStore> {}

export default t.snapshotProcessor(ChartWidgetStore, {
  postProcessor(sn) {
    return omit(sn, ['nodes']);
  },
  preProcessor(values: any) {
    if (isString(values.node_id)) {
      return {
        ...values,
        node_id: [values.node_id]
      };
    }
    return values;
  }
});

/* eslint-disable no-param-reassign */
import { types as t, Instance } from 'mobx-state-tree';
import Certificate from '~/mst/models/certificate';
import Fetchable from '~/mst/models/abstract/fetchable';
import Filterable from '~/mst/models/abstract/filterable';
import CollectionMap from '~/mst/models/abstract/collection_map';

const CertificatesModel = t.compose(CollectionMap(Certificate), Fetchable({ cache: true }), Filterable);

export interface ICertificatesModel extends Instance<typeof CertificatesModel> {}

export default CertificatesModel;

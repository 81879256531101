/* eslint-disable @typescript-eslint/no-use-before-define */
import { types as t, Instance } from 'mobx-state-tree';
import Sensor from '~/mst/models/device/sensor';
import BitConfigType from '~/mst/common/bit_config_type';

const AlphaSenseSensor = t.compose(
  t.model({
    enabled: t.maybeNull(t.union(t.null, BitConfigType)),
    pm1_stel_alert: t.maybeNull(t.union(t.null, t.number, t.string)),
    pm25_stel_alert: t.maybeNull(t.union(t.null, t.number, t.string)),
    pm10_stel_alert: t.maybeNull(t.union(t.null, t.number, t.string)),
    stel_timeout: t.maybeNull(t.union(t.null, t.number, t.string))
  }),
  Sensor
);

export interface IAlphaSenseSensor extends Instance<typeof AlphaSenseSensor> {}

export default AlphaSenseSensor;

/* eslint-disable no-param-reassign */
import { types as t, Instance } from 'mobx-state-tree';
import Template from '~/mst/models/template';
import Fetchable from '~/mst/models/abstract/fetchable';
import CollectionMap from '~/mst/models/abstract/collection_map';

const TemplatesModel = t.compose(CollectionMap(Template), Fetchable({ cache: true }));

export interface ITemplatesModel extends Instance<typeof TemplatesModel> {}

export default TemplatesModel;

import React, { useMemo } from 'react';
import { Grid, Table, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { observer } from 'mobx-react-lite';
import { computed } from 'mobx';
import { getIdentifier } from 'mobx-state-tree';

import useMst from '~/hooks/use_mst';
import I18n from '~/utils/i18n';
import { IUser } from '~/mst/models/user';
import type { UserOrganizationRoleRow } from '~/mst/models/user/types';
import { ContainerGridStyled, GridStyled } from '~/components/react_grid/styled';
import { ActionsProvider, OrganizationProvider, RoleProvider } from './info_providers';

const columns = [
  { name: 'organizationName', title: I18n.t('attributes.organization.fullname') },
  { name: 'role', title: I18n.t('attributes.user.role') },
  { name: 'actions', title: ' ', sortingEnabled: false }
];

function AccessForm({ user }: { user: IUser }) {
  const { organizations } = useMst();
  const rows: UserOrganizationRoleRow[] = useMemo(
    () =>
      computed(() =>
        user.organizationsRoles
          .filter(({ organizationId }) => !!organizations.getById(organizationId))
          .map(({ organizationId, ...rest }) => {
            const organization = organizations.getById(organizationId);
            return {
              ...rest,
              organizationId,
              userId: getIdentifier(user),
              externalId: organization.external_id,
              organizationName: organization.name
            };
          })
      ),
    [organizations, user]
  ).get();

  return (
    <ContainerGridStyled
      sx={{
        '.TableContainer-root': {
          height: 'auto'
        }
      }}
    >
      <GridStyled>
        <Grid rows={rows} columns={columns}>
          <OrganizationProvider for={['organizationName']} />
          <RoleProvider for={['role']} />
          <ActionsProvider for={['actions']} />
          <Table
            columnExtensions={[
              { columnName: 'role', align: 'center', width: '120px' },
              { columnName: 'actions', width: 65 }
            ]}
          />
          <TableHeaderRow />
        </Grid>
      </GridStyled>
    </ContainerGridStyled>
  );
}

export default observer(AccessForm);

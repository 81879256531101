import { Box, CircularProgress } from '@mui/material';

function LoadingBox() {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="40vh" sx={{ width: '100%' }}>
      <CircularProgress size={25} color="info" />
    </Box>
  );
}

export default LoadingBox;

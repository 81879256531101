import { types as t, applySnapshot, Instance, detach, isAlive } from 'mobx-state-tree';
import { ModelV1 as Model } from './model';
import views from './collection_views';

const CollectionArrayFactory = (modelType) =>
  t
    .model({
      models: t.array(modelType)
    })
    .actions((self) => {
      function detachModels() {
        if (isAlive(self.models)) {
          detach(self.models);
        }
      }
      return {
        detach() {
          detachModels();
        },
        add(model) {
          self.models.push(model);
        },
        remove(model) {
          self.models.splice(self.models.indexOf(model), 1);
        },
        reset() {
          detachModels();
          applySnapshot(self, { models: [] });
        }
      };
    })
    .views((self) => ({
      get toArray() {
        return self.models;
      }
    }))
    .views(views);

const CollectionArray = CollectionArrayFactory(Model);
export interface ICollectionArray extends Instance<typeof CollectionArray> {}

export default CollectionArrayFactory;

/* eslint-disable react/jsx-props-no-spreading */
import { observer } from 'mobx-react-lite';
import StatusIcon from '~/components/status_icon';
import withAutoupdate from '~/hocs/with_autoupdate';

const StatusWithAutoUpdate = withAutoupdate(
  observer(({ model, size = 'medium' }) => {
    if (model?.statusType) {
      return <StatusIcon statusType={model.statusType()} statusText={model.statusText()} size={size} />;
    }
    return null;
  })
);

export default StatusWithAutoUpdate;

import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { observer } from 'mobx-react-lite';

import Breadcrumbs from '~/components/breadcrumbs';
import Container from '~/components/container';
import useLocales from '~/hooks/use_locales';
import useFetch from '~/hooks/use_fetch';
import useMst from '~/hooks/use_mst';
import Report from '~/mst/models/report';
import FormContainer from './form_container';

function ReportEdit() {
  const { id } = useParams();
  const { t } = useLocales();
  const { reports } = useMst();

  const { isFetched, isFetching } = useFetch(reports);

  const report = id ? reports.getById(id) : Report.create({ configuration: { node_ids: [] } });

  const links = useMemo(
    () => [
      { title: t('reports.title'), to: 'reports' },
      [
        ...(id ? [{ title: t('reports.view_details_breadcrumb'), to: `reports/${id}/view` }] : []),
        { title: id ? t('reports.edit_details_breadcrumb') : t('reports.create_new'), to: id ? `reports/${id}` : 'reports/create' }
      ]
    ],
    [id, t]
  );

  return (
    <Container>
      <Breadcrumbs links={links} />
      {isFetching || !isFetched ? <CircularProgress size={30} color="info" /> : <FormContainer model={report} />}
    </Container>
  );
}

export default observer(ReportEdit);

import React, { useState } from 'react';
import { Chip, Stack, Box } from '@mui/material';
import Popover from '@mui/material/Popover';
import useLocales from '~/hooks/use_locales';

function PermissionsFormatter({ value }: { value: string[] }) {
  const { t } = useLocales();
  const [permission, ...rest] = value;
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  return (
    <Stack direction="row" flexWrap="wrap">
      <Chip label={t(`permissions.${permission}`)} sx={{ m: '3px' }} />
      {Boolean(rest.length) && (
        <Box>
          <Chip
            label={t(`+ ${rest.length}`)}
            sx={{ m: '3px' }}
            variant="contained"
            onClick={(event) => {
              setOpen(true);
              setAnchorEl(event.currentTarget);
            }}
          >
            + {rest.length}
          </Chip>
          <Popover
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            onClose={() => setOpen(false)}
            disableRestoreFocus
          >
            <Stack direction="column" flexWrap="wrap" sx={{ m: 2 }}>
              {rest.map((item) => (
                <Chip label={t(`permissions.${item}`)} sx={{ m: '3px' }} />
              ))}
            </Stack>
          </Popover>
        </Box>
      )}
    </Stack>
  );
}

export default PermissionsFormatter;

import { useTranslation } from 'react-i18next';
import { enUS, esES, zhCN, ptBR, Localization } from '@mui/material/locale';

type LanguageType = {
  icon: string;
  label: string;
  systemValue: Localization;
  value: string;
};

export const LANGUAGES: Array<LanguageType> = [
  {
    label: 'English',
    value: 'en-US',
    systemValue: enUS,
    icon: '/static/flags/en.svg'
  },
  {
    label: 'Chinese',
    value: 'zh-CN',
    systemValue: zhCN,
    icon: '/static/flags/zh.svg'
  },
  {
    label: 'Portugese',
    value: 'pt-PT',
    systemValue: ptBR,
    icon: '/static/flags/pt.svg'
  },
  {
    label: 'Spanish',
    value: 'es-ES',
    systemValue: esES,
    icon: '/static/flags/es.svg'
  }
];

export function getLanguage(locale) {
  return LANGUAGES.find(({ value }) => value === locale) || LANGUAGES[0];
}

export default function useLocales() {
  const { i18n, t } = useTranslation();
  const changeLanguage = (newlang: string) => {
    i18n.changeLanguage(newlang);
  };

  return {
    changeLanguage,
    t,
    i18n
  };
}

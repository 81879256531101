import { DEVICE_STATUSES } from '~/utils/constants';
import { STATUS_COLORS } from '~/theme/types';
import I18n from '~/utils/i18n';
import type { ITotalDevicesWidget } from '.';

export default (self: ITotalDevicesWidget) => ({
  get pieChartData() {
    return [
      {
        name: 'Offline',
        color: STATUS_COLORS[DEVICE_STATUSES.OFFLINE],
        y: self.data?.devices.offline
      },
      {
        name: 'Online',
        color: STATUS_COLORS[DEVICE_STATUSES.ONLINE],
        y: self.data?.devices.online
      },
      {
        name: 'Inventory',
        color: STATUS_COLORS[DEVICE_STATUSES.INVENTORY],
        y: self.data?.devices.inventory
      },
      {
        name: 'Deactivated',
        color: STATUS_COLORS[DEVICE_STATUSES.DEACTIVATED],
        y: self.data?.devices.deactivated
      }
    ].filter(({ y }) => y);
  },
  get columnChartSeries() {
    const { devices, ...rest } = self.data;
    const data = Object.entries(rest);
    return [
      {
        name: 'Online',
        color: STATUS_COLORS[DEVICE_STATUSES.ONLINE],
        data: data?.map(([, { online }]) => online)
      },
      {
        name: 'Offline',
        color: STATUS_COLORS[DEVICE_STATUSES.OFFLINE],
        data: data?.map(([, { offline }]) => offline)
      },
      {
        name: 'Deactivated',
        color: STATUS_COLORS[DEVICE_STATUSES.DEACTIVATED],
        data: data?.map(([, { deactivated }]) => deactivated)
      },
      {
        name: 'Inventory',
        color: STATUS_COLORS[DEVICE_STATUSES.INVENTORY],
        data: data?.map(([, { inventory }]) => inventory)
      }
    ].filter(({ data: chartData }) => chartData.some((number) => number));
  },
  get columnChartCategories() {
    const { devices, ...rest } = self.data;
    return Object.entries(rest).map(([deviceType]) =>
      I18n.t(`filters.filter-tags.device-type.${deviceType.toLowerCase()}`, {
        defaultValue: deviceType
      })
    );
  }
});

import { types as t, Instance } from 'mobx-state-tree';

const ReportConfigurationModel = t.model({
  node_ids: t.array(t.string),
  subscribers: t.array(t.string),
  standard: t.maybeNull(t.enumeration('Standard', ['aqi'])),
  from: t.maybeNull(t.number),
  to: t.maybeNull(t.number)
});

export interface IReportConfigurationModel extends Instance<typeof ReportConfigurationModel> {}

export default ReportConfigurationModel;

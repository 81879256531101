import { EnumAsUnion } from '~/utils/enum_as_union';

export enum WidgetTypes {
  ChartWidget = 'ChartWidget',
  ChartTableWidget = 'ChartTableWidget',
  NodeWidget = 'NodeWidget',
  BlocksWidget = 'BlocksWidget',
  MapWidget = 'MapWidget',
  HeatmapWidget = 'HeatmapWidget',
  TotalDevicesWidget = 'TotalDevicesWidget',
  TotalDevicesTypesWidget = 'TotalDevicesTypesWidget',
  TotalDevicesOnlinePieChartWidget = 'TotalDevicesOnlinePieChartWidget',
  TotalDevicesOnlineColumnChartWidget = 'TotalDevicesOnlineColumnChartWidget',
  RecentAlertsWidget = 'RecentAlertsWidget',
  DevicesOnlineChartWidget = 'DevicesOnlineChartWidget'
}

export type WidgetTypesUnionType = EnumAsUnion<typeof WidgetTypes>;

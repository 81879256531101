import { observer } from 'mobx-react-lite';
import { TableCell, Grid, Card, CardContent, Typography, Stack, Box } from '@mui/material';

import type { ICalibration } from '~/mst/models/calibration';
import useLocales from '~/hooks/use_locales';
import { DetailRowStyled } from '~/components/react_grid/styled';
import LastTriggeredFormatter from '../last_triggered_formatter';

function GridDetailContainer({ row: { parameters } }: { row: ICalibration }) {
  const { t } = useLocales();

  return (
    <DetailRowStyled>
      <TableCell />
      <TableCell colSpan={7}>
        <Grid container spacing={2} alignItems="flex-start">
          <Grid item md={12} sm={12} lg={12}>
            <Stack direction="row" spacing={2}>
              {parameters?.map((parameter) => (
                <Card key={`${parameter.serial}_${parameter.name}`} sx={{ minWidth: 150 }}>
                  <CardContent>
                    <Typography variant="body1" component="div" mb={1}>
                      {parameter.name}
                    </Typography>
                    <Stack spacing={1}>
                      <Box>
                        <Typography variant="subtitle2">{t('attributes.calibration.timestamp')}</Typography>
                        <Typography variant="body2">
                          <LastTriggeredFormatter value={parameter.timestamp} />
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="subtitle2">{t('attributes.calibration.reference_value')}</Typography>
                        <Typography variant="body2">{parameter.referenceDevice}</Typography>
                      </Box>
                      <Box>
                        <Typography variant="subtitle2">{t('attributes.calibration.status')}</Typography>
                        <Typography variant="body2">{parameter.statusText}</Typography>
                      </Box>
                      <Box>
                        <Typography variant="subtitle2">{t('attributes.calibration.metrics')}</Typography>
                        <Typography variant="body2">{parameter.metricsText}</Typography>
                      </Box>
                    </Stack>
                  </CardContent>
                </Card>
              ))}
            </Stack>
          </Grid>
        </Grid>
      </TableCell>
      <TableCell />
    </DetailRowStyled>
  );
}

export default observer(GridDetailContainer);

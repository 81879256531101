import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useField } from 'react-final-form';
import AddIcon from '@mui/icons-material/Add';
import { LoadingButton } from '@mui/lab';
import { Box, Button } from '@mui/material';
import { observer } from 'mobx-react-lite';

import TextInput from '~/components/final_form/text_input';
import FormGrid from '~/components/@material-extend/form_grid';

import useNavigate from '~/hooks/use_navigate';
import useMst from '~/hooks/use_mst';
import OrganizationSelect from '~/components/organizations_select';

type ClaimDevicesFormType = {
  submitting: boolean;
  handleSubmit: VoidFunction;
};

function ClaimDeviceForm({ handleSubmit, submitting }: ClaimDevicesFormType) {
  const { t } = useTranslation();
  const {
    input: { value: serials }
  } = useField('serials', { subscription: { value: true } });
  const { navigate } = useNavigate();
  const { organizations } = useMst();
  const { isSyncing } = organizations;

  const handleRedirect = useCallback(() => {
    navigate(`organizations/new?serials=${serials}`);
  }, [serials, navigate]);

  return (
    <FormGrid>
      <TextInput name="serials" label={t('claim.serials')} multiline rows={3} helperText={<small>{t('claim.serials_helper')}</small>} />

      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <OrganizationSelect
          name="organization"
          label={t('claim.organization')}
          options={organizations.valueLabelPairsManagerAccess}
          loading={isSyncing}
        />
        {organizations.valueLabelPairsManagerAccess.length === 0 && organizations.isSynced && (
          <Button
            sx={{
              marginLeft: '15px',
              height: 55,
              minWidth: 180
            }}
            variant="outlined"
            onClick={handleRedirect}
          >
            {t('organizations.create_new')}
          </Button>
        )}
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <LoadingButton type="submit" disabled={submitting} variant="contained" loading={submitting} onClick={handleSubmit} startIcon={<AddIcon />}>
          {t('claim.claim_devices')}
        </LoadingButton>
      </Box>
    </FormGrid>
  );
}

export default observer(ClaimDeviceForm);

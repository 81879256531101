/* eslint-disable react/jsx-props-no-spreading */
import { forwardRef } from 'react';
import { TextField } from '@mui/material';
import { Field as FinalField } from 'react-final-form';
import PhoneNumberInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

const PhoneInputWithRef = forwardRef((props, ref) => <TextField {...props} inputRef={ref} fullWidth />);

interface Props {
  name: string;
  label: string;
}

export default function RFFPhoneInput(props: Props) {
  const { name, ...rest } = props;
  return (
    <FinalField name={name}>
      {({ input, meta }) => {
        const hasError = meta.touched && meta.error;
        return (
          <PhoneNumberInput
            {...rest}
            {...input}
            withCountryCallingCode
            international
            inputComponent={PhoneInputWithRef}
            helperText={hasError}
            error={!!hasError}
          />
        );
      }}
    </FinalField>
  );
}

// export default forwardRef(phoneInput);

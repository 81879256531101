/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useTheme } from '@mui/material/styles';
import { Tooltip, Box, Stack, SxProps } from '@mui/material';
import TypographyStyled, { IconWrapperStyled, ParameterWrapperStyled } from './styled';

type DeviceParameterType = {
  tooltipTitle?: string;
  title?: string | number;
  noValueText?: string;
  value?: string | number;
  IconComponent?: any;
  iconWidth?: string | number;
  iconHeight?: string | number;
  align?: string;
  placement?: 'top' | 'bottom';
  showLabel?: boolean;
  iconLeft?: boolean;
  sx?: SxProps;
};

function DeviceParameter({
  tooltipTitle = '',
  title,
  noValueText = 'N/A',
  value,
  IconComponent,
  iconWidth = '14px',
  iconHeight = '14px',
  align = 'center',
  placement = 'top',
  showLabel = true,
  iconLeft = false,
  sx
}: DeviceParameterType) {
  return (
    // eslint-disable-next-line react/no-danger
    <Tooltip title={tooltipTitle && tooltipTitle !== '' && <span dangerouslySetInnerHTML={{ __html: tooltipTitle }} />} arrow placement={placement}>
      <ParameterWrapperStyled alignItems="center" sx={sx}>
        {IconComponent && (
          <IconWrapperStyled width={iconWidth} height={iconHeight}>
            <IconComponent width="100%" height="100%" sx={{ color: 'primary.muted' }} fontSize="small" />
          </IconWrapperStyled>
        )}
        {showLabel && <TypographyStyled>{title || value ? title : noValueText}</TypographyStyled>}
      </ParameterWrapperStyled>
    </Tooltip>
  );
}

export default DeviceParameter;

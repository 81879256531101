import Slider from '@mui/material/Slider';
import { FormControlLabel, FormHelperText, styled, TextField, Box } from '@mui/material';

export const FormHelperTextStyled = styled(FormHelperText)(() => ({
  paddingLeft: 2,
  paddingRight: 2,
  textAlign: 'center'
}));

export const StyledliderStyled = styled(Slider)(({ theme }) => ({
  '& .MuiSlider-thumb': {
    '&:focus, &:hover, &.Mui-active': {
      boxShadow: 'none'
    }
  },
  '& .MuiSlider-valueLabel': {
    fontSize: 14,
    fontWeight: 'normal',
    top: 40,
    backgroundColor: 'unset',
    color: theme.palette.text.primary,
    '&:before': {
      display: 'none'
    },
    '& *': {
      background: 'transparent',
      color: theme.palette.mode === 'dark' ? '#fff' : theme.palette.text.primary
    }
  }
}));

export const SliderInputStyled = styled(TextField)(() => ({
  width: 80,
  input: {
    textAlign: 'center',
    fontSize: '14px'
  }
}));

export const SliderWrapperStyled = styled(Box)(() => ({
  paddingTop: 1,
  paddingBottom: 1
}));

export const SwitchLabelStyled = styled(FormControlLabel)(() => ({
  marginLeft: 0
}));

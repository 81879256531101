import '~/services/datadog';
import '~/services/sentry';
import '~/locales/i18n';

import ReactDOM from 'react-dom/client';
import React, { StrictMode } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import { MstProvider } from '~/contexts/mst_context';
import store from '~/mst/store';
import App from './app';

import { CollapseDrawerProvider } from './contexts/collapse_drawer_context';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <StrictMode>
    <HelmetProvider>
      <CollapseDrawerProvider>
        <BrowserRouter>
          <MstProvider store={store}>
            <App />
          </MstProvider>
        </BrowserRouter>
      </CollapseDrawerProvider>
    </HelmetProvider>
  </StrictMode>
);

import { types as t, Instance, SnapshotOut } from 'mobx-state-tree';

const BreakpointLayout = t.model({
  x: t.maybeNull(t.number),
  y: t.maybeNull(t.number),
  w: t.maybeNull(t.number),
  h: t.maybeNull(t.number)
});

export interface IBreakpointLayout extends Instance<typeof BreakpointLayout> {}
export interface IBreakpointLayoutSnapshotOut extends SnapshotOut<typeof BreakpointLayout> {}

export default BreakpointLayout;

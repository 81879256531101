/* eslint-disable no-param-reassign */
import last from 'lodash/last';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import isBoolean from 'lodash/isBoolean';
import I18n from '~/utils/i18n';
import { FUNCTIONS } from '~/mst/models/condition/types';
import { PATHS } from '~/utils/constants';
import type { IAlertModel } from '../model';

export default (self: IAlertModel) => {
  function getTriggeredDataPoints() {
    return self.data_points?.toArray?.filter(({ lastTs }) => lastTs);
  }
  function getLastTriggeredValue() {
    return get(
      last(
        getTriggeredDataPoints()
          ?.map(({ lastValue, lastTs }) => [lastTs, lastValue])
          .sort(([ts1], [ts2]) => ts1 - ts2)
      ),
      '[1]'
    );
  }
  function statusType() {
    const { status } = self;
    const measurement = getLastTriggeredValue();
    if (status === 'deactivated') {
      return 'deactivated';
    }
    if (isBoolean(measurement)) {
      return measurement ? 'active' : 'resolved';
    }

    return 'idle';
  }
  return {
    get isActive() {
      return self.data_points?.toArray?.some(({ lastValue }) => lastValue === true);
    },
    get lastTriggeredTs() {
      return last(
        getTriggeredDataPoints()
          ?.map(({ lastTs }) => lastTs)
          .sort()
      );
    },
    get lastTriggeredValue() {
      return getLastTriggeredValue();
    },
    statusType() {
      return statusType();
    },
    statusText() {
      return I18n.t(`alerts.states.${statusType()}`);
    },
    get ownerName() {
      if (!self.owner) {
        return '';
      }
      return self.owner.name;
    },
    get notificationType() {
      const { email, sms } = self.subscribers;
      if (email.length && isEqual(email, sms)) {
        return 'Email & SMS';
      }
      if (sms.length) {
        return 'SMS';
      }
      return 'Email';
    },
    get nodesValueLabelPairsWithFunctions() {
      const functionOptions = FUNCTIONS.map((exp) => ({ value: exp, label: I18n.t(`conditions.expressions.${exp}_title`) }));
      return [...functionOptions, ...(self.nodes?.valueLabelPairs.filter(({ model }) => model.isMonitorable) || [])];
    },
    parametersValueLabelPairs(nodeId) {
      return self.nodes?.getById(nodeId)?.data_points?.valueLabelPairs.filter(({ model }) => model.isDefault || model.shortPath === PATHS.ONLINE);
    }
  };
};

import { types as t, getIdentifier, Instance, IAnyStateTreeNode, applySnapshot, detach, isAlive } from 'mobx-state-tree';
import { ModelV1 as Model } from './model';
import views from './collection_views';

const CollectionMapFactory = (modelType) =>
  t
    .model({
      models: t.map(modelType)
    })
    .actions((self) => {
      function detachModels() {
        if (isAlive(self.models)) {
          detach(self.models);
        }
      }
      return {
        detach() {
          detachModels();
        },
        add(model: IAnyStateTreeNode) {
          self.models.put(model);
        },
        remove(model: IAnyStateTreeNode) {
          detach(model);
          self.models.delete(getIdentifier(model)!);
        },
        update(model: IAnyStateTreeNode) {
          self.models.delete(getIdentifier(model)!);
          self.models.put(model);
        },
        reset() {
          detachModels();
          applySnapshot(self, { models: {} });
        }
      };
    })
    .views((self) => ({
      get toArray() {
        return Array.from(self.models.values());
      }
    }))
    .views(views);

const CollectionMap = CollectionMapFactory(Model);
export interface ICollectionMap extends Instance<typeof CollectionMap> {}

export default CollectionMapFactory;

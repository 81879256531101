import { types as t, Instance } from 'mobx-state-tree';
import I18n from '~/utils/i18n';

export const EXPRESSIONS = ['gt', 'gte', 'lt', 'lte', 'eq', 'neq'];
export const FUNCTIONS = ['abs', 'avg', 'min', 'max', 'diff'];

export const Expressions = t.enumeration('Expr', EXPRESSIONS);
export const Functions = t.enumeration('Func', FUNCTIONS);

export const MetricFunctions = t.enumeration('Func', [...FUNCTIONS, 'scale', 'link']);
export const Value = t.frozen();

export type FuncType = Instance<typeof Functions>;
export type ExprType = Instance<typeof Expressions>;

export function getFuncName(func: FuncType) {
  return I18n.t(`conditions.expressions.${func}`);
}

import { Instance } from 'mobx-state-tree';
import WidgetProcessor from '~/mst/models/widget/processor';
import actions from './actions';
import model from './model';
import views from './views';

const FilesWidgetStore = model.views(views).actions(actions);

export interface IFilesWidgetStore extends Instance<typeof FilesWidgetStore> {}

export default WidgetProcessor(FilesWidgetStore);

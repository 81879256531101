import { Instance, SnapshotOut } from 'mobx-state-tree';
import model from './model';
import actions from './actions';
import views from './views';
import viewsFilters from './views/filters';

const Organization = model.views(views).views(viewsFilters).actions(actions);

export interface IOrganization extends Instance<typeof Organization> {}
export interface IOrganizationSnapshotOut extends SnapshotOut<typeof Organization> {}

export default Organization;

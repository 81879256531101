import { Instance, SnapshotOut, types as t } from 'mobx-state-tree';
import pick from 'lodash/pick';
import omit from 'lodash/omit';
import model from './model';
import actions from './actions';
import views from './views';

export const DashboardModel = model.views(views).actions(actions);

export interface IDashboard extends Instance<typeof DashboardModel> {}
export interface IDashboardOut extends SnapshotOut<typeof DashboardModel> {}

const CONFIGURATION_FIELDS = ['widgets', 'range', 'is_starred'];

export default t.snapshotProcessor(DashboardModel, {
  preProcessor(dashboard: IDashboardOut) {
    if (dashboard.configuration) {
      return {
        ...dashboard,
        ...pick(dashboard.configuration, CONFIGURATION_FIELDS)
      };
    }
    return dashboard;
  },
  postProcessor(sn) {
    return {
      ...omit(sn, CONFIGURATION_FIELDS),
      configuration: pick(sn, CONFIGURATION_FIELDS)
    };
  }
});

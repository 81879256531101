import { Theme } from '@mui/material/styles';

export default function Popover(theme: Theme) {
  return {
    MuiPopover: {
      styleOverrides: {
        root: {
          zIndex: 2000,
          '.MuiPopover-paper': {
            overflow: 'visible'
          }
        }
      }
    }
  };
}
